import React, { useState, useEffect } from 'react';
import { Button, Spinner, Row, Col, Form, FormControl, Modal } from 'react-bootstrap';
import { modelContentUtils } from '../utils/helpers';
import { OrgRepository, AppraisalRepository } from '../utils/repositories';
import LoadingComponent from '../presenters/LoadingComponent';
import ReportFragment from '../presenters/ReportFragment';
import { PRACTICE_AREAS } from '../model/practiceareas';
import { PRACTICE_AREA_RELATIONSHIPS } from '../model/practicearearelationships';
import { Constants, getDefaultAPIHeaders, getCurrentDateTime } from '../utils/helpers';
import { API } from 'aws-amplify';
import useArray from '../hooks/useArray';
import pptxgen from "pptxgenjs";
import pptBottom from '../assets/pptBottom.png';

async function fetchData(setDataLoading, setAppraisalName, setOrgName, projects, practiceAreas) {
    setDataLoading(true);
    const currentAppraisalId = localStorage.getItem("selectedAppraisal");
    if (currentAppraisalId) {
        const apiRepo = new AppraisalRepository(null, null, { selected: false, availableForSelection: false });
        const appraisalData = await apiRepo.getOne(currentAppraisalId, false);
        if (!appraisalData.error) {
            projects.setValue(appraisalData.returnValue.projects);
            setAppraisalName(appraisalData.returnValue.name);
            practiceAreas.setValue(modelContentUtils.getPracticeAreasFromAppraisalProjects(appraisalData.returnValue.projects, true, true));

            const orgRepo = new OrgRepository();
            const orgData = await orgRepo.getAll();
            const org = orgData.returnValue.find(org => org.id === appraisalData.returnValue.organization);
            if (org) {
                setOrgName(org.name);
            }
        } else {
            console.log("error getting appraisal data: ", appraisalData.returnValue);
        }
    }

    setDataLoading(false);
}

const FindingsPresentationModal = (props) => {
    const [dataLoading, setDataLoading] = useState(true);
    const [appraisalName, setAppraisalName] = useState('');
    const [orgName, setOrgName] = useState('');
    const [reportLoading, setReportLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [presentationType, setPresentationType] = useState("draft");
    const projects = useArray([]);
    const practiceAreas = useArray([]);

    useEffect(() => {
        if (props.showModal) {
            const getData = () => fetchData(setDataLoading, setAppraisalName, setOrgName, projects, practiceAreas);
            getData();
            setPresentationType("draft");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showModal]);

    const shadowStyle = { type: 'outer', angle: 270, blur: 1, offset: 1 };
    const headerOpts = {
        x: 0.0,
        y: 0.0,
        w: '100%',
        h: .75,
        align: 'center',
        fontSize: 18,
        color: 'FFFFFF',
        fill: '#59a09a',
    };
    let allSlides = [];
    let currentSlide = null;
    let currentY = 0;

    async function generateReport(currentAppraisalId, rollupId) {

        const apiPath = `${Constants.APPRAISAL_ITEM_PATH}/${currentAppraisalId}`
        const data = await API.get(Constants.API_PATH, apiPath, await getDefaultAPIHeaders());
        const rollUpData = data.filter(x => x.project_id === rollupId);
        const allPAs = practiceAreas.value;
        allSlides = [];
        currentY = 0;

        let pptGenerator = new pptxgen();

        addTitleSlide(pptGenerator);

        for (let i = 0; i < allPAs.length; i++) {
            const practiceName = allPAs[i].name;

            //get the node ids for each node in the practice area
            const practiceNodes = PRACTICE_AREA_RELATIONSHIPS[allPAs[i].id];

            //compile findings data
            let strengths = [];
            let weaknesses = [];
            let improvementOps = [];
            for (let j = 0; j < practiceNodes.length; j++) {
                for (let k = 0; k < rollUpData.length; k++) {
                    if (rollUpData[k].node_id === practiceNodes[j].Id) {
                        if (rollUpData[k].item_type === "strengths") {
                            strengths.push([rollUpData[k].item_data]);
                        }
                        else if (rollUpData[k].item_type === "weaknesses") {
                            weaknesses.push([rollUpData[k].item_data]);
                        }
                        else if (rollUpData[k].item_type === "improvement_opportunities") {
                            improvementOps.push([rollUpData[k].item_data]);
                        }
                    }
                }
            }

            addPracticeAreaSlide(pptGenerator, practiceName, allPAs[i].id);
            currentY = 2.3;

            if (presentationType !== "draft") {
                //write strengths entries
                currentSlide.addText("Strengths", { x: 0.5, y: 2.0, w: '75%', h: '10%', fontSize: 12, bold: true, color: '#59a09a' });
                addFindings(pptGenerator, practiceName, "Strengths", strengths);
            }

            //write weakness entries
            addHeader(pptGenerator, practiceName, "Weaknesses");
            addFindings(pptGenerator, practiceName, "Weaknesses", weaknesses);

            if (presentationType !== "draft") {
                //write improvement opportunity entries
                addHeader(pptGenerator, practiceName, "Improvement Opportunities");
                addFindings(pptGenerator, practiceName, "Improvement Opportunities", improvementOps);
            }
        }

        addFooters(pptGenerator, allSlides);

        pptGenerator.writeFile("findingsPresentation.pptx");
        props.setShowModal(false);
    }

    function addTitleSlide(pptGenerator) {
        currentSlide = pptGenerator.addSlide();
        allSlides.push(currentSlide);
        currentSlide.addText("", headerOpts);
        currentSlide.addText(orgName, { y: .8, w: '100%', h: '30%', fontSize: 32, align: 'center', color: '000000' });
        currentSlide.addText(appraisalName, { y: 1.5, w: '100%', h: '30%', fontSize: 28, bold: true, shadow: shadowStyle, align: 'center', color: '#59a09a' });
        let presentationTypeText = presentationType === "draft" ? "Preliminary Findings Presentation" : "Final Findings Presentation";
        currentSlide.addText(presentationTypeText, { y: 2, w: '100%', h: '30%', fontSize: 24, align: 'center', color: '000000' });
        currentSlide.addText("Generated: " + getCurrentDateTime(), { y: 2.5, w: '100%', h: '30%', fontSize: 10, align: 'center', color: '000000' });
    }

    function addPracticeAreaSlide(pptGenerator, practiceName, practiceAreaId) {
        currentSlide = pptGenerator.addSlide();
        allSlides.push(currentSlide);

        currentSlide.addText(practiceName, headerOpts);
        currentSlide.addText("Intent: ", { x: 0.4, y: 1, w: '75%', h: '10%', fontSize: 12, bold: true, color: '#59a09a', valign:"top" });
        currentSlide.addText(PRACTICE_AREAS[practiceAreaId].Intent, { x: 1.0, y: 1, w: '75%', h: '10%', fontSize: 10, valign:"top", margin:5});
        currentSlide.addText("Value: ", { x: 0.4, y: 1.5, w: '75%', h: '10%', fontSize: 12, bold: true, color: '#59a09a', valign:"top" });
        currentSlide.addText(PRACTICE_AREAS[practiceAreaId].Value, { x: 1.0, y: 1.5, w: '75%', h: '10%', fontSize: 10, valign:"top", margin:5 });
    }

    function addHeader(pptGenerator, practiceName, findingsName) {
        //if we are past the vertical limit after improvement opportunities, continue on next page, else stay on same page
        if (currentY > 4.5) {
            currentSlide = pptGenerator.addSlide();
            allSlides.push(currentSlide);
            currentSlide.addText(practiceName + " (continued)", headerOpts);
            currentSlide.addText(findingsName, { x: 0.5, y: 1.0, w: '75%', h: '10%', fontSize: 12, bold: true, color: '#59a09a' });
            currentY = 1.3;
        }
        else {
            currentSlide.addText(findingsName, { x: 0.5, y: currentY, w: '75%', h: '10%', fontSize: 12, bold: true, color: '#59a09a' });
            currentY += .3
        }
    }

    function addFindings(pptGenerator, practiceName, findingsName, findingsCollection) {
        if (findingsCollection.length === 0) {
            currentY += .15;
            currentSlide.addText(`■`, { x: 1, y: currentY, w: '5%', h: '5%', fontSize: 10, color: '#59a09a', valign:"top" });
            currentSlide.addText('None Identified', { x: 1.2, y: currentY, w: '70%', h: '5%', fontSize: 10, valign:"top" });
            currentY += .15;
        }
        else {
            for (let j = 0; j < findingsCollection.length; j++) {

                let text = findingsCollection[j].toString();

                //modify Y position based on sets of 150 chars
                let yModifier = text.length / 150;
                if (yModifier < 1) {
                    yModifier = 1;
                }
                currentY += .15;

                //account for new lines
                let yNewLineModifier = text.split(/\r\n|\r|\n/).length * .15

                //if we are past the vertical limit, continue on next page
                if (currentY > 4.5) {
                    currentSlide = pptGenerator.addSlide();
                    allSlides.push(currentSlide);
                    currentSlide.addText(practiceName + " (continued)", headerOpts);
                    currentSlide.addText(findingsName + " (continued)", { x: 0.5, y: 1.0, w: '75%', h: '10%', fontSize: 12, bold: true, color: '#59a09a' });
                    currentY = 1.3;
                    currentY += (.15 * yModifier);
                }
                currentSlide.addText(`■`, { x: 1, y: currentY, w: '5%', h: '5%', fontSize: 10, color: '#59a09a', valign:"top" });
                currentSlide.addText(findingsCollection[j].toString(), { x: 1.2, y: currentY, w: '70%', h: '5%', fontSize: 10, valign:"top" });
                currentY += (.3 * yModifier) + yNewLineModifier;
            }
        }
    }

    function addFooters(pptGenerator, slides) {
        for (let i = 0; i < slides.length; i++) {
            slides[i].addImage({ path: pptBottom, x: 0, y: 4.95, w: '100%', h: '13%' });
            let pageNumberText = (i + 1);
            //overlay a shape to display page number on top of image
            //do not add to first page
            if (i > 0) {
                slides[i].addText(pageNumberText, { shape: pptGenerator.ShapeType.rect, x: 9.5, y: 5.23, w: '5%', h: '5%', fill: { color: "#59a09a" }, align: "center", fontSize: 12, color: 'FFFFFF' });
            }
        }
    }

    const handleGenerateReportClicked = async (event) => {
        setReportLoading(true);

        let allProjects = projects.value;

        //do not generate if we don't have a rollup
        let hasRollup = false;
        let rollupId = 0;
        for (let i = 0; i < allProjects.length; i++) {
            if (allProjects[i].name === "Organizational Roll-up") {
                hasRollup = true;
                rollupId = allProjects[i].id;
            }
        }

        if (!hasRollup) {
            setModalMessage("No organizational roll-up found.")
            setShowMessageModal(true);
        }
        else {
            let currentAppraisalId = localStorage.getItem("selectedAppraisal");
            await generateReport(currentAppraisalId, rollupId);
        }

        setReportLoading(false);
    }

    const handleTypeChanged = (value) => {
        setPresentationType(value);
    }

    return (
        <Modal show={props.showModal} onHide={(e) => props.setShowModal(false)} dialogClassName="reportModalStyle">
            <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: '18px', color: 'dimgray' }}>
                    <img
                        src={require('../assets/icon.png')}
                        width="30"
                        height="30"
                        className="d-inline-block align-middle"
                        alt="CAP logo"
                    />{' '}Findings Presentation</Modal.Title>
            </Modal.Header>
            <LoadingComponent isLoading={dataLoading} iconSize={80}>
                <ReportFragment visible='true' >
                    <div style={{ display: "grid", gridTemplateColumns: "10% 80% 10%", gridTemplateRows: "100%", marginTop: '-50px' }}>
                        <div style={{ gridColumnStart: "2", gridRowStart: "1" }}>
                            <Form>
                                <Form.Group as={Row} className="d-flex justify-content-center">
                                    <Form.Label
                                        column sm='4'
                                        style={{
                                            color: 'white',
                                            backgroundColor: 'rgb(0,168,168)',
                                            fontSize: '16px',
                                            textAlign: 'center',
                                            padding: '.5rem'
                                        }}>
                                        Appraisal
                                </Form.Label>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        <Form.Control plaintext readOnly value={appraisalName} style={{ color: 'dimgray', paddingLeft: '1rem' }} />
                                    </Col>
                                </Form.Group>
                            </Form>
                            <Form.Group as={Row} className="d-flex justify-content-center">
                                <Form.Label
                                    column sm='4'
                                    style={{
                                        color: 'white',
                                        backgroundColor: 'rgb(0,168,168)',
                                        fontSize: '16px',
                                        textAlign: 'center',
                                        padding: '.5rem'
                                    }}>
                                    Presentation Type
                                </Form.Label>
                                <Col md={6} style={{ textAlign: 'left' }}>
                                    <FormControl
                                        as="select"
                                        style={{ borderRightColor: 'white', borderTopColor: 'white' }}
                                        onChange={(e) => handleTypeChanged(e.target.value)} >
                                        <option value='draft'>Preliminary Presentation</option>
                                        <option value='final'>Final Presentation</option>
                                    </FormControl>
                                </Col>
                            </Form.Group>
                            <Row className="d-flex justify-content-center">
                                <Button
                                    variant="outline-light"
                                    style={{
                                        background: 'rgb(0, 168, 168)',
                                        color: 'white',
                                        border: 'none',
                                        justifyContent: 'center',
                                        padding: '.5rem',
                                        marginTop: '1rem',
                                        marginBottom: '1rem'
                                    }}
                                    size='sm'
                                    onClick={(e) => handleGenerateReportClicked(e)}>
                                    {reportLoading &&
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size='sm'
                                            role="status"
                                            aria-hidden="true"
                                        />}
                                    {!reportLoading ? "Generate Findings Presentation" : ' Generating Presentation...'}
                                </Button>{' '}
                            </Row>
                            <Modal bsSize="small"
                                show={showMessageModal}
                                onHide={(e) => setShowMessageModal(false)}
                                animation
                                style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
                                size='sm'>
                                <Modal.Header>
                                    <Modal.Title className='w-100 text-center' style={{ fontSize: '16px', color: 'dimgray' }}>{modalMessage}</Modal.Title>
                                </Modal.Header>
                                <Modal.Footer style={{ backgroundColor: 'rgb(0,168,168)' }}>
                                    <Button
                                        variant="outline-light"
                                        size='sm'
                                        onClick={(event) => setShowMessageModal(false)}
                                    >Ok</Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </ReportFragment>
            </LoadingComponent>
        </Modal>
    );
}
export default FindingsPresentationModal;