import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import '../modal.css';

function ConfirmDeleteModal(props){

    return(
        <Modal show={props.showDeleteModal} onHide={()=>props.close()} animation bsSize="large">
            <Modal.Header>                
                <Modal.Title style={{fontSize:'18px', color:'dimgray'}}>
                    <img
                        src={require('../assets/icon.png')}
                        width="30"
                        height="30"
                        className="d-inline-block align-middle"
                        alt="CAP logo"/>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Row className= "d-flex justify-content-center">
            <Col className='col-md-10'>
            <Modal.Body>            
                <text style={{fontSize:'14px', color:'dimgray'}}>{props.deleteQuestion}</text> 
                <br></br>               
                <text style={{fontSize:'12px',color:'dimgray'}}>{props.deleteImpact}</text>
            </Modal.Body>
            </Col>
            </Row>            
            <Modal.Footer style={{backgroundColor:'rgb(0,168,168)'}}>
                <Button
                    variant="outline-light"
                    size='sm'                
                    onClick={()=>props.deleteAction()}
                    >Yes</Button>{' '}   
                <Button
                    variant="outline-light"
                    size='sm'                    
                    onClick={()=>props.close()}
                    >No</Button>{' '}                 
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmDeleteModal;