import React, { useState, useEffect, useRef } from "react";
import { Auth, API } from 'aws-amplify';
import "./App.css";
import { Routes } from './presenters/Routes.js';
import { withRouter } from "react-router-dom";

function App (props){
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [session, setSession] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [orgId, setOrgId] = useState(null);

  const userHasAuthenticated = (session) => {
    setSession(session);
    const groups = session.idToken.payload['cognito:groups'];    
    const adminGroup = groups.find(g=>g === 'admins');
    
    if(adminGroup){
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
    if(session.idToken.payload.hasOwnProperty('custom:organization_id')){
      setOrgId(session.idToken.payload['custom:organization_id']);
    } else {
      setOrgId(null);
    }
    //uncomment this to get the AWS session token for development use
    //console.log(session.idToken);
  }

  const fetchAuthenticationState = async () => {
    try{
      const session = await Auth.currentSession();
      userHasAuthenticated(session);
    }catch(e){
    }
    setIsAuthenticating(false);
  }
  useEffect(()=>{
    fetchAuthenticationState();   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = async event => {
    await Auth.signOut();
    sessionStorage.clear();
    setSession(null);
    setIsAdmin(false);
    props.history.push("/login");
  }

  const onLogoutClicked = () =>{
    handleLogout();
  }

  const childProps = {
    userHasAuthenticated: userHasAuthenticated,
    onLogoutClicked: onLogoutClicked,
    session: session,
    orgId: orgId,
    isAdmin: isAdmin
  };


  return(
    !isAuthenticating &&
    <div>     
      <Routes childProps={childProps}/>
    </div>
    );  
  }

export default withRouter(App);

