import { API } from 'aws-amplify';
import { APIResult, convertToFrontEnd, getDefaultAPIHeaders, API_NAME } from './apiUtils';

const API_PATH = '/appraisalstate';

const DATA_MAPPING = {
    affirmed: "affirmed",
    id: "compound_id",
    appraisalId: "appraisal_id",
    nodeId: "node_id",
    state: "node_state",
    projectId: "project_id",
    revNumber: "revision_number"
}

class AppraisalStateRepository{
    constructor(defaultValues = null){
        this.defaultValues = defaultValues;
    }

    async getAll(appraisalId, projectId){
        const resolved = API.get(API_NAME, `${API_PATH}/${appraisalId}/${projectId}`, await getDefaultAPIHeaders())
        .then(d=>{
            const retVal = d.map(i=>{
                let newItem = convertToFrontEnd(DATA_MAPPING, i);

                if(this.defaultValues){
                    newItem = {
                        ...newItem,
                        ...this.defaultValues
                    }
                }
                newItem.projects = [];
                return newItem;
            });
            return new APIResult(false, retVal);
        }).catch(err=>{
            return new APIResult(true, err);
        });
        return resolved;
    }

    async getOne(appraisalId, projectId, item){
        const resolved = API.get(API_NAME, `${API_PATH}/${appraisalId}/${projectId}/${item.id}`, await getDefaultAPIHeaders())
        .then(data=>{
            let retVal = convertToFrontEnd(DATA_MAPPING, data);
            //The purpose of this spread is to preserve the values of the item
            // that are front-end only (such as whether or not they are selected)
            // and update the values that are from the back end.
            retVal = {
                ...item,
                ...retVal
            };
            return new APIResult(false, retVal);
        }).catch(err=>{
            return new APIResult(true, err);
        });
        return resolved;
    }

    async updateOne(appraisalId, projectId, nodeId, state, revNumber){
        const body = {
            appraisal_id: appraisalId,
            project_id: projectId,
            node_id: nodeId,
            node_state: state,
            revision_number: revNumber
        }
        const resolved = API.put(API_NAME, API_PATH, await getDefaultAPIHeaders(body))
            .then(data=>{
                let retVal = [];
                for(let i = 0; i < data.length; i++){
                    retVal.push(convertToFrontEnd(DATA_MAPPING, data[i]));
                }
                return new APIResult(false, retVal);
            }).catch(err=>{
                return new APIResult(true, err);
            });
        return resolved;
    }
}

export { AppraisalStateRepository };