import React from 'react';
import { Row, Col} from 'react-bootstrap';
import styled from 'styled-components';
import Card from 'react-bootstrap/Card';

const Styles = styled.div`
.container {
  position: relative;
  text-align: center;
  color: white;
  font-size: 110px;
}

.centered {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 100px;
  color: white;
}
`;

export const About = () => (
  <Styles>
    <Row>
      <Col md={3}></Col>
      <Col md={6}>    
      <Card>         
        <Card.Img 
          variant='top'  
          src={require('./assets/aboutImage.jpg')}
          alt=""
          width={200}
          height={400} />
          <div class='centered'>About</div>                  
        <Card.Body>
        <Card.Title>CSSA Appraisal Partner (CAP) Version 2.14</Card.Title>
        <Card.Subtitle className='mb-2 text-muted'>Copyright 2007 by Cepeda Systems & Software Analysis (CSSA), Inc. All rights reserved</Card.Subtitle>                    
          <hr class='my-2' style={{height:'1px', borderWidth: '0', color: 'gray', backgroundColor: 'gray'}}/>         
          <Card.Text>The CSSA Appraisal Partner (hereinafter "CAP") is the sole property of CSSA.  Use of CAP is offered as part of CSSA's CMMI coaching and appraisal services and is used for Quick Looks, 
            Appraisal preparation, and Appraisal Conduct performed or facilitated by CSSA. Use of the CAP Tool is governed by the Non-Disclosure Agreement (NDA) between CSSA and the customer Organization.</Card.Text>        
        <Card.Text className='mb-2 text-muted'>Contact the CAP Project Manager, Gayli Craver <a href='mailto:gayli.craver@cepedasystems.com'>(gayli.craver@cepedasystems.com)</a> for any questions.</Card.Text>
        </Card.Body>          
        </Card>
  </Col>
  <Col md={3}></Col>
  </Row>
  </Styles>  
  );
