import React from 'react';
import {Row, Col} from 'react-bootstrap'
import { PRACTICE_AREA_RELATIONSHIPS, PRACTICES } from '../model/modelcontent';
import { getCurrentDateTime } from '../utils/helpers';

function formatAffirmationData(projects, practiceAreas, maturityLevel, affirmationData, includeLevel1){
    let reportEntries = [];
    let reportRows = [];

    for(let i = 0; i < practiceAreas.length; i++){
        let reportHeader = (
            <h5>{practiceAreas[i].Name}</h5>
            );
        
        reportRows=[];

        //add a column for each project that has the current practice area
        let correspondingProjects = [];
        let projectHeaders=[];
        for(let j = 0; j < projects.length; j++){

            //do not include a rollup project
            if(projects[j].name !== "Organizational Roll-up"){

                let correspondingPA = projects[j].practiceAreas.filter(x => x === practiceAreas[i].id);
                if(correspondingPA.length > 0){
                    correspondingProjects.push(projects[j].name);
                    projectHeaders.push(<th style={headerCellStyle}>{projects[j].name}</th>);
                }
            }
        }
        
        //populate practice details
        let practices = [];
        if(includeLevel1){
            practices = PRACTICE_AREA_RELATIONSHIPS[practiceAreas[i].id].filter(x => x.Level <= maturityLevel);
        }
        else{
            practices = PRACTICE_AREA_RELATIONSHIPS[practiceAreas[i].id].filter(x => x.Level > 1 && x.Level <= maturityLevel);
        }
        let practiceRows = [];
        for(let j = 0; j < practices.length; j++){
            let practiceCells = [];
            practiceCells.push(<td style={cellStyle}>{PRACTICES[practices[j].Id].Abbreviation}</td>)
            for(let k = 0; k < correspondingProjects.length; k++){
                let correspondingPracticeData = affirmationData.filter(x => x.practiceArea === practiceAreas[i].id
                    && x.practice === PRACTICES[practices[j].Id].Abbreviation
                    && x.project === correspondingProjects[k]);
                let hasQuestion = "";
                let isAffirmed ="";
                if(correspondingPracticeData[0].hasQuestion){
                    hasQuestion = "Q";
                }if(correspondingPracticeData[0].isAffirmed){
                    isAffirmed = "A";
                }
                practiceCells.push(<td style={cellStyle}>{hasQuestion}{isAffirmed}</td>)
            }
            
            practiceRows.push(
                <tr>{practiceCells}</tr>
            )
        }

        //populate totals
        let totalCells = [];
        let projectTotals = [];
        for(let j = 0; j < correspondingProjects.length; j++){
            if(j === 0){
                totalCells.push(<td style={cellStyle}>Total:</td>)
            }
            let allPracticeAreaData = affirmationData.filter(x => x.practiceArea === practiceAreas[i].id
                && x.project === correspondingProjects[j]);
            let dataCount = allPracticeAreaData.length;
            let affirmationCount = 0;
            for(let k = 0; k < allPracticeAreaData.length; k++){
                if(allPracticeAreaData[k].hasQuestion){
                    affirmationCount++;
                }
            }
            //current projectTotal
            let percentTotal = affirmationCount / dataCount * 100;
            totalCells.push(<td style={cellStyle}>{Math.round(percentTotal)}%</td>);

            //all projects total
            projectTotals.push(percentTotal);
        }
        
        let allProjectsTotal = 0;
        for(let j = 0; j < projectTotals.length; j++){
            allProjectsTotal += projectTotals[j];
        }
        allProjectsTotal = allProjectsTotal / projectTotals.length;
        totalCells.push(<td style={cellStyle}>{Math.round(allProjectsTotal)}%</td>);

        reportRows.push(
            <tr style={{width:"300px"}}>
                <th style={headerCellStyle}>{practiceAreas[i].id}</th>
                {projectHeaders}
            </tr>
        );
        reportRows.push(practiceRows);
        reportRows.push(<tr>{totalCells}</tr>)

        reportEntries.push(
            <div>
                {reportHeader}
                <table style={{width: "50%", borderCollapse: "separate", borderSpacing:"1px", marginBottom:'50px'}}>
                    {reportRows}
                </table>
            </div>);
    }
    
    return reportEntries;
}

const cellStyle = {
    border: "1px solid black",
    textAlign: "center",
    width:'100px'
};

const headerCellStyle = {
    border: "1px solid black",
    textAlign: "center",
    backgroundColor:'rgb(0,168,168)',
    width:'100px'
};

const AffirmationCoverageReportView = ({projects, practiceAreas, maturityLevel, affirmationData, includeLevel1}) => {
    let reportRows = [];
    
    if(affirmationData.length !== 0){
        reportRows = formatAffirmationData(projects, practiceAreas, maturityLevel, affirmationData, includeLevel1);
    }

    let dateTime = getCurrentDateTime();
    return (
        <React.Fragment>
            <Row style={{height:'20px', marginBottom:'8px'}}>
            <Col>
                    <p style={{textAlign: "right"}}><small><b>Date/Time Generated:</b></small></p>
                </Col>
                <Col>
                    <p style={{textAlign: "left"}}><small>{dateTime}</small></p>
                </Col>
            </Row>
            <Row >
                <Col>
                    {reportRows}
                </Col>
            </Row>
        </React.Fragment>
    );
}
export default AffirmationCoverageReportView; 