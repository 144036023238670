import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Spinner, Modal} from 'react-bootstrap';
import { modelContentUtils } from '../utils/helpers';
import { AppraisalRepository, AppraisalStateRepository } from '../utils/repositories';
import useArray from '../hooks/useArray';
import LoadingComponent from '../presenters/LoadingComponent';
import ReportFragment from '../presenters/ReportFragment';
import RatingsReportView from '../presenters/RatingsReportView';
import { PRACTICE_AREAS, PRACTICES_AREAS_BY_MATURITY_LEVEL } from '../model/modelcontent';
import NewWindow from 'react-new-window';

async function fetchData(setDataLoading, setAppraisalData, projects, practiceAreas) {
    const currentAppraisalId = localStorage.getItem("selectedAppraisal");
    if (currentAppraisalId) {
        const apiRepo = new AppraisalRepository(null, null, { selected: false });
        const appraisalData = await apiRepo.getOne(currentAppraisalId, false);
        if (!appraisalData.error) {
            projects.setValue(appraisalData.returnValue.projects);
            setAppraisalData({
                id: appraisalData.returnValue.id,
                name: appraisalData.returnValue.name,
                view: appraisalData.returnValue.view
            });
            let PAs = modelContentUtils.getPracticeAreasFromAppraisalProjects(appraisalData.returnValue.projects);
            practiceAreas.setValue(PAs);


        } else {
            console.log("error getting appraisal data: ", appraisalData.returnValue);
        }
    }

    setDataLoading(false);
}

const RatingsReportModal = (props) => {
    const [dataLoading, setDataLoading] = useState(true);
    const [optionsVisible, setOptionsVisible] = useState(true);
    const [appraisalData, setAppraisalData] = useState({});
    const [reportLoading, setReportLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [ratingsData, setRatingsData] = useState(null);
    const projects = useArray([]);
    const practiceAreas = useArray([]);

    useEffect(() => {
        if (props.showModal) {
            const getData = () => fetchData(setDataLoading, setAppraisalData, projects, practiceAreas);
            getData();
            setOptionsVisible(true);
            setRatingsData(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showModal]);

    const generateReport = async (rollupId) => {

        setReportLoading(true);
        const maturityLevel = modelContentUtils.getMaturityLevelFromView(appraisalData.view);

        let rollUpProject;
        for(let i = 0; i < projects.value.length; i++){
            if(projects.value[i].id === rollupId){
                rollUpProject = projects.value[i];
                break;
            } 
        }

        const stateRepo = new AppraisalStateRepository();

        const response = await stateRepo.getAll(appraisalData.id, rollUpProject.id);
        if (!response.error) {
            let stateData = response.returnValue;

            let PAData = [];
            let levelData = [];
            for(let i = 0; i < rollUpProject.practiceAreas.length; i++){
                let currentPA = rollUpProject.practiceAreas[i];

                let paStateData;
                let paState = stateData.filter(x => x.nodeId === currentPA)[0];
                if(paState){
                    paStateData = paState.state;
                }
                else{
                    paStateData = "Not Examined"
                }

                let levelIds = PRACTICE_AREAS[currentPA].LevelIds;
                let availableLevels = Object.keys(levelIds);
                for(let j = 0; j < availableLevels.length; j++){
                    if(availableLevels[j] <= maturityLevel){
                        let currentLevelId = levelIds[availableLevels[j]];

                        let levelStateData = "";
                        let levelState = stateData.filter(x => x.nodeId === currentLevelId)[0];
                        if(levelState){
                            levelStateData = levelState.state;
                        }
                        else{
                            levelStateData = "Not Examined";
                        }
    
                        levelData.push({
                            PAName: currentPA,
                            Level: availableLevels[j],
                            State: levelStateData
                        });
                    }
                }

                PAData.push({
                    Name: currentPA,
                    State: paStateData
                })
            }
            let ratingsData = {
                PAData: PAData,
                LevelData: levelData
            }

            setRatingsData(ratingsData);
        }

        setReportLoading(false);
        setOptionsVisible(false);
        props.setShowModal(false);
    }

    const handleGenerateReportClicked = async (event) => {
        setReportLoading(true);

        let allProjects = projects.value;

        //do not generate if we don't have a rollup
        let hasRollup = false;
        let rollupId = 0;
        for (let i = 0; i < allProjects.length; i++) {
            if (allProjects[i].name === "Organizational Roll-up") {
                hasRollup = true;
                rollupId = allProjects[i].id;
            }
        }

        if (!hasRollup) {
            setModalMessage("No organizational roll-up found.")
            setShowMessageModal(true);
        }
        else {
            await generateReport(rollupId);
        }

        setReportLoading(false);
    }

    const reportName = `Ratings Report`;
    const scopeHeader = 'Level:';
    const appraisalHeader = 'Appraisal';

    const viewReportStyle = {
        textAlign: "center",
        paddingTop: "0px",
        color: 'black'
    }

    return (
        <React.Fragment>
            <Modal show={props.showModal} onHide={(e) => props.setShowModal(false)} dialogClassName="reportModalStyle">
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '18px', color: 'dimgray' }}>
                        <img
                            src={require('../assets/icon.png')}
                            width="30"
                            height="30"
                            className="d-inline-block align-middle"
                            alt="CAP logo"
                        />{' '}Ratings Report</Modal.Title>
                </Modal.Header>
                <LoadingComponent isLoading={dataLoading} iconSize={80}>
                    <ReportFragment visible={optionsVisible}>
                        <div style={{ display: "grid", gridTemplateColumns: "10% 80% 10%", gridTemplateRows: "100%", marginTop: '-50px' }}>
                            <div style={{ gridColumnStart: "2", gridRowStart: "1" }}>
                                <Form>
                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <Form.Label column md='3' style={{ color: 'white', backgroundColor: 'rgb(0,168,168)', fontSize: '16px', textAlign: 'center', padding: '.5rem' }}>
                                            Appraisal
                                        </Form.Label>
                                        <Col md={6} style={{ textAlign: 'left' }}>
                                            <Form.Control plaintext readOnly value={appraisalData.name} style={{ color: 'dimgray' }} />
                                        </Col>
                                    </Form.Group>
                                    <Row className="d-flex justify-content-center">
                                        <Button
                                            variant="outline-light"
                                            className='text-nowrap'
                                            style={{
                                                background: 'rgb(0, 168, 168)',
                                                color: 'white',
                                                border: 'none',
                                                justifyContent: 'center',
                                                padding: '.5rem',
                                                marginTop: '1rem',
                                                marginBottom: '1rem'
                                            }}
                                            onClick={(e) => handleGenerateReportClicked(e)}
                                        >
                                            {reportLoading &&
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size='sm'
                                                    role="status"
                                                    aria-hidden="true"
                                                />}
                                            {!reportLoading ? "Generate Ratings Report" : ' Generating Ratings Report'}
                                        </Button>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                        <Modal bsSize="small"
                                show={showMessageModal}
                                onHide={(e) => setShowMessageModal(false)}
                                animation
                                style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
                                size='sm'>
                                <Modal.Header>
                                    <Modal.Title className='w-100 text-center' style={{ fontSize: '16px', color: 'dimgray' }}>{modalMessage}</Modal.Title>
                                </Modal.Header>
                                <Modal.Footer style={{ backgroundColor: 'rgb(0,168,168)' }}>
                                    <Button
                                        variant="outline-light"
                                        size='sm'
                                        onClick={(event) => setShowMessageModal(false)}
                                    >Ok</Button>
                                </Modal.Footer>
                            </Modal>
                    </ReportFragment>

                </LoadingComponent>
            </Modal>

            {!optionsVisible &&
                <NewWindow>
                    <ReportFragment
                        reportName={reportName}
                        reportHeaderStyle={viewReportStyle}
                        scopeHeader={scopeHeader}
                        scope={appraisalData.view}
                        appraisalName={appraisalData.name}
                        appraisalHeader={appraisalHeader}>
                        <RatingsReportView
                            ratingsData={ratingsData} />
                    </ReportFragment>
                </NewWindow>
            }
        </React.Fragment>
    );
}

export default RatingsReportModal;