import { API } from 'aws-amplify';
import { APIResult, convertToFrontEnd, convertToBackEnd, getDefaultAPIHeaders, API_NAME } from './apiUtils';
import { PRACTICE_AREAS } from '../../model/modelcontent';
import arrayEquals from '../libs';

const API_PATH = '/appraisals';

const APPRAISAL_DATA_MAPPING = {
    id: "appraisal_id",
    name: "appraisal_name",
    projects: "projects",
    view: "appraisal_view",
    organization: "organization_id"
}

const PROJECT_DATA_MAPPING = {
    name: "project_name",
    practiceAreas: "practice_areas",
    id: "project_id"
}

const DEFAULT_VALUES = {
    markedForDelete: false
}

const PA_DEFAULT_VALUES = {
    SelectedForAdd: false,
    SelectedForRemove: false,
    MappedByAnyProject: false
}

function getEditBody(beforeEdit, afterEdit){
    let body = {};
    //TODO - Uncomment and remove code below when the edit paradigm has been completely
    // shifted over to only passing changed values.
    // if(beforeEdit.name !== afterEdit.name){
    //     body = {...body, appraisal_name: afterEdit.name};
    // }
    // if(beforeEdit.organization !== afterEdit.organization){
    //     body = {...body, organization_id: afterEdit.organization};
    // }
    // if(beforeEdit.view !== afterEdit.view){
    //     body = {...body, appraisal_view: afterEdit.view };
    // }
    body = {...body, appraisal_name: afterEdit.name, organization_id: afterEdit.organization, appraisal_view: afterEdit.view};
    //End TODO
    const added = afterEdit.projects.filter(p=>{
        return beforeEdit.projects.find(bep=>bep.id === p.id) === undefined
    });
    if(added.length > 0){
        const projects = added.map(p=>{
            return{
                project_name: p.name,
                project_id: p.id,
                practice_areas: p.practiceAreas.map(pa=>{
                    return pa.Id;
                })
            }
        });
        body = {...body, added: projects};
    }
    const deleted = beforeEdit.projects.filter(p=>{
        return afterEdit.projects.find(bep=>bep.id === p.id) === undefined
    });
    if(deleted.length > 0) {
        const projects = deleted.map(p=>p.id);
        body = {...body, deleted: projects};
    }
    let modified = [];
    const foundProject = (projects, project) => {
        return projects.find(p=>p.id === project.id);
    }
    for(let i = 0; i < beforeEdit.projects.length; i++){
        let project = foundProject(afterEdit.projects, beforeEdit.projects[i]);
        if(project){
            const beforePAs = beforeEdit.projects[i].practiceAreas.map(pa=>pa.Id);
            const afterPAs = project.practiceAreas.map(pa=>pa.Id);
            const beforeName = beforeEdit.projects[i].name;
            const afterName = project.name;

            if(!arrayEquals(beforePAs, afterPAs) || beforeName !== afterName){
                modified.push(project);
            }
        }
    }
    if(modified.length > 0){
        const projects = modified.map(p=>{
            return{
                project_name: p.name,
                project_id: p.id,
                practice_areas: p.practiceAreas.map(pa=>{
                    return pa.Id
                })
            }
        });
        body = {...body, modified: projects};
    }
    return body;
}

class AppraisalRepository {
    constructor(defaultValues = DEFAULT_VALUES, paDefaultValues = PA_DEFAULT_VALUES, projectDefaultValues = null){
        this.defaultValues = DEFAULT_VALUES;
        this.paDefaultValues = paDefaultValues;
        this.projectDefaultValues = projectDefaultValues;
    }

    async getAll(){
        const resolved = API.get(API_NAME, API_PATH, await getDefaultAPIHeaders())
            .then(d=>{
                const retVal = d.map(i=>{
                    let newItem = convertToFrontEnd(APPRAISAL_DATA_MAPPING, i);

                    if(this.defaultValues){
                        newItem = {
                            ...newItem,
                            ...this.defaultValues
                        }
                    }
                    newItem.projects = [];
                    return newItem;
                });
                return new APIResult(false, retVal);
            }).catch(err=>{
                return new APIResult(true, err);
            });
        return resolved;
    }

    async getOne(id, getPracticeAreaInfo = true){
        const resolved = API.get(API_NAME, `${API_PATH}/${id}`, await getDefaultAPIHeaders())
            .then(data=>{
                let newItem = convertToFrontEnd(APPRAISAL_DATA_MAPPING, data);
                newItem.projects = newItem.projects.map(p=>{
                    let newProject = convertToFrontEnd(PROJECT_DATA_MAPPING, p);
                    if(this.projectDefaultValues){
                        newProject = {
                            ...newProject,
                            ...this.projectDefaultValues
                        }
                    }
                    return newProject;
                });
                if(getPracticeAreaInfo){
                    newItem.projects.forEach(p=>{
                        p.practiceAreas = p.practiceAreas.map(pa=>{
                            let newPa = {
                                ...PRACTICE_AREAS[pa]
                            };
                            if(this.paDefaultValues){
                                newPa = {
                                    ...newPa,
                                    ...this.paDefaultValues
                                }
                            }
                            return newPa;
                        });
                    });
                }
                return new APIResult(false, newItem);
            }).catch(err=>{
                return new APIResult(true, err);
            });
        return resolved;
    }

    async createOne(item){
        let newItem = { ...item };
        let projectItems = newItem.projects.map(p=>{
            return convertToBackEnd(PROJECT_DATA_MAPPING, p)
        });
        projectItems.forEach(p=>{
            p.practice_areas = p.practice_areas.map(pa=>pa.Id);
        })
        newItem.projects = projectItems;
        const fullOrg = newItem.organization;
        newItem.organization = newItem.organization.id;
        const body = convertToBackEnd(APPRAISAL_DATA_MAPPING, newItem);

        const resolved = API.post(API_NAME, API_PATH, await getDefaultAPIHeaders(body))
            .then(data=>{
                let retVal = convertToFrontEnd(APPRAISAL_DATA_MAPPING, data);
                retVal = {
                    ...retVal,
                    organization: fullOrg,
                    projects: []
                };
                if(this.defaultValues){
                    retVal = {
                        ...retVal,
                        ...this.defaultValues
                    }
                }
                return new APIResult(false, retVal);
            }).catch(err=>{
                return new APIResult(true, err);
            });
        return resolved;
    }

    async updateOne(itemBefore, itemAfter){
        const editBody = getEditBody(itemBefore, itemAfter);
        const resolved = API.put(API_NAME, `${API_PATH}/${itemBefore.id}`, await getDefaultAPIHeaders(editBody))
            .then(data=>{
                return new APIResult(false, data);
            }).catch(err=>{
                return new APIResult(true, err);
            });
        return resolved;
    }

    async deleteOne(id){
        const resolved = API.del(API_NAME, `${API_PATH}/${id}`, await getDefaultAPIHeaders())
            .then(data=>{
                return new APIResult(false, null);
            }).catch(err=>{
                return new APIResult(true, err);
            });
        return resolved;
    }
}

export { AppraisalRepository };