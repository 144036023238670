import React, { useState, useEffect } from 'react';
import { Button, Spinner, Row, Col, Form, FormControl, Modal } from 'react-bootstrap';
import { modelContentUtils } from '../utils/helpers';
import { AppraisalRepository } from '../utils/repositories';
import useArray from '../hooks/useArray';
import LoadingComponent from '../presenters/LoadingComponent';
import ReportFragment from '../presenters/ReportFragment';
import MultiSelectListReportOptions from '../presenters/MultiSelectListReportOptions';
import FindingsReportView from '../presenters/FindingsReportView';
import { PRACTICE_AREAS } from '../model/practiceareas';
import { PRACTICE_AREA_RELATIONSHIPS } from '../model/practicearearelationships';
import { PRACTICES } from '../model/practices';
import { Constants, getDefaultAPIHeaders, getCurrentDateTime } from '../utils/helpers';
import { API } from 'aws-amplify';
import NewWindow from 'react-new-window'
import { CSVLink } from "react-csv";

async function fetchData(setDataLoading, setAppraisalName, projects, practiceAreas, setMaturityLevel) {
    const currentAppraisalId = localStorage.getItem("selectedAppraisal");
    if (currentAppraisalId) {
        const apiRepo = new AppraisalRepository(null, null, { selected: false, availableForSelection: false });
        const appraisalData = await apiRepo.getOne(currentAppraisalId, false);
        if (!appraisalData.error) {
            projects.setValue(appraisalData.returnValue.projects);
            setAppraisalName(appraisalData.returnValue.name);
            setMaturityLevel(modelContentUtils.getMaturityLevelFromView(appraisalData.returnValue.view));
            const formattedPracticeAreas = modelContentUtils.getPracticeAreasFromAppraisalProjects(appraisalData.returnValue.projects, true, true)
                .map(pa => {
                    return {
                        ...pa,
                        selected: false,
                        availableForSelection: false
                    }
                });
            practiceAreas.setValue(formattedPracticeAreas);
        } else {
            console.log("error getting appraisal data: ", appraisalData.returnValue);
        }
    }

    setDataLoading(false);
}

const FindingsReportModal = (props) => {
    const [dataLoading, setDataLoading] = useState(true);
    const [optionsVisible, setOptionsVisible] = useState(true);
    const [practiceOptionsVisible, setPracticeOptionsVisible] = useState(true);
    const [appraisalName, setAppraisalName] = useState('');
    const [reportLoading, setReportLoading] = useState(false);
    const [csvLoading, setCSVLoading] = useState(false);
    const [showCSV, setShowCSV] = useState(false);
    const [findingsData, setFindingsData] = useState({});
    const [csvData, setCSVData] = useState({});
    const [csvFilename, setCSVFilename] = useState({});
    const [findingsScopeValue, setFindingsScopeValue] = useState("practiceFindings");
    const [hasProjectSelected, setHasProjectSelected] = useState("false");
    const [hasPracticeAreaSelected, setHasPracticeAreaSelected] = useState("false");
    const [includeCharacterizations, setIncludeCharacterizations] = useState("true");
    const [includeNotes, setIncludeNotes] = useState("true");
    const [includeStrengths, setIncludeStrengths] = useState("false");
    const [includeWeaknesses, setIncludeWeaknesses] = useState("false");
    const [includeIOs, setIncludeIOs] = useState("false");
    const [showLevelOnePractices, setShowLevelOnePractices] = useState("false");
    const projects = useArray([]);
    const practiceAreas = useArray([]);
    const [maturityLevel, setMaturityLevel] = useState(null);

    useEffect(() => {
        if (props.showModal) {
            setDataLoading(true);
            const getData = () => fetchData(setDataLoading, setAppraisalName, projects, practiceAreas, setMaturityLevel);
            getData();
            setShowCSV(false);
            setOptionsVisible(true);
            setPracticeOptionsVisible(true);
            setHasProjectSelected(false);
            setHasPracticeAreaSelected(false);
            setIncludeCharacterizations(true);
            setIncludeNotes(true);
            setIncludeStrengths(false);
            setIncludeWeaknesses(false);
            setIncludeIOs(false);
            setShowLevelOnePractices(false);
            setFindingsScopeValue("practiceFindings");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showModal]);

    const handleProjectSelectionChanged = (event) => {
        const allOptions = event.target.options;
        let selectedProjectIds = [];
        for (let i = 0; i < allOptions.length; i++) {
            const currentOption = allOptions[i];
            let project = projects.getById(currentOption.value);
            if (currentOption.selected) {
                selectedProjectIds.push(currentOption.value);
            }
            project.selected = currentOption.selected;
            projects.replace(currentOption.value, project);
        }

        const selectedProjectPracticeAreaIds = projects.value.filter(p => selectedProjectIds.includes(p.id))
            .map(p => p.practiceAreas).flat();
        const newPracticeAreas = practiceAreas.value.slice()
            .map(pa => {
                if (selectedProjectPracticeAreaIds.includes(pa.id)) {
                    return {
                        ...pa,
                        availableForSelection: true
                    }
                } else {
                    return {
                        ...pa,
                        availableForSelection: false,
                        selected: false
                    }
                }
            });
        practiceAreas.setValue(newPracticeAreas);

        setHasProjectSelected(projects.value.filter(p => p.selected === true).length > 0 ? true : false);

        let anyPASelected = false;
        newPracticeAreas.forEach(d => {
            if (d.selected) {
                anyPASelected = true;
            }
        });
        setHasPracticeAreaSelected(anyPASelected);
        setShowCSV(false);
    }

    const handlePracticeAreaSelectionChanged = (event) => {
        const allOptions = event.target.options;
        let selectedPracticeAreaIds = [];
        for (let i = 0; i < allOptions.length; i++) {
            const currentOption = allOptions[i];
            if (currentOption.selected) {
                selectedPracticeAreaIds.push(currentOption.value);
            }
        }
        const newPracticeAreas = practiceAreas.value.slice()
            .map(pa => {
                if (selectedPracticeAreaIds.includes(pa.id)) {
                    return {
                        ...pa,
                        selected: true
                    }
                } else {
                    return {
                        ...pa,
                        selected: false
                    }
                }
            });
        practiceAreas.setValue(newPracticeAreas);

        setHasPracticeAreaSelected(selectedPracticeAreaIds.length > 0 ? true : false);
        setShowCSV(false);
    }

    async function getAllNotesData(currentAppraisalId, selectedProjects, selectedPracticeAreas) {
        let notesInfo = [];
        const apiPath = `${Constants.APPRAISAL_ITEM_PATH}/${currentAppraisalId}`
        const data = await API.get(Constants.API_PATH, apiPath, await getDefaultAPIHeaders());

        for (let i = 0; i < selectedProjects.length; i++) {
            const currentProject = selectedProjects[i];

            let PAs = [];

            for (let i = 0; i < selectedPracticeAreas.length; i++) {
                const practiceAreaName = [selectedPracticeAreas[i].name];
                let PANotes = "";
                let hasAnyPAData = false;

                //practice area notes
                data.forEach(d => {
                    if (d.project_id === currentProject.id && d.node_id === selectedPracticeAreas[i].id && d.item_type === "notes") {
                        PANotes = d.item_data;
                        hasAnyPAData = true;
                    }
                });

                //get the node ids for each level/node in the practice area
                const practiceNodes = PRACTICE_AREA_RELATIONSHIPS[selectedPracticeAreas[i].id];
                const practiceAreaLevelIDs = PRACTICE_AREAS[selectedPracticeAreas[i].id].LevelIds;

                let currentLevel;
                let levelData = [];
                let practices = [];
                let hasPracticeData = false;
                let hasLevelData = false;
                let levelNotes = "";
                let levelString = "";

                for (let i = 0; i < practiceNodes.length; i++) {

                    const level = PRACTICES[practiceNodes[i].Id].Level;

                    if(level === 1 && !showLevelOnePractices){
                        continue;
                    }

                    if (currentLevel !== level) {

                        //add data when level changes
                        if (currentLevel) {
                            if (hasLevelData || hasPracticeData) {
                                levelData.push({
                                    Name: levelString,
                                    Notes: levelNotes,
                                    Practices: practices
                                })
                            }
                        }

                        hasPracticeData = false;
                        hasLevelData = false;
                        levelNotes = "";
                        const practiceGroupID = practiceAreaLevelIDs[level];
                        for (let j = 0; j < data.length; j++) {
                            if (data[j].project_id === currentProject.id && data[j].node_id === practiceGroupID && data[j].item_type === "notes") {
                                levelNotes = data[j].item_data;
                                hasLevelData = true;
                                hasAnyPAData = true;
                            }
                        }

                        levelString = "Level " + level;
                        practices = [];
                        currentLevel = level;
                    }

                    //get the friendly abbreviation for the practice node
                    const practice = PRACTICES[practiceNodes[i].Id].Abbreviation;
                    let practiceNotes = "";

                    for (let j = 0; j < data.length; j++) {
                        if (data[j].project_id === currentProject.id && data[j].node_id === practiceNodes[i].Id && data[j].item_type === "notes") {
                            practiceNotes = data[j].item_data;
                            hasPracticeData = true;
                            hasAnyPAData = true;
                        }
                    }

                    if (practiceNotes !== "") {
                        practices.push({
                            PracticeName: practice,
                            Notes: practiceNotes
                        })
                    }
                }

                //add the remaining data
                if (hasLevelData || hasPracticeData) {
                    levelData.push({
                        Name: levelString,
                        Notes: levelNotes,
                        Practices: practices
                    })
                }

                if (hasAnyPAData) {
                    PAs.push({
                        Name: practiceAreaName,
                        Notes: PANotes,
                        LevelData: levelData
                    })
                }
                hasAnyPAData = false;
            }

            if (PAs.length > 0) {
                notesInfo.push({
                    ProjectName: currentProject.name,
                    PAData: PAs
                })
            }
        }

        return notesInfo;
    }

    async function getPracticeAreaNotesData(currentAppraisalId, selectedProjects, selectedPracticeAreas) {
        let notesInfo = [];
        const apiPath = `${Constants.APPRAISAL_ITEM_PATH}/${currentAppraisalId}`
        const data = await API.get(Constants.API_PATH, apiPath, await getDefaultAPIHeaders());

        for (let i = 0; i < selectedProjects.length; i++) {
            const currentProject = selectedProjects[i];

            let PAs = [];
            for (let i = 0; i < selectedPracticeAreas.length; i++) {
                const practiceAreaName = [selectedPracticeAreas[i].name]

                data.forEach(d => {
                    if (d.project_id === currentProject.id && d.node_id === selectedPracticeAreas[i].id && d.item_type === "notes") {
                        PAs.push({
                            Name: practiceAreaName,
                            Notes: d.item_data
                        })
                    }
                });
            }
            if (PAs.length > 0) {
                notesInfo.push({
                    ProjectName: currentProject.name,
                    PAData: PAs
                });
            }
        }

        return notesInfo;
    }

    async function getPracticeGroupNotesData(currentAppraisalId, selectedProjects, selectedPracticeAreas) {
        let notesInfo = [];
        const apiPath = `${Constants.APPRAISAL_ITEM_PATH}/${currentAppraisalId}`
        const data = await API.get(Constants.API_PATH, apiPath, await getDefaultAPIHeaders());

        for (let i = 0; i < selectedProjects.length; i++) {
            const currentProject = selectedProjects[i];

            let PAs = [];
            for (let i = 0; i < selectedPracticeAreas.length; i++) {
                const practiceAreaName = [selectedPracticeAreas[i].name]

                //iterate through each practice group (level)
                let levelData = [];
                let hasLevelData = false;
                const practiceAreaLevelIDs = PRACTICE_AREAS[selectedPracticeAreas[i].id].LevelIds;
                for (var key in practiceAreaLevelIDs) {

                    if(key === "1" && !showLevelOnePractices){
                        continue;
                    }

                    const practiceGroupID = practiceAreaLevelIDs[key];
                    const levelString = "Level " + key;

                    for (let z = 0; z < data.length; z++) {
                        if (data[z].project_id === currentProject.id && data[z].node_id === practiceGroupID && data[z].item_type === "notes") {
                            levelData.push({
                                Name: levelString,
                                Notes: data[z].item_data
                            });
                            hasLevelData = true;
                        }
                    }
                }

                if (hasLevelData) {
                    PAs.push({
                        Name: practiceAreaName,
                        LevelData: levelData
                    })
                }
            }
            if (PAs.length > 0) {
                notesInfo.push({
                    ProjectName: currentProject.name,
                    PAData: PAs
                });
            }
        }

        return notesInfo;
    }

    async function getPracticeFindingsData(currentAppraisalId, selectedProjects, selectedPracticeAreas) {

        const notesApiPath = `${Constants.APPRAISAL_ITEM_PATH}/${currentAppraisalId}`
        const itemData = await API.get(Constants.API_PATH, notesApiPath, await getDefaultAPIHeaders());

        const stateApiPath = `${Constants.APPRAISAL_STATE_PATH}/${currentAppraisalId}`
        const stateData = await API.get(Constants.API_PATH, stateApiPath, await getDefaultAPIHeaders());

        let findingsInfo = [];
        for (let i = 0; i < selectedProjects.length; i++) {
            const currentProject = selectedProjects[i];

            let practiceAreas = [];
            let projectHasData = false;

            for (let j = 0; j < currentProject.practiceAreas.length; j++) {
                const practiceAreaName = currentProject.practiceAreas[j];

                let practices = [];
                for (let z = 0; z < selectedPracticeAreas.length; z++) {
                    if (selectedPracticeAreas[z].id === practiceAreaName) {

                        let PAHasData = false;

                        //get the node ids for each node in the practice area
                        let practiceNodes = [];
                        if(showLevelOnePractices){
                            practiceNodes = PRACTICE_AREA_RELATIONSHIPS[currentProject.practiceAreas[j]].filter(x => x.Level <= maturityLevel);
                        }
                        else{
                            practiceNodes = PRACTICE_AREA_RELATIONSHIPS[currentProject.practiceAreas[j]].filter(x => x.Level > 1 && x.Level <= maturityLevel);
                        }

                        for (let k = 0; k < practiceNodes.length; k++) {

                            //get the friendly abbreviation for the practice node
                            const practice = PRACTICES[practiceNodes[k].Id].Abbreviation;

                            let characterization = "";
                            stateData.forEach(d => {
                                if (d.project_id === currentProject.id && d.node_id === practiceNodes[k].Id) {
                                    characterization = d.node_state;
                                }
                            });

                            let statement = PRACTICES[practiceNodes[k].Id].Statement;
                            let notes = "";
                            let strengths = "";
                            let weaknesses = "";
                            let ios = "";

                            let practiceHasData = false;

                            itemData.forEach(d => {
                                if (d.project_id === currentProject.id && d.node_id === practiceNodes[k].Id) {
                                    if (d.item_type === "notes") {
                                        notes = d.item_data;
                                        if (includeNotes) {
                                            practiceHasData = true;
                                        }
                                    }
                                    else if (d.item_type === "strengths") {
                                        strengths = d.item_data;
                                        if (includeStrengths) {
                                            practiceHasData = true;
                                        }
                                    }
                                    else if (d.item_type === "weaknesses") {
                                        weaknesses = d.item_data;
                                        if (includeWeaknesses) {
                                            practiceHasData = true;
                                        }
                                    }
                                    else if (d.item_type === "improvement_opportunities") {
                                        ios = d.item_data;
                                        if (includeIOs) {
                                            practiceHasData = true;
                                        }
                                    }
                                }
                            });

                            if (practiceHasData) {
                                PAHasData = true;
                            }

                            //push the data for this node to the collection if criteria is met
                            if (includeCharacterizations || practiceHasData) {
                                practices.push({
                                    PracticeName: practice,
                                    Statement: statement,
                                    Characterization: characterization,
                                    Notes: notes,
                                    Strengths: strengths,
                                    Weaknesses: weaknesses,
                                    IOs: ios
                                });
                            }
                        }
                        if (PAHasData) {
                            projectHasData = true;
                        }

                        if (includeCharacterizations || PAHasData) {
                            practiceAreas.push({
                                PracticeAreaName: practiceAreaName,
                                Practices: practices
                            });
                        }
                    }
                }
            }
            if (includeCharacterizations || projectHasData) {
                findingsInfo.push({
                    ProjectName: currentProject.name,
                    PracticeAreas: practiceAreas
                });
            }
        }
        return findingsInfo;
    }

    const getFindingsData = async () => {

        let currentAppraisalId = localStorage.getItem("selectedAppraisal");
        let selectedProjects = projects.value.filter(p => p.selected === true);
        let selectedPracticeAreas = practiceAreas.value.filter(p => p.selected === true);
        let findingsData = [];

        switch (findingsScopeValue) {
            case "allFindings":
                findingsData = await getAllNotesData(currentAppraisalId, selectedProjects, selectedPracticeAreas);
                break;

            case "practiceAreaFindings":
                findingsData = await getPracticeAreaNotesData(currentAppraisalId, selectedProjects, selectedPracticeAreas);
                break;

            case "practiceGroupFindings":
                findingsData = await getPracticeGroupNotesData(currentAppraisalId, selectedProjects, selectedPracticeAreas);
                break;

            case "practiceFindings":
                findingsData = await getPracticeFindingsData(currentAppraisalId, selectedProjects, selectedPracticeAreas);
                break;

            default: break;
        }

        return findingsData;
    }

    const handleGenerateReportClicked = async (event) => {
        setReportLoading(true);

        let findingsData = await getFindingsData();
        setFindingsData(findingsData);

        setReportLoading(false);
        setOptionsVisible(false);
        props.setShowModal(false);
    }

    const handleGenerateCSVClicked = async (event) => {
        setCSVLoading(true);

        let findingsData = await getFindingsData();
        let csvData = formatCSVData(findingsData);
        setCSVData(csvData);

        setCSVLoading(false);
        setShowCSV(true);
    }

    let dateTime = getCurrentDateTime();

    function formatCSVData(findingsData) {
        let csvRows = [];
        let filename = "";

        switch (findingsScopeValue) {
            case "allFindings":
                csvRows.push(["Project", "Practice Area", "Findings Item", "Note"]);

                for (let i = 0; i < findingsData.length; i++) {
                    let PAs = findingsData[i].PAData;

                    for (let j = 0; j < PAs.length; j++) {
                        if (PAs[j].Notes !== "") {
                            csvRows.push([findingsData[i].ProjectName, PAs[j].Name, PAs[j].Name, PAs[j].Notes]);
                        }

                        let levelData = PAs[j].LevelData;
                        for (let k = 0; k < levelData.length; k++) {

                            if (levelData[k].Notes !== "") {
                                csvRows.push([findingsData[i].ProjectName, PAs[j].Name, levelData[k].Name, levelData[k].Notes]);
                            }

                            let practices = levelData[k].Practices;
                            for (let l = 0; l < practices.length; l++) {
                                csvRows.push([findingsData[i].ProjectName, PAs[j].Name, practices[l].PracticeName, practices[l].Notes]);
                            }
                        }
                    }
                }

                filename = "allNotesReport_" + dateTime + ".csv";
                break;

            case "practiceAreaFindings":
                csvRows.push(["Project", "Practice Area", "Note"]);
                for (let i = 0; i < findingsData.length; i++) {
                    let PAs = findingsData[i].PAData;
                    for (let j = 0; j < PAs.length; j++) {
                        csvRows.push([findingsData[i].ProjectName, PAs[j].Name, PAs[j].Notes]);
                    }
                }
                filename = "practiceAreaNotesReport_" + dateTime + ".csv";
                break;

            case "practiceGroupFindings":
                csvRows.push(["Project", "Practice Area", "Practice Group", "Note"]);

                for (let i = 0; i < findingsData.length; i++) {
                    let PAs = findingsData[i].PAData;
                    for (let j = 0; j < PAs.length; j++) {
                        let levelData = PAs[j].LevelData;
                        for (let k = 0; k < levelData.length; k++) {
                            csvRows.push([findingsData[i].ProjectName, PAs[j].Name, levelData[k].Name, levelData[k].Notes]);
                        }
                    }
                }

                filename = "practiceGroupNotesReport_" + dateTime + ".csv";
                break;

            case "practiceFindings":

                csvRows.push(getHeaderBasedOnPracticeOptions());

                for (let i = 0; i < findingsData.length; i++) {
                    let project = findingsData[i];
                    let PAs = project.PracticeAreas;

                    for (let j = 0; j < PAs.length; j++) {
                        let practices = PAs[j].Practices;

                        for (let k = 0; k < practices.length; k++) {
                            let practice = practices[k];

                            let csvRow = getCSVRowBasedOnOptions(project.ProjectName, PAs[j].PracticeAreaName, practice);
                            csvRows.push(csvRow);
                        }
                    }
                }
                filename = "practiceFindingsReport_" + dateTime + ".csv";
                break;


            default: break;
        }
        setCSVFilename(filename);
        return csvRows;
    }

    const getHeaderBasedOnPracticeOptions = () => {
        let header = ["Project", "Practice Area", "Practice", "Practice Statement"];
        if (includeCharacterizations) { header.push("Characterization"); }
        if (includeNotes) { header.push("Notes"); }
        if (includeStrengths) { header.push("Strengths"); }
        if (includeWeaknesses) { header.push("Weaknesses"); }
        if (includeIOs) { header.push("Improvement Opportunities"); }
        return header;
    }

    const getCSVRowBasedOnOptions = (projectName, PAName, practice) => {
        let row = [projectName, PAName, practice.PracticeName, practice.Statement];
        if (includeCharacterizations) { row.push(cleanCSVData(practice.Characterization)); }
        if (includeNotes) { row.push(cleanCSVData(practice.Notes)); }
        if (includeStrengths) { row.push(cleanCSVData(practice.Strengths)); }
        if (includeWeaknesses) { row.push(cleanCSVData(practice.Weaknesses)); }
        if (includeIOs) { row.push(cleanCSVData(practice.IOs)); }
        return row;
    }

    const cleanCSVData = (entry) => {
        return entry.replace(/"/g, '\'');
    }

    const handleFindingsScopeChanged = (value) => {
        setFindingsScopeValue(value);

        if (value === "practiceFindings") {
            setPracticeOptionsVisible(true);
            setIncludeCharacterizations(true);
            setIncludeNotes(true);
            setIncludeStrengths(false);
            setIncludeWeaknesses(false);
            setIncludeIOs(false);
        }
        else {
            setIncludeNotes(true);
            setPracticeOptionsVisible(false);
        }

        setShowCSV(false);
    }

    const hasReportOptionSelected = () => {
        return includeCharacterizations || includeNotes || includeStrengths || includeWeaknesses || includeIOs;
    }

    const projectSelectionDisplayOptions = {
        title: "Projects",
        displayKey: "name",
        filterAvailable: false
    };

    const practiceAreaSelectionDisplayOptions = {
        title: "Practice Areas",
        displayKey: "name",
        filterAvailable: true,
        availableKey: "availableForSelection",
        size: 25
    };

    const scopeHeader = 'Scope:';
    const appraisalHeader = 'Appraisal:';
    const reportName = 'Findings Report';

    const viewReportSubTitle = () => {
        let type = findingsScopeValue === 'allFindings' ? 'All' :
            findingsScopeValue === 'practiceAreaFindings' ? 'Practice Area' :
                findingsScopeValue === 'practiceGroupFindings' ? 'Practice Group' : 'Practice';
        return type;
    }

    const getScopeOptionsList = () => {
        return (
            <React.Fragment>
                <option value='practiceFindings' selected='true'>Practice</option>
                <option value='practiceGroupFindings'>Practice Group</option>
                <option value='practiceAreaFindings'>Practice Area</option>
                <option value='allFindings' >All</option>
            </React.Fragment>
        )
    }

    const characterizationsCheckBoxChanged = () => {
        setIncludeCharacterizations(!includeCharacterizations);
        setShowCSV(false);
    }
    const notesCheckBoxChanged = () => {
        setIncludeNotes(!includeNotes);
        setShowCSV(false);
    }
    const strengthsCheckBoxChanged = () => {
        setIncludeStrengths(!includeStrengths);
        setShowCSV(false);
    }
    const weaknessesCheckBoxChanged = () => {
        setIncludeWeaknesses(!includeWeaknesses);
        setShowCSV(false);
    }
    const iosCheckBoxChanged = () => {
        setIncludeIOs(!includeIOs);
        setShowCSV(false);
    }

    const levelOneCheckBoxChanged = () => {
        setShowLevelOnePractices(!showLevelOnePractices);
    }
    
    const getReportOptions = () => {
        return {
            HasCharacterizations: includeCharacterizations,
            HasNotes: includeNotes,
            HasStrengths: includeStrengths,
            HasWeaknesses: includeWeaknesses,
            HasIOs: includeIOs
        }
    }

    const spacingStyle = {
        marginBottom: "10px",
        marginLeft: "20%",
        fontSize: "16px",
        justifyContent: 'center',
        alignItems: 'center'
    };

    const viewReportStyle = {
        textAlign: "right",
        paddingTop: "0px",
        color: 'black',
        marginTop: '16px',
        fontSize: '22px'
    }

    return (
        <React.Fragment>
            <Modal show={props.showModal} onHide={(e) => props.setShowModal(false)} dialogClassName="findingsReportModalStyle">
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '18px', color: 'dimgray' }}>
                        <img
                            src={require('../assets/icon.png')}
                            width="30"
                            height="30"
                            className="d-inline-block align-middle"
                            alt="CAP logo"
                        />{' '}Findings Report</Modal.Title>
                </Modal.Header>
                <LoadingComponent isLoading={dataLoading} iconSize={80}>
                    <ReportFragment >
                        <Row className="d-flex justify-content-center" style={{ marginTop: '-50px' }}>
                            <Col md={6}>
                                <Form>
                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <Form.Label
                                            column sm='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                padding: '.5rem'
                                            }}>
                                            Appraisal
                                        </Form.Label>
                                        <Col sm={6} style={{ textAlign: 'left' }}>
                                            <Form.Control plaintext readOnly value={appraisalName} style={{ color: 'dimgray', paddingLeft: '1rem' }} />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <Form.Label
                                            column sm='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                padding: '.5rem'
                                            }}>
                                            Scope
                                        </Form.Label>
                                        <Col sm={6} style={{ textAlign: 'left' }}>
                                            <FormControl
                                                as="select"
                                                style={{ borderRightColor: 'white', borderTopColor: 'white' }}
                                                onChange={(e) => handleFindingsScopeChanged(e.target.value)}>
                                                {getScopeOptionsList()}
                                            </FormControl>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <Form.Label
                                            column sm='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                padding: '.5rem'
                                            }}>
                                            Report Options
                                        </Form.Label>
                                        <Col sm={6} style={{ textAlign: 'left' }}>
                                            {practiceOptionsVisible &&
                                                <Form.Check type="checkbox" label="Characterizations"
                                                    style={{ color: "dimgray" }}
                                                    checked={includeCharacterizations}
                                                    onChange={characterizationsCheckBoxChanged}
                                                />
                                            }
                                            <Form.Check type="checkbox" label="Notes"
                                                disabled={findingsScopeValue !== 'practiceFindings'}
                                                style={{ color: "dimgray" }}
                                                checked={includeNotes}
                                                onChange={notesCheckBoxChanged}
                                            />
                                            {practiceOptionsVisible &&
                                                <Form.Check type="checkbox" label="Strengths"
                                                    style={{ color: "dimgray" }}
                                                    checked={includeStrengths}
                                                    onChange={strengthsCheckBoxChanged}
                                                />
                                            }
                                            {practiceOptionsVisible &&
                                                <Form.Check type="checkbox" label="Weaknesses"
                                                    style={{ color: "dimgray" }}
                                                    checked={includeWeaknesses}
                                                    onChange={weaknessesCheckBoxChanged}
                                                />
                                            }
                                            {practiceOptionsVisible &&
                                                <Form.Check type="checkbox" label="Improvement Opportunities"
                                                    style={{ color: "dimgray" }}
                                                    checked={includeIOs}
                                                    onChange={iosCheckBoxChanged}
                                                />
                                            }
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <Form.Label
                                            column sm='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                padding: '.5rem'
                                            }}>
                                            Projects
                                        </Form.Label>
                                        <Col sm={6} style={{ textAlign: 'left' }}>
                                            <MultiSelectListReportOptions listData={projects.value} style={spacingStyle}
                                                handleSelectionChanged={handleProjectSelectionChanged}
                                                displayOptions={projectSelectionDisplayOptions} />
                                        </Col>
                                    </Form.Group>
                                </Form>

                            </Col>
                            <Col md={6}>
                                <Form>
                                    <Form.Group as={Row} className="d-flex justify-content-center" >
                                        <Form.Label hidden={!hasProjectSelected}
                                            column sm='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center'
                                            }}>
                                            Practice Areas
                                        </Form.Label>
                                        <Col style={{ marginRight: '10px' }}>
                                            <MultiSelectListReportOptions listData={practiceAreas.value}
                                                handleSelectionChanged={handlePracticeAreaSelectionChanged}
                                                displayOptions={practiceAreaSelectionDisplayOptions} />
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-center">
                            <Form.Group controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Include Level 1 Findings"
                                    style={{ color: "dimgray" }}
                                    checked={showLevelOnePractices}
                                    onChange={levelOneCheckBoxChanged}
                                    disabled={findingsScopeValue === 'practiceAreaFindings'}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="d-flex justify-content-center">
                            <Button
                                variant="outline-light"
                                disabled={!hasProjectSelected || !hasPracticeAreaSelected || csvLoading || !hasReportOptionSelected()}
                                style={{
                                    background: 'rgb(0, 168, 168)',
                                    color: 'white',
                                    border: 'none',
                                    justifyContent: 'center',
                                    padding: '.5rem',
                                    marginTop: '1rem',
                                    marginBottom: '2rem'
                                }}
                                size='sm'
                                onClick={(e) => handleGenerateReportClicked(e)}>
                                {reportLoading &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size='sm'
                                        role="status"
                                        aria-hidden="true"
                                    />}
                                {!reportLoading ? "Generate Findings Report" : ' Generating Report...'}
                            </Button>{' '}
                            {!showCSV &&
                                <Button
                                    variant="outline-light"
                                    disabled={!hasProjectSelected || !hasPracticeAreaSelected || reportLoading || !hasReportOptionSelected()}
                                    style={{
                                        background: 'rgb(0, 168, 168)',
                                        color: 'white',
                                        border: 'none',
                                        justifyContent: 'center',
                                        padding: '.5rem',
                                        marginLeft: '1rem',
                                        marginTop: '1rem',
                                        marginBottom: '2rem'
                                    }}
                                    size='sm'
                                    onClick={(e) => handleGenerateCSVClicked(e)}>
                                    {csvLoading &&
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size='sm'
                                            role="status"
                                            aria-hidden="true"
                                        />}
                                    {!csvLoading ? "Generate Findings CSV" : ' Generating CSV...'}
                                </Button>
                            }
                            {showCSV &&
                                <CSVLink data={csvData} filename={csvFilename}>
                                    <Button color="primary"
                                        style={{
                                            border: 'none',
                                            justifyContent: 'center',
                                            padding: '.5rem',
                                            marginLeft: '1rem',
                                            marginTop: '1rem',
                                            marginBottom: '2rem'
                                        }}>
                                        Download CSV
                                    </Button>
                                </CSVLink>
                            }
                        </Row>
                    </ReportFragment>

                </LoadingComponent>
            </Modal>

            {!optionsVisible &&
                <NewWindow>
                    <ReportFragment
                        reportName={reportName}
                        reportHeaderStyle={viewReportStyle}
                        scopeHeader={scopeHeader}
                        scope={viewReportSubTitle()}
                        appraisalHeader={appraisalHeader}
                        appraisalName={appraisalName}>
                        <FindingsReportView
                            reportType={findingsScopeValue}
                            projects={projects.value.filter(p => p.selected === true)}
                            findingsData={findingsData}
                            reportOptions={getReportOptions()} />
                    </ReportFragment>
                </NewWindow>
            }
        </React.Fragment>
    );
}
export default FindingsReportModal;