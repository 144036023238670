import React, { useState, useEffect } from 'react';
import { Button, Container, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown, faAngleDoubleUp, faRedo } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select';
import { API } from 'aws-amplify';
import uuid from 'uuid';
import { Constants, getDefaultAPIHeaders } from '../../utils/helpers';
import useArray from '../../hooks/useArray';
import useInterval from '../../hooks/useInterval';
import { PRACTICE_AREAS, PRACTICES, PRACTICE_AREA_RELATIONSHIPS } from '../../model/modelcontent';
import './TreeView.css';
import LoadingComponent from '../../presenters/LoadingComponent';
import TreePresenter from '../../presenters/Tree/TreePresenter';
import MergeConflictModal from '../MergeConflictModal';
import { ICON_MAP } from '../../utils/IconMap'
import styled from 'styled-components';
import NetworkErrorModal from '../../presenters/NetworkErrorModal';

const Styles = styled.div`
}
 .w-20 {
    width: 20%!important;     
    background-color: rgb(0,168,168)!important;    
}

.w-80 {
  width: 80%!important;
}

.custom-btn {
    background:rgb(0, 168, 168);
    color: white;
    border: none;
    justify-content: center; 
    padding: .5rem;     
    cursor: default;
    min-width: 100px;   
  }

 .custom-btn.disabled {
    opacity: 1;
  }
`;

const FETCH_INTERVAL = 300000 //Five minutes

//A hash map of the node ratings. Used so we can remember the tree's expanded and collapsed states until the page refreshes.
let expandedStates = {};

//Returns the practices for a given practice area and maturity level
function getPracticesForPracticeArea(practiceArea, maturityLevel) {
  const practiceRelationships = PRACTICE_AREA_RELATIONSHIPS[practiceArea];
  const filtered = practiceRelationships.filter(r => (r.Level === maturityLevel)).map(r => {
    return r.Id
  });
  let practices = [];
  for (let i = 0; i < filtered.length; i++) {
    const practice = PRACTICES[filtered[i]];
    practices.push(practice);
  }
  return practices;
}

function getRating(stateData, id) {
  return stateData[id] !== undefined ? stateData[id].rating : "Not Examined";
}

function getRevNumber(stateData, id) {
  return stateData[id] !== undefined ? stateData[id].revNumber : undefined;
}

function getHighlight(stateData, id) {
  return stateData[id] !== undefined ? stateData[id].highlight : undefined;
}

function getExpandedState(appraisalId, projectId, nodeId) {
  return expandedStates[`${appraisalId}/${projectId}/${nodeId}`] || false;
}

//Creates a single (data) node in the tree
function buildTreeNode(name, nodeId, appraisalId, projectId, stateData, isPractice, isGroup) {
  return {
    id: uuid.v1(),
    name: name,
    state: {
      nodeId: nodeId,
      expanded: getExpandedState(appraisalId, projectId, nodeId),
      rating: getRating(stateData, nodeId),
      revNumber: getRevNumber(stateData, nodeId),
      highlight: getHighlight(stateData, nodeId),
      isPractice: isPractice,
      isGroup: isGroup
    }
  }
}

//Builds the tree data that's dispalyed in the tree. This function is called
// every time the rating of a tree changes
function buildTreeData(appraisalId, project, setNodes, maturityLevel, stateData, setStateLoading) {
  setStateLoading(true);
  let nodes = [];
  for (let i = 0; i < project.practiceAreas.length; i++) {
    //First build the practice area node.
    const practiceAreaInfo = PRACTICE_AREAS[project.practiceAreas[i]];
    let paNode = buildTreeNode(practiceAreaInfo.Name, practiceAreaInfo.Id, appraisalId, project.id,
      stateData, false, false);
    let groups = [];

    for (let j = 1; j <= maturityLevel; j++) {
      const currentPractices = getPracticesForPracticeArea(project.practiceAreas[i], j);
      if (currentPractices.length > 0) {
        const practiceNodes = currentPractices.map(p => {
          return buildTreeNode(p.Abbreviation || "", p.Id, appraisalId, project.id, stateData, true, false);
        });
        let groupNode = buildTreeNode(`Level ${j}`, practiceAreaInfo.LevelIds[j], appraisalId,
          project.id, stateData, false, true);
        groupNode.children = practiceNodes;
        groups.push(groupNode);
      }
    }
    paNode.children = groups;
    nodes.push(paNode);
  }
  setNodes(nodes);
  setStateLoading(false);
  return nodes;
}

function refreshTree(nodes, setNodes) {
  let newNodes = [];
  for (let i = 0; i < nodes.length; i++) {
    newNodes.push(nodes[i]);
  }
  setNodes(newNodes);
}

//Parses the maturity level from the view string.
function getMaturityLevel(view) {
  const levelRegex = /\d/;
  const matches = view.match(levelRegex);
  return matches[0];
}

//Fetches the data from the back end needed by thist page. Includes the the appraisal data, project data, and the node ratings.
async function fetchData(setAppraisals, setProjects, setNodes, setSelectedAppraisal, setSelectedProject, setControlLoading, getProjectData, setShowNetworkErrorModal) {
  try {
    const appraisals = await API.get(Constants.API_PATH, Constants.APPRAISAL_PATH, await getDefaultAPIHeaders())
      .catch(err => {
        if (err.message === "Network Error") {
          setShowNetworkErrorModal(true);
        }
      });
    const formattedAppraisals = appraisals.map(a => {
      return {
        id: a.appraisal_id,
        name: a.appraisal_name,
        view: a.appraisal_view
      }
    });
    if (appraisals.length > 0) {
      setAppraisals(formattedAppraisals);
      const storedAppraisalId = localStorage.getItem("selectedAppraisal");
      let selectedAppraisal = {}
      if (!storedAppraisalId) {
        selectedAppraisal = {
          id: appraisals[0].appraisal_id,
          name: appraisals[0].appraisal_name,
          view: appraisals[0].appraisal_view
        }
      } else {
        selectedAppraisal = formattedAppraisals.find(a => a.id === storedAppraisalId);
        if (!selectedAppraisal) {
          selectedAppraisal = {
            id: appraisals[0].appraisal_id,
            name: appraisals[0].appraisal_name,
            view: appraisals[0].appraisal_view
          }
        }
      }
      localStorage.setItem("selectedAppraisal", selectedAppraisal.id);
      setSelectedAppraisal(selectedAppraisal);
      await getProjectData(selectedAppraisal.id, setProjects, setSelectedProject, setNodes);
    }
    else {
      console.log('No appraisals were found.');
    }
  } catch (e) {
    console.log(e);
  }
  setControlLoading(false);
}

//Formats the response from the back end call to retreive the node ratings.
function formatStateData(data) {
  let retVal = {};
  data.forEach(sd => {
    retVal[sd.node_id] = {
      rating: sd.node_state,
      revNumber: sd.revision_number,
      highlight: sd.highlight
    };
  });
  return retVal;
}

//Gets the ratings for the tree nodes.
async function getStateData(appraisalId, projectId) {
  try {
    const result = await API.get(Constants.API_PATH, `${Constants.APPRAISAL_STATE_PATH}/${appraisalId}/${projectId}`, await getDefaultAPIHeaders());
    return formatStateData(result);
  } catch (e) {
    return e;
  }
}

async function handleNodeRatingError(err, localRating, selectedAppraisal, selectedProject, setNodes, setMergeConflictData, setShowMergeConflictModal, setStateLoading, setShowNetworkErrorModal) {

  console.log(err);

  if (err.message === "Network Error") {
    setShowNetworkErrorModal(true);
  }
  else {
    //Only show the merge conflict dialog if the ratings actually differ.
    if (err.response.status === 412 && err.response.data.node_state !== localRating) {
      setMergeConflictData({
        client: {
          appraisalId: err.response.data.appraisal_id,
          projectId: err.response.data.project_id,
          nodeId: err.response.data.node_id,
          revNumber: err.response.data.revision_number,
          text: localRating
        },
        server: {
          appraisalId: err.response.data.appraisal_id,
          projectId: err.response.data.project_id,
          nodeId: err.response.data.node_id,
          revNumber: err.response.data.revision_number,
          text: err.response.data.node_state
        }
      });
      setShowMergeConflictModal(true);
    } //If the ratings don't differ, we just want to resend the error body and rebuild the tree
    // to automatically resolve the conflict
    else {
      API.put(Constants.API_PATH, Constants.APPRAISAL_STATE_PATH, await getDefaultAPIHeaders(err.response.data))
        .then(data => {
          const stateData = formatStateData(data);
          buildTreeData(selectedAppraisal.id, selectedProject, setNodes, getMaturityLevel(selectedAppraisal.view), stateData, setStateLoading);
        })
    }
  }
}

//Stores the expanded states of the tree into a hashmap. This information isn't stored on the back end
//so we need to keep track of it here.
function saveExpandedStates(nodes, appraisalId, projectId) {
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    expandedStates[`${appraisalId}/${projectId}/${node.state.nodeId}`] = node.state.expanded || false;
    if (node.children && node.children.length > 0) {
      saveExpandedStates(node.children, appraisalId, projectId);
    }
  }
}

//Rendering function for the merge conflict dialog. Allows us to show 
// the node rating image and text instead of just the text in the event of a conflict.
function renderMergeConflictContent(data) {
  if (data) {
    const style = {
      marginLeft: "5px"
    }
    let faIcon = ICON_MAP[data.text];
    return (
      <React.Fragment>
        <div style={style} />
        {faIcon}
        <label style={style}>{data.text}</label>
      </React.Fragment>
    );
  }
}

async function refreshTreeData(selectedAppraisal, selectedProject, setNodes, setStateLoading) {
  if (selectedAppraisal && selectedAppraisal.view && selectedProject) {

    setStateLoading(true);
    const stateData = await getStateData(selectedAppraisal.id, selectedProject.id)
    buildTreeData(selectedAppraisal.id, selectedProject, setNodes, getMaturityLevel(selectedAppraisal.view), stateData, setStateLoading);
  }
}

function TreeControl(props) {
  const [showNetworkErrorModal, setShowNetworkErrorModal] = useState(false);
  const [controlLoading, setControlLoading] = useState(true);
  const [nodes, setNodes] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);
  const appraisals = useArray([]);
  const projects = useArray([]);
  const [selectedAppraisal, setSelectedAppraisal] = useState({});
  const [selectedProject, setSelectedProject] = useState({});
  const [showMergeConflictModal, setShowMergeConflictModal] = useState(false);
  const [mergeConflictData, setMergeConflictData] = useState({});

  useEffect(() => {
    const getData = () => fetchData(appraisals.setValue, projects.setValue, setNodes, setSelectedAppraisal, setSelectedProject, setControlLoading, getProjectData, setShowNetworkErrorModal);
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refreshTreeData(selectedAppraisal, selectedProject, setNodes, setStateLoading);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refreshTrigger]);

  useEffect(() => {
    setStateLoading(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.treeLoadingTrigger]);

  useInterval(() => fetchData(appraisals.setValue, projects.setValue, setNodes, setSelectedAppraisal, setSelectedProject, setControlLoading), FETCH_INTERVAL);

  function onRefreshClicked() {
    refreshTreeData(selectedAppraisal, selectedProject, setNodes, setStateLoading);
  }

  //Gets the practice areas and practices for a single project. 
  async function getProjectData(appraisalId, setProjects, setSelectedProject, setNodes) {
    try {
      const currentAppraisalData = await API.get(Constants.API_PATH, `${Constants.APPRAISAL_PATH}/${appraisalId}`, await getDefaultAPIHeaders());
      const formattedProjectData = currentAppraisalData.projects.map(p => {
        return {
          id: p.project_id,
          name: p.project_name,
          practiceAreas: p.practice_areas
        }
      });
      setProjects(formattedProjectData);
      const maturityLevel = getMaturityLevel(currentAppraisalData.appraisal_view);
      const storedProjectId = localStorage.getItem("selectedProject");
      let selectedProject = {};
      if (!storedProjectId) {
        selectedProject = {
          id: currentAppraisalData.projects[0].project_id,
          name: currentAppraisalData.projects[0].project_name,
          practiceAreas: currentAppraisalData.projects[0].practice_areas
        }
      } else {
        selectedProject = formattedProjectData.find(p => p.id === storedProjectId);
        if (!selectedProject) {
          selectedProject = {
            id: currentAppraisalData.projects[0].project_id,
            name: currentAppraisalData.projects[0].project_name,
            practiceAreas: currentAppraisalData.projects[0].practice_areas
          }
        }
      }
      localStorage.setItem("selectedProject", selectedProject.id);
      setSelectedProject(selectedProject);
      const stateData = await getStateData(currentAppraisalData.id, selectedProject.id);
      let nodes = buildTreeData(appraisalId, selectedProject, setNodes, maturityLevel, stateData, setStateLoading);
      selectFirstNodeOnLoad(nodes[0], appraisalId, selectedProject.id);
    }
    catch (e) {
      console.log(e);
    }
  }

  const handleSelectedAppraisalChanged = async (appraisal) => {
    props.updateNodeInfo(null);
    const newAppraisal = appraisals.getById(appraisal.value);
    setSelectedAppraisal(newAppraisal);
    setControlLoading(true);

    sessionStorage.setItem('copyBuffer', "");
    sessionStorage.setItem('copyInterviewsBuffer', "");

    localStorage.setItem("selectedAppraisal", newAppraisal.id);
    await getProjectData(newAppraisal.id, projects.setValue, setSelectedProject, setNodes);
    setControlLoading(false);
  }

  const handleSelectedProjectChanged = async (project) => {
    props.updateNodeInfo(null);
    const newProject = projects.getById(project.value);
    setSelectedProject(newProject);
    localStorage.setItem("selectedProject", newProject.id);
    setControlLoading(true);
    try {
      const stateData = await getStateData(selectedAppraisal.id, newProject.id);
      let nodes = buildTreeData(selectedAppraisal.id, newProject, setNodes, getMaturityLevel(selectedAppraisal.view), stateData, setStateLoading);
      selectFirstNodeOnLoad(nodes[0], selectedAppraisal.id, newProject.id);
    }
    catch (e) {
      console.log(e);
    }
    setControlLoading(false);
  }

  function selectFirstNodeOnLoad(node, appraisalId, projectId) {
    props.updateNodeInfo(
      {
        appraisalId: appraisalId,
        projectId: projectId,
        nodeId: node.state.nodeId,
        isPractice: node.state.isPractice,
        isGroup: node.state.isGroup,
        name: node.name
      }
    );
  }

  const handleNodeClicked = (nodeInfo) => {
    props.updateNodeInfo(
      {
        appraisalId: selectedAppraisal.id,
        projectId: selectedProject.id,
        nodeId: nodeInfo.nodeId,
        isPractice: nodeInfo.isPractice,
        isGroup: nodeInfo.isGroup,
        name: nodeInfo.name
      }
    );
  }

  const handleNodesChanged = (nodes) => {
    setNodes(nodes);
    saveExpandedStates(nodes, selectedAppraisal.id, selectedProject.id);
  }

  const handleNodeRatingChanged = async (node, newRating) => {
    setStateLoading(true);

    const body = {
      appraisal_id: selectedAppraisal.id,
      project_id: selectedProject.id,
      node_id: node.state.nodeId,
      node_state: newRating,
      revision_number: node.state.revNumber
    };
    API.put(Constants.API_PATH, Constants.APPRAISAL_STATE_PATH, await getDefaultAPIHeaders(body))
      .then(data => {
        const stateData = formatStateData(data);
        buildTreeData(selectedAppraisal.id, selectedProject, setNodes, getMaturityLevel(selectedAppraisal.view), stateData, setStateLoading);
      })
      .catch(err => {
        handleNodeRatingError(err, newRating, selectedAppraisal, selectedProject, setNodes, setMergeConflictData, setShowMergeConflictModal, setStateLoading, setShowNetworkErrorModal);
      });
  }

  const closeMergeConflictModal = () => {
    setShowMergeConflictModal(false);
  }

  const handleRatingMergeConflict = async (selection, value) => {
    if (value === "client") {
      const body = {
        appraisal_id: selection.appraisalId,
        project_id: selection.projectId,
        node_id: selection.nodeId,
        node_state: selection.text,
        revision_number: selection.revNumber
      };
      API.put(Constants.API_PATH, Constants.APPRAISAL_STATE_PATH, await getDefaultAPIHeaders(body))
        .then(data => {
          const stateData = formatStateData(data);
          buildTreeData(selectedAppraisal.id, selectedProject, setNodes, getMaturityLevel(selectedAppraisal.view), stateData, setStateLoading);
        })
        .catch(err => {
          handleNodeRatingError(err, selection.text, selectedAppraisal, selectedProject, setNodes, setMergeConflictData, setShowMergeConflictModal, setStateLoading, setShowNetworkErrorModal);
        });
    } else {
      getStateData(selection.appraisalId, selection.projectId)
        .then(data => {
          buildTreeData(selectedAppraisal.id, selectedProject, setNodes, getMaturityLevel(selectedAppraisal.view), data, setStateLoading);
        }).catch(err => {
          console.log(err);
        });
    }
  }

  function setExpanded(expanded) {
    nodes.forEach(e => {
      e.state.expanded = expanded;
      if (e.children && e.children.length > 0) {
        e.children.forEach(child => {
          child.state.expanded = expanded;
        });
      }
    });
    setNodes(nodes);
    saveExpandedStates(nodes, selectedAppraisal.id, selectedProject.id);
    refreshTree(nodes, setNodes);
  }

  function expandAll() {
    setExpanded(true);
  }

  function collapseAll() {
    setExpanded(false);
  }

  const appraisalOptions = appraisals.value.sort((a, b) => a.name.localeCompare(b.name)).map(a => {
    return {
      value: a.id,
      label: a.name
    }
  });

  const projectOptions = projects.value.sort((a, b) => a.name.localeCompare(b.name)).map(p => {
    return {
      value: p.id,
      label: p.name
    }
  });

  const customSelectStyles = {
    singleValue: (base, state) => ({
      ...base,
      color: 'dimgray'
    }),
    option: (base, state) => ({
      ...base,
      color: 'dimgray'
    }),
    control: (base, state) => ({
      ...base,
      borderRight: '0 !important',
      borderTop: '0 !important',
      borderBottom: '1px solid lightgray !important',
      boxShadow: '0 !important',
      outline: 'none !important'
    })
  }

  return (
    <Styles>
      <div className="treeControlContainer">
        <MergeConflictModal conflict={mergeConflictData} handleMergeConflict={handleRatingMergeConflict} showModal={showMergeConflictModal}
          renderConflictContent={renderMergeConflictContent} closeModal={closeMergeConflictModal} />
        <div>
          <InputGroup size='sm' className='mb-2' shadow style={{ flexWrap: 'nowrap' }}>
            <InputGroup.Prepend className='w-20' style={{ minWidth: '100px' }}>
              <Button block disabled className='custom-btn disabled' style={{ fontSize: '16px' }}>Appraisals</Button>
            </InputGroup.Prepend>
            <Select className='w-80' styles={customSelectStyles}
              theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: 'rgba(0,168,168, 0.2)',
                  primary: 'rgba(0,168,168, 0.5)',
                },
              })}
              options={appraisalOptions} value={selectedAppraisal && { value: selectedAppraisal.id, label: selectedAppraisal.name }}
              onChange={(e) => handleSelectedAppraisalChanged(e)} />
          </InputGroup>
          <InputGroup size='sm' className='mb-2' shadow style={{ flexWrap: 'nowrap' }}>
            <InputGroup.Prepend className='w-20' style={{ minWidth: '100px' }}>
              <Button block disabled className='custom-btn disabled' style={{ fontSize: '16px' }}>Projects</Button>
            </InputGroup.Prepend>
            <Select className='w-80' styles={customSelectStyles}
              theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: 'rgba(0,168,168, 0.2)',
                  primary: 'rgba(0,168,168, 0.5)',
                },
              })}
              options={projectOptions} value={selectedProject && { value: selectedProject.id, label: selectedProject.name }}
              onChange={(e) => handleSelectedProjectChanged(e)} />
          </InputGroup>
        </div>
        <div className="treeContainer">
          <Container style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Collapse All</Tooltip>}>
              <span className="d-inline-block">
                <Button
                  style={{ background: 'none', borderColor: 'rgb(0,168,168)', borderWidth: '2px' }}
                  size="sm"
                  onClick={collapseAll}>
                  <FontAwesomeIcon icon={faAngleDoubleUp} size="sm" color='rgb(0,168,168)' />
                </Button>
              </span>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Expand All</Tooltip>}>
              <span className="d-inline-block">
                <Button
                  style={{ background: 'none', borderColor: 'rgb(0,168,168)', borderWidth: '2px', marginRight: '2px' }}
                  size="sm"
                  onClick={expandAll}>
                  <FontAwesomeIcon icon={faAngleDoubleDown} size="sm" color='rgb(0,168,168)' />
                </Button>
              </span>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Refresh Model</Tooltip>}>
              <span className="d-inline-block">
                <Button
                  style={{ background: 'none', borderColor: 'rgb(0,168,168)', borderWidth: '2px', marginRight: '15px' }}
                  size="sm"
                  onClick={onRefreshClicked}>
                  <FontAwesomeIcon icon={faRedo} size="sm" color='rgb(0,168,168)' />
                </Button>
              </span>
            </OverlayTrigger>
          </Container>
          <LoadingComponent isLoading={controlLoading} iconSize={80}>
            <TreePresenter nodesDisabled={stateLoading} nodes={nodes} handleNodesChanged={handleNodesChanged}
              handleNodeRatingChanged={handleNodeRatingChanged} handleNodeClicked={handleNodeClicked} />
          </LoadingComponent>
        </div>

        <NetworkErrorModal showNetworkErrorModal={showNetworkErrorModal} />
      </div>
    </Styles>
  )
}
export default TreeControl;