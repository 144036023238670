import React, { useState, useEffect, useRef } from 'react';
import { API } from 'aws-amplify';
import { FormLabel, Spinner, Row, ButtonToolbar, Table, Button, Modal, Container, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { OrgRepository, AppraisalRepository } from '../utils/repositories';
import ConfirmDeleteModal from '../presenters/ConfirmDeleteModal';
import AppraisalItem from '../presenters/AppraisalItem';
import useArray from '../hooks/useArray';
import { AppraisalStateOne, AppraisalStateTwo, AppraisalStateThree, APPRAISAL_CONSTANTS } from '../presenters/AppraisalStateMachineBodies';
import InterviewSessionsModal from './InterviewSessionsModal';
import WizardFooter from '../presenters/WizardFooter';
import { modelContentUtils, getDefaultAPIHeaders, getNodeAPIPath, Constants } from '../utils/helpers';
import { MODEL_CONSTANTS, PRACTICES, PRACTICE_AREAS, PRACTICE_AREA_RELATIONSHIPS } from '../model/modelcontent';
import LoadingComponent from '../presenters/LoadingComponent';
import addApprIcon from '../assets/appr-add.png'
import deleteApprIcon from '../assets/appr-delete.png'
import editApprIcon from '../assets/appr-edit.png'
import editInterviewSessions from '../assets/interview-edit.png'
import "../modal.css";

const DELETE_QUESTION = "Delete the selected appraisals and associated mappings?";
const DELETE_IMPACT = "This action cannot be undone.";

const INITIAL_FORM_ONE_STATE = {
    name: '',
    nameError: false,
    nameTooltip: '',
    devSelected: true,
    svcSelected: false,
    level: '',
    levelError: false,
    levelTooltip: '',
    projectName: '',
    projectNameError: false,
    projectNameTooltip: '',
    organization: '', //Org id
    organizationError: false,
    organizationTooltip: '',
    projectError: false,
    projectTooltip: ''
};

const INIT_FORM_TWO_STATE = {
    selectedProject: {},
};

const INIT_FORM_THREE_STATE = {
    selectedProject: {},
};

const APPRAISAL_REF_RESET_STATE = {
    name: '',
    view: '',
    organization: '',
    projects: []
};

function getPracticeAreas(view) {
    const pas = modelContentUtils.getPracticeAreasFromView(view, true).filter(x => x.Optional === false);
    
    //Pulling only the information I want from the utils call, which returns everything
    const modifiedPas = pas.map(pa => {
        return (
            {
                Name: pa.Name,
                Id: pa.Id,
                SelectedForAdd: false,
                SelectedForRemove: false,
                SvcExclusive: pa.SvcExclusive,
                DevExclusive: pa.DevExclusive,
                Optional: pa.Optional,
                MappedByAnyProject: false
            }
        )
    });
    return modifiedPas;
}

function getOptionalPracticeAreas() {
    const optionalPAs = Object.values(PRACTICE_AREAS).filter(x => x.Optional === true);
 
    //Pulling only the information I want from the utils call, which returns everything
    const modifiedPas = optionalPAs.map(pa => {
        return (
            {
                Name: pa.Name,
                Id: pa.Id,
                SelectedForAdd: false,
                SelectedForRemove: false,
                SvcExclusive: pa.SvcExclusive,
                DevExclusive: pa.DevExclusive,
                Optional: pa.Optional,
                MappedByAnyProject: false
            }
        )
    });
    return modifiedPas;
}

function getViewStringFromState(formOneState) {
    let viewString;
    if (formOneState.devSelected) {
        viewString = 'Development ';
        viewString = viewString.concat(formOneState.level);
    } else if (formOneState.svcSelected) {
        viewString = 'Services ';
        viewString = viewString.concat(formOneState.level);
    }
    return viewString;
}

async function fetchData(setOrgData, setAppraisalData, setTableLoading, org) {
    setTableLoading(true);
    const orgRepo = new OrgRepository();
    const orgData = await orgRepo.getAll();
    if (!orgData.error) {
        setOrgData(orgData.returnValue);
        const appraisalRepo = new AppraisalRepository();
        const appraisalData = await appraisalRepo.getAll();
        const filteredAppraisals = appraisalData.returnValue.filter(x => x.organization === org);

        if (!appraisalData.error) {
            setAppraisalData(filteredAppraisals.map(ad => {
                const matchedOrgItem = orgData.returnValue.find(org => org.id === ad.organization);
                return {
                    ...ad,
                    organization: matchedOrgItem
                }
            }));
        } else {
            console.log('error getting appraisal data', appraisalData.returnValue);
        }
    } else {
        console.log('error getting orgData: ', orgData.returnValue);
    }

    setTableLoading(false);
}

function Appraisals(props) {
    const [tableLoading, setTableLoading] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [showDeletingModal, setShowDeletingModal] = useState(false);
    const [isItemDeleting, setIsItemDeleting] = useState(false);
    const [itemBeingDeleted, setItemBeingDeleted] = useState("");
    const [itemDeleteStatus, setItemDeleteStatus] = useState("");
    const [itemDeleteTitle, setItemDeleteTitle] = useState("");
    const [itemDeleteButtonText, setItemDeleteButtonText] = useState("");
    const [showCreateEditModal, setShowCreateEditModal] = useState(false);
    const [showClosePrompt, setShowClosePrompt] = useState(false);
    const [modalMachineState, setModalMachineState] = useState(0);
    const [modalTitle, setModalTitle] = useState('');
    const [formOneState, setFormOneState] = useState(INITIAL_FORM_ONE_STATE);
    const [formTwoState, setFormTwoState] = useState(INIT_FORM_TWO_STATE);
    const [formThreeState, setFormThreeState] = useState(INIT_FORM_THREE_STATE);
    const [finishLoading, setFinishLoading] = useState(false);
    const [showInterviewSessionsModal, setShowInterviewSessionsModal] = useState(false);
    const [showPAWarningModal, setShowPAWarningModal] = useState(false);
    const [projectsWithNoPA, setProjectsWithNoPA] = useState(false);
    const practicesToCleanFromMLLower = useArray([]);
    const interviewSessionsArray = useArray([]);
    const appraisalRef = useRef(APPRAISAL_REF_RESET_STATE);
    const orgsArray = useArray([]);
    const appraisalsArray = useArray([]);
    const currentAppraisalProjects = useArray([]);
    const availablePracticeAreas = useArray([]);
    const availablePracticeAreasRef = useRef([]);
    const availableOptionalPracticeAreas = useArray([]);
    const availableOptionalPracticeAreasRef = useRef([]);
    const editedAppraisalRef = useRef({});
    const interviewIdRef = useRef("");
    const sessionSnapshotRef = useRef([]);
    const autoFocus = React.createRef();
    let modalBody, modalFooter;

    function getOrgId() {
        if (props.org === null) {
            return "";
        }
        else {
            return props.org.id;
        }
    }

    const enabledStyle = {
        backgroundColor: 'white',
        border: 'none',
        pointerEvents: 'auto'
    }

    const disabledStyle = {
        backgroundColor: 'white',
        border: 'none',
        pointerEvents: 'none'
    }

    //Get org and appraisal data on startup
    useEffect(() => {
        const getData = () => fetchData(orgsArray.setValue, appraisalsArray.setValue, setTableLoading, getOrgId());
        getData();
    }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [props.org, props.refreshTrigger]);

    const handleShowDeleteModal = (event) => {
        setShowDeleteModal(true);
    }

    function refreshAppraisals() {
        fetchData(orgsArray.setValue, appraisalsArray.setValue, setTableLoading, getOrgId());
    }

    const handleDelete = async () => {
        setShowDeleteModal(false);
        setShowDeletingModal(true);
        setIsItemDeleting(true);
        setItemDeleteTitle("Deleting Appraisal(s)");
        setItemDeleteButtonText("Deleting Appraisal, please wait...");

        const itemsToRemove = appraisalsArray.value.filter(el => el.selected === true);
        const apiRepo = new AppraisalRepository();

        for (let i = 0; i < itemsToRemove.length; i++) {
            const item = itemsToRemove[i];
            setItemBeingDeleted("Deleting " + item.name);

            //clean items from appraisal
            setItemDeleteStatus("Deleting Practice Data...");
            const itemApiPath = `${Constants.APPRAISAL_ITEM_PATH}/${item.id}`
            const itemData = await API.get(Constants.API_PATH, itemApiPath, await getDefaultAPIHeaders());
            for (let j = 0; j < itemData.length; j++) {
                try {
                    let body = {
                        appraisal_item_id: itemData[j].appraisal_item_id,
                    };
                    await API.del(Constants.API_PATH, Constants.APPRAISAL_ITEM_PATH, await getDefaultAPIHeaders(body))
                }
                catch (e) {
                    console.log('Delete appraisal item failure' + e.message);
                }
            }

            //clean states from appraisal
            setItemDeleteStatus("Deleting State Data...");
            const stateApiPath = `${Constants.APPRAISAL_STATE_PATH}/${item.id}`
            const stateData = await API.get(Constants.API_PATH, stateApiPath, await getDefaultAPIHeaders());
            for (let j = 0; j < stateData.length; j++) {
                try {
                    let body = {
                        compound_id: stateData[j].compound_id,
                    };
                    await API.del(Constants.API_PATH, Constants.APPRAISAL_STATE_PATH, await getDefaultAPIHeaders(body))
                }
                catch (e) {
                    console.log('Delete appraisal state failure' + e.message);
                }
            }

            //clean evidence from appraisal
            setItemDeleteStatus("Deleting Evidence Data...");
            const evidenceApiPath = `${Constants.APPRAISAL_EVIDENCE_PATH}/${item.id}`
            const evidenceData = await API.get(Constants.API_PATH, evidenceApiPath, await getDefaultAPIHeaders());
            for (let j = 0; j < evidenceData.length; j++) {
                let body = {
                    appraisal_evidence_id: evidenceData[j].appraisal_evidence_id,
                };
                try {
                    await API.del(Constants.API_PATH, Constants.APPRAISAL_EVIDENCE_PATH, await getDefaultAPIHeaders(body));
                }
                catch (e) {
                    console.log('Delete appraisal evidence failure' + e);
                }
            }

            //clean interview questions from appraisal
            setItemDeleteStatus("Deleting Interview Data...");
            const interviewsApiPath = `${Constants.INTERVIEW_QUESTIONS_PATH}/${item.id}`
            const interviewsData = await API.get(Constants.API_PATH, interviewsApiPath, await getDefaultAPIHeaders());
            for (let j = 0; j < interviewsData.length; j++) {
                try {
                    let body = {
                        interview_question_id: interviewsData[j].interview_question_id,
                    };
                    await API.del(Constants.API_PATH, Constants.INTERVIEW_QUESTIONS_PATH, await getDefaultAPIHeaders(body))
                }
                catch (e) {
                    console.log('Delete appraisal interview question failure' + e.message);
                }
            }

            //delete appraisal entry
            setItemDeleteStatus("Deleting Appraisal...");
            const result = await apiRepo.deleteOne(item.id);
            if (!result.error) {
                appraisalsArray.removeById(item.id);
            } else {
                console.log("Error deleting appraisal: ", result.returnValue);
            }
            setItemDeleteStatus("Appraisal Deleted Successfully");
        }
        setItemBeingDeleted("");
        setItemDeleteStatus("Appraisal Deletion(s) Complete");
        setIsItemDeleting(false);
    }

    const handleUpdateDelete = (event, id) => {
        const checked = event.target.checked;
        const appraisalItem = appraisalsArray.getById(id);
        appraisalItem.markedForDelete = checked;
        appraisalsArray.replace(id, appraisalItem);
    }

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
    }

    const tryCloseDeletingModal = () => {
        if (!isItemDeleting) {
            setShowDeletingModal(false);
        }
    }

    const getDeletingButtonText = () => {
        return !isItemDeleting ? "Close" : itemDeleteButtonText;
    }

    const closeCreateEditModal = () => {
            setFormOneState(INITIAL_FORM_ONE_STATE);
            appraisalRef.current = APPRAISAL_REF_RESET_STATE;
            currentAppraisalProjects.clear();
            availablePracticeAreas.clear();
            availablePracticeAreasRef.current = [];
            setModalMachineState(0);
            setShowCreateEditModal(false);
            hideClosePrompt();
    }

    const handleCreateAppraisal = (event) => {
        setModalTitle('Create');
        if (props.org) {
            formOneState.organization = props.org.id;
        }
        setShowCreateEditModal(true);
    }

    const handleNextAppraisalAction = () => {
        const foundError = validateFormOneState();
        if (!foundError) {
            appraisalRef.current = {
                name: formOneState.name,
                projects: currentAppraisalProjects.value,
                organization: formOneState.organization,
                view: formOneState.view !== 'Custom' ? getViewStringFromState(formOneState) : 'Custom'
            };

            deselectPAs();

            if (appraisalRef.current.view !== 'Custom') {
                if(modalMachineState === 0){
                    const practiceAreas = getPracticeAreas(appraisalRef.current.view);
                    availablePracticeAreasRef.current = practiceAreas;
    
                    const selectedProject = currentAppraisalProjects.value[0];
                    let filtered = practiceAreas.filter(pa => {
                        return selectedProject.practiceAreas.find(ppa => ppa.Id === pa.Id) === undefined
                    });
                    const nonCurrentProjects = currentAppraisalProjects.value.filter(p => p.id !== selectedProject.id);
                    const mappedPractices = nonCurrentProjects.map(p => {
                        return p.practiceAreas
                    }).reduce((value, next) => value.concat(next), []);
    
                    if (mappedPractices.length > 0) {
                        for (let i = 0; i < filtered.length; i++) {
                            if (mappedPractices.find(pa => pa.Id === filtered[i].Id)) {
                                filtered[i].MappedByAnyProject = true;
                            }
                        }
                    }
                    availablePracticeAreas.setValue(filtered);
                    setFormTwoState(prevState => ({ ...prevState, selectedProject: appraisalRef.current.projects[0] }));
                }
                else{
                    const optionalPAs = getOptionalPracticeAreas();
                    availableOptionalPracticeAreasRef.current = optionalPAs;

                    const selectedProject = currentAppraisalProjects.value[0];
                    let filtered = optionalPAs.filter(pa => {
                        return selectedProject.practiceAreas.find(ppa => ppa.Id === pa.Id) === undefined
                    });
                    const nonCurrentProjects = currentAppraisalProjects.value.filter(p => p.id !== selectedProject.id);
                    const mappedPractices = nonCurrentProjects.map(p => {
                        return p.practiceAreas
                    }).reduce((value, next) => value.concat(next), []);
    
                    if (mappedPractices.length > 0) {
                        for (let i = 0; i < filtered.length; i++) {
                            if (mappedPractices.find(pa => pa.Id === filtered[i].Id)) {
                                filtered[i].MappedByAnyProject = true;
                            }
                        }
                    }
                    
                    availableOptionalPracticeAreas.setValue(filtered);
                     setFormThreeState(prevState => ({ ...prevState, selectedProject: appraisalRef.current.projects[0] }));
                }
            }
            
            setModalMachineState(modalMachineState + 1);
        }
    }

    const validateFormOneState = () => {
        let nameError = false, levelError = false,
            projectNameError = false, organizationError = false,
            foundError = false;
        let nameTooltip = '', organizationTooltip = '',
            levelTooltip = '', projectTooltip = '';

        if (formOneState.name.length === 0) {
            nameError = true;
            foundError = true;
            nameTooltip = APPRAISAL_CONSTANTS.NAME_ERROR_TOOLTIP;
        }
        if (formOneState.organization.length === 0) {
            organizationError = true;
            foundError = true;
            organizationTooltip = APPRAISAL_CONSTANTS.ORG_ERROR_TOOLTIP;
        }
        if (formOneState.level.length === 0) {
            levelError = true;
            foundError = true;
            levelTooltip = APPRAISAL_CONSTANTS.LEVEL_ERROR_TOOLTIP;
        }
        if (currentAppraisalProjects.value.length === 0) {
            projectNameError = true;
            foundError = true;
            projectTooltip = APPRAISAL_CONSTANTS.PROJECT_ERROR_TOOLTIP
        }
        if (checkAppraisalClashes()) {
            foundError = true;
            nameError = true;
            organizationError = true;
            organizationTooltip = APPRAISAL_CONSTANTS.CLASHING_APPRAISAL_TOOLTIP;
            nameTooltip = APPRAISAL_CONSTANTS.CLASHING_APPRAISAL_TOOLTIP;
        }
        if (foundError) {
            setFormOneState(prevState => ({
                ...prevState, nameError: nameError,
                nameTooltip: nameTooltip, levelError: levelError,
                levelTooltip: levelTooltip, organizationError: organizationError,
                organizationTooltip: organizationTooltip,
                projectNameError: projectNameError, projectTooltip: projectTooltip
            }));
        }
        return foundError;
    }

    const checkAppraisalClashes = (nameVal = null, orgVal = null) => {
        let foundClashingOrg = false;
        const name = nameVal === null ? formOneState.name : nameVal;
        const org = orgVal === null ? formOneState.organization : orgVal;
        const appraisalClashes = appraisalsArray.value.filter(a => a.name === name);
        if (appraisalClashes.length > 0) {
            if (appraisalClashes[0].id !== editedAppraisalRef.current.id) {
                for (let i = 0; i < appraisalClashes.length; i++) {
                    const appraisal = appraisalClashes[i];
                    if (appraisal.organization.id === org) {
                        foundClashingOrg = true;
                        break;
                    }
                }
            }
        }
        return foundClashingOrg;
    }

    const getInitialPracticeAreas = (projectId) => {
        const nonCurrentProjects = currentAppraisalProjects.value.filter(p => p.id !== projectId);
        const mappedPracticeAreas = nonCurrentProjects.map(p => {
            return p.practiceAreas
        }).reduce((value, next) => value.concat(next), []);
        let availablePracticeAreas = JSON.parse(JSON.stringify(availablePracticeAreasRef.current));
        if (mappedPracticeAreas.length > 0) {
            //Pulling this find function out of the loop to avoid a linter warning
            // (although I'm not certain it makes any difference being writtent this way)
            const foundPractice = (practiceAreas, target) => {
                return practiceAreas.find(pa => pa.Id === target.Id);
            }
            for (let i = 0; i < availablePracticeAreas.length; i++) {
                if (foundPractice(mappedPracticeAreas, availablePracticeAreas[i])) {
                    availablePracticeAreas[i].MappedByAnyProject = true;
                } else {
                    availablePracticeAreas[i].MappedByAnyProject = false;
                }
            }
        } else {
            availablePracticeAreas = availablePracticeAreas.map(pa => ({ ...pa, MappedByAnyProject: false }));
        }
        return availablePracticeAreas;
    }

    const getInitialOptionalPracticeAreas = (projectId) => {
        const nonCurrentProjects = currentAppraisalProjects.value.filter(p => p.id !== projectId);
        const mappedPracticeAreas = nonCurrentProjects.map(p => {
            return p.practiceAreas
        }).reduce((value, next) => value.concat(next), []);
        let optionalPracticeAreas = JSON.parse(JSON.stringify(availableOptionalPracticeAreasRef.current));
        if (mappedPracticeAreas.length > 0) {
            //Pulling this find function out of the loop to avoid a linter warning
            // (although I'm not certain it makes any difference being writtent this way)
            const foundPractice = (practiceAreas, target) => {
                return practiceAreas.find(pa => pa.Id === target.Id);
            }
            for (let i = 0; i < optionalPracticeAreas.length; i++) {
                if (foundPractice(mappedPracticeAreas, optionalPracticeAreas[i])) {
                    optionalPracticeAreas[i].MappedByAnyProject = true;
                } else {
                    optionalPracticeAreas[i].MappedByAnyProject = false;
                }
            }
        } else {
            optionalPracticeAreas = optionalPracticeAreas.map(pa => ({ ...pa, MappedByAnyProject: false }));
        }
        return optionalPracticeAreas;
    }

    const handleMaturityLevelDowngrade = (currentMaturityLevel, selectedMaturityLevel) => {
     
        let nodeIdsToBeRemoved = [];

        //find all practices that will be removed due to maturity level downgrade
        //these will be all practices that are > the new level and <= the current level
        let allPractices = Object.values(PRACTICES);
        for (let i = 0; i < allPractices.length; i++) {
            if (allPractices[i].Level > selectedMaturityLevel && allPractices[i].Level <= currentMaturityLevel) {
                nodeIdsToBeRemoved.push(allPractices[i].Id);
            }
        }

        //find all groups that will be removed due to maturity level downgrade
        let allPAs = Object.values(PRACTICE_AREAS);
        for(let i = 0; i < allPAs.length; i++){
            for(let j = currentMaturityLevel; j > selectedMaturityLevel; j--){
                let groupToRemove = allPAs[i].LevelIds[j];
                if(groupToRemove){
                    nodeIdsToBeRemoved.push(groupToRemove);
                }
            }
        }

        //if the maturity level drops to 2, we must also remove practice areas
        if (currentMaturityLevel > 2 && selectedMaturityLevel === "2") {
            let optionalPracticeAreas = Object.values(PRACTICE_AREAS).filter(x => x.Optional === true);
  
            let practiceAreas;
            if (formOneState.devSelected) {
                practiceAreas = modelContentUtils.getPracticeAreasFromView(MODEL_CONSTANTS.DEV_LEVEL_2);
            }
            else {
                practiceAreas = modelContentUtils.getPracticeAreasFromView(MODEL_CONSTANTS.SVCS_LEVEL_2);
            }
            
            practiceAreas = practiceAreas.concat(optionalPracticeAreas);
            practiceAreas = practiceAreas.map(pa => {
                return (
                    {
                        Name: pa.Name,
                        Id: pa.Id,
                        Level: pa.Level,
                        Selected: false,
                        SvcExclusive: pa.SvcExclusive,
                        DevExclusive: pa.DevExclusive,
                        SelectedForAdd: false,
                        SelectedForRemove: false,
                        MappedByAnyProject: false,
                        Optional: pa.Optional
                    }
                )
            });
            for (let i = 0; i < currentAppraisalProjects.value.length; i++) {
                let project = currentAppraisalProjects.value[i];
                const filtered = practiceAreas.filter(pa => {
                    return project.practiceAreas.find(ppa => ppa.Id === pa.Id) !== undefined
                });

                //add removed PA nodes to removal list
                for (let j = 0; j < project.practiceAreas.length; j++) {
                    let correspondingPA = filtered.filter(pa => pa.Id === project.practiceAreas[j].Id)[0];
                    if (correspondingPA === undefined) {
                        nodeIdsToBeRemoved.push(project.practiceAreas[j].Id)
                    }
                }

                project.practiceAreas = filtered;
                currentAppraisalProjects.replace(project.id, project);
            }
        }
        
        practicesToCleanFromMLLower.setValue(nodeIdsToBeRemoved);
    }

    //disable the finish button if:
    // 1. any project has no practice areas
    // 2. any non-optional practice area is not mapped to at least one project
    const finishActionDisabled = () => {
        const mappedPracticesAbbr = currentAppraisalProjects.value.map(p => {
            return p.practiceAreas.filter(x => x.Optional === false).map(pa => {
                return pa.Id
            });
        }).reduce((value, next) => value.concat(next), []);
        const requiredMappedAbbr = availablePracticeAreasRef.current.filter(x => x.Optional === false).map(pa => {
            return pa.Id
        });
        const unique = Array.from(new Set(mappedPracticesAbbr)); //removing duplicate entries
        return requiredMappedAbbr.length === unique.length;
    }

    const handleFinishAppraisalAction = async () => {

        //validate has PA
        const unMappedProjects = currentAppraisalProjects.value.filter(p => p.practiceAreas.length === 0);
        if(unMappedProjects.length > 0){

            let noPA = unMappedProjects.map(x => {
                return (
                    <span>
                        <span>{x.name}</span><br></br>
                    </span>
                );
            });
            setProjectsWithNoPA(noPA);

            setModalOpen(true);
            setShowPAWarningModal(true);
            return;
        }

        setFinishLoading(true);

        switch (modalTitle) {
            case "Create":
                var newAppraisal = {
                    name: formOneState.name,
                    projects: currentAppraisalProjects.value,
                    organization: orgsArray.getById(appraisalRef.current.organization),
                    view: appraisalRef.current.view
                }
                const appraisalRepo = new AppraisalRepository();
                const result = await appraisalRepo.createOne(newAppraisal);
                if (!result.error) {
                    appraisalsArray.add(result.returnValue);
                }
                break;
            case "Edit":
                const org = orgsArray.getById(appraisalRef.current.organization);
                let ref = { ...appraisalRef.current, name: formOneState.name, projects: currentAppraisalProjects.value };
                
                //if projects were deleted, trigger cleanup
                const deletedProjects = editedAppraisalRef.current.projects.filter(p => {
                    return ref.projects.find(bep => bep.id === p.id) === undefined
                });
                
                if (deletedProjects.length > 0) {
                    await cleanUpDeletedProjects(appraisalRef.current.id, deletedProjects);
                }

                //determine what practice areas have been removed
                for (let i = 0; i < ref.projects.length; i++) {
                    let newProjectData = ref.projects[i];
                    let newPracticeAreas = newProjectData.practiceAreas;

                    let originalProject = editedAppraisalRef.current.projects.filter(p => p.id === newProjectData.id)[0];

                    //check to make sure the project was not just added
                    if(originalProject){
                        let originalPracticeAreas = originalProject.practiceAreas;

                        let deletedPracticeAreas = [];
                        for (let j = 0; j < originalPracticeAreas.length; j++) {
                            let correspondingPA = newPracticeAreas.filter(pa => pa.Id === originalPracticeAreas[j].Id)[0];
                            if (correspondingPA === undefined) {
                                deletedPracticeAreas.push(originalPracticeAreas[j])
                            }
                        }
    
                        //if practice areas were deleted, trigger cleanup
                        if (deletedPracticeAreas.length > 0) {
                            await cleanUpDeletedPracticeAreas(editedAppraisalRef.current.id, ref.projects[i].id, deletedPracticeAreas);
                        }
                    }
                }

                //clean up any practices removed from maturity level downgrade
                if (practicesToCleanFromMLLower.value.length > 0) {
                    for (let i = 0; i < ref.projects.length; i++) {
                        await cleanUpDeletedPracticesFromMLLower(editedAppraisalRef.current.id, ref.projects[i].id, ref.projects[i].name, practicesToCleanFromMLLower.value)
                    }
                }

                const apiRepo = new AppraisalRepository();
                const data = await apiRepo.updateOne(editedAppraisalRef.current, ref);
                if (!data.error) {
                    ref = { ...ref, id: editedAppraisalRef.current.id, organization: org };
                    appraisalsArray.replace(editedAppraisalRef.current.id, ref);
                } else {
                    console.log('Error editing appraisal: ', data.returnValue);
                }
                break;
            default:
                break;
        }

        setFinishLoading(false);
        closeCreateEditModal();
        refreshAppraisals();
    }

    async function cleanUpDeletedProjects(appraisalId, deletedProjects) {
        setModalOpen(true);
        setShowDeletingModal(true);
        setIsItemDeleting(true);
        setItemDeleteTitle("Deleting Project(s)");
        setItemDeleteButtonText("Deleting Project, please wait...");

        for (let i = 0; i < deletedProjects.length; i++) {
            let item = deletedProjects[i];
            setItemBeingDeleted("Deleting " + item.name);

            //clean items from appraisal
            setItemDeleteStatus("Deleting Practice Data...");
            const itemApiPath = `${Constants.APPRAISAL_ITEM_PATH}/${appraisalId}/${item.id}`
            const itemData = await API.get(Constants.API_PATH, itemApiPath, await getDefaultAPIHeaders());
            for (let j = 0; j < itemData.length; j++) {
                try {
                    let body = {
                        appraisal_item_id: itemData[j].appraisal_item_id,
                    };
                    await API.del(Constants.API_PATH, Constants.APPRAISAL_ITEM_PATH, await getDefaultAPIHeaders(body))
                }
                catch (e) {
                    console.log('Delete appraisal item failure' + e.message);
                }
            }

            //clean states from appraisal
            setItemDeleteStatus("Deleting State Data...");
            const stateApiPath = `${Constants.APPRAISAL_STATE_PATH}/${appraisalId}/${item.id}`
            const stateData = await API.get(Constants.API_PATH, stateApiPath, await getDefaultAPIHeaders());
            for (let j = 0; j < stateData.length; j++) {
                try {
                    let body = {
                        compound_id: stateData[j].compound_id,
                    };
                    await API.del(Constants.API_PATH, Constants.APPRAISAL_STATE_PATH, await getDefaultAPIHeaders(body))
                }
                catch (e) {
                    console.log('Delete appraisal state failure' + e.message);
                }
            }

            //clean evidence from appraisal
            setItemDeleteStatus("Deleting Evidence Data...");
            const evidenceApiPath = `${Constants.APPRAISAL_EVIDENCE_PATH}/${appraisalId}/${item.id}`
            const evidenceData = await API.get(Constants.API_PATH, evidenceApiPath, await getDefaultAPIHeaders());
            for (let j = 0; j < evidenceData.length; j++) {
                let body = {
                    appraisal_evidence_id: evidenceData[j].appraisal_evidence_id,
                };
                try {
                    await API.del(Constants.API_PATH, Constants.APPRAISAL_EVIDENCE_PATH, await getDefaultAPIHeaders(body));
                }
                catch (e) {
                    console.log('Delete appraisal evidence failure' + e);
                }
            }

            //clean interview questions from appraisal
            setItemDeleteStatus("Deleting Interview Data...");
            const interviewsApiPath = `${Constants.INTERVIEW_QUESTIONS_PATH}/${appraisalId}/${item.id}`
            const interviewsData = await API.get(Constants.API_PATH, interviewsApiPath, await getDefaultAPIHeaders());
            for (let j = 0; j < interviewsData.length; j++) {
                try {
                    let body = {
                        interview_question_id: interviewsData[j].interview_question_id,
                    };
                    await API.del(Constants.API_PATH, Constants.INTERVIEW_QUESTIONS_PATH, await getDefaultAPIHeaders(body))
                }
                catch (e) {
                    console.log('Delete appraisal interview question failure' + e.message);
                }
            }
        }
        setItemBeingDeleted("");
        setItemDeleteStatus("Project Deletion(s) Complete");
        setIsItemDeleting(false);
    }

    async function cleanUpDeletedPracticeAreas(appraisalId, projectId, deletedPracticeAreas) {
        setModalOpen(true);
        setShowDeletingModal(true);
        setIsItemDeleting(true);
        setItemDeleteTitle("Deleting Practice Areas");
        setItemDeleteButtonText("Deleting Practice Areas, please wait...");

        for (let i = 0; i < deletedPracticeAreas.length; i++) {
            setItemBeingDeleted("Deleting " + deletedPracticeAreas[i].Name);
            let nodesToDelete = [];

            //find all practice nodes associated with a practice area to clear
            let practicesToDelete = PRACTICE_AREA_RELATIONSHIPS[deletedPracticeAreas[i].Id];
            for (let j = 0; j < practicesToDelete.length; j++) {
                nodesToDelete.push(practicesToDelete[j].Id);
            }

            //add group nodes
            let groupIds = Object.values(PRACTICE_AREAS[deletedPracticeAreas[i].Id].LevelIds);
            for (let j = 0; j < groupIds.length; j++) {
                nodesToDelete.push(groupIds[j]);
            }

            //add practice area node
            nodesToDelete.push(deletedPracticeAreas[i].Id);

            for (let j = 0; j < nodesToDelete.length; j++) {
                let nodeInfoToDelete = {
                    appraisalId: appraisalId,
                    projectId: projectId,
                    nodeId: nodesToDelete[j]
                }

                let num = j+1;
                setItemDeleteStatus("Deleting Practice Area item " + num + " of " + nodesToDelete.length);

                //clean appraisalevidence table
                try {
                    const evidenceData = await API.get(Constants.API_PATH, getNodeAPIPath(nodeInfoToDelete, Constants.APPRAISAL_EVIDENCE_PATH), await getDefaultAPIHeaders());
                    for (let k = 0; k < evidenceData.length; k++) {
                        let body = {
                            appraisal_evidence_id: evidenceData[k].appraisal_evidence_id
                        };
                        await API.del(Constants.API_PATH, Constants.APPRAISAL_EVIDENCE_PATH, await getDefaultAPIHeaders(body));
                    }

                } catch (e) {
                    console.log('Failure clearing appraisalevidence: ' + e);
                }

                //clean appraisalitem table
                try {
                    const itemData = await API.get(Constants.API_PATH, getNodeAPIPath(nodeInfoToDelete, Constants.APPRAISAL_ITEM_PATH), await getDefaultAPIHeaders());
                    for (let k = 0; k < itemData.length; k++) {
                        let body = {
                            appraisal_item_id: itemData[k].appraisal_item_id
                        };
                        await API.del(Constants.API_PATH, Constants.APPRAISAL_ITEM_PATH, await getDefaultAPIHeaders(body));
                    }

                } catch (e) {
                    console.log('Failure clearing appraisalitem: ' + e);
                }

                //clean appraisalstate table
                try {
                    const stateData = await API.get(Constants.API_PATH, getNodeAPIPath(nodeInfoToDelete, Constants.APPRAISAL_STATE_PATH), await getDefaultAPIHeaders());
                    if (stateData.compound_id) {
                        let body = {
                            compound_id: stateData.compound_id
                        };
                        await API.del(Constants.API_PATH, Constants.APPRAISAL_STATE_PATH, await getDefaultAPIHeaders(body));
                    }

                } catch (e) {
                    console.log('Failure clearing appraisalstate: ' + e);
                }

                //clean interviewquestions table
                try {
                    const interviewData = await API.get(Constants.API_PATH, getNodeAPIPath(nodeInfoToDelete, Constants.INTERVIEW_QUESTIONS_PATH), await getDefaultAPIHeaders());
                    for (let k = 0; k < interviewData.length; k++) {
                        let body = {
                            interview_question_id: interviewData[k].interview_question_id
                        };
                        await API.del(Constants.API_PATH, Constants.INTERVIEW_QUESTIONS_PATH, await getDefaultAPIHeaders(body));
                    }

                } catch (e) {
                    console.log('Failure clearing interviewQuestions: ' + e);
                }
            }
        }
        setItemBeingDeleted("");
        setItemDeleteStatus("Practice Area Deletion Complete");
        setIsItemDeleting(false);
    }

    async function cleanUpDeletedPracticesFromMLLower(appraisalId, projectId, projectName,  deletedPractices) {
        setModalOpen(true);
        setShowDeletingModal(true);
        setIsItemDeleting(true);
        setItemDeleteTitle("Lowering Maturity Level");
        setItemDeleteButtonText("Lowering Maturity Level, please wait...");
        for (let i = 0; i < deletedPractices.length; i++) {
            let nodeInfoToDelete = {
                appraisalId: appraisalId,
                projectId: projectId,
                nodeId: deletedPractices[i]
            }
            let num = i+1;
            setItemBeingDeleted("Deleting items from project " + projectName + ": " + num + " of " + deletedPractices.length);

            //clean appraisalevidence table
            try {
                setItemDeleteStatus("Deleting Evidence Data...");
                const evidenceData = await API.get(Constants.API_PATH, getNodeAPIPath(nodeInfoToDelete, Constants.APPRAISAL_EVIDENCE_PATH), await getDefaultAPIHeaders());
                for (let k = 0; k < evidenceData.length; k++) {
                    let body = {
                        appraisal_evidence_id: evidenceData[k].appraisal_evidence_id
                    };
                    await API.del(Constants.API_PATH, Constants.APPRAISAL_EVIDENCE_PATH, await getDefaultAPIHeaders(body));
                }

            } catch (e) {
                console.log('Failure clearing appraisalevidence: ' + e);
            }

            //clean appraisalitem table
            try {
                setItemDeleteStatus("Deleting Practice Data...");
                const itemData = await API.get(Constants.API_PATH, getNodeAPIPath(nodeInfoToDelete, Constants.APPRAISAL_ITEM_PATH), await getDefaultAPIHeaders());
                for (let k = 0; k < itemData.length; k++) {
                    let body = {
                        appraisal_item_id: itemData[k].appraisal_item_id
                    };
                    await API.del(Constants.API_PATH, Constants.APPRAISAL_ITEM_PATH, await getDefaultAPIHeaders(body));
                }

            } catch (e) {
                console.log('Failure clearing appraisalitem: ' + e);
            }

            //clean appraisalstate table
            try {
                setItemDeleteStatus("Deleting State Data...");
                const stateData = await API.get(Constants.API_PATH, getNodeAPIPath(nodeInfoToDelete, Constants.APPRAISAL_STATE_PATH), await getDefaultAPIHeaders());
                if (stateData.compound_id) {
                    let body = {
                        compound_id: stateData.compound_id
                    };
                    await API.del(Constants.API_PATH, Constants.APPRAISAL_STATE_PATH, await getDefaultAPIHeaders(body));
                }

            } catch (e) {
                console.log('Failure clearing appraisalstate: ' + e);
            }

            //clean interviewquestions table
            try {
                setItemDeleteStatus("Deleting Interview Data...");
                const interviewData = await API.get(Constants.API_PATH, getNodeAPIPath(nodeInfoToDelete, Constants.INTERVIEW_QUESTIONS_PATH), await getDefaultAPIHeaders());
                for (let k = 0; k < interviewData.length; k++) {
                    let body = {
                        interview_question_id: interviewData[k].interview_question_id
                    };
                    await API.del(Constants.API_PATH, Constants.INTERVIEW_QUESTIONS_PATH, await getDefaultAPIHeaders(body));
                }

            } catch (e) {
                console.log('Failure clearing interviewQuestions: ' + e);
            }
        }
        setItemBeingDeleted("");
        setItemDeleteStatus("Maturity Level Lowering Complete");
        setIsItemDeleting(false);
    }

    const deselectPAs = () => {
        if(formTwoState.selectedProject){
            let currentlySelectedProject = Object.assign({}, formTwoState.selectedProject);

            if(currentlySelectedProject.practiceAreas){
                for (let i = 0; i < currentlySelectedProject.practiceAreas.length; i++) {
                    currentlySelectedProject.practiceAreas[i].SelectedForRemove = false;
                }
    
                setFormTwoState(prevState => ({ ...prevState, selectedProject: currentlySelectedProject }));
            }
        }

        if(formThreeState.selectedProject){
            let currentlySelectedProject = Object.assign({}, formThreeState.selectedProject);

            if(currentlySelectedProject.practiceAreas){
                for (let i = 0; i < currentlySelectedProject.practiceAreas.length; i++) {
                    currentlySelectedProject.practiceAreas[i].SelectedForRemove = false;
                }

                setFormThreeState(prevState => ({ ...prevState, selectedProject: currentlySelectedProject }));
            }
        }
    }

    const handlePreviousAppraisalAction = (state) => {
        //This shouldn't be possible, but it's worth guarding against
        // in case the previous button is pressed at state zero,
        // which should be invisible.
        if (state !== 0) {
            if(state === 2){

                deselectPAs();

                const practiceAreas = getPracticeAreas(appraisalRef.current.view);
                availablePracticeAreasRef.current = practiceAreas;

                const selectedProject = currentAppraisalProjects.value[0];
                let filtered = practiceAreas.filter(pa => {
                    return selectedProject.practiceAreas.find(ppa => ppa.Id === pa.Id) === undefined
                });
                const nonCurrentProjects = currentAppraisalProjects.value.filter(p => p.id !== selectedProject.id);
                const mappedPractices = nonCurrentProjects.map(p => {
                    return p.practiceAreas
                }).reduce((value, next) => value.concat(next), []);

                if (mappedPractices.length > 0) {
                    for (let i = 0; i < filtered.length; i++) {
                        if (mappedPractices.find(pa => pa.Id === filtered[i].Id)) {
                            filtered[i].MappedByAnyProject = true;
                        }
                    }
                }

                availablePracticeAreas.setValue(filtered);
                setFormTwoState(prevState => ({ ...prevState, selectedProject: appraisalRef.current.projects[0] }));
            }
            setModalMachineState(modalMachineState - 1);
        }
    }

    const handleEditAppraisal = async () => {
        practicesToCleanFromMLLower.setValue([]);
        const items = appraisalsArray.value.filter(e => e.selected === true);
        var item = items[0];

        const appraisalRepo = new AppraisalRepository();
        const result = await appraisalRepo.getOne(item.id);
        if (!result.error) {
            const internalAppraisal = result.returnValue;
            appraisalRef.current = internalAppraisal;
            if (internalAppraisal.view !== 'Custom') {
                availablePracticeAreas.setValue(getPracticeAreas(internalAppraisal.view));
                availablePracticeAreasRef.current = availablePracticeAreas.value;
            }
            currentAppraisalProjects.setValue(internalAppraisal.projects);
            const levelRegex = /Level [\d]/;
            const typeRegex = /Services/;

            const levelMatches = levelRegex.exec(internalAppraisal.view);
            const typeMatches = typeRegex.exec(internalAppraisal.view);

            let level = "";
            if (levelMatches.length > 0) {
                level = levelMatches[0];
            }
            setFormOneState({
                name: internalAppraisal.name,
                nameError: false,
                nameTooltip: '',
                devSelected: typeMatches !== null ? false : true,
                svcSelected: typeMatches !== null ? true : false,
                level: level,
                levelError: false,
                levelTooltip: '',
                projectName: '',
                projectNameError: false,
                projectNameTooltip: '',
                organization: internalAppraisal.organization,
                organizationError: false,
                organizationTooltip: '',
                projectError: false,
                projectTooltip: ''
            });
            setModalTitle("Edit");
            // Taking a deep copy of the apprisal information before the edit begins,
            // so that I can use it to determine what changed by comparing it the 
            // current appraisal ref object.
            editedAppraisalRef.current = JSON.parse(JSON.stringify(internalAppraisal));
            setShowCreateEditModal(true);
        } else {
            console.log("error getting appraisal: ", result.returnValue);
        }
    }

    const updateSelected = (e, id) => {
        let item = appraisalsArray.getById(id);
        item.selected = e.target.checked;
        appraisalsArray.replace(id, item);
    };

    const hasOrgSelected = () => {
        return props.org;
    }

    const singleItemSelected = () => {
        return appraisalsArray.value.filter(x => x.selected === true).length === 1;
    }

    const itemsAreSelected = () => {
        return appraisalsArray.value.filter(x => x.selected === true).length > 0;
    }

    const handleInterviewSetup = async () => {
        const items = appraisalsArray.value.filter(e => e.selected === true);
        var item = items[0];
        try {
            const currentInterviews = await API.get(Constants.API_PATH, `${Constants.INTERVIEW_PATH}/${item.id}`, await getDefaultAPIHeaders());
            const internal = currentInterviews.map(is => {
                return {
                    id: is.interview_session_id,
                    interviewee: is.interviewee,
                    hours: is.hours
                }
            });
            sessionSnapshotRef.current = JSON.parse(JSON.stringify(internal));
            interviewSessionsArray.setValue(internal);
            interviewIdRef.current = item.id;
            setShowInterviewSessionsModal(true);
        } catch (e) {
            console.log(e);
        }
    }

    const handleCloseInterviewSessionsModal = () => {
        interviewSessionsArray.clear();
        interviewIdRef.current = ""
        setShowInterviewSessionsModal(false);
    }

    const toggleModalOpen = () => {
        setModalOpen(!modalOpen);
    }

    const openClosePrompt = () => {
        if(!modalOpen){
            setModalOpen(true);
            setShowClosePrompt(true);
        }
    }

    const confirmClosePrompt = () => {
        setModalOpen(false);
        closeCreateEditModal();
    }

    const hideClosePrompt = () => {
        setModalOpen(false);
        setShowClosePrompt(false);
    }

    const hidePAWarningModal = () => {
        setModalOpen(false);
        setShowPAWarningModal(false);
    }

    const appraisalTableItems = appraisalsArray.value.map(a => {
        return (
            <tr key={a.id}>
                <AppraisalItem
                    appraisal={a}
                    updateSelected={updateSelected}
                    updateDelete={handleUpdateDelete}
                // handleEditClicked={handleEditAppraisal}
                // handleInterviewsClicked={handleInterviewSetup}
                />
            </tr>
        );
    });

    switch (modalMachineState) {
        case 0:
            modalBody = (<AppraisalStateOne
                type={modalTitle}
                autofocusRef={autoFocus}
                projects={currentAppraisalProjects}
                orgs={orgsArray.value}
                formState={formOneState}
                setFormState={setFormOneState}
                checkAppraisalClashes={checkAppraisalClashes}
                handleMaturityLevelDowngrade={handleMaturityLevelDowngrade}
                toggleModalOpen={toggleModalOpen}
                modalOpen={modalOpen}
            />);
            modalFooter = (
                <WizardFooter prevVisible={false}
                    actionText="Next"
                    handleNextAction={handleNextAppraisalAction}
                    handleStateChange={handlePreviousAppraisalAction}
                    currentState={modalMachineState}
                    isNextLoading={false}
                    modalOpen={modalOpen}
                />
            );
            break;
        case 1:
            modalBody = (<AppraisalStateTwo
                practiceAreas={availablePracticeAreas}
                projects={currentAppraisalProjects}
                formState={formTwoState}
                setFormState={setFormTwoState}
                view={appraisalRef.current.view}
                getInitialPracticeAreas={getInitialPracticeAreas}
                toggleModalOpen={toggleModalOpen}
                modalOpen={modalOpen}
            />);
            modalFooter = (
                <WizardFooter prevVisible={true}
                    actionText="Next"
                    handleNextAction={handleNextAppraisalAction}
                    handleStateChange={handlePreviousAppraisalAction}
                    currentState={modalMachineState}
                    isNextLoading={true}
                    isLoading={finishLoading}
                    nextDisabled={finishActionDisabled}
                    modalOpen={modalOpen}
                />
            );
            break;
        case 2:
            modalBody = (<AppraisalStateThree
                practiceAreas={availableOptionalPracticeAreas}
                projects={currentAppraisalProjects}
                formState={formThreeState}
                setFormState={setFormThreeState}
                view={appraisalRef.current.view}
                getInitialOptionalPracticeAreas={getInitialOptionalPracticeAreas}
                toggleModalOpen={toggleModalOpen}
                modalOpen={modalOpen}
            />);
            modalFooter = (
                <WizardFooter prevVisible={true}
                    actionText="Finish"
                    handleNextAction={handleFinishAppraisalAction}
                    handleStateChange={handlePreviousAppraisalAction}
                    currentState={modalMachineState}
                    isNextLoading={true}
                    isLoading={finishLoading}
                    nextDisabled={finishActionDisabled}
                    modalOpen={modalOpen}
                />
            );
            break;
        default:
            break;
    }

    return (
        <React.Fragment>
            <Container className='container-sm'>
                <Row>
                    <FormLabel
                        style={{
                            color: 'dimgray',
                            fontSize: '16px',
                            textAlign: 'left',
                            fontWeight: 'bold'
                        }}>
                        Appraisals
                                </FormLabel>
                </Row>
                <Row>
                    <ButtonToolbar style={{ flexWrap: 'nowrap' }}>
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Edit Appraisal</Tooltip>}>
                            <span className="d-inline-block">
                                <Button
                                    variant="light"
                                    type="submit"
                                    size="sm"
                                    style={singleItemSelected() ? enabledStyle : disabledStyle}
                                    disabled={!singleItemSelected()}
                                    onClick={e => handleEditAppraisal(e)}>
                                    <img
                                        src={editApprIcon}
                                        width="25"
                                        height="25"
                                        className="d-inline-block align-top"
                                        alt="CAP logo and text"
                                        color="royalblue"
                                    />{' '}
                                </Button>
                            </span>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete Appraisal</Tooltip>}>
                            <span className="d-inline-block">
                                <Button
                                    variant="light"
                                    type="submit"
                                    size="sm"
                                    style={itemsAreSelected() ? enabledStyle : disabledStyle}
                                    onClick={e => handleShowDeleteModal(e)}
                                    disabled={!itemsAreSelected()}>
                                    <img
                                        src={deleteApprIcon}
                                        width="25"
                                        height="25"
                                        className="d-inline-block align-top"
                                        alt="CAP logo and text"
                                        color="royalblue"
                                    />{' '}
                                </Button>
                            </span>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Appraisal</Tooltip>}>
                            <span className="d-inline-block">
                                <Button
                                    variant="light"
                                    type="submit"
                                    size="sm"
                                    style={hasOrgSelected() ? enabledStyle : disabledStyle}
                                    disabled={!hasOrgSelected()}
                                    onClick={e => handleCreateAppraisal(e)}>
                                    <img
                                        src={addApprIcon}
                                        width="25"
                                        height="25"
                                        className="d-inline-block align-top"
                                        alt="CAP logo and text"
                                        color="royalblue"
                                    />{' '}
                                </Button>
                            </span>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Edit Interview Sessions</Tooltip>}>
                            <span className="d-inline-block">
                                <Button
                                    variant="light"
                                    type="submit"
                                    size="sm"
                                    style={singleItemSelected() ? enabledStyle : disabledStyle}
                                    onClick={e => handleInterviewSetup(e)}
                                    disabled={!singleItemSelected()}>
                                    <img
                                        src={editInterviewSessions}
                                        width="25"
                                        height="25"
                                        className="d-inline-block align-top"
                                        alt="CAP logo and text"
                                        color="royalblue"
                                    />{' '}
                                </Button>
                            </span>
                        </OverlayTrigger>
                    </ButtonToolbar>
                </Row>
                <Row>
                    <LoadingComponent isLoading={tableLoading} iconSize={60}>
                        <Table size='sm' style={{ tableLayout: 'fixed' }} >
                            <div style={{ maxWidth: '800px' }}>
                                <thead className='thead'>
                                    <tr>
                                        <th style={{ width: '30px', textAlign: 'left' }}></th>
                                        <th style={{ width: '240px', textAlign: 'left' }}>Appraisal</th>
                                        <th style={{ width: '350px', textAlign: 'left' }}>View</th>
                                    </tr>
                                </thead>
                            </div>
                            <div style={{ maxWidth: '800px', overflow: 'auto' }}>
                                <tbody style={{ flexWrap: 'nowrap' }}>
                                    {appraisalTableItems}
                                </tbody>
                            </div>
                        </Table>
                    </LoadingComponent>
                </Row>
                <ConfirmDeleteModal
                    showDeleteModal={showDeleteModal}
                    close={closeDeleteModal}
                    deleteAction={handleDelete}
                    deleteQuestion={DELETE_QUESTION}
                    deleteImpact={DELETE_IMPACT}
                />
                <Modal show={showCreateEditModal} backdrop="static" onHide={(e) => openClosePrompt()} dialogClassName="appraisalModalStyle" onEntered={() => autoFocus.current.focus()}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ fontSize: '18px', color: 'dimgray' }}>
                            <img
                                src={require('../assets/icon.png')}
                                width="30"
                                height="30"
                                className="d-inline-block align-middle"
                                alt="CAP logo"
                            />
                            {modalTitle + ' Appraisal'}</Modal.Title>
                    </Modal.Header>
                    <Row className="d-flex justify-content-center">
                        <Col className='col-md-12'>
                            <Modal.Body>
                                {modalBody}
                            </Modal.Body>
                        </Col>
                    </Row>
                    <Modal.Footer style={{ backgroundColor: 'rgb(0,168,168)' }}>
                        {modalFooter}
                    </Modal.Footer>
                </Modal>
                
                <Modal show={showDeletingModal} onHide={(e) => tryCloseDeletingModal(e)} >
                    <Modal.Header >
                        <Modal.Title style={{ fontSize: '18px', color: 'dimgray' }}>
                            <img
                                src={require('../assets/icon.png')}
                                width="30"
                                height="30"
                                className="d-inline-block align-middle"
                                alt="CAP logo"
                            />{itemDeleteTitle}</Modal.Title>
                    </Modal.Header>
                    <Row className="d-flex justify-content-center">
                        <Col className='col-md-10'>
                            <Modal.Body>
                                <text style={{ fontSize: '14px', color: 'dimgray' }}>{itemBeingDeleted}</text>
                                <br></br>
                                <text style={{ fontSize: '12px', color: 'dimgray' }}>{itemDeleteStatus}</text>
                            </Modal.Body>
                        </Col>
                    </Row>
                    <Modal.Footer style={{ backgroundColor: 'rgb(0,168,168)' }}>
                        <Button
                            variant="outline-light"
                            disabled={isItemDeleting}
                            size='sm'
                            onClick={(e) => tryCloseDeletingModal(e)}>
                            {isItemDeleting &&
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size='sm'
                                    role="status"
                                    aria-hidden="true"
                                />}
                            {getDeletingButtonText()}
                        </Button>{' '}
                    </Modal.Footer>
                </Modal>

                <Modal show={showClosePrompt} backdrop="static" onHide={hideClosePrompt} bsSize="large">
                    <Modal.Header>                
                        <Modal.Title style={{fontSize:'18px', color:'dimgray'}}>
                            <img
                                src={require('../assets/icon.png')}
                                width="30"
                                height="30"
                                className="d-inline-block align-middle"
                                alt="CAP logo"/>Confirm Close</Modal.Title>
                    </Modal.Header>
                    <Row className= "d-flex justify-content-center">
                    <Col className='col-md-10'>
                    <Modal.Body>            
                        <text style={{fontSize:'14px', color:'dimgray'}}>Are you sure you want to close this window?</text> 
                        <br></br>               
                        <text style={{fontSize:'12px',color:'dimgray'}}>Unsaved changes will be lost.</text>
                    </Modal.Body>
                    </Col>
                    </Row>            
                    <Modal.Footer style={{backgroundColor:'rgb(0,168,168)'}}>
                        <Button
                            variant="outline-light"
                            size='sm'                
                            onClick={() => confirmClosePrompt()}
                            >Yes</Button>{' '}   
                        <Button
                            variant="outline-light"
                            size='sm'                    
                            onClick={hideClosePrompt}
                            >No</Button>{' '}                 
                    </Modal.Footer>
                </Modal>

                <Modal show={showPAWarningModal} backdrop="static" onHide={hidePAWarningModal} bsSize="large">
                    <Modal.Header>                
                        <Modal.Title style={{fontSize:'18px', color:'dimgray'}}>
                            <img
                                src={require('../assets/icon.png')}
                                width="30"
                                height="30"
                                className="d-inline-block align-middle"
                                alt="CAP logo"/>Invalid Options</Modal.Title>
                    </Modal.Header>
                    <Row className= "d-flex justify-content-center">
                    <Col className='col-md-10'>
                    <Modal.Body>            
                        <text style={{fontSize:'14px', color:'dimgray'}}>The following projects have no assigned practice areas:</text> 
                        <br></br>               
                        <span style={{fontSize:'14px'}}>{projectsWithNoPA}</span>
                    </Modal.Body>
                    </Col>
                    </Row>            
                    <Modal.Footer style={{backgroundColor:'rgb(0,168,168)'}}>
                        <Button
                            variant="outline-light"
                            size='sm'                
                            onClick={() => hidePAWarningModal()}
                            >OK</Button>{' '}                 
                    </Modal.Footer>
                </Modal>

                <InterviewSessionsModal
                    close={handleCloseInterviewSessionsModal}
                    showModal={showInterviewSessionsModal}
                    interviewSessions={interviewSessionsArray}
                    appraisalId={interviewIdRef.current}
                    snapshot={sessionSnapshotRef.current}
                />
            </Container>
        </React.Fragment >
    );
}
export default Appraisals;