import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Spinner, FormControl, Row, Col } from 'react-bootstrap';
import { AppraisalRepository, AppraisalStateRepository } from '../utils/repositories';
import uuid from 'uuid';
import { API } from 'aws-amplify';
import { modelContentUtils, Constants, getDefaultAPIHeaders } from '../utils/helpers';
import { PRACTICES, PRACTICE_AREA_RELATIONSHIPS } from '../model/modelcontent';
import '../modal.css';

function GenerateRollupModal(props){

    const [actionLoading, setActionLoading] = useState(false);
    const [rollupType, setRollupType] = useState("benchSustain");
    const [resultText, setResultText] = useState("");
    const [showFinishedModal, setShowFinishedModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [practicesNotCharacterizedList, setPracticesNotCharacterizedList] = useState(""); 
    const [practicesIncorrectlyCharacterizedList, setPracticesIncorrectlyCharacterizedList] = useState(""); 
    const [rollUpTypeText, setRollUpTypeText] = useState(""); 
    

    //reset when modal is shown
    useEffect(()=>{
        if(props.showModal === true){
            setShowFinishedModal(false);
            setShowErrorModal(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showModal]);

    const handleRollupTypeChanged = (value) => {
        setRollupType(value);
    }

    function getPracticeAreas(projects){
        const pas = modelContentUtils.getPracticeAreasFromAppraisalProjects(projects, true);
        //Pulling only the information I want from the utils call, which returns everything
        const modifiedPas = pas.map(pa=>{
            return(
                {
                    Name: pa.Name,
                    Id: pa.Id,
                    SelectedForAdd: false,
                    SelectedForRemove: false,
                    SvcExclusive: pa.SvcExclusive,
                    DevExclusive: pa.DevExclusive,
                    MappedByAnyProject: false
                }
            )
        });
        return modifiedPas;
    }

    const handleClick = async () => {

        setActionLoading(true);

        const currentAppraisalId = localStorage.getItem("selectedAppraisal");
        let availablePracticeAreas = [];
        let currentAppraisalProjects = [];
        let maturityLevel = "";

        if(currentAppraisalId){

            if(rollupType === "benchSustain"){
                setRollUpTypeText("Benchmark/Sustainment");
            } 
            else if (rollupType === "evaluation"){
                setRollUpTypeText("Evaluation");
            }
            const appraisalRepo = new AppraisalRepository();
            const stateRepo = new AppraisalStateRepository();
            const data = await appraisalRepo.getOne(currentAppraisalId, false);

            if(!data.error){
                //get the current appraisal data
                const currentAppraisal = data.returnValue;
                currentAppraisalProjects = currentAppraisal.projects;
                maturityLevel = modelContentUtils.getMaturityLevelFromView(currentAppraisal.view);
                availablePracticeAreas = getPracticeAreas(currentAppraisalProjects);

                //check to see if we need to remove an existing roll-up project
                let existingRollUpId = "";
                currentAppraisalProjects.forEach(p =>{
                    if(p.name.toString() === "Organizational Roll-up"){
                        existingRollUpId = p.id;
                    }
                });

                //remove existing if found
                if(existingRollUpId !== ""){
                    currentAppraisalProjects = currentAppraisalProjects.filter((v) => v && v.id !== existingRollUpId)
                }
                    
                //gather state data and check to see if we can roll-up
                //to rollup all practices must be characterized and match the type of rollup selected
                let anyUncharacterized = false;
                let anyInvalidCharacterizations = false;
                let practicesNotCharacterized = [];
                let practicesInvalidCharacterization = [];
                let allAppraisalStateData = [];

                for(let i = 0; i < currentAppraisalProjects.length; i++) {

                    //get all state entries for the current project
                    let stateEntries = "";
                    const response = await stateRepo.getAll(currentAppraisal.id, currentAppraisalProjects[i].id);
                    if(!response.error){
                        stateEntries = response.returnValue;
                    }
                    
                    for(let j = 0; j < currentAppraisalProjects[i].practiceAreas.length; j++){

                        //get all practices available to the current practice area and maturity level
                        let currentPracticeArea = currentAppraisalProjects[i].practiceAreas[j];
                        let practices = PRACTICE_AREA_RELATIONSHIPS[currentPracticeArea].filter(x => x.Level <= maturityLevel);

                        for(let k = 0; k < practices.length; k++){

                            //see if there is a database entry for the practice
                            let correspondingStateEntry = stateEntries.filter(x => x.nodeId === practices[k].Id)
                            if(correspondingStateEntry.length > 0){

                                allAppraisalStateData.push(correspondingStateEntry[0]);
                                let practiceState = correspondingStateEntry[0].state;

                                //the practice is uncharacterized - do not complete rollup
                                if(practiceState === "Not Examined"){
                                    anyUncharacterized = true;
                                    practicesNotCharacterized.push(currentAppraisalProjects[i].name + ": " + PRACTICES[practices[k].Id].Abbreviation);
                                }
                                else{
                                    if(rollupType === "benchSustain"){
                                        //the practice must be characterized with a benchmark/sustainment option to rollup
                                        if(practiceState.toString() !== "Fully Meets" 
                                            && practiceState.toString() !== "Largely Meets"
                                            && practiceState.toString() !== "Partially Meets"
                                            && practiceState.toString() !== "Does Not Meet"
                                            && practiceState.toString() !== "Not Yet Implemented"){
                                            anyInvalidCharacterizations = true;
                                            practicesInvalidCharacterization.push(currentAppraisalProjects[i].name + ": " + PRACTICES[practices[k].Id].Abbreviation);
                                        }
                                    } 
                                    else if (rollupType === "evaluation"){
                                        //the practice must be characterized with an evaluation option to rollup
                                        if(practiceState.toString() !== "Major Issues" 
                                            && practiceState.toString() !== "Minor Issues"
                                            && practiceState.toString() !== "No Issues"){
                                            anyInvalidCharacterizations = true;
                                            practicesInvalidCharacterization.push(currentAppraisalProjects[i].name + ": " + PRACTICES[practices[k].Id].Abbreviation);
                                        }
                                    }
                                }
                            }
                            else{
                                //the practice is uncharacterized - do not complete rollup
                                anyUncharacterized = true;
                                practicesNotCharacterized.push(currentAppraisalProjects[i].name + ": " + PRACTICES[practices[k].Id].Abbreviation);
                            }
                        }
                    }
                }
                //roll up could not complete due to errors
                if(anyUncharacterized || anyInvalidCharacterizations){
                    setActionLoading(false);

                    setPracticesNotCharacterizedList(practicesNotCharacterized.join("\n"));
                    setPracticesIncorrectlyCharacterizedList(practicesInvalidCharacterization.join("\n"));
                    setShowErrorModal(true);
                }
                else{
                    //create the new roll-up project object
                    const newProject = {
                        id: uuid.v1(),
                        name: "Organizational Roll-up",
                        practiceAreas: availablePracticeAreas
                    };
                    
                    //add the new rollup project to the appraisal
                    currentAppraisalProjects = [...currentAppraisalProjects, newProject];
                    let newAppraisal = {...currentAppraisal, name: currentAppraisal.name, projects: currentAppraisalProjects};
                    await appraisalRepo.updateOne(currentAppraisal, newAppraisal);
                    const data = await appraisalRepo.getOne(currentAppraisalId);

                    let updatedProjects = data.returnValue.projects;
                    let updatedRollUpId = "";
                    updatedProjects.forEach(p =>{
                        if(p.name.toString() === "Organizational Roll-up"){
                            updatedRollUpId = p.id;
                        }
                    });
                    
                    //add characterizations to the project
                    for(let i = 0; i < newProject.practiceAreas.length; i++){
                        let newPA = newProject.practiceAreas[i];
                        let practices = PRACTICE_AREA_RELATIONSHIPS[newPA.Id].filter(x => x.Level <= maturityLevel);
                        for(let j = 0; j < practices.length; j++){

                            //this is the state data in the database for each specific node
                            let nodeStateEntries = allAppraisalStateData.filter(x => x.nodeId === practices[j].Id)

                            //if theres only 1 node, write it
                            if(nodeStateEntries.length === 1){
                                const body = {
                                    appraisal_id: currentAppraisal.id,
                                    project_id: updatedRollUpId,
                                    node_id: nodeStateEntries[0].nodeId,
                                    node_state: nodeStateEntries[0].state,
                                    revision_number: 0
                                };
                                await API.put(Constants.API_PATH, Constants.APPRAISAL_STATE_PATH, await getDefaultAPIHeaders(body))
                            }
                            //more than 1 node we must follow roll up rules to characterize
                            else{
                                // All Fully Meets (FM) or Not Yet (NY) with at least 1 FM = Fully Meets
                                // All Largely Meets (LM) or FM or NY with at least 1 LM = Largely Meets
                                // At least one Largely Meets (LM) or Fully Meets (FM), AND at least one Partially Meets (PM) or Does not Meet (DM) = Not Examined
                                // All PM, DM, or NY with at least one PM
                                // All DM or NY with at least one DM
                                // All NY
                                if(rollupType === "benchSustain"){
                                    let finalState = "";
                                    let nodeStates = [];
                                    for(let k = 0; k < nodeStateEntries.length; k++){
                                        nodeStates.push(nodeStateEntries[k].state.toString());
                                    }
                                    if(nodeStates.includes("Fully Meets") || nodeStates.includes("Largely Meets")){
                                        if(nodeStates.includes("Partially Meets") || nodeStates.includes("Does Not Meet")){
                                            finalState = "Not Examined";
                                        } 
                                        else if(nodeStates.includes("Largely Meets")){
                                            finalState = "Largely Meets";
                                        } 
                                        else{
                                            finalState = "Fully Meets";
                                        }
                                    }
                                    else if (nodeStates.includes("Partially Meets") || nodeStates.includes("Does Not Meet") || nodeStates.includes("Not Yet Implemented")){
                                        if(nodeStates.includes("Partially Meets")){
                                            finalState = "Partially Meets";
                                        } 
                                        else if(nodeStates.includes("Does Not Meet")){
                                            finalState = "Does Not Meet";
                                        } 
                                        else{
                                            finalState = "Not Yet Implemented";
                                        }
                                    }

                                    const body = {
                                        appraisal_id: currentAppraisal.id,
                                        project_id: updatedRollUpId,
                                        node_id: nodeStateEntries[0].nodeId,
                                        node_state: finalState,
                                        revision_number: 0
                                    };
                                    await API.put(Constants.API_PATH, Constants.APPRAISAL_STATE_PATH, await getDefaultAPIHeaders(body))
                                } 
                                //evaluation rules: 
                                // all green = green
                                // all red = red
                                // all yellow = yellow
                                // all other conditions = not examined
                                else if (rollupType === "evaluation"){
                                    let firstState = nodeStateEntries[0].state.toString();
                                    let conflictingState = false;
                                    for(let k = 0; k < nodeStateEntries.length; k++){
                                        if(nodeStateEntries[k].state.toString() !== firstState){
                                            conflictingState = true;
                                        }
                                    }
                                    let state = conflictingState ? "Not Examined" : firstState;

                                    const body = {
                                        appraisal_id: currentAppraisal.id,
                                        project_id: updatedRollUpId,
                                        node_id: nodeStateEntries[0].nodeId,
                                        node_state: state,
                                        revision_number: 0
                                    };
                                    await API.put(Constants.API_PATH, Constants.APPRAISAL_STATE_PATH, await getDefaultAPIHeaders(body))
                                }
                            }
                        }
                    }
                    
                    //get all findings data for the appraisal
                    const apiPath = `${Constants.APPRAISAL_ITEM_PATH}/${currentAppraisalId}`
                    const findingsData = await API.get(Constants.API_PATH, apiPath, await getDefaultAPIHeaders());

                    //add findings to the project
                    for(let i = 0; i < newProject.practiceAreas.length; i++){
                        let newPA = newProject.practiceAreas[i];
                        let practices = PRACTICE_AREA_RELATIONSHIPS[newPA.Id].filter(x => x.Level <= maturityLevel);
                        for(let j = 0; j < practices.length; j++){

                            //this is the findings data in the database for each specific node
                            let nodeFindingsEntries = findingsData.filter(x => x.node_id === practices[j].Id)

                            let notes = nodeFindingsEntries.filter(x => x.item_type === "notes");
                            let strengths = nodeFindingsEntries.filter(x => x.item_type === "strengths");
                            let weaknesses = nodeFindingsEntries.filter(x => x.item_type === "weaknesses");
                            let improvementOps = nodeFindingsEntries.filter(x => x.item_type === "improvement_opportunities");

                            let notesRollUpItems = [];
                            for(let i = 0; i < notes.length; i++){
                                let currentEntryProject = updatedProjects.filter(x => x.id === notes[i].project_id)[0];
                                if(currentEntryProject){
                                    let projectName = currentEntryProject.name;
                                    if(projectName.toString() !== "Organizational Roll-up"){
                                        notesRollUpItems.push(projectName + ": " + notes[i].item_data);
                                    }
                                }
                            }
                            let notesRollUpEntry = notesRollUpItems.sort().join("\n");
                            if(notesRollUpEntry !== ""){
                                const body = {
                                    appraisal_id: currentAppraisal.id,
                                    project_id: updatedRollUpId,
                                    node_id: practices[j].Id,
                                    item_type: "notes",
                                    item_data: notesRollUpEntry,
                                    revision_number: 0
                                }
                                await API.put(Constants.API_PATH, Constants.APPRAISAL_ITEM_PATH, await getDefaultAPIHeaders(body))
                            }

                            let strengthRollUpItems = [];
                            for(let i = 0; i < strengths.length; i++){
                                let currentEntryProject = updatedProjects.filter(x => x.id === strengths[i].project_id)[0];
                                if(currentEntryProject){
                                    let projectName = currentEntryProject.name;
                                    if(projectName.toString() !== "Organizational Roll-up"){
                                        strengthRollUpItems.push(projectName + ": " + strengths[i].item_data);
                                    }
                                }
                            }
                            let strengthRollUpEntry = strengthRollUpItems.sort().join("\n");

                            if(strengthRollUpEntry !== ""){
                                const body = {
                                    appraisal_id: currentAppraisal.id,
                                    project_id: updatedRollUpId,
                                    node_id: practices[j].Id,
                                    item_type: "strengths",
                                    item_data: strengthRollUpEntry,
                                    revision_number: 0
                                }
                                await API.put(Constants.API_PATH, Constants.APPRAISAL_ITEM_PATH, await getDefaultAPIHeaders(body))
                            }
                            
                            let weaknessRollUpItems = [];
                            for(let i = 0; i < weaknesses.length; i++){
                                let currentEntryProject = updatedProjects.filter(x => x.id === weaknesses[i].project_id)[0];
                                if(currentEntryProject){
                                    let projectName = currentEntryProject.name;
                                    if(projectName.toString() !== "Organizational Roll-up"){
                                        weaknessRollUpItems.push(projectName + ": " + weaknesses[i].item_data);
                                    }
                                }
                            }
                            let weaknessRollUpEntry = weaknessRollUpItems.sort().join("\n");
                            if(weaknessRollUpEntry !== ""){
                                const body = {
                                    appraisal_id: currentAppraisal.id,
                                    project_id: updatedRollUpId,
                                    node_id: practices[j].Id,
                                    item_type: "weaknesses",
                                    item_data: weaknessRollUpEntry,
                                    revision_number: 0
                                }
                                await API.put(Constants.API_PATH, Constants.APPRAISAL_ITEM_PATH, await getDefaultAPIHeaders(body))
                            }


                            let improvementOpRollUpItems = [];
                            for(let i = 0; i < improvementOps.length; i++){
                                let currentEntryProject = updatedProjects.filter(x => x.id === improvementOps[i].project_id)[0];
                                if(currentEntryProject){
                                    let projectName = currentEntryProject.name;
                                    if(projectName.toString() !== "Organizational Roll-up"){
                                        improvementOpRollUpItems.push(projectName + ": " + improvementOps[i].item_data);
                                    }
                                }
                            }
                            let improvementOpRollUpEntry = improvementOpRollUpItems.sort().join("\n");
                            if(improvementOpRollUpEntry !== ""){
                                const body = {
                                    appraisal_id: currentAppraisal.id,
                                    project_id: updatedRollUpId,
                                    node_id: practices[j].Id,
                                    item_type: "improvement_opportunities",
                                    item_data: improvementOpRollUpEntry,
                                    revision_number: 0
                                }
                                await API.put(Constants.API_PATH, Constants.APPRAISAL_ITEM_PATH, await getDefaultAPIHeaders(body))
                            }
                        }
                    }
                    

                    setResultText("Roll-up successfully generated.");

                    setActionLoading(false);
                    setShowFinishedModal(true);
                }
            }
        }
    }

    const onAcknowledge = () => {
        setShowFinishedModal(false);
        setShowErrorModal(false);
        props.setShowModal(false);
        window.location.reload();
    }

    const tryHideModal = (e) => {
        if(!actionLoading){
            props.setShowModal(false)
        }
    }

    return(
        <Modal show={props.showModal}
            onHide={(e)=>tryHideModal(e)} 
            animation 
            disabled={showFinishedModal || showErrorModal}
            >
            <Modal.Header closeButton disabled={showFinishedModal || showErrorModal || actionLoading}>
                <Modal.Title style={{fontSize:'18px', color:'dimgray'}}> 
                    <img
                    src={require('../assets/icon.png')}
                    width="30"
                    height="30"
                    className="d-inline-block align-middle"
                    alt="CAP logo"
                />{' '}Generate Organizational Roll-up</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group as={Row} className="d-flex justify-content-center">
                    <Col sm={2} style={{marginLeft:"-100px"}}></Col>
                        <Form.Label 
                            column sm='3' 
                            style={{ color: 'white', 
                            backgroundColor: 'rgb(0,168,168)', 
                            fontSize: '16px', 
                            textAlign: 'center'}}>
                        Roll-up Type
                    </Form.Label>
                    <Col sm={7} style={{ textAlign: 'left' }}>
                        <FormControl
                            as="select"
                            style={{ borderRightColor: 'white', borderTopColor: 'white' }}
                            onChange={(e) => handleRollupTypeChanged(e.target.value)}>
                            <option value='benchSustain'>Benchmark/Sustainment</option>
                            <option value='evaluation'>Evaluation</option>
                        </FormControl>
                    </Col>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer style={{backgroundColor:'rgb(0,168,168)'}}>
                <Button
                    variant="outline-light"
                    size='sm'
                    disabled={showFinishedModal || showErrorModal}
                    onClick={(event)=>handleClick()}
                    >
                    {actionLoading &&
                        <Spinner
                            as="span"
                            animation="border"
                            size='sm'
                            role="status"
                            aria-hidden="true"
                        />}
                    {!actionLoading ? "Generate Roll-up" : ' This may take a while. Generating Roll-up...'}
                </Button>{' '}            
            </Modal.Footer>


            <Modal bsSize="small"  
                show={showFinishedModal} 
                onHide={(e)=>setShowFinishedModal(false)} 
                animation
                style={{backgroundColor: 'rgba(0,0,0,0.2)'}}
                size='sm'>
                <Modal.Header>
                    <Modal.Title className='w-100 text-center' style={{fontSize:'16px', color:'dimgray'}}>{resultText}</Modal.Title>
                </Modal.Header>
                <Modal.Footer style={{backgroundColor:'rgb(0,168,168)'}}>
                <Button
                    variant="outline-light"
                    size='sm'                
                    onClick={(event)=>onAcknowledge()}
                    >Ok</Button>                   
                </Modal.Footer>
            </Modal>

            <Modal 
                show={showErrorModal} 
                showClose
                onHide={(e)=>setShowErrorModal(false)} 
                animation
                style={{backgroundColor: 'rgba(0,0,0,0.2)'}}
                >
                <Modal.Header>
                    <Modal.Title className='w-100 text-center' style={{fontSize:'20px', color:'dimgray'}}>Error Generating Roll-up</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Row className="d-flex justify-content-center" style={{paddingLeft:"20px", paddingRight:"20px", paddingBottom:"20px" }}>
                    <h5 style={{fontSize:'20px', color:'dimgray'}}>Type: {rollUpTypeText}</h5>
                </Row>
                <Row>
                    <Col>
                        <Row className="d-flex justify-content-center">
                            <h5 style={{fontSize:'18px', color:'dimgray'}}>Uncharacterized</h5>
                        </Row>
                        <Row className="d-flex justify-content-center" style={{paddingLeft:"20px", paddingRight:"20px", paddingBottom:"20px" }}>
                            <FormControl 
                                readOnly
                                as="textarea"
                                aria-label="With textarea"
                                style={{minHeight:'150px', minWidth:'150px'}}
                                value={practicesNotCharacterizedList}/>
                        </Row>
                    </Col>
                    <Col>
                        <Row className="d-flex justify-content-center">
                            <h5 style={{fontSize:'18px', color:'dimgray'}}>Incorrectly Characterized</h5>
                        </Row>
                        <Row className="d-flex justify-content-center" style={{paddingLeft:"20px", paddingRight:"20px"}}>
                            <FormControl 
                                readOnly
                                as="textarea"
                                aria-label="With textarea"
                                style={{minHeight:'150px', minWidth:'150px'}}
                                value={practicesIncorrectlyCharacterizedList}/>
                        </Row>
                    </Col>
                </Row>
                </Modal.Body>
                <Modal.Footer style={{backgroundColor:'rgb(0,168,168)'}}>
                <Button
                    variant="outline-light"
                    size='sm'                
                    onClick={(event)=>onAcknowledge()}
                    >Ok</Button>                   
                </Modal.Footer>
            </Modal>
        </Modal>
    );
}

export default GenerateRollupModal;