import React from 'react';
import { Tabs, Tab, Row, Col } from 'react-bootstrap';
import "./TabControl.css";
import Evidence from '../containers/Evidence';
import Interviews from '../containers/Interviews';
import LoadingComponent from './LoadingComponent';
import { Container } from 'react-bootstrap';


const TabControl = ({ evidence, interviews, interviewSessions, formatInterviewData, nodeInfo, evidenceLoading, interviewsLoading, formatEvidenceData }) => {
    return (
        nodeInfo.isPractice &&
        <Container>
            <div>
            <Tabs defaultActiveKey={1} id="tabControl">
                <Tab eventKey={1} title="Evidence" style={{ color: 'dimgray' }}>
                    <Tab.Pane eventKey={1}>
                        <LoadingComponent isLoading={evidenceLoading}>
                            <Row>
                                <Col>
                                    <Evidence evidence={evidence} nodeInfo={nodeInfo} formatEvidenceData={formatEvidenceData} />
                                </Col>
                            </Row>
                        </LoadingComponent>
                    </Tab.Pane>
                </Tab>
                <Tab eventKey={2} title="Interviews" style={{ color: 'dimgray' }}>
                    <Tab.Pane eventKey={2}>
                        <LoadingComponent isLoading={interviewsLoading}>
                            <Row>
                                <Col>
                                    <Interviews interviews={interviews} interviewSessions={interviewSessions} formatInterviewData={formatInterviewData} nodeInfo={nodeInfo} />
                                </Col>
                            </Row>
                        </LoadingComponent>
                    </Tab.Pane>
                </Tab>
            </Tabs>
            </div>  
        </Container>            
    )
}
export default TabControl