import React from 'react';

const checkBoxStyle = {
    width: "15px",
    height: "15px",
    color:'dimgray'
  };

const InterviewItem = ({interview, interviewSessions, updateSelected}) => {
    const chkBoxKey = interview.id + "chkBox";
    const questionKey = interview.id + "question"; 

    const sessionsAndDurationRows = interviewSessions.filter(is=>interview.sessions.includes(is.id)).map(is=>{
        return(
            <tr>
            <td key={is.id} style={{ margin:'0', width: '250px',textAlign:'center', color:'dimgray'}}>{`${is.interviewee}`}</td> 
            <td key={is.id} style={{ margin:'0', width: '50px',textAlign:'center', color:'dimgray'}}>{`${is.hours}`}</td> 
            </tr>
        );
    });
    
    return (
        <React.Fragment>
            <td style={{ width: '30px'}} key={chkBoxKey}>
                <input type="checkbox"
                    onChange={(e) => updateSelected(e, interview.id)}
                    checked={interview.selected}
                    style={checkBoxStyle}
                />
            </td>
            <td  style={{ width: '500px',textAlign:'left', color:'dimgray'}} key={questionKey}>
                <p>{interview.question}</p>
                </td>
            <table size='sm' style={{ tableLayout: 'fixed' }}>
                <div >
                    <tbody>
                        {sessionsAndDurationRows}
                    </tbody>
                </div>
            </table>
        </React.Fragment>
        
    );
}
export default InterviewItem;