import React, { useState } from 'react';
import { Modal, Table, Button, Row, ButtonToolbar, OverlayTrigger, Tooltip, FormText } from 'react-bootstrap';
import LoaderButton from '../presenters/LoaderButton';
import { API } from 'aws-amplify';
import { Constants, getDefaultAPIHeaders } from '../utils/helpers';
import ConfirmDeleteModal from '../presenters/ConfirmDeleteModal';
import InterviewSessionTableItem from '../presenters/InterviewSessionTableItem';
import uuid from 'uuid';
import './InterviewSessionsModal.css';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'

const DELETE_QUESTION = "Delete the selected interview session(s)?";
const Styles = styled.div`

.custom-btn {
    background:rgb(0, 168, 168);
    color: white;
    border: none;    
    padding: 0;   
  } 
`;

function getEditBody(beforeEdit, afterEdit) {
    const filteredAfter = afterEdit.filter(is => is.interviewee.length > 0 && is.hours > 0);
    const filteredBefore = beforeEdit.filter(is => is.interviewee.length > 0 && is.hours > 0);

    let body = {};

    const added = filteredAfter.filter(is => {
        return beforeEdit.find(bis => bis.id === is.id) === undefined;
    })
    if (added.length > 0) {
        body = {
            ...body,
            added: added
        };
    }

    const deleted = filteredBefore.filter(is => {
        return afterEdit.find(bis => bis.id === is.id) === undefined;
    })
    if (deleted.length > 0) {
        body = {
            ...body,
            deleted: deleted
        };
    }

    let modified = afterEdit.filter(is => is.modified === true);
    if (modified.length > 0) {
        body = {
            ...body,
            modified: modified.map(is => {
                return {
                    interview_session_id: is.id,
                    interviewee: is.interviewee,
                    hours: is.hours
                };
            })
        }
    }
    return body;
}

function InterviewSessionsModal(props) {
    const [okLoading, setOkLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const enabledStyle = {
        backgroundColor: 'white',
        border: 'none',
        pointerEvents: 'auto'
    }

    const disabledStyle = {
        backgroundColor: 'white',
        border: 'none',
        pointerEvents: 'none'
    }

    const handleAddInterviewSession = () => {
        const newIs = {
            id: uuid.v1(),
            hours: 1,
            interviewee: '',
            added: true
        }
        props.interviewSessions.add(newIs)
        setHasError(true);
    }

    const handleShowDeleteModal = (event) => {
        setShowDeleteModal(true);
    }

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
    }
    
    const handleDeleteInterviewSession = () => {
        setShowDeleteModal(false);
        const items = props.interviewSessions.value.filter(e => e.selected === true);
        for (let i = 0; i < items.length; i++) {
            props.interviewSessions.removeById(items[i].id);
        }

        //if something is being deleted validate the fields without including it
        props.interviewSessions.value.forEach(is => {
            if(is.interviewee === '' || is.hours.toString() === "0"){
                let isBeingDeleted = items.filter(x => x.id === is.id).length === 1;
                if(!isBeingDeleted){
                    setHasError(true);
                }
            }
            else{
                setHasError(false);
            }
        });
    }

    const validateFields = () => {
        setHasError(false);
        props.interviewSessions.value.forEach(is => {
            if(is.interviewee === '' || is.hours === 0){
                setHasError(true);
            }
        });
    }

    const updateInterviewSessionState = (value, name, id) => {
        let target = props.interviewSessions.getById(id);
        if (name === "hours") {
            target[name] = Number(value);
            target = { ...target, modified: true };
            props.interviewSessions.replace(id, target);
        } else {
            target[name] = value;
            target = { ...target, modified: true };
            props.interviewSessions.replace(id, target);
        }
        validateFields();
    }

    const validateAdd = (interviewSessions) => {
        return interviewSessions.filter(is => is.interviewee.length === 0).length > 0;
    }

    const handleOkClicked = async () => {
        if (!validateAdd(props.interviewSessions.value)) {
            setOkLoading(true);
            const body = getEditBody(props.snapshot, props.interviewSessions.value);
            if (Object.keys(body).length !== 0) {
                try {
                    await API.put(Constants.API_PATH, `${Constants.INTERVIEW_PATH}/${props.appraisalId}`, await getDefaultAPIHeaders(body));
                
                    //if sessions were deleted, perform database clean up
                    if(body.deleted.length > 0){
                        await cleanInterviewQuestions(body.deleted);
                    }
                }
                catch (e) {
                    console.log(e);
                }
            }
            setOkLoading(false);
            props.close();
        }
    }

    const cleanInterviewQuestions = async (deletedSessions) => {
        for(let i = 0; i < deletedSessions.length; i++){
            let affectedQuestions = await getInterviewQuestionsBySession(deletedSessions[i].id);

            for(let j = 0; j < affectedQuestions.length; j++){

                let updatedSessions = affectedQuestions[j].sessions.filter( x => x !== deletedSessions[i].id)

                //if there are no sessions left, delete the question
                if(updatedSessions.length === 0){
                    let body = {
                        appraisal_id: props.appraisalId,
                        node_id: affectedQuestions[j].nodeId,
                        project_id: affectedQuestions[j].projectId,
                        interview_question_id: affectedQuestions[j].id,
                        question: affectedQuestions[j].question,
                        sessions: updatedSessions,
                        revision_number: affectedQuestions[j].revNumber
                    };
                    try {
                        await API.del(Constants.API_PATH, Constants.INTERVIEW_QUESTIONS_PATH, await getDefaultAPIHeaders(body));
                    } catch (e) {
                        console.log("Failure deleting interview session: " + e);
                    }
                }
                //remove the session from the interview question
                else{
                    let body = {
                        appraisal_id: props.appraisalId,
                        node_id: affectedQuestions[j].nodeId,
                        project_id: affectedQuestions[j].projectId,
                        interview_question_id: affectedQuestions[j].id,
                        question: affectedQuestions[j].question,
                        sessions: updatedSessions,
                        revision_number: affectedQuestions[j].revNumber + 1
                    }
                    try {
                        await API.put(Constants.API_PATH, Constants.INTERVIEW_QUESTIONS_PATH, await getDefaultAPIHeaders(body));
                    } catch (e) {
                        console.log("Failure deleting session from interview: " + e);
                    }
                }
            }
        }
    }

    async function getInterviewQuestionsBySession(sessionId) {

        let interviews = [];
        const interviewsData = await API.get(Constants.API_PATH, `${Constants.INTERVIEW_QUESTIONS_PATH}/${props.appraisalId}`, await getDefaultAPIHeaders());
        interviewsData.forEach(is => {
            if(is.sessions.includes(sessionId)){
                interviews.push({
                    id: is.interview_question_id,
                    nodeId: is.node_id,
                    projectId: is.project_id,
                    question: is.question,
                    sessions: is.sessions,
                    revNumber: is.revision_number
                });
            }
        });
    
        return interviews;
    }

    const updateSelected = (e, id) => {
        let item = props.interviewSessions.getById(id);
        item.selected = e.target.checked;
        props.interviewSessions.replace(id, item);
    };

    const itemsAreSelected = () => {
        const items = props.interviewSessions.value.filter(e => e.selected === true);
        return items.length > 0;
    }

    const tryHideModal = (e) => {
        if (!okLoading) {
            props.close()
        }
    }

    const tableItems = props.interviewSessions.value.map(is => {
        return (
            <tr key={is.id}>
                <InterviewSessionTableItem
                    interviewSession={is}
                    updateState={updateInterviewSessionState}
                    updateSelected={updateSelected}
                    modalOpen={showDeleteModal}
                />
            </tr>
        )
    });

    return (
        <Styles>
            <ConfirmDeleteModal
                showDeleteModal={showDeleteModal}
                close={closeDeleteModal}
                deleteAction={handleDeleteInterviewSession}
                deleteQuestion={DELETE_QUESTION}
            />
            <Modal show={props.showModal} onHide={(e) => tryHideModal(e)} animation>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '18px', color: 'dimgray' }}>
                        <img
                            src={require('../assets/icon.png')}
                            width="30"
                            height="30"
                            className="d-inline-block align-middle"
                            alt="CAP logo"
                        />{' '}Edit Interview Sessions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row>
                            <ButtonToolbar >
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Interview Session</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button
                                            active
                                            variant="light"
                                            type="submit"
                                            size="sm"
                                            style={enabledStyle}
                                            onClick={e => handleAddInterviewSession()}>
                                            <FontAwesomeIcon icon={faPlusCircle} size="lg" color="royalblue" />
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete Interview Session</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button
                                            active
                                            variant="light"
                                            type="submit"
                                            size="sm"
                                            style={itemsAreSelected() ? enabledStyle : disabledStyle}
                                            onClick={e => handleShowDeleteModal(e)}
                                            disabled={!itemsAreSelected()}>
                                            <FontAwesomeIcon icon={faMinusCircle} size="lg" color="rgb(0,168,168)" />
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                            </ButtonToolbar>
                        </Row>
                        <Table striped style={{ tableLayout: 'fixed'}}>
                            <thead className='thead' style={{ display: 'block'}}>
                                <tr>
                                    <th style={{ width: '50px', textAlign: 'center' }}></th>
                                    <th style={{ width: '330px', textAlign: 'left', color: 'dimgray' }}>Interviewee</th>
                                    <th style={{ width: '100px', textAlign: 'left', color: 'dimgray' }}>Hours</th>
                                    <th style={{ width: '100px', textAlign: 'left', color: 'dimgray' }}>Minutes</th>
                                </tr>
                            </thead>
                            <tbody style={{height:'400px', display: 'block', overflow:'auto'}}>
                                {tableItems}
                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: 'rgb(0,168,168)' }}>
                    <LoaderButton
                        text="Submit"
                        loadingText="Submitting..."
                        isLoading={okLoading}
                        disabled={hasError || showDeleteModal}
                        onClick={(e) => handleOkClicked()} />
                </Modal.Footer>
            </Modal>
        </Styles>
    );
}

export default InterviewSessionsModal;