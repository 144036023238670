import React, { useState} from 'react';
import { API } from 'aws-amplify';
import { Constants, getDefaultAPIHeaders } from '../utils/helpers';
import { PRACTICE_AREAS, PRACTICES, MODEL_CONSTANTS } from '../model/modelcontent';
import HighlightColorPicker from '../containers/HighlightColorPicker';
import LoadingComponent from '../presenters/LoadingComponent';
import ToggleButtonLoadingWrapper from './ToggleButtonLoadingWrapper';
import { Container, Table, Row, Col, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons'
import NetworkErrorModal from '../presenters/NetworkErrorModal';

const Styles = styled.div`
.td {
    font-size: small;    
  }

.th {
    text-align: left; 
    font-size: 16px;     
  }

  .no-border{
      border: none;
  }
`;


//Renders the read-only information for the practice areas and practices within the Node Page.
const NodePageModelInfo = ({ nodeInfo, nodeHighlight, setNodeHighlight, practiceAffirmed, setPracticeAffirmed, affirmedLoading, setTreeControlLoading, onNodeHighlight, onPracticeAffirmed, refreshPage }) => {

    const [showNetworkErrorModal, setShowNetworkErrorModal] = useState(false);
    
    async function onHighlightColorPicked(highlightColor) {

        setTreeControlLoading();

        //save highlight color
        highlightColor = highlightColor.replace(" ", "").toLowerCase();
        const body = {
            appraisal_id: nodeInfo.appraisalId,
            project_id: nodeInfo.projectId,
            node_id: nodeInfo.nodeId,
            affirmed: practiceAffirmed,
            highlight: highlightColor,
            revision_number: 999
        };

        try {
            await API.put(Constants.API_PATH, Constants.APPRAISAL_STATE_PATH, await getDefaultAPIHeaders(body))
                .catch(err => {
                    if(err.message === "Network Error"){
                        setShowNetworkErrorModal(true);
                    }
                    return;
                });

            onNodeHighlight();
        }
        catch (e) {
            console.log(e);
        }
    }

    const updatePracticeAffirmed = async () => {

        setTreeControlLoading();
        
        const body = {
            appraisal_id: nodeInfo.appraisalId,
            project_id: nodeInfo.projectId,
            node_id: nodeInfo.nodeId,
            affirmed: !practiceAffirmed,
            revision_number: 999
        };
        setPracticeAffirmed(!practiceAffirmed);
        await API.put(Constants.API_PATH, Constants.APPRAISAL_STATE_PATH, await getDefaultAPIHeaders(body))
            .catch(err => {
                if(err.message === "Network Error"){
                    setShowNetworkErrorModal(true);
                }
                return;
            });
        
        onPracticeAffirmed();
    }

    if (nodeInfo.isGroup) {
        let objectName = Object.keys(MODEL_CONSTANTS).find(key => MODEL_CONSTANTS[key] === nodeInfo.nodeId);
        let abbreviation = objectName.substring(0, objectName.indexOf("_"));

        
        
        return (
            <Styles>
                <Container>
                    <div >
                        <Row>
                            <Col sm={1}>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Refresh Page</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button
                                            variant="light"
                                            type="submit"
                                            size="sm"
                                            style={{ backgroundColor: 'white', border: 'none' }}
                                            onClick={(event) => refreshPage()}>
                                            <FontAwesomeIcon icon={faRedo} size="lg" color="rgb(0,168,168)" />
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                            </Col>
                            <Col sm={3}>
                                <h5 style={{ paddingLeft: '.5rem', color: 'dimgray' }}><b>{abbreviation} - {nodeInfo.name}</b></h5>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </Styles>
        )
    } else {
        return (
            !nodeInfo.isPractice ?
                <Container>
                    <div >
                        <Row>
                            <Col sm={1}>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Refresh Page</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button
                                            variant="light"
                                            type="submit"
                                            size="sm"
                                            style={{ backgroundColor: 'white', border: 'none' }}
                                            onClick={(event) => refreshPage()}>
                                            <FontAwesomeIcon icon={faRedo} size="lg" color="rgb(0,168,168)" />
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                            </Col>
                            <Col sm={8}>
                                <h5 style={{ paddingLeft: '.5rem', color: 'dimgray' }}><b>{PRACTICE_AREAS[nodeInfo.nodeId].Name}</b></h5>
                            </Col>
                            <Table className="no-border" responsive>
                                <tbody>
                                    <tr>
                                        <th className="th no-border" style={{ color: 'dimgray' }}>Intent</th>
                                        <td className="td no-border" style={{ color: 'dimgray' }}>{PRACTICE_AREAS[nodeInfo.nodeId].Intent}</td>
                                    </tr>
                                    <tr>
                                        <th className="th no-border" style={{ color: 'dimgray' }}>Value</th>
                                        <td className="td no-border" style={{ color: 'dimgray' }}>{PRACTICE_AREAS[nodeInfo.nodeId].Value}</td>
                                    </tr>
                                </tbody>
                            </Table>

                        </Row>
                    </div>
                </Container>
                :
                <Container>
                    <Row >
                        <Col sm={1}>
                            <LoadingComponent isLoading={affirmedLoading} style={{ display: 'inline' }} >
                                <HighlightColorPicker nodeInfo={nodeInfo} nodeHighlight={nodeHighlight} setNodeHighlight={setNodeHighlight} onHighlightColorPicked={onHighlightColorPicked} />
                            </LoadingComponent>
                        </Col>
                        <Col sm={1}>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Refresh Page</Tooltip>}>
                                <span className="d-inline-block">
                                    <Button
                                        variant="light"
                                        type="submit"
                                        size="sm"
                                        style={{ backgroundColor: 'white', border: 'none' }}
                                        onClick={(event) => refreshPage()}>
                                        <FontAwesomeIcon icon={faRedo} size="lg" color="rgb(0,168,168)" />
                                    </Button>
                                </span>
                            </OverlayTrigger>
                        </Col>
                        <Col sm={2}>
                            <h5 style={{ color: 'dimgray' }}><b>{PRACTICES[nodeInfo.nodeId].Abbreviation}</b></h5>
                        </Col>
                        <Col sm={8}>
                            <LoadingComponent isLoading={affirmedLoading} style={{ display: 'inline' }} >
                                <ToggleButtonLoadingWrapper value={practiceAffirmed} onToggle={updatePracticeAffirmed} />
                            </LoadingComponent>
                        </Col>
                    </Row>
                    <div>
                        <Table className="no-border" responsive>
                            <tbody>
                                <tr>
                                    <td className="td no-border" style={{ whiteSpace: "pre-wrap", wordWrap: "break-word", paddingLeft: '.5rem', color: 'dimgray' }}>{PRACTICES[nodeInfo.nodeId].Statement}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    
                <NetworkErrorModal  showNetworkErrorModal={showNetworkErrorModal} />
                </Container>
        );
    }
}
export default NodePageModelInfo;