import React, { useState, useEffect } from 'react';
import { Button, Spinner, Row, Col, Form, Modal } from 'react-bootstrap';
import { modelContentUtils } from '../utils/helpers';
import { AppraisalRepository } from '../utils/repositories';
import useArray from '../hooks/useArray';
import LoadingComponent from '../presenters/LoadingComponent';
import ReportFragment from '../presenters/ReportFragment';
import MultiSelectListReportOptions from '../presenters/MultiSelectListReportOptions';
import EvidenceMappingsReportView from '../presenters/EvidenceMappingsReportView';
import { PRACTICE_AREA_RELATIONSHIPS } from '../model/practicearearelationships';
import { PRACTICES } from '../model/practices';
import { Constants, getDefaultAPIHeaders, getCurrentDateTime } from '../utils/helpers';
import { API } from 'aws-amplify';
import NewWindow from 'react-new-window';
import { CSVLink } from "react-csv";

async function fetchData(setDataLoading, setAppraisalName, projects, practiceAreas) {
    setDataLoading(true);
    const currentAppraisalId = localStorage.getItem("selectedAppraisal");
    if (currentAppraisalId) {
        const apiRepo = new AppraisalRepository(null, null, { selected: false, availableForSelection: false });
        const appraisalData = await apiRepo.getOne(currentAppraisalId, false);
        if (!appraisalData.error) {
            projects.setValue(appraisalData.returnValue.projects.filter(x => x.name !== "Organizational Roll-up"));
            setAppraisalName(appraisalData.returnValue.name);
            const formattedPracticeAreas = modelContentUtils.getPracticeAreasFromAppraisalProjects(appraisalData.returnValue.projects, true, true)
                .map(pa => {
                    return {
                        ...pa,
                        selected: false,
                        availableForSelection: false
                    }
                });
            practiceAreas.setValue(formattedPracticeAreas);
        } else {
            console.log("error getting appraisal data: ", appraisalData.returnValue);
        }
    }

    setDataLoading(false);
}

const EvidenceMappingsReportModal = (props) => {
    const [dataLoading, setDataLoading] = useState(true);
    const [optionsVisible, setOptionsVisible] = useState(true);
    const [appraisalName, setAppraisalName] = useState('');
    const [reportLoading, setReportLoading] = useState(false);
    const [csvLoading, setCSVLoading] = useState(false);
    const [showCSV, setShowCSV] = useState(false);
    const [evidenceData, setEvidenceData] = useState({});
    const [csvData, setCSVData] = useState({});
    const [csvFilename, setCSVFilename] = useState({});
    const [includeOnlyValidEvidence, setIncludeOnlyValidEvidence] = useState(false);
    const [includeOnlyProcesses, setIncludeOnlyProcesses] = useState(false);
    const [includePath, setIncludePath] = useState(false);
    const [includeComments, setIncludeComments] = useState(false);
    const [hasProjectSelected, setHasProjectSelected] = useState("false");
    const [hasPracticeAreaSelected, setHasPracticeAreaSelected] = useState("false");
    const projects = useArray([]);
    const practiceAreas = useArray([]);

    useEffect(() => {
        if (props.showModal) {
            const getData = () => fetchData(setDataLoading, setAppraisalName, projects, practiceAreas);
            getData();
            setShowCSV(false);
            setOptionsVisible(true);
            setIncludeOnlyValidEvidence(false);
            setIncludeOnlyProcesses(false);
            setIncludePath(false);
            setIncludeComments(false);
            setHasProjectSelected(false);
            setHasPracticeAreaSelected(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showModal]);

    const handleProjectSelectionChanged = (event) => {
        const allOptions = event.target.options;
        let selectedProjectIds = [];
        for (let i = 0; i < allOptions.length; i++) {
            const currentOption = allOptions[i];
            let project = projects.getById(currentOption.value);
            if (currentOption.selected) {
                selectedProjectIds.push(currentOption.value);
            }
            project.selected = currentOption.selected;
            projects.replace(currentOption.value, project);
        }

        const selectedProjectPracticeAreaIds = projects.value.filter(p => selectedProjectIds.includes(p.id))
            .map(p => p.practiceAreas).flat();
        const newPracticeAreas = practiceAreas.value.slice()
            .map(pa => {
                if (selectedProjectPracticeAreaIds.includes(pa.id)) {
                    return {
                        ...pa,
                        availableForSelection: true
                    }
                } else {
                    return {
                        ...pa,
                        availableForSelection: false,
                        selected: false
                    }
                }
            });
        practiceAreas.setValue(newPracticeAreas);

        setHasProjectSelected(projects.value.filter(p => p.selected === true).length > 0 ? true : false);

        let anyPASelected = false;
        newPracticeAreas.forEach(d => {
            if (d.selected) {
                anyPASelected = true;
            }
        });
        setHasPracticeAreaSelected(anyPASelected);
        setShowCSV(false);
    }

    const handlePracticeAreaSelectionChanged = (event) => {
        const allOptions = event.target.options;
        let selectedPracticeAreaIds = [];
        for (let i = 0; i < allOptions.length; i++) {
            const currentOption = allOptions[i];
            if (currentOption.selected) {
                selectedPracticeAreaIds.push(currentOption.value);
            }
        }
        const newPracticeAreas = practiceAreas.value.slice()
            .map(pa => {
                if (selectedPracticeAreaIds.includes(pa.id)) {
                    return {
                        ...pa,
                        selected: true
                    }
                } else {
                    return {
                        ...pa,
                        selected: false
                    }
                }
            });
        practiceAreas.setValue(newPracticeAreas);

        setHasPracticeAreaSelected(selectedPracticeAreaIds.length > 0 ? true : false);
        setShowCSV(false);
    }

    const onlyValidCheckBoxChanged = () => {
        setIncludeOnlyValidEvidence(!includeOnlyValidEvidence);
        setShowCSV(false);
    }

    const onlyProcessesCheckBoxChanged = () => {
        setIncludeOnlyProcesses(!includeOnlyProcesses);
        setShowCSV(false);
    }

    const pathCheckBoxChanged = () => {
        setIncludePath(!includePath);
        setShowCSV(false);
    }

    const commentsCheckBoxChanged = () => {
        setIncludeComments(!includeComments);
        setShowCSV(false);
    }

    async function getEvidenceData(currentAppraisalId, selectedProjects, selectedPracticeAreas) {

        let allEvidenceData = [];
        const apiPath = `${Constants.APPRAISAL_EVIDENCE_PATH}/${currentAppraisalId}`
        const data = await API.get(Constants.API_PATH, apiPath, await getDefaultAPIHeaders());

        for (let i = 0; i < selectedProjects.length; i++) {
            const currentProject = selectedProjects[i];

            let practiceAreaData = [];
            for (let i = 0; i < selectedPracticeAreas.length; i++) {
                const practiceAreaName = [selectedPracticeAreas[i].name]

                let practiceData = [];
                //get the node ids for each node in the practice area
                const practiceNodes = PRACTICE_AREA_RELATIONSHIPS[selectedPracticeAreas[i].id];
                for (let i = 0; i < practiceNodes.length; i++) {

                    //get the friendly abbreviation for the practice node
                    const practice = PRACTICES[practiceNodes[i].Id].Abbreviation;
                    let practiceStatement = PRACTICES[practiceNodes[i].Id].Statement;

                    let practiceEvidence = [];
                    data.forEach(d => {
                        if (d.project_id === currentProject.id && d.node_id === practiceNodes[i].Id) {
                            
                            if(includeOnlyProcesses && (!d.evidence_state[0] || d.evidence_state[2] !== "P")){
                                //skip this entry if include processes is checked and it is not a process.
                            }
                            else{
                                if(includeOnlyValidEvidence){
                                    if(d.evidence_state[0] && d.evidence_state[0] === "V"){
                                        practiceEvidence.push({
                                            fileName: d.evidence_fileName,
                                            path: d.evidence_path,
                                            notes: d.evidence_notes
                                        })
                                    }
                                }
                                else{
                                    practiceEvidence.push({
                                        fileName: d.evidence_fileName,
                                        path: d.evidence_path,
                                        notes: d.evidence_notes
                                    })
                                }
                            }
                        }
                    });

                    if (practiceEvidence.length > 0) {
                        practiceData.push({
                            practiceName: practice,
                            statement: practiceStatement,
                            evidence: practiceEvidence
                        })
                    }
                }
                if (practiceData.length > 0) {
                    practiceAreaData.push({
                        practiceAreaName: practiceAreaName,
                        practiceData
                    });
                }
            }
            allEvidenceData.push({
                projectName: currentProject.name,
                practiceAreaData
            });
        }

        return allEvidenceData;
    }

    const handleGenerateReportClicked = async (event) => {
        setReportLoading(true);

        let currentAppraisalId = localStorage.getItem("selectedAppraisal");
        let selectedProjects = projects.value.filter(p => p.selected === true);
        let selectedPracticeAreas = practiceAreas.value.filter(p => p.selected === true);
        let evidenceinfo = await getEvidenceData(currentAppraisalId, selectedProjects, selectedPracticeAreas);
        setEvidenceData(evidenceinfo);

        setReportLoading(false);
        setOptionsVisible(false);
        props.setShowModal(false);
    }

    const handleGenerateCSVClicked = async (event) => {
        setCSVLoading(true);

        let currentAppraisalId = localStorage.getItem("selectedAppraisal");
        let selectedProjects = projects.value.filter(p => p.selected === true);
        let selectedPracticeAreas = practiceAreas.value.filter(p => p.selected === true);
        let evidenceinfo = await getEvidenceData(currentAppraisalId, selectedProjects, selectedPracticeAreas);

        let csvData = formatCSVData(evidenceinfo);
        setCSVData(csvData);

        setCSVLoading(false);
        setShowCSV(true);
    }

    function formatCSVData(evidenceInfo) {
        let csvData = [];
        if (includeComments && includePath) {
            csvData.push(["Project", "Practice Area", "Practice", "Practice Statement", "Work Product Name", "Path", "Comments"]);
        }
        else if (includeComments) {
            csvData.push(["Project", "Practice Area", "Practice", "Practice Statement", "Work Product Name", "Comments"]);
        }
        else if (includePath) {
            csvData.push(["Project", "Practice Area", "Practice", "Practice Statement", "Work Product Name", "Path"]);
        }
        else {
            csvData.push(["Project", "Practice Area", "Practice", "Practice Statement", "Work Product Name"]);
        }

        for (let i = 0; i < evidenceInfo.length; i++) {

            let practiceAreaData = evidenceInfo[i].practiceAreaData;
            let projectName = "";
            if (practiceAreaData.length > 0) {
                projectName = evidenceInfo[i].projectName;
            }

            for (let j = 0; j < practiceAreaData.length; j++) {
                let practiceAreaName = practiceAreaData[j].practiceAreaName;
                let practiceData = practiceAreaData[j].practiceData;
                for (let k = 0; k < practiceData.length; k++) {
                    let practiceName = practiceData[k].practiceName;
                    let statement = practiceData[k].statement;
                    let practiceEvidence = practiceData[k].evidence;
                    for (let l = 0; l < practiceEvidence.length; l++) {
                        //add data to CSV output
                        if (includeComments && includePath) {
                            csvData.push([projectName, practiceAreaName, practiceName, statement, practiceEvidence[l].fileName, practiceEvidence[l].path, practiceEvidence[l].notes]);
                        }
                        else if (includeComments) {
                            csvData.push([projectName, practiceAreaName, practiceName, statement, practiceEvidence[l].fileName, practiceEvidence[l].notes]);
                        }
                        else if (includePath) {
                            csvData.push([projectName, practiceAreaName, practiceName, statement, practiceEvidence[l].fileName, practiceEvidence[l].path]);
                        }
                        else {
                            csvData.push([projectName, practiceAreaName, practiceName, statement, practiceEvidence[l].fileName]);
                        }
                    }
                }
            }
        }

        setCSVFilename("evidenceReport_" + getCurrentDateTime() + ".csv");
        return csvData;
    }

    const projectSelectionDisplayOptions = {
        title: "Projects",
        displayKey: "name",
        filterAvailable: false
    };

    const practiceAreaSelectionDisplayOptions = {
        title: "Practice Areas",
        displayKey: "name",
        filterAvailable: true,
        availableKey: "availableForSelection",
        size: 25
    };

    const viewReportHeader = ``;
    const scopeHeader = '';
    const appraisalHeader = 'Appraisal:';
    const reportName = 'Evidence Mappings Report';

    const spacingStyle = {
        marginBottom: "10px",
        marginLeft: "20%",
        fontSize: "16px",
        justifyContent: 'center',
        alignItems: 'center'
    };

    const viewReportStyle = {
        textAlign: "right",
        paddingTop: "0px",
        color: 'black',
        marginTop: '16px',
        fontSize: '22px'
    }

    return (
        <React.Fragment>
            <Modal show={props.showModal} onHide={(e) => props.setShowModal(false)} dialogClassName="findingsReportModalStyle">
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '18px', color: 'dimgray' }}>
                        <img
                            src={require('../assets/icon.png')}
                            width="30"
                            height="30"
                            className="d-inline-block align-middle"
                            alt="CAP logo"
                        />{' '}Evidence Mappings Report</Modal.Title>
                </Modal.Header>
                <LoadingComponent isLoading={dataLoading} iconSize={80}>
                    <ReportFragment visible={optionsVisible} >
                        <Row className="d-flex justify-content-center" style={{ marginTop: '-50px' }}>
                            <Col md={6}>
                                <Form>
                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <Form.Label
                                            column sm='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                padding: '.5rem'
                                            }}>
                                            Appraisal
                                </Form.Label>
                                        <Col sm={6} style={{ textAlign: 'left' }}>
                                            <Form.Control plaintext readOnly value={appraisalName} style={{ color: 'dimgray', paddingLeft: '1rem' }} />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <Form.Label
                                            column sm='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                padding: '.5rem'
                                            }}>
                                            Projects
                                </Form.Label>
                                        <Col sm={6} style={{ textAlign: 'left' }}>
                                            <MultiSelectListReportOptions listData={projects.value} style={spacingStyle}
                                                handleSelectionChanged={handleProjectSelectionChanged}
                                                displayOptions={projectSelectionDisplayOptions} />
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col md={6}>
                                <Form>
                                    <Form.Group as={Row} className="d-flex justify-content-center" >
                                        <Form.Label hidden={!hasProjectSelected}
                                            column sm='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                padding: '.5rem'
                                            }}>
                                            Practice Areas
                                </Form.Label>
                                        <Col style={{ marginRight: '10px' }}>
                                            <MultiSelectListReportOptions listData={practiceAreas.value}
                                                handleSelectionChanged={handlePracticeAreaSelectionChanged}
                                                displayOptions={practiceAreaSelectionDisplayOptions} />
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-center">
                            <Form.Group controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Valid evidence only"
                                    style={{ color: "dimgray" }}
                                    checked={includeOnlyValidEvidence}
                                    onChange={onlyValidCheckBoxChanged}
                                />
                                <Form.Check type="checkbox" label="Processes only"
                                style={{ color: "dimgray" }}
                                checked={includeOnlyProcesses}
                                onChange={onlyProcessesCheckBoxChanged}
                                />
                                <Form.Check type="checkbox" label="Include file path"
                                    style={{ color: "dimgray" }}
                                    checked={includePath}
                                    onChange={pathCheckBoxChanged}
                                />
                                <Form.Check type="checkbox" label="Include comments"
                                    style={{ color: "dimgray" }}
                                    checked={includeComments}
                                    onChange={commentsCheckBoxChanged}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="d-flex justify-content-center">
                            <Button
                                variant="outline-light"
                                disabled={!hasProjectSelected || !hasPracticeAreaSelected}
                                style={{
                                    background: 'rgb(0, 168, 168)',
                                    color: 'white',
                                    border: 'none',
                                    justifyContent: 'center',
                                    padding: '.5rem',
                                    marginTop: '1rem',
                                    marginBottom: '2rem'
                                }}
                                size='sm'
                                onClick={(e) => handleGenerateReportClicked(e)}>
                                {reportLoading &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size='sm'
                                        role="status"
                                        aria-hidden="true"
                                    />}
                                {!reportLoading ? "Generate Evidence Mappings Report" : ' Generating Report...'}
                            </Button>
                            {!showCSV &&
                                <Button
                                    variant="outline-light"
                                    disabled={!hasProjectSelected || !hasPracticeAreaSelected || reportLoading}
                                    style={{
                                        background: 'rgb(0, 168, 168)',
                                        color: 'white',
                                        border: 'none',
                                        justifyContent: 'center',
                                        padding: '.5rem',
                                        marginLeft: '1rem',
                                        marginTop: '1rem',
                                        marginBottom: '2rem'
                                    }}
                                    size='sm'
                                    onClick={(e) => handleGenerateCSVClicked(e)}>
                                    {csvLoading &&
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size='sm'
                                            role="status"
                                            aria-hidden="true"
                                        />}
                                    {!csvLoading ? "Generate Evidence Mappings CSV" : ' Generating CSV...'}
                                </Button>
                            }
                            {showCSV &&
                                <CSVLink data={csvData} filename={csvFilename}>
                                    <Button color="primary"
                                        style={{
                                            border: 'none',
                                            justifyContent: 'center',
                                            padding: '.5rem',
                                            marginLeft: '1rem',
                                            marginTop: '1rem',
                                            marginBottom: '2rem'
                                        }}>
                                        Download CSV
                                </Button>
                                </CSVLink>
                            }
                        </Row>
                    </ReportFragment>
                </LoadingComponent>
            </Modal>

            {!optionsVisible &&
                <NewWindow>
                    <ReportFragment
                        reportName={reportName}
                        reportHeader={viewReportHeader}
                        reportHeaderStyle={viewReportStyle}
                        scopeHeader={scopeHeader}
                        appraisalHeader={appraisalHeader}
                        appraisalName={appraisalName}>
                        <EvidenceMappingsReportView
                            reportType="Evidence Mappings"
                            projects={projects.value.filter(p => p.selected === true)}
                            evidenceData={evidenceData}
                            includeComments={includeComments}
                            includePath={includePath}
                        />
                    </ReportFragment>
                </NewWindow>
            }
        </React.Fragment>
    );
}
export default EvidenceMappingsReportModal;