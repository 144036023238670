import React, { useState } from 'react';
import { API } from 'aws-amplify';
import { Container, Row, Table, Button, ButtonToolbar, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import EvidenceItem from '../presenters/EvidenceItem';
import EvidenceModal from './EvidenceModal';
import './Evidence.css';
import { Constants, getDefaultAPIHeaders } from '../utils/helpers';
import ConfirmDeleteModal from '../presenters/ConfirmDeleteModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPaste, faPlus, faMinus, faEdit, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components';
import NetworkErrorModal from '../presenters/NetworkErrorModal';

const Styles = styled.div`
.table{
    table-layout: auto;
}

.thead{
  background-color: rgb(0,168,168); 
  font-size: 16px;   
  color: white; 
}

.w {
    width: 40px;
  }

.buttons { 
    display: block; 
    text-align: right;  
}
  
.buttons .btn { 
    float: none;  
}
`;

const Evidence = ({ evidence, nodeInfo, formatEvidenceData }) => {
    const [showNetworkErrorModal, setShowNetworkErrorModal] = useState(false);
    const [evidenceModalState, setEvidenceModalState] = useState({});
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const DELETE_QUESTION = "Delete the selected evidence?";
    const DELETE_IMPACT = "This action cannot be undone.";

    const enabledStyle = {
        backgroundColor: 'white',
        border: 'none',
        pointerEvents: 'auto'
    }

    const disabledStyle = {
        backgroundColor: 'white',
        border: 'none',
        pointerEvents: 'none'
    }

    const updateSelected = (e, id) => {
        let item = evidence.getById(id);
        item.selected = e.target.checked;
        evidence.replace(id, item);
    };

    const handleAddClicked = () => {
        setEvidenceModalState({
            showModal: true,
            fileName: '',
            location: '',
            comments: '',
            titleFragment: 'Add',
            isHyperlink: true
        });
    }

    const handleShowDeleteModal = (event) => {
        setShowDeleteModal(true);
    }

    const handleDelete = async () => {
        setShowDeleteModal(false);
        setDeleteLoading(true);
        const items = evidence.value.filter(e => e.selected === true);
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            let body = {
                appraisal_evidence_id: item.id,
            };
            if (i === items.length - 1) {
                body = {
                    ...body,
                    node_id: nodeInfo.nodeId,
                    appraisal_id: nodeInfo.appraisalId,
                    project_id: nodeInfo.projectId
                };
            }
            try {
                await API.del(Constants.API_PATH, Constants.APPRAISAL_EVIDENCE_PATH, await getDefaultAPIHeaders(body))
                    .catch(err => {
                        if(err.message === "Network Error"){
                            setShowNetworkErrorModal(true);
                        }
                        return;
                    });

                evidence.removeById(item.id);
            }
            catch (e) {
                console.log('Delete evidence failure' + e);
            }
        }
        setDeleteLoading(false);
    }

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
    }

    const handleReOrder = async (moveUp) => {
        //make a copy of the array so we don't refresh the control while moving
        let newEvidence = [];
        for(let i = 0; i < evidence.value.length; i++){
            newEvidence.push(evidence.value[i]);
        }

        if (moveUp) {
            for (let i = 0; i < newEvidence.length; i++) {
                let e = newEvidence[i];

                //if the item is selected, save off the item above it, and swap the items
                if (e.selected) {
                    let temp = newEvidence[i-1];
                    newEvidence[i-1] = e;
                    newEvidence[i] = temp;
                }
            }
        } else {
            for (let i = newEvidence.length - 1; i >= 0; i--) {
                let e = newEvidence[i];
                //if the item is selected, save off the item below it, and swap the items
                if (e.selected) {
                    let temp = newEvidence[i+1];
                    newEvidence[i+1] = e;
                    newEvidence[i] = temp;
                }
            }
        }
        evidence.setValue(newEvidence);
        const evidenceIds = newEvidence.map(e => e.id);
        const body = {
            node_id: nodeInfo.nodeId,
            appraisal_id: nodeInfo.appraisalId,
            project_id: nodeInfo.projectId,
            reorder: evidenceIds
        };
        API.put(Constants.API_PATH, `${Constants.APPRAISAL_EVIDENCE_PATH}/reorder`,
            await getDefaultAPIHeaders(body))
            .catch(err => {
                if(err.message === "Network Error"){
                    setShowNetworkErrorModal(true);
                }
                evidence.setValue(evidence.value);
            });
    }

    const handleCopyClicked = async () => {
        const items = evidence.value.filter(e => e.selected === true).map(i => {
            return {
                ...i,
                selected: false
            };
        });
        sessionStorage.setItem('copyBuffer', JSON.stringify(items));
    }

    const itemsAreSelected = () => {
        const items = evidence.value.filter(e => e.selected === true);
        return items.length > 0;
    }

    const singleItemSelected = () => {
        const items = evidence.value.filter(e => e.selected === true);
        return items.length === 1;
    }

    const reOrderUpDisabled = () => {
        const hasItemsSelected = itemsAreSelected();
        if(hasItemsSelected){
            //if any selected item is at the top of the list, we cannot move up
            for(let i = 0; i < evidence.value.length; i++){
                let e = evidence.value[i];
                if(e.selected && evidence.value.indexOf(e) === 0){
                    return false;
                }
            }
        }
        return (hasItemsSelected && evidence.value.length > 1);
    }

    const reOrderDownDisabled = () => {
        const hasItemsSelected = itemsAreSelected();
        if(hasItemsSelected){
            //if any selected item is at the bottom of the list, we cannot move down
            for(let i = 0; i < evidence.value.length; i++){
                let e = evidence.value[i];
                if(e.selected && evidence.value.indexOf(e) >= evidence.value.length-1){
                    return false;
                }
            }
        }
        return (hasItemsSelected && evidence.value.length > 1);
    }

    const handleEvidenceEditClicked = () => {
        const items = evidence.value.filter(e => e.selected === true);
        const item = items[0];
        setEvidenceModalState({
            showModal: true,
            fileName: item.fileName,
            location: item.location,
            comments: item.comments,
            titleFragment: 'Edit',
            revNumber: item.revNumber,
            state: item.state,
            id: item.id,
            isHyperlink: item.isHyperlink
        });
    }

    const handlePasteClicked = async () => {
        let pasteItems = sessionStorage.getItem("copyBuffer");
        if (pasteItems) {
            pasteItems = JSON.parse(pasteItems);
            for (let i = 0; i < pasteItems.length; i++) {
                let item = pasteItems[i];
                const body = {
                    project_id: nodeInfo.projectId,
                    appraisal_id: nodeInfo.appraisalId,
                    node_id: nodeInfo.nodeId,
                    evidence_fileName: item.fileName,
                    evidence_path: item.location,
                    evidence_notes: item.comments,
                    evidence_state: item.state,
                    isHyperlink: item.isHyperlink
                };
                const data = await API.post(Constants.API_PATH, Constants.APPRAISAL_EVIDENCE_PATH, await getDefaultAPIHeaders(body))
                    .catch(err => {
                        if(err.message === "Network Error"){
                            setShowNetworkErrorModal(true);
                        }
                        return;
                    });
                formatEvidenceData(data, evidence);
            }
        }
    }

    //TODO - do we need to assume this list is returned to us from the server sorted?
    const wrappedEvidence = evidence.value.map(e => {
        return (
            <tr key={e.id}>
                <EvidenceItem item={e} nodeInfo={nodeInfo} updateSelected={updateSelected} />
            </tr>
        )
    });

    return (
        <Styles>
            <Container>
                <Row >
                    <Container bg="white" className="m-2">
                        <ButtonToolbar >
                            <ButtonGroup className="buttons" >
                                <OverlayTrigger rootClose overlay={<Tooltip id="tooltip-disabled1">Copy</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button
                                            variant="light"
                                            type="submit"
                                            size="sm"
                                            disabled={!itemsAreSelected()}
                                            style={itemsAreSelected() ? enabledStyle : disabledStyle}
                                            onClick={e => handleCopyClicked()}>
                                            <FontAwesomeIcon icon={faCopy} size="lg" color="rgb(0,168,168)" />
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                                <OverlayTrigger rootClose overlay={<Tooltip id="tooltip-disabled2">Paste</Tooltip>}>
                                    <Button
                                        variant="light"
                                        type="submit"
                                        size="sm"
                                        style={{ backgroundColor: 'white', border: 'none' }}
                                        onClick={e => handlePasteClicked()}>
                                        <FontAwesomeIcon icon={faPaste} size="lg" color="rgb(0,168,168)" />
                                    </Button>
                                </OverlayTrigger>
                            </ButtonGroup>
                            <ButtonGroup>
                                <OverlayTrigger rootClose overlay={<Tooltip id="tooltip-disabled3">Edit</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button
                                            variant="light"
                                            type="submit"
                                            size="sm"
                                            style={singleItemSelected() ? enabledStyle : disabledStyle}
                                            disabled={!singleItemSelected()}
                                            onClick={e => handleEvidenceEditClicked()}>
                                            <FontAwesomeIcon icon={faEdit} size="lg" color="rgb(0,168,168)" />
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                                <OverlayTrigger rootClose overlay={ <Tooltip id="tooltip-disabled4">Delete Evidence</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button
                                            variant="light"
                                            type="submit"
                                            size="sm"
                                            style={itemsAreSelected() ? enabledStyle : disabledStyle}
                                            onClick={e => handleShowDeleteModal()}
                                            isLoading={deleteLoading}
                                            disabled={!itemsAreSelected()}>
                                            <FontAwesomeIcon icon={faMinus} size="lg" color="rgb(0,168,168)" />
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                            </ButtonGroup>
                            <ButtonGroup>
                                <OverlayTrigger rootClose overlay={<Tooltip id="tooltip-disabled5">Move Up</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button
                                            variant="light"
                                            type="submit"
                                            size="sm"
                                            style={reOrderUpDisabled() ? enabledStyle : disabledStyle}
                                            disabled={!reOrderUpDisabled()}
                                            onClick={e => handleReOrder(true)}>
                                            <FontAwesomeIcon icon={faArrowUp} size="lg" color="rgb(0,168,168)" />
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                                <OverlayTrigger rootClose overlay={<Tooltip id="tooltip-disabled6">Move Down</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button
                                            variant="light"
                                            type="submit"
                                            size="sm"
                                            style={reOrderDownDisabled() ? enabledStyle : disabledStyle}
                                            onClick={e => handleReOrder(false)}
                                            disabled={!reOrderDownDisabled()}>
                                            <FontAwesomeIcon icon={faArrowDown} size="lg" color="rgb(0,168,168)" />
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                            </ButtonGroup>
                            <ButtonGroup>
                            <OverlayTrigger rootClose overlay={<Tooltip id="tooltip-disabled7">Add Evidence</Tooltip>}>
                                <Button
                                    variant="light"
                                    type="submit"
                                    size="sm"
                                    style={{ backgroundColor: 'white', border: 'none' }}
                                    onClick={e => handleAddClicked()}>
                                    <FontAwesomeIcon icon={faPlus} size="lg" color="royalblue" />
                                </Button>
                                </OverlayTrigger>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Container>
                </Row>
                <Row>
                    <table  style={{ tableLayout: 'fixed', width:'100%' }}>
                            <thead className="thead" style={{ maxWidth: '1200px', display:'block' }}>
                                <tr>
                                    <th style={{ width: '5vw', textAlign: 'center'}}></th>
                                    <th style={{ width: '5vw', textAlign: 'center'}}></th>
                                    <th style={{ width: '20vw', textAlign: 'left'}}>Work Product Name</th>
                                    <th style={{ width: '25vw', textAlign: 'left'}}>Comment</th>
                                    <th style={{ width: '6vw',maxWidth:'6vw', textAlign: 'center'}}>Valid</th>
                                    <th style={{ width: '6vw',maxWidth:'6vw', textAlign: 'center'}}>Reviewed</th>
                                    <th style={{ width: '6vw',maxWidth:'6vw', textAlign: 'center'}}>Process</th>
                                </tr>
                            </thead>
                            <tbody style={{ maxHeight: '300px', maxWidth: '1200px', display:'block' , overflow: 'auto' }}>
                                {wrappedEvidence}
                            </tbody>
                    </table>
                </Row>
                <ConfirmDeleteModal
                    showDeleteModal={showDeleteModal}
                    close={closeDeleteModal}
                    deleteAction={handleDelete}
                    deleteQuestion={DELETE_QUESTION}
                    deleteImpact={DELETE_IMPACT}
                />
                <EvidenceModal
                    modalState={evidenceModalState}
                    setModalState={setEvidenceModalState}
                    evidence={evidence}
                    nodeInfo={nodeInfo}
                    formatEvidenceData={formatEvidenceData} />

                    
                <NetworkErrorModal  showNetworkErrorModal={showNetworkErrorModal} />
            </Container>
        </Styles>
    )
}

export default Evidence;