import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import '../modal.css';

function NetworkErrorModal(props){

    function reload(){
        window.location.reload(false);
    }

    return(
        <Modal show={props.showNetworkErrorModal} animation bsSize="large">
            <Modal.Header>                
                <Modal.Title style={{fontSize:'18px', color:'dimgray'}}>
                    <img
                        src={require('../assets/icon.png')}
                        width="30"
                        height="30"
                        className="d-inline-block align-middle"
                        alt="CAP logo"/>Network Error</Modal.Title>
            </Modal.Header>
            <Row className= "d-flex justify-content-center">
            <Col className='col-md-10'>
            <Modal.Body>            
                <text style={{fontSize:'14px', color:'dimgray'}}>Any pending changes may be lost. Reloading will return you to the homepage when the connection is reestablished.</text> 
                <br></br>               
                <text style={{fontSize:'12px',color:'dimgray'}}></text>
            </Modal.Body>
            </Col>
            </Row>            
            <Modal.Footer style={{backgroundColor:'rgb(0,168,168)'}}>
                <Button
                    variant="outline-light"
                    size='sm'                
                    onClick={()=>reload()}
                    >Reload</Button>{' '}                   
            </Modal.Footer>
        </Modal>
    );
}

export default NetworkErrorModal;