import React, { useState, useEffect } from 'react';
import { Container, Modal, FormGroup, Button, Spinner, FormControl, FormText, Row, Col } from 'react-bootstrap';
import '../modal.css';
import { emailUtils } from '../utils/helpers';
import { Auth } from 'aws-amplify';

const allowedSpecialCharacters = "^ $ * . [ ] { } ( ) ? \" ! @ # % & / \\ , > < ' : ; | _ ~ ` - + =";
const PASSWORD_HELP_TEXT = () => (
    <div>
        <p><b>Password Rules:</b></p>
        <ul style={{ listStyleType: 'circle' }}>
            <li>Must be at least eight characters in length.</li>
            <li>Cannot contain spaces.</li>
            <li>Must contain one uppercase character.</li>
            <li>Must contain one lowercase character.</li>
            <li>Must contain one number.</li>
            <li>Must contain one special character.</li>
            <li>Allowed special characters: {allowedSpecialCharacters}</li>
        </ul>
    </div>
);

//8 characters, 1 Uppercase, 1 Lowercase, 1 number, 1 special character
const PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]\\{}()?"!@#%&/,><':;|_\+\-\=~`])\S{8,99}$/;
const RESET_ERROR_TEXT = "Error resetting password. Please check your code and try again.";

function ResetPasswordModal(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showResetModal, setShowResetModal] = useState(false);
    const [showFinishedModal, setShowFinishedModal] = useState(false);
    const [resetEmail, setResetEmail] = useState('');
    const [resetCode, setResetCode] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isConfirmedPasswordValid, setIsConfirmedPasswordValid] = useState(false);
    const [requestErrorText, setRequestErrorText] = useState("");
    const [infoBlockText, setInfoBlockText] = useState("");


    //reset when modal is shown
    useEffect(() => {
        if (props.showConfirmationModal === true) {
            setResetEmail('');
            setIsEmailValid(false);
            setShowFinishedModal(false);
            setRequestErrorText('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showConfirmationModal]);

    const tryHideModal = (e) => {
        props.setShowConfirmationModal(false)
    }

    const validateField = (fieldText) => {
        return fieldText.match(PASSWORD_PATTERN) ? true : false;
    }

    const validateEmailField = (fieldText) => {
        //don't allow empty or only whitespace
        return fieldText.trim().length === 0 ? false : true;
    }

    const handleChangePassword = (event) => {
        setPassword(event.target.value);
        setIsPasswordValid(validateField(event.target.value));
    }

    const handleChangeConfirmedPassword = (event) => {
        setConfirmPassword(event.target.value);
        setIsConfirmedPasswordValid(validateField(event.target.value));
    }

    const handleSubmit = async event => {
        event.preventDefault();
        setIsLoading(true);
        try {
            await resetPassword();
        } catch {
            setInfoBlockText(RESET_ERROR_TEXT)
        }
    }

    const handleSubmitWithEnter = async e => {
        if (!isConfirmedPasswordValid || !isPasswordValid || password !== confirmPassword) {
            return;
        }
        setIsLoading(true);
        await resetPassword();
    }

    const handleEnterPress = (e) => {
        if(props.showConfirmationModal){
            emailUtils.validateEmailCase(e);
        }
        
        var code = e.keyCode || e.which;
        if (code === 13) {
            if(props.showConfirmationModal){
                onConfirmation();
            }
            else if(showResetModal){
                handleSubmitWithEnter();
            }
        }
    }

    const onConfirmation = () => {
        if(isEmailValid){
            Auth.forgotPassword(resetEmail)
                .then(data => handleResetCodeSendSuccess(data))
                .catch(err => handleResetCodeSendError(err));
        }
    }

    function handleResetCodeSendSuccess(data) {
        props.setShowConfirmationModal(false);
        setShowResetModal(true);
    }

    function handleResetCodeSendError(err) {
        if (err.code === "UserNotFoundException") {
            setRequestErrorText("Email address not found. Contact gayli.craver@cepedasystems.com for assistance.");
        }
        else if (err.code === "NotAuthorizedException") {
            setRequestErrorText("User does not have a password set. Check your email for a temporary password. Contact gayli.craver@cepedasystems.com for assistance.")
        }
        else if (err.code === "LimitExceededException") {
            setRequestErrorText("Reset attempts exceeded. Try again later. Contact gayli.craver@cepedasystems.com for assistance.");
        }
        else {
            setRequestErrorText("Failed to send reset code. Contact gayli.craver@cepedasystems.com for assistance.");
        }
    }

    const onFinishedAcknowledge = () => {
        setShowFinishedModal(false);
        window.location.reload();
    }

    const handleChangeEmail = (event) => {
        setResetEmail(event.target.value);
        setIsEmailValid(validateEmailField(event.target.value));
    }

    const handleChangeResetCode = (event) => {
        setResetCode(event.target.value);
    }

    async function resetPassword() {
        await Auth.forgotPasswordSubmit(resetEmail, resetCode, password)
            .then(data => handleResetSuccess(data))
            .catch(err => handleResetError(err));
    }

    function handleResetSuccess(data) {
        setShowResetModal(false);
        setShowFinishedModal(true);

        setIsLoading(false);
    }

    function handleResetError(err) {
        if (err.code === "CodeMismatchException") {
            setInfoBlockText("Invalid verification code provided, please try again. Contact gayli.craver@cepedasystems.com for assistance.");
        }
        else {
            setRequestErrorText("Failed to reset password. Contact gayli.craver@cepedasystems.com for assistance.");
        }
        setIsLoading(false);
    }

    const textAreaErrorStyle = {
        border: "1px solid red",
        width: "100%",
        paddingLeft: '5px'
    };

    const textAreaStyle = {
        width: "100%",
        paddingLeft: '5px'
    };

    return (
        <Container>
            <Modal bsSize="small"
                show={props.showConfirmationModal}
                onHide={(e) => tryHideModal(false)}
                animation
                style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
                size='sm'>
                <Modal.Header closeButton disabled={showFinishedModal}>
                    <Modal.Title style={{ fontSize: '18px', color: 'dimgray' }}>
                        <img
                            src={require('../assets/icon.png')}
                            width="30"
                            height="30"
                            className="d-inline-block align-middle"
                            alt="CAP logo"
                        />{' '}Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <FormGroup>
                                <FormText style={{ fontSize: '16px', color: 'dimgray' }}>Enter your email address to send a recovery code.</FormText>
                            </FormGroup>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md="12">
                            <FormGroup
                                controlID="username"
                                bsSize="lg">
                                <FormControl
                                    autoFocus
                                    id='username'
                                    type="email"
                                    label="Email Address"
                                    placeholder="Email Address"
                                    onChange={handleChangeEmail}
                                    style={isEmailValid ? textAreaStyle : textAreaErrorStyle}
                                    onKeyPress={(e) => handleEnterPress(e)}
                                    onPaste={(event) => emailUtils.validateEmailCase(event)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md="12">
                            <FormGroup>
                                <FormText className="text-center" style={{ color: 'red', fontSize: '14px'}}>{requestErrorText}</FormText>
                            </FormGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: 'rgb(0,168,168)' }}>
                    <Button
                        variant="outline-light"
                        size='sm'
                        disabled={!isEmailValid}
                        onClick={(event) => onConfirmation()}
                    >Ok</Button>
                    <Button
                        variant="outline-light"
                        size='sm'
                        onClick={(event) => tryHideModal(false)}
                    >Cancel</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showResetModal}
                onHide={(e) => setShowResetModal(false)}
                animation
                disabled={showFinishedModal}
            >
                <Modal.Header closeButton disabled={showFinishedModal}>
                    <Modal.Title style={{ fontSize: '18px', color: 'dimgray' }}>
                        <img
                            src={require('../assets/icon.png')}
                            width="30"
                            height="30"
                            className="d-inline-block align-middle"
                            alt="CAP logo"
                        />{' '}Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <FormGroup>
                                <FormText style={{ fontSize: '16px', color: 'dimgray' }}>A reset code has been sent to {resetEmail}.</FormText>
                                <FormText style={{ fontSize: '16px', color: 'dimgray' }}>Please complete the form to reset your password.</FormText>
                            </FormGroup>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col></Col>
                        <Col md="auto">
                            <FormGroup
                                controlID="resetCode"
                                bsSize="lg">
                                <FormControl
                                    autoFocus
                                    id='resetCode'
                                    type="code"
                                    label="Reset Code"
                                    placeholder="Reset Code"
                                    onChange={handleChangeResetCode}
                                />
                            </FormGroup>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md="auto">
                            <FormGroup
                                controlID="password"
                                bsSize="large">
                                <FormControl
                                    id="password"
                                    type="password"
                                    label="Password"
                                    placeholder="New Password"
                                    onChange={handleChangePassword}
                                    style={isPasswordValid ? textAreaStyle : textAreaErrorStyle}
                                />
                            </FormGroup>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md="auto">
                            <FormGroup
                                controlID="password"
                                bsSize="large">
                                <FormControl
                                    id="password"
                                    type="password"
                                    label="Password"
                                    placeholder="Confirm Password"
                                    onChange={handleChangeConfirmedPassword}
                                    onKeyPress={(e) => handleEnterPress(e)}
                                    style={isConfirmedPasswordValid ? textAreaStyle : textAreaErrorStyle}
                                />
                            </FormGroup>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md="auto">
                            <FormGroup bsSize="small">
                                <FormText className="text-center" style={{ color: 'red', fontSize: '14px', width: '500px' }}>{infoBlockText}</FormText>
                                <FormText>{PASSWORD_HELP_TEXT()}</FormText>
                            </FormGroup>
                        </Col>
                        <Col></Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: 'rgb(0,168,168)' }}>
                    <Button
                        variant="outline-light"
                        size='sm'
                        disabled={!isConfirmedPasswordValid || !isPasswordValid || password !== confirmPassword || resetCode.length < 1}
                        onClick={handleSubmit}>
                        {isLoading &&
                            <Spinner
                                as="span"
                                animation="border"
                                size='sm'
                                role="status"
                                aria-hidden="true"
                            />}
                        {!isLoading ? "Reset" : 'Resetting...'}
                    </Button>{' '}
                    <Button
                        variant="outline-light"
                        size='sm'
                        onClick={(event) => setShowResetModal(false)}>
                        Cancel
                    </Button>{' '}
                </Modal.Footer>
            </Modal>

            <Modal bsSize="small"
                show={showFinishedModal}
                onHide={(e) => setShowFinishedModal(false)}
                animation
                style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
                size='sm'>
                <Modal.Header closeButton disabled={showFinishedModal}>
                    <Modal.Title style={{ fontSize: '18px', color: 'dimgray' }}>
                        <img
                            src={require('../assets/icon.png')}
                            width="30"
                            height="30"
                            className="d-inline-block align-middle"
                            alt="CAP logo"
                        />{' '}Success</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Row>
                        <Col></Col>
                        <Col md="auto">
                            <FormGroup bsSize="small">
                                <FormText style={{ fontSize: '16px', color: 'dimgray' }}>Password reset successfully.</FormText>
                                <FormText style={{ fontSize: '16px', color: 'dimgray' }}>Please log in.</FormText>
                            </FormGroup>
                        </Col>
                        <Col></Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: 'rgb(0,168,168)' }}>
                    <Button
                        variant="outline-light"
                        size='sm'
                        onClick={(event) => onFinishedAcknowledge()}
                    >Ok</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default ResetPasswordModal;