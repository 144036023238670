import { Auth } from 'aws-amplify';

const API_NAME  = 'api';

//Refreshes the authorization header for making API calls
async function getDefaultAPIHeaders(body = null){
    const currentSession = await Auth.currentSession();
    return {
        headers:{
            'Content-Type': 'application/json',
            Authorization: currentSession.idToken.jwtToken
        },
        body: body
    };
}

//Returns an object that describes the results of the API calls.
//@param error - boolean to indicate if the call was successful
//@param returnValue - the results of the API call
class APIResult {
    constructor(error, returnValue){
        this.error = error;
        this.returnValue = returnValue;
    }
}

//Converts a back end object to its front end representation
//@param dataMapping - the data mapping from DATA_MAPPINGS to use for the conversion
//@param backEndItem - the back end item to convert to a front end item
function convertToFrontEnd(dataMapping, backEndItem){
    let newItem = {};
    Object.keys(dataMapping).forEach(k=>{
        newItem[k] = backEndItem[dataMapping[k]];
    });
    return newItem;
}

function convertToBackEnd(dataMapping, frontEndItem, defaultValues){
    let newItem = {};
    const mappingKeys = Object.keys(dataMapping);
    const mappingValues = Object.values(dataMapping);
    for(let i = 0; i < mappingValues.length; i++){
        newItem[mappingValues[i]] = frontEndItem[mappingKeys[i]];
    }
    if(defaultValues){
        Object.keys(defaultValues).forEach(k=>{
            delete newItem[k];
        })
    }
    return newItem;
}

export { APIResult, convertToFrontEnd, convertToBackEnd, getDefaultAPIHeaders, API_NAME };