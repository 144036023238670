import React from "react";
import "./NotFound.css";

const NotFound = (props) => {
  return(
    <div className="NotFound">
      <h3>Sorry, page not found!</h3>
      <img
                    src={require('../assets/page-not-found.jpg')}                   
                    className="d-inline-block align-middle"
                    alt=""
                />
    </div>
  );
}

export default NotFound;