import React, { useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToggleButton from 'react-toggle-button';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { API } from 'aws-amplify';
import { Constants, getDefaultAPIHeaders } from '../utils/helpers';
import NetworkErrorModal from '../presenters/NetworkErrorModal';

const VALID_INDEX = 0;
const REVIEWED_INDEX = 1;
const PROCESS_INDEX = 2;
const VALID = "V";
const INVALID = "I";
const REVIEWED = "R";
const NOT_REVIEWED = "N";
const PROCESS = "P";
const NOT_PROCESS = "E";

const checkBoxStyle = {
    width: "15px",
    height: "15px"
};

function getItemState(index, state) {
    return state[index];
}

const EvidenceItem = ({ item, nodeInfo, updateSelected }) => {
    const [showNetworkErrorModal, setShowNetworkErrorModal] = useState(false);
    const [isValid, setIsValid] = useState(getItemState(VALID_INDEX, item.state) === VALID ? true : false);
    const [isReviewed, setIsReviewed] = useState(getItemState(REVIEWED_INDEX, item.state) === REVIEWED ? true : false);
    const [isProcess, setIsProcess] = useState(getItemState(PROCESS_INDEX, item.state) === PROCESS ? true : false);
    //Assigning unique keys for each data column so
    // that react can perform precise rerendering
    const selectedChkBox = item.id + "selectedChkBox";
    const locationKey = item.id + "location";
    const commentsKey = item.id + "comments";
    const validKey = item.id + "valid";
    const reviewedKey = item.id + "reviewed";

    let isLinkable = false;

    if (item.isHyperlink || item.isHyperlink === undefined) {
        isLinkable = true;
    }

    const handleLinkClicked = (event) => {
        event.preventDefault();
        window.open(item.location, '_blank');
    }

    const getStateString = (itemIndex, state) => {
        let validCode = isValid ? VALID : INVALID;
        let reviewedCode = isReviewed ? REVIEWED : NOT_REVIEWED;
        let processCode = isProcess ? PROCESS : NOT_PROCESS;

        if (itemIndex === VALID_INDEX) {
            if (state) { validCode = VALID; }
            else { validCode = INVALID; }
        }
        else if (itemIndex === REVIEWED_INDEX) {
            if (state) { reviewedCode = REVIEWED; }
            else { reviewedCode = NOT_REVIEWED; }
        }
        else if (itemIndex === PROCESS_INDEX) {
            if (state) { processCode = PROCESS; }
            else { processCode = NOT_PROCESS; }
        }
        return validCode + reviewedCode + processCode;
    }

    const handleUpdateEvidenceState = async (itemIndex, state) => {
        let newState = getStateString(itemIndex, state);

        const body = {
            appraisal_evidence_id: item.id,
            revision_number: item.revNumber,
            evidence_notes: item.comments,
            evidence_fileName: item.fileName,
            evidence_path: item.location,
            isHyperlink: item.isHyperlink,
            evidence_state: newState.toString(),
            node_id: nodeInfo.nodeId,
            appraisal_id: nodeInfo.appraisalId,
            project_id: nodeInfo.projectId
        };
        try {
            API.put(Constants.API_PATH, Constants.APPRAISAL_EVIDENCE_PATH, await getDefaultAPIHeaders(body))
                .catch(err => {
                    if(err.message === "Network Error"){
                        setShowNetworkErrorModal(true);
                    }
                });

            item.revNumber = item.revNumber + 1;
            item.state = newState.toString();

            if (itemIndex === VALID_INDEX) {
                setIsValid(state);
            }
            else if (itemIndex === REVIEWED_INDEX) {
                setIsReviewed(state);
            }
            else if (itemIndex === PROCESS_INDEX) {
                setIsProcess(state);
            }
        }
        catch (e) {
            console.log('Update evidence state failure' + e);
        }
    }

    const handleCopyPathClicked = (e) => {
        navigator.clipboard.writeText(item.location.replace(/"/g, ''));
    }

    const toggleIsValid = (e) => {
        handleUpdateEvidenceState(VALID_INDEX, !e);
    }

    const toggleIsReviewed = (e) => {
        handleUpdateEvidenceState(REVIEWED_INDEX, !e);
    }

    const toggleIsProcess = (e) => {
        handleUpdateEvidenceState(PROCESS_INDEX, !e);
    }

    return (
        <React.Fragment>
            <td style={{ width: '5vw', textAlign: 'center', verticalAlign:'top'}} key={selectedChkBox}>
                <input type="checkbox"
                    onChange={(e) => updateSelected(e, item.id)}
                    checked={item.selected}
                    style={checkBoxStyle}
                />
            </td>
            <td style={{ width: '5vw', textAlign: 'left', verticalAlign:'top'}}>
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Copy Path</Tooltip>}>
                    <span>
                        <Button
                            variant="light"
                            style={{ backgroundColor: 'white', border: 'none' }}
                            type="submit"
                            size="sm"
                            onClick={e => handleCopyPathClicked(e)}>
                            <FontAwesomeIcon icon={faClipboard} size="lg" color="rgb(0,168,168)" />
                        </Button>
                    </span>
                </OverlayTrigger>
            </td>
            <td key={locationKey} style={{ width: '15vw', maxWidth:'15vw', textAlign: 'left', color: 'dimgray', wordWrap:'break-word', verticalAlign:'top'}}>
                <OverlayTrigger placement="top-start" overlay={<Tooltip id="tooltip-disabled">{item.location}</Tooltip>}>
                    { isLinkable ?
                    <a href={item.location} title={item.location} style={{ wordWrap:'break-word'}} onClick={e => handleLinkClicked(e)}>{item.fileName}</a>
                    :
                    <p style={{ wordWrap:'break-word' }}>{item.fileName}</p>
                    }
                </OverlayTrigger>
            </td>
            <td style={{ width: '25vw', maxWidth:'25vw', textAlign: 'left', color: 'dimgray', verticalAlign:'top'}} key={commentsKey}>
                <p style={{ wordWrap:'break-word', }}>{item.comments}</p>
            </td>
            <td style={{ width: '5vw', maxWidth:'5vw', verticalAlign:'top'}} key={validKey} align='left'><ToggleButton value={isValid} onToggle={(e) => toggleIsValid(e)} inactiveLabel="No" activeLabel="Yes" /></td>
            <td style={{ width: '5vw', maxWidth:'5vw', verticalAlign:'top'}} key={reviewedKey} align='left'><ToggleButton value={isReviewed} onToggle={(e) => toggleIsReviewed(e)} inactiveLabel="No" activeLabel="Yes" /></td>
            <td style={{ width: '5vw', maxWidth:'5vw', verticalAlign:'top'}} key={reviewedKey} align='left'><ToggleButton value={isProcess} onToggle={(e) => toggleIsProcess(e)} inactiveLabel="No" activeLabel="Yes" /></td>
        
            <NetworkErrorModal  showNetworkErrorModal={showNetworkErrorModal} />
        </React.Fragment>
    );
}

export default EvidenceItem;