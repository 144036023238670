import React, { useState } from 'react';
import { OrgRepository } from '../utils/Repositories/OrgRepository';
import { Modal, FormGroup, FormControl, Button, Spinner, Row, Col } from 'react-bootstrap';
import './OrganizationModal.css';
import { AsYouType } from 'libphonenumber-js';
import '../modal.css';

const NUM_ZERO = 48;
const NUM_NINE = 57;
const NUMPAD_0 = 96;
const NUMPAD_9 = 105;
const BACK_SPACE = 8;
const MAX_LENGTH = 16;

const TooltipConstants = {
    NAME_TOOLTIP_1: "Name must not be empty.",
    NAME_TOOLTIP_2: "An organization with that name already exists.",
    NOT_CHANGED: "Organization information has not changed."
}

//Modal the handles adding and editing an organization
function OrganizationModal(props){
    const [isOkLoading, setIsOkLoading] = useState(false);
    const [hasValidationError, setHasValidationError] = useState(false);
    const autoFocus = React.createRef();

    const handleClick = async (e) => {
        setIsOkLoading(true);
        let error = false;
        const orgRepo = new OrgRepository();
        switch(props.modalTitleFragment){
            case "Add":
                error = validateAdd();
                if(!error.foundError){
                    let newOrg = {
                        markedForDelete:false,
                        name: props.modalState.name,
                        address: props.modalState.address,
                        contactInfo: props.modalState.contactInfo,
                        isActive:true
                    }
                    try{
                        const result = await orgRepo.createOne(newOrg);
                        newOrg = {
                            ...newOrg,
                            id: result.returnValue
                        };
                        props.orgsArray.add(newOrg);
                    } catch(e){
                        console.log(e);
                    }
                } else{
                    props.setModalState(prevState=>({...prevState, nameError: error.nameError, nameTooltip: error.nameTooltip}));
                }
                break;
            case "Edit":
                error = validateEdit();
                if(!error.foundError){
                    const org = {
                        id: props.modalState.id,
                        name: props.modalState.name,
                        address: props.modalState.address,
                        contactInfo: props.modalState.contactInfo,
                        isActive: props.editOrg.isActive
                    };
                    const result = await orgRepo.updateOne(org);
                    if(!result.error){
                        props.orgsArray.replace(org.id, result.returnValue);
                    } else {
                        console.log(result.returnValue);
                    }
                } else{
                    props.setModalState(prevState=>({...prevState, nameError: error.nameError, nameTooltip: error.nameTooltip,
                        addressError: error.addressError, addressTooltip: error.addressTooltip, contactInfoError: error.contactInfoError,
                        contactInfoTooltip: error.contactInfoTooltip}));                    
                }
                break;
            default:
                break;
        }
        setIsOkLoading(false);
        if(!error.foundError){
            props.close();
        }
    }

    const handleNameChange = (value) => {
        let error = false;
        switch(props.modalTitleFragment){
            case "Add":
                error = validateAdd(value);
                props.setModalState(prevState=>({...prevState, name: value, nameError: error.nameError, nameTooltip: error.nameTooltip}));
            break;
            case "Edit":
                error = validateEdit(value);
                props.setModalState(prevState=>({...prevState, name: value, nameError: error.nameError, nameTooltip: error.nameTooltip,
                    addressError: error.addressError, addressTooltip: error.addressTooltip, contactInfoError: error.contactInfoError,
                    contactInfoTooltip: error.contactInfoTooltip}));
            break;
            default:
            break;
        }
    }

    const handleAddressChange = (value) => {
        if(props.modalTitleFragment === "Edit"){
            const error = validateEdit(null, value);
            props.setModalState(prevState=>({...prevState, address: value, nameError: error.nameError, nameTooltip: error.nameTooltip,
                addressError: error.addressError, addressTooltip: error.addressTooltip, contactInfoError: error.contactInfoError,
                contactInfoTooltip: error.contactInfoTooltip}));
        } else {
            props.setModalState(prevState=>({...prevState, address: value}));
        }
    }

    const handleContactInfoChange = (value) => {
        //If if the user is deleting the area code,
        // then we need to detect that to prevent
        // the AsYouType from reformatting (562
        // back in to (562) instead of 56
        const areaCode = /\([\d]+/;
        if(areaCode.test(value)){
            //Javscript's test method is too greedy to be completely useful
            // because it returns a match before evaluating a negative lookahead
            // as far as I can tell...
            if(!value.includes(')')){
                value = value.substr(0, value.length - 1);
            }
        }
        value = new AsYouType('US').input(value);
        if(props.modalTitleFragment === "Edit"){
            const error = validateEdit(null, null, value);
            props.setModalState(prevState=>({...prevState, contactInfo: value, nameError: error.nameError, nameTooltip: error.nameTooltip,
                addressError: error.addressError, addressTooltip: error.addressTooltip, contactInfoError: error.contactInfoError,
                contactInfoTooltip: error.contactInfoTooltip}));
        } else {
            props.setModalState(prevState=>({...prevState, contactInfo: value}));
        }
    }

    //Determines if the organizations modal can add.
    const validateAdd = (value = null) => {
        const name = value !== null ? value : props.modalState.name;
        const clashes = props.orgsArray.value.filter(o=>o.name === name);
        let foundError = false, nameError = false;
        let nameTooltip = "";
        if(name.length === 0){
            foundError = true;
            nameError = true;
            nameTooltip = TooltipConstants.NAME_TOOLTIP_1
        }
        if(clashes.length > 0){
            foundError = true;
            nameError = true;
            nameTooltip = TooltipConstants.NAME_TOOLTIP_2
        }
        
        setHasValidationError(nameError);

        return {
            foundError: foundError,
            nameError: nameError,
            nameTooltip: nameTooltip
        }
    }

    //Determines if the organizations modal can edit
    const validateEdit = (nameValue = null, addressValue = null, contactInfoValue = null) => {
        let foundError = false, nameError = false, addressError = false, contactInfoError = false;
        let nameTooltip = "", addressTooltip = "", contactInfoTooltip = "";
        const name = nameValue !== null ? nameValue : props.modalState.name;
        const address = addressValue !== null ? addressValue : props.modalState.address;
        const contactInfo = contactInfoValue !== null ? contactInfoValue : props.modalState.contactInfo;
        const clashes = props.orgsArray.value.filter(o=>o.name === name);
        if(clashes.length > 0 && clashes[0].id !== props.editOrg.id){
            foundError = true;
            nameError = true;
            nameTooltip = TooltipConstants.NAME_TOOLTIP_2;
        } 
        else if(props.editOrg.name === name && props.editOrg.address === address && props.editOrg.contactInfo === contactInfo){
            foundError = true;
            nameError = true;
            nameTooltip = TooltipConstants.NOT_CHANGED;
            addressError = true;
            addressTooltip = TooltipConstants.NOT_CHANGED;
            contactInfoError = true;
            contactInfoTooltip = TooltipConstants.NOT_CHANGED;
        }
        else if (name === ""){
            foundError = true;
            nameError = true;
            nameTooltip = TooltipConstants.NAME_TOOLTIP_1;
        }
        
        setHasValidationError(nameError);

        return{
            foundError: foundError,
            nameError: nameError,
            nameTooltip: nameTooltip,
            addressError: addressError,
            addressTooltip: addressTooltip,
            contactInfoError: contactInfoError,
            contactInfoTooltip: contactInfoTooltip
        }
    }

    //Prevents non numerics from being entered into the text field
    // and limits the field to 14 characters (the max length of a
    // US phone number)
    const onCIKeyDown = (event) => {
        const key = event.which;
        if(key === BACK_SPACE) return true;
        if( ((key >= NUM_ZERO && key <= NUM_NINE) 
            || (key >= NUMPAD_0 && key <= NUMPAD_9)) 
            && (event.target.value.length < MAX_LENGTH) ) return true;
        event.preventDefault();
    }

    const errorStyle = {
        border: "1px solid red"
    };
    const defaultStyle = {};

    return(
        <Modal show={props.showModal} onHide={(e)=>props.close(e)} animation onEntered={() => autoFocus.current.focus()}>
            <Modal.Header closeButton>
                <Modal.Title style={{fontSize:'18px', color:'dimgray'}}>
                <img
                    src={require('../assets/icon.png')}
                    width="30"
                    height="30"
                    className="d-inline-block align-middle"
                    alt="CAP logo"
                />{props.modalTitleFragment} Organization</Modal.Title>
            </Modal.Header>
            <Row className= "d-flex justify-content-center">
            <Col className='col-md-10'>
            <Modal.Body>
                    <FormGroup controlId="userBasicText">
                        <FormControl ref={autoFocus}
                            type="text"
                            onChange={(event)=>handleNameChange(event.target.value)}
                            value={props.modalState.name}
                            placeholder="Name"
                            style={props.modalState.nameError ? errorStyle : defaultStyle}
                            title={props.modalState.nameTooltip}
                        />
                    </FormGroup>
                    <FormGroup controlId="userBasicText">
                        <FormControl
                            type="text"
                            onChange={(event)=>handleAddressChange(event.target.value)}
                            value={props.modalState.address}
                            placeholder="Address"
                            style={props.modalState.addressError ? errorStyle : defaultStyle}
                            title={props.modalState.addressTooltip}
                        />
                    </FormGroup>
                    <FormGroup controlId="userBasicText">
                        <FormControl
                            onKeyDown={(event)=>onCIKeyDown(event)}
                            type="text"
                            onChange={(event)=>handleContactInfoChange(event.target.value)}
                            value={props.modalState.contactInfo}
                            placeholder="Phone Number"
                            style={props.modalState.contactInfoError ? errorStyle : defaultStyle}
                            title={props.modalState.contactInfoTooltip}
                        />
                    </FormGroup>
            </Modal.Body>
            </Col>
            </Row>
            <Modal.Footer style={{backgroundColor:'rgb(0,168,168)'}}>
            <Button
                    variant="outline-light"
                    size='sm'
                    disabled={hasValidationError}
                    onClick={(event)=>handleClick()}
                    >
                    {isOkLoading &&
                        <Spinner
                            as="span"
                            animation="border"
                            size='sm'
                            role="status"
                            aria-hidden="true"
                        />}
                    {!isOkLoading ? "Save" : ''}
                </Button>{' '}                   
            </Modal.Footer>
        </Modal>
    );
}

export default OrganizationModal;