import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import brand from '../assets/icon.png';
import styled from 'styled-components';

const Styles = styled.div`
.navbar {
    background:rgb(0, 168, 168);   
    margin-bottom: 1.5rem;  
  } 
`;

export const SecondaryNavigationBar = (props) => {
    return (
        <Styles>
        <Navbar className='navbar navbar-expand' variant='dark'>
            <Navbar.Brand>
                <img
                    src={brand}
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    alt="CAP logo and text"
                />{' '}
                CAP
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    <Nav.Item><Nav.Link href="/">Home</Nav.Link></Nav.Item>                  
                    <Nav.Item><Nav.Link href="/profile">Profile</Nav.Link></Nav.Item>
                    <Nav.Item onClick={props.onLogoutClicked}><Nav.Link href="/login">Logout </Nav.Link></Nav.Item>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
        </Styles>
)
};