import React, { useState, useEffect } from 'react';
import { Button, Spinner, Row, Col, Form, Modal } from 'react-bootstrap';
import { AppraisalRepository, AppraisalStateRepository } from '../utils/repositories';
import { modelContentUtils } from '../utils/helpers';
import LoadingComponent from '../presenters/LoadingComponent';
import ReportFragment from '../presenters/ReportFragment';
import { PRACTICE_AREAS } from '../model/practiceareas';
import { PRACTICE_AREA_RELATIONSHIPS } from '../model/practicearearelationships';
import { PRACTICES } from '../model/practices';
import { Constants, getDefaultAPIHeaders } from '../utils/helpers';
import { API } from 'aws-amplify';
import { ICON_MAP, CHECK_ICON, X_ICON } from '../utils/IconMap';
import { CharacterizationsLegend } from '../utils/IconLegends';
import NewWindow from 'react-new-window';

async function fetchData(setDataLoading, setAppraisalName, setPracticeAreas, setProjects, setMaturityLevel, setEvidence, setFindings) {
    setDataLoading(true);

    const currentAppraisalId = localStorage.getItem("selectedAppraisal");
    if (currentAppraisalId) {
        const apiRepo = new AppraisalRepository(null, null, { selected: false, availableForSelection: false });
        const appraisalData = await apiRepo.getOne(currentAppraisalId, false);
        if (!appraisalData.error) {

            setAppraisalName(appraisalData.returnValue.name);
            setProjects(appraisalData.returnValue.projects.filter(x => x.name !== "Organizational Roll-up"));

            setMaturityLevel(modelContentUtils.getMaturityLevelFromView(appraisalData.returnValue.view));
            setPracticeAreas(modelContentUtils.getPracticeAreasFromAppraisalProjects(appraisalData.returnValue.projects, true, true));

            let evidenceData = await getAppraisalEvidence(currentAppraisalId);
            setEvidence(evidenceData);

            let findingsData = await getAppraisalFindings(currentAppraisalId);
            setFindings(findingsData);

        } else {
            console.log("error getting appraisal data: ", appraisalData.returnValue);
        }
    }

    setDataLoading(false);
}

async function getAppraisalFindings(currentAppraisalId) {
    const apiPath = `${Constants.APPRAISAL_ITEM_PATH}/${currentAppraisalId}`
    const data = await API.get(Constants.API_PATH, apiPath, await getDefaultAPIHeaders());
    return data;
}

async function getAppraisalEvidence(currentAppraisalId) {
    const apiPath = `${Constants.APPRAISAL_EVIDENCE_PATH}/${currentAppraisalId}`
    const data = await API.get(Constants.API_PATH, apiPath, await getDefaultAPIHeaders());
    return data;
}

async function getAppraisalStatesByProject(currentAppraisalId, projectId) {
    let stateEntries = "";
    const stateRepo = new AppraisalStateRepository();
    const response = await stateRepo.getAll(currentAppraisalId, projectId);
    if (!response.error) {
        stateEntries = response.returnValue;
    }
    return stateEntries;
}

const SummaryView = (props) => {
    const [dataLoading, setDataLoading] = useState(true);
    const [optionsVisible, setOptionsVisible] = useState(true);
    const [appraisalName, setAppraisalName] = useState('');
    const [reportLoading, setReportLoading] = useState(false);
    const [practiceAreas, setPracticeAreas] = useState({});
    const [projects, setProjects] = useState({});
    const [evidence, setEvidence] = useState({});
    const [findings, setFindings] = useState({});
    const [maturityLevel, setMaturityLevel] = useState('');
    const [summaryColumns, setSummaryColumns] = useState({});

    useEffect(() => {
        if (props.showModal) {
            const getData = () => fetchData(setDataLoading, setAppraisalName, setPracticeAreas, setProjects, setMaturityLevel, setEvidence, setFindings);
            getData();
            setOptionsVisible(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showModal]);


    async function getSummaryData(currentAppraisalId) {
        let summaryEntries = [];

        for (let projectIndex = 0; projectIndex < projects.length; projectIndex++) {
            let projectName = projects[projectIndex].name;
            let projectSpecificPracticeAreas = projects[projectIndex].practiceAreas;
            let practiceAreaData = [];

            //get all state entries for the current project
            let stateData = await getAppraisalStatesByProject(currentAppraisalId, projects[projectIndex].id);

            for (let i = 0; i < practiceAreas.length; i++) {

                let practiceAreaName = PRACTICE_AREAS[practiceAreas[i].id].Name;
                let practiceNodes = PRACTICE_AREA_RELATIONSHIPS[practiceAreas[i].id].filter(x => x.Level <= maturityLevel);
                let practiceData = [];

                for (let j = 0; j < practiceNodes.length; j++) {
                    let practiceName = PRACTICES[practiceNodes[j].Id].Abbreviation;

                    let characterization = "";
                    let affirmed = false;
                    let hasEvidence = false;
                    let hasStrengths = false;
                    let hasWeaknesses = false;
                    let hasIOs = false;

                    //see if the project contains the current practice area
                    if (projectSpecificPracticeAreas.includes(practiceAreas[i].id)) {
                        //get characterization and affirmed
                        for (let l = 0; l < stateData.length; l++) {
                            if (stateData[l].nodeId === practiceNodes[j].Id) {
                                characterization = stateData[l].state;
                                affirmed = stateData[l].affirmed;
                            }
                        }

                        //see if we have evidence
                        for (let l = 0; l < evidence.length; l++) {
                            if (evidence[l].node_id === practiceNodes[j].Id && evidence[l].project_id === projects[projectIndex].id) {
                                hasEvidence = true;
                            }
                        }

                        //see if we have strengths, weakenesses, and IOs
                        for (let l = 0; l < findings.length; l++) {
                            if (findings[l].node_id === practiceNodes[j].Id && findings[l].project_id === projects[projectIndex].id) {
                                if (findings[l].item_type === "strengths") {
                                    hasStrengths = true;
                                }
                                if (findings[l].item_type === "weaknesses") {
                                    hasWeaknesses = true;
                                }
                                if (findings[l].item_type === "improvement_opportunities") {
                                    hasIOs = true;
                                }
                            }
                        }
                    }
                    else {
                        characterization = 0;
                        affirmed = 0;
                        hasEvidence = 0;
                        hasStrengths = 0;
                        hasWeaknesses = 0;
                        hasIOs = 0;
                    }

                    practiceData.push({
                        practiceName,
                        characterization,
                        affirmed,
                        hasEvidence,
                        hasStrengths,
                        hasWeaknesses,
                        hasIOs
                    });
                }
                practiceAreaData.push({
                    practiceAreaName,
                    practiceData
                });
            }

            summaryEntries.push({
                projectName,
                practiceAreaData
            });
        }

        return summaryEntries;
    }

    function getCharacterizationIcon(value) {
        if (value === 0) {
            return "-";
        }
        else {
            return ICON_MAP[value]
        }
    }

    function getIcon(value) {
        if (value === 0) {
            return "-";
        }
        else {
            return value ? CHECK_ICON : X_ICON;
        }
    }

    const handleGenerateReportClicked = async (event) => {
        setReportLoading(true);

        let currentAppraisalId = localStorage.getItem("selectedAppraisal");

        let summaryData = await getSummaryData(currentAppraisalId);
        let summaryColumns = [];

        for (let i = 0; i < summaryData.length; i++) {
            let summaryRows = [];
            summaryRows.push(<h2 style={{ background: 'rgb(0,168,168)', paddingLeft: '5px' }}><b>{summaryData[i].projectName}</b></h2>);

            let practiceAreaData = summaryData[i].practiceAreaData;
            for (let j = 0; j < practiceAreaData.length; j++) {
                summaryRows.push(<h5 style={{ background: 'rgb(0,168,168)', paddingLeft: '5px' }}><b>{practiceAreaData[j].practiceAreaName}</b></h5>);

                summaryRows.push(
                    <Row style={{ alignContent: 'center', textAlign: 'center' }}>
                        <Col xs={3}>Practice</Col>
                        <Col xs={1}>C</Col>
                        <Col xs={1}>Ar</Col>
                        <Col xs={1}>Af</Col>
                        <Col xs={1}>S</Col>
                        <Col xs={1}>W</Col>
                        <Col xs={1}>I</Col>
                    </Row>
                );
                let practiceData = practiceAreaData[j].practiceData;
                for (let k = 0; k < practiceData.length; k++) {
                    summaryRows.push(
                        <Row style={{ alignContent: 'center', textAlign: 'center' }}>
                            <Col xs={3}><b>{practiceData[k].practiceName}</b></Col>
                            <Col xs={1}>{getCharacterizationIcon(practiceData[k].characterization)}</Col>
                            <Col xs={1}>{getIcon(practiceData[k].hasEvidence)}</Col>
                            <Col xs={1}>{getIcon(practiceData[k].affirmed)}</Col>
                            <Col xs={1}>{getIcon(practiceData[k].hasStrengths)}</Col>
                            <Col xs={1}>{getIcon(practiceData[k].hasWeaknesses)}</Col>
                            <Col xs={1}>{getIcon(practiceData[k].hasIOs)}</Col>
                        </Row>
                    );
                }
                summaryRows.push(<div><br></br></div>);
            }
            summaryColumns.push(<Col md={4}>{summaryRows}</Col>);
        }

        setSummaryColumns(summaryColumns);
        setReportLoading(false);
        setOptionsVisible(false);
        props.setShowModal(false);
    }

    return (
        <React.Fragment>
            <Modal show={props.showModal} onHide={(e) => props.setShowModal(false)} dialogClassName="reportModalStyle">
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '18px', color: 'dimgray' }}>
                        <img
                            src={require('../assets/icon.png')}
                            width="30"
                            height="30"
                            className="d-inline-block align-middle"
                            alt="CAP logo"
                        />{' '}Summary View</Modal.Title>
                </Modal.Header>
                <LoadingComponent isLoading={dataLoading} iconSize={80}>
                    <ReportFragment visible={optionsVisible}>
                        <div style={{ display: "grid", gridTemplateColumns: "10% 80% 10%", gridTemplateRows: "100%", marginTop: '-50px' }}>
                            <div style={{ gridColumnStart: "2", gridRowStart: "1" }}>
                                <Form>
                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <Form.Label column md='3' style={{ color: 'white', backgroundColor: 'rgb(0,168,168)', fontSize: '16px', textAlign: 'center', padding: '.5rem' }}>
                                            Appraisal
                                        </Form.Label>
                                        <Col md={6} style={{ textAlign: 'left' }}>
                                            <Form.Control plaintext readOnly value={appraisalName} style={{ color: 'dimgray' }} />
                                        </Col>
                                    </Form.Group>
                                    <Row className="d-flex justify-content-center">
                                        <Button
                                            variant="outline-light"
                                            className='text-nowrap'
                                            style={{
                                                background: 'rgb(0, 168, 168)',
                                                color: 'white',
                                                border: 'none',
                                                justifyContent: 'center',
                                                padding: '.5rem',
                                                marginTop: '1rem',
                                                marginBottom: '1rem'
                                            }}
                                            onClick={(e) => handleGenerateReportClicked(e)}
                                        >
                                            {reportLoading &&
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size='sm'
                                                    role="status"
                                                    aria-hidden="true"
                                                />}
                                            {!reportLoading ? "Generate Summary View" : ' Generating Summary View'}
                                        </Button>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </ReportFragment>
                </LoadingComponent>
            </Modal>
            {!optionsVisible &&
                <NewWindow>
                    <ReportFragment
                        reportName={"Appraisal Summary"}
                        appraisalHeader={"Appraisal:"}
                        appraisalName={appraisalName}>
                        <div class="row flex-nowrap">{summaryColumns}</div>
                        <Row>
                            {CharacterizationsLegend}
                        </Row>
                    </ReportFragment>
                </NewWindow>
            }
        </React.Fragment>
    );
}
export default SummaryView;