import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Spinner, Modal, Tabs, Tab, FormControl } from 'react-bootstrap';
import { modelContentUtils } from '../utils/helpers';
import { AppraisalRepository, AppraisalStateRepository } from '../utils/repositories';
import useArray from '../hooks/useArray';
import LoadingComponent from '../presenters/LoadingComponent';
import ReportFragment from '../presenters/ReportFragment';
import MultiSelectListReportOptions from '../presenters/MultiSelectListReportOptions';
import PracticeComplianceReportView from '../presenters/PracticeComplianceReportView';
import { PRACTICES, PRACTICES_AREAS_BY_MATURITY_LEVEL, PRACTICE_AREA_RELATIONSHIPS } from '../model/modelcontent';
import NewWindow from 'react-new-window';

const totalPracticesByMaturity = {
    1: 3,
    2: 11,
    3: 18,
    4: 23,
    5: 26,
}

const PRACTICE_STATE_RANKS = {
    "Not Yet Implemented": { value: 8, state: "Not Yet Implemented" },
    "Not Examined": { value: 9, state: "Not Examined" },
    //evaluation ranks
    "Major Issues": { value: 5, state: "Major Issues" },
    "Minor Issues": { value: 6, state: "Minor Issues" },
    "No Issues": { value: 7, state: "No Issues" },
    //benchmark/sustainment ranks
    "Does Not Meet": { value: 1, state: "Does Not Meet" },
    "Partially Meets": { value: 2, state: "Partially Meets" },
    "Largely Meets": { value: 3, state: "Largely Meets" },
    "Fully Meets": { value: 4, state: "Fully Meets" }
}

async function fetchData(setDataLoading, setAppraisalData, projects, practiceAreas) {
    const currentAppraisalId = localStorage.getItem("selectedAppraisal");
    if (currentAppraisalId) {
        const apiRepo = new AppraisalRepository(null, null, { selected: false });
        const appraisalData = await apiRepo.getOne(currentAppraisalId, false);
        if (!appraisalData.error) {
            projects.setValue(appraisalData.returnValue.projects);
            setAppraisalData({
                id: appraisalData.returnValue.id,
                name: appraisalData.returnValue.name,
                view: appraisalData.returnValue.view
            });
            let PAs = modelContentUtils.getPracticeAreasFromAppraisalProjects(appraisalData.returnValue.projects);
            practiceAreas.setValue(PAs);


        } else {
            console.log("error getting appraisal data: ", appraisalData.returnValue);
        }
    }

    setDataLoading(false);
}

function getPracticeOffset(abbrString) {
    return abbrString.substring(abbrString.length - 1) - 1;
}

function getPracticeAreaLevelCount(practiceArea, level){
    return PRACTICE_AREA_RELATIONSHIPS[practiceArea].filter(x => x.Level === level).length;
}

//since the data in the database does not reflect items net yet marked (not examined),
// we must rely on model data to build our visual structure to place the data
function buildEmptyComplianceChartData(practiceAreas, maturityLevel) {
    let complianceData = {};
    let yMax = totalPracticesByMaturity[maturityLevel];
    for (let i = 0; i < practiceAreas.length; i++) {

        var currentLevel1PracticeCount = getPracticeAreaLevelCount(practiceAreas[i], 1);
        var currentLevel2PracticeCount = getPracticeAreaLevelCount(practiceAreas[i], 2);
        var currentLevel3PracticeCount = getPracticeAreaLevelCount(practiceAreas[i], 3);
        var currentLevel4PracticeCount = getPracticeAreaLevelCount(practiceAreas[i], 4);
        var currentLevel5PracticeCount = getPracticeAreaLevelCount(practiceAreas[i], 5);

        complianceData[practiceAreas[i]] = new Array(yMax);
        for (let j = 0; j < yMax; j++) {
            //if this index also has a corresponding practice based on maturity level, mark it as blank
            if ((j < totalPracticesByMaturity[1] && j < currentLevel1PracticeCount)
            || (j >= totalPracticesByMaturity[1] && j < totalPracticesByMaturity[1] + currentLevel2PracticeCount)
            || (j >= totalPracticesByMaturity[2] && j < totalPracticesByMaturity[2] + currentLevel3PracticeCount)
            || (j >= totalPracticesByMaturity[3] && j < totalPracticesByMaturity[3] + currentLevel4PracticeCount)
            || (j >= totalPracticesByMaturity[4] && j < totalPracticesByMaturity[4] + currentLevel5PracticeCount)){
                complianceData[practiceAreas[i]][j] = "Not Examined";
            }
            //if there is no practice for this index, mark it no data so we can gray it out
            else {
                complianceData[practiceAreas[i]][j] = "No Data";
            }
        }
    }
    return complianceData;
}

function getMinState(prevState, currentState) {
    if (prevState === "No Data") {
        return currentState;
    }
    return PRACTICE_STATE_RANKS[prevState].value <= PRACTICE_STATE_RANKS[currentState].value
        ? prevState : currentState;
}

function buildComplianceChartData(previousComplianceData, stateData, firstPass) {
    for (let i = 0; i < stateData.length; i++) {
        const currentState = stateData[i].state;
        const assocPractice = PRACTICES[stateData[i].nodeId];
        const currentStateRank = PRACTICE_STATE_RANKS[currentState];
        //If we don't get an associated practice back then the state is associated
        // with either an appraisal or a level.
        if (assocPractice && currentStateRank !== undefined) {
            const practiceAreaIndex = assocPractice.PracticeAreaID;
            let practiceIndex = 0;
            if(assocPractice.Level === 1){
                practiceIndex = getPracticeOffset(assocPractice.Abbreviation);
            }
            else{
                practiceIndex = totalPracticesByMaturity[assocPractice.Level - 1] + getPracticeOffset(assocPractice.Abbreviation);
            }
            const previousState = previousComplianceData[practiceAreaIndex][practiceIndex];
            if (!firstPass) {
                previousComplianceData[practiceAreaIndex][practiceIndex] = getMinState(previousState, currentState);
            } else {
                previousComplianceData[practiceAreaIndex][practiceIndex] = currentState;
            }
        }
    }
    return previousComplianceData;
}

const PracticeComplianceReportModal = (props) => {
    const [dataLoading, setDataLoading] = useState(true);
    const [optionsVisible, setOptionsVisible] = useState(true);
    const [appraisalData, setAppraisalData] = useState({});
    const [reportLoading, setReportLoading] = useState(false);
    const [includeLevel1, setIncludeLevel1] = useState(false);

    const [hasProjectSelected, setHasProjectSelected] = useState("false");
    const [displayValue, setDisplayValue] = useState("tabbed");

    const allReportData = useArray([]);
    const projects = useArray([]);
    const practiceAreas = useArray([]);

    useEffect(() => {
        if (props.showModal) {
            const getData = () => fetchData(setDataLoading, setAppraisalData, projects, practiceAreas);
            getData();

            setHasProjectSelected(false);
            setOptionsVisible(true);
            setIncludeLevel1(false);
            setDisplayValue("tabbed");
            allReportData.clear();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showModal]);

    const handleProjectSelectionChanged = (event) => {
        const allOptions = event.target.options;
        for (let i = 0; i < allOptions.length; i++) {
            const currentOption = allOptions[i];
            let project = projects.getById(currentOption.value);
            project.selected = currentOption.selected;
            projects.replace(currentOption.value, project);
        }
        setHasProjectSelected(projects.value.filter(p => p.selected === true).length > 0 ? true : false);
    }

    const handleGenerateReportClicked = async (event) => {
        setReportLoading(true);
        const maturityLevel = modelContentUtils.getMaturityLevelFromView(appraisalData.view);
        const selectedProjects = projects.value.filter(p => p.selected === true);
        let reportIndex = 1;

        //INDIVIDUAL PROJECTS
        for (let i = 0; i < selectedProjects.length; i++) {
            let complianceReportData = buildEmptyComplianceChartData(selectedProjects[i].practiceAreas, maturityLevel);
            const stateRepo = new AppraisalStateRepository();
            let firstPass = true;
            const currentProject = selectedProjects[i];
            const response = await stateRepo.getAll(appraisalData.id, currentProject.id);
            if (!response.error) {
                complianceReportData = buildComplianceChartData(complianceReportData,
                    response.returnValue, firstPass);
                firstPass = false;
            }

            let p = projects.value.filter(p => p.id === currentProject.id);
            allReportData.add({
                title: currentProject.name,
                index: reportIndex,
                projects: p,
                data: complianceReportData
            });
            reportIndex++;
        }


        //CUMULATIVE (if multiple projects selected)

        //do not include rollup project
        let eligibleProjects = selectedProjects.filter(x => x.name !== "Organizational Roll-up");
        let excludingRollUp = eligibleProjects.length !== selectedProjects.length;

        if (eligibleProjects.length > 1) {

            let relevantPAs = [];
            for (let i = 0; i < eligibleProjects.length; i++) {
                for (let j = 0; j < eligibleProjects[i].practiceAreas.length; j++) {
                    let currentPA = eligibleProjects[i].practiceAreas[j];
                    if (!relevantPAs.includes(currentPA)) {
                        relevantPAs.push(currentPA);
                    }
                }
            }
            practiceAreas.setValue(relevantPAs);

            let complianceReportData = buildEmptyComplianceChartData(relevantPAs, maturityLevel);
            const stateRepo = new AppraisalStateRepository();
            let firstPass = true;
            for (let i = 0; i < eligibleProjects.length; i++) {
                const currentProject = eligibleProjects[i];
                const response = await stateRepo.getAll(appraisalData.id, currentProject.id);
                if (!response.error) {
                    complianceReportData = buildComplianceChartData(complianceReportData,
                        response.returnValue, firstPass);
                    firstPass = false;
                }
            }

            let title = excludingRollUp ? "Cumulative (Roll-up excluded)" : "Cumulative";
            allReportData.add({
                title: title,
                index: reportIndex,
                projects: eligibleProjects,
                data: complianceReportData
            });
        }

        setReportLoading(false);
        setOptionsVisible(false);
        props.setShowModal(false);
    }

    const tabFormattedReportData = allReportData.value.map(o => {
        return (
            <Tab eventKey={o.index} title={o.title} style={{ color: 'dimgray' }}>
                <Tab.Pane eventKey={o.index}>
                    <PracticeComplianceReportView
                        projects={o.projects}
                        reportData={o.data}
                        practiceRanks={PRACTICE_STATE_RANKS} 
                        includeLevel1={includeLevel1}/>
                </Tab.Pane>
            </Tab>
        )
    });

    const singlePageFormattedReportData = allReportData.value.map(o => {
        return (
            <div>
                <PracticeComplianceReportView
                    projects={o.projects}
                    reportData={o.data}
                    practiceRanks={PRACTICE_STATE_RANKS} 
                    includeLevel1={includeLevel1}/>

                <br /><br /><br />
            </div>
        )
    });

    const handleDisplayChanged = (value) => {
        setDisplayValue(value);
    }

    const levelOneCheckBoxChanged = () => {
        setIncludeLevel1(!includeLevel1);
    }

    const projectSelectionDisplayOptions = {
        title: "Projects",
        displayKey: "name",
        filterAvailable: false
    };

    const reportName = `Practice Compliance Report`;
    const scopeHeader = 'Level:';
    const appraisalHeader = 'Appraisal';

    const spacingStyle = {
        marginBottom: "10px",
        maxHeight: '5rem'
    };

    const viewReportStyle = {
        textAlign: "center",
        paddingTop: "0px",
        color: 'black'
    }

    return (
        <React.Fragment>
            <Modal show={props.showModal} onHide={(e) => props.setShowModal(false)} dialogClassName="reportModalStyle">
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '18px', color: 'dimgray' }}>
                        <img
                            src={require('../assets/icon.png')}
                            width="30"
                            height="30"
                            className="d-inline-block align-middle"
                            alt="CAP logo"
                        />{' '}Practice Compliance Report</Modal.Title>
                </Modal.Header>
                <LoadingComponent isLoading={dataLoading} iconSize={80}>
                    <ReportFragment visible={optionsVisible}>
                        <div style={{ display: "grid", gridTemplateColumns: "10% 80% 10%", gridTemplateRows: "100%", marginTop: '-50px' }}>
                            <div style={{ gridColumnStart: "2", gridRowStart: "1" }}>
                                <Form>
                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <Form.Label column md='3' style={{ color: 'white', backgroundColor: 'rgb(0,168,168)', fontSize: '16px', textAlign: 'center', padding: '.5rem' }}>
                                            Appraisal
                                        </Form.Label>
                                        <Col md={6} style={{ textAlign: 'left' }}>
                                            <Form.Control plaintext readOnly value={appraisalData.name} style={{ color: 'dimgray' }} />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="d-flex justify-content-center" >
                                        <Form.Label
                                            column sm='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                padding: '.5rem'
                                            }}>
                                            Projects
                                        </Form.Label>
                                        <Col sm={6} style={{ textAlign: 'left' }}>
                                            <MultiSelectListReportOptions listData={projects.value} style={spacingStyle}
                                                handleSelectionChanged={handleProjectSelectionChanged}
                                                displayOptions={projectSelectionDisplayOptions} />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="d-flex justify-content-center" >
                                        <Form.Label
                                            column sm='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                padding: '.5rem'
                                            }}>
                                            Display
                                        </Form.Label>
                                        <Col sm={6} style={{ textAlign: 'left' }}>
                                            <FormControl
                                                as="select"
                                                style={{ borderRightColor: 'white', borderTopColor: 'white' }}
                                                onChange={(e) => handleDisplayChanged(e.target.value)}>
                                                <option value='tabbed'>Tabbed</option>
                                                <option value='singlePage'>Single Page</option>
                                            </FormControl>
                                        </Col>
                                    </Form.Group>
                                    <Row className="d-flex justify-content-center">
                                        <Form.Group controlId="formBasicCheckbox">
                                            <Form.Check type="checkbox" label="Include Level 1 Practices"
                                                style={{ color: "dimgray" }}
                                                checked={includeLevel1}
                                                onChange={levelOneCheckBoxChanged}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row className="d-flex justify-content-center">
                                        <Button
                                            variant="outline-light"
                                            className='text-nowrap'
                                            style={{
                                                background: 'rgb(0, 168, 168)',
                                                color: 'white',
                                                border: 'none',
                                                justifyContent: 'center',
                                                padding: '.5rem',
                                                marginTop: '1rem',
                                                marginBottom: '1rem'
                                            }}
                                            disabled={!hasProjectSelected}
                                            onClick={(e) => handleGenerateReportClicked(e)}
                                        >
                                            {reportLoading &&
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size='sm'
                                                    role="status"
                                                    aria-hidden="true"
                                                />}
                                            {!reportLoading ? "Generate Practice Compliance Report" : ' Generating Report'}
                                        </Button>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </ReportFragment>

                </LoadingComponent>
            </Modal>

            {!optionsVisible &&
                <NewWindow>
                    <ReportFragment
                        reportName={reportName}
                        reportHeaderStyle={viewReportStyle}
                        scopeHeader={scopeHeader}
                        scope={appraisalData.view}
                        appraisalName={appraisalData.name}
                        appraisalHeader={appraisalHeader}>
                        {displayValue === "tabbed" ?
                            <div>
                                <br /><br />
                                <Tabs defaultActiveKey={1} id="tabControl">
                                    {tabFormattedReportData}
                                </Tabs>
                            </div>
                            :
                            <div>
                                <br /><br />
                                {singlePageFormattedReportData}
                            </div>
                        }
                    </ReportFragment>
                </NewWindow>
            }
        </React.Fragment>
    );
}

export default PracticeComplianceReportModal;