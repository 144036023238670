import React from 'react';
import { Row, Col } from 'react-bootstrap'
import { PRACTICE_EVAL_ICON_MAP } from '../utils/IconMap';
import { getCurrentDateTime } from '../utils/helpers';
import { CharacterizationsLegend } from '../utils/IconLegends';

const totalPracticesByMaturity = {
    1: 3,
    2: 11,
    3: 18,
    4: 23,
    5: 26,
}

function getFirstCellContent(indexOffset) {
    if (indexOffset <= totalPracticesByMaturity[1]) {
        return `1.${indexOffset}`;
    } else if (indexOffset <= totalPracticesByMaturity[2]) {
        return `2.${indexOffset - totalPracticesByMaturity[1]}`;
    } else if (indexOffset <= totalPracticesByMaturity[3]) {
        return `3.${indexOffset - totalPracticesByMaturity[2]}`;
    } else if (indexOffset <= totalPracticesByMaturity[4]) {
        return `4.${indexOffset - totalPracticesByMaturity[3]}`;
    } else {
        return `5.${indexOffset - totalPracticesByMaturity[4]}`;
    }
}

function getCellIcon(cellValue) {
    return PRACTICE_EVAL_ICON_MAP[cellValue];
}

function generateDataRows(reportData, cellStyle, practiceRanks, includeLevel1) {
    const keys = Object.keys(reportData);
    const yMax = reportData[keys[0]].length;
    let tableRows = [];
    for (let i = 0; i < yMax; i++) {
        let tableCells = [];
        for (let j = 0; j < keys.length; j++) {
            const cellValue = reportData[keys[j]][i];
            const cellColorStyle = {
                ...cellStyle
            };
            if (cellValue !== "No Data") {
                let newCell = (
                    <td style={cellColorStyle} title={practiceRanks[cellValue].state}>
                        {getCellIcon(cellValue)}
                    </td>
                );
                tableCells.push(newCell);
            }
            else {
                let newCell = (
                    <td style={{ border: "1px solid black", textAlign: "center", backgroundColor: "gray" }}>
                    </td>
                );
                tableCells.push(newCell);
            }
        }
        let firstCellContent = getFirstCellContent(i + 1);
        let firstCell = (
            <td style={{ ...cellStyle, fontWeight: "bold", background: 'rgb(0,168,168)' }}>{firstCellContent}</td>
        );
        tableCells.unshift(firstCell);
        const newRow = (
            <tr>
                {tableCells}
            </tr>
        );
        const levelStyle = {
            height: ".1em",
            textAlign: "right",
            fontWeight: "bold",
            paddingRight: "5px"
        };

        if (i === 0
            || i === totalPracticesByMaturity[1]
            || i === totalPracticesByMaturity[2]
            || i === totalPracticesByMaturity[3]
            || i === totalPracticesByMaturity[4]) {
            const levelRow = (<tr><td style={levelStyle}></td></tr>);
            tableRows.push(levelRow);
        }

        tableRows.push(newRow);
    }

    if (!includeLevel1) {
        tableRows.splice(0, 4);
    }

    return tableRows;
}

function getProjectSubTitle(projects) {
    let projectSubTitle = "";
    for (let i = 0; i < projects.length; i++) {
        if (i !== projects.length - 1) {
            const nextProject = `${projects[i].name}, `;
            projectSubTitle = projectSubTitle.concat(nextProject);
        } else {
            projectSubTitle = projectSubTitle.concat(projects[i].name);
        }
    }
    return projectSubTitle;
}

const PracticeComplianceReportView = ({ projects, reportData, practiceRanks, includeLevel1 }) => {
    const rowWidths = 100 / (Object.keys(reportData).length + 1);
    const cellStyle = {
        width: `${rowWidths}%`,
        border: "1px solid black",
        textAlign: "center"
    };

    const colHeaderStyle = {
        width: `${rowWidths}%`,
        border: "1px solid black",
        textAlign: "center",
        background: 'rgb(0,168,168)'
    };

    const tableHeadings = Object.keys(reportData)
        .map(k => {
            return (
                <th style={colHeaderStyle}>{k}</th>
            );
        });

    const projectSubTitle = getProjectSubTitle(projects);
    const tableRows = generateDataRows(reportData, cellStyle, practiceRanks, includeLevel1);
    let dateTime = getCurrentDateTime();
    return (
        <React.Fragment>
            <Row style={{ height: 'auto', marginBottom: '-20px' }}>
                <Col>
                    <p style={{ textAlign: 'right', fontSize: '18px' }}><b>Created for Project(s):</b></p>
                </Col>
                <Col>
                    <p style={{ textAlign: "left", fontSize: '18px' }}>{projectSubTitle}</p>
                </Col>
            </Row>
            <Row style={{ height: '20px', marginBottom: '8px' }}>
                <Col>
                    <p style={{ textAlign: "right" }}><small><b>Date/Time Generated:</b></small></p>
                </Col>
                <Col>
                    <p style={{ textAlign: "left" }}><small>{dateTime}</small></p>
                </Col>
            </Row>

            <hr class='my-2' style={{ height: '1px', borderWidth: '0', color: 'dimgray', backgroundColor: 'dimgray' }} />
            <table style={{ width: "100%", height: "80vh", border: "1px solid black", borderCollapse: "separate", borderSpacing: "3px" }}>

                <tr>
                    <th style={{ cellStyle }} />
                    {tableHeadings}
                </tr>
                {tableRows}
            </table>
            <br/>
            <Row>
                {CharacterizationsLegend}
            </Row>
        </React.Fragment>
    );
}
export default PracticeComplianceReportView;