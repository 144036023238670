import React from 'react';
import { Container, Col, Row, Figure, Form, Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';

const Styles = styled.div`

.custom-btn {
    background:rgb(0, 168, 168);
    color: white;
    border: none;
    justify-content: center; 
    padding: .5rem;  
  } 
`;

export const EditableProfile = () => (
  <Styles>
  <Container fluid>
    <Row className="justify-content-center">
      <Col className='col-md-6'>
        <Row className="d-flex justify-content-center">
          <Figure>
            <Figure.Image
              className='rounded-circle'
              width={171}
              height={180}
              src={require('./assets/avatar.png')}
            />
          </Figure>
        </Row>
        <Row className="d-flex justify-content-center"><h2>Gayli Craver</h2></Row>
        <Row className="d-flex justify-content-center">
          <Card style={{ width: '30rem' }}>
            <Card.Body>
              <Form>
                <Form.Group as={Row} controlId="formPlaintextEmail"  style={{ height: '14px' }}>
                  <Form.Label column sm="4" style={{ fontWeight: 'bold' }}>
                    Email:
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control plaintext readOnly defaultValue="gayli.craver@cepedasystems.com" />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formPlaintextPhone"  style={{ height: '14px' }}>
                  <Form.Label column sm="4" style={{ fontWeight: 'bold' }}>
                    Phone:
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control style={{ borderLeftColor: 'white', borderRightColor: 'white', borderTopColor: 'white' }} defaultValue="256-428-8186" />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formCompany" style={{ height: '14px' }}>
                  <Form.Label column sm="4" style={{ fontWeight: 'bold' }}>
                    Organization:
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control plaintext readOnly defaultValue="CSSA" />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formAppraisals" style={{ height: '14px' }}>
                  <Form.Label column sm="4" style={{ fontWeight: 'bold' }}>
                    Appraisal:
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control plaintext readOnly defaultValue="Appraisal 1" />
                  </Col>
                </Form.Group>
              </Form>                     
            </Card.Body>            
          </Card>          
        </Row>         
        <Row style={{ height: '14px' }}></Row>
        <Row className="d-flex justify-content-center" style={{ height: '14px' }}><Button className='custom-btn' href='/edit'>Update</Button></Row>   
      </Col>
    </Row>
  </Container>
  </Styles>
);
