import React from 'react';
import {Row, Col} from 'react-bootstrap'

const ReportFragment = ({visible, reportName, reportType, reportHeader, reportHeaderStyle, scopeHeader, scope, appraisalHeader, appraisalName, children}) => {  
    
    function showScope(){
        if(!scope){
            return false;
        }
        if(scope.trim().length > 0 ){
            return true;
        }
        return false;
    }

    let scopeStyle = {height:'auto', marginBottom:'-20px'}
    let scopeHiddenStyle = {height:'auto', marginBottom:'-20px', display:'none'}
  
    return (
        <React.Fragment>
            <Row style={{marginLeft:'20px', fontSize:'20px'}}><b>{reportName}</b></Row>
            <Row style={{height:'26px', marginBottom:'18px'}}> 
            <Col>
                    <p style={reportHeaderStyle}><b>{reportHeader}</b></p> 
                </Col>
                <Col >
                    <p style={{fontSize:'22px', marginTop:'16px'}}>{reportType}</p>
                </Col>
            </Row>
            <Row style={showScope() ? scopeStyle : scopeHiddenStyle}>
            <Col>
                    <p style={{textAlign:'right', fontSize:'20px'}}><b>{scopeHeader}</b></p>
                </Col>
                <Col>
                    <p style={{textAlign:"left", fontSize:'20px'}}>{scope}</p>
                </Col>
            </Row> 
            <Row style={{height:'20px', marginBottom:'8px'}}>
            <Col>
                    <p style={{textAlign:'right', fontSize:'20px'}}><b>{appraisalHeader}</b></p>
                </Col>
                <Col>
                    <p style={{textAlign:"left", fontSize:'20px'}}>{appraisalName}</p>
                </Col>
            </Row>            
            {children}
        </React.Fragment>
    );
}
export default ReportFragment;