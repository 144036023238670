import React, { useState, useRef } from 'react';
import { Container, Row, Table, Button, ButtonGroup, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { API } from 'aws-amplify';
import { Constants, getDefaultAPIHeaders } from '../utils/helpers';
import './Interviews.css';
import InterviewItem from '../presenters/InterviewItem';
import InterviewsModal from './InterviewsModal';
import ConfirmDeleteModal from '../presenters/ConfirmDeleteModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPaste, faPlus, faMinus, faEdit } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import NetworkErrorModal from '../presenters/NetworkErrorModal';

const Styles = styled.div`
.table{
    table-layout: auto;
}

.thead{
  background-color: rgb(0,168,168); 
  font-size: 16px;   
  color: white; 
}
`;

const Interviews = ({ interviews, interviewSessions, formatInterviewData, nodeInfo }) => {
    const [showNetworkErrorModal, setShowNetworkErrorModal] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [showInterviewsModal, setShowInterviewsModal] = useState(false);
    const [modalTitleFragment, setModalTitleFragment] = useState("Add");
    const selectedInterviewRef = useRef(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const DELETE_QUESTION = "Delete the selected interview session(s)?";
    const DELETE_IMPACT = "This action cannot be undone.";

    const enabledStyle = {
        backgroundColor: 'white',
        border: 'none',
        pointerEvents: 'auto'
    }

    const disabledStyle = {
        backgroundColor: 'white',
        border: 'none',
        pointerEvents: 'none'
    }


    const itemsAreSelected = () => {
        const items = interviews.value.filter(e => e.selected === true);
        return items.length > 0;
    }

    const singleItemSelected = () => {
        const items = interviews.value.filter(e => e.selected === true);
        return items.length === 1;
    }

    const handleEditClicked = async (id) => {
        const items = interviews.value.filter(e => e.selected === true);
        let interview = items[0];
        selectedInterviewRef.current = interview;

        //Getting the interview sessions that should be marked as selected based on the selected interview's sessions
        const selectedInterviewSessions = interviewSessions.value.filter(is => interview.sessions.includes(is.id))
            .map(is => {
                return {
                    ...is,
                    selected: true
                }
            });
        selectedInterviewSessions.forEach(sis => interviewSessions.replace(sis.id, sis));
        setModalTitleFragment("Edit");
        setShowInterviewsModal(true);
    }

    const updateSelected = (e, id) => {
        let item = interviews.getById(id);
        item.selected = e.target.checked;
        interviews.replace(id, item);
    };

    const handleAddClicked = () => {
        setModalTitleFragment("Add");
        setShowInterviewsModal(true);
    }

    const handleShowDeleteModal = (event) => {
        setShowDeleteModal(true);
    }

    const handleDelete = async () => {
        setShowDeleteModal(false);
        setDeleteLoading(true);
        const deletedInterviews = interviews.value.filter(i => i.selected === true);
        for (let i = 0; i < deletedInterviews.length; i++) {
            const deletedInterview = deletedInterviews[i];
            const body = {
                interview_question_id: deletedInterview.id,
                appraisal_id: nodeInfo.appraisal_id,
                project_id: nodeInfo.project_id,
                node_id: nodeInfo.node_id,
                sessions: deletedInterview.sessions,
                question: deletedInterview.question,
                revision_number: deletedInterview.revNumber
            };
            try {
                await API.del(Constants.API_PATH, Constants.INTERVIEW_QUESTIONS_PATH, await getDefaultAPIHeaders(body))
                    .catch(err => {
                        if(err.message === "Network Error"){
                            setShowNetworkErrorModal(true);
                        }
                        return;
                    });
                interviews.removeById(deletedInterview.id);
            } catch (e) {
                console.log("Delete interview question failure " + e);
            }
        }
        setDeleteLoading(false);
    }

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
    }

    const handleCopyClicked = async () => {
        const items = interviews.value.filter(e => e.selected === true).map(i => {
            return {
                ...i,
                selected: false
            };
        });
        sessionStorage.setItem('copyInterviewsBuffer', JSON.stringify(items));
    }

    const handlePasteClicked = async () => {
        let pasteItems = sessionStorage.getItem("copyInterviewsBuffer");
        if (pasteItems) {
            pasteItems = JSON.parse(pasteItems);
            for (let i = 0; i < pasteItems.length; i++) {
                let item = pasteItems[i];
                const body = {
                    project_id: nodeInfo.projectId,
                    appraisal_id: nodeInfo.appraisalId,
                    node_id: nodeInfo.nodeId,
                    interview_question_id: item.id,
                    sessions: item.sessions,
                    question: item.question
                };
                const data = await API.post(Constants.API_PATH, Constants.INTERVIEW_QUESTIONS_PATH, await getDefaultAPIHeaders(body))
                    .catch(err => {
                        if(err.message === "Network Error"){
                            setShowNetworkErrorModal(true);
                        }
                        return;
                    });
                formatInterviewData(data, interviews);
            }
        }
    }

    const wrappedInterviews = interviews.value.map(i => {
        return (
            <tr key={i.id}>
                <InterviewItem interview={i}
                    interviewSessions={interviewSessions.value}
                    updateSelected={updateSelected} />
            </tr>
        )
    });

    return (
        <Styles>
            <Container>
                <Row >
                    <Container bg="white" className="m-2">
                        <ButtonToolbar >
                        <ButtonGroup className="buttons" >
                                <OverlayTrigger rootClose overlay={<Tooltip id="tooltip-disabled1">Copy</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button
                                            variant="light"
                                            type="submit"
                                            size="sm"
                                            disabled={!itemsAreSelected()}
                                            style={itemsAreSelected() ? enabledStyle : disabledStyle}
                                            onClick={e => handleCopyClicked()}>
                                            <FontAwesomeIcon icon={faCopy} size="lg" color="rgb(0,168,168)" />
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                                <OverlayTrigger rootClose overlay={<Tooltip id="tooltip-disabled2">Paste</Tooltip>}>
                                    <Button
                                        variant="light"
                                        type="submit"
                                        size="sm"
                                        style={{ backgroundColor: 'white', border: 'none' }}
                                        onClick={e => handlePasteClicked()}>
                                        <FontAwesomeIcon icon={faPaste} size="lg" color="rgb(0,168,168)" />
                                    </Button>
                                </OverlayTrigger>
                            </ButtonGroup>
                            <ButtonGroup>
                                <OverlayTrigger rootClose overlay={<Tooltip id="tooltip-disabled3">Add Question</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button
                                            variant="light"
                                            type="submit"
                                            size="sm"
                                            style={{ backgroundColor: 'white', border: 'none' }}
                                            onClick={e => handleAddClicked()}>
                                            <FontAwesomeIcon icon={faPlus} size="lg" color="royalblue" />
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                                <OverlayTrigger rootClose overlay={<Tooltip id="tooltip-disabled4">Delete Question</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button
                                            variant="light"
                                            type="submit"
                                            size="sm"
                                            style={itemsAreSelected() ? enabledStyle : disabledStyle}
                                            onClick={e => handleShowDeleteModal()}
                                            isLoading={deleteLoading}
                                            disabled={!itemsAreSelected()}>
                                            <FontAwesomeIcon icon={faMinus} size="lg" color="rgb(0,168,168)" />
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                                <OverlayTrigger rootClose overlay={<Tooltip id="tooltip-disabled5">Edit Question</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button
                                            variant="light"
                                            type="submit"
                                            size="sm"
                                            style={itemsAreSelected() ? enabledStyle : disabledStyle}
                                            disabled={!singleItemSelected()}
                                            onClick={e => handleEditClicked()}>
                                            <FontAwesomeIcon icon={faEdit} size="lg" color="rgb(0,168,168)" />
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Container>
                </Row>
                <Row>
                    <Table size='sm' style={{ tableLayout: 'fixed' }}>
                        <div style={{ maxWidth: '825px' }}>
                            <thead className="thead">
                                <tr>
                                    <th style={{ width: '30px', textAlign: 'center' }}></th>
                                    <th style={{ width: '500px', textAlign: 'center' }}>Question</th>
                                    <th style={{ width: '250px', textAlign: 'center' }}>Session</th>
                                    <th style={{ width: '50', textAlign: 'center' }}>Duration (Hrs)</th>
                                </tr>
                            </thead>
                        </div>
                        <div style={{ maxHeight: '300px', maxWidth: '825px', overflow: 'auto' }}>
                            <tbody>
                                {wrappedInterviews}
                            </tbody>
                        </div>
                    </Table>
                </Row>
                <ConfirmDeleteModal
                    showDeleteModal={showDeleteModal}
                    close={closeDeleteModal}
                    deleteAction={handleDelete}
                    deleteQuestion={DELETE_QUESTION}
                    deleteImpact={DELETE_IMPACT}
                />
                <InterviewsModal showModal={showInterviewsModal}
                    setShowModal={setShowInterviewsModal}
                    titleFragment={modalTitleFragment}
                    interviewSessions={interviewSessions}
                    formatInterviewData={formatInterviewData}
                    interviews={interviews}
                    interview={selectedInterviewRef.current}
                    nodeInfo={nodeInfo}
                />
                <NetworkErrorModal  showNetworkErrorModal={showNetworkErrorModal} />
            </Container>
        </Styles>
    );
}

export default Interviews;