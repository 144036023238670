import React from 'react';
import { FormGroup } from 'react-bootstrap';

const listOptionStyle = {
    paddingTop:'2px',
    paddingBottom:'2px',
    paddingLeft:'3px',
    paddingRight:'3px',
    fontSize: '16px',
    color:'dimgray'
};


const MultiSelectListReportOptions = ({listData, handleSelectionChanged, displayOptions}) => {
    let data = listData;  
    
    if(displayOptions.filterAvailable){
        data = listData.filter(ld=>ld[displayOptions.availableKey]);
    }
    const listOptions = data.map(ld=>{
        return(
            <option style={listOptionStyle} key={ld.id} value={ld.id}>{ld[displayOptions.displayKey]}</option>
        )
    });

    const maxHeight = () => {
        if(displayOptions.title === 'Practice Areas'){
            return '40rem';
        }
        else if(displayOptions.title === 'CopyAppraisalPAs'){
            return '10rem';
        }
        else{
            return '20rem';
        }
    }
 
    const style = {
        width: "100%", 
        border:'none', 
        outline:'none', 
        overflow:'auto', 
        maxHeight: maxHeight()
    };

    return(
        <FormGroup controlId={displayOptions.title}>            
            <div style={{overflow: "none"}}>
                <select style={style} multiple size={listOptions.length} onChange={(e)=>handleSelectionChanged(e)} >
                    {listOptions}
                </select>
            </div>
        </FormGroup>
    );
}
export default MultiSelectListReportOptions;