import { useEffect, useRef } from 'react';

//Hook that takes a callback function and calls it every
// delay milliseconds until the component that started it is unmounted.
function useInterval(callback, delay){
    const savedCallback = useRef();

    //Remember the most recently passed in callback
    useEffect(()=>{
        savedCallback.current = callback;
    }, [callback]);

    //Setup the timer and tear it down on unmount
    useEffect(()=>{
        function tick(){
            savedCallback.current();
        }
        if(delay !== null){
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

export default useInterval;