import React, { useRef, useState } from 'react';
import { API } from 'aws-amplify';
import { Container, InputGroup, Button } from 'react-bootstrap';
import { Constants, getDefaultAPIHeaders, createMergeConflictData } from '../utils/helpers';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize'
import NetworkErrorModal from '../presenters/NetworkErrorModal';


const Styles = styled.div`
}
 .w-18 {
    width: 20%!important;     
    background-color: rgb(0,168,168)!important;   
}

.custom-btn {
    background:rgb(0, 168, 168);
    color: white;
    border: none;
    justify-content: center; 
    padding: .5rem;     
    cursor: default;
  }

 .custom-btn.disabled {
    opacity: 1;
  }
`;

const Finding = ({ finding, setFinding, nodeInfo, handleFindingUpdated, showMergeConflictModal }) => {
    const findingTextRef = useRef(finding.text);
    const [showNetworkErrorModal, setShowNetworkErrorModal] = useState(false);

    const handleFindingTextChanged = (value) => {
        setFinding(prevState => (
            {
                ...prevState,
                [finding.key]: {
                    ...prevState[finding.key],
                    text: value,
                },
                dirty: true
            }
        ));
    }

    const handlePutFindingError = async (err) => {
        console.log(err);
        if(err.message === "Network Error"){
            setShowNetworkErrorModal(true);
        }
        else{
            if (err.response.status === 412) {
                if (err.response.data.item_data !== finding.text) {
                    showMergeConflictModal(
                        createMergeConflictData(err.response.data.item_data, finding.text, err.response.data.revision_number),
                        handleMergeConflict, finding.header, true
                    );
                } else {
                    API.put(Constants.API_PATH, Constants.APPRAISAL_ITEM_PATH, await getDefaultAPIHeaders(err.response.data))
                        .then(data => {
                            findingTextRef.current = err.response.data.item_data;
                            handleFindingUpdated(data);
                        });
                }
            } else {
                handleFindingTextChanged(findingTextRef.current);
            }
        }
    }

    const handleMergeConflict = async (selection, value) => {
        if (value !== "server") {
            const body = {
                appraisal_id: nodeInfo.appraisalId,
                project_id: nodeInfo.projectId,
                node_id: nodeInfo.nodeId,
                item_type: finding.key,
                item_data: selection.text !== "" ? selection.text : null,
                revision_number: selection.revNumber
            }
            API.put(Constants.API_PATH, Constants.APPRAISAL_ITEM_PATH, await getDefaultAPIHeaders(body))
                .then(data => {
                    findingTextRef.current = finding.text;
                    handleFindingUpdated(data);
                }).catch(err => {
                    handlePutFindingError(err);
                });
        } else {
            setFinding(prevState => (
                {
                    ...prevState,
                    [finding.key]: {
                        ...prevState[finding.key],
                        text: selection.text,
                        revNumber: selection.revNumber
                    }
                }
            ));
        }
    }

    const handleBlur = async () => {
        if (findingTextRef.current !== finding.text) {
            const body = {
                appraisal_id: nodeInfo.appraisalId,
                project_id: nodeInfo.projectId,
                node_id: nodeInfo.nodeId,
                item_type: finding.key,
                item_data: finding.text !== "" ? finding.text : null,
                revision_number: finding.revNumber
            }
            API.put(Constants.API_PATH, Constants.APPRAISAL_ITEM_PATH, await getDefaultAPIHeaders(body))
                .then(data => {
                    findingTextRef.current = finding.text;
                    handleFindingUpdated(data);
                }).catch(err => {
                    handlePutFindingError(err);
                });
        }
    }

    return (
        <Styles>
            <Container>
                <InputGroup size='sm' className='mb-2' shadow style={{ flexWrap: 'nowrap' }}>
                    <InputGroup.Prepend className='w-18' style={{ minWidth: '120px' }} >
                        <Button block className='custom-btn disabled' style={{ fontSize: '16px', boxShadow: "none" }}>{finding.header}</Button>
                    </InputGroup.Prepend>
                    <TextareaAutosize 
                        style={{width: '600px', borderColor:'lightgray', borderWidth:'1px', color:'dimgray'}}
                        placeholder="Enter Text"
                        minRows="3" maxRows="20"
                        value={finding.text}
                        onChange={(e) => handleFindingTextChanged(e.target.value)}
                        onBlur={() => handleBlur()} />
                </InputGroup>
                
                <NetworkErrorModal  showNetworkErrorModal={showNetworkErrorModal} />
            </Container>
        </Styles>
        
    )
}

export default Finding;