import React, { useState, useEffect } from 'react';
import { Modal, FormCheck, FormLabel, Row, Col, Button, Spinner, FormControl } from 'react-bootstrap';
import { API } from 'aws-amplify';
import { Constants, getDefaultAPIHeaders } from '../utils/helpers';
import { DEFAULT_QUESTIONS } from '../model/modelcontent';
import { PRACTICES } from '../model/modelcontent';
import NetworkErrorModal from '../presenters/NetworkErrorModal';

const DEFAULT_QUESTION = "Default Question";
const questionErrorText = "You must enter an interview question.";
const interviewSessionErrorText = "At least one interview session must be selected.";

const InterviewsModal = ({ showModal, setShowModal, titleFragment, interviewSessions, formatInterviewData, interviews, interview, nodeInfo }) => {
    const [showNetworkErrorModal, setShowNetworkErrorModal] = useState(false);
    const [actionMade, setActionMade] = useState(false);
    const [okLoading, setOkLoading] = useState(false);
    const [questionError, setQuestionError] = useState(false);
    const [interviewSessionError, setInterviewQuestionError] = useState(false);
    const [questionText, setQuestionText] = useState('');
    const autoFocus = React.createRef();

    //Syncing up the local state when the showModal property changes
    useEffect(() => {
        if (showModal === true) {
            setActionMade(false);
            let defaultQuestion = getDefaultQuestion(nodeInfo.nodeId);
            const question = interview ? interview.question : defaultQuestion;
            setQuestionText(question);
            validateQuestionField(question);
            validateInterviewSessions();
        } else {
            const transformedSessions = interviewSessions.value.map(is => {
                return {
                    ...is,
                    selected: false
                }
            });
            interviewSessions.setValue(transformedSessions);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);

    function getDefaultQuestion(nodeId) {

        let defaultQuestion = DEFAULT_QUESTIONS[nodeId];
        if (!defaultQuestion) {
            return "";
        }
        return defaultQuestion;
    }

    function validateInterviewSessions() {
        //one interview session must be checked to proceed
        let hasSelection = false;
        interviewSessions.value.forEach(e => {
            if (e.selected) {
                hasSelection = true;
            }
        });
        setInterviewQuestionError(!hasSelection);
    }

    function validateIterviewSessionsOnChange(currentChangedID, currentChangedStatus) {
        //one interview session must be checked to proceed
        let hasSelection = false;
        //check the currently changing checkbox
        if (currentChangedStatus) {
            hasSelection = true;
        }
        //check remaining checkboxes
        interviewSessions.value.forEach(e => {
            if (e.id !== currentChangedID) {
                if (e.selected) {
                    hasSelection = true;
                }
            }
        });
        setInterviewQuestionError(!hasSelection);
    }

    function validateQuestionField(value) {
        if (value === "" || value.trim().length === 0) {
            setQuestionError(true);
        }
        else {
            setQuestionError(false);
        }
    }

    function onQuestionChanged(value) {
        setQuestionText(value);
        validateQuestionField(value);
    }

    const handleClick = async (e) => {

        if(actionMade) return;
        setActionMade(true);

        setOkLoading(true);
        const selectedSessions = interviewSessions.value.filter(is => is.selected === true);
        const selectedSessionIds = selectedSessions.map(is => {
            return is.id
        });

        if (titleFragment === "Add") {
            const body = {
                appraisal_id: nodeInfo.appraisalId,
                node_id: nodeInfo.nodeId,
                project_id: nodeInfo.projectId,
                question: questionText,
                sessions: selectedSessionIds
            }
            try {
                const data = await API.post(Constants.API_PATH, Constants.INTERVIEW_QUESTIONS_PATH, await getDefaultAPIHeaders(body))
                    .catch(err => {
                        if(err.message === "Network Error"){
                            setShowNetworkErrorModal(true);
                        }
                        return;
                    });
                formatInterviewData(data, interviews);
            }
            catch (e) {
                console.log("add interview question failure" + e);
            }
        } else {
            let body = {
                appraisal_id: nodeInfo.appraisalId,
                node_id: nodeInfo.nodeId,
                project_id: nodeInfo.projectId,
                interview_question_id: interview.id,
                question: questionText,
                sessions: selectedSessionIds,
                revision_number: interview.revNumber
            }
            try {
                const data = await API.put(Constants.API_PATH, Constants.INTERVIEW_QUESTIONS_PATH, await getDefaultAPIHeaders(body))
                    .catch(err => {
                        if(err.message === "Network Error"){
                            setShowNetworkErrorModal(true);
                        }
                        return;
                    });
                formatInterviewData(data, interviews);
            }
            catch (e) {
                if (e.response.status === 412) {
                    body = {
                        ...body,
                        revision_number: e.response.data.revision_number
                    }
                    try {
                        const data = await API.put(Constants.API_PATH, Constants.INTERVIEW_QUESTIONS_PATH, await getDefaultAPIHeaders(body))
                            .catch(err => {
                                if(err.message === "Network Error"){
                                    setShowNetworkErrorModal(true);
                                }
                                return;
                            });
                        formatInterviewData(data, interviews);
                    }
                    catch (e) {
                        console.log("Merge conflict resolution failed" + e);
                    }
                }
            }
        }
        setOkLoading(false);
        setShowModal(false);
    }

    const handleInterviewSessionSelectionChanged = (id) => {
        let interviewSession = interviewSessions.getById(id);
        interviewSession = { ...interviewSession, selected: !interviewSession.selected };
        interviewSessions.replace(id, interviewSession);

        let isid = id;
        validateIterviewSessionsOnChange(isid, interviewSession.selected);
    }

    const textAreaErrorStyle = {
        width: "100%",
        paddingLeft: '5px',
        borderBottomColor: 'red',
        borderRightColor: 'dimgray',
        borderLeftColor: 'dimgray',
        borderTopColor: 'dimgray',
        color: 'dimgray',
        marginBottom: '1rem'
    };

    const textAreaStyle = {
        width: "100%",
        paddingLeft: '5px',
        borderBottomColor: 'dimgray',
        borderRightColor: 'dimgray',
        borderLeftColor: 'dimgray',
        borderTopColor: 'dimgray',
        color: 'dimgray',
        marginBottom: '1rem'
    };

    const isCheckboxes = interviewSessions.value.map(is => {
        return (
            <FormCheck key={is.id}
                checked={is.selected}
                onChange={e => handleInterviewSessionSelectionChanged(is.id)}
                label={`${is.interviewee} - ${is.hours} hrs`}>
            </FormCheck>
        );
    })

    return (
        <div>
            <Modal size='lg' show={showModal} onHide={(e) => setShowModal(false)} animation onEntered={() => autoFocus.current.focus()}>
                <Modal.Header closeButton>
                    <Modal.Title
                        style={{ fontSize: '18px', color: 'dimgray' }}>
                        <img
                            src={require('../assets/icon.png')}
                            width="30"
                            height="30"
                            className="d-inline-block align-middle"
                            alt="CAP logo" />{titleFragment} Interview</Modal.Title>
                </Modal.Header>
                <Row className="d-flex justify-content-center">
                    <Col className='col-md-10'>
                        <Modal.Body>
                            <FormLabel style={{ fontSize: '18px', color: 'dimgray' }}>Practice Statement</FormLabel>
                            <div style={{ color: 'dimgray' }}>{PRACTICES[nodeInfo.nodeId].Statement}</div> <br></br>
                            <hr class="solid"></hr>
                            <FormLabel style={{ fontSize: '18px', color: 'dimgray' }}>Interview Question(s)</FormLabel>
                            <FormControl as='textarea' rows='4'
                                ref={autoFocus}
                                title={questionError ? questionErrorText : ""}
                                style={questionError ? textAreaErrorStyle : textAreaStyle}
                                value={questionText}
                                onChange={e => onQuestionChanged(e.target.value)}></FormControl>
                            <FormLabel style={{ fontSize: '18px', color: 'dimgray' }}>Interview Sessions</FormLabel>
                            <div
                                title={interviewSessionError ? interviewSessionErrorText : ""}
                                style={interviewSessionError ? textAreaErrorStyle : textAreaStyle}>
                                {isCheckboxes}
                            </div>
                        </Modal.Body>
                    </Col>
                </Row>
                <Modal.Footer style={{ backgroundColor: 'rgb(0,168,168)' }}>
                    <Button
                        variant="outline-light"
                        size='sm'
                        disabled={questionError || interviewSessionError}
                        onClick={(event) => handleClick()}
                    >
                        {okLoading &&
                            <Spinner
                                as="span"
                                animation="border"
                                size='sm'
                                role="status"
                                aria-hidden="true"
                            />}
                        {!okLoading ? "Save" : ' Saving'}
                    </Button>{' '}
                </Modal.Footer>
            </Modal>
            <NetworkErrorModal showNetworkErrorModal={showNetworkErrorModal} />
        </div>
    )
}
export default InterviewsModal;