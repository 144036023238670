function arrayEquals(arrayOne, arrayTwo){
    // if the other array is a falsy value, return
    if (!arrayOne)
        return false;
    if(!arrayTwo)
        return false;

    // compare lengths - can save a lot of time 
    if (arrayTwo.length !== arrayOne.length)
        return false;

    for (var i = 0, l=arrayTwo.length; i < l; i++) {
        // Check if we have nested arrays
        if (arrayTwo[i] instanceof Array && arrayOne[i] instanceof Array) {
            // recurse into the nested arrays
            if (!arrayEquals(arrayOne[i], arrayTwo[i]))
                return false;       
        }           
        else if (arrayTwo[i] !== arrayOne[i]) { 
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;   
        }           
    }       
    return true;
}

export default arrayEquals;