import React from 'react';
import LoaderButton from './LoaderButton';
import { Button, Spinner } from 'react-bootstrap';
import './WizardFooter.css';

function WizardFooter(props){
    const nextButton = props.isNextLoading ? 
        (<LoaderButton bsSize="large"
                       isLoading={props.isLoading}
                       disabled={props.modalOpen || !props.nextDisabled()}
                       text={props.actionText}
                       onClick={(e) => props.handleNextAction()}
                       loadingText={props.actionText + "ing..."}
        />):
     
        (            
            <Button
                variant="outline-light"
                size='sm'
                disabled={props.nextDisabled !== undefined ? !props.nextDisabled() : false || props.modalOpen}
                onClick={(e) => props.handleNextAction()}>
                    {props.actionText}
            </Button>
        )
    return(
        <React.Fragment>
            <Button
                variant="outline-light"
                size='sm'
                disabled={props.modalOpen}
                style={{ visibility: props.prevVisible ? 'visible' : 'hidden', float:"left" }}
                onClick={(e) => props.handleStateChange(props.currentState)}>
                Back</Button>
            { nextButton }
        </React.Fragment>
    );
}

export default WizardFooter;