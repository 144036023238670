import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { OrgRepository } from '../utils/repositories';
import { ButtonToolbar, Row, Button, Table, FormLabel, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ConfirmDeleteModal from '../presenters/ConfirmDeleteModal';
import UserItem from '../presenters/UserItem';
import LoadingComponent from '../presenters/LoadingComponent';
import useArray from '../hooks/useArray';
import { getDefaultAPIHeaders, Constants } from '../utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMinus } from '@fortawesome/free-solid-svg-icons'

const DELETE_QUESTION = "Remove access for this user?";
const DELETE_IMAPCT = "This action cannot be undone.";

async function fetchData(setOrgData, setUserData, setTableLoading) {
    setTableLoading(true);
    const orgRepo = new OrgRepository();
    const orgData = await orgRepo.getAll();
    if (!orgData.error) {
        let activeOrgs = orgData.returnValue.filter(x => x.isActive);
        activeOrgs.push({
            id: "(None)",
            name: "(None)"
        });
        setOrgData(activeOrgs);
    }
    else {
        console.log('error getting org data: ', orgData.returnValue);
    }

    try {
        const userData = await API.get(Constants.API_PATH, Constants.USER_PATH, await getDefaultAPIHeaders());
        const filteredUsers = userData;//.filter(x => x.organization_id === org);
        let activeUsers = [];
        filteredUsers.forEach(o => {
            const matchOrgItem = orgData.returnValue.find(org => org.id === o.organization_id);

            var matchOrgName = '(None)';
            if (matchOrgItem !== undefined) {
                matchOrgName = matchOrgItem.name;
            }
            if(matchOrgItem === undefined || matchOrgItem.isActive){
                activeUsers.push({
                    id: o.user_id,
                    email: o.email,
                    selectedOrg: matchOrgName,
                    markedForDelete: false
                });
            }
        });
        setUserData(activeUsers);
    } catch (e) {
        console.log('error getting user data: ', e);
    }
    setTableLoading(false);
}

function Users(props) {
    const [tableLoading, setTableLoading] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const usersArray = useArray([]);
    const orgsArray = useArray([]);

    const enabledStyle = {
        backgroundColor: 'white',
        border: 'none',
        pointerEvents: 'auto'
    }

    const disabledStyle = {
        backgroundColor: 'white',
        border: 'none',
        pointerEvents: 'none'
    }

    useEffect(() => {
        const getData = () => fetchData(orgsArray.setValue, usersArray.setValue, setTableLoading);
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.refreshTrigger]);

    const handleShowDeleteModal = (event) => {
        setShowDeleteModal(true);
    }

    const handleDelete = async () => {
        setShowDeleteModal(false);
        const itemsToRemove = usersArray.value.filter(el => el.selected === true);

        for (let i = 0; i < itemsToRemove.length; i++) {
            const itemToRemove = itemsToRemove[i];
            try {
                await API.del(Constants.API_PATH, `${Constants.USER_PATH}/${itemToRemove.id}`, await getDefaultAPIHeaders());
                usersArray.removeById(itemToRemove.id);
            }
            catch (e) {

            }
        }
    }

    const handleUpdateDelete = (event, id) => {
        const checked = event.target.checked;
        const orgItem = usersArray.getById(id);
        orgItem.markedForDelete = checked;
        usersArray.replace(id, orgItem);
    }

    const updateSelected = (e, id) => {
        let item = usersArray.getById(id);
        item.selected = e.target.checked;
        usersArray.replace(id, item);
    };

    const itemsAreSelected = () => {
        const items = usersArray.value.filter(e => e.selected === true);
        return items.length > 0;
    }

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
    }

    const handleOrgSelection = async (event) => {
        const ids = event.split('/');
        const userId = ids[0];
        let orgId = ids[1];
        let user = usersArray.getById(userId);
        const organization = orgsArray.getById(orgId);
        user = {
            ...user,
            selectedOrg: organization.name
        };
        if(orgId === "(None)"){
            orgId = "";
        }
        usersArray.replace(userId, user);
        try {
            API.put(Constants.API_PATH, `${Constants.USER_PATH}/${userId}`, await getDefaultAPIHeaders({ organization_id: orgId }));
        }
        catch (e) {

        }
    }

    const userTableItems = usersArray.value.map(u => {
        return (
            <tr key={u.id}>
                <UserItem
                    orgs={orgsArray.value}
                    user={u}
                    handleOrgSelection={handleOrgSelection}
                    updateSelected={updateSelected}
                    updateDelete={handleUpdateDelete}
                />
            </tr>
        );
    });

    return (
        <React.Fragment>
            <Container className='container-sm' >
                <Row>
                    <FormLabel
                        style={{
                            color: 'dimgray',
                            fontSize: '16px',
                            textAlign: 'left',
                            fontWeight: 'bold'
                        }}>
                        Users
                                </FormLabel>
                </Row>
                <Row>
                    <ButtonToolbar style={{ marginBottom: '4px' }}>
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete User</Tooltip>}>
                            <span className="d-inline-block">
                                <Button
                                    variant="light"
                                    type="submit"
                                    size="sm"
                                    style={itemsAreSelected() ? enabledStyle : disabledStyle}
                                    onClick={e => handleShowDeleteModal()}
                                    disabled={!itemsAreSelected()}>
                                    <FontAwesomeIcon icon={faUserMinus} size="lg" color="rgb(0,168,168)" />
                                </Button>
                            </span>
                        </OverlayTrigger>
                    </ButtonToolbar>
                </Row>
                <Row>
                    <LoadingComponent isLoading={tableLoading} iconSize={80}>
                        <Table size='sm' style={{ tableLayout: 'fixed' }} >
                            <thead className='thead'>
                                <tr>
                                    <th style={{ width: '30px', textAlign: 'center' }}></th>
                                    <th style={{ width: '260px', textAlign: 'left' }}>Org</th>
                                    <th style={{ width: '210px', textAlign: 'left' }}>User</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userTableItems}
                            </tbody>
                        </Table>
                    </LoadingComponent>
                </Row>
                <ConfirmDeleteModal
                    showDeleteModal={showDeleteModal}
                    close={closeDeleteModal}
                    deleteAction={handleDelete}
                    deleteQuestion={DELETE_QUESTION}
                    deleteImpact={DELETE_IMAPCT}
                />
            </Container>
        </React.Fragment>
    )
}
export default Users;