import React, { useState } from 'react';
import {Form, FormGroup, FormControl, FormText, Row, Col, Container, Button, Spinner } from 'react-bootstrap';
import { Image } from '../components/Image';
import { emailUtils } from '../utils/helpers';
import { Auth } from 'aws-amplify';
import UpdatePasswordForm from '../containers/UpdatePasswordForm';
import ResetPasswordModal from '../containers/ResetPasswordModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

function Login(props) {
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [updatePasswordRequired, setUpdatePasswordRequired] = useState(false);
    const [showBadPasswordModal, setShowBadPasswordModal] = useState(false);
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
    const [cognitoUser, setCognitoUser] = useState({});
    const [helpBlockState, setHelpBlockState] = useState({});

    //Sets the current session after successful login
    // before redirecting to the home page.
    const redirectToHomePage = async () => {
        const newSession = await Auth.currentSession();
        props.userHasAuthenticated(newSession);
        props.history.push("/");
    }

    //Disables the login button
    const validateField = (fieldText) => {
        //don't allow empty or only whitespace
        return fieldText.trim().length === 0 ? false : true;
    }

    const handleChangeEmail = (event) => {
        setUserName(event.target.value);
        setIsEmailValid(validateField(event.target.value));
    }

    const handleChangePassword = (event) => {
        setPassword(event.target.value);
        setIsPasswordValid(validateField(event.target.value))
    }


    //Login form submit handler
    const handleSubmit = async event => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const user = await Auth.signIn(username, password);
            setCognitoUser(user);
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setUpdatePasswordRequired(true);
            } else {
                redirectToHomePage();
            }
        } catch (e) {
            if (e.code === 'UserNotFoundException' || e.code === 'NotAuthorizedException') {
                setHelpBlockState({
                    visible: true,
                    helpText: 'Incorrect Login credentials. Please try again.'
                });
            }
            else if (e.code === 'UserLambdaValidationException') {
                const helpText = e.message.replace('PostAuthentication failed with error ', '');
                setHelpBlockState({
                    visible: true,
                    helpText: helpText.slice(0, -1)
                });
            }
            else if (e.code === 'PasswordResetRequiredException') {
                setHelpBlockState({
                    visible: true,
                    helpText: "This user has been flagged for password reset. Please use the \"Forgot Password?\" link below or contact gayli.craver@cepedasystems.com for assistance."
                });
            }
            console.log(e.code);
            console.log(e.message);
        }
        setIsLoading(false);
    }

    const handleSubmitWithEnter = async e => {
        if(!isEmailValid || !isPasswordValid){
            return;
        }
        setIsLoading(true);
        try {
            const user = await Auth.signIn(username, password);
            setCognitoUser(user);
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setUpdatePasswordRequired(true);
            } else {
                redirectToHomePage();
            }
        } catch (e) {
            if (e.code === 'UserNotFoundException' || e.code === 'NotAuthorizedException') {
                setHelpBlockState({
                    visible: true,
                    helpText: 'Your username and/or password does not match our records. Please try again.'
                });
            }
            else if (e.code === 'UserLambdaValidationException') {
                const helpText = e.message.replace('PostAuthentication failed with error ', '');
                setHelpBlockState({
                    visible: true,
                    helpText: helpText.slice(0, -1)
                });
            }
            else if (e.code === 'PasswordResetRequiredException') {
                setHelpBlockState({
                    visible: true,
                    helpText: "This user has been flagged for password reset. Please use the \"Forgot Password?\" link below or contact gayli.craver@cepedasystems.com for assistance."
                });
            }
            console.log(e.code);
            console.log(e.message);
        }
        setIsLoading(false);
    }


    //Sets the state of the login form to render
    // an update password form if it is required.
    const handleUpdatePassword = async newPassword => {
        try {
            console.log(cognitoUser);
            await Auth.completeNewPassword(
                cognitoUser,
                newPassword
            );
            redirectToHomePage();
        }
        catch (e) {
            setShowBadPasswordModal(true);
        }
    }
    const handleEnterPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            handleSubmitWithEnter();
        }
    }

    const handleForgotPassword = (e) => {
        setShowResetPasswordModal(true)
    }

    const textAreaErrorStyle = {
        border: "1px solid red",
        width: "100%",
        paddingLeft: '5px'
    };

    const textAreaStyle = {
        width: "100%",
        paddingLeft: '5px'
    };

    const renderLoginForm = () => {
        return (
            <Container>
                <Image />
                <Row className="justify-content-md-center">
                    <Form onSubmit={(event) => handleSubmit(event)}>
                        <Row>
                            <Col></Col>
                            <Col md="auto">
                                <FormGroup
                                    controlID="username"
                                    bsSize="lg">
                                    <FormControl
                                        autoFocus
                                        id='username'
                                        type="email"
                                        label="Email Address"
                                        placeholder="Email Address"
                                        onChange={handleChangeEmail}
                                        style={isEmailValid ? textAreaStyle : textAreaErrorStyle}
                                        onKeyPress={(event) => emailUtils.validateEmailCase(event)}
                                        onPaste={(event) => emailUtils.validateEmailCase(event)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row>
                            <Col></Col>
                            <Col md="auto">
                                <FormGroup
                                    controlID="password"
                                    bsSize="large">
                                    <FormControl
                                        id="password"
                                        type="password"
                                        label="Password"
                                        placeholder="Password"
                                        onChange={handleChangePassword}
                                        onKeyPress={(e)=>handleEnterPress(e)} 
                                        style={isPasswordValid ? textAreaStyle : textAreaErrorStyle}
                                    />
                                </FormGroup>
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup bsSize="small">
                                    <FormText className="text-center" style={{ visibility: helpBlockState.visible ? 'visible' : 'hidden', color:'red', fontSize:'14px', width:'500px' }}><FontAwesomeIcon icon={faExclamationTriangle} size="lg" color="red" />{helpBlockState.helpText}</FormText>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col></Col>
                            <Col md="auto">
                                <Button
                                    variant="primary"
                                    size='sm'
                                    disabled={!isEmailValid || !isPasswordValid}
                                    onClick={handleSubmit}
                                >
                                    {isLoading &&
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size='sm'
                                            role="status"
                                            aria-hidden="true"
                                        />}
                                    {!isLoading ? "Login" : ' Logging In...'}
                                </Button>{' '}
                            </Col>
                            <Col></Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col></Col>
                            <Col md="auto">
                                <Button
                                    style={{border:'none', background:'transparent', color:'blue'}}
                                    size='sm'
                                    onClick={handleForgotPassword}
                                >Forgot Password?
                                </Button>{' '}
                            </Col>
                            <Col></Col>
                        </Row>
                    </Form>
                </Row>
                
            <ResetPasswordModal showConfirmationModal={showResetPasswordModal} setShowConfirmationModal={setShowResetPasswordModal} />
            </Container>
        );
    }

    const renderUpdatePasswordForm = () => {
        return (
            <UpdatePasswordForm
                helpText='Please update your password.'
                handleUpdatePassword={handleUpdatePassword}
                showBadPasswordModal={showBadPasswordModal}
                setShowBadPasswordModal={setShowBadPasswordModal}
                oldPassword={password}
            />
        )
    }

    return (
        <div className="Login">
            {!updatePasswordRequired ? (renderLoginForm()) :
                (renderUpdatePasswordForm())}
        </div>
    );
}
export default Login;
