import { MODEL_CONSTANTS } from './modelconstants';

const VIEWS = {
    [MODEL_CONSTANTS.DEV_LEVEL_2] : {
        [MODEL_CONSTANTS.CAR]: [
            MODEL_CONSTANTS.CAR_11,
            MODEL_CONSTANTS.CAR_21,
            MODEL_CONSTANTS.CAR_22
        ],
        [MODEL_CONSTANTS.DAR]: [
            MODEL_CONSTANTS.DAR_11,
            MODEL_CONSTANTS.DAR_12,
            MODEL_CONSTANTS.DAR_21,
            MODEL_CONSTANTS.DAR_22,
            MODEL_CONSTANTS.DAR_23,
            MODEL_CONSTANTS.DAR_24,
            MODEL_CONSTANTS.DAR_25
        ],
        [MODEL_CONSTANTS.RSK]: [
            MODEL_CONSTANTS.RSK_11,
            MODEL_CONSTANTS.RSK_21,
            MODEL_CONSTANTS.RSK_22
        ],
        [MODEL_CONSTANTS.OT]: [
            MODEL_CONSTANTS.OT_11,
            MODEL_CONSTANTS.OT_21,
            MODEL_CONSTANTS.OT_22
        ],
        [MODEL_CONSTANTS.PCM]: [
            MODEL_CONSTANTS.PCM_11,
            MODEL_CONSTANTS.PCM_12,
            MODEL_CONSTANTS.PCM_13,
            MODEL_CONSTANTS.PCM_21,
            MODEL_CONSTANTS.PCM_22
        ],
        [MODEL_CONSTANTS.PAD]: [
            MODEL_CONSTANTS.PAD_11,
            MODEL_CONSTANTS.PAD_21,
            MODEL_CONSTANTS.PAD_22,
            MODEL_CONSTANTS.PAD_23
        ],
        [MODEL_CONSTANTS.PR]: [
            MODEL_CONSTANTS.PR_11,
            MODEL_CONSTANTS.PR_21,
            MODEL_CONSTANTS.PR_22,
            MODEL_CONSTANTS.PR_23,
            MODEL_CONSTANTS.PR_24
        ],
        [MODEL_CONSTANTS.VV]: [
            MODEL_CONSTANTS.VV_11,
            MODEL_CONSTANTS.VV_12,
            MODEL_CONSTANTS.VV_21,
            MODEL_CONSTANTS.VV_22,
            MODEL_CONSTANTS.VV_23
        ],
        [MODEL_CONSTANTS.TS]: [
            MODEL_CONSTANTS.TS_11,
            MODEL_CONSTANTS.TS_21,
            MODEL_CONSTANTS.TS_22,
            MODEL_CONSTANTS.TS_23
        ],
        [MODEL_CONSTANTS.PI]: [
            MODEL_CONSTANTS.PI_11,
            MODEL_CONSTANTS.PI_21,
            MODEL_CONSTANTS.PI_22,
            MODEL_CONSTANTS.PI_23,
            MODEL_CONSTANTS.PI_24,
            MODEL_CONSTANTS.PI_25,
            MODEL_CONSTANTS.PI_26
        ],
        [MODEL_CONSTANTS.MPM]: [
            MODEL_CONSTANTS.MPM_11,
            MODEL_CONSTANTS.MPM_12,
            MODEL_CONSTANTS.MPM_21,
            MODEL_CONSTANTS.MPM_22,
            MODEL_CONSTANTS.MPM_23,
            MODEL_CONSTANTS.MPM_24,
            MODEL_CONSTANTS.MPM_25,
            MODEL_CONSTANTS.MPM_26
        ],
        [MODEL_CONSTANTS.PQA]: [
            MODEL_CONSTANTS.PQA_11,
            MODEL_CONSTANTS.PQA_21,
            MODEL_CONSTANTS.PQA_22,
            MODEL_CONSTANTS.PQA_23,
            MODEL_CONSTANTS.PQA_24
        ],
        [MODEL_CONSTANTS.CM]: [
            MODEL_CONSTANTS.CM_11,
            MODEL_CONSTANTS.CM_21,
            MODEL_CONSTANTS.CM_22,
            MODEL_CONSTANTS.CM_23,
            MODEL_CONSTANTS.CM_24,
            MODEL_CONSTANTS.CM_25,
            MODEL_CONSTANTS.CM_26
        ],
        [MODEL_CONSTANTS.MC]: [
            MODEL_CONSTANTS.MC_11,
            MODEL_CONSTANTS.MC_12,
            MODEL_CONSTANTS.MC_21,
            MODEL_CONSTANTS.MC_22,
            MODEL_CONSTANTS.MC_23,
            MODEL_CONSTANTS.MC_24
        ],
        [MODEL_CONSTANTS.PLAN]: [
            MODEL_CONSTANTS.PLAN_11,
            MODEL_CONSTANTS.PLAN_12,
            MODEL_CONSTANTS.PLAN_21,
            MODEL_CONSTANTS.PLAN_22,
            MODEL_CONSTANTS.PLAN_23,
            MODEL_CONSTANTS.PLAN_24,
            MODEL_CONSTANTS.PLAN_25,
            MODEL_CONSTANTS.PLAN_26,
            MODEL_CONSTANTS.PLAN_27,
            MODEL_CONSTANTS.PLAN_28
        ],
        [MODEL_CONSTANTS.EST]: [
            MODEL_CONSTANTS.EST_11,
            MODEL_CONSTANTS.EST_21,
            MODEL_CONSTANTS.EST_22,
            MODEL_CONSTANTS.EST_23
        ],
        [MODEL_CONSTANTS.RDM]: [
            MODEL_CONSTANTS.RDM_11,
            MODEL_CONSTANTS.RDM_21,
            MODEL_CONSTANTS.RDM_22,
            MODEL_CONSTANTS.RDM_23,
            MODEL_CONSTANTS.RDM_24,
            MODEL_CONSTANTS.RDM_25
        ],
        [MODEL_CONSTANTS.GOV]: [
            MODEL_CONSTANTS.GOV_11,
            MODEL_CONSTANTS.GOV_21,
            MODEL_CONSTANTS.GOV_22,
            MODEL_CONSTANTS.GOV_23,
            MODEL_CONSTANTS.GOV_24
        ],
        [MODEL_CONSTANTS.II]: [
            MODEL_CONSTANTS.II_11,
            MODEL_CONSTANTS.II_21,
            MODEL_CONSTANTS.II_22
        ]       
    },
    [MODEL_CONSTANTS.DEV_LEVEL_3]: {
        [MODEL_CONSTANTS.CAR]: [
            MODEL_CONSTANTS.CAR_11,
            MODEL_CONSTANTS.CAR_21,
            MODEL_CONSTANTS.CAR_22,
            MODEL_CONSTANTS.CAR_31,
            MODEL_CONSTANTS.CAR_32,
            MODEL_CONSTANTS.CAR_33,
            MODEL_CONSTANTS.CAR_34,
            MODEL_CONSTANTS.CAR_35
        ],
        [MODEL_CONSTANTS.DAR]: [
            MODEL_CONSTANTS.DAR_11,
            MODEL_CONSTANTS.DAR_12,
            MODEL_CONSTANTS.DAR_21,
            MODEL_CONSTANTS.DAR_22,
            MODEL_CONSTANTS.DAR_23,
            MODEL_CONSTANTS.DAR_24,
            MODEL_CONSTANTS.DAR_25,
            MODEL_CONSTANTS.DAR_31
        ],
        [MODEL_CONSTANTS.RSK]: [
            MODEL_CONSTANTS.RSK_11,
            MODEL_CONSTANTS.RSK_21,
            MODEL_CONSTANTS.RSK_22,
            MODEL_CONSTANTS.RSK_31,
            MODEL_CONSTANTS.RSK_32,
            MODEL_CONSTANTS.RSK_33,
            MODEL_CONSTANTS.RDM_34,
            MODEL_CONSTANTS.RSK_35
        ],
        [MODEL_CONSTANTS.OT]: [
            MODEL_CONSTANTS.OT_11,
            MODEL_CONSTANTS.OT_21,
            MODEL_CONSTANTS.OT_22,
            MODEL_CONSTANTS.OT_31,
            MODEL_CONSTANTS.OT_32,
            MODEL_CONSTANTS.OT_33,
            MODEL_CONSTANTS.OT_34,
            MODEL_CONSTANTS.OT_35,
            MODEL_CONSTANTS.OT_36,
        ],
        [MODEL_CONSTANTS.PCM]: [
            MODEL_CONSTANTS.PCM_11,
            MODEL_CONSTANTS.PCM_12,
            MODEL_CONSTANTS.PCM_13,
            MODEL_CONSTANTS.PCM_21,
            MODEL_CONSTANTS.PCM_22,
            MODEL_CONSTANTS.PCM_31,
            MODEL_CONSTANTS.PCM_32,
            MODEL_CONSTANTS.PCM_33,
            MODEL_CONSTANTS.PCM_34,
            MODEL_CONSTANTS.PCM_35,
            MODEL_CONSTANTS.PCM_36
        ],
        [MODEL_CONSTANTS.PAD]: [
            MODEL_CONSTANTS.PAD_11,
            MODEL_CONSTANTS.PAD_21,
            MODEL_CONSTANTS.PAD_22,
            MODEL_CONSTANTS.PAD_23,
            MODEL_CONSTANTS.PAD_31,
            MODEL_CONSTANTS.PAD_32,
            MODEL_CONSTANTS.PAD_33,
            MODEL_CONSTANTS.PAD_34,
            MODEL_CONSTANTS.PAD_35,
            MODEL_CONSTANTS.PAD_36,
            MODEL_CONSTANTS.PAD_37
        ],
        [MODEL_CONSTANTS.PR]: [
            MODEL_CONSTANTS.PR_11,
            MODEL_CONSTANTS.PR_21,
            MODEL_CONSTANTS.PR_22,
            MODEL_CONSTANTS.PR_23,
            MODEL_CONSTANTS.PR_24,
            MODEL_CONSTANTS.PR_31
        ],
        [MODEL_CONSTANTS.VV]: [
            MODEL_CONSTANTS.VV_11,
            MODEL_CONSTANTS.VV_12,
            MODEL_CONSTANTS.VV_21,
            MODEL_CONSTANTS.VV_22,
            MODEL_CONSTANTS.VV_23,
            MODEL_CONSTANTS.VV_31,
            MODEL_CONSTANTS.VV_32
        ],
        [MODEL_CONSTANTS.TS]: [
            MODEL_CONSTANTS.TS_11,
            MODEL_CONSTANTS.TS_21,
            MODEL_CONSTANTS.TS_22,
            MODEL_CONSTANTS.TS_23,
            MODEL_CONSTANTS.TS_31,
            MODEL_CONSTANTS.TS_32,
            MODEL_CONSTANTS.TS_33,
            MODEL_CONSTANTS.TS_34,
            MODEL_CONSTANTS.TS_35,
            MODEL_CONSTANTS.TS_36,
        ],
        [MODEL_CONSTANTS.PI]: [
            MODEL_CONSTANTS.PI_11,
            MODEL_CONSTANTS.PI_21,
            MODEL_CONSTANTS.PI_22,
            MODEL_CONSTANTS.PI_23,
            MODEL_CONSTANTS.PI_24,
            MODEL_CONSTANTS.PI_25,
            MODEL_CONSTANTS.PI_26,
            MODEL_CONSTANTS.PI_31,
            MODEL_CONSTANTS.PI_32,
            MODEL_CONSTANTS.PI_33
        ],
        [MODEL_CONSTANTS.MPM]: [
            MODEL_CONSTANTS.MPM_11,
            MODEL_CONSTANTS.MPM_12,
            MODEL_CONSTANTS.MPM_21,
            MODEL_CONSTANTS.MPM_22,
            MODEL_CONSTANTS.MPM_23,
            MODEL_CONSTANTS.MPM_24,
            MODEL_CONSTANTS.MPM_25,
            MODEL_CONSTANTS.MPM_26,
            MODEL_CONSTANTS.MPM_31,
            MODEL_CONSTANTS.MPM_32,
            MODEL_CONSTANTS.MPM_33,
            MODEL_CONSTANTS.MPM_34,
            MODEL_CONSTANTS.MPM_35,
            MODEL_CONSTANTS.MPM_36
        ],
        [MODEL_CONSTANTS.PQA]: [
            MODEL_CONSTANTS.PQA_11,
            MODEL_CONSTANTS.PQA_21,
            MODEL_CONSTANTS.PQA_22,
            MODEL_CONSTANTS.PQA_23,
            MODEL_CONSTANTS.PQA_24,
            MODEL_CONSTANTS.PQA_31
        ],
        [MODEL_CONSTANTS.CM]: [
            MODEL_CONSTANTS.CM_11,
            MODEL_CONSTANTS.CM_21,
            MODEL_CONSTANTS.CM_22,
            MODEL_CONSTANTS.CM_23,
            MODEL_CONSTANTS.CM_24,
            MODEL_CONSTANTS.CM_25,
            MODEL_CONSTANTS.CM_26
        ],
        [MODEL_CONSTANTS.MC]: [
            MODEL_CONSTANTS.MC_11,
            MODEL_CONSTANTS.MC_12,
            MODEL_CONSTANTS.MC_21,
            MODEL_CONSTANTS.MC_22,
            MODEL_CONSTANTS.MC_23,
            MODEL_CONSTANTS.MC_24,
            MODEL_CONSTANTS.MC_31,
            MODEL_CONSTANTS.MC_32,
            MODEL_CONSTANTS.MC_33,
            MODEL_CONSTANTS.MC_34
        ],
        [MODEL_CONSTANTS.PLAN]: [
            MODEL_CONSTANTS.PLAN_11,
            MODEL_CONSTANTS.PLAN_12,
            MODEL_CONSTANTS.PLAN_21,
            MODEL_CONSTANTS.PLAN_22,
            MODEL_CONSTANTS.PLAN_23,
            MODEL_CONSTANTS.PLAN_24,
            MODEL_CONSTANTS.PLAN_25,
            MODEL_CONSTANTS.PLAN_26,
            MODEL_CONSTANTS.PLAN_27,
            MODEL_CONSTANTS.PLAN_28,
            MODEL_CONSTANTS.PLAN_31,
            MODEL_CONSTANTS.PLAN_32,
            MODEL_CONSTANTS.PLAN_33,
            MODEL_CONSTANTS.PLAN_34
        ],
        [MODEL_CONSTANTS.EST]: [
            MODEL_CONSTANTS.EST_11,
            MODEL_CONSTANTS.EST_21,
            MODEL_CONSTANTS.EST_22,
            MODEL_CONSTANTS.EST_23,
            MODEL_CONSTANTS.EST_31,
            MODEL_CONSTANTS.EST_32
        ],
        [MODEL_CONSTANTS.RDM]: [
            MODEL_CONSTANTS.RDM_11,
            MODEL_CONSTANTS.RDM_21,
            MODEL_CONSTANTS.RDM_22,
            MODEL_CONSTANTS.RDM_23,
            MODEL_CONSTANTS.RDM_24,
            MODEL_CONSTANTS.RDM_25,
            MODEL_CONSTANTS.RDM_26,
            MODEL_CONSTANTS.RDM_31,
            MODEL_CONSTANTS.RDM_32,
            MODEL_CONSTANTS.RDM_33,
            MODEL_CONSTANTS.RDM_34,
            MODEL_CONSTANTS.RDM_35,
            MODEL_CONSTANTS.RDM_36,
            MODEL_CONSTANTS.RDM_37
        ],
        [MODEL_CONSTANTS.GOV]: [
            MODEL_CONSTANTS.GOV_11,
            MODEL_CONSTANTS.GOV_21,
            MODEL_CONSTANTS.GOV_22,
            MODEL_CONSTANTS.GOV_23,
            MODEL_CONSTANTS.GOV_24,
            MODEL_CONSTANTS.GOV_31,
            MODEL_CONSTANTS.GOV_32
        ],
        [MODEL_CONSTANTS.II]: [
            MODEL_CONSTANTS.II_11,
            MODEL_CONSTANTS.II_21,
            MODEL_CONSTANTS.II_22,
            MODEL_CONSTANTS.II_31,
            MODEL_CONSTANTS.II_32,
            MODEL_CONSTANTS.II_33
        ]       
    },
    [MODEL_CONSTANTS.DEV_LEVEL_4]: {
        [MODEL_CONSTANTS.CAR]: [
            MODEL_CONSTANTS.CAR_11,
            MODEL_CONSTANTS.CAR_21,
            MODEL_CONSTANTS.CAR_22,
            MODEL_CONSTANTS.CAR_31,
            MODEL_CONSTANTS.CAR_32,
            MODEL_CONSTANTS.CAR_33,
            MODEL_CONSTANTS.CAR_34,
            MODEL_CONSTANTS.CAR_35,
            MODEL_CONSTANTS.CAR_41,
            MODEL_CONSTANTS.CAR_42
        ],
        [MODEL_CONSTANTS.DAR]: [
            MODEL_CONSTANTS.DAR_11,
            MODEL_CONSTANTS.DAR_12,
            MODEL_CONSTANTS.DAR_21,
            MODEL_CONSTANTS.DAR_22,
            MODEL_CONSTANTS.DAR_23,
            MODEL_CONSTANTS.DAR_24,
            MODEL_CONSTANTS.DAR_25,
            MODEL_CONSTANTS.DAR_31
        ],
        [MODEL_CONSTANTS.RSK]: [
            MODEL_CONSTANTS.RSK_11,
            MODEL_CONSTANTS.RSK_21,
            MODEL_CONSTANTS.RSK_22,
            MODEL_CONSTANTS.RSK_31,
            MODEL_CONSTANTS.RSK_32,
            MODEL_CONSTANTS.RSK_33,
            MODEL_CONSTANTS.RSK_34,
            MODEL_CONSTANTS.RSK_35
        ],
        [MODEL_CONSTANTS.OT]: [
            MODEL_CONSTANTS.OT_11,
            MODEL_CONSTANTS.OT_21,
            MODEL_CONSTANTS.OT_22,
            MODEL_CONSTANTS.OT_31,
            MODEL_CONSTANTS.OT_32,
            MODEL_CONSTANTS.OT_33,
            MODEL_CONSTANTS.OT_34,
            MODEL_CONSTANTS.OT_35,
            MODEL_CONSTANTS.OT_36,
        ],
        [MODEL_CONSTANTS.PCM]: [
            MODEL_CONSTANTS.PCM_11,
            MODEL_CONSTANTS.PCM_12,
            MODEL_CONSTANTS.PCM_13,
            MODEL_CONSTANTS.PCM_21,
            MODEL_CONSTANTS.PCM_22,
            MODEL_CONSTANTS.PCM_31,
            MODEL_CONSTANTS.PCM_32,
            MODEL_CONSTANTS.PCM_33,
            MODEL_CONSTANTS.PCM_34,
            MODEL_CONSTANTS.PCM_35,
            MODEL_CONSTANTS.PCM_36,
            MODEL_CONSTANTS.PCM_41
        ],
        [MODEL_CONSTANTS.PAD]: [
            MODEL_CONSTANTS.PAD_11,
            MODEL_CONSTANTS.PAD_21,
            MODEL_CONSTANTS.PAD_22,
            MODEL_CONSTANTS.PAD_23,
            MODEL_CONSTANTS.PAD_31,
            MODEL_CONSTANTS.PAD_32,
            MODEL_CONSTANTS.PAD_33,
            MODEL_CONSTANTS.PAD_34,
            MODEL_CONSTANTS.PAD_35,
            MODEL_CONSTANTS.PAD_36,
            MODEL_CONSTANTS.PAD_37
        ],
        [MODEL_CONSTANTS.PR]: [
            MODEL_CONSTANTS.PR_11,
            MODEL_CONSTANTS.PR_21,
            MODEL_CONSTANTS.PR_22,
            MODEL_CONSTANTS.PR_23,
            MODEL_CONSTANTS.PR_24,
            MODEL_CONSTANTS.PR_31
        ],
        [MODEL_CONSTANTS.VV]: [
            MODEL_CONSTANTS.VV_11,
            MODEL_CONSTANTS.VV_12,
            MODEL_CONSTANTS.VV_21,
            MODEL_CONSTANTS.VV_22,
            MODEL_CONSTANTS.VV_23,
            MODEL_CONSTANTS.VV_31,
            MODEL_CONSTANTS.VV_32
        ],
        [MODEL_CONSTANTS.TS]: [
            MODEL_CONSTANTS.TS_11,
            MODEL_CONSTANTS.TS_21,
            MODEL_CONSTANTS.TS_22,
            MODEL_CONSTANTS.TS_23,
            MODEL_CONSTANTS.TS_31,
            MODEL_CONSTANTS.TS_32,
            MODEL_CONSTANTS.TS_33,
            MODEL_CONSTANTS.TS_34,
            MODEL_CONSTANTS.TS_35,
            MODEL_CONSTANTS.TS_36,
        ],
        [MODEL_CONSTANTS.PI]: [
            MODEL_CONSTANTS.PI_11,
            MODEL_CONSTANTS.PI_21,
            MODEL_CONSTANTS.PI_22,
            MODEL_CONSTANTS.PI_23,
            MODEL_CONSTANTS.PI_24,
            MODEL_CONSTANTS.PI_25,
            MODEL_CONSTANTS.PI_26,
            MODEL_CONSTANTS.PI_31,
            MODEL_CONSTANTS.PI_32,
            MODEL_CONSTANTS.PI_33
        ],
        [MODEL_CONSTANTS.MPM]: [
            MODEL_CONSTANTS.MPM_11,
            MODEL_CONSTANTS.MPM_12,
            MODEL_CONSTANTS.MPM_21,
            MODEL_CONSTANTS.MPM_22,
            MODEL_CONSTANTS.MPM_23,
            MODEL_CONSTANTS.MPM_24,
            MODEL_CONSTANTS.MPM_25,
            MODEL_CONSTANTS.MPM_26,
            MODEL_CONSTANTS.MPM_31,
            MODEL_CONSTANTS.MPM_32,
            MODEL_CONSTANTS.MPM_33,
            MODEL_CONSTANTS.MPM_34,
            MODEL_CONSTANTS.MPM_35,
            MODEL_CONSTANTS.MPM_36,
            MODEL_CONSTANTS.MPM_41,
            MODEL_CONSTANTS.MPM_42,
            MODEL_CONSTANTS.MPM_43,
            MODEL_CONSTANTS.MPM_44,
            MODEL_CONSTANTS.MPM_45
        ],
        [MODEL_CONSTANTS.PQA]: [
            MODEL_CONSTANTS.PQA_11,
            MODEL_CONSTANTS.PQA_21,
            MODEL_CONSTANTS.PQA_22,
            MODEL_CONSTANTS.PQA_23,
            MODEL_CONSTANTS.PQA_24,
            MODEL_CONSTANTS.PQA_31
        ],
        [MODEL_CONSTANTS.CM]: [
            MODEL_CONSTANTS.CM_11,
            MODEL_CONSTANTS.CM_21,
            MODEL_CONSTANTS.CM_22,
            MODEL_CONSTANTS.CM_23,
            MODEL_CONSTANTS.CM_24,
            MODEL_CONSTANTS.CM_25,
            MODEL_CONSTANTS.CM_26
        ],
        [MODEL_CONSTANTS.MC]: [
            MODEL_CONSTANTS.MC_11,
            MODEL_CONSTANTS.MC_12,
            MODEL_CONSTANTS.MC_21,
            MODEL_CONSTANTS.MC_22,
            MODEL_CONSTANTS.MC_23,
            MODEL_CONSTANTS.MC_24,
            MODEL_CONSTANTS.MC_31,
            MODEL_CONSTANTS.MC_32,
            MODEL_CONSTANTS.MC_33,
            MODEL_CONSTANTS.MC_34
        ],
        [MODEL_CONSTANTS.PLAN]: [
            MODEL_CONSTANTS.PLAN_11,
            MODEL_CONSTANTS.PLAN_12,
            MODEL_CONSTANTS.PLAN_21,
            MODEL_CONSTANTS.PLAN_22,
            MODEL_CONSTANTS.PLAN_23,
            MODEL_CONSTANTS.PLAN_24,
            MODEL_CONSTANTS.PLAN_25,
            MODEL_CONSTANTS.PLAN_26,
            MODEL_CONSTANTS.PLAN_27,
            MODEL_CONSTANTS.PLAN_28,
            MODEL_CONSTANTS.PLAN_31,
            MODEL_CONSTANTS.PLAN_32,
            MODEL_CONSTANTS.PLAN_33,
            MODEL_CONSTANTS.PLAN_34,
            MODEL_CONSTANTS.PLAN_41
        ],
        [MODEL_CONSTANTS.EST]: [
            MODEL_CONSTANTS.EST_11,
            MODEL_CONSTANTS.EST_21,
            MODEL_CONSTANTS.EST_22,
            MODEL_CONSTANTS.EST_23,
            MODEL_CONSTANTS.EST_31,
            MODEL_CONSTANTS.EST_32
        ],
        [MODEL_CONSTANTS.RDM]: [
            MODEL_CONSTANTS.RDM_11,
            MODEL_CONSTANTS.RDM_21,
            MODEL_CONSTANTS.RDM_22,
            MODEL_CONSTANTS.RDM_23,
            MODEL_CONSTANTS.RDM_24,
            MODEL_CONSTANTS.RDM_25,
            MODEL_CONSTANTS.RDM_26,
            MODEL_CONSTANTS.RDM_31,
            MODEL_CONSTANTS.RDM_32,
            MODEL_CONSTANTS.RDM_33,
            MODEL_CONSTANTS.RDM_34,
            MODEL_CONSTANTS.RDM_35,
            MODEL_CONSTANTS.RDM_36,
            MODEL_CONSTANTS.RDM_37
        ],
        [MODEL_CONSTANTS.GOV]: [
            MODEL_CONSTANTS.GOV_11,
            MODEL_CONSTANTS.GOV_21,
            MODEL_CONSTANTS.GOV_22,
            MODEL_CONSTANTS.GOV_23,
            MODEL_CONSTANTS.GOV_24,
            MODEL_CONSTANTS.GOV_31,
            MODEL_CONSTANTS.GOV_32,
            MODEL_CONSTANTS.GOV_41
        ],
        [MODEL_CONSTANTS.II]: [
            MODEL_CONSTANTS.II_11,
            MODEL_CONSTANTS.II_21,
            MODEL_CONSTANTS.II_22,
            MODEL_CONSTANTS.II_31,
            MODEL_CONSTANTS.II_32,
            MODEL_CONSTANTS.II_33
        ]      
    },
    [MODEL_CONSTANTS.DEV_LEVEL_5]:{
        [MODEL_CONSTANTS.CAR]: [
            MODEL_CONSTANTS.CAR_11,
            MODEL_CONSTANTS.CAR_21,
            MODEL_CONSTANTS.CAR_22,
            MODEL_CONSTANTS.CAR_31,
            MODEL_CONSTANTS.CAR_32,
            MODEL_CONSTANTS.CAR_33,
            MODEL_CONSTANTS.CAR_34,
            MODEL_CONSTANTS.CAR_35,
            MODEL_CONSTANTS.CAR_41,
            MODEL_CONSTANTS.CAR_42,
            MODEL_CONSTANTS.CAR_51
        ],
        [MODEL_CONSTANTS.DAR]: [
            MODEL_CONSTANTS.DAR_11,
            MODEL_CONSTANTS.DAR_12,
            MODEL_CONSTANTS.DAR_21,
            MODEL_CONSTANTS.DAR_22,
            MODEL_CONSTANTS.DAR_23,
            MODEL_CONSTANTS.DAR_24,
            MODEL_CONSTANTS.DAR_25,
            MODEL_CONSTANTS.DAR_31
        ],
        [MODEL_CONSTANTS.RSK]: [
            MODEL_CONSTANTS.RSK_11,
            MODEL_CONSTANTS.RSK_21,
            MODEL_CONSTANTS.RSK_22,
            MODEL_CONSTANTS.RSK_31,
            MODEL_CONSTANTS.RSK_32,
            MODEL_CONSTANTS.RSK_33,
            MODEL_CONSTANTS.RSK_34,
            MODEL_CONSTANTS.RSK_35
        ],
        [MODEL_CONSTANTS.OT]: [
            MODEL_CONSTANTS.OT_11,
            MODEL_CONSTANTS.OT_21,
            MODEL_CONSTANTS.OT_22,
            MODEL_CONSTANTS.OT_31,
            MODEL_CONSTANTS.OT_32,
            MODEL_CONSTANTS.OT_33,
            MODEL_CONSTANTS.OT_34,
            MODEL_CONSTANTS.OT_35,
            MODEL_CONSTANTS.OT_36,
        ],
        [MODEL_CONSTANTS.PCM]: [
            MODEL_CONSTANTS.PCM_11,
            MODEL_CONSTANTS.PCM_12,
            MODEL_CONSTANTS.PCM_13,
            MODEL_CONSTANTS.PCM_21,
            MODEL_CONSTANTS.PCM_22,
            MODEL_CONSTANTS.PCM_31,
            MODEL_CONSTANTS.PCM_32,
            MODEL_CONSTANTS.PCM_33,
            MODEL_CONSTANTS.PCM_34,
            MODEL_CONSTANTS.PCM_35,
            MODEL_CONSTANTS.PCM_36,
            MODEL_CONSTANTS.PCM_41
        ],
        [MODEL_CONSTANTS.PAD]: [
            MODEL_CONSTANTS.PAD_11,
            MODEL_CONSTANTS.PAD_21,
            MODEL_CONSTANTS.PAD_22,
            MODEL_CONSTANTS.PAD_23,
            MODEL_CONSTANTS.PAD_31,
            MODEL_CONSTANTS.PAD_32,
            MODEL_CONSTANTS.PAD_33,
            MODEL_CONSTANTS.PAD_34,
            MODEL_CONSTANTS.PAD_35,
            MODEL_CONSTANTS.PAD_36,
            MODEL_CONSTANTS.PAD_37
        ],
        [MODEL_CONSTANTS.PR]: [
            MODEL_CONSTANTS.PR_11,
            MODEL_CONSTANTS.PR_21,
            MODEL_CONSTANTS.PR_22,
            MODEL_CONSTANTS.PR_23,
            MODEL_CONSTANTS.PR_24,
            MODEL_CONSTANTS.PR_31
        ],
        [MODEL_CONSTANTS.VV]: [
            MODEL_CONSTANTS.VV_11,
            MODEL_CONSTANTS.VV_12,
            MODEL_CONSTANTS.VV_21,
            MODEL_CONSTANTS.VV_22,
            MODEL_CONSTANTS.VV_23,
            MODEL_CONSTANTS.VV_31,
            MODEL_CONSTANTS.VV_32
        ],
        [MODEL_CONSTANTS.TS]: [
            MODEL_CONSTANTS.TS_11,
            MODEL_CONSTANTS.TS_21,
            MODEL_CONSTANTS.TS_22,
            MODEL_CONSTANTS.TS_23,
            MODEL_CONSTANTS.TS_31,
            MODEL_CONSTANTS.TS_32,
            MODEL_CONSTANTS.TS_33,
            MODEL_CONSTANTS.TS_34,
            MODEL_CONSTANTS.TS_35,
            MODEL_CONSTANTS.TS_36,
        ],
        [MODEL_CONSTANTS.PI]: [
            MODEL_CONSTANTS.PI_11,
            MODEL_CONSTANTS.PI_21,
            MODEL_CONSTANTS.PI_22,
            MODEL_CONSTANTS.PI_23,
            MODEL_CONSTANTS.PI_24,
            MODEL_CONSTANTS.PI_25,
            MODEL_CONSTANTS.PI_26,
            MODEL_CONSTANTS.PI_31,
            MODEL_CONSTANTS.PI_32,
            MODEL_CONSTANTS.PI_33
        ],
        [MODEL_CONSTANTS.MPM]: [
            MODEL_CONSTANTS.MPM_11,
            MODEL_CONSTANTS.MPM_12,
            MODEL_CONSTANTS.MPM_21,
            MODEL_CONSTANTS.MPM_22,
            MODEL_CONSTANTS.MPM_23,
            MODEL_CONSTANTS.MPM_24,
            MODEL_CONSTANTS.MPM_25,
            MODEL_CONSTANTS.MPM_26,
            MODEL_CONSTANTS.MPM_31,
            MODEL_CONSTANTS.MPM_32,
            MODEL_CONSTANTS.MPM_33,
            MODEL_CONSTANTS.MPM_34,
            MODEL_CONSTANTS.MPM_35,
            MODEL_CONSTANTS.MPM_36,
            MODEL_CONSTANTS.MPM_41,
            MODEL_CONSTANTS.MPM_42,
            MODEL_CONSTANTS.MPM_43,
            MODEL_CONSTANTS.MPM_44,
            MODEL_CONSTANTS.MPM_45,
            MODEL_CONSTANTS.MPM_51,
            MODEL_CONSTANTS.MPM_52,
            MODEL_CONSTANTS.MPM_53
        ],
        [MODEL_CONSTANTS.PQA]: [
            MODEL_CONSTANTS.PQA_11,
            MODEL_CONSTANTS.PQA_21,
            MODEL_CONSTANTS.PQA_22,
            MODEL_CONSTANTS.PQA_23,
            MODEL_CONSTANTS.PQA_24,
            MODEL_CONSTANTS.PQA_31
        ],
        [MODEL_CONSTANTS.CM]: [
            MODEL_CONSTANTS.CM_11,
            MODEL_CONSTANTS.CM_21,
            MODEL_CONSTANTS.CM_22,
            MODEL_CONSTANTS.CM_23,
            MODEL_CONSTANTS.CM_24,
            MODEL_CONSTANTS.CM_25,
            MODEL_CONSTANTS.CM_26
        ],
        [MODEL_CONSTANTS.MC]: [
            MODEL_CONSTANTS.MC_11,
            MODEL_CONSTANTS.MC_12,
            MODEL_CONSTANTS.MC_21,
            MODEL_CONSTANTS.MC_22,
            MODEL_CONSTANTS.MC_23,
            MODEL_CONSTANTS.MC_24,
            MODEL_CONSTANTS.MC_31,
            MODEL_CONSTANTS.MC_32,
            MODEL_CONSTANTS.MC_33,
            MODEL_CONSTANTS.MC_34
        ],
        [MODEL_CONSTANTS.PLAN]: [
            MODEL_CONSTANTS.PLAN_11,
            MODEL_CONSTANTS.PLAN_12,
            MODEL_CONSTANTS.PLAN_21,
            MODEL_CONSTANTS.PLAN_22,
            MODEL_CONSTANTS.PLAN_23,
            MODEL_CONSTANTS.PLAN_24,
            MODEL_CONSTANTS.PLAN_25,
            MODEL_CONSTANTS.PLAN_26,
            MODEL_CONSTANTS.PLAN_27,
            MODEL_CONSTANTS.PLAN_28,
            MODEL_CONSTANTS.PLAN_31,
            MODEL_CONSTANTS.PLAN_32,
            MODEL_CONSTANTS.PLAN_33,
            MODEL_CONSTANTS.PLAN_34,
            MODEL_CONSTANTS.PLAN_41
        ],
        [MODEL_CONSTANTS.EST]: [
            MODEL_CONSTANTS.EST_11,
            MODEL_CONSTANTS.EST_21,
            MODEL_CONSTANTS.EST_22,
            MODEL_CONSTANTS.EST_23,
            MODEL_CONSTANTS.EST_31,
            MODEL_CONSTANTS.EST_32
        ],
        [MODEL_CONSTANTS.RDM]: [
            MODEL_CONSTANTS.RDM_11,
            MODEL_CONSTANTS.RDM_21,
            MODEL_CONSTANTS.RDM_22,
            MODEL_CONSTANTS.RDM_23,
            MODEL_CONSTANTS.RDM_24,
            MODEL_CONSTANTS.RDM_25,
            MODEL_CONSTANTS.RDM_26,
            MODEL_CONSTANTS.RDM_31,
            MODEL_CONSTANTS.RDM_32,
            MODEL_CONSTANTS.RDM_33,
            MODEL_CONSTANTS.RDM_34,
            MODEL_CONSTANTS.RDM_35,
            MODEL_CONSTANTS.RDM_36,
            MODEL_CONSTANTS.RDM_37
        ],
        [MODEL_CONSTANTS.GOV]: [
            MODEL_CONSTANTS.GOV_11,
            MODEL_CONSTANTS.GOV_21,
            MODEL_CONSTANTS.GOV_22,
            MODEL_CONSTANTS.GOV_23,
            MODEL_CONSTANTS.GOV_24,
            MODEL_CONSTANTS.GOV_31,
            MODEL_CONSTANTS.GOV_32,
            MODEL_CONSTANTS.GOV_41
        ],
        [MODEL_CONSTANTS.II]: [
            MODEL_CONSTANTS.II_11,
            MODEL_CONSTANTS.II_21,
            MODEL_CONSTANTS.II_22,
            MODEL_CONSTANTS.II_31,
            MODEL_CONSTANTS.II_32,
            MODEL_CONSTANTS.II_33
        ]   
    },
    [MODEL_CONSTANTS.SVCS_LEVEL_2]: {
        [MODEL_CONSTANTS.CAR]: [
            MODEL_CONSTANTS.CAR_11,
            MODEL_CONSTANTS.CAR_21,
            MODEL_CONSTANTS.CAR_22
        ],
        [MODEL_CONSTANTS.DAR]: [
            MODEL_CONSTANTS.DAR_11,
            MODEL_CONSTANTS.DAR_12,
            MODEL_CONSTANTS.DAR_21,
            MODEL_CONSTANTS.DAR_22,
            MODEL_CONSTANTS.DAR_23,
            MODEL_CONSTANTS.DAR_24,
            MODEL_CONSTANTS.DAR_25
        ],
        [MODEL_CONSTANTS.RSK]: [
            MODEL_CONSTANTS.RSK_11,
            MODEL_CONSTANTS.RSK_21,
            MODEL_CONSTANTS.RSK_22
        ],
        [MODEL_CONSTANTS.OT]: [
            MODEL_CONSTANTS.OT_11,
            MODEL_CONSTANTS.OT_21,
            MODEL_CONSTANTS.OT_22
        ],
        [MODEL_CONSTANTS.PCM]: [
            MODEL_CONSTANTS.PCM_11,
            MODEL_CONSTANTS.PCM_12,
            MODEL_CONSTANTS.PCM_13,
            MODEL_CONSTANTS.PCM_21,
            MODEL_CONSTANTS.PCM_22
        ],
        [MODEL_CONSTANTS.PAD]: [
            MODEL_CONSTANTS.PAD_11,
            MODEL_CONSTANTS.PAD_21,
            MODEL_CONSTANTS.PAD_22,
            MODEL_CONSTANTS.PAD_23
        ],
        [MODEL_CONSTANTS.PR]: [
            MODEL_CONSTANTS.PR_11,
            MODEL_CONSTANTS.PR_21,
            MODEL_CONSTANTS.PR_22,
            MODEL_CONSTANTS.PR_23,
            MODEL_CONSTANTS.PR_24
        ],
        [MODEL_CONSTANTS.VV]: [
            MODEL_CONSTANTS.VV_11,
            MODEL_CONSTANTS.VV_12,
            MODEL_CONSTANTS.VV_21,
            MODEL_CONSTANTS.VV_22,
            MODEL_CONSTANTS.VV_23
        ],
        [MODEL_CONSTANTS.IRP]: [
            MODEL_CONSTANTS.IRP_11,
            MODEL_CONSTANTS.IRP_21,
            MODEL_CONSTANTS.IRP_22,
            MODEL_CONSTANTS.IRP_23
        ],
        [MODEL_CONSTANTS.CONT]: [
            MODEL_CONSTANTS.CONT_11,
            MODEL_CONSTANTS.CONT_21,
            MODEL_CONSTANTS.CONT_22,
            MODEL_CONSTANTS.CONT_23
        ],
        [MODEL_CONSTANTS.STSM]: [
            MODEL_CONSTANTS.STSM_11,
            MODEL_CONSTANTS.STSM_21,
            MODEL_CONSTANTS.STSM_22,
            MODEL_CONSTANTS.STSM_23
        ],
        [MODEL_CONSTANTS.MPM]: [
            MODEL_CONSTANTS.MPM_11,
            MODEL_CONSTANTS.MPM_12,
            MODEL_CONSTANTS.MPM_21,
            MODEL_CONSTANTS.MPM_22,
            MODEL_CONSTANTS.MPM_23,
            MODEL_CONSTANTS.MPM_24,
            MODEL_CONSTANTS.MPM_25,
            MODEL_CONSTANTS.MPM_26
        ],
        [MODEL_CONSTANTS.SDM]: [
            MODEL_CONSTANTS.SDM_11,
            MODEL_CONSTANTS.SDM_21,
            MODEL_CONSTANTS.SDM_22,
            MODEL_CONSTANTS.SDM_23,
            MODEL_CONSTANTS.SDM_24,
            MODEL_CONSTANTS.SDM_25,
            MODEL_CONSTANTS.SDM_26
        ],
        [MODEL_CONSTANTS.PQA]: [
            MODEL_CONSTANTS.PQA_11,
            MODEL_CONSTANTS.PQA_21,
            MODEL_CONSTANTS.PQA_22,
            MODEL_CONSTANTS.PQA_23,
            MODEL_CONSTANTS.PQA_24
        ],
        [MODEL_CONSTANTS.CM]: [
            MODEL_CONSTANTS.CM_11,
            MODEL_CONSTANTS.CM_21,
            MODEL_CONSTANTS.CM_22,
            MODEL_CONSTANTS.CM_23,
            MODEL_CONSTANTS.CM_24,
            MODEL_CONSTANTS.CM_25,
            MODEL_CONSTANTS.CM_26
        ],
        [MODEL_CONSTANTS.MC]: [
            MODEL_CONSTANTS.MC_11,
            MODEL_CONSTANTS.MC_12,
            MODEL_CONSTANTS.MC_21,
            MODEL_CONSTANTS.MC_22,
            MODEL_CONSTANTS.MC_23,
            MODEL_CONSTANTS.MC_24
        ],
        [MODEL_CONSTANTS.PLAN]: [
            MODEL_CONSTANTS.PLAN_11,
            MODEL_CONSTANTS.PLAN_12,
            MODEL_CONSTANTS.PLAN_21,
            MODEL_CONSTANTS.PLAN_22,
            MODEL_CONSTANTS.PLAN_23,
            MODEL_CONSTANTS.PLAN_24,
            MODEL_CONSTANTS.PLAN_25,
            MODEL_CONSTANTS.PLAN_26,
            MODEL_CONSTANTS.PLAN_27,
            MODEL_CONSTANTS.PLAN_28
        ],
        [MODEL_CONSTANTS.EST]: [
            MODEL_CONSTANTS.EST_11,
            MODEL_CONSTANTS.EST_21,
            MODEL_CONSTANTS.EST_22,
            MODEL_CONSTANTS.EST_23
        ],
        [MODEL_CONSTANTS.RDM]: [
            MODEL_CONSTANTS.RDM_11,
            MODEL_CONSTANTS.RDM_21,
            MODEL_CONSTANTS.RDM_22,
            MODEL_CONSTANTS.RDM_23,
            MODEL_CONSTANTS.RDM_24,
            MODEL_CONSTANTS.RDM_25
        ],
        [MODEL_CONSTANTS.GOV]: [
            MODEL_CONSTANTS.GOV_11,
            MODEL_CONSTANTS.GOV_21,
            MODEL_CONSTANTS.GOV_22,
            MODEL_CONSTANTS.GOV_23,
            MODEL_CONSTANTS.GOV_24
        ],
        [MODEL_CONSTANTS.II]: [
            MODEL_CONSTANTS.II_11,
            MODEL_CONSTANTS.II_21,
            MODEL_CONSTANTS.II_22
        ]          
    },
    [MODEL_CONSTANTS.SVCS_LEVEL_3]: {
        [MODEL_CONSTANTS.CAR]: [
            MODEL_CONSTANTS.CAR_11,
            MODEL_CONSTANTS.CAR_21,
            MODEL_CONSTANTS.CAR_22,
            MODEL_CONSTANTS.CAR_31,
            MODEL_CONSTANTS.CAR_32,
            MODEL_CONSTANTS.CAR_33,
            MODEL_CONSTANTS.CAR_34,
            MODEL_CONSTANTS.CAR_35
        ],
        [MODEL_CONSTANTS.DAR]: [
            MODEL_CONSTANTS.DAR_11,
            MODEL_CONSTANTS.DAR_12,
            MODEL_CONSTANTS.DAR_21,
            MODEL_CONSTANTS.DAR_22,
            MODEL_CONSTANTS.DAR_23,
            MODEL_CONSTANTS.DAR_24,
            MODEL_CONSTANTS.DAR_25,
            MODEL_CONSTANTS.DAR_31
        ],
        [MODEL_CONSTANTS.RSK]: [
            MODEL_CONSTANTS.RSK_11,
            MODEL_CONSTANTS.RSK_21,
            MODEL_CONSTANTS.RSK_22,
            MODEL_CONSTANTS.RSK_31,
            MODEL_CONSTANTS.RSK_32,
            MODEL_CONSTANTS.RSK_33,
            MODEL_CONSTANTS.RSK_34,
            MODEL_CONSTANTS.RSK_35
        ],
        [MODEL_CONSTANTS.OT]: [
            MODEL_CONSTANTS.OT_11,
            MODEL_CONSTANTS.OT_21,
            MODEL_CONSTANTS.OT_22,
            MODEL_CONSTANTS.OT_31,
            MODEL_CONSTANTS.OT_32,
            MODEL_CONSTANTS.OT_33,
            MODEL_CONSTANTS.OT_34,
            MODEL_CONSTANTS.OT_35,
            MODEL_CONSTANTS.OT_36,
        ],
        [MODEL_CONSTANTS.PCM]: [
            MODEL_CONSTANTS.PCM_11,
            MODEL_CONSTANTS.PCM_12,
            MODEL_CONSTANTS.PCM_13,
            MODEL_CONSTANTS.PCM_21,
            MODEL_CONSTANTS.PCM_22,
            MODEL_CONSTANTS.PCM_31,
            MODEL_CONSTANTS.PCM_32,
            MODEL_CONSTANTS.PCM_33,
            MODEL_CONSTANTS.PCM_34,
            MODEL_CONSTANTS.PCM_35,
            MODEL_CONSTANTS.PCM_36
        ],
        [MODEL_CONSTANTS.PAD]: [
            MODEL_CONSTANTS.PAD_11,
            MODEL_CONSTANTS.PAD_21,
            MODEL_CONSTANTS.PAD_22,
            MODEL_CONSTANTS.PAD_23,
            MODEL_CONSTANTS.PAD_31,
            MODEL_CONSTANTS.PAD_32,
            MODEL_CONSTANTS.PAD_33,
            MODEL_CONSTANTS.PAD_34,
            MODEL_CONSTANTS.PAD_35,
            MODEL_CONSTANTS.PAD_36,
            MODEL_CONSTANTS.PAD_37
        ],
        [MODEL_CONSTANTS.PR]: [
            MODEL_CONSTANTS.PR_11,
            MODEL_CONSTANTS.PR_21,
            MODEL_CONSTANTS.PR_22,
            MODEL_CONSTANTS.PR_23,
            MODEL_CONSTANTS.PR_24,
            MODEL_CONSTANTS.PR_31
        ],
        [MODEL_CONSTANTS.VV]: [
            MODEL_CONSTANTS.VV_11,
            MODEL_CONSTANTS.VV_12,
            MODEL_CONSTANTS.VV_21,
            MODEL_CONSTANTS.VV_22,
            MODEL_CONSTANTS.VV_23,
            MODEL_CONSTANTS.VV_31,
            MODEL_CONSTANTS.VV_32
        ],
        [MODEL_CONSTANTS.IRP]: [
            MODEL_CONSTANTS.IRP_11,
            MODEL_CONSTANTS.IRP_21,
            MODEL_CONSTANTS.IRP_22,
            MODEL_CONSTANTS.IRP_23,
            MODEL_CONSTANTS.IRP_31,
            MODEL_CONSTANTS.IRP_32
        ],
        [MODEL_CONSTANTS.CONT]: [
            MODEL_CONSTANTS.CONT_11,
            MODEL_CONSTANTS.CONT_21,
            MODEL_CONSTANTS.CONT_22,
            MODEL_CONSTANTS.CONT_23,
            MODEL_CONSTANTS.CONT_31,
            MODEL_CONSTANTS.CONT_32,
            MODEL_CONSTANTS.CONT_33
        ],
        [MODEL_CONSTANTS.STSM]: [
            MODEL_CONSTANTS.STSM_11,
            MODEL_CONSTANTS.STSM_21,
            MODEL_CONSTANTS.STSM_22,
            MODEL_CONSTANTS.STSM_23,
            MODEL_CONSTANTS.STSM_31
        ],
        [MODEL_CONSTANTS.MPM]: [
            MODEL_CONSTANTS.MPM_11,
            MODEL_CONSTANTS.MPM_12,
            MODEL_CONSTANTS.MPM_21,
            MODEL_CONSTANTS.MPM_22,
            MODEL_CONSTANTS.MPM_23,
            MODEL_CONSTANTS.MPM_24,
            MODEL_CONSTANTS.MPM_25,
            MODEL_CONSTANTS.MPM_26,
            MODEL_CONSTANTS.MPM_31,
            MODEL_CONSTANTS.MPM_32,
            MODEL_CONSTANTS.MPM_33,
            MODEL_CONSTANTS.MPM_34,
            MODEL_CONSTANTS.MPM_35,
            MODEL_CONSTANTS.MPM_36
        ],
        [MODEL_CONSTANTS.SDM]: [
            MODEL_CONSTANTS.SDM_11,
            MODEL_CONSTANTS.SDM_21,
            MODEL_CONSTANTS.SDM_22,
            MODEL_CONSTANTS.SDM_23,
            MODEL_CONSTANTS.SDM_24,
            MODEL_CONSTANTS.SDM_25,
            MODEL_CONSTANTS.SDM_26,
            MODEL_CONSTANTS.SDM_31
        ],
        [MODEL_CONSTANTS.PQA]: [
            MODEL_CONSTANTS.PQA_11,
            MODEL_CONSTANTS.PQA_21,
            MODEL_CONSTANTS.PQA_22,
            MODEL_CONSTANTS.PQA_23,
            MODEL_CONSTANTS.PQA_24,
            MODEL_CONSTANTS.PQA_31
        ],
        [MODEL_CONSTANTS.CM]: [
            MODEL_CONSTANTS.CM_11,
            MODEL_CONSTANTS.CM_21,
            MODEL_CONSTANTS.CM_22,
            MODEL_CONSTANTS.CM_23,
            MODEL_CONSTANTS.CM_24,
            MODEL_CONSTANTS.CM_25,
            MODEL_CONSTANTS.CM_26
        ],
        [MODEL_CONSTANTS.MC]: [
            MODEL_CONSTANTS.MC_11,
            MODEL_CONSTANTS.MC_12,
            MODEL_CONSTANTS.MC_21,
            MODEL_CONSTANTS.MC_22,
            MODEL_CONSTANTS.MC_23,
            MODEL_CONSTANTS.MC_24,
            MODEL_CONSTANTS.MC_31,
            MODEL_CONSTANTS.MC_32,
            MODEL_CONSTANTS.MC_33,
            MODEL_CONSTANTS.MC_34
        ],
        [MODEL_CONSTANTS.PLAN]: [
            MODEL_CONSTANTS.PLAN_11,
            MODEL_CONSTANTS.PLAN_12,
            MODEL_CONSTANTS.PLAN_21,
            MODEL_CONSTANTS.PLAN_22,
            MODEL_CONSTANTS.PLAN_23,
            MODEL_CONSTANTS.PLAN_24,
            MODEL_CONSTANTS.PLAN_25,
            MODEL_CONSTANTS.PLAN_26,
            MODEL_CONSTANTS.PLAN_27,
            MODEL_CONSTANTS.PLAN_28,
            MODEL_CONSTANTS.PLAN_31,
            MODEL_CONSTANTS.PLAN_32,
            MODEL_CONSTANTS.PLAN_33,
            MODEL_CONSTANTS.PLAN_34
        ],
        [MODEL_CONSTANTS.EST]: [
            MODEL_CONSTANTS.EST_11,
            MODEL_CONSTANTS.EST_21,
            MODEL_CONSTANTS.EST_22,
            MODEL_CONSTANTS.EST_23,
            MODEL_CONSTANTS.EST_31,
            MODEL_CONSTANTS.EST_32
        ],
        [MODEL_CONSTANTS.RDM]: [
            MODEL_CONSTANTS.RDM_11,
            MODEL_CONSTANTS.RDM_21,
            MODEL_CONSTANTS.RDM_22,
            MODEL_CONSTANTS.RDM_23,
            MODEL_CONSTANTS.RDM_24,
            MODEL_CONSTANTS.RDM_25,
            MODEL_CONSTANTS.RDM_26,
            MODEL_CONSTANTS.RDM_31,
            MODEL_CONSTANTS.RDM_32,
            MODEL_CONSTANTS.RDM_33,
            MODEL_CONSTANTS.RDM_34,
            MODEL_CONSTANTS.RDM_35,
            MODEL_CONSTANTS.RDM_36,
            MODEL_CONSTANTS.RDM_37
        ],
        [MODEL_CONSTANTS.GOV]: [
            MODEL_CONSTANTS.GOV_11,
            MODEL_CONSTANTS.GOV_21,
            MODEL_CONSTANTS.GOV_22,
            MODEL_CONSTANTS.GOV_23,
            MODEL_CONSTANTS.GOV_24,
            MODEL_CONSTANTS.GOV_31,
            MODEL_CONSTANTS.GOV_32
        ],
        [MODEL_CONSTANTS.II]: [
            MODEL_CONSTANTS.II_11,
            MODEL_CONSTANTS.II_21,
            MODEL_CONSTANTS.II_22,
            MODEL_CONSTANTS.II_31,
            MODEL_CONSTANTS.II_32,
            MODEL_CONSTANTS.II_33
        ]          
    },
    [MODEL_CONSTANTS.SVCS_LEVEL_4]: {
        [MODEL_CONSTANTS.CAR]: [
            MODEL_CONSTANTS.CAR_11,
            MODEL_CONSTANTS.CAR_21,
            MODEL_CONSTANTS.CAR_22,
            MODEL_CONSTANTS.CAR_31,
            MODEL_CONSTANTS.CAR_32,
            MODEL_CONSTANTS.CAR_33,
            MODEL_CONSTANTS.CAR_34,
            MODEL_CONSTANTS.CAR_35,
            MODEL_CONSTANTS.CAR_41,
            MODEL_CONSTANTS.CAR_42
        ],
        [MODEL_CONSTANTS.DAR]: [
            MODEL_CONSTANTS.DAR_11,
            MODEL_CONSTANTS.DAR_12,
            MODEL_CONSTANTS.DAR_21,
            MODEL_CONSTANTS.DAR_22,
            MODEL_CONSTANTS.DAR_23,
            MODEL_CONSTANTS.DAR_24,
            MODEL_CONSTANTS.DAR_25,
            MODEL_CONSTANTS.DAR_31
        ],
        [MODEL_CONSTANTS.RSK]: [
            MODEL_CONSTANTS.RSK_11,
            MODEL_CONSTANTS.RSK_21,
            MODEL_CONSTANTS.RSK_22,
            MODEL_CONSTANTS.RSK_31,
            MODEL_CONSTANTS.RSK_32,
            MODEL_CONSTANTS.RSK_33,
            MODEL_CONSTANTS.RSK_34,
            MODEL_CONSTANTS.RSK_35
        ],
        [MODEL_CONSTANTS.OT]: [
            MODEL_CONSTANTS.OT_11,
            MODEL_CONSTANTS.OT_21,
            MODEL_CONSTANTS.OT_22,
            MODEL_CONSTANTS.OT_31,
            MODEL_CONSTANTS.OT_32,
            MODEL_CONSTANTS.OT_33,
            MODEL_CONSTANTS.OT_34,
            MODEL_CONSTANTS.OT_35,
            MODEL_CONSTANTS.OT_36,
        ],
        [MODEL_CONSTANTS.PCM]: [
            MODEL_CONSTANTS.PCM_11,
            MODEL_CONSTANTS.PCM_12,
            MODEL_CONSTANTS.PCM_13,
            MODEL_CONSTANTS.PCM_21,
            MODEL_CONSTANTS.PCM_22,
            MODEL_CONSTANTS.PCM_31,
            MODEL_CONSTANTS.PCM_32,
            MODEL_CONSTANTS.PCM_33,
            MODEL_CONSTANTS.PCM_34,
            MODEL_CONSTANTS.PCM_35,
            MODEL_CONSTANTS.PCM_36,
            MODEL_CONSTANTS.PCM_41
        ],
        [MODEL_CONSTANTS.PAD]: [
            MODEL_CONSTANTS.PAD_11,
            MODEL_CONSTANTS.PAD_21,
            MODEL_CONSTANTS.PAD_22,
            MODEL_CONSTANTS.PAD_23,
            MODEL_CONSTANTS.PAD_31,
            MODEL_CONSTANTS.PAD_32,
            MODEL_CONSTANTS.PAD_33,
            MODEL_CONSTANTS.PAD_34,
            MODEL_CONSTANTS.PAD_35,
            MODEL_CONSTANTS.PAD_36,
            MODEL_CONSTANTS.PAD_37
        ],
        [MODEL_CONSTANTS.PR]: [
            MODEL_CONSTANTS.PR_11,
            MODEL_CONSTANTS.PR_21,
            MODEL_CONSTANTS.PR_22,
            MODEL_CONSTANTS.PR_23,
            MODEL_CONSTANTS.PR_24,
            MODEL_CONSTANTS.PR_31
        ],
        [MODEL_CONSTANTS.VV]: [
            MODEL_CONSTANTS.VV_11,
            MODEL_CONSTANTS.VV_12,
            MODEL_CONSTANTS.VV_21,
            MODEL_CONSTANTS.VV_22,
            MODEL_CONSTANTS.VV_23,
            MODEL_CONSTANTS.VV_31,
            MODEL_CONSTANTS.VV_32
        ],
        [MODEL_CONSTANTS.IRP]: [
            MODEL_CONSTANTS.IRP_11,
            MODEL_CONSTANTS.IRP_21,
            MODEL_CONSTANTS.IRP_22,
            MODEL_CONSTANTS.IRP_23,
            MODEL_CONSTANTS.IRP_31,
            MODEL_CONSTANTS.IRP_32
        ],
        [MODEL_CONSTANTS.CONT]: [
            MODEL_CONSTANTS.CONT_11,
            MODEL_CONSTANTS.CONT_21,
            MODEL_CONSTANTS.CONT_22,
            MODEL_CONSTANTS.CONT_23,
            MODEL_CONSTANTS.CONT_31,
            MODEL_CONSTANTS.CONT_32,
            MODEL_CONSTANTS.CONT_33
        ],
        [MODEL_CONSTANTS.STSM]: [
            MODEL_CONSTANTS.STSM_11,
            MODEL_CONSTANTS.STSM_21,
            MODEL_CONSTANTS.STSM_22,
            MODEL_CONSTANTS.STSM_23,
            MODEL_CONSTANTS.STSM_31
        ],
        [MODEL_CONSTANTS.MPM]: [
            MODEL_CONSTANTS.MPM_11,
            MODEL_CONSTANTS.MPM_12,
            MODEL_CONSTANTS.MPM_21,
            MODEL_CONSTANTS.MPM_22,
            MODEL_CONSTANTS.MPM_23,
            MODEL_CONSTANTS.MPM_24,
            MODEL_CONSTANTS.MPM_25,
            MODEL_CONSTANTS.MPM_26,
            MODEL_CONSTANTS.MPM_31,
            MODEL_CONSTANTS.MPM_32,
            MODEL_CONSTANTS.MPM_33,
            MODEL_CONSTANTS.MPM_34,
            MODEL_CONSTANTS.MPM_35,
            MODEL_CONSTANTS.MPM_36,
            MODEL_CONSTANTS.MPM_41,
            MODEL_CONSTANTS.MPM_42,
            MODEL_CONSTANTS.MPM_43,
            MODEL_CONSTANTS.MPM_44,
            MODEL_CONSTANTS.MPM_45
        ],
        [MODEL_CONSTANTS.SDM]: [
            MODEL_CONSTANTS.SDM_11,
            MODEL_CONSTANTS.SDM_21,
            MODEL_CONSTANTS.SDM_22,
            MODEL_CONSTANTS.SDM_23,
            MODEL_CONSTANTS.SDM_24,
            MODEL_CONSTANTS.SDM_25,
            MODEL_CONSTANTS.SDM_26,
            MODEL_CONSTANTS.SDM_31
        ],
        [MODEL_CONSTANTS.PQA]: [
            MODEL_CONSTANTS.PQA_11,
            MODEL_CONSTANTS.PQA_21,
            MODEL_CONSTANTS.PQA_22,
            MODEL_CONSTANTS.PQA_23,
            MODEL_CONSTANTS.PQA_24,
            MODEL_CONSTANTS.PQA_31
        ],
        [MODEL_CONSTANTS.CM]: [
            MODEL_CONSTANTS.CM_11,
            MODEL_CONSTANTS.CM_21,
            MODEL_CONSTANTS.CM_22,
            MODEL_CONSTANTS.CM_23,
            MODEL_CONSTANTS.CM_24,
            MODEL_CONSTANTS.CM_25,
            MODEL_CONSTANTS.CM_26
        ],
        [MODEL_CONSTANTS.MC]: [
            MODEL_CONSTANTS.MC_11,
            MODEL_CONSTANTS.MC_12,
            MODEL_CONSTANTS.MC_21,
            MODEL_CONSTANTS.MC_22,
            MODEL_CONSTANTS.MC_23,
            MODEL_CONSTANTS.MC_24,
            MODEL_CONSTANTS.MC_31,
            MODEL_CONSTANTS.MC_32,
            MODEL_CONSTANTS.MC_33,
            MODEL_CONSTANTS.MC_34
        ],
        [MODEL_CONSTANTS.PLAN]: [
            MODEL_CONSTANTS.PLAN_11,
            MODEL_CONSTANTS.PLAN_12,
            MODEL_CONSTANTS.PLAN_21,
            MODEL_CONSTANTS.PLAN_22,
            MODEL_CONSTANTS.PLAN_23,
            MODEL_CONSTANTS.PLAN_24,
            MODEL_CONSTANTS.PLAN_25,
            MODEL_CONSTANTS.PLAN_26,
            MODEL_CONSTANTS.PLAN_27,
            MODEL_CONSTANTS.PLAN_28,
            MODEL_CONSTANTS.PLAN_31,
            MODEL_CONSTANTS.PLAN_32,
            MODEL_CONSTANTS.PLAN_33,
            MODEL_CONSTANTS.PLAN_34,
            MODEL_CONSTANTS.PLAN_41
        ],
        [MODEL_CONSTANTS.EST]: [
            MODEL_CONSTANTS.EST_11,
            MODEL_CONSTANTS.EST_21,
            MODEL_CONSTANTS.EST_22,
            MODEL_CONSTANTS.EST_23,
            MODEL_CONSTANTS.EST_31,
            MODEL_CONSTANTS.EST_32
        ],
        [MODEL_CONSTANTS.RDM]: [
            MODEL_CONSTANTS.RDM_11,
            MODEL_CONSTANTS.RDM_21,
            MODEL_CONSTANTS.RDM_22,
            MODEL_CONSTANTS.RDM_23,
            MODEL_CONSTANTS.RDM_24,
            MODEL_CONSTANTS.RDM_25,
            MODEL_CONSTANTS.RDM_26,
            MODEL_CONSTANTS.RDM_31,
            MODEL_CONSTANTS.RDM_32,
            MODEL_CONSTANTS.RDM_33,
            MODEL_CONSTANTS.RDM_34,
            MODEL_CONSTANTS.RDM_35,
            MODEL_CONSTANTS.RDM_36,
            MODEL_CONSTANTS.RDM_37
        ],
        [MODEL_CONSTANTS.GOV]: [
            MODEL_CONSTANTS.GOV_11,
            MODEL_CONSTANTS.GOV_21,
            MODEL_CONSTANTS.GOV_22,
            MODEL_CONSTANTS.GOV_23,
            MODEL_CONSTANTS.GOV_24,
            MODEL_CONSTANTS.GOV_31,
            MODEL_CONSTANTS.GOV_32,
            MODEL_CONSTANTS.GOV_41
        ],
        [MODEL_CONSTANTS.II]: [
            MODEL_CONSTANTS.II_11,
            MODEL_CONSTANTS.II_21,
            MODEL_CONSTANTS.II_22,
            MODEL_CONSTANTS.II_31,
            MODEL_CONSTANTS.II_32,
            MODEL_CONSTANTS.II_33
        ]      
    },
    [MODEL_CONSTANTS.SVCS_LEVEL_5]: {
        [MODEL_CONSTANTS.CAR]: [
            MODEL_CONSTANTS.CAR_11,
            MODEL_CONSTANTS.CAR_21,
            MODEL_CONSTANTS.CAR_22,
            MODEL_CONSTANTS.CAR_31,
            MODEL_CONSTANTS.CAR_32,
            MODEL_CONSTANTS.CAR_33,
            MODEL_CONSTANTS.CAR_34,
            MODEL_CONSTANTS.CAR_35,
            MODEL_CONSTANTS.CAR_41,
            MODEL_CONSTANTS.CAR_42,
            MODEL_CONSTANTS.CAR_51
        ],
        [MODEL_CONSTANTS.DAR]: [
            MODEL_CONSTANTS.DAR_11,
            MODEL_CONSTANTS.DAR_12,
            MODEL_CONSTANTS.DAR_21,
            MODEL_CONSTANTS.DAR_22,
            MODEL_CONSTANTS.DAR_23,
            MODEL_CONSTANTS.DAR_24,
            MODEL_CONSTANTS.DAR_25,
            MODEL_CONSTANTS.DAR_31
        ],
        [MODEL_CONSTANTS.RSK]: [
            MODEL_CONSTANTS.RSK_11,
            MODEL_CONSTANTS.RSK_21,
            MODEL_CONSTANTS.RSK_22,
            MODEL_CONSTANTS.RSK_31,
            MODEL_CONSTANTS.RSK_32,
            MODEL_CONSTANTS.RSK_33,
            MODEL_CONSTANTS.RSK_34,
            MODEL_CONSTANTS.RSK_35
        ],
        [MODEL_CONSTANTS.OT]: [
            MODEL_CONSTANTS.OT_11,
            MODEL_CONSTANTS.OT_21,
            MODEL_CONSTANTS.OT_22,
            MODEL_CONSTANTS.OT_31,
            MODEL_CONSTANTS.OT_32,
            MODEL_CONSTANTS.OT_33,
            MODEL_CONSTANTS.OT_34,
            MODEL_CONSTANTS.OT_35,
            MODEL_CONSTANTS.OT_36,
        ],
        [MODEL_CONSTANTS.PCM]: [
            MODEL_CONSTANTS.PCM_11,
            MODEL_CONSTANTS.PCM_12,
            MODEL_CONSTANTS.PCM_13,
            MODEL_CONSTANTS.PCM_21,
            MODEL_CONSTANTS.PCM_22,
            MODEL_CONSTANTS.PCM_31,
            MODEL_CONSTANTS.PCM_32,
            MODEL_CONSTANTS.PCM_33,
            MODEL_CONSTANTS.PCM_34,
            MODEL_CONSTANTS.PCM_35,
            MODEL_CONSTANTS.PCM_36,
            MODEL_CONSTANTS.PCM_41
        ],
        [MODEL_CONSTANTS.PAD]: [
            MODEL_CONSTANTS.PAD_11,
            MODEL_CONSTANTS.PAD_21,
            MODEL_CONSTANTS.PAD_22,
            MODEL_CONSTANTS.PAD_23,
            MODEL_CONSTANTS.PAD_31,
            MODEL_CONSTANTS.PAD_32,
            MODEL_CONSTANTS.PAD_33,
            MODEL_CONSTANTS.PAD_34,
            MODEL_CONSTANTS.PAD_35,
            MODEL_CONSTANTS.PAD_36,
            MODEL_CONSTANTS.PAD_37
        ],
        [MODEL_CONSTANTS.PR]: [
            MODEL_CONSTANTS.PR_11,
            MODEL_CONSTANTS.PR_21,
            MODEL_CONSTANTS.PR_22,
            MODEL_CONSTANTS.PR_23,
            MODEL_CONSTANTS.PR_24,
            MODEL_CONSTANTS.PR_31
        ],
        [MODEL_CONSTANTS.VV]: [
            MODEL_CONSTANTS.VV_11,
            MODEL_CONSTANTS.VV_12,
            MODEL_CONSTANTS.VV_21,
            MODEL_CONSTANTS.VV_22,
            MODEL_CONSTANTS.VV_23,
            MODEL_CONSTANTS.VV_31,
            MODEL_CONSTANTS.VV_32
        ],
        [MODEL_CONSTANTS.IRP]: [
            MODEL_CONSTANTS.IRP_11,
            MODEL_CONSTANTS.IRP_21,
            MODEL_CONSTANTS.IRP_22,
            MODEL_CONSTANTS.IRP_23,
            MODEL_CONSTANTS.IRP_31,
            MODEL_CONSTANTS.IRP_32
        ],
        [MODEL_CONSTANTS.CONT]: [
            MODEL_CONSTANTS.CONT_11,
            MODEL_CONSTANTS.CONT_21,
            MODEL_CONSTANTS.CONT_22,
            MODEL_CONSTANTS.CONT_23,
            MODEL_CONSTANTS.CONT_31,
            MODEL_CONSTANTS.CONT_32,
            MODEL_CONSTANTS.CONT_33
        ],
        [MODEL_CONSTANTS.STSM]: [
            MODEL_CONSTANTS.STSM_11,
            MODEL_CONSTANTS.STSM_21,
            MODEL_CONSTANTS.STSM_22,
            MODEL_CONSTANTS.STSM_23,
            MODEL_CONSTANTS.STSM_31
        ],
        [MODEL_CONSTANTS.MPM]: [
            MODEL_CONSTANTS.MPM_11,
            MODEL_CONSTANTS.MPM_12,
            MODEL_CONSTANTS.MPM_21,
            MODEL_CONSTANTS.MPM_22,
            MODEL_CONSTANTS.MPM_23,
            MODEL_CONSTANTS.MPM_24,
            MODEL_CONSTANTS.MPM_25,
            MODEL_CONSTANTS.MPM_26,
            MODEL_CONSTANTS.MPM_31,
            MODEL_CONSTANTS.MPM_32,
            MODEL_CONSTANTS.MPM_33,
            MODEL_CONSTANTS.MPM_34,
            MODEL_CONSTANTS.MPM_35,
            MODEL_CONSTANTS.MPM_36,
            MODEL_CONSTANTS.MPM_41,
            MODEL_CONSTANTS.MPM_42,
            MODEL_CONSTANTS.MPM_43,
            MODEL_CONSTANTS.MPM_44,
            MODEL_CONSTANTS.MPM_45,
            MODEL_CONSTANTS.MPM_51,
            MODEL_CONSTANTS.MPM_52,
            MODEL_CONSTANTS.MPM_53
        ],
        [MODEL_CONSTANTS.SDM]: [
            MODEL_CONSTANTS.SDM_11,
            MODEL_CONSTANTS.SDM_21,
            MODEL_CONSTANTS.SDM_22,
            MODEL_CONSTANTS.SDM_23,
            MODEL_CONSTANTS.SDM_24,
            MODEL_CONSTANTS.SDM_25,
            MODEL_CONSTANTS.SDM_26,
            MODEL_CONSTANTS.SDM_31
        ],
        [MODEL_CONSTANTS.PQA]: [
            MODEL_CONSTANTS.PQA_11,
            MODEL_CONSTANTS.PQA_21,
            MODEL_CONSTANTS.PQA_22,
            MODEL_CONSTANTS.PQA_23,
            MODEL_CONSTANTS.PQA_24,
            MODEL_CONSTANTS.PQA_31
        ],
        [MODEL_CONSTANTS.CM]: [
            MODEL_CONSTANTS.CM_11,
            MODEL_CONSTANTS.CM_21,
            MODEL_CONSTANTS.CM_22,
            MODEL_CONSTANTS.CM_23,
            MODEL_CONSTANTS.CM_24,
            MODEL_CONSTANTS.CM_25,
            MODEL_CONSTANTS.CM_26
        ],
        [MODEL_CONSTANTS.MC]: [
            MODEL_CONSTANTS.MC_11,
            MODEL_CONSTANTS.MC_12,
            MODEL_CONSTANTS.MC_21,
            MODEL_CONSTANTS.MC_22,
            MODEL_CONSTANTS.MC_23,
            MODEL_CONSTANTS.MC_24,
            MODEL_CONSTANTS.MC_31,
            MODEL_CONSTANTS.MC_32,
            MODEL_CONSTANTS.MC_33,
            MODEL_CONSTANTS.MC_34
        ],
        [MODEL_CONSTANTS.PLAN]: [
            MODEL_CONSTANTS.PLAN_11,
            MODEL_CONSTANTS.PLAN_12,
            MODEL_CONSTANTS.PLAN_21,
            MODEL_CONSTANTS.PLAN_22,
            MODEL_CONSTANTS.PLAN_23,
            MODEL_CONSTANTS.PLAN_24,
            MODEL_CONSTANTS.PLAN_25,
            MODEL_CONSTANTS.PLAN_26,
            MODEL_CONSTANTS.PLAN_27,
            MODEL_CONSTANTS.PLAN_28,
            MODEL_CONSTANTS.PLAN_31,
            MODEL_CONSTANTS.PLAN_32,
            MODEL_CONSTANTS.PLAN_33,
            MODEL_CONSTANTS.PLAN_34,
            MODEL_CONSTANTS.PLAN_41
        ],
        [MODEL_CONSTANTS.EST]: [
            MODEL_CONSTANTS.EST_11,
            MODEL_CONSTANTS.EST_21,
            MODEL_CONSTANTS.EST_22,
            MODEL_CONSTANTS.EST_23,
            MODEL_CONSTANTS.EST_31,
            MODEL_CONSTANTS.EST_32
        ],
        [MODEL_CONSTANTS.RDM]: [
            MODEL_CONSTANTS.RDM_11,
            MODEL_CONSTANTS.RDM_21,
            MODEL_CONSTANTS.RDM_22,
            MODEL_CONSTANTS.RDM_23,
            MODEL_CONSTANTS.RDM_24,
            MODEL_CONSTANTS.RDM_25,
            MODEL_CONSTANTS.RDM_26,
            MODEL_CONSTANTS.RDM_31,
            MODEL_CONSTANTS.RDM_32,
            MODEL_CONSTANTS.RDM_33,
            MODEL_CONSTANTS.RDM_34,
            MODEL_CONSTANTS.RDM_35,
            MODEL_CONSTANTS.RDM_36,
            MODEL_CONSTANTS.RDM_37
        ],
        [MODEL_CONSTANTS.GOV]: [
            MODEL_CONSTANTS.GOV_11,
            MODEL_CONSTANTS.GOV_21,
            MODEL_CONSTANTS.GOV_22,
            MODEL_CONSTANTS.GOV_23,
            MODEL_CONSTANTS.GOV_24,
            MODEL_CONSTANTS.GOV_31,
            MODEL_CONSTANTS.GOV_32,
            MODEL_CONSTANTS.GOV_41
        ],
        [MODEL_CONSTANTS.II]: [
            MODEL_CONSTANTS.II_11,
            MODEL_CONSTANTS.II_21,
            MODEL_CONSTANTS.II_22,
            MODEL_CONSTANTS.II_31,
            MODEL_CONSTANTS.II_32,
            MODEL_CONSTANTS.II_33
        ]   
    }
};

export { VIEWS };