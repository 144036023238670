import React from 'react';
import { Dropdown, ButtonGroup } from 'react-bootstrap';


function UserTableItem(props){    
    const menuItems = props.orgs.map(o=>{
    return(
        <Dropdown.Item 
            style={{color:'dimgray', fontSize:'16px'}}
            key={`${props.user.id}/${o.id}`}
            eventKey={`${props.user.id}/${o.id}`}
            onSelect={(e)=>props.handleOrgSelection(e)}>{o.name}
        </Dropdown.Item>
    )
    });

    const chkBoxId = `${props.user.id}_chkBox`;
    const labelId = `${props.user.id}_label`;
    const dropDownId = `${props.user.id}_dd`;
    return (
        <React.Fragment>
            <td style={{ width: '30px', textAlign: 'center' }} key={chkBoxId}>
                <input type="checkbox" onChange={(e)=>props.updateSelected(e, props.user.id)}
                    checked={props.user.selected}/>
            </td>
            <td style={{ width: '260px', textAlign: 'left', color:'dimgray'}} key={labelId}>
                <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle id={dropDownId}                    
                        title={props.user.selectedOrg}                   
                        variant='light'                 
                        size='sm'
                        style={{color:'dimgray'}}>
                            {props.user.selectedOrg} 
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {menuItems}
                    </Dropdown.Menu>
                </Dropdown>{' '}
            </td> 
            <td style={{ width: '210px', textAlign: 'left', color:'dimgray' }} key={labelId}>
                <label>{props.user.email}</label>
            </td>   
        </React.Fragment>
    )
}
export default UserTableItem;