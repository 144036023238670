import React, { useState } from 'react';
import { Form, FormGroup, FormControl, FormText, Row, Col, Container, Button, Spinner, Modal } from 'react-bootstrap';
import { Image } from '../components/Image';
import { Auth } from 'aws-amplify';

const allowedSpecialCharacters = "^ $ * . [ ] { } ( ) ? \" ! @ # % & / \\ , > < ' : ; | _ ~ ` - + =";
const PASSWORD_HELP_TEXT = () => (
    <div>
        <p><b>Password Rules:</b></p>
        <ul style={{ listStyleType: 'circle' }}>
            <li>Must be at least eight characters in length.</li>
            <li>Cannot contain spaces.</li>
            <li>Must contain one uppercase character.</li>
            <li>Must contain one lowercase character.</li>
            <li>Must contain one number.</li>
            <li>Must contain one special character.</li>
            <li>Allowed special characters: {allowedSpecialCharacters}</li>
        </ul>
    </div>
);

//8 characters, 1 Uppercase, 1 Lowercase, 1 number, 1 special character
const PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]\\{}()?"!@#%&/,><':;|_\+\-\=~`])\S{8,99}$/;
const PASSWORDS_SAME_HELP_TEXT = "The new password must be different from the current password";

//Form for updating password
function ChangePasswordForm(props) {
    const [existingPassword, setExistingPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isExistingPasswordValid, setIsExistingPasswordValid] = useState(false);
    const [isConfirmedPasswordValid, setIsConfirmedPasswordValid] = useState(false);
    const [helpBlockText, setHelpBlockText] = useState(PASSWORD_HELP_TEXT);
    const [infoBlockText, setInfoBlockText] = useState("");
    const [submitError, setSubmitError] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const validateField = (fieldText) => {
        return fieldText.match(PASSWORD_PATTERN) ? true : false;
    }

    const handleChangeExistingPassword = (event) => {
        setExistingPassword(event.target.value);
        setIsExistingPasswordValid(validateField(event.target.value));
    }

    const handleChangePassword = (event) => {
        setPassword(event.target.value);
        setIsPasswordValid(validateField(event.target.value));
    }

    const handleChangeConfirmedPassword = (event) => {
        setConfirmPassword(event.target.value);
        setIsConfirmedPasswordValid(validateField(event.target.value));
    }

    const handleUpdatePassword = async newPassword => {
        try {
            let user = await Auth.currentAuthenticatedUser();
            await Auth.changePassword(user, existingPassword, newPassword);
            setShowSuccessModal(true);
        }
        catch (e) {
            setSubmitError(true);
            setHelpBlockText(e.message);
        }
    }

    //form submit event handler
    const handleSubmit = async event => {
        event.preventDefault();
        if (password !== existingPassword) {
            setSubmitError(false);
            setHelpBlockText(PASSWORD_HELP_TEXT);
            setIsLoading(true);
            handleUpdatePassword(password);
            setIsLoading(false);
        } else {
            setInfoBlockText(PASSWORDS_SAME_HELP_TEXT)
        }
    }

    const handleSubmitWithEnter = async e => {
        if (!isConfirmedPasswordValid || !isPasswordValid || password !== confirmPassword) {
            return;
        }
        if (password !== existingPassword) {
            setSubmitError(false);
            setHelpBlockText(PASSWORD_HELP_TEXT);
            setIsLoading(true);
            handleUpdatePassword(password);
            setIsLoading(false);
        } else {
            setInfoBlockText(PASSWORDS_SAME_HELP_TEXT)
        }
    }

    const handleEnterPress = (e) => {
        var code = e.keyCode || e.which;
        if (code === 13) {
            handleSubmitWithEnter();
        }
    }

    const onAcknowledge = () => {
        setShowSuccessModal(false);
        props.history.push("/profile");
    }

    const textAreaErrorStyle = {
        border: "1px solid red",
        width: "100%",
        paddingLeft: '5px'
    };

    const textAreaStyle = {
        width: "100%",
        paddingLeft: '5px'
    };

    const helpTextStyle = {
        fontSize: 12
    }
    const helpTextErrorStyle = {
        color: "red",
        fontSize: 14
    }

    return (
        <Container>
            <Image />
            <Row className="justify-content-md-center">
                <Form onSubmit={(event) => handleSubmit(event)}>
                    <Row>
                        <Col></Col>
                        <Col md="auto">
                            <FormGroup
                                controlID="password"
                                bsSize="large">
                                <FormControl
                                    id="password"
                                    type="password"
                                    label="Password"
                                    placeholder="Existing Password"
                                    onChange={handleChangeExistingPassword}
                                    style={isExistingPasswordValid ? textAreaStyle : textAreaErrorStyle}
                                />
                            </FormGroup>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md="auto">
                            <FormGroup
                                controlID="password"
                                bsSize="large">
                                <FormControl
                                    id="password"
                                    type="password"
                                    label="Password"
                                    placeholder="New Password"
                                    onChange={handleChangePassword}
                                    style={isPasswordValid ? textAreaStyle : textAreaErrorStyle}
                                />
                            </FormGroup>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md="auto">
                            <FormGroup
                                controlID="password"
                                bsSize="large">
                                <FormControl
                                    id="password"
                                    type="password"
                                    label="Password"
                                    placeholder="Confirm Password"
                                    onChange={handleChangeConfirmedPassword}
                                    onKeyPress={(e) => handleEnterPress(e)}
                                    style={isConfirmedPasswordValid ? textAreaStyle : textAreaErrorStyle}
                                />
                            </FormGroup>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md="auto">
                            <FormGroup bsSize="small">
                                <FormText style={{color:'red'}}>{infoBlockText}</FormText>
                                <FormText style={submitError ? helpTextErrorStyle : helpTextStyle}>{helpBlockText}</FormText>
                            </FormGroup>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col md="auto">
                            <Button
                                variant="primary"
                                size='sm'
                                disabled={!isConfirmedPasswordValid || !isPasswordValid || password !== confirmPassword}
                                onClick={handleSubmit} >
                                {isLoading &&
                                    <Spinner
                                        as="span"
                                        size='sm'
                                        animation="border"
                                        role="status"
                                        aria-hidden="true"
                                    />}
                                {!isLoading ? "Submit" : ' Submitting....'}
                            </Button>
                        </Col>
                        <Col></Col>
                    </Row>
                </Form>
            </Row>
            <Modal bsSize="small"
                show={showSuccessModal}
                onHide={(e) => setShowSuccessModal(false)}
                animation
                style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
                size='sm'>
                <Modal.Header>
                    <Modal.Title className='w-100 text-center' style={{ fontSize: '16px', color: 'dimgray' }}>Password changed successfully.</Modal.Title>
                </Modal.Header>
                <Modal.Footer style={{ backgroundColor: 'rgb(0,168,168)' }}>
                    <Button
                        variant="outline-light"
                        size='sm'
                        onClick={(event) => onAcknowledge()}
                    >Ok</Button>
                </Modal.Footer>
            </Modal>
        </Container>

    );
}

export default ChangePasswordForm;