import React, { useState, useEffect } from 'react';
import { Modal, FormGroup, Button, Spinner, FormControl, Row, Col } from 'react-bootstrap';
import { API } from 'aws-amplify';
import { Constants, getDefaultAPIHeaders } from '../utils/helpers';
import '../modal.css';


function FindReplaceEvidenceModal(props){

    const [actionLoading, setActionLoading] = useState(false);
    const [findTextError, setFindTextError] = useState(true);
    const [replaceTextError, setReplaceTextError] = useState(true);
    const [findText, setFindText] = useState("");
    const [replaceText, setReplaceText] = useState("");
    const [replacedCount, setReplacedCount] = useState(0);
    const [showReplacedModal, setShowReplacedModal] = useState(false);

    const findTextErrorTooltip = "Required"
    const replaceTextErrorTooltip = "Required"

    //reset when modal is shown
    useEffect(()=>{
        if(props.showModal === true){
            setShowReplacedModal(false);
            setFindTextError(true);
            setReplaceTextError(true);
            setFindText("");
            setReplaceText("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showModal]);

    const handleClick = async () => {
        setActionLoading(true);

        //get all evidence for the selected appraisal
        const currentAppraisalId = localStorage.getItem("selectedAppraisal");
        const apiPath = `${Constants.APPRAISAL_EVIDENCE_PATH}/${currentAppraisalId}`
        const data = await API.get(Constants.API_PATH, apiPath, await getDefaultAPIHeaders());

        //check the returned paths for matching string, and replace it
        let counter = 0;
        await data.forEach(async d => {
            let evidencePath = d.evidence_path;
            if(evidencePath.includes(findText)){
                await counter++;
                //we found a match, replace the text
                let newPath = evidencePath.replace(findText, replaceText);
                
                //update the database
                const body = {
                    node_id: d.node_id,
                    appraisal_id: d.appraisal_id,
                    project_id: d.project_id,
                    evidence_path: newPath,
                    evidence_fileName: d.evidence_fileName,
                    evidence_notes: d.evidence_notes || '-',
                    evidence_state: d.evidence_state,
                    revision_number: d.revision_number,
                    appraisal_evidence_id: d.appraisal_evidence_id
                };
                try{
                    await API.put(Constants.API_PATH, Constants.APPRAISAL_EVIDENCE_PATH, await getDefaultAPIHeaders(body));
                }
                catch(e){
                    console.log('evidence path find and replace failure: ' + e);
                }  
            }
        });

        setReplacedCount(counter);
        setActionLoading(false);
        
        setShowReplacedModal(true);
    }

    const onAcknowledge = () => {
        setShowReplacedModal(false);
        props.setShowModal(false);
        window.location.reload();
    }

    const onFindTextChanged = (e) => {
        if(e === "" || e.trim().length === 0){
            setFindTextError(true);
        }
        else{
            setFindTextError(false);
        }
        setFindText(e);
    }

    const onReplaceTextChanged = (e) => {
        if(e === "" || e.trim().length === 0){
            setReplaceTextError(true);
        }
        else{
            setReplaceTextError(false);
        }
        setReplaceText(e);
    }

    const textAreaErrorStyle = {
        border: "1px solid red",
        width: "100%",
        borderRightColor: 'white',
        borderLeftColor: 'white',
        borderTopColor: 'white'    
    };

    const textAreaStyle = {
        width: "100%",
        paddingLeft: '5px',
        borderBottomColor: 'dimgray',
        borderRightColor: 'white',
        borderLeftColor: 'white',
        borderTopColor: 'white'       
    };

    return(
        <Modal show={props.showModal}
            onHide={(e)=>props.setShowModal(false)} 
            animation 
            disabled={showReplacedModal}
            >
            <Modal.Header closeButton disabled={showReplacedModal}>
                <Modal.Title style={{fontSize:'18px', color:'dimgray'}}> 
                    <img
                    src={require('../assets/icon.png')}
                    width="30"
                    height="30"
                    className="d-inline-block align-middle"
                    alt="CAP logo"
                />{' '}Find and Replace Evidence Paths</Modal.Title>
            </Modal.Header>
            <Row className= "d-flex justify-content-center">
            <Col className='col-md-10'>
            <Modal.Body>
            <FormGroup controlId="originalPathInput">
                    <FormControl type="text" name="originalPath" 
                        placeholder='Original Evidence Path'
                        onChange={e=>onFindTextChanged(e.target.value)} 
                        style={findTextError ? textAreaErrorStyle : textAreaStyle} 
                        title={findTextError ? findTextErrorTooltip : ""}
                        disabled={showReplacedModal}/>
                </FormGroup>
                <FormGroup controlId="replacePathInput">
                    <FormControl type="text" name="changedPath" 
                        placeholder='New Evidence Path'
                        onChange={e=>onReplaceTextChanged(e.target.value)} 
                        style={replaceTextError ? textAreaErrorStyle : textAreaStyle}
                        title={replaceTextError ? replaceTextErrorTooltip : ""}
                        disabled={showReplacedModal}/>
                </FormGroup>
            </Modal.Body>
            </Col>
            </Row>
            <Modal.Footer style={{backgroundColor:'rgb(0,168,168)'}}>
                <Button
                    variant="outline-light"
                    size='sm'
                    disabled={findTextError || replaceTextError || showReplacedModal}
                    onClick={(event)=>handleClick()}
                    >
                    {actionLoading &&
                        <Spinner
                            as="span"
                            animation="border"
                            size='sm'
                            role="status"
                            aria-hidden="true"
                        />}
                    {!actionLoading ? "Replace" : ' Replacing...'}
                </Button>{' '}            
            </Modal.Footer>


            <Modal bsSize="small"  
                show={showReplacedModal} 
                onHide={(e)=>setShowReplacedModal(false)} 
                animation
                style={{backgroundColor: 'rgba(0,0,0,0.2)'}}
                size='sm'>
                <Modal.Header>
                    <Modal.Title className='w-100 text-center' style={{fontSize:'16px', color:'dimgray'}}>{replacedCount} occurences replaced.</Modal.Title>
                </Modal.Header>
                <Modal.Footer style={{backgroundColor:'rgb(0,168,168)'}}>
                <Button
                    variant="outline-light"
                    size='sm'                
                    onClick={(event)=>onAcknowledge()}
                    >Ok</Button>                   
                </Modal.Footer>
            </Modal>
        </Modal>
    );
}

export default FindReplaceEvidenceModal;