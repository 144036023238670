import React, { useState, useRef, useEffect } from 'react';
import { Button, ButtonToolbar, FormLabel, Row, Col, Table, Container, OverlayTrigger, Tooltip, Modal, Spinner } from 'react-bootstrap';
import { OrgRepository, AppraisalRepository } from '../utils/repositories';
import { getDefaultAPIHeaders, Constants } from '../utils/helpers';
import { API } from 'aws-amplify';
import useArray from '../hooks/useArray';
import OrganizationModal from './OrganizationModal';
import ConfirmDeleteModal from '../presenters/ConfirmDeleteModal';
import OrganizationItem from '../presenters/OrganizationItem';
import LoadingComponent from '../presenters/LoadingComponent';
import addOrgIcon from '../assets/org-add.png'
import deleteOrgIcon from '../assets/org-delete.png'
import editOrgIcon from '../assets/org-edit.png'

const DELETE_QUESTION = "Delete the selected organizations and associated appraisals?";
const DELETE_IMPACT = "This action cannot be undone.";

//Initial state for the add/edit modal
const INITAL_MODAL_STATE = {
    id: "",
    name: "",
    nameError: false,
    nameTooltip: "",
    address: "",
    addressError: false,
    addressTooltip: "",
    contactInfo: "",
    contactInfoError: false,
    contactInfoTooltip: "",
    isActive: true
};

async function fetchData(setData, setTableLoading) {
    const orgRepo = new OrgRepository();
    const apiData = await orgRepo.getAll();
    if (!apiData.error) {
        setData(apiData.returnValue);
    } else {
        console.log(apiData.returnValue);
    }

    setTableLoading(false);
}

//Organizations page
function Organizations(props) {
    const [tableLoading, setTableLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [modalState, setModalState] = useState(INITAL_MODAL_STATE);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDeletingModal, setShowDeletingModal] = useState(false);
    const [itemBeingDeleted, setItemBeingDeleted] = useState("");
    const [itemDeleteStatus, setItemDeleteStatus] = useState("");
    const [isOrgDeleting, setIsOrgDeleting] = useState(false);
    const [isActive, setIsActive] = useState(true);
    const editOrg = useRef({});

    const enabledStyle = {
        backgroundColor: 'white',
        border: 'none',
        pointerEvents: 'auto'
    }

    const disabledStyle = {
        backgroundColor: 'white',
        border: 'none',
        pointerEvents: 'none'
    }

    //The title of the modal and text of the button of the modal
    const modalTitleFragment = useRef('Add');

    //Array to hold the orgs
    const orgsArray = useArray([]);

    //Get org data on startup
    useEffect(() => {
        const getData = () => fetchData(orgsArray.setValue, setTableLoading);
        getData();
    }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [props.refreshTrigger]);

    //#region Organizations page event handlers

    const handleAddClicked = (event) => {
        modalTitleFragment.current = "Add";

        setShowModal(true);
    }

    const handleEdit = async () => {
        const items = orgsArray.value.filter(e => e.selected === true);
        var item = items[0];

        const orgRepo = new OrgRepository();
        const result = await orgRepo.getOne(item);
        if (!result.error) {
            orgsArray.replace(result.returnValue.id, result.returnValue);
            modalTitleFragment.current = "Edit";
            editOrg.current = JSON.parse(JSON.stringify(result.returnValue));
            setModalState({
                id: result.returnValue.id,
                name: result.returnValue.name,
                address: result.returnValue.address,
                contactInfo: result.returnValue.contactInfo
            });
            setShowModal(true);
        }
        else {
            console.log(result.returnValue);
        }
    }

    //Handler for showing the confirm delete modal
    const handleShowDeleteModal = (e) => {
        e.preventDefault();
        setShowDeleteModal(true);
    }

    //Handles updating an individual isActive checkbox within the orgs table.
    const updateIsActive = async (e, id) => {
        let orgItem = orgsArray.getById(id);
        const prevValue = orgItem.isActive;
        orgItem.isActive = !e;
        orgsArray.replace(id, orgItem);
        const orgRepo = new OrgRepository();
        await orgRepo.updateOne(orgItem)
            .then(data => {
                if (data.error) {
                    orgItem = { ...orgItem, isActive: prevValue };
                    orgsArray.replace(id, orgItem);
                    return true;
                }
                else {
                    return false;
                }
            });
        uncheckInactive(id);
        props.onOrgEditted();
    }

    //Handler for closing the OrganizationModal
    const handleModalClosed = () => {
        setModalState(INITAL_MODAL_STATE);
        editOrg.current = {};
        setShowModal(false);

        for (let i = 0; i < orgsArray.value.length; i++) {
            orgsArray.value[i].selected = false;
        }
        props.updateSelectedOrg(null);
        props.onOrgEditted();
    }

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
    }

    const tryCloseDeletingModal = () => {
        if (!isOrgDeleting) {
            setShowDeletingModal(false);
        }
    }

    async function cleanOrgAppraisals(org) {
        const appraisalRepo = new AppraisalRepository();
        const appraisalData = await appraisalRepo.getAll();
        const filteredAppraisals = appraisalData.returnValue.filter(x => x.organization === org);

        for (let i = 0; i < filteredAppraisals.length; i++) {
            const item = filteredAppraisals[i];
            setItemBeingDeleted("Deleting Appraisal: " + item.name + ":");

            //clean items from appraisal
            setItemDeleteStatus("Deleting Practice Data...");
            const itemApiPath = `${Constants.APPRAISAL_ITEM_PATH}/${item.id}`
            const itemData = await API.get(Constants.API_PATH, itemApiPath, await getDefaultAPIHeaders());
            for (let j = 0; j < itemData.length; j++) {
                try {
                    let body = {
                        appraisal_item_id: itemData[j].appraisal_item_id,
                    };
                    await API.del(Constants.API_PATH, Constants.APPRAISAL_ITEM_PATH, await getDefaultAPIHeaders(body))
                }
                catch (e) {
                    console.log('Delete appraisal item failure' + e.message);
                }
            }

            //clean states from appraisal
            setItemDeleteStatus("Deleting State Data...");
            const stateApiPath = `${Constants.APPRAISAL_STATE_PATH}/${item.id}`
            const stateData = await API.get(Constants.API_PATH, stateApiPath, await getDefaultAPIHeaders());
            for (let j = 0; j < stateData.length; j++) {
                try {
                    let body = {
                        compound_id: stateData[j].compound_id,
                    };
                    await API.del(Constants.API_PATH, Constants.APPRAISAL_STATE_PATH, await getDefaultAPIHeaders(body))
                }
                catch (e) {
                    console.log('Delete appraisal state failure' + e.message);
                }
            }

            //clean evidence from appraisal
            setItemDeleteStatus("Deleting Evidence Data...");
            const evidenceApiPath = `${Constants.APPRAISAL_EVIDENCE_PATH}/${item.id}`
            const evidenceData = await API.get(Constants.API_PATH, evidenceApiPath, await getDefaultAPIHeaders());
            for (let j = 0; j < evidenceData.length; j++) {
                let body = {
                    appraisal_evidence_id: evidenceData[j].appraisal_evidence_id,
                };
                try {
                    await API.del(Constants.API_PATH, Constants.APPRAISAL_EVIDENCE_PATH, await getDefaultAPIHeaders(body));
                }
                catch (e) {
                    console.log('Delete appraisal evidence failure' + e);
                }
            }

            //clean interview questions from appraisal
            setItemDeleteStatus("Deleting Interview Data...");
            const interviewsApiPath = `${Constants.INTERVIEW_QUESTIONS_PATH}/${item.id}`
            const interviewsData = await API.get(Constants.API_PATH, interviewsApiPath, await getDefaultAPIHeaders());
            for (let j = 0; j < interviewsData.length; j++) {
                try {
                    let body = {
                        interview_question_id: interviewsData[j].interview_question_id,
                    };
                    await API.del(Constants.API_PATH, Constants.INTERVIEW_QUESTIONS_PATH, await getDefaultAPIHeaders(body))
                }
                catch (e) {
                    console.log('Delete appraisal interview question failure' + e.message);
                }
            }

            //delete appraisal entry
            setItemDeleteStatus("Deleting Appraisal...");
            const result = await appraisalRepo.deleteOne(item.id);
            if (result.error) {
                console.log("Error deleting appraisal: ", result.returnValue);
            }
            setItemDeleteStatus("Appraisal Deleted Successfully.");
        }
        setItemBeingDeleted("");
    }

    const handleDelete = async () => {
        setShowDeleteModal(false);
        setIsOrgDeleting(true);
        setShowDeletingModal(true);
        const itemsToRemove = orgsArray.value.filter(org => org.selected === true);
        const orgRepo = new OrgRepository();
        for (let i = 0; i < itemsToRemove.length; i++) {
            let item = itemsToRemove[i];

            await cleanOrgAppraisals(item.id);
            setItemBeingDeleted("Deleting Organization: " + item.name + ":");

            const result = await orgRepo.deleteOne(item);
            if (!result.error) {
                orgsArray.removeById(item.id);
                props.updateSelectedOrg(null);
                props.onOrgEditted();
            }
            else {
                console.log(result.returnValue);
            }
        }
        setItemBeingDeleted("");
        setItemDeleteStatus("Organization Deletion Complete");
        setIsOrgDeleting(false);
    }

    const updateSelected = (e, id) => {
        orgsArray.value.forEach(o => {
            o.selected = false;
        });
        let item = orgsArray.getById(id);
        item.selected = e.target.checked;
        orgsArray.replace(id, item);

        if (orgsArray.value.filter(x => x.selected).length === 1) {
            props.updateSelectedOrg(orgsArray.value.filter(x => x.selected)[0]);
        }
        else {
            props.updateSelectedOrg(null);
        }
    };

    const uncheckInactive = (id) => {
        let item = orgsArray.getById(id);
        item.selected = false;
        orgsArray.replace(id, item);

        if (orgsArray.value.filter(x => x.selected).length === 1) {
            props.updateSelectedOrg(orgsArray.value.filter(x => x.selected)[0]);
        }
        else {
            props.updateSelectedOrg(null);
        }
    }

    const singleItemSelected = () => {
        return orgsArray.value.filter(x => x.selected === true).length === 1;
    }

    const itemsAreSelected = () => {
        return orgsArray.value.filter(x => x.selected === true).length > 0;
    }

    const orgTableItems = orgsArray.value.map(org => {
        return (
            <tr key={org.id}>
                <OrganizationItem
                    org={org}
                    updateSelected={updateSelected}
                    updateIsActive={updateIsActive}
                />
            </tr>
        );
    });

    return (
        <React.Fragment>
            <Container className='container-sm'>
                <Row>
                    <FormLabel
                        style={{
                            color: 'dimgray',
                            fontSize: '16px',
                            textAlign: 'left',
                            fontWeight: 'bold'
                        }}>
                        Organizations
                                </FormLabel>
                </Row>
                <ButtonToolbar style={{ flexWrap: 'nowrap' }}>
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Edit Org</Tooltip>}>
                        <span className="d-inline-block">
                            <Button
                                variant="light"
                                type="submit"
                                size="sm"
                                style={singleItemSelected() ? enabledStyle : disabledStyle}
                                disabled={!singleItemSelected()}
                                onClick={e => handleEdit(e)}>
                                <img
                                    src={editOrgIcon}
                                    width="25"
                                    height="25"
                                    className="d-inline-block align-top"
                                    alt="CAP logo and text"
                                    color="royalblue"
                                />{' '}
                            </Button>
                        </span>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete Org</Tooltip>}>
                        <span className="d-inline-block">
                            <Button
                                variant="light"
                                type="submit"
                                size="sm"
                                style={itemsAreSelected() ? enabledStyle : disabledStyle}
                                onClick={e => handleShowDeleteModal(e)}
                                disabled={!itemsAreSelected()}>
                                <img
                                    src={deleteOrgIcon}
                                    width="25"
                                    height="25"
                                    className="d-inline-block align-top"
                                    alt="CAP logo and text"
                                    color="royalblue"
                                />{' '}
                            </Button>
                        </span>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Org</Tooltip>}>
                        <span className="d-inline-block">
                            <Button
                                variant="light"
                                type="submit"
                                size="sm"
                                style={{ backgroundColor: 'white', border: 'none' }}
                                onClick={e => handleAddClicked(e)}>
                                <img
                                    src={addOrgIcon}
                                    width="25"
                                    height="25"
                                    className="d-inline-block align-top"
                                    alt="CAP logo and text"
                                    color="royalblue"
                                />{' '}
                            </Button>
                        </span>
                    </OverlayTrigger>
                </ButtonToolbar>
                <Row>
                    <LoadingComponent isLoading={tableLoading} iconSize={80}>
                        <Table size='sm' style={{ tableLayout: 'fixed' }}>
                            <div style={{ maxWidth: '400px' }}>
                                <thead className='thead'>
                                    <tr>
                                        <th style={{ width: '30px', textAlign: 'center' }}></th>
                                        <th style={{ width: '170px', textAlign: 'left' }}>Org</th>
                                        <th style={{ width: '200px', textAlign: 'left' }}>Active</th>
                                    </tr>
                                </thead>
                            </div>
                            <div style={{ maxWidth: '400px', overflow: 'auto' }}>
                                <tbody>
                                    {orgTableItems}
                                </tbody>
                            </div>
                        </Table>
                    </LoadingComponent>
                </Row>
                <OrganizationModal
                    modalState={modalState}
                    setModalState={setModalState}
                    orgsArray={orgsArray}
                    editOrg={editOrg.current}
                    modalTitleFragment={modalTitleFragment.current}
                    close={handleModalClosed}
                    showModal={showModal}
                />
                <ConfirmDeleteModal
                    showDeleteModal={showDeleteModal}
                    close={closeDeleteModal}
                    deleteAction={handleDelete}
                    deleteQuestion={DELETE_QUESTION}
                    deleteImpact={DELETE_IMPACT}
                />
                <Modal show={showDeletingModal} onHide={(e) => tryCloseDeletingModal(e)} >
                    <Modal.Header >
                        <Modal.Title style={{ fontSize: '18px', color: 'dimgray' }}>
                            <img
                                src={require('../assets/icon.png')}
                                width="30"
                                height="30"
                                className="d-inline-block align-middle"
                                alt="CAP logo"
                            />Deleting Organization</Modal.Title>
                    </Modal.Header>
                    <Row className="d-flex justify-content-center">
                        <Col className='col-md-10'>
                            <Modal.Body>                                
                                <text style={{ fontSize: '14px', color: 'dimgray' }}>{itemDeleteStatus}</text>
                            </Modal.Body>                            
                        </Col>
                    </Row>
                    <Modal.Footer style={{ backgroundColor: 'rgb(0,168,168)' }}>
                        <Button
                            variant="outline-light"
                            disabled={isOrgDeleting}
                            size='sm'
                            onClick={(e) => tryCloseDeletingModal(e)}>
                            {isOrgDeleting &&
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size='sm'
                                    role="status"
                                    aria-hidden="true"
                                />}
                            {!isOrgDeleting ? "Close" : ' Deleting Organization, please wait...'}
                        </Button>{' '}
                    </Modal.Footer>
                </Modal>
            </Container>
        </React.Fragment>
    )
}
export default Organizations;