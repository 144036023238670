import React from 'react';

function AppraisalTableItem(props){
    const chkBoxId = `${props.appraisal.id}_chkBox`;
    const nameLabelId = `${props.appraisal.id}_nameLabel`;
    const viewLabelId = `${props.appraisal.id}_levelLabel`;
    const colId = `${props.appraisal.id}_organizationLabel`;
    const editKey = `${props.appraisal.id}_editButton`;
    const interviewKey = `${props.appraisal.id}_interviewButton`;

    return (
        <React.Fragment>
            <td key={chkBoxId} style={{ width: '5%', textAlign: 'center' }}>
                <input type="checkbox" 
                     onChange={(e) => props.updateSelected(e, props.appraisal.id)}
                     checked={props.appraisal.selected}/>
            </td>
            <td key={nameLabelId} style={{ width: '45%', textAlign: 'left', color:'dimgray'}}>
                <label>{props.appraisal.name}</label>
            </td>            
            <td key={viewLabelId} style={{ width: '50%', textAlign: 'left', color:'dimgray' }}>
                <label>{props.appraisal.view}</label>
            </td>            
        </React.Fragment>
    )
}
export default AppraisalTableItem;