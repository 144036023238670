import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import TreeControl from './Tree/TreeControl';
import NodePage from './NodePage';
import './Home.css';


function Home(props) {
  const [nodeInfo, setNodeInfo] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(true);
  const [treeLoadingTrigger, setTreeLoadingTrigger] = useState(true);

  const updateNodeInfo = (nodeInfo) => {
    setNodeInfo(nodeInfo);
  }

  const onNodeHighlight = () => {
    setRefreshTrigger(!refreshTrigger);
  }

  const onPracticeAffirmed = () => {
    setRefreshTrigger(!refreshTrigger);
  }

  const setTreeControlLoading = () => {
    setTreeLoadingTrigger(!treeLoadingTrigger);
  }

  return (
    <Container fluid>
      <div class="row flex-nowrap">
        <Col style={{maxWidth:'500px'}}>
          <TreeControl updateNodeInfo={updateNodeInfo} refreshTrigger={refreshTrigger} treeLoadingTrigger={treeLoadingTrigger}/>
        </Col>
        <Col style={{maxWidth:'1400px'}}>
          <NodePage nodeInfo={nodeInfo} onNodeHighlight={onNodeHighlight} onPracticeAffirmed={onPracticeAffirmed} setTreeControlLoading={setTreeControlLoading}/>
        </Col>
      </div>
    </Container>
  )
}

export default Home;
