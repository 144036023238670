import { MODEL_CONSTANTS } from './modelconstants';

const PRACTICE_AREA_RELATIONSHIPS = {
    [MODEL_CONSTANTS.CAR]: [
        {Id: MODEL_CONSTANTS.CAR_11, Level: 1},
        {Id: MODEL_CONSTANTS.CAR_21, Level: 2},
        {Id: MODEL_CONSTANTS.CAR_22, Level: 2},  
        {Id: MODEL_CONSTANTS.CAR_31, Level: 3},
        {Id: MODEL_CONSTANTS.CAR_32, Level: 3},
        {Id: MODEL_CONSTANTS.CAR_33, Level: 3},
        {Id: MODEL_CONSTANTS.CAR_34, Level: 3},
        {Id: MODEL_CONSTANTS.CAR_35, Level: 3},
        {Id: MODEL_CONSTANTS.CAR_41, Level: 4},
        {Id: MODEL_CONSTANTS.CAR_42, Level: 4},
        {Id: MODEL_CONSTANTS.CAR_51, Level: 5}
    ],
    [MODEL_CONSTANTS.CM]: [
        {Id: MODEL_CONSTANTS.CM_11, Level: 1},
        {Id: MODEL_CONSTANTS.CM_21, Level: 2},
        {Id: MODEL_CONSTANTS.CM_22, Level: 2},
        {Id: MODEL_CONSTANTS.CM_23, Level: 2},
        {Id: MODEL_CONSTANTS.CM_24, Level: 2},
        {Id: MODEL_CONSTANTS.CM_25, Level: 2},
        {Id: MODEL_CONSTANTS.CM_26, Level: 2}
    ],
    [MODEL_CONSTANTS.CONT]: [
        {Id: MODEL_CONSTANTS.CONT_11, Level: 1},
        {Id: MODEL_CONSTANTS.CONT_21, Level: 2},
        {Id: MODEL_CONSTANTS.CONT_22, Level: 2},
        {Id: MODEL_CONSTANTS.CONT_23, Level: 2},
        {Id: MODEL_CONSTANTS.CONT_31, Level: 3},
        {Id: MODEL_CONSTANTS.CONT_32, Level: 3},
        {Id: MODEL_CONSTANTS.CONT_33, Level: 3}
    ],
    [MODEL_CONSTANTS.DAR]: [
        {Id: MODEL_CONSTANTS.DAR_11, Level: 1},
        {Id: MODEL_CONSTANTS.DAR_12, Level: 1},
        {Id: MODEL_CONSTANTS.DAR_21, Level: 2},
        {Id: MODEL_CONSTANTS.DAR_22, Level: 2},
        {Id: MODEL_CONSTANTS.DAR_23, Level: 2},
        {Id: MODEL_CONSTANTS.DAR_24, Level: 2},
        {Id: MODEL_CONSTANTS.DAR_25, Level: 2},
        {Id: MODEL_CONSTANTS.DAR_31, Level: 3}
    ],
    [MODEL_CONSTANTS.EST]: [
        {Id: MODEL_CONSTANTS.EST_11, Level: 1},
        {Id: MODEL_CONSTANTS.EST_21, Level: 2},
        {Id: MODEL_CONSTANTS.EST_22, Level: 2},
        {Id: MODEL_CONSTANTS.EST_23, Level: 2},
        {Id: MODEL_CONSTANTS.EST_31, Level: 3},
        {Id: MODEL_CONSTANTS.EST_32, Level: 3}
    ],
    [MODEL_CONSTANTS.EVSD]: [
        {Id: MODEL_CONSTANTS.EVSD_11, Level: 1},
        {Id: MODEL_CONSTANTS.EVSD_12, Level: 1},
        {Id: MODEL_CONSTANTS.EVSD_21, Level: 2},
        {Id: MODEL_CONSTANTS.EVSD_22, Level: 2},
        {Id: MODEL_CONSTANTS.EVSD_31, Level: 3},
        {Id: MODEL_CONSTANTS.EVSD_32, Level: 3},
        {Id: MODEL_CONSTANTS.EVSD_33, Level: 3}
    ],
    [MODEL_CONSTANTS.GOV]: [
        {Id: MODEL_CONSTANTS.GOV_11, Level: 1},
        {Id: MODEL_CONSTANTS.GOV_21, Level: 2},
        {Id: MODEL_CONSTANTS.GOV_22, Level: 2}, 
        {Id: MODEL_CONSTANTS.GOV_23, Level: 2},
        {Id: MODEL_CONSTANTS.GOV_24, Level: 2},
        {Id: MODEL_CONSTANTS.GOV_31, Level: 3},
        {Id: MODEL_CONSTANTS.GOV_32, Level: 3},
        {Id: MODEL_CONSTANTS.GOV_41, Level: 4}
    ],
    [MODEL_CONSTANTS.II]: [
        {Id: MODEL_CONSTANTS.II_11, Level: 1},
        {Id: MODEL_CONSTANTS.II_21, Level: 2},
        {Id: MODEL_CONSTANTS.II_22, Level: 2},
        {Id: MODEL_CONSTANTS.II_31, Level: 3},
        {Id: MODEL_CONSTANTS.II_32, Level: 3},
        {Id: MODEL_CONSTANTS.II_33, Level: 3}
    ],
    [MODEL_CONSTANTS.IRP]: [
        {Id: MODEL_CONSTANTS.IRP_11, Level: 1}, 
        {Id: MODEL_CONSTANTS.IRP_21, Level: 2},
        {Id: MODEL_CONSTANTS.IRP_22, Level: 2},
        {Id: MODEL_CONSTANTS.IRP_23, Level: 2},
        {Id: MODEL_CONSTANTS.IRP_31, Level: 3},
        {Id: MODEL_CONSTANTS.IRP_32, Level: 3}
    ],
    [MODEL_CONSTANTS.MPM]: [
        {Id: MODEL_CONSTANTS.MPM_11, Level: 1},
        {Id: MODEL_CONSTANTS.MPM_12, Level: 1},
        {Id: MODEL_CONSTANTS.MPM_21, Level: 2},
        {Id: MODEL_CONSTANTS.MPM_22, Level: 2},
        {Id: MODEL_CONSTANTS.MPM_23, Level: 2},
        {Id: MODEL_CONSTANTS.MPM_24, Level: 2},
        {Id: MODEL_CONSTANTS.MPM_25, Level: 2},
        {Id: MODEL_CONSTANTS.MPM_26, Level: 2},
        {Id: MODEL_CONSTANTS.MPM_31, Level: 3},
        {Id: MODEL_CONSTANTS.MPM_32, Level: 3},
        {Id: MODEL_CONSTANTS.MPM_33, Level: 3},
        {Id: MODEL_CONSTANTS.MPM_34, Level: 3},
        {Id: MODEL_CONSTANTS.MPM_35, Level: 3},
        {Id: MODEL_CONSTANTS.MPM_36, Level: 3},
        {Id: MODEL_CONSTANTS.MPM_41, Level: 4},
        {Id: MODEL_CONSTANTS.MPM_42, Level: 4},
        {Id: MODEL_CONSTANTS.MPM_43, Level: 4},
        {Id: MODEL_CONSTANTS.MPM_44, Level: 4},
        {Id: MODEL_CONSTANTS.MPM_45, Level: 4},
        {Id: MODEL_CONSTANTS.MPM_51, Level: 5},
        {Id: MODEL_CONSTANTS.MPM_52, Level: 5},
        {Id: MODEL_CONSTANTS.MPM_53, Level: 5}
    ],
    [MODEL_CONSTANTS.MC]: [
        {Id: MODEL_CONSTANTS.MC_11, Level: 1},
        {Id: MODEL_CONSTANTS.MC_12, Level: 1},
        {Id: MODEL_CONSTANTS.MC_21, Level: 2}, 
        {Id: MODEL_CONSTANTS.MC_22, Level: 2},
        {Id: MODEL_CONSTANTS.MC_23, Level: 2},
        {Id: MODEL_CONSTANTS.MC_24, Level: 2},
        {Id: MODEL_CONSTANTS.MC_31, Level: 3},
        {Id: MODEL_CONSTANTS.MC_32, Level: 3},
        {Id: MODEL_CONSTANTS.MC_33, Level: 3},
        {Id: MODEL_CONSTANTS.MC_34, Level: 3}
    ],
    [MODEL_CONSTANTS.OT]: [
        {Id: MODEL_CONSTANTS.OT_11, Level: 1},
        {Id: MODEL_CONSTANTS.OT_21, Level: 2},
        {Id: MODEL_CONSTANTS.OT_22, Level: 2},
        {Id: MODEL_CONSTANTS.OT_31, Level: 3},
        {Id: MODEL_CONSTANTS.OT_32, Level: 3},
        {Id: MODEL_CONSTANTS.OT_33, Level: 3},
        {Id: MODEL_CONSTANTS.OT_34, Level: 3},
        {Id: MODEL_CONSTANTS.OT_35, Level: 3},
        {Id: MODEL_CONSTANTS.OT_36, Level: 3}
    ],
    [MODEL_CONSTANTS.PR]: [
        {Id: MODEL_CONSTANTS.PR_11, Level: 1},
        {Id: MODEL_CONSTANTS.PR_21, Level: 2},
        {Id: MODEL_CONSTANTS.PR_22, Level: 2},
        {Id: MODEL_CONSTANTS.PR_23, Level: 2},
        {Id: MODEL_CONSTANTS.PR_24, Level: 2},
        {Id: MODEL_CONSTANTS.PR_31, Level: 3}
    ],
    [MODEL_CONSTANTS.PLAN]: [
        {Id: MODEL_CONSTANTS.PLAN_11, Level: 1},
        {Id: MODEL_CONSTANTS.PLAN_12, Level: 1},
        {Id: MODEL_CONSTANTS.PLAN_21, Level: 2},
        {Id: MODEL_CONSTANTS.PLAN_22, Level: 2},
        {Id: MODEL_CONSTANTS.PLAN_23, Level: 2},
        {Id: MODEL_CONSTANTS.PLAN_24, Level: 2},
        {Id: MODEL_CONSTANTS.PLAN_25, Level: 2},
        {Id: MODEL_CONSTANTS.PLAN_26, Level: 2},
        {Id: MODEL_CONSTANTS.PLAN_27, Level: 2},
        {Id: MODEL_CONSTANTS.PLAN_28, Level: 2},
        {Id: MODEL_CONSTANTS.PLAN_31, Level: 3},
        {Id: MODEL_CONSTANTS.PLAN_32, Level: 3},
        {Id: MODEL_CONSTANTS.PLAN_33, Level: 3},
        {Id: MODEL_CONSTANTS.PLAN_34, Level: 3},
        {Id: MODEL_CONSTANTS.PLAN_41, Level: 4}
    ],
    [MODEL_CONSTANTS.PAD]: [
        {Id: MODEL_CONSTANTS.PAD_11, Level: 1},
        {Id: MODEL_CONSTANTS.PAD_21, Level: 2},
        {Id: MODEL_CONSTANTS.PAD_22, Level: 2},
        {Id: MODEL_CONSTANTS.PAD_23, Level: 2},
        {Id: MODEL_CONSTANTS.PAD_31, Level: 3},
        {Id: MODEL_CONSTANTS.PAD_32, Level: 3},
        {Id: MODEL_CONSTANTS.PAD_33, Level: 3},
        {Id: MODEL_CONSTANTS.PAD_34, Level: 3},
        {Id: MODEL_CONSTANTS.PAD_35, Level: 3},
        {Id: MODEL_CONSTANTS.PAD_36, Level: 3}
        //{Id: MODEL_CONSTANTS.PAD_37, Level: 3} //removed in 3.0
    ],
    [MODEL_CONSTANTS.PCM]: [
        {Id: MODEL_CONSTANTS.PCM_11, Level: 1},
        {Id: MODEL_CONSTANTS.PCM_12, Level: 1},
        {Id: MODEL_CONSTANTS.PCM_13, Level: 1}, 
        {Id: MODEL_CONSTANTS.PCM_21, Level: 2}, 
        {Id: MODEL_CONSTANTS.PCM_22, Level: 2}, 
        {Id: MODEL_CONSTANTS.PCM_31, Level: 3}, 
        {Id: MODEL_CONSTANTS.PCM_32, Level: 3},
        {Id: MODEL_CONSTANTS.PCM_33, Level: 3},
        {Id: MODEL_CONSTANTS.PCM_34, Level: 3}, 
        {Id: MODEL_CONSTANTS.PCM_35, Level: 3},
        {Id: MODEL_CONSTANTS.PCM_36, Level: 3},
        {Id: MODEL_CONSTANTS.PCM_41, Level: 4}
    ],
    [MODEL_CONSTANTS.PQA]: [
        {Id: MODEL_CONSTANTS.PQA_11, Level: 1},
        {Id: MODEL_CONSTANTS.PQA_21, Level: 2},
        {Id: MODEL_CONSTANTS.PQA_22, Level: 2},
        {Id: MODEL_CONSTANTS.PQA_23, Level: 2},
        {Id: MODEL_CONSTANTS.PQA_24, Level: 2},
        {Id: MODEL_CONSTANTS.PQA_31, Level: 3}
    ],
    [MODEL_CONSTANTS.PI]: [
        {Id: MODEL_CONSTANTS.PI_11, Level: 1},
        {Id: MODEL_CONSTANTS.PI_21, Level: 2},
        {Id: MODEL_CONSTANTS.PI_22, Level: 2},
        {Id: MODEL_CONSTANTS.PI_23, Level: 2}, 
        {Id: MODEL_CONSTANTS.PI_24, Level: 2},
        {Id: MODEL_CONSTANTS.PI_25, Level: 2},
        {Id: MODEL_CONSTANTS.PI_26, Level: 2},
        {Id: MODEL_CONSTANTS.PI_31, Level: 3},
        {Id: MODEL_CONSTANTS.PI_32, Level: 3},
        {Id: MODEL_CONSTANTS.PI_33, Level: 3}
    ],
    [MODEL_CONSTANTS.RDM]: [
        {Id: MODEL_CONSTANTS.RDM_11, Level: 1},
        {Id: MODEL_CONSTANTS.RDM_21, Level: 2},
        {Id: MODEL_CONSTANTS.RDM_22, Level: 2}, 
        {Id: MODEL_CONSTANTS.RDM_23, Level: 2},
        {Id: MODEL_CONSTANTS.RDM_24, Level: 2},
        {Id: MODEL_CONSTANTS.RDM_25, Level: 2},
        //{Id: MODEL_CONSTANTS.RDM_26, Level: 2}, removed in 3.0
        {Id: MODEL_CONSTANTS.RDM_31, Level: 3},
        {Id: MODEL_CONSTANTS.RDM_32, Level: 3},
        {Id: MODEL_CONSTANTS.RDM_33, Level: 3},
        {Id: MODEL_CONSTANTS.RDM_34, Level: 3},
        {Id: MODEL_CONSTANTS.RDM_35, Level: 3},
        {Id: MODEL_CONSTANTS.RDM_36, Level: 3},
        {Id: MODEL_CONSTANTS.RDM_37, Level: 3}
    ],
    [MODEL_CONSTANTS.RSK]:[
        {Id: MODEL_CONSTANTS.RSK_11, Level: 1},
        {Id: MODEL_CONSTANTS.RSK_21, Level: 2},
        {Id: MODEL_CONSTANTS.RSK_22, Level: 2}, 
        {Id: MODEL_CONSTANTS.RSK_31, Level: 3},
        {Id: MODEL_CONSTANTS.RSK_32, Level: 3},
        {Id: MODEL_CONSTANTS.RSK_33, Level: 3},
        {Id: MODEL_CONSTANTS.RSK_34, Level: 3},
        {Id: MODEL_CONSTANTS.RSK_35, Level: 3}
    ],
    [MODEL_CONSTANTS.SDM]: [
        {Id: MODEL_CONSTANTS.SDM_11, Level: 1},
        {Id: MODEL_CONSTANTS.SDM_21, Level: 2},
        {Id: MODEL_CONSTANTS.SDM_22, Level: 2}, 
        {Id: MODEL_CONSTANTS.SDM_23, Level: 2},
        {Id: MODEL_CONSTANTS.SDM_24, Level: 2},
        {Id: MODEL_CONSTANTS.SDM_25, Level: 2},
        {Id: MODEL_CONSTANTS.SDM_26, Level: 2},
        {Id: MODEL_CONSTANTS.SDM_31, Level: 3}
    ],
    [MODEL_CONSTANTS.STSM]:[
        {Id: MODEL_CONSTANTS.STSM_11, Level: 1},
        {Id: MODEL_CONSTANTS.STSM_21, Level: 2},
        {Id: MODEL_CONSTANTS.STSM_22, Level: 2},
        {Id: MODEL_CONSTANTS.STSM_23, Level: 2},
        {Id: MODEL_CONSTANTS.STSM_31, Level: 3}
    ],
    [MODEL_CONSTANTS.SAM]:[
        {Id: MODEL_CONSTANTS.SAM_11, Level: 1},
        {Id: MODEL_CONSTANTS.SAM_12, Level: 1},
        {Id: MODEL_CONSTANTS.SAM_13, Level: 1},
        {Id: MODEL_CONSTANTS.SAM_14, Level: 1},
        {Id: MODEL_CONSTANTS.SAM_21, Level: 2},
        {Id: MODEL_CONSTANTS.SAM_22, Level: 2}, 
        {Id: MODEL_CONSTANTS.SAM_23, Level: 2},
        {Id: MODEL_CONSTANTS.SAM_24, Level: 2},
        {Id: MODEL_CONSTANTS.SAM_25, Level: 2},
        {Id: MODEL_CONSTANTS.SAM_31, Level: 3},
        {Id: MODEL_CONSTANTS.SAM_32, Level: 3},
        {Id: MODEL_CONSTANTS.SAM_41, Level: 4}
    ],
    [MODEL_CONSTANTS.SSS]: [
        {Id: MODEL_CONSTANTS.SSS_11, Level: 1},
        {Id: MODEL_CONSTANTS.SSS_12, Level: 1},
        {Id: MODEL_CONSTANTS.SSS_13, Level: 1},
        {Id: MODEL_CONSTANTS.SSS_21, Level: 2},
        {Id: MODEL_CONSTANTS.SSS_22, Level: 2},
        {Id: MODEL_CONSTANTS.SSS_23, Level: 2},
        {Id: MODEL_CONSTANTS.SSS_31, Level: 3}
    ],
    [MODEL_CONSTANTS.TS]:[
        {Id: MODEL_CONSTANTS.TS_11, Level: 1},
        {Id: MODEL_CONSTANTS.TS_21, Level: 2}, 
        {Id: MODEL_CONSTANTS.TS_22, Level: 2},
        {Id: MODEL_CONSTANTS.TS_23, Level: 2},
        {Id: MODEL_CONSTANTS.TS_31, Level: 3},
        {Id: MODEL_CONSTANTS.TS_32, Level: 3},
        {Id: MODEL_CONSTANTS.TS_33, Level: 3},
        {Id: MODEL_CONSTANTS.TS_34, Level: 3},
        {Id: MODEL_CONSTANTS.TS_35, Level: 3},
        {Id: MODEL_CONSTANTS.TS_36, Level: 3}
    ],
    [MODEL_CONSTANTS.VV]: [
        {Id: MODEL_CONSTANTS.VV_11, Level: 1},
        {Id: MODEL_CONSTANTS.VV_12, Level: 1},
        {Id: MODEL_CONSTANTS.VV_21, Level: 2},
        {Id: MODEL_CONSTANTS.VV_22, Level: 2},
        {Id: MODEL_CONSTANTS.VV_23, Level: 2},
        {Id: MODEL_CONSTANTS.VV_31, Level: 3},
        {Id: MODEL_CONSTANTS.VV_32, Level: 3}
    ]
};

const PRACTICES_AREAS_BY_MATURITY_LEVEL = {
    DEV: {
        2: [
            MODEL_CONSTANTS.CM,
            MODEL_CONSTANTS.DAR,
            MODEL_CONSTANTS.PQA,
            MODEL_CONSTANTS.PR,
            MODEL_CONSTANTS.EST,
            MODEL_CONSTANTS.GOV,
            MODEL_CONSTANTS.SAM,
            MODEL_CONSTANTS.VV,
            MODEL_CONSTANTS.II,
            MODEL_CONSTANTS.PI,
            MODEL_CONSTANTS.MC,
            MODEL_CONSTANTS.PLAN,
            MODEL_CONSTANTS.CAR,
            MODEL_CONSTANTS.RSK,
            MODEL_CONSTANTS.MPM,
            MODEL_CONSTANTS.OT,
            MODEL_CONSTANTS.PCM,
            MODEL_CONSTANTS.TS,
            MODEL_CONSTANTS.PAD,
            MODEL_CONSTANTS.RDM
        ],
        3: [
            MODEL_CONSTANTS.CM,
            MODEL_CONSTANTS.DAR,
            MODEL_CONSTANTS.PQA,
            MODEL_CONSTANTS.PR,
            MODEL_CONSTANTS.EST,
            MODEL_CONSTANTS.GOV,
            MODEL_CONSTANTS.SAM,
            MODEL_CONSTANTS.VV,
            MODEL_CONSTANTS.II,
            MODEL_CONSTANTS.PI,
            MODEL_CONSTANTS.MC,
            MODEL_CONSTANTS.PLAN,
            MODEL_CONSTANTS.CAR,
            MODEL_CONSTANTS.RSK,
            MODEL_CONSTANTS.MPM,
            MODEL_CONSTANTS.OT,
            MODEL_CONSTANTS.PCM,
            MODEL_CONSTANTS.TS,
            MODEL_CONSTANTS.PAD,
            MODEL_CONSTANTS.RDM
        ],
        4: [
            MODEL_CONSTANTS.CM,
            MODEL_CONSTANTS.DAR,
            MODEL_CONSTANTS.PQA,
            MODEL_CONSTANTS.PR,
            MODEL_CONSTANTS.EST,
            MODEL_CONSTANTS.VV,
            MODEL_CONSTANTS.II,
            MODEL_CONSTANTS.PI,
            MODEL_CONSTANTS.MC,
            MODEL_CONSTANTS.RSK,
            MODEL_CONSTANTS.OT,
            MODEL_CONSTANTS.TS,
            MODEL_CONSTANTS.PAD,
            MODEL_CONSTANTS.PCM,
            MODEL_CONSTANTS.RDM,
            MODEL_CONSTANTS.GOV,
            MODEL_CONSTANTS.PLAN,
            MODEL_CONSTANTS.SAM,
            MODEL_CONSTANTS.CAR,
            MODEL_CONSTANTS.MPM
        ],
        5:[
            MODEL_CONSTANTS.CM,
            MODEL_CONSTANTS.DAR,
            MODEL_CONSTANTS.PQA,
            MODEL_CONSTANTS.PR,
            MODEL_CONSTANTS.EST,
            MODEL_CONSTANTS.VV,
            MODEL_CONSTANTS.II,
            MODEL_CONSTANTS.PI,
            MODEL_CONSTANTS.MC,
            MODEL_CONSTANTS.RSK,
            MODEL_CONSTANTS.OT,
            MODEL_CONSTANTS.TS,
            MODEL_CONSTANTS.PAD,
            MODEL_CONSTANTS.PCM,
            MODEL_CONSTANTS.RDM,
            MODEL_CONSTANTS.GOV,
            MODEL_CONSTANTS.PLAN,
            MODEL_CONSTANTS.SAM,
            MODEL_CONSTANTS.CAR,
            MODEL_CONSTANTS.MPM
        ]
    },
    SVC:{
        2: [
            MODEL_CONSTANTS.CM,
            MODEL_CONSTANTS.DAR,
            MODEL_CONSTANTS.PQA,
            MODEL_CONSTANTS.PR,
            MODEL_CONSTANTS.SDM,
            MODEL_CONSTANTS.STSM,
            MODEL_CONSTANTS.EST,
            MODEL_CONSTANTS.GOV,
            MODEL_CONSTANTS.IRP,
            MODEL_CONSTANTS.SAM,
            MODEL_CONSTANTS.VV,
            MODEL_CONSTANTS.CONT,
            MODEL_CONSTANTS.II,
            MODEL_CONSTANTS.MC,
            MODEL_CONSTANTS.PLAN,
            MODEL_CONSTANTS.CAR,
            MODEL_CONSTANTS.RSK,
            MODEL_CONSTANTS.MPM,
            MODEL_CONSTANTS.OT,
            MODEL_CONSTANTS.PCM,
            MODEL_CONSTANTS.PAD,
            MODEL_CONSTANTS.RDM
        ],
        3: [
            MODEL_CONSTANTS.CM,
            MODEL_CONSTANTS.DAR,
            MODEL_CONSTANTS.PQA,
            MODEL_CONSTANTS.PR,
            MODEL_CONSTANTS.SDM,
            MODEL_CONSTANTS.STSM,
            MODEL_CONSTANTS.EST,
            MODEL_CONSTANTS.GOV,
            MODEL_CONSTANTS.IRP,
            MODEL_CONSTANTS.SAM,
            MODEL_CONSTANTS.VV,
            MODEL_CONSTANTS.CONT,
            MODEL_CONSTANTS.II,
            MODEL_CONSTANTS.MC,
            MODEL_CONSTANTS.PLAN,
            MODEL_CONSTANTS.CAR,
            MODEL_CONSTANTS.RSK,
            MODEL_CONSTANTS.MPM,
            MODEL_CONSTANTS.OT,
            MODEL_CONSTANTS.PCM,
            MODEL_CONSTANTS.PAD,
            MODEL_CONSTANTS.RDM
        ],
       4: [
            MODEL_CONSTANTS.CM,
            MODEL_CONSTANTS.DAR,
            MODEL_CONSTANTS.PQA,
            MODEL_CONSTANTS.PR,
            MODEL_CONSTANTS.SDM,
            MODEL_CONSTANTS.STSM,
            MODEL_CONSTANTS.EST,
            MODEL_CONSTANTS.IRP,
            MODEL_CONSTANTS.VV,
            MODEL_CONSTANTS.CONT,
            MODEL_CONSTANTS.II,
            MODEL_CONSTANTS.MC,
            MODEL_CONSTANTS.RSK,
            MODEL_CONSTANTS.OT,
            MODEL_CONSTANTS.PAD,
            MODEL_CONSTANTS.PCM,
            MODEL_CONSTANTS.RDM,
            MODEL_CONSTANTS.GOV,
            MODEL_CONSTANTS.PLAN,
            MODEL_CONSTANTS.SAM,
            MODEL_CONSTANTS.CAR,
            MODEL_CONSTANTS.MPM
        ],
       5: [
            MODEL_CONSTANTS.CM,
            MODEL_CONSTANTS.DAR,
            MODEL_CONSTANTS.PQA,
            MODEL_CONSTANTS.PR,
            MODEL_CONSTANTS.SDM,
            MODEL_CONSTANTS.STSM,
            MODEL_CONSTANTS.EST,
            MODEL_CONSTANTS.IRP,
            MODEL_CONSTANTS.VV,
            MODEL_CONSTANTS.CONT,
            MODEL_CONSTANTS.II,
            MODEL_CONSTANTS.MC,
            MODEL_CONSTANTS.RSK,
            MODEL_CONSTANTS.OT,
            MODEL_CONSTANTS.PAD,
            MODEL_CONSTANTS.PCM,
            MODEL_CONSTANTS.RDM,
            MODEL_CONSTANTS.GOV,
            MODEL_CONSTANTS.PLAN,
            MODEL_CONSTANTS.SAM,
            MODEL_CONSTANTS.CAR,
            MODEL_CONSTANTS.MPM
       ]
    }
};

export { PRACTICE_AREA_RELATIONSHIPS, PRACTICES_AREAS_BY_MATURITY_LEVEL };