const MODEL_CONSTANTS = {
    CAR: 'CAR',   //Causal Analysis and Resolution practice area
    CAR_LEVEL_1: 'cd8647fd-ed3c-4091-bfa4-66e63039e51f', //CAR Group ids
    CAR_LEVEL_2: '13a88d46-29b7-4fb7-b3c4-aaaedcb9954b',
    CAR_LEVEL_3: 'db76df43-85c1-4960-bce6-bb6023b6a0c1',
    CAR_LEVEL_4: '5afcad8c-61f6-4e55-adc7-900fd1b51d53',
    CAR_LEVEL_5: '5f4918d0-cff6-45c5-9829-7fc391fd1c42',
    CAR_11: '754e9634-8489-11e9-bc42-526af7764f64', //Causal Analysis and Resolution practices
    CAR_21: '45f259c2-848c-11e9-bc42-526af7764f64',
    CAR_22: '4c8db6aa-848c-11e9-bc42-526af7764f64',
    CAR_31: '5129ecce-848c-11e9-bc42-526af7764f64',
    CAR_32: '55ee9af2-848c-11e9-a159-526af7764f64',
    CAR_33: '59a37046-848c-11e9-bc42-526af7764f64',
    CAR_34: '5d91e5d4-848c-11e9-bc42-526af7764f64',
    CAR_35: '62ca302e-848c-11e9-bc42-526af7764f64',
    CAR_41: '667cd94c-848c-11e9-bc42-526af7764f64',
    CAR_42: '6cc80862-848c-11e9-bc42-526af7764f64',
    CAR_51: '75f0d748-848c-11e9-bc42-526af7764f64',
    CM: 'CM',    //Configuration Mangement practice area
    CM_LEVEL_1: '75b3342a-9d2f-4427-85ec-5a43450e4774',
    CM_LEVEL_2: '8addaa71-7778-4894-adfe-a63d7503cbdfv',
    CM_11: 'be140b96-849e-11e9-bc42-526af7764f64', //Configuration Mangement practices
    CM_21: 'c1e0ef96-849e-11e9-bc42-526af7764f64',
    CM_22: 'c53d501c-849e-11e9-bc42-526af7764f64',
    CM_23: 'c99f1a78-849e-11e9-bc42-526af7764f64',
    CM_24: 'cc79185c-849e-11e9-bc42-526af7764f64',
    CM_25: 'cfb9744e-849e-11e9-a159-526af7764f64',
    CM_26: 'd397a478-849e-11e9-bc42-526af7764f64',
    CONT: 'CONT',    //Continuity practice area
    CONT_LEVEL_1: 'a8d093a3-0b07-4a21-8489-c6e205b68c9c',
    CONT_LEVEL_2: '722b80b4-7f59-481a-9694-7add6c1b8d7d',
    CONT_LEVEL_3: '7ec24063-d54c-4fbf-9b33-d433243c5f38',
    CONT_11: 'f49572ca-84a0-11e9-a159-526af7764f64', //Continuity practices
    CONT_21: 'f8189dfa-84a0-11e9-bc42-526af7764f64',
    CONT_22: 'fbb85ea0-84a0-11e9-a159-526af7764f64',
    CONT_23: '007cb1c0-84a1-11e9-bc42-526af7764f64',
    CONT_31: '097f02c8-84a1-11e9-bc42-526af7764f64',
    CONT_32: '0dee38d8-84a1-11e9-bc42-526af7764f64',
    CONT_33: '11048158-84a1-11e9-bc42-526af7764f64',
    DAR: 'DAR',    // Decision Analysis and Resolution Practice Area
    DAR_LEVEL_1: '97645143-1edb-4a7b-a661-e6755d61a744',
    DAR_LEVEL_2: 'c6a466b4-f9bb-4e3e-82e1-0e2a635f1085',
    DAR_LEVEL_3: '41ea4d24-3246-4a2d-a930-6e5783a6be30',
    DAR_11: '56ea0ce0-84a3-11e9-bc42-526af7764f64', //Decision Analysis and Resolution Practices
    DAR_12: '599a215a-84a3-11e9-bc42-526af7764f64',
    DAR_21: '5ca69e0a-84a3-11e9-bc42-526af7764f64',
    DAR_22: '5fb47d6a-84a3-11e9-bc42-526af7764f64',
    DAR_23: '63048c3a-84a3-11e9-bc42-526af7764f64',
    DAR_24: '66773796-84a3-11e9-bc42-526af7764f64',
    DAR_25: '6a75bfac-84a3-11e9-bc42-526af7764f64',
    DAR_31: '6d59bb24-84a3-11e9-bc42-526af7764f64',
    EST: 'EST',    //Estimation Pratice Area
    EST_LEVEL_1: '3f5319d4-b61b-4a60-af36-5f3f57c7f997',
    EST_LEVEL_2: '4c2c6555-de36-4c27-bd34-fd493fb8f986',
    EST_LEVEL_3: 'b51866df-ef4d-49f1-b0f1-a097bf82e189',
    EST_11: '7e6284ee-84aa-11e9-bc42-526af7764f64', //Estimation practices
    EST_21: '817070e2-84aa-11e9-a159-526af7764f64',
    EST_22: '8445ef86-84aa-11e9-bc42-526af7764f64',
    EST_23: '883714ee-84aa-11e9-bc42-526af7764f64',
    EST_31: '8b24bd00-84aa-11e9-bc42-526af7764f64',
    EST_32: '8e867722-84aa-11e9-bc42-526af7764f64',
    EVSD: 'EVSD',   //EVSD Practice Area
    EVSD_LEVEL_1: 'e876d107-e60c-4674-8529-fa0aa02fab77',
    EVSD_LEVEL_2: '47ccfa21-0c32-4d0f-af94-e253ed561c92',
    EVSD_LEVEL_3: '43c9a973-d602-4065-b87b-f3c287b598bc',
    EVSD_11: '5693e4d1-87a0-4dc6-9bf3-5d23637dd4c8',
    EVSD_12: 'e6733deb-d514-4957-a73a-b4523a34449d',
    EVSD_21: 'ad6efb03-17c9-4864-b459-d9a04e58e26d',
    EVSD_22: '76ba6ca0-9117-47e5-b383-624c481d546c',
    EVSD_31: '37865429-96ac-4fa0-9e07-a90808870bf3',
    EVSD_32: '70d5e6a9-cf5e-4931-9392-3c46ee067b97',
    EVSD_33: '598e7906-3fb9-487f-a481-f3cc40f3eec5',
    GOV: 'GOV',    //Governace Practice Area
    GOV_LEVEL_1: '2fc6dc43-4652-4097-943e-8691550ea083',
    GOV_LEVEL_2: 'feed0109-52a7-4158-bd6a-b9338adb12d7',
    GOV_LEVEL_3: 'ece4dd3b-83ce-47a3-b632-8ba0dfa88dff',
    GOV_LEVEL_4: '5f90155c-48c0-44e4-84fd-01c69bfc1750',
    GOV_11: '5f820cde-84af-11e9-bc42-526af7764f64', //Governance Practices
    GOV_21: '6577997e-84af-11e9-a159-526af7764f64',
    GOV_22: '68e7f112-84af-11e9-bc42-526af7764f64',
    GOV_23: '6cb4f204-84af-11e9-a159-526af7764f64',
    GOV_24: '7055f3ea-84af-11e9-bc42-526af7764f64',
    GOV_31: '74fb197a-84af-11e9-a159-526af7764f64',
    GOV_32: '78b5f544-84af-11e9-bc42-526af7764f64',
    GOV_41: 'f8778c10-84b0-11e9-bc42-526af7764f64',
    II: 'II',    //Implementation Infrastructure Practice Area
    II_LEVEL_1: '7b489ed8-a80b-4ed6-a5b6-64da1057cc51',
    II_LEVEL_2: 'c581c15c-e0c7-4947-9e1f-319f6204a371',
    II_LEVEL_3: 'ab88ce7b-e280-4761-b2d9-0a81b203effe',
    II_11: 'eeb3468c-84b1-11e9-bc42-526af7764f64', //Implementation Infrastruction practices
    II_21: 'f45a137c-84b1-11e9-bc42-526af7764f64',
    II_22: 'f7fd9d0a-84b1-11e9-bc42-526af7764f64',
    II_31: 'facc3776-84b1-11e9-bc42-526af7764f64',
    II_32: 'fe03049c-84b1-11e9-a159-526af7764f64',
    II_33: '00b58ba6-84b2-11e9-bc42-526af7764f64',
    IRP: 'IRP',    //Incident Response and Prevention Practice Area
    IRP_LEVEL_1: '9b902f7a-4654-470b-880f-6c6fcfe7143e',
    IRP_LEVEL_2: '4975e5d6-695d-40cd-b814-6dfeb3edeaa5',
    IRP_LEVEL_3: '27226499-72d8-44c6-9d4b-f56bdd88b6cb',
    IRP_11: '9af8936e-84b4-11e9-bc42-526af7764f64', //Incident Response and Prevention practices
    IRP_21: '9efc56f8-84b4-11e9-bc42-526af7764f64', 
    IRP_22: 'a2451746-84b4-11e9-bc42-526af7764f64',
    IRP_23: 'a57ec786-84b4-11e9-a159-526af7764f64',
    IRP_31: 'a85d0d46-84b4-11e9-bc42-526af7764f64',
    IRP_32: 'ab94da98-84b4-11e9-bc42-526af7764f64',
    MPM: 'MPM',    // Managing Performance and Measurement Practice Area
    MPM_LEVEL_1: '042f6562-831b-45bb-8513-91725918c4eb',
    MPM_LEVEL_2: '88de750a-bc67-4013-9251-2fe9b1953cd7',
    MPM_LEVEL_3: '15f52a34-5b71-4982-a51d-7c9bbdb14c73',
    MPM_LEVEL_4: '2bf6ea0b-9b4c-4f56-abb2-a83c4295e322',
    MPM_LEVEL_5: 'c0bc3b80-c695-46cf-813a-9482aa01b024',
    MPM_11: 'd007a80e-84b6-11e9-bc42-526af7764f64', // Managing Performance and Measurement practices
    MPM_12: 'd5849f6c-84b6-11e9-a159-526af7764f64',
    MPM_21: '988bd14c-84b7-11e9-bc42-526af7764f64',
    MPM_22: 'd8623ba4-84b6-11e9-bc42-526af7764f64',
    MPM_23: 'db4b7aa6-84b6-11e9-bc42-526af7764f64',
    MPM_24: 'dde7ec7c-84b6-11e9-bc42-526af7764f64',
    MPM_25: 'e0ef4582-84b6-11e9-bc42-526af7764f64',
    MPM_26: 'e3ba75de-84b6-11e9-bc42-526af7764f64',
    MPM_31: 'e704ac8c-84b6-11e9-bc42-526af7764f64',
    MPM_32: 'e98efbf6-84b6-11e9-a159-526af7764f64',
    MPM_33: 'ec60a564-84b6-11e9-bc42-526af7764f64',
    MPM_34: 'f03bc88a-84b6-11e9-a159-526af7764f64',
    MPM_35: 'f2cbf322-84b6-11e9-a159-526af7764f64',
    MPM_36: 'fbb09ad8-84b6-11e9-bc42-526af7764f64',
    MPM_41: 'fe8c5a4e-84b6-11e9-bc42-526af7764f64',
    MPM_42: '02499eb2-84b7-11e9-bc42-526af7764f64',
    MPM_43: '0555a254-84b7-11e9-bc42-526af7764f64',
    MPM_44: '07dda238-84b7-11e9-bc42-526af7764f64',
    MPM_45: 'fcc6a04e-84ba-11e9-a159-526af7764f64',
    MPM_51: '0af78fba-84b7-11e9-a159-526af7764f64',
    MPM_52: '109b6694-84b7-11e9-a159-526af7764f64',
    MPM_53: '1551432a-84b7-11e9-bc42-526af7764f64',
    MC: 'MC',    //Monitoring and Control Practice Area
    MC_LEVEL_1: '3640c593-4097-4d09-888a-66e0002e5ad6',
    MC_LEVEL_2: 'cfee9a9e-6ec8-4dfc-9e7b-beb625a8dc74',
    MC_LEVEL_3: 'bc38d6d0-6269-4f5e-a88b-30499ff67558',
    MC_11: '5952dc40-84bd-11e9-a159-526af7764f64', //Monitoring and Control practices
    MC_12: 'b944592a-84be-11e9-bc42-526af7764f64',
    MC_21: '5eeffe1c-84bd-11e9-bc42-526af7764f64',
    MC_22: '6449528c-84bd-11e9-bc42-526af7764f64',
    MC_23: '670ae846-84bd-11e9-bc42-526af7764f64',
    MC_24: '6a1d8304-84bd-11e9-bc42-526af7764f64',
    MC_31: '6d019060-84bd-11e9-bc42-526af7764f64',
    MC_32: 'd336f168-84bd-11e9-bc42-526af7764f64',
    MC_33: 'd65bbdba-84bd-11e9-bc42-526af7764f64',
    MC_34: 'd914ce02-84bd-11e9-bc42-526af7764f64',
    OT: 'OT',    //Organizational Training Practice Area
    OT_LEVEL_1: '88037568-eaa3-40a5-b380-0a78e21f5dc5v',
    OT_LEVEL_2: '590999dc-ead0-4827-b981-ad15b08df08d',
    OT_LEVEL_3: '9e780d91-2d2f-47e9-8acd-223557fe1969',
    OT_11: '90c2c066-84c0-11e9-bc42-526af7764f64', //Organizational Training practices
    OT_21: '96d7e486-84c0-11e9-a159-526af7764f64',
    OT_22: '9a3df8cc-84c0-11e9-a159-526af7764f64',
    OT_31: '9e1714d8-84c0-11e9-bc42-526af7764f64',
    OT_32: 'a154b15a-84c0-11e9-bc42-526af7764f64',
    OT_33: 'a40e464a-84c0-11e9-bc42-526af7764f64',
    OT_34: 'a6e75dfc-84c0-11e9-bc42-526af7764f64',
    OT_35: 'aa45d76c-84c0-11e9-a159-526af7764f64',
    OT_36: 'ad8e21f4-84c0-11e9-bc42-526af7764f64',
    PR: 'PR',    //Peer Review Practice Area
    PR_LEVEL_1: 'a7acfba1-6fde-44eb-ae5d-7e269d719038',
    PR_LEVEL_2: 'cb4c9caa-8e19-47f7-9d02-bbbb510869fa',
    PR_LEVEL_3: 'ddc0c0df-f9fc-4578-b46f-24b9d58841a1v',
    PR_11: '7c6ace22-84c2-11e9-bc42-526af7764f64', //Peer Review practices
    PR_21: '7fa2c450-84c2-11e9-bc42-526af7764f64',
    PR_22: '824e7686-84c2-11e9-a159-526af7764f64',
    PR_23: '853387ce-84c2-11e9-bc42-526af7764f64',
    PR_24: '88f13848-84c2-11e9-bc42-526af7764f64',
    PR_31: '903b92e2-84c2-11e9-bc42-526af7764f64',
    PLAN: 'PLAN',    //Planning practice area
    PLAN_LEVEL_1: '725e1eb8-a4fc-43d8-b9e8-7444cb5d82ee',
    PLAN_LEVEL_2: 'ad018ddf-03a0-40db-87a5-efb6779cdf84',
    PLAN_LEVEL_3: '24bd4c64-3eda-4319-9a49-b021a5912bf6',
    PLAN_LEVEL_4: '989fef35-281b-4e60-b547-e0129d331875',
    PLAN_11: '821621d8-84c6-11e9-bc42-526af7764f64', //Planning practices
    PLAN_12: '84e3eff8-84c6-11e9-bc42-526af7764f64',
    PLAN_21: '87a0ddbe-84c6-11e9-bc42-526af7764f64',
    PLAN_22: '8cd84b3c-84c6-11e9-bc42-526af7764f64',
    PLAN_23: '90b2c4a8-84c6-11e9-bc42-526af7764f64',
    PLAN_24: '938683cc-84c6-11e9-bc42-526af7764f64',
    PLAN_25: '969f44a4-84c6-11e9-bc42-526af7764f64',
    PLAN_26: '99c7e6fe-84c6-11e9-bc42-526af7764f64',
    PLAN_27: '9cf01e5a-84c6-11e9-bc42-526af7764f64',
    PLAN_28: '9fe941ea-84c6-11e9-a159-526af7764f64',
    PLAN_31: 'a239674a-84c6-11e9-bc42-526af7764f64',
    PLAN_32: 'a473f8fe-84c6-11e9-bc42-526af7764f64',
    PLAN_33: 'a978c046-84c6-11e9-bc42-526af7764f64',
    PLAN_34: 'ac922ec0-84c6-11e9-bc42-526af7764f64',
    PLAN_41: 'afe8f41e-84c6-11e9-bc42-526af7764f64',
    PAD: 'PAD',    //Process Asset Development practice area
    PAD_LEVEL_1: '2a58b0db-3734-4649-bc6b-7fd1771ea93f',
    PAD_LEVEL_2: '8685a33f-f100-46c2-bfe0-b45f14d8ff68',
    PAD_LEVEL_3: '952acf19-8402-4399-bf14-8c1cbafdf4ea',
    PAD_11: '972e8320-84cc-11e9-bc42-526af7764f64', //Process Asset Development practices
    PAD_21: '99f9e9aa-84cc-11e9-bc42-526af7764f64',
    PAD_22: '9cbf4e8c-84cc-11e9-bc42-526af7764f64',
    PAD_23: 'a0b62f10-84cc-11e9-bc42-526af7764f64',
    PAD_31: 'a4353dca-84cc-11e9-bc42-526af7764f64',
    PAD_32: 'a8e1b7a4-84cc-11e9-bc42-526af7764f64',
    PAD_33: 'ac5a202e-84cc-11e9-bc42-526af7764f64',
    PAD_34: 'af60b404-84cc-11e9-bc42-526af7764f64',
    //PAD_35: 'b2702ddc-84cc-11e9-bc42-526af7764f64', //V2.0 PAD 3.5 was removed, renumbering 3.6 and 3.7
    PAD_35: 'b5f930de-84cc-11e9-bc42-526af7764f64', //V2.0 PAD 3.6 (renumbered)
    PAD_36: 'b99ae9d0-84cc-11e9-bc42-526af7764f64', //V2.0 PAD 3.7 (renumbered)
    PCM: 'PCM',    //Process Management practice area
    PCM_LEVEL_1: 'd68d9dbd-27d1-4633-b265-0734c12076bf',
    PCM_LEVEL_2: 'edf6a9c5-e94d-4e7f-9976-d29b7f947be7',
    PCM_LEVEL_3: '2dd9e767-8022-458d-a750-45332d9a43a2',
    PCM_LEVEL_4: '7ea65856-5e46-4fba-b522-49e8bd1f33b4',
    PCM_11: '0491c1fa-84cf-11e9-bc42-526af7764f64', //Process Management practices
    PCM_12: '07783a20-84cf-11e9-bc42-526af7764f64',
    PCM_13: '0a1489d2-84cf-11e9-bc42-526af7764f64',
    PCM_21: '0d0ddf08-84cf-11e9-bc42-526af7764f64',
    PCM_22: '103d2292-84cf-11e9-a159-526af7764f64',
    PCM_31: '13228268-84cf-11e9-bc42-526af7764f64',
    PCM_32: '1669cc06-84cf-11e9-bc42-526af7764f64',
    PCM_33: '1a2cb22c-84cf-11e9-bc42-526af7764f64',
    PCM_34: '1d491194-84cf-11e9-bc42-526af7764f64',
    PCM_35: '213e7398-84cf-11e9-bc42-526af7764f64',
    PCM_36: '2419f79a-84cf-11e9-bc42-526af7764f64',
    PCM_41: '278e0fec-84cf-11e9-bc42-526af7764f64',
    PQA: 'PQA',    //Process Quality Assurance practice area
    PQA_LEVEL_1: 'cb1f6581-0e81-4687-9dcf-768a23b8d00f',
    PQA_LEVEL_2: '077622e6-57ae-453f-9084-a8d520cfecd6',
    PQA_LEVEL_3: '2cf9dbe6-d3f9-4029-abfd-24fc707cfb9f',
    PQA_11: '5e31c24c-84d3-11e9-bc42-526af7764f64', //Process Quality Assurance practices
    PQA_21: '611b8eac-84d3-11e9-bc42-526af7764f64',
    PQA_22: '64d53336-84d3-11e9-bc42-526af7764f64',
    PQA_23: '67c41616-84d3-11e9-bc42-526af7764f64',
    PQA_24: '6ae5063e-84d3-11e9-bc42-526af7764f64',
    PQA_31: '6e393d78-84d3-11e9-a159-526af7764f64',
    PI: 'PI',    //Product Integration practice area
    PI_LEVEL_1: 'f52c9062-d897-4344-9682-1afd5996e117',
    PI_LEVEL_2: '017734e1-f4d9-48f9-86ad-46171d99b1a2',
    PI_LEVEL_3: '3022f9c9-6470-4979-ac16-23932cefa2b8',
    PI_11: 'f9a6bb6a-84d3-11e9-bc42-526af7764f64', //Product Integration practices
    PI_21: '00e34dbc-84d4-11e9-bc42-526af7764f64',
    PI_22: '04721cf6-84d4-11e9-bc42-526af7764f64',
    PI_23: '071f45a0-84d4-11e9-bc42-526af7764f64',
    PI_24: '0a4356a4-84d4-11e9-bc42-526af7764f64',
    PI_25: '0dc31daa-84d4-11e9-bc42-526af7764f64',
    PI_26: '1071ef86-84d4-11e9-bc42-526af7764f64',
    PI_31: '13157c62-84d4-11e9-bc42-526af7764f64',
    PI_32: '16327bb6-84d4-11e9-bc42-526af7764f64',
    PI_33: '18b5f124-84d4-11e9-bc42-526af7764f64',
    RDM: 'RDM',    //Requirements Development and Management practice area
    RDM_LEVEL_1: 'ed5e8f30-abc3-4ad3-9d75-44127a870226',
    RDM_LEVEL_2: 'b09bd293-cf4e-4954-bd9d-09d59a0469e4',
    RDM_LEVEL_3: '2f818cae-7523-4fde-8a29-50b786b2ef96',
    RDM_11: 'abe61bac-84d6-11e9-bc42-526af7764f64', //Requirements Development and Management practices
    RDM_21: 'af085070-84d6-11e9-bc42-526af7764f64',
    RDM_22: 'b1ac584e-84d6-11e9-a159-526af7764f64',
    //RDM_23: 'b88d619e-84d6-11e9-bc42-526af7764f64', //V2.0 RDM 2.3 was removed, renumbering 2.4, 2.5, 2.6
    RDM_23: 'bb2aa2f4-84d6-11e9-a159-526af7764f64', //V2.0 RDM 2.4 (renumbered)
    RDM_24: 'bda9e864-84d6-11e9-bc42-526af7764f64', //V2.0 RDM 2.5 (renumbered)
    RDM_25: 'c187e1a2-84d6-11e9-bc42-526af7764f64', //V2.0 RDM 2.6 (renumbered)
    RDM_31: 'c4b88f2a-84d6-11e9-bc42-526af7764f64',
    RDM_32: 'c75f26a8-84d6-11e9-bc42-526af7764f64',
    RDM_33: 'c9fb49fa-84d6-11e9-bc42-526af7764f64',
    RDM_34: 'ccb81768-84d6-11e9-a159-526af7764f64',
    RDM_35: 'cf419b80-84d6-11e9-bc42-526af7764f64',
    RDM_36: 'd22297e6-84d6-11e9-a159-526af7764f64',
    RDM_37: 'd565fccc-84d6-11e9-bc42-526af7764f64',
    RSK: 'RSK',    //Risk and Opportunity Management practice area
    RSK_LEVEL_1: 'ed59cc3f-380a-4da0-8313-8c871dfe14cd',
    RSK_LEVEL_2: 'dfa0b803-3e45-497a-9338-ab7f4471b934',
    RSK_LEVEL_3: 'b59ca88c-833a-4b03-9b8d-76c7501ad3cb',
    RSK_11: '216433e2-84e0-11e9-bc42-526af7764f64', //Risk and Opportunity Management practices
    RSK_21: '25a5f4ea-84e0-11e9-bc42-526af7764f64',
    RSK_22: '288558a4-84e0-11e9-bc42-526af7764f64',
    RSK_31: '2be20d62-84e0-11e9-bc42-526af7764f64',
    RSK_32: '2ecd0450-84e0-11e9-bc42-526af7764f64',
    RSK_33: '320eaf74-84e0-11e9-bc42-526af7764f64',
    RSK_34: '34a24430-84e0-11e9-bc42-526af7764f64',
    RSK_35: '3a11a320-84e0-11e9-bc42-526af7764f64',
    SDM: 'SDM',    //Service Delivery Management practice area
    SDM_LEVEL_1: 'b27469eb-db4e-4b1f-93e2-1e59a4c4885d',
    SDM_LEVEL_2: 'f03d1be1-c162-475c-8506-0452391a981b',
    SDM_LEVEL_3: '7c06d4d6-5f04-44dc-b1dc-182750f6a4b5',
    SDM_11: '26c72d6a-84e2-11e9-bc42-526af7764f64', //Service Delivery Management practices
    SDM_21: '29ec086c-84e2-11e9-bc42-526af7764f64',
    SDM_22: '2ca08b14-84e2-11e9-bc42-526af7764f64',
    SDM_23: '2f1c31ae-84e2-11e9-bc42-526af7764f64',
    SDM_24: '31c9e356-84e2-11e9-bc42-526af7764f64',
    SDM_25: '34752d54-84e2-11e9-bc42-526af7764f64',
    SDM_26: '374c683a-84e2-11e9-bc42-526af7764f64',
    SDM_31: '3a541cd0-84e2-11e9-bc42-526af7764f64',
    STSM: 'STSM',    //Strategic Service Management practice area
    STSM_LEVEL_1: '55c91e3c-a3ff-43cd-bf5b-fa6fb77833f9',
    STSM_LEVEL_2: 'da9e0cfe-74d1-44f7-9b3e-f08d8b4fa7b0',
    STSM_LEVEL_3: '907192be-abea-42af-9f7b-962edfcb9195',
    STSM_11: 'd22b81d2-84e3-11e9-bc42-526af7764f64', //Strategic Service Management practices
    STSM_21: 'da82f4c8-84e3-11e9-bc42-526af7764f64',
    STSM_22: 'dda0ae0c-84e3-11e9-bc42-526af7764f64',
    STSM_23: 'e036ac48-84e3-11e9-a159-526af7764f64',
    STSM_31: 'e38c8444-84e3-11e9-bc42-526af7764f64',
    SAM: 'SAM',    //Supplier Agreement Management practice area
    SAM_LEVEL_1: '00cdb846-3499-427f-b1c8-dc4ef37c5c91',
    SAM_LEVEL_2: '964691c7-6fe5-48ae-88bb-4fb807d58dc3',
    SAM_LEVEL_3: '2f28e192-5df8-4aaa-956c-c37166ea00d3',
    SAM_LEVEL_4: 'ba6f9dca-5241-47ca-b811-3ea977901157',
    SAM_11: '0c230faa-84e8-11e9-bc42-526af7764f64', //Supplier Agreement Management practices
    SAM_12: '1037aede-84e8-11e9-bc42-526af7764f64',
    SAM_13: '12ecbaf2-84e8-11e9-bc42-526af7764f64',
    SAM_14: 'f28dg29d-84e8-11e9-bc42-526af7764f64',
    SAM_21: '170f8588-84e8-11e9-bc42-526af7764f64',
    SAM_22: '1a445a76-84e8-11e9-a159-526af7764f64',
    SAM_23: '1cd06fc8-84e8-11e9-bc42-526af7764f64',
    SAM_24: '20118622-84e8-11e9-bc42-526af7764f64',
    SAM_25: '1gda2g0d-84e8-11e9-bc42-526af7764f64',
    SAM_31: '231278cc-84e8-11e9-bc42-526af7764f64',
    SAM_32: '259db048-84e8-11e9-bc42-526af7764f64',
    SAM_41: '28f98cee-84e8-11e9-a159-526af7764f64',
    SSS: 'SSS',    //Supplier Source Selection practice area
    SSS_LEVEL_1: 'c6228f9d-0473-40eb-8054-d53277d216ad',
    SSS_LEVEL_2: '1c88763e-e66d-47e4-89c1-1b3bc1eae686',
    SSS_LEVEL_3: '2140f908-cb33-40f1-801e-42c1d5045392',
    SSS_11: 'a4ccf662-84e8-11e9-bc42-526af7764f64', //Supplier Source Select practices
    SSS_12: 'aa68ecd4-84e8-11e9-bc42-526af7764f64',
    SSS_13: 'ad40911e-84e8-11e9-bc42-526af7764f64',
    SSS_21: 'afbecd48-84e8-11e9-bc42-526af7764f64',
    SSS_22: 'b2790b34-84e8-11e9-bc42-526af7764f64',
    SSS_23: 'b4d83f76-84e8-11e9-bc42-526af7764f64',
    SSS_31: 'b7ad9480-84e8-11e9-bc42-526af7764f64',
    TS: 'TS',    //Technical Solution practice area
    TS_LEVEL_1: '5575f222-9d52-4c5f-962c-f40a827b6b1a',
    TS_LEVEL_2: '9ab787da-9ab8-4aaa-9f46-10a9b0d313b4',
    TS_LEVEL_3: '0c264d2e-fce7-4928-8f3e-4812f7369628',
    TS_11: '63ef3d7e-84ea-11e9-a159-526af7764f64', //Technical Solution practices
    TS_21: '66d7dea6-84ea-11e9-bc42-526af7764f64',
    TS_22: '0cd2d1cf-425d-4e77-b099-4e0b03ae555a',
    TS_23: '69c7f8c6-84ea-11e9-bc42-526af7764f64',
    TS_31: '6f2cefba-84ea-11e9-bc42-526af7764f64',
    TS_32: '71a3be0e-84ea-11e9-a159-526af7764f64',
    TS_33: '74a5b526-84ea-11e9-bc42-526af7764f64',
    TS_34: '772d9200-84ea-11e9-bc42-526af7764f64',
    TS_35: '7a2eb880-84ea-11e9-bc42-526af7764f64',
    TS_36: '7e963b1e-84ea-11e9-bc42-526af7764f64',
    VV: 'VV',    //Verification and Validation practice area
    VV_LEVEL_1: '0f3620b3-707c-4fa6-8c29-d855de1f3d66',
    VV_LEVEL_2: '23ada88d-ac50-40d5-8828-2a96a5214eb2',
    VV_LEVEL_3: 'c5cd1c61-db60-4d7d-a6bd-43a00c0fc469',
    VV_11: '83e79f18-84ea-11e9-bc42-526af7764f64', //Verification and Validation practices
    VV_12: 'ee4d9176-84ec-11e9-bc42-526af7764f64',
    VV_21: 'f22d9796-84ec-11e9-a159-526af7764f64',
    VV_22: 'f50ba91c-84ec-11e9-bc42-526af7764f64',
    VV_23: 'f748b63e-84ec-11e9-bc42-526af7764f64',
    VV_31: 'fc4c350c-84ec-11e9-bc42-526af7764f64',
    VV_32: 'ffbc406a-84ec-11e9-bc42-526af7764f64',

    DEV_LEVEL_2: 'Development Level 2', //Predefined view strings
    DEV_LEVEL_3: 'Development Level 3',
    DEV_LEVEL_4: 'Development Level 4',
    DEV_LEVEL_5: 'Development Level 5',
    SVCS_LEVEL_2: 'Services Level 2',
    SVCS_LEVEL_3: 'Services Level 3',
    SVCS_LEVEL_4: 'Services Level 4',
    SVCS_LEVEL_5: 'Services Level 5',
};

export { MODEL_CONSTANTS }