import React from 'react';
import { FormControl } from 'react-bootstrap';

function InterviewSessionTableItem(props) {
    const chkBoxId = props.interviewSession.id + 'chkbox';
    const intervieweeKey = props.interviewSession.id + 'intervieweeInput';
    const hoursKey = props.interviewSession.id + 'hoursInput';
    const minutesKey = props.interviewSession.id + 'minutesInput';

    const errorStyle = {
        border: "1px solid red"
    };
    const errorTitle = "An interview session must have a name";
    const defaultStyle = {}

    const getHours = () => {
        let parts = props.interviewSession.hours.toString().split('.');
        return Number(parts[0]);
    }
    const hoursValue = getHours();

    const getMinutesFraction = () => {
        let minutesFraction = Number(props.interviewSession.hours.toString().split('.')[1]);
        if(isNaN(minutesFraction)){
            return 0;
        }
        else{
            return minutesFraction;
        }
    }

    const getMinutes = () => {
        let minutesFraction = getMinutesFraction();
        let minutes = "zero";
        switch(minutesFraction){
            case 0:
                minutes = "zero";
                break;
            case 25:
                minutes = "fifteen";
                break;
            case 5:
                minutes =  "thirty";
                break;
            case 75:
                minutes = "fortyfive";
                break;
            default:
                break;
        }
        return minutes;
    }
    const minutesValue = getMinutes();

    const onHoursChanged = (value) => {
        let totalHours = Number(value + "." + getMinutesFraction());
        props.updateState(totalHours, "hours", props.interviewSession.id)
    }

    const onMinutesChanged = (value) => {
        let hoursDecimal = 0;
        switch(value){
            case "zero":
                hoursDecimal = 0;
                break;
            case "fifteen":
                hoursDecimal = 0.25
                break;
            case "thirty":
                hoursDecimal = 0.5
                break;
            case "fortyfive":
                hoursDecimal = 0.75
                break;
            default: break;
        }
        let totalHours = Number(hoursValue) + hoursDecimal;
        props.updateState(totalHours, "hours", props.interviewSession.id)
    }

    return (
        <React.Fragment>
            <td key={chkBoxId} style={{ width: '5%', textAlign: 'center' }} >
                <input type="checkbox" onChange={(e) => props.updateSelected(e, props.interviewSession.id)} disabled={props.modalOpen}
                    checked={props.interviewSession.selected} />
            </td>
            <td key={intervieweeKey} style={{ width: '55%', textAlign: 'center', color: 'dimgray' }}>
                <FormControl type="text" name="interviewee" onChange={(e) => props.updateState(e.target.value, e.target.name, props.interviewSession.id)}
                    value={props.interviewSession.interviewee} autoFocus
                    style={props.interviewSession.interviewee.length === 0 ? errorStyle : defaultStyle}
                    title={props.interviewSession.interviewee.length === 0 ? errorTitle : ""} />
            </td>
            <td key={hoursKey} style={{ width: '20%', textAlign: 'center', color: 'dimgray' }}>
                <FormControl
                    as="select"
                    value={hoursValue}
                    onChange={(e) => onHoursChanged(e.target.value)} >
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                </FormControl>
                
            </td>
            <td key={minutesKey} style={{ width: '20%', textAlign: 'center', color: 'dimgray' }}>
                <FormControl
                    as="select"
                    value={minutesValue}
                    onChange={(e) => onMinutesChanged(e.target.value)} >
                    <option value='zero'>00</option>
                    <option value='fifteen'>15</option>
                    <option value='thirty'>30</option>
                    <option value='fortyfive'>45</option>
                </FormControl>
            </td>
        </React.Fragment>
    )
}

export default InterviewSessionTableItem;