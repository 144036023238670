import React from 'react';
import { PRACTICE_AREAS } from '../model/modelcontent';
import {Row, Col} from 'react-bootstrap';
import { getCurrentDateTime } from '../utils/helpers';

const cellStyle = {
    border: "1px solid black",
    textAlign: "center",
};

const practiceAreaCellStyle = {
    border: "1px solid black",
    textAlign: "center",
    backgroundColor:'rgb(0,168,168)'
};

const cellStyleLow = {
    border: "1px solid black",
    textAlign: "center",
    backgroundColor: "lightgreen"
};

const cellStyleMed = {
    border: "1px solid black",
    textAlign: "center",
    backgroundColor: "lightyellow"
};

const cellStyleHigh = {
    border: "1px solid black",
    textAlign: "center",
    backgroundColor: "tomato"
};

const cellStyleNotImp = {
    border: "1px solid black",
    textAlign: "center",
    backgroundColor: "pink"
};

const cellStyleNotEx = {
    border: "1px solid black",
    textAlign: "center",
    backgroundColor: "lightgray"
};

function getTotal(percentData){
    return percentData.lowRisk + percentData.mediumRisk + percentData.highRisk +
        percentData.notImplemented + percentData.notExamined;
}

function getPercent(actual, total){
    return (actual / total * 100).toFixed(2);
}

function generateDataRows(reportData){
    const keys = Object.keys(reportData);
    let tableRows = [];
    let totals = {
        lowRisk:0,
        mediumRisk:0,
        highRisk:0,
        notImplemented:0,
        notExamined:0
    };
    for(let i = 0; i < keys.length; i++){
        //create a row for each practice and its data
        let rowCells = [];
        let practiceFullName = PRACTICE_AREAS[keys[i]].Name;
        let practiceName = practiceFullName.substring(0, practiceFullName.indexOf(" ("));
        let total = getTotal(reportData[keys[i]]);
        rowCells.push(<td style={practiceAreaCellStyle}><b>{keys[i]}</b></td>);
        rowCells.push(<td style={cellStyle}>{practiceName}</td>);
        rowCells.push(<td style={cellStyle}>{total}</td>);
        rowCells.push(<td style={cellStyleLow}>{reportData[keys[i]].lowRisk} ({getPercent(reportData[keys[i]].lowRisk, total)}%)</td>);
        rowCells.push(<td style={cellStyleMed}>{reportData[keys[i]].mediumRisk} ({getPercent(reportData[keys[i]].mediumRisk, total)}%)</td>);
        rowCells.push(<td style={cellStyleHigh}>{reportData[keys[i]].highRisk} ({getPercent(reportData[keys[i]].highRisk, total)}%)</td>);
        rowCells.push(<td style={cellStyleNotImp}>{reportData[keys[i]].notImplemented} ({getPercent(reportData[keys[i]].notImplemented, total)}%)</td>);
        rowCells.push(<td style={cellStyleNotEx}>{reportData[keys[i]].notExamined} ({getPercent(reportData[keys[i]].notExamined, total)}%)</td>);
        tableRows.push(<tr>{rowCells}</tr>);

        //add the practice data to the totals
        totals.lowRisk = totals.lowRisk + reportData[keys[i]].lowRisk;
        totals.mediumRisk = totals.mediumRisk + reportData[keys[i]].mediumRisk;
        totals.highRisk = totals.highRisk + reportData[keys[i]].highRisk;
        totals.notImplemented = totals.notImplemented + reportData[keys[i]].notImplemented;
        totals.notExamined = totals.notExamined + reportData[keys[i]].notExamined;
    }

    //create a row for the data totals
    let totalsCells = [];
    let total = getTotal(totals);
    totalsCells.push(<td></td>)
    totalsCells.push(<td style={{textAlign: "right"}}><b>Totals:</b></td>)
    totalsCells.push(<td style={cellStyle}>{total}</td>)
    totalsCells.push(<td style={cellStyleLow}>{totals.lowRisk} ({getPercent(totals.lowRisk, total)}%)</td>)
    totalsCells.push(<td style={cellStyleMed}>{totals.mediumRisk} ({getPercent(totals.mediumRisk, total)}%)</td>)
    totalsCells.push(<td style={cellStyleHigh}>{totals.highRisk} ({getPercent(totals.highRisk, total)}%)</td>)
    totalsCells.push(<td style={cellStyleNotImp}>{totals.notImplemented} ({getPercent(totals.notImplemented, total)}%)</td>)
    totalsCells.push(<td style={cellStyleNotEx}>{totals.notExamined} ({getPercent(totals.notExamined, total)}%)</td>)
    tableRows.push(totalsCells);

    return tableRows;
}

function getProjectSubTitle(projects){
    let projectSubTitle = "";
    for(let i = 0; i < projects.length; i++){
        if(i !== projects.length - 1){
            const nextProject = `${projects[i].name}, `;
            projectSubTitle = projectSubTitle.concat(nextProject);
        } else {
            projectSubTitle = projectSubTitle.concat(projects[i].name);
        }
    }
    return projectSubTitle;
}

const CompliancePercentReportView = ({projects, reportData, practiceRanks}) => {
    const tableHeadings =  (
        <tr>
            <th></th>
            <th></th>
            <th style={{textAlign: "center"}}>Total</th>
            <th style={cellStyleLow}>Low Risk</th>
            <th style={cellStyleMed}>Medium Risk</th>
            <th style={cellStyleHigh}>High Risk</th>
            <th style={cellStyleNotImp}>Not Implemented</th>
            <th style={cellStyleNotEx}>Not Examined</th>
        </tr>
    );
    
    const projectSubTitle = getProjectSubTitle(projects);
    const tableRows = generateDataRows(reportData, practiceRanks);
    let dateTime = getCurrentDateTime();
    return (
        
        <React.Fragment>
            <Row style={{height:'auto', marginBottom:'-20px'}}>
                <Col>
                    <p style={{textAlign:'right', fontSize:'18px'}}><b>Created for Project(s):</b></p>
                </Col>
                <Col>
                    <p style={{textAlign:"left", fontSize:'18px'}}>{projectSubTitle}</p>
                </Col>
            </Row>
            <Row style={{height:'20px', marginBottom:'8px'}}>
            <Col>
                    <p style={{textAlign: "right"}}><small><b>Date/Time Generated:</b></small></p>
                </Col>
                <Col>
                    <p style={{textAlign: "left"}}><small>{dateTime}</small></p>
                </Col>
            </Row>            
            <hr class='my-2' style={{height:'1px', borderWidth: '0', color: 'dimgray', backgroundColor: 'dimgray'}}/>  
            <table style={{width: "100%", borderCollapse: "separate", borderSpacing:"1px"}}>
                {tableHeadings}
                {tableRows}
            </table>
        </React.Fragment>
    );
}

export default CompliancePercentReportView;