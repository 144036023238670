import React, {useState} from 'react';
import ToggleButton from 'react-toggle-button';

//An entry in the organizations table
function OrganizationItem(props){
    //Assigning unique keys for each data column so
    // that react can perform precise rerendering

    const [isActive, setIsActive] = useState(props.org.isActive);

    const toggleActive = (e) => {
        if(props.updateIsActive(e, props.org.id)){
            setIsActive(!e);
        }
    }

    const deleteChkboxKey = props.org.id + "dltChk";
    const nameKey = props.org.id + 'nme';
    const addressKey = props.org.id + "addr";
    const contactInfoKey = props.org.id + "ctnfo";
    const activeKey = props.org.id + "isActive";
    const editKey = props.org.id + "edit";
    return(
        <React.Fragment>
            <td style={{ width: '5%', textAlign: 'center' }} key={deleteChkboxKey}>
                <input type="checkbox" 
                    onChange={(e) => props.updateSelected(e, props.org.id)}
                    checked={props.org.selected}
                    disabled={!isActive}
                />
            </td>
            <td style={{ width: '45%', textAlign: 'left', color:'dimgray'}} key={nameKey}>
                <text style={{flexWrap:'nowrap'}}>{props.org.name}</text>
            </td>
            <td style={{ width: '50%', textAlign: 'center' }} key={activeKey} align='center'><ToggleButton value={isActive} inactiveLabel="No" activeLabel="Yes" onToggle={(e) => toggleActive(e)}/></td>
        </React.Fragment>
    );
}
export default OrganizationItem;