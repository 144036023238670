import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Form, FormControl, Modal, FormLabel, Spinner } from 'react-bootstrap';
import { modelContentUtils } from '../utils/helpers';
import useArray from '../hooks/useArray';
import LoadingComponent from '../presenters/LoadingComponent';
import ReportFragment from '../presenters/ReportFragment';
import { PRACTICE_AREA_RELATIONSHIPS } from '../model/practicearearelationships';
import { PRACTICES } from '../model/practices';
import { Constants, getDefaultAPIHeaders, getNodeAPIPath } from '../utils/helpers';
import { API } from 'aws-amplify';
import { PRACTICE_AREAS } from '../model/practiceareas';

async function fetchData(setDataLoading, appraisals_FROM, appraisals_TO) {
    try {
        const data = await API.get(Constants.API_PATH, Constants.APPRAISAL_PATH, await getDefaultAPIHeaders());
        const formattedAppraisals = data.map(a => {
            return {
                id: a.appraisal_id,
                name: a.appraisal_name,
                view: a.appraisal_view
            }
        });
        if (formattedAppraisals.length > 0) {
            appraisals_FROM.setValue(formattedAppraisals);
        }
        else {
            console.log('No appraisals were found.');
        }
    } 
    catch (e) {
        console.log(e);
    }

    setDataLoading(false);
}

const CopyAppraisalDataPracticeAreaModal = (props) => {
    const [dataLoading, setDataLoading] = useState(true);

    //from
    const [selectedAppraisalID_FROM, setSelectedAppraisalID_FROM] = useState("false");
    const [selectedProjectID_FROM, setSelectedProjectID_FROM] = useState("false");
    const [selectedPracticeAreaID_FROM, setSelectedPracticeAreaID_FROM] = useState("false");
    const [selectedAppraisalLevel_FROM, setSelectedAppraisalLevel_FROM] = useState("false");
    const [selectedAppraisalName_FROM, setSelectedAppraisalName_FROM] = useState("");
    const [selectedAppraisalType_FROM, setSelectedAppraisalType_FROM] = useState("");
    const appraisals_FROM = useArray([]);
    const projects_FROM = useArray([]);
    const practiceAreas_FROM = useArray([]);

    //to
    const [selectedAppraisalID_TO, setSelectedAppraisalID_TO] = useState("false");
    const [selectedProjectID_TO, setSelectedProjectID_TO] = useState("false");
    const [selectedAppraisalLevel_TO, setSelectedAppraisalLevel_TO] = useState("false");
    const appraisals_TO = useArray([]);
    const projects_TO = useArray([]);

    //modal states
    //0 = awaiting 1st appraisal selection
    //1 = awaiting 1st project selection
    //2 = awaiting practice area selection
    //3 = awaiting 2nd appraisal selection
    //4 = awaiting 2nd project selection
    const [modalOptionsState, setModalOptionsState] = useState(0);

    //progress modal
    const [showCopyingModal, setShowCopyingModal] = useState(false);
    const [areItemsCopying, setAreItemsCopying] = useState(false);
    const [itemCopyType, setItemCopyType] = useState("");
    const [itemCopyStatus, setItemCopyStatus] = useState("");

    const defaultSelectionOption = " -- select an option -- ";

    useEffect(() => {
        if (props.showModal) {
            setModalOptionsState(0);

            setSelectedAppraisalID_FROM('');
            setSelectedProjectID_FROM('');
            setSelectedPracticeAreaID_FROM('');

            setSelectedAppraisalID_TO('');
            setSelectedProjectID_TO('');
            
            setDataLoading(true);
            const getData = () => fetchData(setDataLoading, appraisals_FROM);
            getData();
        }
    }, [props.showModal]);


    useEffect(() => {
        if(selectedAppraisalID_FROM !== ""){
            setModalOptionsState(1);
        }
    }, [selectedAppraisalID_FROM]);

    useEffect(() => {
        if(selectedProjectID_FROM !== ""){
            setModalOptionsState(2);
        }
    }, [selectedProjectID_FROM]);

    useEffect(() => {
        if(selectedPracticeAreaID_FROM !== ""){
            setModalOptionsState(3);
        }
    }, [selectedPracticeAreaID_FROM]);
    
    useEffect(() => {
        if(selectedAppraisalID_TO !== ""){
            setModalOptionsState(4);
        }
    }, [selectedAppraisalID_TO]);

    useEffect(() => {
        if(selectedProjectID_TO !== ""){
            setModalOptionsState(5);
        }
    }, [selectedProjectID_TO]);

    //go from state 0 to 1
    const handleAppraisalChanged_FROM = async (selectedAppraisal) => {

        //reset previous selections
        setModalOptionsState(0);
        projects_FROM.setValue([]);
        practiceAreas_FROM.setValue([]);
        setSelectedAppraisalID_TO('');
        setSelectedProjectID_FROM('');
        setSelectedPracticeAreaID_FROM('');

        if(selectedAppraisal === ""){
            return;
        }

        try {
            let currentAppraisal = appraisals_FROM.value.filter(x => x.id === selectedAppraisal)[0];
            let currentAppraisalType = currentAppraisal.view.charAt(0);
            let fromAppraisalLevel = currentAppraisal.view.charAt(currentAppraisal.view.length - 1)
            setSelectedAppraisalName_FROM(currentAppraisal.name);
            setSelectedAppraisalType_FROM(currentAppraisalType);
            setSelectedAppraisalLevel_FROM(fromAppraisalLevel);

            //get projects
            const currentAppraisalData = await API.get(Constants.API_PATH, `${Constants.APPRAISAL_PATH}/${selectedAppraisal}`, await getDefaultAPIHeaders());
            const formattedProjectData = currentAppraisalData.projects
                .filter(x => x.project_name !== "Organizational Roll-up")
                .map(p => {
                    return {
                        id: p.project_id,
                        name: p.project_name,
                        practiceAreas: p.practice_areas
                    }
            });

            //set new states
            projects_FROM.setValue(formattedProjectData);
            setSelectedAppraisalID_FROM(selectedAppraisal);
        }
        catch (e) {
            console.log(e);
        }
    }

    //go from state 1 to 2
    const handleProjectChanged_FROM = async (projectID) => {

        //reset previous selections
        setModalOptionsState(1);
        setSelectedPracticeAreaID_FROM('');
        practiceAreas_FROM.setValue([]);

        if(projectID === ""){
            return;
        }

        let selectedProject = projects_FROM.value.find(p => p.id === projectID);
        if(selectedProject){
            practiceAreas_FROM.setValue(selectedProject.practiceAreas);
            setSelectedProjectID_FROM(projectID);
        }
    }

    //go from state 2 to 3
    const handlePracticeAreaChanged_FROM = async (practiceArea) => {

        //reset previous selections
        setModalOptionsState(2);

        if(practiceArea === ""){
            return;
        }
        
        //filter 'copy to' appraisals list based on PA selected
        let devExclusive = PRACTICE_AREAS[practiceArea].DevExclusive;
        let svcExclusive = PRACTICE_AREAS[practiceArea].SvcExclusive;

        //if dev or svc specific PA selected, only allow dev or svc appraisals
        if(devExclusive || svcExclusive){
            let possibleAppraisals = [];
            for(let i = 0; i < appraisals_FROM.value.length; i++){
                if(appraisals_FROM.value[i].view.substring(0,1) === selectedAppraisalType_FROM){
                    possibleAppraisals.push(appraisals_FROM.value[i]);
                }
            }
            appraisals_TO.setValue(possibleAppraisals);
        }
        //if PA is non-specific, allow copy to all appraisals
        else{
            appraisals_TO.setValue(appraisals_FROM.value);
        }

        setSelectedPracticeAreaID_FROM(practiceArea);
    }

    //go from state 3 to 4
    const handleAppraisalChanged_TO = async (selectedAppraisal) => {

        //reset previous selections
        setModalOptionsState(3);
        projects_TO.setValue([]);

        if(selectedAppraisal === ""){
            return;
        }

        try {

            let currentAppraisal = appraisals_FROM.value.filter(x => x.id === selectedAppraisal)[0];
            let toAppraisalLevel = currentAppraisal.view.charAt(currentAppraisal.view.length - 1)
            setSelectedAppraisalLevel_TO(toAppraisalLevel);

            const currentAppraisalData = await API.get(Constants.API_PATH, `${Constants.APPRAISAL_PATH}/${selectedAppraisal}`, await getDefaultAPIHeaders());
            
            //filter out org rollup
            let formattedProjectData = currentAppraisalData.projects.filter(x => x.project_name !== "Organizational Roll-up");
            
            //if same appraisal selected, filter out same project names
            if(currentAppraisalData.appraisal_name === selectedAppraisalName_FROM){
                
                const currentProjectName = currentAppraisalData.projects.filter(x => x.project_id === selectedProjectID_FROM)[0].project_name;
                formattedProjectData =  formattedProjectData.filter(x => x.project_name !== currentProjectName);
            }

            formattedProjectData = formattedProjectData.map(p => {
                    return {
                        id: p.project_id,
                        name: p.project_name,
                        practiceAreas: p.practice_areas
                    }
            });
            
            //only include projects that have the selected 'FROM' PA
            let usableProjects = [];
            for(let i = 0; i < formattedProjectData.length; i++){
                let currentPAs = formattedProjectData[i].practiceAreas;
                for(let j = 0; j < currentPAs.length; j++){
                    if(currentPAs[j] === selectedPracticeAreaID_FROM)
                        usableProjects.push(formattedProjectData[i]);
                }
            }

            //set new states
            projects_TO.setValue(usableProjects);
            setSelectedAppraisalID_TO(selectedAppraisal);
            setSelectedProjectID_TO('');
        }
        catch (e) {
            console.log(e);
        }
    }
    
    //go from state 4 to 5
    const handleProjectChanged_TO = async (projectID) => {

        //reset previous selections
        setModalOptionsState(4);

        if(projectID === ""){
            return;
        }

        let selectedProject = projects_TO.value.find(p => p.id === projectID);
        if(selectedProject){
            setSelectedProjectID_TO(projectID);
        }
    }

    const getAppraisalOptionsList_FROM = () => {
        let options = [];
        options.push(<option selected value="">{defaultSelectionOption}</option>);
        for(let i = 0; i < appraisals_FROM.value.length; i++){
            options.push(<option value={appraisals_FROM.value[i].id}>{appraisals_FROM.value[i].name}</option>)
        }
        return (
            <React.Fragment>
                {options}
            </React.Fragment>
        )
    }

    const getProjectOptionsList_FROM = () => {
        let options = [];
        options.push(<option selected value="">{defaultSelectionOption}</option>);
        if(modalOptionsState >= 1){
            for(let i = 0; i < projects_FROM.value.length; i++){
                options.push(<option value={projects_FROM.value[i].id}>{projects_FROM.value[i].name}</option>)
            }
        }
        return (
            <React.Fragment>
                {options}
            </React.Fragment>
        )
    }

    const getPracticeAreasOptionsList_FROM = () => {
        let options = [];
        options.push(<option selected value="">{defaultSelectionOption}</option>);
        if(modalOptionsState >= 2){
            for(let i = 0; i < practiceAreas_FROM.value.length; i++){
                options.push(<option value={practiceAreas_FROM.value[i]}>{practiceAreas_FROM.value[i]}</option>)
            }
        }
        return (
            <React.Fragment>
                {options}
            </React.Fragment>
        )
    }

    const getAppraisalOptionsList_TO = () => {
        let options = [];
        options.push(<option selected value="">{defaultSelectionOption}</option>);
        if(modalOptionsState >= 3){
            for(let i = 0; i < appraisals_TO.value.length; i++){
                options.push(<option value={appraisals_TO.value[i].id}>{appraisals_TO.value[i].name}</option>)
            }
        }
        return (
            <React.Fragment>
                {options}
            </React.Fragment>
        )
    }

    const getProjectOptionsList_TO = () => {
        let options = [];
        options.push(<option selected value="">{defaultSelectionOption}</option>);
        if(modalOptionsState >= 4){
            for(let i = 0; i < projects_TO.value.length; i++){
                options.push(<option value={projects_TO.value[i].id}>{projects_TO.value[i].name}</option>)
            }
        }
        return (
            <React.Fragment>
                {options}
            </React.Fragment>
        )
    }
    
    const executeClick = async () => {

        setShowCopyingModal(true);
        setAreItemsCopying(true);

        //get practices based on highest appraisal level
        let highestAppraisalLevel = (selectedAppraisalLevel_FROM > selectedAppraisalLevel_TO) ? selectedAppraisalLevel_TO : selectedAppraisalLevel_FROM;
        let practiceList = PRACTICE_AREA_RELATIONSHIPS[selectedPracticeAreaID_FROM].filter(x => x.Level <= highestAppraisalLevel);
        
        for(let i = 0; i < practiceList.length; i++){

            let currentNodeName = PRACTICES[practiceList[i].Id].Abbreviation;
            setItemCopyType(currentNodeName);

            if(props.options.Evidence){
                await copyEvidence(practiceList[i].Id);
            }
            
            if(props.options.Interviews){
                await copyInterviews(practiceList[i].Id);
            }
    
            if(props.options.Notes 
            || props.options.Strengths
            || props.options.Weaknesses
            || props.options.IOs){
                await copyFindings(practiceList[i].Id);
            }
        }

        setItemCopyType("Copy Complete.");
        setItemCopyStatus("");
        setAreItemsCopying(false);
        
    }

    const copyEvidence = async (currentNodeId) => {

        setItemCopyStatus("Copying Evidence Data...");

        //get the FROM evidence
        let nodeInfo = {
            appraisalId: selectedAppraisalID_FROM,
            projectId: selectedProjectID_FROM,
            nodeId: currentNodeId
        }
        let evidenceData = await API.get(Constants.API_PATH, getNodeAPIPath(nodeInfo, Constants.APPRAISAL_EVIDENCE_PATH), await getDefaultAPIHeaders());

        for(let i = 0; i < evidenceData.length; i++){

            //create the TO evidence for every FROM evidence
            const body = {
                appraisal_id: selectedAppraisalID_TO,
                project_id: selectedProjectID_TO,
                node_id: currentNodeId,
                evidence_fileName: evidenceData[i].evidence_fileName,
                evidence_path: evidenceData[i].evidence_path,
                evidence_notes: evidenceData[i].evidence_notes,
                evidence_state: evidenceData[i].evidence_state,
                isHyperlink: evidenceData[i].isHyperlink,
            };

            try {
                await API.post(Constants.API_PATH, Constants.APPRAISAL_EVIDENCE_PATH, await getDefaultAPIHeaders(body));
            }
            catch (e) {
                console.log('evidence post failure' + e);
            }
        }
    }

    const copyInterviews = async (currentNodeId) => {

        setItemCopyStatus("Copying Interview Data...");

        //get the FROM interviews
        let nodeInfo = {
            appraisalId: selectedAppraisalID_FROM,
            projectId: selectedProjectID_FROM,
            nodeId: currentNodeId
        }
        const interviewData = await API.get(Constants.API_PATH, getNodeAPIPath(nodeInfo, Constants.INTERVIEW_QUESTIONS_PATH), await getDefaultAPIHeaders());

        for(let i = 0; i < interviewData.length; i++){

            //create the TO interviews for every FROM interview
            const body = {
                appraisal_id: selectedAppraisalID_TO,
                project_id: selectedProjectID_TO,
                node_id: currentNodeId,
                question: interviewData[i].question,
                sessions: interviewData[i].sessions
            }
            try {
                await API.post(Constants.API_PATH, Constants.INTERVIEW_QUESTIONS_PATH, await getDefaultAPIHeaders(body));
            }
            catch (e) {
                console.log("add interview question failure" + e);
            }
        }
    }

    const copyFindings = async (currentNodeId) => {

        setItemCopyStatus("Copying Findings Data...");

        //get the FROM findings
        let nodeInfo = {
            appraisalId: selectedAppraisalID_FROM,
            projectId: selectedProjectID_FROM,
            nodeId: currentNodeId
        }
        const findingsData = await API.get(Constants.API_PATH, getNodeAPIPath(nodeInfo, Constants.APPRAISAL_ITEM_PATH), await getDefaultAPIHeaders());

        for(let i = 0; i < findingsData.length; i++){

            //filter any findings whose options are not chosen
            if((findingsData[i].item_type === "notes" && !props.options.Notes)
            || (findingsData[i].item_type === "strengths" && !props.options.Strengths)
            || (findingsData[i].item_type === "weaknesses" && !props.options.Weaknesses)
            || (findingsData[i].item_type === "improvement_opportunities" && !props.options.IOs)) {
                continue;
            }
            
            const body = {
                appraisal_id: selectedAppraisalID_TO,
                project_id: selectedProjectID_TO,
                node_id: currentNodeId,
                item_type: findingsData[i].item_type,
                item_data: findingsData[i].item_data,
                revision_number: 0
            }
            try {
                await API.put(Constants.API_PATH, Constants.APPRAISAL_ITEM_PATH, await getDefaultAPIHeaders(body));
            }
            catch (e) {
                console.log("add findings failure" + e);
            }  
        }
    }

    const getCopyingButtonText = () => {
        return !areItemsCopying ? "Close" : " Copying Appraisal Data, please wait...";
    }

    const getSelectedOptions = () => {
        let selectedOptions = [];
        if(props.options.Evidence){
            selectedOptions.push("Evidence");
        }
        if(props.options.Interviews){
            selectedOptions.push("Interviews");
        }
        if(props.options.Notes){
            selectedOptions.push("Notes");
        }
        if(props.options.Strengths){
            selectedOptions.push("Strengths");
        }
        if(props.options.Weaknesses){
            selectedOptions.push("Weaknesses");
        }
        if(props.options.IOs){
            selectedOptions.push("Improvement Opportunities");
        }
        return selectedOptions.join(', ');
    }

    const tryCloseModal = () => {
        if(!areItemsCopying){
            props.setShowModal(false);
        }
    }

    const tryCloseCopyingModal = () => {
        if (!areItemsCopying) {
            setShowCopyingModal(false);
            props.setShowModal(false);
        }
    }

    return (
        <React.Fragment>
            <Modal show={props.showModal} onHide={(e) => tryCloseModal()} dialogClassName="findingsReportModalStyle">
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '18px', color: 'dimgray' }}>
                        <img
                            src={require('../assets/icon.png')}
                            width="30"
                            height="30"
                            className="d-inline-block align-middle"
                            alt="CAP logo"
                        />{' '}Copy Appraisal Data by Practice Area</Modal.Title>
                </Modal.Header>
                <LoadingComponent isLoading={dataLoading} iconSize={80}>
                    <ReportFragment >
                        <Row className="d-flex justify-content-center" style={{ marginTop: '-50px' }}>
                       
                        <Form.Label
                            column md='2'
                            style={{
                                color: 'white',
                                backgroundColor: 'rgb(0,168,168)',
                                fontSize: '16px',
                                textAlign: 'center',
                                padding: '.5rem'
                            }}>
                            Selected Options
                        </Form.Label>
                        
                        <Col md={5}>
                            {getSelectedOptions()}
                        </Col>
                        </Row>

                        <Row className="d-flex justify-content-center" style={{ marginTop: '50px' }}>
                            <Col md={5}>
                                <Form>
                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <FormLabel
                                            style={{
                                                color: 'rgb(0,168,168)',
                                                fontSize: '20px',
                                                textAlign: 'right',
                                                fontWeight: 'bold'
                                            }}>
                                            Copy From
                                        </FormLabel>
                                    </Form.Group>
                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <Form.Label
                                            column sm='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                padding: '.5rem'
                                            }}>
                                            Appraisal
                                        </Form.Label>
                                        <Col sm={6} style={{ textAlign: 'left' }}>
                                            <FormControl
                                                as="select"
                                                style={{ borderRightColor: 'white', borderTopColor: 'white' }}
                                                selected={selectedAppraisalID_FROM}
                                                onChange={(e) => handleAppraisalChanged_FROM(e.target.value)}>
                                                {getAppraisalOptionsList_FROM()}
                                            </FormControl>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <Form.Label
                                            column sm='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                padding: '.5rem'
                                            }}>
                                            Project
                                        </Form.Label>
                                        <Col sm={6} style={{ textAlign: 'left' }}>
                                            <FormControl id="projectsSelect_FROM"
                                                as="select"
                                                style={{ borderRightColor: 'white', borderTopColor: 'white' }}
                                                disabled={modalOptionsState < 1}
                                                selected={selectedProjectID_FROM}
                                                onChange={(e) => handleProjectChanged_FROM(e.target.value)}>
                                                {getProjectOptionsList_FROM()}
                                            </FormControl>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <Form.Label
                                            column sm='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                padding: '.5rem'
                                            }}>
                                            Practice Area
                                        </Form.Label>
                                        <Col sm={6} style={{ textAlign: 'left' }}>
                                            <FormControl id="practiceAreaSelect_FROM"
                                                as="select"
                                                style={{ borderRightColor: 'white', borderTopColor: 'white' }}
                                                disabled={modalOptionsState < 2}
                                                selected={selectedPracticeAreaID_FROM}
                                                onChange={(e) => handlePracticeAreaChanged_FROM(e.target.value)}>
                                                {getPracticeAreasOptionsList_FROM()}
                                            </FormControl>
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </Col>



                            <Col xs={1} style={{maxWidth:"5px", background: "rgb(0,168,168)", paddingLeft:"1px", paddingRight:"1px"}}>
                            </Col> 

                            

                            <Col md={5}>
                            <Form>
                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <FormLabel
                                            style={{
                                                color: 'rgb(0,168,168)',
                                                fontSize: '20px',
                                                textAlign: 'right',
                                                fontWeight: 'bold'
                                            }}>
                                            Copy To
                                        </FormLabel>
                                    </Form.Group>
                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <Form.Label
                                            column sm='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                padding: '.5rem'
                                            }}>
                                            Appraisal
                                        </Form.Label>
                                        <Col sm={6} style={{ textAlign: 'left' }}>
                                            <FormControl
                                                as="select"
                                                style={{ borderRightColor: 'white', borderTopColor: 'white' }}
                                                disabled={modalOptionsState < 3}
                                                selected={selectedAppraisalID_TO}
                                                onChange={(e) => handleAppraisalChanged_TO(e.target.value)}>
                                                {getAppraisalOptionsList_TO()}
                                            </FormControl>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <Form.Label
                                            column sm='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                padding: '.5rem'
                                            }}>
                                            Project
                                        </Form.Label>
                                        <Col sm={6} style={{ textAlign: 'left' }}>
                                            <FormControl
                                                as="select"
                                                style={{ borderRightColor: 'white', borderTopColor: 'white' }}
                                                disabled={modalOptionsState < 4}
                                                selected={selectedProjectID_TO}
                                                onChange={(e) => handleProjectChanged_TO(e.target.value)}>
                                                {getProjectOptionsList_TO()}
                                            </FormControl>
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-center">
                            <Button
                                variant="outline-light"
                                disabled={selectedPracticeAreaID_FROM === "" || selectedProjectID_TO === ""}
                                style={{
                                    background: 'rgb(0, 168, 168)',
                                    color: 'white',
                                    border: 'none',
                                    justifyContent: 'center',
                                    padding: '.5rem',
                                    marginTop: '1rem',
                                    marginBottom: '2rem'
                                }}
                                size='sm'
                                onClick={() => executeClick()}
                                >
                                Copy Practice Area
                            </Button>{' '}
                        </Row>
                    </ReportFragment>
                </LoadingComponent>
            </Modal>

            <Modal show={showCopyingModal} onHide={(e) => tryCloseCopyingModal(e)} >
                <Modal.Header >
                    <Modal.Title style={{ fontSize: '18px', color: 'dimgray' }}>
                        <img
                            src={require('../assets/icon.png')}
                            width="30"
                            height="30"
                            className="d-inline-block align-middle"
                            alt="CAP logo"
                        />Copy Appraisal Data</Modal.Title>
                </Modal.Header>
                <Row className="d-flex justify-content-center">
                    <Col className='col-md-10'>
                        <Modal.Body>
                            <text style={{ fontSize: '16px', color: 'dimgray' }}>{itemCopyType}</text>
                            <br></br>
                            <text style={{ fontSize: '12px', color: 'dimgray' }}>{itemCopyStatus}</text>
                        </Modal.Body>
                    </Col>
                </Row>
                <Modal.Footer style={{ backgroundColor: 'rgb(0,168,168)' }}>
                    <Button
                        variant="outline-light"
                        disabled={areItemsCopying}
                        size='sm'
                        onClick={(e) => tryCloseCopyingModal(e)}>
                        {areItemsCopying &&
                            <Spinner
                                as="span"
                                animation="border"
                                size='sm'
                                role="status"
                                aria-hidden="true"
                            />}
                        {getCopyingButtonText()}
                    </Button>{' '}
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}
export default CopyAppraisalDataPracticeAreaModal;