import React from 'react';
import {Row, Col} from 'react-bootstrap'
import { getCurrentDateTime } from '../utils/helpers';

function formatInterviewData(interviewData, interviewSessionData){
    let reportRows = []

    //each potential interview session that exists for the appraisal
    for(let i = 0; i < interviewSessionData.length; i++){

        let currentQuestionCount = 0;

        //check each interview entry for a matching session
        for(let j = 0; j < interviewData.length; j++){
            if(interviewData[j].sessions.filter(x => x === interviewSessionData[i].id).length > 0){
                currentQuestionCount++;
            }
        }
        let maxQuestions = interviewSessionData[i].hours*60/2;

        let currentQuestionCountStyle = questionCountStyle;
        if(currentQuestionCount > maxQuestions){
            currentQuestionCountStyle = questionCountExceededStyle;
        }
        
        if(currentQuestionCount > 0){
            reportRows.push(
                <tr>
                    <th>{interviewSessionData[i].interviewee}</th>
                    <th style={{textAlign: "center"}}>{interviewSessionData[i].hours}</th>
                    <th style={{textAlign: "center"}}>{maxQuestions}</th>
                    <th style={currentQuestionCountStyle}>{currentQuestionCount}</th>
                </tr>
            );
        }
    }
    return reportRows;
}

const questionCountStyle = {
    textAlign: "center",
    color: 'black'
}

const questionCountExceededStyle = {
    textAlign: "center",
    color: 'red'
}

const headerCellStyle = {
    border: "1px solid black",
    textAlign: "center",
    backgroundColor:'rgb(0,168,168)',
    maxWidth:'100px'
};

const InterviewQuestionCountReportView = ({interviewData, interviewSessionData}) => {
    let reportRows = [];
    
    if(interviewData.length !== 0){
        reportRows = formatInterviewData(interviewData, interviewSessionData);
    }

    const tableHeadings =  (
        <tr>
            <th style={headerCellStyle}>Role Interviewed</th>
            <th style={headerCellStyle}>Interview Duration<br/>(hr)</th>
            <th style={headerCellStyle}>Number of Questions Allowed (2 Min Avg)</th>
            <th style={headerCellStyle}>Total Number of Questions</th>
        </tr>
    );
    
    let dateTime = getCurrentDateTime();
    return (
        <React.Fragment>
            <Row style={{height:'20px', marginBottom:'8px'}}>
            <Col>
                    <p style={{textAlign: "right"}}><small><b>Date/Time Generated:</b></small></p>
                </Col>
                <Col>
                    <p style={{textAlign: "left"}}><small>{dateTime}</small></p>
                </Col>
            </Row>
            <table style={{width: "100%", borderCollapse: "separate", borderSpacing:"1px"}}>
                {tableHeadings}
                {reportRows}
            </table>
        </React.Fragment>
    );
}
export default InterviewQuestionCountReportView; 