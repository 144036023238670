import React, { useState, useEffect } from 'react';
import { Button, Spinner, Row, Col, Form, Modal } from 'react-bootstrap';
import { AppraisalRepository } from '../utils/repositories';
import LoadingComponent from '../presenters/LoadingComponent';
import ReportFragment from '../presenters/ReportFragment';
import InterviewQuestionCountReportView from '../presenters/InterviewQuestionCountReportView';
import { Constants, getDefaultAPIHeaders } from '../utils/helpers';
import { API } from 'aws-amplify';
import NewWindow from 'react-new-window';

async function fetchData(setDataLoading, setAppraisalName) {
    const currentAppraisalId = localStorage.getItem("selectedAppraisal");
    if (currentAppraisalId) {
        const apiRepo = new AppraisalRepository(null, null, { selected: false, availableForSelection: false });
        const appraisalData = await apiRepo.getOne(currentAppraisalId, false);
        if (!appraisalData.error) {
            setAppraisalName(appraisalData.returnValue.name);
        } else {
            console.log("error getting appraisal data: ", appraisalData.returnValue);
        }
    }

    setDataLoading(false);
}

const InterviewQuestionsCountReportModal = (props) => {
    const [dataLoading, setDataLoading] = useState(true);
    const [optionsVisible, setOptionsVisible] = useState(true);
    const [appraisalName, setAppraisalName] = useState('');
    const [reportLoading, setReportLoading] = useState(false);
    const [interviewSessionData, setInterviewSessionData] = useState({});
    const [interviewData, setInterviewData] = useState({});

    useEffect(() => {
        if (props.showModal) {
            const getData = () => fetchData(setDataLoading, setAppraisalName);
            getData();
            setOptionsVisible(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showModal]);

    async function getInterviewSessionData(currentAppraisalId) {

        let interviewSessions = [];
        const interviewSessionData = await API.get(Constants.API_PATH, `${Constants.INTERVIEW_PATH}/${currentAppraisalId}`, await getDefaultAPIHeaders());
        interviewSessionData.forEach(is => {
            interviewSessions.push({
                id: is.interview_session_id,
                hours: is.hours,
                interviewee: is.interviewee
            });
        });

        return interviewSessions;
    }

    async function getInterviewData(currentAppraisalId) {

        let interviews = [];
        const interviewsData = await API.get(Constants.API_PATH, `${Constants.INTERVIEW_QUESTIONS_PATH}/${currentAppraisalId}`, await getDefaultAPIHeaders());
        interviewsData.forEach(is => {
            interviews.push({
                id: is.interview_question_id,
                sessions: is.sessions
            });
        });

        return interviews;
    }

    const handleGenerateReportClicked = async (event) => {
        setReportLoading(true);

        let currentAppraisalId = localStorage.getItem("selectedAppraisal");
        let interviewSessionInfo = [];
        let interviewInfo = [];

        interviewSessionInfo = await getInterviewSessionData(currentAppraisalId);
        interviewInfo = await getInterviewData(currentAppraisalId);

        setInterviewSessionData(interviewSessionInfo);
        setInterviewData(interviewInfo);

        setReportLoading(false);
        setOptionsVisible(false);
        props.setShowModal(false);
    }

    const viewReportHeader = ``;
    const scopeHeader = '';
    const appraisalHeader = 'Appraisal:';
    const reportName = 'Interview Question Count Report';

    const viewReportStyle = {
        textAlign: "right",
        paddingTop: "0px",
        color: 'black',
        marginTop: '16px',
        fontSize: '22px'
    }

    return (
        <React.Fragment>
            <Modal show={props.showModal} onHide={(e) => props.setShowModal(false)} dialogClassName="reportModalStyle">
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '18px', color: 'dimgray' }}>
                        <img
                            src={require('../assets/icon.png')}
                            width="30"
                            height="30"
                            className="d-inline-block align-middle"
                            alt="CAP logo"
                        />{' '}Interview Question Count Report</Modal.Title>
                </Modal.Header>
                <LoadingComponent isLoading={dataLoading} iconSize={80}>
                    <ReportFragment visible={optionsVisible} >
                        <Row className="d-flex justify-content-center" style={{ marginTop: '-50px' }}>
                            <Col md={10}>
                                <Form>
                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <Form.Label
                                            column md='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                padding: '.5rem'
                                            }}>
                                            Appraisal
                                </Form.Label>
                                        <Col sm={6} style={{ textAlign: 'left' }}>
                                            <Form.Control plaintext readOnly value={appraisalName} style={{ color: 'dimgray', paddingLeft: '1rem' }} />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <Col sm={3}></Col>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col md={5}>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                        <Row className="d-flex justify-content-center">
                            <Button
                                variant="outline-light"
                                style={{
                                    background: 'rgb(0, 168, 168)',
                                    color: 'white',
                                    border: 'none',
                                    justifyContent: 'center',
                                    padding: '.5rem',
                                    marginTop: '1rem',
                                    marginBottom: '1rem'
                                }}
                                size='sm'
                                onClick={(e) => handleGenerateReportClicked(e)}>
                                {reportLoading &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size='sm'
                                        role="status"
                                        aria-hidden="true"
                                    />}
                                {!reportLoading ? "Generate Interview Question Count Report" : ' Generating Report...'}
                            </Button>{' '}
                        </Row>
                    </ReportFragment>
                </LoadingComponent>
            </Modal>

            {!optionsVisible &&
                <NewWindow>
                    <ReportFragment
                        reportName={reportName}
                        reportHeader={viewReportHeader}
                        reportHeaderStyle={viewReportStyle}
                        scopeHeader={scopeHeader}
                        appraisalHeader={appraisalHeader}
                        appraisalName={appraisalName}>
                        <InterviewQuestionCountReportView
                            reportType="Interview Question Count"
                            interviewSessionData={interviewSessionData}
                            interviewData={interviewData}
                        />
                    </ReportFragment>
                </NewWindow>
            }
        </React.Fragment>
    );
}
export default InterviewQuestionsCountReportModal;