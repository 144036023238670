import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Figure, Form, Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import LoadingComponent from './presenters/LoadingComponent';
import { Auth, API} from 'aws-amplify';

async function fetchData(setDataLoading, setUserEmail) {
  const userInfo = await Auth.currentUserInfo();
  setUserEmail(userInfo.attributes.email);

  setDataLoading(false);
}

const UserProfile = () => {

  const [dataLoading, setDataLoading] = useState(true);
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    const getData = () => fetchData(setDataLoading, setUserEmail);
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoadingComponent isLoading={dataLoading} iconSize={80}>
      <Container fluid>
        <Row className="justify-content-center">
          <Col className='col-md-6'>
            <Row className="d-flex justify-content-center">
              <Figure>
                <Figure.Image
                  className='rounded-circle'
                  width={171}
                  height={180}
                  src={require('./assets/avatar.png')}
                />
              </Figure>
            </Row>
            <Row className="d-flex justify-content-center">
                  <Form>
                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{ height: '12px' }}>
                      <Col>
                        <h5>{userEmail}</h5>
                      </Col>
                    </Form.Group>
                  </Form>
            </Row>
            <Row className="d-flex justify-content-center"><Button variant="link" href='/change-password'>Change Password</Button></Row>
          </Col>
        </Row>
      </Container>
    </LoadingComponent>
  );
}
export default UserProfile;