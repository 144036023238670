import { API } from 'aws-amplify';
import { APIResult, convertToFrontEnd, convertToBackEnd, getDefaultAPIHeaders, API_NAME } from './apiUtils';

const API_PATH = '/organizations';

const DATA_MAPPING = {
    id: "organization_id",
    name: "orgName",
    address: "address",
    contactInfo: "contactInfo",
    isActive: "isActive"
}

const DEFAULT_VALUES = {
    markedForDelete: false
}

class OrgRepository {
    constructor(defaultValues = DEFAULT_VALUES){
        this.defaultValues = defaultValues;
    }

    async getAll(){
        const resolved = API.get(API_NAME, API_PATH, await getDefaultAPIHeaders())
            .then(d=>{
                const retVal = d.map(i=>{
                    let newItem = convertToFrontEnd(DATA_MAPPING, i);

                    if(this.defaultValues){
                        newItem = {
                            ...newItem,
                            ...this.defaultValues
                        }
                    }
                    return newItem;
                });
                return new APIResult(false, retVal);
            }).catch(err=>{
                return new APIResult(true, err);
            });
        return resolved;
    }

    async getOne(item){
        const resolved = API.get(API_NAME, `${API_PATH}/${item.id}`, await getDefaultAPIHeaders())
            .then(data=>{
                let retVal = convertToFrontEnd(DATA_MAPPING, data);
                //The purpose of this spread is to preserve the values of the item
                // that are front-end only (such as whether or not they are selected)
                // and update the values that are from the back end.
                retVal = {
                    ...item,
                    ...retVal
                };
                return new APIResult(false, retVal);
            }).catch(err=>{
                return new APIResult(true, err);
            });
        return resolved;
    }

    async createOne(item){
        const body = convertToBackEnd(DATA_MAPPING, item, this.defaultValues);
        const resolved = API.post(API_NAME, `${API_PATH}`, await getDefaultAPIHeaders(body))
            .then(data=>{
                return new APIResult(false, data);
            }).catch(err=>{
                return new APIResult(true, err);
            });
        return resolved;
    }

    async updateOne(item){
        const body = convertToBackEnd(DATA_MAPPING, item, this.defaultValues);
        const resolved = API.put(API_NAME, `${API_PATH}/${item.id}`, await getDefaultAPIHeaders(body))
            .then(data=>{
                let retVal = convertToFrontEnd(DATA_MAPPING, data);
                retVal  = {
                    ...item,
                    retVal
                };
                return new APIResult(false, retVal);
            }).catch(err=>{
                return new APIResult(true, err);
            });
        return resolved;
    }

    async deleteOne(item){
        const resolved = API.del(API_NAME, `${API_PATH}/${item.id}`, await getDefaultAPIHeaders())
            .then(data=>{
                return new APIResult(false, null);
            }).catch(err=>{
                return new APIResult(true, err);
            });
        return resolved;
    }
}

export { OrgRepository };