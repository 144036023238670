import { MODEL_CONSTANTS } from './modelconstants';

const DEFAULT_QUESTIONS = {
    [MODEL_CONSTANTS.CAR_21]: "How do you select outcomes to be analyzed? \nVALUE: How does this lead to effort focused on outcomes with the greatest impact?", 
    [MODEL_CONSTANTS.CAR_22]: "How are the outcomes analyzed and the causes addressed? \nVALUE: How does this help reduce cost and time?", 
    [MODEL_CONSTANTS.CAR_31]: "How are root causes of the outcomes determined? What organizational process is followed? \nVALUE: How does this increase the likelihood of meeting objectives?", 
    [MODEL_CONSTANTS.CAR_32]: "How are actions identified that address the root causes? \nVALUE: How does this reduce cost and time?", 
    [MODEL_CONSTANTS.CAR_33]: "How are actions selected and implemented? \nVALUE: How does this ensure the most impact and the likelihood of meeting objectives?", 
    [MODEL_CONSTANTS.CAR_34]: "How are the root causes, selected actions, and completion of actions documented? \nVALUE: How does recording this information leverage savings and increase productivity across the organization?", 
    [MODEL_CONSTANTS.CAR_35]: "How are the implemented actions monitored for effectiveness? How are the effective changes submitted to the organization? \nVALUE: How does this allow other projects to take advantage of the increased savings/productivity?", 
    [MODEL_CONSTANTS.CAR_41]: "", 
    [MODEL_CONSTANTS.CAR_42]: "", 
    [MODEL_CONSTANTS.CAR_51]: "", 
    [MODEL_CONSTANTS.CM_21]: "How do you identify and document which items are going to be placed under configuration management? \nVALUE: How do you ensure the right version is always being delivered?", 
    [MODEL_CONSTANTS.CM_22]: "Would you please describe your CM system and how it is used? \nVALUE: How do you reduce the cost and effort needed to ensure work product integrity?", 
    [MODEL_CONSTANTS.CM_23]: "Would you please describe how and when you establish baselines and for what work products? \nVALUE: How do you ensure the integrity of the work products?", 
    [MODEL_CONSTANTS.CM_24]: "How are change requests generated and how do you track changes to the configuration items? \nVALUE: How are changes to CIs approved?", 
    [MODEL_CONSTANTS.CM_25]: "What types of records are maintained that describe the configuration items? \nVALUE: How do these records prevent rework?", 
    [MODEL_CONSTANTS.CM_26]: "How do you ensure that all configuration items are part of the correct baseline at the time of release? \nVALUE: How do you ensure the customer receives the agreed-on and correct version of work products?", 
    [MODEL_CONSTANTS.CONT_21]: "How do you identify and prioritize the functions critical to the continuation of the organization's services?  \nVALUE: How does this enable continued operation of essential functions during an emergency or significant disruption?", 
    [MODEL_CONSTANTS.CONT_22]: "How do you identify and prioritize the resources critical to the continuation of the organization's services?  \nVALUE: How does this increase customer satisfaction and enable continued operation of essential resources during an emergency or significant disruption?", 
    [MODEL_CONSTANTS.CONT_23]: "How do you plan for the continuity of the organization's services in the event of an emergency or significant disruption? Where is this plan documented? \nVALUE: How does this minimize impact on customer satisfaction?", 
    [MODEL_CONSTANTS.CONT_31]: "How do you develop and maintain training materials for service continuity? \nVALUE: How does this ensure organizational preparedness in response to significant disruptions?", 
    [MODEL_CONSTANTS.CONT_32]: "How is the continuity training provided and what feedback is obtained after the training? \nVALUE: How does this maximize the organization's ability to restore or continue essential functions for the business?", 
    [MODEL_CONSTANTS.CONT_33]: "How do you prepare, conduct, and analyze results from verification and validation of the continuity plan? \nVALUE: How does this increase confidence and likelihood that the continuity plan is effective?",  
    [MODEL_CONSTANTS.DAR_21]: "What guidelines are in place for determining when to follow a formal decision process? \nVALUE: How does this reduce costs by focusing on the most important decisions?", 
    [MODEL_CONSTANTS.DAR_22]: "For a given decision, is criteria used for evaluating alternatives?  \nVALUE: How does this enable consistent selection of the optimal solution?", 
    [MODEL_CONSTANTS.DAR_23]: "For a given decision, how are alternative solutions identified? \nVALUE: How does this increase the quality of the solution?", 
    [MODEL_CONSTANTS.DAR_24]: "For a given decision, how do you select the evaluation method(s) to be used when evaluating the alternatives? \nVALUE: How does this optimize the cost, schedule, and performance for the decision being made?", 
    [MODEL_CONSTANTS.DAR_25]: "For a given decision, how were the alternative solutions evaluated over the criteria and a selection made?  \nVALUE: How does this ensure the optimal solution is selected?", 
    [MODEL_CONSTANTS.DAR_31]: "How do you develop and maintain a role-based decision authority? \nVALUE: How does this reduce risk in ensuring the right level of management is reviewing/approving the decision?", 
    [MODEL_CONSTANTS.EST_21]: "Would you please describe how the work to be performed is scoped? How do you determine what tasks need to be performed? \nVALUE: How do you ensure the entire solution is addressed?", 
    [MODEL_CONSTANTS.EST_22]: "How are size estimates generated? \nVALUE: How do you ensure the accuracy of estimates?", 
    [MODEL_CONSTANTS.EST_23]: "Please describe how effort and cost is estimated for the project.  Are the estimates based on rationale (e.g. historical data)? Where is the rationale documented? \nVALUE: How do you ensure repeatable estimates and the accuracy of the rationale upon which they are based?", 
    [MODEL_CONSTANTS.EST_31]: "What estimation methods does the organization have? How are they improved? \nVALUE: How do you ensure repeatable and consistent estimates?", 
    [MODEL_CONSTANTS.EST_32]: "How was the organization's measurement repository used to estimate? \nVALUE: How do you increase estimation precision, accuracy, and consistency?", 
    [MODEL_CONSTANTS.EVSD_21]: "Please describe the approach for virtual solution delivery. \nWhere is the approach documented?",
    [MODEL_CONSTANTS.EVSD_22]: "Please describe how the organization determines the effectiveness of the virtual solution delivery approach.  Have any corrective actions been taken as a result of the review?",
    [MODEL_CONSTANTS.EVSD_31]: "Please describe the organization's approach for virtual solution delivery.  Where is the approach documented? How was the approach deployed to the organization?",
    [MODEL_CONSTANTS.EVSD_32]: "Please describe how the organization reviews the implementation of its virtual solution delivery approach to ensure its continued effectiveness.",
    [MODEL_CONSTANTS.EVSD_33]: "What corrective or other actions have been taken to ensure the organization's virtual solution delivery approach remains effective and efficient?",
    [MODEL_CONSTANTS.GOV_21]: "Is there an organizational policy in place for process implementation and improvement? Where is it documented? How is it communicated? \nVALUE: How does the policy increase the likelihood of meeting organizational needs and objectives?", 
    [MODEL_CONSTANTS.GOV_22]: "How does senior management ensure resources and training are provided for process development, implementation, and improvement? \nVALUE: How do the resources provided ensure senior management's priorities are met?", 
    [MODEL_CONSTANTS.GOV_23]: "How does senior management identify their information needs and provide oversight using that information? \nVALUE: How does the alignment of information needs to providing effective oversight ensure business objectives are met?", 
    [MODEL_CONSTANTS.GOV_24]: "What are some ways senior management holds people accountable for adhering to the organizational policies? \nVALUE: How does this accountability ensure business objectives are met?", 
    [MODEL_CONSTANTS.GOV_31]: "How does Senior Management ensure that measures supporting organization objectives are collected, analyzed, and used? \nVALUE: How do the measures increase the organization's ability to successfully deliver products and services?", 
    [MODEL_CONSTANTS.GOV_32]: "How does Senior Management ensure that competencies and processes are aligned with the organizational objectives? \nVALUE: How does this alignment improve the capability of the organization? ", 
    [MODEL_CONSTANTS.GOV_41]: "", 
    [MODEL_CONSTANTS.II_21]: "What tools do you use to perform your job? Do you have enough resources (tools, labor, money) to perform your work? What training is required by the organization for people performing the various processes? What training have you received? \nVALUE: How does having sufficient resources ensure successful projects?", 
    [MODEL_CONSTANTS.II_22]: "How are processes maintained? How do you ensure the processes are being followed? \nVALUE: How do you ensure the processes are effective and reflect the critical activities?", 
    [MODEL_CONSTANTS.II_31]: "How are the organizational processes used to plan, perform, and manage work? \nVALUE: How does this increase organizational learning and overall effectiveness?", 
    [MODEL_CONSTANTS.II_32]: "How is adherence to the processes determined?  How is effectiveness of the processes determined? \nVALUE: How does this lead to process improvement?", 
    [MODEL_CONSTANTS.II_33]: "What types of information are submitted by projects to the organization? \nVALUE: How is this information used to keep the processes maintained and relevant?", 
    [MODEL_CONSTANTS.IRP_21]: "Please describe your approach for incident resolution and prevention? \nVALUE: How does this ensure customer satisfaction?", 
    [MODEL_CONSTANTS.IRP_22]: "How are incidents resolved and monitored to closure? \nVALUE: How does this maximize the effectiveness of incident resolution?", 
    [MODEL_CONSTANTS.IRP_23]: "How is the status of incidents communicated and to whom? \nVALUE: How does this minimize disruptions to work by ensuring affected stakeholders have a common understanding of the incident status?", 
    [MODEL_CONSTANTS.IRP_31]: "Please describe the mechanism for identifying and resolving incidents? How are incidents categorized and prioritized? Who is responsible for tracking and determining resolution? \nVALUE: How does this maximize reuse of information about past incidents to help resolve future incidents?", 
    [MODEL_CONSTANTS.IRP_32]: "How are incidents selected for analysis?  What actions are taken to prevent future occurrence of the incident? \nVALUE: How does this increase customer satisfaction?", 
    [MODEL_CONSTANTS.MPM_21]: "Please describe how you derive your measurement and performance objectives? \nVALUE: How does this increase the likelihood of achieving business results?", 
    [MODEL_CONSTANTS.MPM_22]: "Where do you describe your measures?  What are examples of some of the information captured for each measure? \nVALUE: How does this increase the consistency of the measures?", 
    [MODEL_CONSTANTS.MPM_23]: "How is the measurement data collected? \nVALUE: How does the data improve decisions?", 
    [MODEL_CONSTANTS.MPM_24]: "How is the measurement data analyzed? \nVALUE: How does the analysis provide insight into performance?", 
    [MODEL_CONSTANTS.MPM_25]: "How is the measurement data and results stored? \nVALUE: How does this enable analysis of performance?", 
    [MODEL_CONSTANTS.MPM_26]: "How does the analysis of measures highlight issues and possible actions?  \nVALUE: How does this result in meeting objectives?", 
    [MODEL_CONSTANTS.MPM_31]: "Please describe how you develop and maintain organizational measure and performance objectives that are tied to business objectives? \nVALUE: How does this lead to business success?", 
    [MODEL_CONSTANTS.MPM_32]: "What organizational standards and processes are in place for describing measures? \nVALUE: How does this enable a consistent collection, understanding, and use of the measurement data?", 
    [MODEL_CONSTANTS.MPM_33]: "How do you ensure the integrity of the measurement data being collected? \nVALUE: How does this lead to an increased confidence in the data resulting in better decisions?", 
    [MODEL_CONSTANTS.MPM_34]: "How is the organization's measurement repository maintained and used? \nVALUE: How does the use of the organization's measurement repository result in successful work?", 
    [MODEL_CONSTANTS.MPM_35]: "How is organizational performance analyzed and who is involved? What performance improvements have been made as a result? \nVALUE: How does this lead to business success?", 
    [MODEL_CONSTANTS.MPM_36]: "How are performance results communicated to the organization? \nVALUE: How does this increase understanding and buy-in from the organization", 
    [MODEL_CONSTANTS.MPM_41]: "", 
    [MODEL_CONSTANTS.MPM_42]: "", 
    [MODEL_CONSTANTS.MPM_43]: "", 
    [MODEL_CONSTANTS.MPM_44]: "", 
    [MODEL_CONSTANTS.MPM_45]: "", 
    [MODEL_CONSTANTS.MPM_51]: "", 
    [MODEL_CONSTANTS.MPM_52]: "", 
    [MODEL_CONSTANTS.MPM_53]: "", 
    [MODEL_CONSTANTS.MC_21]: "How is the project's effort, cost and progress monitored against the plan? \nVALUE: How does this increase the likelihood of meeting objectives?",
    [MODEL_CONSTANTS.MC_22]: "How is stakeholder involvement and commitments monitored? \nVALUE: How does this lead to the successful delivery of work?",
    [MODEL_CONSTANTS.MC_23]: "How do you monitor the transition to operations and support? \nVALUE: How does this ensure a smooth and successful transition?",
    [MODEL_CONSTANTS.MC_24]: "What types of issues might arise from monitoring actuals vs. planned? How are corrective actions addressing the issues captured and tracked to closure? \nVALUE: How does this increase the probability that objectives will be met?",
    [MODEL_CONSTANTS.MC_31]: "How do you manage the work being performed? \nVALUE: How does this improve the likelihood of achieving objectives?",
    [MODEL_CONSTANTS.MC_32]: "How are critical dependencies managed? \nVALUE: How does this increase the likelihood of meeting objectives?",
    [MODEL_CONSTANTS.MC_33]: "How is the work environment monitored? \nVALUE: How does this ensure objectives are met?",
    [MODEL_CONSTANTS.MC_34]: "How are issues with and between stakeholders identified, managed, and resolved? \nVALUE: How does this increase the likelihood of meeting objectives?",
    [MODEL_CONSTANTS.OT_21]: "How are organizational training needs identified and where are they documented? \nVALUE: How does this reduce cost by providing training needed to perform the work?", 
    [MODEL_CONSTANTS.OT_22]: "How is organizational training conducted and what records are maintained? \nVALUE: How does this ensure that only individuals without the necessary knowledge/skills are trained.", 
    [MODEL_CONSTANTS.OT_31]: "How are the organization's long-term and short-term training needs developed and maintained? Where are they documented? \nVALUE: How does this maximize the likelihood of meeting objectives by ensuring individuals have the appropriate knowledge/skills?",
    [MODEL_CONSTANTS.OT_32]: "How are training needs and the delivery of that training coordinated between all levels in the organization?  \nVALUE: How does this ensure efficient and effective allocation of training resources?", 
    [MODEL_CONSTANTS.OT_33]: "How are the identified training needs planned and implemented? \nVALUE: How does this ensure personnel are trained so that they can efficiently and effectively perform their work?", 
    [MODEL_CONSTANTS.OT_34]: "What capability has been put in place by the organization to address each organizational training need? \nVALUE: How do this ensure personnel are trained so that they can efficiently and effectively perform their work?", 
    [MODEL_CONSTANTS.OT_35]: "How is the effectiveness of the organization's training assessed? \nVALUE: How does this keep the training program relevant and valuable?", 
    [MODEL_CONSTANTS.OT_36]: "How are training records established and used? \nVALUE: How does this reflect how well the training program supports the achievement of business and performance goals?",
    [MODEL_CONSTANTS.PR_21]: "What procedures and supporting materials are available for performing peer reviews? \nVALUE: How does this maximize the efficiency and effectiveness of finding issues in peer reviews?", 
    [MODEL_CONSTANTS.PR_22]: "How are work products selected for peer review?  Where are the work products documented? \nVALUE: How does this help manage cost and provide return on investment?", 
    [MODEL_CONSTANTS.PR_23]: "How are peer reviews conducted? \nVALUE: How does this lead to thorough and consistent reviews where issues are identified?", 
    [MODEL_CONSTANTS.PR_24]: "How are issues identified in a peer review resolved? \nVALUE: How does this lead to reduced rework and cost and increased quality?", 
    [MODEL_CONSTANTS.PR_31]: "How are peer review results and data from the preparation and conduct analyzed?  How often is the analysis performed? \nVALUE: How does this increase the efficiency and effectiveness of peer reviews?", 
    [MODEL_CONSTANTS.PLAN_21]: "How is the approach to performing work developed and maintained? \nVALUE: How does this maximize success?", 
    [MODEL_CONSTANTS.PLAN_22]: "Please describe how you plan for training needs? \nVALUE: How does this lead to a more efficient and effective use of resources?", 
    [MODEL_CONSTANTS.PLAN_23]: "How are the budget and schedule for the work developed and maintained? \nVALUE: How does a budget and schedule enable timely management and corrective action?", 
    [MODEL_CONSTANTS.PLAN_24]: "How is the involvement of stakeholders planned? \nVALUE: How does this ensure stakeholder needs are addressed?", 
    [MODEL_CONSTANTS.PLAN_25]: "How is the transition to operations and support planned?  \nVALUE: How does this minimize surprises and rework?", 
    [MODEL_CONSTANTS.PLAN_26]: "How are adjustments made to the plan to address resource availability? \nVALUE: How does this increase the likelihood that there are sufficient resources for the work that has to be performed?", 
    [MODEL_CONSTANTS.PLAN_27]: "Would you please describe the key elements of the plan? How is the plan maintained? \nVALUE: How does a project plan ensure effective and efficient communication leading to the achievement of objectives?", 
    [MODEL_CONSTANTS.PLAN_28]: "How is the plan reviewed and who commits to the plan? \nVALUE: How does this lead to reduced rework and the likelihood of achieving objectives?", 
    [MODEL_CONSTANTS.PLAN_31]: "How was the process for performing the work developed from the organization processes?  What tailoring was performed? \nVALUE: How does this lead to a more efficient and effective achievement of objectives?", 
    [MODEL_CONSTANTS.PLAN_32]: "How was the process for performing the work, and the organization's process assets and measurement repository used to develop the plan?  \nVALUE: How does this increase the likelihood that objectives will be met?", 
    [MODEL_CONSTANTS.PLAN_33]: "How are critical dependencies managed? \nVALUE: How does this reduce risk and increase the likelihood of a successful work effort?", 
    [MODEL_CONSTANTS.PLAN_34]: "How is the work environment planned and how does it align with the organization's standards? \nVALUE: How does this lead to increased productivity?", 
    [MODEL_CONSTANTS.PLAN_41]: "", 
    [MODEL_CONSTANTS.PAD_21]: "How do you determine what process assets will be needed for the work being performed? \nVALUE: How does this ensure that resources will be focused only on the process assets needed to perform the work?", 
    [MODEL_CONSTANTS.PAD_22]: "How do you develop, buy, or reuse process assets? How do you determine if a build/buy/reuse analysis is needed? \nVALUE: How does this minimize cost, effort, and time?", 
    [MODEL_CONSTANTS.PAD_23]: "How are the process assets made available to those individuals performing the work? \nVALUE: How does this reduce cost and time needed to perform the work?", 
    [MODEL_CONSTANTS.PAD_31]: "What approach is used for developing and maintaining a process asset? How is that approach maintained? \nVALUE: How does this minimize cost?", 
    [MODEL_CONSTANTS.PAD_32]: "How is the organization's process architecture developed and maintained?  What makes up the process architecture? \nVALUE: How does the process architecture ensure processes that add value?", 
    [MODEL_CONSTANTS.PAD_33]: "How are process assets developed, maintained, and made available to the organization? \nVALUE: How does this enable work to be done more efficiently and effectively?", 
    [MODEL_CONSTANTS.PAD_34]: "Please describe your tailoring guidelines and criteria.  Where are these guidelines documented and how are they used? \nVALUE: How does this prevent unnecessary work by a team?", 
    //[MODEL_CONSTANTS.PAD_35]: "PRIMARY:  Where are the organization’s processes and process assets made available for use?  How is it maintained? \nVALUE: How does the process asset library reduce time and effort?", 
    [MODEL_CONSTANTS.PAD_35]: "What work environment standards does the organization employ?  Where are these standards documented?  How often are they updated? \nVALUE: How is productivity and consistency increased through these standards?", 
    [MODEL_CONSTANTS.PAD_36]: "What organizational measurement and analysis standards are available for use?  How are they maintained? \nVALUE: How does this support consistent use of measurements and the related analysis for better decision making?", 
    [MODEL_CONSTANTS.PCM_21]: "How are improvements identified to the process assets? \nVALUE: How does this ensure that resources will only be allocated to the most critical business needs and objectives?", 
    [MODEL_CONSTANTS.PCM_22]: "How are plans established and maintained for implementing process improvements? \nVALUE: How does this enable more efficient and effective improvement efforts?", 
    [MODEL_CONSTANTS.PCM_31]: "How are process improvement objectives developed and maintained?  How do they tie to the business objectives? \nVALUE: How does this ensure process improvements focus on business objectives?", 
    [MODEL_CONSTANTS.PCM_32]: "What processes are the largest contributors to meeting business objectives? How/when are those processes identified? \nVALUE: How does this maximize the impact of the improvement activities?", 
    [MODEL_CONSTANTS.PCM_33]: "How do you research new processes, techniques, methods, and tools for possible improvement opportunities? \nVALUE: How does this maximize process innovation to more efficiently and effectively achieve objectives?",
    [MODEL_CONSTANTS.PCM_34]: "How is the deployment, training, and support of process improvements planned? \nVALUE: How does this ensure the improvements add value to the organization?", 
    [MODEL_CONSTANTS.PCM_35]: "How are process improvements deployed to the organization? \nVALUE: How do you ensure an efficient, effective, and coordinated process deployment?", 
    [MODEL_CONSTANTS.PCM_36]: "How is the effectiveness of the deployed process improvements evaluated? How is that evaluation captured? \nVALUE: How does this ensure the improvements are contributing to meeting the organization's process and performance improvement objectives?", 
    [MODEL_CONSTANTS.PCM_41]: "", 
    [MODEL_CONSTANTS.PQA_21]: "How is the approach to quality assurance planned and what historical data was used? \nVALUE: How is cost reduced and quality increased as a result?", 
    [MODEL_CONSTANTS.PQA_22]: "How are the actual processes and work products audited? \nVALUE: How does this lead to the delivery of high-quality solutions?", 
    [MODEL_CONSTANTS.PQA_23]: "How are non-compliance issues communicated and resolved? \nVALUE: How does this lead to less rework and customer satisfaction?", 
    [MODEL_CONSTANTS.PQA_24]: "How are the quality assurance activities documented? \nVALUE: How does the recording of current quality assurance activities impact future activities? ", 
    [MODEL_CONSTANTS.PQA_31]: "How are opportunities for improvement identified during an audit? \nVALUE: How does this lead to an increased organizational capability?",
    [MODEL_CONSTANTS.PI_21]: "What is the integration approach and where is it documented? \nVALUE: How does this ensure the product will meet customer requirements?", 
    [MODEL_CONSTANTS.PI_22]: "What is the integration environment and where is it documented and maintained? \nVALUE: How does this ensure correct integration?", 
    [MODEL_CONSTANTS.PI_23]: "What procedures are in place for integration and where are they documented? \nVALUE: How does this improve the likelihood of producing a solution that works correctly and meets the customer's requirements?", 
    [MODEL_CONSTANTS.PI_24]: "How do you ensure each component has been identified and operates according to its allocated requirements and design? \nVALUE: How does this reduce total development cost, integration cycle time, and rework?", 
    [MODEL_CONSTANTS.PI_25]: "How are the integrated components evaluated to ensure they meet the allocated requirements and design? \nVALUE: How does this ensure the customer requirements are correctly implemented?", 
    [MODEL_CONSTANTS.PI_26]: "Was integration performed in accordance with the integration strategy?  How are the results of integration documented? \nVALUE: How does this ensure a solution that meets the requirements and design?", 
    [MODEL_CONSTANTS.PI_31]: "What reviews of interface descriptions are performed?  Are interface relationships/dependencies maintained over the life of the project? \nVALUE: How does this reduce rework and missed project objectives?", 
    [MODEL_CONSTANTS.PI_32]: "How do you ensure prior to integration that each interface complies with the applicable interface description? \nVALUE: How does this reduce rework due to interface incompatibility?", 
    [MODEL_CONSTANTS.PI_33]: "How are the integrated components evaluated and how are the results documented? \nVALUE: How does this reduce the risk of interface failure within the integrated components?", 
    [MODEL_CONSTANTS.RDM_21]: "How do you discuss customer (and other stakeholder) needs, expectations, and constraints?  \nVALUE: How does this increase the likelihood that the customer will be satisfied?", 
    [MODEL_CONSTANTS.RDM_22]: "How are customer needs, expectations, and constraints transformed into a set of prioritized requirements? \nVALUE: How does this lead to increased customer satisfaction?", 
    //[MODEL_CONSTANTS.RDM_23]: "How do you reach agreement with the customer (and/or other relevant stakeholders) on the requirements?  Where are the requirements documented? \nVALUE: How does this increase customer satisfaction?",
    [MODEL_CONSTANTS.RDM_23]: "How is commitment to the requirements obtained from project team members? \nVALUE: How does this lead to fewer delays and rework?", 
    [MODEL_CONSTANTS.RDM_24]: "How is requirements traceability captured?  What critical components trace to the requirements? \nVALUE: How does this ensure better consistency and increase the likelihood of customer satisfaction?", 
    [MODEL_CONSTANTS.RDM_25]: "How do you ensure that work products remain consistent with the requirements? \nVALUE: How does this lead to less rework?", 
    [MODEL_CONSTANTS.RDM_31]: "How are requirements developed and maintained?  Where are they documented? \nVALUE: How does this ensure customer needs and expectations are consistently addressed?", 
    [MODEL_CONSTANTS.RDM_32]: "How do you establish or define operational concepts and user scenarios? How are they documented? \nVALUE: How does this ensure increased understanding and commitment from the customer?", 
    [MODEL_CONSTANTS.RDM_33]: "How are requirements allocated and to what are they allocated? \nVALUE: How does this increase customer satisfaction?", 
    [MODEL_CONSTANTS.RDM_34]: "How are interface requirements identified and maintained? \nVALUE: How does this lead to reduced rework and risk?",
    [MODEL_CONSTANTS.RDM_35]: "How are requirements reviewed/evaluated and what criteria is used?  \nVALUE: How does this lead to less rework?", 
    [MODEL_CONSTANTS.RDM_36]: "How are risks related to stakeholder needs/constraints monitored?  How are stakeholder needs/constraints balanced with available resources, training, and budget? \nVALUE: How does this lead to increased stakeholder satisfaction?", 
    [MODEL_CONSTANTS.RDM_37]: "How do you ensure that you have captured the correct requirements that will result in a service or solution that performs as intended in the target environment? \nVALUE: How does this lead to increased customer satisfaction?", 
    [MODEL_CONSTANTS.RSK_21]: "How are risks or opportunities analyzed? \nVALUE: How does this increase the likelihood of achieving objectives?", 
    [MODEL_CONSTANTS.RSK_22]: "How are risks or opportunities monitored and how is their status communicated? \nVALUE: How does this enable timely corrective or leveraging actions?",
    [MODEL_CONSTANTS.RSK_31]: "What risk or opportunity categories are used for risk identification? \nVALUE: How does this help focus attention on uncertainties that will impact the achievement of objectives?",
    [MODEL_CONSTANTS.RSK_32]: "Please describe what parameters were used for risks or opportunities and how the parameters are documented for each risk/opportunity? \nVALUE: How does this focus risk/opportunity management activities on those that are the highest priority?",
    [MODEL_CONSTANTS.RSK_33]: "How is the risk or opportunity management strategy/approach documented and maintained? \nVALUE: How does this increase the likelihood of achieving objectives?",
    [MODEL_CONSTANTS.RSK_34]: "What type of risk or opportunity management plans are developed and maintained?  Where are they documented? \nVALUE: How does this minimize the impact of risks or maximize the benefits of opportunities?",
    [MODEL_CONSTANTS.RSK_35]: "How are risk or opportunities managed? \nVALUE: How does this reduce unforeseen occurrences and increase business value?",
    [MODEL_CONSTANTS.SDM_21]: "Please describe how contracts are established and maintained. \nVALUE: How does this enhance customer satisfaction by aligning service delivery with their expectations?",
    [MODEL_CONSTANTS.SDM_22]: "What is a service request?  How are they received and processed? \nVALUE: How does this enhance service delivery to better meet customer expectations?", 
    [MODEL_CONSTANTS.SDM_23]: "How are services delivered to satisfy the contract? \nVALUE: How does this increase customer satisfaction?",
    [MODEL_CONSTANTS.SDM_24]: "How are existing data and service agreements used in preparing for new/updated agreements? \nVALUE: How does this align service delivery capability and customer expectations?",
    [MODEL_CONSTANTS.SDM_25]: "What is the service system? What is the approach for operating and changing the service system?  Where is that documented? \nVALUE: How does this increase the likelihood that services and changes to them will meet customer expectations?",
    [MODEL_CONSTANTS.SDM_26]: "How do you confirm the readiness of the service system to support service delivery? \nVALUE: How does this improve customer satisfaction?",
    [MODEL_CONSTANTS.SDM_31]: "What organizational standard service systems have been defined and where are they documented? Are there specific agreements for use with those service systems? \nVALUE: How does this maximize the availability and consistency of the service system to meet customer needs?", 
    [MODEL_CONSTANTS.STSM_21]: "Where are the organization's services described and maintained? \nVALUE: How does this enable consistent service delivery that aligns with customer needs?", 
    [MODEL_CONSTANTS.STSM_22]: "How is data concerning strategic needs and service delivery capability collected, documented, and analyzed? \nVALUE: How does this focus the organization on the needs and objectives that have the greatest impact on customer satisfaction.", 
    [MODEL_CONSTANTS.STSM_23]: "How do you develop and maintain an approach for providing new or updated services derived from strategic needs? \nVALUE: How does this focus resources on identifying services that best anticipate and meet market needs?", 
    [MODEL_CONSTANTS.STSM_31]: "What are the organization's standard services and service descriptions? Where are they documented? \nVALUE: How does this minimize cost and achieve faster time to market for new or changed services?", 
    [MODEL_CONSTANTS.SAM_21]: "How is an agreement with the supplier developed and maintained? \nVALUE: How does this improve the likelihood that the supplier will provide the correct deliverable?", 
    [MODEL_CONSTANTS.SAM_22]: "How are supplier activities monitored against the supplier agreement? \nVALUE: How does this improve the likelihood that the supplier will provide a quality deliverable?", 
    [MODEL_CONSTANTS.SAM_23]: "How is the deliverable provided by the supplier accepted? \nVALUE: How does this decrease the risk of an unsatisfactory deliverable?", 
    [MODEL_CONSTANTS.SAM_24]: "How are invoices approved and managed against the supplier agreement? \nVALUE: How does this ensure a good relationship between the acquirer and supplier?", 
    [MODEL_CONSTANTS.SAM_31]: "How are specific technical deliverables selected for additional review?  What type of review is performed by the acquirer? \nVALUE: How does this lead to increased confidence by the acquirer that the supplier will provide a quality deliverable on time?", 
    [MODEL_CONSTANTS.SAM_32]: "How are specific processes and deliverables selected for monitoring?  Which processes and deliverables were monitored and how? \nVALUE: How does this lead to increased visibility into the supplier's capability/performance resulting in reduced risk?", 
    [MODEL_CONSTANTS.SAM_41]: "",
    [MODEL_CONSTANTS.TS_21]: "How is the design documented? How do you ensure all requirements have been addressed in the design? \nVALUE: How does this enable the implementation of a cost-effective solution that meets requirements and avoids rework?", 
    [MODEL_CONSTANTS.TS_22]: "How is the design reviewed and issues addressed? \nVALUE: How does this ensure that the solution meets requirements?", 
    [MODEL_CONSTANTS.TS_23]: "What types of user documentation is developed? \nVALUE: How does this ensure that the solution is usable and maintainable?", 
    [MODEL_CONSTANTS.TS_31]: "How are design decisions made and documented? Were criteria identified and used? \nVALUE: How does this increase the likelihood of producing a design that meets customer requirements and constraints?", 
    [MODEL_CONSTANTS.TS_32]: "How are design decisions made and documented? Were alternatives identified and evaluated? \nVALUE: How does this ensure the most beneficial solution is identified and selected?", 
    [MODEL_CONSTANTS.TS_33]: "How are make, buy or reuse decisions made?  Who is involved?  Is criteria identified and used? How is this information documented? \nVALUE: How does this ensure the most effective way to implement the design has been chosen?", 
    [MODEL_CONSTANTS.TS_34]: "How are design decisions made and documented? Were criteria identified and used? \nVALUE: How does this ensure the most efficient and effective solution is selected to meet the customer's requirements?", 
    [MODEL_CONSTANTS.TS_35]: "What comprises the information needed to implement the design (i.e., Technical Data Package)? \nVALUE: How does this avoid rework by ensuring the solution implementers have everything they need to develop the solution?", 
    [MODEL_CONSTANTS.TS_36]: "How is the interface design captured? What criteria have been established for the interfaces? \nVALUE: How does this reduce the likelihood of failure and rework during testing and maximize performance?", 
    [MODEL_CONSTANTS.VV_21]: "What types of verification and validation does the project perform? How are work products selected for verification/validation and how is that documented? \nVALUE: How does this produce solutions that meet or exceed customer expectations?", 
    [MODEL_CONSTANTS.VV_22]: "How is the environment for verification and validation determined and documented? \nVALUE: How does this minimize project delays by ensuring the applicable environment is ready when needed?", 
    [MODEL_CONSTANTS.VV_23]: "What procedures for verification and validation are developed and maintained? How are results from verification and validation captured? \nVALUE: How does the use of verification/validation procedures reduce cost and ensure more predictable performance?", 
    [MODEL_CONSTANTS.VV_31]: "How are expected or acceptable verification/validation results indicated?  \nVALUE: How does this ensure the verification and validation activities focus on critical needs?", 
    [MODEL_CONSTANTS.VV_32]: "What analysis of the verification/validation results are performed, how are those results communicated, and to whom? \nVALUE: How does this improve verification and validation effectiveness over time?"
};

export { DEFAULT_QUESTIONS }