import React, { useState, useEffect } from 'react';
import { Modal, FormGroup, FormLabel, Button, Row, Col, FormControl } from 'react-bootstrap';
import './MergeConflictModal.css';
import '../modal.css';

function MergeConflictModal(props) {
    const [value, setValue] = useState("server");
    const [mergedText, setMergedText] = useState('');

    const textAreaStyle = {
        width: "100%",
        paddingLeft: '5px',
        borderBottomColor: 'dimgray',
        borderRightColor: 'white',
        borderLeftColor: 'white',
        borderTopColor: 'white'
    };

    useEffect(() => {
        if (props.canMerge) {
            setMergedText(props.conflict != null ? props.conflict.server.text + '\n\n'
                + props.conflict.client.text : '');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.conflict]);

    const onSelectionChanged = (value) => {
        setValue(value);
    }

    const updateMergedText = (value) => {
        setMergedText(value);
    }

    const handleMergeConflict = () => {
        props.closeModal();
        if (value === "merged") {
            const merged = {
                revNumber: props.conflict.client.revNumber,
                text: mergedText
            };
            props.handleMergeConflict(merged, value);
        } else {
            props.handleMergeConflict(props.conflict[value], value);
        }
        setValue("server");
    }

    return (
        <Modal show={props.showModal}>
            <Modal.Header>
                <Modal.Title style={{ fontSize: '18px', color: 'dimgray' }}>
                    <img
                        src={require('../assets/icon.png')}
                        width="30"
                        height="30"
                        className="d-inline-block align-middle"
                        alt="CAP logo"
                    />{' '}Resolve Edit Conflict {props.modalTitleFragment && "- " + props.modalTitleFragment}</Modal.Title>
            </Modal.Header>
            <Row className="d-flex justify-content-center">
                <Col className='col-md-10'>
                    <Modal.Body>
                        <FormGroup controlId="Server">
                            <FormLabel style={{ color: 'dimgray' }}>Server Value</FormLabel>
                            <div className="conflictContainer">
                                <input type="radio" name="radio"
                                    value="server" defaultChecked={true}
                                    onChange={(e => onSelectionChanged(e.target.value))} />
                                {props.renderConflictContent ? props.renderConflictContent(props.conflict.server)
                                    : <FormControl readonly as="textarea"
                                        name="defaultConflict"
                                        value={props.conflict && props.conflict.server.text}
                                        style={textAreaStyle} />
                                }
                            </div>
                        </FormGroup>
                        <FormGroup controlId="Client">
                            <FormLabel style={{ color: 'dimgray' }}>Your Value</FormLabel>
                            <div className="conflictContainer">
                                <input type="radio" name="radio"
                                    value="client" checked={value === "client"}
                                    onChange={(e => onSelectionChanged(e.target.value))} />
                                {props.renderConflictContent ? props.renderConflictContent(props.conflict.client)
                                    : <FormControl readonly as="textarea"
                                        name="defaultConflict"
                                        value={props.conflict && props.conflict.client.text}
                                        style={textAreaStyle} />
                                }
                            </div>
                        </FormGroup>
                        {props.canMerge &&
                            <FormGroup controlId="Merged">
                                <FormLabel style={{ color: 'dimgray' }}>Merged Value</FormLabel>
                                <div className="conflictContainer">
                                    <input type="radio" name="radio" value="merged" onChange={(e => onSelectionChanged(e.target.value))} checked={value === "merged"} />
                                    <FormControl readonly as="textarea"
                                        name="defaultConflict"
                                        onChange={(e) => updateMergedText(e.target.value)}
                                        onFocus={() => setValue("merged")}
                                        value={mergedText}
                                        style={textAreaStyle} />
                                </div>
                            </FormGroup>
                        }
                    </Modal.Body>
                </Col>
            </Row>
            <Modal.Footer style={{ backgroundColor: 'rgb(0,168,168)' }}>
                <Button
                    variant="outline-light"
                    size='sm'
                    onClick={() => handleMergeConflict()}
                >Ok</Button>{' '}
            </Modal.Footer>
        </Modal>
    )
}

export default MergeConflictModal;