import React from 'react';
import Tree from 'react-virtualized-tree/lib/index';
import { Expandable } from 'react-virtualized-tree/lib/renderers';
import TreeNode from '../../containers/Tree/TreeNode';
import 'react-virtualized/styles.css';
import 'react-virtualized-tree/lib/main.css';
import 'material-icons/css/material-icons.min.css';

//Wraps the tree for the Tree control to make it simple to wrap inside of a Loading Component
const TreePresenter = ({nodesDisabled, nodes, handleNodesChanged, handleNodeRatingChanged, handleNodeClicked}) => {
    return (
        <Tree nodes={nodes} onChange={handleNodesChanged} nodeMarginLeft={65} >
            {({style, node, ...rest}) => {
              return(
                <div style={style}>
                      <span> 
                          <Expandable node={node} {...rest} 
                            iconsClassNameMap={{
                                collapsed: 'fa fa-chevron-right  ',
                                expanded: 'fa fa-chevron-down  '}} >
                              <TreeNode disabled={nodesDisabled} node={node} nodeRatingChanged={handleNodeRatingChanged} handleNodeClicked={handleNodeClicked} style={{paddingLeft:"15px"}}/>
                          </Expandable>
                      </span>
                  </div>
                )
              }}
        </Tree>
    )
}

export default TreePresenter;