import React from 'react';
import { Row, Col } from 'react-bootstrap'
import { ICON_MAP } from '../utils/IconMap';
import { getCurrentDateTime } from '../utils/helpers';
import { CharacterizationsLegend } from '../utils/IconLegends';

function getProjectSubTitle(projects) {
    let projectSubTitle = "";
    for (let i = 0; i < projects.length; i++) {
        if (i !== projects.length - 1) {
            const nextProject = `${projects[i].name}, `;
            projectSubTitle = projectSubTitle.concat(nextProject);
        } else {
            projectSubTitle = projectSubTitle.concat(projects[i].name);
        }
    }
    return projectSubTitle;
}

const indent1Style = {
    paddingLeft: '5px',
    paddingRight: '5px',
    border: 'none',
    whiteSpace: 'pre-wrap'
};

const indent2Style = {
    paddingLeft: '35px',
    paddingRight: '5px',
    border: 'none',
    whiteSpace: 'pre-wrap'
};

const indent3Style = {
    paddingLeft: '70px',
    paddingRight: '5px',
    border: 'none',
    whiteSpace: 'pre-wrap'
};

const indent4Style = {
    paddingLeft: '105px',
    paddingRight: '5px',
    border: 'none',
    whiteSpace: 'pre-wrap'
};

function addProjectRow(projectName, reportRows) {
    const projectRow = (
        <div class="list-group-item" style={indent1Style}>
            <h5 style={{ background: 'rgb(0,168,168)' }}><b>{projectName}</b></h5>
        </div>
    );
    reportRows.push(<div class="just-padding" />)
    reportRows.push(projectRow);
}

function addPARow(practiceAreaName, reportRows) {
    const practiceAreaRow = (
        <div class="list-group-item" style={indent2Style}>
            <h6><b>{practiceAreaName}</b></h6>
        </div>
    );
    reportRows.push(practiceAreaRow);
}

function addPracticeRow(hasCharacterization, name, statement, characterization, reportRows) {
    let practiceFindingsRow;
    if (hasCharacterization) {
        practiceFindingsRow = (
            <div class="list-group-item" style={indent3Style}>
                <div class="row">
                    <div class="col-auto"><h6>{getCharacterizationIcon(characterization)} <b>{name}: {statement}</b></h6></div>
                </div>
            </div>
        );
    }
    else {
        practiceFindingsRow = (
            <div class="list-group-item" style={indent3Style}>
                <div class="row">
                    <div class="col-auto"><h6><b>{name}: {statement}</b></h6></div>
                </div>
            </div>
        );
    }
    reportRows.push(practiceFindingsRow);
}

function addPracticeFindingsRow(type, data, reportRows) {
    let practiceFindingsRow;
    if (data !== "") {
        practiceFindingsRow = (
            <div class="list-group-item" style={indent3Style}>
                <Row>
                    <Col style={{ maxWidth: "140px", textAlign: "right" }}><b>{type}</b>:</Col>
                    <Col><span style={{ textAlign: "left" }}>{data}</span></Col>
                </Row>
            </div>
        );
        reportRows.push(practiceFindingsRow);
    }
}

function addBlankRow(reportRows) {
    reportRows.push(<br />);
}

function addNotesRow(level, name, notes, reportRows) {
    const groupNotesRow = (
        <div class="list-group-item" style={level}>
            <div class="row">
                <div class="col-auto"><b>{name}</b>:</div>
                <div class="col"><span style={{ textAlign: "left" }}>{notes}</span></div>
            </div>
        </div>
    );
    reportRows.push(groupNotesRow);
}

function addPANotesRow(level, name, notes, reportRows) {
    const groupNotesRow = (
        <div class="list-group-item" style={level}>
            <div class="row align-items-end">
                <Col style={{ maxWidth: "230px", textAlign: "right" }}><b>{name}</b>:</Col>
                <Col><span style={{ textAlign: "left" }}>{notes}</span></Col>
            </div>
        </div>
    );
    reportRows.push(groupNotesRow);
}

function getCharacterizationIcon(value) {
    if (value === "") {
        return ICON_MAP["Not Examined"];
    }
    else {
        return ICON_MAP[value]
    }
}

function formatAllNotesReportData(findingsData) {
    let reportRows = []
    for (let i = 0; i < findingsData.length; i++) {
        addProjectRow(findingsData[i].ProjectName, reportRows)
        let PAs = findingsData[i].PAData;

        for (let j = 0; j < PAs.length; j++) {
            addNotesRow(indent2Style, PAs[j].Name, PAs[j].Notes, reportRows)
            let levelData = PAs[j].LevelData;

            for (let k = 0; k < levelData.length; k++) {
                addNotesRow(indent3Style, levelData[k].Name, levelData[k].Notes, reportRows);
                let practices = levelData[k].Practices;

                for (let l = 0; l < practices.length; l++) {
                    addNotesRow(indent4Style, practices[l].PracticeName, practices[l].Notes, reportRows)
                }
            }
        }
    }
    return reportRows;
}

function formatPracticeAreaReportData(findingsData) {
    let reportRows = []
    for (let i = 0; i < findingsData.length; i++) {
        addProjectRow(findingsData[i].ProjectName, reportRows);
        let PAs = findingsData[i].PAData;
        for (let j = 0; j < PAs.length; j++) {
            addPANotesRow(indent1Style, PAs[j].Name, PAs[j].Notes, reportRows);
            addBlankRow(reportRows);
        }
    }
    return reportRows;
}

function formatPracticeGroupReportData(findingsData) {
    let reportRows = []

    for (let i = 0; i < findingsData.length; i++) {
        addProjectRow(findingsData[i].ProjectName, reportRows);
        let PAs = findingsData[i].PAData;
        for (let j = 0; j < PAs.length; j++) {
            addPARow(PAs[j].Name, reportRows);
            let levelData = PAs[j].LevelData;
            for (let k = 0; k < levelData.length; k++) {
                addNotesRow(indent3Style, levelData[k].Name, levelData[k].Notes, reportRows);
                addBlankRow(reportRows);
            }
        }
    }

    return reportRows;
}

function formatPracticeReportData(reportOptions, findingsData) {
    let reportRows = []

    for (let i = 0; i < findingsData.length; i++) {
        let project = findingsData[i];
        addProjectRow(project.ProjectName, reportRows);
        let PAs = project.PracticeAreas;
        for (let j = 0; j < PAs.length; j++) {
            addPARow(PAs[j].PracticeAreaName, reportRows);
            let practices = PAs[j].Practices;
            for (let k = 0; k < practices.length; k++) {
                let practice = practices[k];

                addPracticeRow(reportOptions.HasCharacterizations, practice.PracticeName, practice.Statement, practice.Characterization, reportRows);

                if (reportOptions.HasNotes) {
                    addPracticeFindingsRow("Notes", practice.Notes, reportRows);
                }
                if (reportOptions.HasStrengths) {
                    addPracticeFindingsRow("Strengths", practice.Strengths, reportRows);
                }
                if (reportOptions.HasWeaknesses) {
                    addPracticeFindingsRow("Weaknesses", practice.Weaknesses, reportRows);
                }
                if (reportOptions.HasIOs) {
                    addPracticeFindingsRow("IOs", practice.IOs, reportRows);
                }

                addBlankRow(reportRows);
            }
        }
    }
    return reportRows;
}

const FindingsReportView = ({ reportType, projects, findingsData, reportOptions }) => {
    const projectSubtitle = getProjectSubTitle(projects);
    let reportRows = [];

    let projectList = "";
    let noDataText = " - No results found";
    let hasProjectWithNoData = false;


    projects.forEach(project => {
        let hasProject = false;
        for (let i = 0; i < findingsData.length; i++) {
            if (findingsData[i].ProjectName === project.name) {
                hasProject = true;
            }
        }
        if (!hasProject) {
            hasProjectWithNoData = true;
            projectList += project.name + ", ";
        }
    });

    const InsertNoDataRow = () => {
        projectList = projectList.substring(0, projectList.lastIndexOf(','));
        let noResults = projectList.concat(noDataText);

        if (hasProjectWithNoData) {
            return (<div>
                {
                    noResults
                }
            </div>)
        }
    }

    let dateTime = getCurrentDateTime();

    if (findingsData.length !== 0) {
        switch (reportType) {
            case "allFindings":
                reportRows = formatAllNotesReportData(findingsData);
                break;

            case "practiceAreaFindings":
                reportRows = formatPracticeAreaReportData(findingsData);
                break;

            case "practiceGroupFindings":
                reportRows = formatPracticeGroupReportData(findingsData);
                break;

            case "practiceFindings":
                reportRows = formatPracticeReportData(reportOptions, findingsData);
                break;

            default: break;
        }
    }

    return (
        <React.Fragment>
            <Row style={{ height: 'auto', marginBottom: '0px' }}>
                <Col>
                    <p style={{ textAlign: 'right', fontSize: '18px' }}><b>Created for Project(s):</b></p>
                </Col>
                <Col>
                    <p style={{ textAlign: "left", fontSize: '18px' }}>{projectSubtitle}</p>
                </Col>
            </Row>
            <Row style={{ height: '20px', marginBottom: '8px' }}>
                <Col>
                    <p style={{ textAlign: "right" }}><small><b>Date/Time Generated:</b></small></p>
                </Col>
                <Col>
                    <p style={{ textAlign: "left" }}><small>{dateTime}</small></p>
                </Col>
            </Row>
            <hr class='my-2' style={{ height: '1px', borderWidth: '0', color: 'dimgray', backgroundColor: 'dimgray' }} />
            <div style={{ display: "grid", gridTemplateColumns: "15% 70% 15%", gridTemplateRows: "100%" }}>
                <div style={{ gridColumnStart: "2", gridRowStart: "1", border: 'none' }} >
                    {reportRows}
                    <h5 style={{ background: 'rgb(0,168,168)' }}><b>{InsertNoDataRow()}</b></h5>
                </div>
            </div>
            {reportOptions.HasCharacterizations &&
                <Col>
                    <Row>
                        {CharacterizationsLegend}
                    </Row>
                </Col>
            }
        </React.Fragment>
    );
}
export default FindingsReportView;