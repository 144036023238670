import React, { useState, useEffect } from 'react';
import { modelContentUtils } from '../utils/helpers';
import { AppraisalRepository, AppraisalStateRepository } from '../utils/repositories';
import useArray from '../hooks/useArray';
import LoadingComponent from '../presenters/LoadingComponent';
import ReportFragment from '../presenters/ReportFragment';
import MultiSelectListReportOptions from '../presenters/MultiSelectListReportOptions';
import CompliancePercentReportView from '../presenters/CompliancePercentReportView';
import { PRACTICES, PRACTICES_AREAS_BY_MATURITY_LEVEL, PRACTICE_AREA_RELATIONSHIPS } from '../model/modelcontent';
import { Form, Col, Row, Button, Spinner, Modal, Tabs, Tab, FormControl } from 'react-bootstrap';
import NewWindow from 'react-new-window';

const PRACTICE_STATE_RANKS = {
    "Not Yet Implemented": { value: 4, state: "Not Yet Implemented" },
    "Not Examined": { value: 5, state: "Not Examined" },
    //evaluation ranks
    "Major Issues": { value: 3, state: "Major Issues" },
    "Minor Issues": { value: 2, state: "Minor Issues" },
    "No Issues": { value: 1, state: "No Issues" },
    //benchmark/sustainment ranks
    "Does Not Meet": { value: 3, state: "Does Not Meet" },
    "Partially Meets": { value: 2, state: "Partially Meets" },
    "Largely Meets": { value: 2, state: "Largely Meets" },
    "Fully Meets": { value: 1, state: "Fully Meets" }
}

async function fetchData(setDataLoading, setAppraisalData, projects, practiceAreas) {
    const currentAppraisalId = localStorage.getItem("selectedAppraisal");
    if (currentAppraisalId) {
        const apiRepo = new AppraisalRepository(null, null, { selected: false });
        const appraisalData = await apiRepo.getOne(currentAppraisalId, false);
        if (!appraisalData.error) {
            projects.setValue(appraisalData.returnValue.projects.filter(x => x.name !== "Organizational Roll-up"));
            setAppraisalData({
                id: appraisalData.returnValue.id,
                name: appraisalData.returnValue.name,
                view: appraisalData.returnValue.view
            });
            const maturityLevel = modelContentUtils.getMaturityLevelFromView(appraisalData.returnValue.view);
            if (appraisalData.returnValue.view.includes("Development")) {
                practiceAreas.setValue(PRACTICES_AREAS_BY_MATURITY_LEVEL.DEV[maturityLevel]);
            } else {
                practiceAreas.setValue(PRACTICES_AREAS_BY_MATURITY_LEVEL.SVC[maturityLevel]);
            }
        } else {
            console.log("error getting appraisal data: ", appraisalData.returnValue);
        }
    }

    setDataLoading(false);
}

//since the data in the database cannot reflect not examined, we must rely on model data to set defaults
function buildEmptyComplianceChartData(selectedProjects, maturityLevel,includeLevel1) {
    let complianceData = {};
    for (let i = 0; i < selectedProjects.length; i++) {
        let practiceAreas = selectedProjects[i].practiceAreas;
        for (let i = 0; i < practiceAreas.length; i++) {

            //the total possible evaluations for this practice area by selected maturity levelj
            let totalPracticesForMaturity = 0;
            if(includeLevel1){
                totalPracticesForMaturity = PRACTICE_AREA_RELATIONSHIPS[practiceAreas[i]].filter(x => x.Level <= maturityLevel).length;
            }
            else{
                totalPracticesForMaturity = PRACTICE_AREA_RELATIONSHIPS[practiceAreas[i]].filter(x => x.Level > 1 && x.Level <= maturityLevel).length;
            }

            //if the practice area does not already exist in the collection,
            //make a placeholder object for it
            if (complianceData[practiceAreas[i]] === undefined) {
                complianceData[practiceAreas[i]] = {
                    lowRisk: 0,
                    mediumRisk: 0,
                    highRisk: 0,
                    notImplemented: 0,
                    notExamined: totalPracticesForMaturity
                };
            }
            //if the practice area has already been added from a previous project,
            //add the total not examined practices to the placeholder
            else {
                complianceData[practiceAreas[i]].notExamined = complianceData[practiceAreas[i]].notExamined + totalPracticesForMaturity;
            }
        }
    }
    return complianceData;
}

function buildComplianceChartData(complianceData, stateData, includeLevel1) {
    for (let i = 0; i < stateData.length; i++) {
        const currentState = stateData[i].state;
        const assocPractice = PRACTICES[stateData[i].nodeId];
        const currentStateRank = PRACTICE_STATE_RANKS[currentState];

        //If we don't get an associated practice back then the state is associated
        // with either an appraisal or a level.
        if (assocPractice && currentStateRank !== undefined) {

            //do not include level 1 practices unless specified
            if(!includeLevel1 && assocPractice.Level === 1){
                continue;
            }

            const practiceAreaIndex = assocPractice.PracticeAreaID;

            //we have data for this item, remove it from not examined before setting actual value
            complianceData[practiceAreaIndex].notExamined = complianceData[practiceAreaIndex].notExamined - 1;

            //update the placeholder objects with data from the database
            switch (currentStateRank.value) {
                case 1:
                    complianceData[practiceAreaIndex].lowRisk = complianceData[practiceAreaIndex].lowRisk + 1;
                    break;
                case 2:
                    complianceData[practiceAreaIndex].mediumRisk = complianceData[practiceAreaIndex].mediumRisk + 1;
                    break;
                case 3:
                    complianceData[practiceAreaIndex].highRisk = complianceData[practiceAreaIndex].highRisk + 1;
                    break;
                case 4:
                    complianceData[practiceAreaIndex].notImplemented = complianceData[practiceAreaIndex].notImplemented + 1;
                    break;
                case 5:
                    complianceData[practiceAreaIndex].notExamined = complianceData[practiceAreaIndex].notExamined + 1;
                    break;
                default:
                    break;
            }
        }
    }
    return complianceData;
}

const CompliancePercentReportModal = (props) => {
    const [dataLoading, setDataLoading] = useState(true);
    const [optionsVisible, setOptionsVisible] = useState(true);
    const [appraisalData, setAppraisalData] = useState({});
    const [reportLoading, setReportLoading] = useState(false);
    const [hasProjectSelected, setHasProjectSelected] = useState("false");
    const [includeLevel1, setIncludeLevel1] = useState(false);

    const [displayValue, setDisplayValue] = useState("tabbed");
    const allReportData = useArray([]);

    const projects = useArray([]);
    const practiceAreas = useArray([]);

    useEffect(() => {
        if (props.showModal) {
            const getData = () => fetchData(setDataLoading, setAppraisalData, projects, practiceAreas);
            getData();

            setIncludeLevel1(false);
            setHasProjectSelected(false);
            setOptionsVisible(true);
            setDisplayValue("tabbed");
            allReportData.clear();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showModal]);

    const handleProjectSelectionChanged = (event) => {
        const allOptions = event.target.options;
        for (let i = 0; i < allOptions.length; i++) {
            const currentOption = allOptions[i];
            let project = projects.getById(currentOption.value);
            project.selected = currentOption.selected;
            projects.replace(currentOption.value, project);
        }
        setHasProjectSelected(projects.value.filter(p => p.selected === true).length > 0 ? true : false);
    }

    const handleGenerateReportClicked = async (event) => {
        setReportLoading(true);
        const maturityLevel = modelContentUtils.getMaturityLevelFromView(appraisalData.view);
        const selectedProjects = projects.value.filter(p => p.selected === true);
        let reportIndex = 1;

        //individual projects
        for (let i = 0; i < selectedProjects.length; i++) {
            let complianceReportData = buildEmptyComplianceChartData([selectedProjects[i]], maturityLevel, includeLevel1);
            const stateRepo = new AppraisalStateRepository();
            const currentProject = selectedProjects[i];
            const response = await stateRepo.getAll(appraisalData.id, currentProject.id);
            if (!response.error) {
                complianceReportData = buildComplianceChartData(complianceReportData, response.returnValue, includeLevel1);
            }

            let p = projects.value.filter(p => p.id === currentProject.id);
            allReportData.add({
                title: currentProject.name,
                index: reportIndex,
                projects: p,
                data: complianceReportData
            });
            reportIndex++;
        }

        let eligibleProjects = selectedProjects.filter(x => x.name !== "Organizational Roll-up");
        let excludingRollUp = eligibleProjects.length !== selectedProjects.length;

        if (eligibleProjects.length > 1) {
            let complianceReportData = buildEmptyComplianceChartData(selectedProjects, maturityLevel, includeLevel1);
            const stateRepo = new AppraisalStateRepository();
            for (let i = 0; i < selectedProjects.length; i++) {
                const currentProject = selectedProjects[i];
                const response = await stateRepo.getAll(appraisalData.id, currentProject.id);
                if (!response.error) {
                    complianceReportData = buildComplianceChartData(complianceReportData, response.returnValue, includeLevel1);
                }
            }

            let title = excludingRollUp ? "Cumulative (Roll-up excluded)" : "Cumulative";
            allReportData.add({
                title: title,
                index: reportIndex,
                projects: eligibleProjects,
                data: complianceReportData
            });
        }

        setReportLoading(false);
        setOptionsVisible(false);
        props.setShowModal(false);
    }

    const handleDisplayChanged = (value) => {
        setDisplayValue(value);
    }

    const levelOneCheckBoxChanged = () => {
        setIncludeLevel1(!includeLevel1);
    }

    const tabFormattedReportData = allReportData.value.map(o => {
        return (
            <Tab eventKey={o.index} title={o.title} style={{ color: 'dimgray' }}>
                <Tab.Pane eventKey={o.index}>
                    <CompliancePercentReportView
                        projects={o.projects}
                        reportData={o.data}
                        practiceRanks={PRACTICE_STATE_RANKS} />
                </Tab.Pane>
            </Tab>
        )
    });

    const singlePageFormattedReportData = allReportData.value.map(o => {
        return (
            <div>
                <CompliancePercentReportView
                    projects={o.projects}
                    reportData={o.data}
                    practiceRanks={PRACTICE_STATE_RANKS} />

                <br /><br /><br />
            </div>
        )
    });

    const projectSelectionDisplayOptions = {
        title: "Projects",
        displayKey: "name",
        filterAvailable: false
    };

    const viewReportTitle = `Compliance Percent Report`
    const scopeHeader = "Level:"
    const appraisalHeader = 'Appraisal:'

    const spacingStyle = {
        marginBottom: "10px",
        maxHeight: '6rem'
    };

    const viewReportStyle = {
        textAlign: "center",
        paddingTop: "0px",
        color: 'black',
        fontSize: '22px'
    }


    return (
        <React.Fragment>
            <Modal show={props.showModal} onHide={(e) => props.setShowModal(false)} dialogClassName="reportModalStyle">
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '18px', color: 'dimgray' }}>
                        <img
                            src={require('../assets/icon.png')}
                            width="30"
                            height="30"
                            className="d-inline-block align-middle"
                            alt="CAP logo"
                        />{' '}Compliance Percent Report</Modal.Title>
                </Modal.Header>
                <LoadingComponent isLoading={dataLoading} iconSize={80}>
                    <ReportFragment visible={optionsVisible}>
                        <div style={{ display: "grid", gridTemplateColumns: "10% 80% 10%", gridTemplateRows: "100%", marginTop: '-50px' }}>
                            <div style={{ gridColumnStart: "2", gridRowStart: "1" }}>
                                <Form>
                                    <Form.Group as={Row} className="d-flex justify-content-center">

                                        <Form.Label column md='3' style={{ color: 'white', backgroundColor: 'rgb(0,168,168)', fontSize: '16px', textAlign: 'center', padding: '.5rem' }}>
                                            Appraisal
                                        </Form.Label>
                                        <Col md={6} style={{ textAlign: 'left' }}>
                                            <Form.Control plaintext readOnly value={appraisalData.name} style={{ color: 'dimgray' }} />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="d-flex justify-content-center" >

                                        <Form.Label
                                            column md='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                padding: '.5rem'
                                            }}>
                                            Projects
                                        </Form.Label>
                                        <Col md={6} style={{ textAlign: 'left' }}>
                                            <MultiSelectListReportOptions listData={projects.value} style={spacingStyle}
                                                handleSelectionChanged={handleProjectSelectionChanged}
                                                displayOptions={projectSelectionDisplayOptions} />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="d-flex justify-content-center" >
                                        <Form.Label
                                            column sm='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                padding: '.5rem'
                                            }}>
                                            Display
                                        </Form.Label>
                                        <Col sm={6} style={{ textAlign: 'left' }}>
                                            <FormControl
                                                as="select"
                                                style={{ borderRightColor: 'white', borderTopColor: 'white' }}
                                                onChange={(e) => handleDisplayChanged(e.target.value)}>
                                                <option value='tabbed'>Tabbed</option>
                                                <option value='singlePage'>Single Page</option>
                                            </FormControl>
                                        </Col>
                                    </Form.Group>
                                    <Row className="d-flex justify-content-center">
                                        <Form.Group controlId="formBasicCheckbox">
                                            <Form.Check type="checkbox" label="Include Level 1 Practices"
                                                style={{ color: "dimgray" }}
                                                checked={includeLevel1}
                                                onChange={levelOneCheckBoxChanged}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row className="d-flex justify-content-center">
                                        <Button className='text-nowrap'
                                            variant="outline-light"
                                            style={{
                                                background: 'rgb(0, 168, 168)',
                                                color: 'white',
                                                border: 'none',
                                                justifyContent: 'center',
                                                padding: '.5rem',
                                                marginTop: '1rem',
                                                marginBottom: '1rem'
                                            }}
                                            size='sm'
                                            disabled={!hasProjectSelected}
                                            onClick={(e) => handleGenerateReportClicked(e)}
                                        >
                                            {reportLoading &&
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size='sm'
                                                    role="status"
                                                    aria-hidden="true"
                                                />}
                                            {!reportLoading ? "Generate Compliance Percent Report" : ' Generating Report...'}
                                        </Button>{' '}
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </ReportFragment>
                </LoadingComponent>
            </Modal>

            {!optionsVisible &&
                <NewWindow>
                    <ReportFragment
                        reportName={viewReportTitle}
                        reportHeaderStyle={viewReportStyle}
                        scopeHeader={scopeHeader}
                        scope={appraisalData.view}
                        appraisalHeader={appraisalHeader}
                        appraisalName={appraisalData.name}>
                        {displayValue === "tabbed" ?
                            <div>
                                <br /><br />
                                <Tabs defaultActiveKey={1} id="tabControl">
                                    {tabFormattedReportData}
                                </Tabs>
                            </div>
                            :
                            <div>
                                <br /><br />
                                {singlePageFormattedReportData}
                            </div>
                        }
                    </ReportFragment>
                </NewWindow>
            }
        </React.Fragment>
    );
}

export default CompliancePercentReportModal;