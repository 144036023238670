import React, { useState, useEffect } from 'react';
import { Button, Spinner, Row, Col, Form, FormControl, Modal } from 'react-bootstrap';
import { modelContentUtils } from '../utils/helpers';
import { AppraisalRepository } from '../utils/repositories';
import useArray from '../hooks/useArray';
import LoadingComponent from '../presenters/LoadingComponent';
import ReportFragment from '../presenters/ReportFragment';
import MultiSelectListReportOptions from '../presenters/MultiSelectListReportOptions';
import QuestionsReportView from '../presenters/QuestionsReportView';
import { PRACTICE_AREAS } from '../model/practiceareas';
import { PRACTICE_AREA_RELATIONSHIPS } from '../model/practicearearelationships';
import { PRACTICES } from '../model/practices';
import { Constants, getDefaultAPIHeaders } from '../utils/helpers';
import { API } from 'aws-amplify';
import NewWindow from 'react-new-window';

async function fetchData(setDataLoading, setAppraisalName, setPracticeAreas, setProjects, setInterviewSessionData, setInterviewQuestionData, switchOptions) {
    setDataLoading(true);
    const currentAppraisalId = localStorage.getItem("selectedAppraisal");
    if (currentAppraisalId) {
        const apiRepo = new AppraisalRepository(null, null, { selected: false, availableForSelection: false });
        const appraisalData = await apiRepo.getOne(currentAppraisalId, false);
        if (!appraisalData.error) {
            setAppraisalName(appraisalData.returnValue.name);
            setProjects(appraisalData.returnValue.projects.filter(x => x.name !== "Organizational Roll-up"));
            setPracticeAreas(modelContentUtils.getPracticeAreasFromAppraisalProjects(appraisalData.returnValue.projects, true, true));

            let interviewSessionData = await getInterviewSessionData(currentAppraisalId);
            setInterviewSessionData(interviewSessionData);

            let interviewQuestionData = await getInterviewQuestionData(currentAppraisalId);
            setInterviewQuestionData(interviewQuestionData);

            switchOptions(interviewSessionData);

        } else {
            console.log("error getting appraisal data: ", appraisalData.returnValue);
        }
    }
    setDataLoading(false);
}

async function getInterviewQuestionData(currentAppraisalId) {

    let interviews = [];
    const interviewsData = await API.get(Constants.API_PATH, `${Constants.INTERVIEW_QUESTIONS_PATH}/${currentAppraisalId}`, await getDefaultAPIHeaders());
    interviewsData.forEach(is => {
        interviews.push({
            id: is.interview_question_id,
            nodeId: is.node_id,
            projectId: is.project_id,
            question: is.question,
            sessions: is.sessions
        });
    });

    return interviews;
}

async function getInterviewSessionData(currentAppraisalId) {

    let interviewSessions = [];
    const interviewSessionData = await API.get(Constants.API_PATH, `${Constants.INTERVIEW_PATH}/${currentAppraisalId}`, await getDefaultAPIHeaders());
    interviewSessionData.forEach(is => {
        interviewSessions.push({
            id: is.interview_session_id,
            hours: is.hours,
            interviewee: is.interviewee
        });
    });
    return interviewSessions;
}

const InterviewQuestionsReportModal = (props) => {
    const [dataLoading, setDataLoading] = useState(true);
    const [optionsVisible, setOptionsVisible] = useState(true);
    const [appraisalName, setAppraisalName] = useState('');
    const [reportLoading, setReportLoading] = useState(false);
    const [interviewSessionData, setInterviewSessionData] = useState({});
    const [interviewQuestionData, setInterviewQuestionData] = useState({});
    const [practiceAreas, setPracticeAreas] = useState({});
    const [projects, setProjects] = useState({});
    const [filterValue, setFilterValue] = useState("session");
    const [reportData, setReportData] = useState({});
    const [filterSelectionLabel, setFilterSelectionLabel] = useState("Interview Session");
    const [hasOptionSelected, setHasOptionSelected] = useState("false");
    const [addBlankLines, setAddBlankLines] = useState(false);
    const [addEvidence, setAddEvidence] = useState(false);
    const options = useArray([]);

    useEffect(() => {
        if (props.showModal) {
            const getData = () => fetchData(setDataLoading, setAppraisalName, setPracticeAreas, setProjects, setInterviewSessionData, setInterviewQuestionData, switchOptions);
            getData();
            setAddBlankLines(false);
            setAddEvidence(false);
            setHasOptionSelected(false);
            setOptionsVisible(true);
            setFilterSelectionLabel("Interview Session");
            setFilterValue("session");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showModal]);


    function switchOptions(data) {
        const formattedOptions = data
            .map(option => {
                return {
                    ...option,
                    selected: false,
                    availableForSelection: true
                }
            });
        options.setValue(formattedOptions);
    }

    const handleOptionsChanged = (event) => {
        const allOptions = event.target.options;
        let selectedOptions = [];
        for (let i = 0; i < allOptions.length; i++) {
            const currentOption = allOptions[i];
            if (currentOption.selected) {
                selectedOptions.push(currentOption.value);
            }
        }
        const newOptions = options.value.slice()
            .map(pa => {
                if (selectedOptions.includes(pa.id)) {
                    return {
                        ...pa,
                        selected: true
                    }
                } else {
                    return {
                        ...pa,
                        selected: false
                    }
                }
            });
        options.setValue(newOptions);

        setHasOptionSelected(selectedOptions.length > 0 ? true : false);
    }

    function getInterviewQuestionsBySession(sessionId) {
        let questions = [];
        for (let i = 0; i < interviewQuestionData.length; i++) {
            for (let j = 0; j < interviewQuestionData[i].sessions.length; j++) {
                let session = interviewQuestionData[i].sessions[j];
                if (session === sessionId) {
                    questions.push(interviewQuestionData[i]);
                }
            }
        }
        return questions;
    }

    function getInterviewQuestionsByNode(nodeId) {
        let questions = [];
        for (let i = 0; i < interviewQuestionData.length; i++) {
            if (interviewQuestionData[i].nodeId === nodeId) {
                questions.push(interviewQuestionData[i]);
            }
        }
        return questions;
    }

    function getSessionNamesFromQuestion(interviewQuestionData) {
        let sessionNames = [];
        for (let i = 0; i < interviewQuestionData.sessions.length; i++) {
            for (let j = 0; j < interviewSessionData.length; j++) {
                if (interviewQuestionData.sessions[i] === interviewSessionData[j].id) {
                    sessionNames.push(interviewSessionData[j].interviewee);
                }
            }
        }
        return sessionNames.join(', ');
    }

    function getSessionQuestionReportData(interviewSessions, evidence) {

        let reportEntries = [];

        for (let i = 0; i < interviewSessions.length; i++) {
            let session = interviewSessions[i].interviewee;
            let hours = interviewSessions[i].hours;
            let currentQuestions = getInterviewQuestionsBySession(interviewSessions[i].id);
            let questionCount = currentQuestions.length;

            let practiceAreaData = [];
            for (let j = 0; j < practiceAreas.length; j++) {
                const practiceNodes = PRACTICE_AREA_RELATIONSHIPS[practiceAreas[j].id];
                let practiceAreaName = practiceAreas[j].name;

                let practiceData = [];
                for (let k = 0; k < practiceNodes.length; k++) {
                    let practiceName = PRACTICES[practiceNodes[k].Id].Abbreviation;
                    let statement = PRACTICES[practiceNodes[k].Id].Statement;

                    let questionData = [];
                    for (let l = 0; l < currentQuestions.length; l++) {
                        if (currentQuestions[l].nodeId === practiceNodes[k].Id) {
                            let projectName = getProjectName(currentQuestions[l].projectId)
                            let question = currentQuestions[l].question;
                            questionData.push({
                                question,
                                projectName
                            });
                        }
                    }

                    let evidenceData = [];
                    if (addEvidence) {
                        for (let l = 0; l < evidence.length; l++) {
                            if (evidence[l].node_id === practiceNodes[k].Id) {
                                let projectName = getProjectName(evidence[l].project_id);
                                let fileName = evidence[l].evidence_fileName;
                                let notes = evidence[l].evidence_notes;
                                evidenceData.push({
                                    projectName,
                                    fileName,
                                    notes
                                });
                            }
                        }
                    }
                    if (questionData.length > 0) {
                        practiceData.push({
                            practiceName,
                            statement,
                            questionData,
                            evidenceData
                        });
                    }
                }
                if (practiceData.length > 0) {
                    practiceAreaData.push({
                        practiceAreaName,
                        practiceData
                    });
                }
            }
            if (practiceAreaData.length > 0) {
                reportEntries.push({
                    session,
                    hours,
                    questionCount,
                    practiceAreaData
                });
            }
        }
        return reportEntries;
    }

    function getPracticeAreaQuestionReportData(practiceAreas, evidence) {
        let reportEntries = [];

        for (let i = 0; i < practiceAreas.length; i++) {
            let practiceAreaName = PRACTICE_AREAS[practiceAreas[i].id].Name;
            const practiceNodes = PRACTICE_AREA_RELATIONSHIPS[practiceAreas[i].id];

            let practiceData = [];
            for (let j = 0; j < practiceNodes.length; j++) {
                let practiceName = PRACTICES[practiceNodes[j].Id].Abbreviation;
                let statement = PRACTICES[practiceNodes[j].Id].Statement;

                let questionData = [];
                let currentQuestions = getInterviewQuestionsByNode(practiceNodes[j].Id);
                for (let k = 0; k < currentQuestions.length; k++) {
                    let projectName = getProjectName(currentQuestions[k].projectId)
                    let question = currentQuestions[k].question;
                    let sessionNames = getSessionNamesFromQuestion(currentQuestions[k])
                    questionData.push({
                        question,
                        projectName,
                        sessionNames
                    });
                }
                let evidenceData = [];
                if (addEvidence) {
                    for (let l = 0; l < evidence.length; l++) {
                        if (evidence[l].node_id === practiceNodes[j].Id) {
                            let projectName = getProjectName(evidence[l].project_id);
                            let fileName = evidence[l].evidence_fileName;
                            let notes = evidence[l].evidence_notes;
                            evidenceData.push({
                                projectName,
                                fileName,
                                notes
                            });
                        }
                    }
                }
                if (questionData.length > 0) {
                    practiceData.push({
                        practiceName,
                        statement,
                        questionData,
                        evidenceData
                    });
                }
            }
            if (practiceData.length > 0) {
                reportEntries.push({
                    practiceAreaName,
                    practiceData
                });
            }
        }

        return reportEntries;
    }

    async function getAppraisalEvidence(currentAppraisalId) {
        const apiPath = `${Constants.APPRAISAL_EVIDENCE_PATH}/${currentAppraisalId}`
        const data = await API.get(Constants.API_PATH, apiPath, await getDefaultAPIHeaders());
        return data;
    }

    function getProjectName(id) {
        let project = projects.filter(x => x.id === id)[0];
        if (project) {
            return project.name;
        }
        else {
            return "";
        }
    }

    const handleGenerateReportClicked = async (event) => {
        setReportLoading(true);

        let currentAppraisalId = localStorage.getItem("selectedAppraisal");
        let selectedOptions = options.value.filter(p => p.selected === true);

        let questionReportData = [];
        let evidenceData = [];
        if (addEvidence) {
            evidenceData = await getAppraisalEvidence(currentAppraisalId);
        }

        if (filterValue === 'session') {
            questionReportData = getSessionQuestionReportData(selectedOptions, evidenceData);
        }
        else {
            questionReportData = getPracticeAreaQuestionReportData(selectedOptions, evidenceData);
        }

        setReportData(questionReportData);

        setReportLoading(false);
        setOptionsVisible(false);
        props.setShowModal(false);
    }

    const handleFilterChanged = (value) => {
        setFilterValue(value);

        if (value === 'session') {
            setFilterSelectionLabel('Interview Session');
            switchOptions(interviewSessionData);
        }
        else {
            setFilterSelectionLabel('Practice Area');
            switchOptions(practiceAreas);
        }
    }

    const blankLinesCheckBoxChanged = () => {
        setAddBlankLines(!addBlankLines);
    }

    const evidenceCheckBoxChanged = () => {
        setAddEvidence(!addEvidence);
    }

    function getDisplayKey() {
        if (filterValue === 'session') {
            return 'interviewee';
        }
        else {
            return 'name';
        }
    }

    const optionSelectionDisplayOptions = {
        title: "Options",
        displayKey: getDisplayKey(),
        filterAvailable: true,
        availableKey: "availableForSelection",
        size: 25
    };

    const viewReportHeader = `Filter Type:`;
    const appraisalHeader = 'Appraisal:';
    const reportName = 'Interview Questions Report';

    const viewReportTitle = () => {
        let type = filterValue === 'session' ? 'Interview Session' : 'Practice Area';
        return type;
    }

    const viewReportStyle = {
        textAlign: "right",
        paddingTop: "0px",
        color: 'black',
        marginTop: '16px',
        fontSize: '22px'
    }

    return (
        <React.Fragment>
            <Modal show={props.showModal} onHide={(e) => props.setShowModal(false)} dialogClassName="findingsReportModalStyle">
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '18px', color: 'dimgray' }}>
                        <img
                            src={require('../assets/icon.png')}
                            width="30"
                            height="30"
                            className="d-inline-block align-middle"
                            alt="CAP logo"
                        />{' '}Interview Questions Report</Modal.Title>
                </Modal.Header>
                <LoadingComponent isLoading={dataLoading} iconSize={80}>
                    <ReportFragment visible={optionsVisible} >
                        <Row className="d-flex justify-content-center" style={{ marginTop: '-50px' }}>
                            <Col md={5}>
                                <Form>
                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <Form.Label
                                            column sm='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                padding: '.5rem'
                                            }}>
                                            Appraisal
                                </Form.Label>
                                        <Col sm={6} style={{ textAlign: 'left' }}>
                                            <Form.Control plaintext readOnly value={appraisalName} style={{ color: 'dimgray', paddingLeft: '1rem' }} />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <Form.Label
                                            column sm='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                padding: '.5rem'
                                            }}>
                                            Filter By
                                </Form.Label>
                                        <Col sm={6} style={{ textAlign: 'left' }}>
                                            <FormControl
                                                as="select"
                                                style={{ borderRightColor: 'white', borderTopColor: 'white' }}
                                                onChange={(e) => handleFilterChanged(e.target.value)}>
                                                <option value='session'>Interview Session</option>
                                                <option value='practiceArea'>Practice Area</option>
                                            </FormControl>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <Col sm={3}></Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <Col sm={3}></Col>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col md={6}>
                                <Form>
                                    <Form.Group as={Row} className="d-flex justify-content-center" >
                                        <Form.Label
                                            column sm='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                padding: '.5rem'
                                            }}>
                                            {filterSelectionLabel}
                                        </Form.Label>
                                        <Col>
                                            <MultiSelectListReportOptions listData={options.value}
                                                handleSelectionChanged={handleOptionsChanged}
                                                displayOptions={optionSelectionDisplayOptions} />
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>

                        <Row className="d-flex justify-content-center">
                            <Form.Group controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Add blank lines"
                                    style={{ color: "dimgray" }}
                                    checked={addBlankLines}
                                    onChange={blankLinesCheckBoxChanged}
                                />
                                <Form.Check type="checkbox" label="Add evidence"
                                    style={{ color: "dimgray" }}
                                    checked={addEvidence}
                                    onChange={evidenceCheckBoxChanged}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="d-flex justify-content-center">
                            <Button
                                variant="outline-light"
                                disabled={!hasOptionSelected}
                                style={{
                                    background: 'rgb(0, 168, 168)',
                                    color: 'white',
                                    border: 'none',
                                    justifyContent: 'center',
                                    padding: '.5rem',
                                    marginTop: '1rem',
                                    marginBottom: '1rem'
                                }}
                                size='sm'
                                onClick={(e) => handleGenerateReportClicked(e)}>
                                {reportLoading &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size='sm'
                                        role="status"
                                        aria-hidden="true"
                                    />}
                                {!reportLoading ? "Generate Interview Questions Report" : ' Generating Report...'}
                            </Button>{' '}
                        </Row>
                    </ReportFragment>
                </LoadingComponent>
            </Modal>
            {!optionsVisible &&
                <NewWindow>
                    <ReportFragment
                        reportName={reportName}
                        reportType={viewReportTitle()}
                        reportHeader={viewReportHeader}
                        reportHeaderStyle={viewReportStyle}
                        appraisalHeader={appraisalHeader}
                        appraisalName={appraisalName}>
                        <QuestionsReportView
                            filterType={filterValue}
                            addBlankLines={addBlankLines}
                            addEvidence={addEvidence}
                            reportData={reportData} />
                    </ReportFragment>
                </NewWindow>
            }
        </React.Fragment>
    );
}
export default InterviewQuestionsReportModal;