import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Finding from './Finding';
import logo from '../assets/node-page-bottom.png';

function Findings({ findings, setFindings, nodeInfo, handleFindingUpdated, showMergeConflictModal }) {
    return (
        <Container>
            <div>
                <Finding name="notes"
                    finding={findings.notes}
                    setFinding={setFindings}
                    nodeInfo={nodeInfo}
                    handleFindingUpdated={handleFindingUpdated}
                    showMergeConflictModal={showMergeConflictModal} />
            </div>
            {
                nodeInfo.isPractice ?
                    <React.Fragment>                            
                        <div >
                            <Finding
                                finding={findings.strengths}
                                setFinding={setFindings}
                                nodeInfo={nodeInfo}
                                handleFindingUpdated={handleFindingUpdated}
                                showMergeConflictModal={showMergeConflictModal} />
                            <Finding
                                finding={findings.weaknesses}
                                setFinding={setFindings}
                                nodeInfo={nodeInfo}
                                handleFindingUpdated={handleFindingUpdated}
                                showMergeConflictModal={showMergeConflictModal} />
                            <Finding
                                finding={findings.improvement_opportunities}
                                setFinding={setFindings} nodeInfo={nodeInfo}
                                handleFindingUpdated={handleFindingUpdated}
                                showMergeConflictModal={showMergeConflictModal} />
                        </div>
                    </React.Fragment> :
                    <Container>
                        <Row>
                            <Col md={6}>
                                <Image bsPrefix='img' src={logo} fluid rounded style={{ position: 'fixed', bottom: '0px', width: '45%' }} />
                            </Col>
                        </Row>
                    </Container>
            }            
        </Container>
    );
}
export default Findings;