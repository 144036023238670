import React from 'react';
import { Navbar } from 'react-bootstrap';
import brand from '../assets/icon.png'

export const LoginNavigationBar = () => (

    <Navbar expand="lg">
        <Navbar.Brand>
            <img                
                src={brand}
                width="30"
                height="30"
                className="d-inline-block align-top"
                alt="CAP logo and text"
            />{' '}
                CAP
            </Navbar.Brand>
    </Navbar>
)