import React from 'react';
import { Row, Col } from 'react-bootstrap'
import { RATINGS_ICON_MAP } from '../utils/IconMap';
import { getCurrentDateTime } from '../utils/helpers';
import { RatingsLegend } from '../utils/IconLegends';

function getCharacterizationIcon(value) {
    if (value === "") {
        return RATINGS_ICON_MAP["Not Examined"];
    }
    else {
        return RATINGS_ICON_MAP[value]
    }
}

function formatRatingsReportData(ratingsData, cellStyle) {
    let reportRows = []
    for (let i = 1; i <= 5; i++) {
        let currentLevelData = ratingsData.LevelData.filter(x => x.Level === i.toString());

        const cellColorStyle = {
            ...cellStyle
        };

        if (currentLevelData.length > 0) {
            let tableCells = [];
            let firstCellContent = "Practice Group Level " + i;
            let firstCell = (
                <td style={{ ...cellStyle, fontWeight: "bold", background: 'rgb(0,168,168)' }}>{firstCellContent}</td>
            );
            tableCells.push(firstCell);

            for (let j = 0; j < ratingsData.PAData.length; j++) {

                let correspondingLevelData = currentLevelData.filter(x => x.PAName === ratingsData.PAData[j].Name)[0];
                if (!correspondingLevelData) {
                    let newCell = (
                        <td style={{ border: "1px solid black", textAlign: "center", backgroundColor: "gray" }}>
                        </td>
                    );
                    tableCells.push(newCell);
                }
                else {
                    let cellValue = correspondingLevelData.State;
                    if (cellValue !== "Not Examined") {
                        let newCell = (
                            <td style={cellColorStyle}>
                                {getCharacterizationIcon(cellValue)}
                            </td>
                        );
                        tableCells.push(newCell);
                    }
                    else {
                        let newCell = (
                            <td style={{ border: "1px solid black", textAlign: "center", backgroundColor: "white" }}>
                            </td>
                        );
                        tableCells.push(newCell);
                    }
                }

            }

            const levelStyle = {
                height: ".1em",
                textAlign: "right",
                fontWeight: "bold",
                paddingRight: "5px"
            };

            const levelRow = (<tr><td style={levelStyle}></td></tr>);
            reportRows.push(levelRow);
            
            reportRows.push(<tr>{tableCells}</tr>);
        }
    }
    return reportRows;
}

const RatingsReportView = ({ ratingsData }) => {

    const cellStyle = {
        border: "1px solid black",
        textAlign: "center"
    };

    const colHeaderStyle = {
        border: "1px solid black",
        textAlign: "center",
        background: 'rgb(0,168,168)'
    };

    const tableHeadings = ratingsData.PAData
        .map(k => {
            return (
                <th style={colHeaderStyle}>{k.Name}</th>
            );
        });

    const paStates = ratingsData.PAData
        .map(k => {
            return (
                <th style={cellStyle}>{getCharacterizationIcon(k.State)}</th>
            );
        });

    let reportRows = [];

    let dateTime = getCurrentDateTime();

    reportRows = formatRatingsReportData(ratingsData, cellStyle);

    return (
        <React.Fragment>
            <Row style={{ height: '20px', marginBottom: '8px' }}>
                <Col>
                    <p style={{ textAlign: "right" }}><small><b>Date/Time Generated:</b></small></p>
                </Col>
                <Col>
                    <p style={{ textAlign: "left" }}><small>{dateTime}</small></p>
                </Col>
            </Row>
            <hr class='my-2' style={{ height: '1px', borderWidth: '0', color: 'dimgray', backgroundColor: 'dimgray' }} />
            <table style={{ width: "100%", height: "50vh", border: "1px solid black", borderCollapse: "separate", borderSpacing: "3px" }}>

                <tr>
                    <th style={{ cellStyle }} />
                    {tableHeadings}
                </tr>
                <tr>
                    <th style={{ cellStyle }} />
                    {paStates}
                </tr>
                <tr style={{height: '25px'}}></tr>
                {reportRows}
            </table>
            <br/>
            <Row>
                {RatingsLegend}
            </Row>
        </React.Fragment>
    );
}
export default RatingsReportView;