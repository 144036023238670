import { MODEL_CONSTANTS } from './modelconstants'

const PRACTICE_AREAS = {
    [MODEL_CONSTANTS.CAR]: {
        Name: 'Causal Analysis and Resolution (CAR)',
        Intent: 'Identify causes of selected outcomes and take action to either prevent recurrence of undesirable outcomes or ensure recurrence of positive outcomes.',
        Value: 'Addressing root cause issues eliminates rework and directly improves quality and productivity.',
        Id: 'CAR',
        LevelIds: {
            1: MODEL_CONSTANTS.CAR_LEVEL_1,
            2: MODEL_CONSTANTS.CAR_LEVEL_2,
            3: MODEL_CONSTANTS.CAR_LEVEL_3,
            4: MODEL_CONSTANTS.CAR_LEVEL_4,
            5: MODEL_CONSTANTS.CAR_LEVEL_5
        },
        AdditionalInfo: '',
        DevExclusive: false,
        SvcExclusive: false,
        Optional: false
    },
    [MODEL_CONSTANTS.DAR]: {
        Name: 'Decision Analysis and Resolution (DAR)',
        Intent: 'Make and record decisions using a recorded process that analyzes alternatives',
        Value: 'Increases the objectivity of decision making and the probability of selecting the optimal solution',
        Id: 'DAR',
        LevelIds: {
            1: MODEL_CONSTANTS.DAR_LEVEL_1,
            2: MODEL_CONSTANTS.DAR_LEVEL_2,
            3: MODEL_CONSTANTS.DAR_LEVEL_3
        },
        AdditionalInfo: '',
        DevExclusive: false,
        SvcExclusive: false,
        Optional: false
    },
    [MODEL_CONSTANTS.CM]: {
        Name: 'Configuration Management (CM)',
        Intent: 'Manage the integrity of work products using configuration identification, version control, change control, and audits.',
        Value: 'Reduces loss of work and increases the ability to deliver the correct version of the solution to the customer.',
        Id: 'CM',
        LevelIds: {
            1: MODEL_CONSTANTS.CM_LEVEL_1,
            2: MODEL_CONSTANTS.CM_LEVEL_2
        },
        AdditionalInfo: '',
        DevExclusive: false,
        SvcExclusive: false,
        Optional: false
    },
    [MODEL_CONSTANTS.CONT]: {
        Name: 'Continuity (CONT)',
        Intent: 'Plan mitigation activities for significant disruptions to business operations so that work can continue or resume.',
        Value: 'Enables continued operation when serious disruptions or catastrophic events occur.',
        Id: 'CONT',
        LevelIds: {
            1: MODEL_CONSTANTS.CONT_LEVEL_1,
            2: MODEL_CONSTANTS.CONT_LEVEL_2,
            3: MODEL_CONSTANTS.CONT_LEVEL_3
        },
        AdditionalInfo: '',
        DevExclusive: false,
        SvcExclusive: true,
        Optional: false
    },
    [MODEL_CONSTANTS.EST]: {
        Name: 'Estimating (EST)',
        Intent: 'Estimate the size, effort, duration, and cost of the work and resources needed to develop, acquire, or deliver the solution.',
        Value: 'Estimation provides a basis for making commitments, planning, and reducing uncertainty, which allows for early corrective actions and increases the likelihood of meeting objectives',
        Id: 'EST',
        LevelIds: {
            1: MODEL_CONSTANTS.EST_LEVEL_1,
            2: MODEL_CONSTANTS.EST_LEVEL_2,
            3: MODEL_CONSTANTS.EST_LEVEL_3
        },
        AdditionalInfo: '',
        DevExclusive: false,
        SvcExclusive: false,
        Optional: false
    },
    [MODEL_CONSTANTS.EVSD]: {
        Name: 'Enabling Virtual Solution Delivery (EVSD)',
        Intent: 'Identifies an approach to ensure smooth and effective virtual solution delivery to customers and affected stakeholders.',
        Value: 'Maximizes delivery effectiveness and efficiency to customers, while reducing the impact and expense from travel and in-person delivery.',
        Id: 'EVSD',
        LevelIds: {
            1: MODEL_CONSTANTS.EVSD_LEVEL_1,
            2: MODEL_CONSTANTS.EVSD_LEVEL_2,
            3: MODEL_CONSTANTS.EVSD_LEVEL_3
        },
        AdditionalInfo: '',
        DevExclusive: false,
        SvcExclusive: false,
        Optional: true
    },
    [MODEL_CONSTANTS.GOV]: {
        Name: 'Governance (GOV)',
        Intent: 'Provides guidance to senior management on their role in the sponsorship and governance of performance, processes, and related activities.',
        Value: 'Minimizes the cost of process implementation, increases the likelihood of meeting objectives, and ensures that the implemented processes support and contribute to the success of the business.',
        Id: 'GOV',
        LevelIds: {
            1: MODEL_CONSTANTS.GOV_LEVEL_1,
            2: MODEL_CONSTANTS.GOV_LEVEL_2,
            3: MODEL_CONSTANTS.GOV_LEVEL_3,
            4: MODEL_CONSTANTS.GOV_LEVEL_4
        },
        AdditionalInfo: '',
        DevExclusive: false,
        SvcExclusive: false,
        Optional: false
    },
    [MODEL_CONSTANTS.II]: {
        Name: 'Implementation Infrastructure (II)',
        Intent: 'Ensures that the processes and assets important to an organization\'s performance are persistently and habitually followed, used and improved.',
        Value: 'Sustains the ability to consistently achieve goals and objectives efficiently and effectively.',
        Id: 'II',
        LevelIds: {
            1: MODEL_CONSTANTS.II_LEVEL_1,
            2: MODEL_CONSTANTS.II_LEVEL_2,
            3: MODEL_CONSTANTS.II_LEVEL_3
        },
        AdditionalInfo: '',
        DevExclusive: false,
        SvcExclusive: false,
        Optional: false
    },
    [MODEL_CONSTANTS.IRP]: {
        Name: 'Incident Resolution and Prevention (IRP)',
        Intent: 'Resolve and prevent disruptions promptly to sustain service delivery levels.',
        Value: 'Minimize the impact of disruptions to meet objectives and customer commitments more effectively.',
        Id: 'IRP',
        LevelIds: {
            1: MODEL_CONSTANTS.IRP_LEVEL_1,
            2: MODEL_CONSTANTS.IRP_LEVEL_2,
            3: MODEL_CONSTANTS.IRP_LEVEL_3
        },
        AdditionalInfo: '',
        DevExclusive: false,
        SvcExclusive: true,
        Optional: false
    },
    [MODEL_CONSTANTS.MPM]: {
        Name: 'Managing Performance and Measurement (MPM)',
        Intent: 'Manage performance using measurement and analysis to achieve business objectives.',
        Value: 'Maximizes business return on investment by focusing management and improvement efforts on cost, schedule, and quality performance.',
        Id: 'MPM',
        LevelIds: {
            1: MODEL_CONSTANTS.MPM_LEVEL_1,
            2: MODEL_CONSTANTS.MPM_LEVEL_2,
            3: MODEL_CONSTANTS.MPM_LEVEL_3,
            4: MODEL_CONSTANTS.MPM_LEVEL_4,
            5: MODEL_CONSTANTS.MPM_LEVEL_5
        },
        AdditionalInfo: `Managing performance helps to:

• Ensure that benefits and business performance are the leading factors in driving performance and improvement

• Change the paradigm from “process improvement leads to performance improvement”

to “performance is the primary driver of process improvement”

• Use the results of measurement and analysis to manage and control performance at various work and business levels

Performance and measurement management includes:

• Setting objectives for:

    o The business

    o Measurement and performance

    o Quality and process performance

• Allocating and tracing objectives to subordinate levels in the business and processes

• Defining measurements to improve the understanding of progress towards achieving the objectives

• Analyzing measurement and performance data to:

    o Understand the relationship and interactions between performance and process 

    o Define and take actions to address any observed issues with achieving objectives 

    o Make the performance results and related benefits clearly visible to all stakeholders

Measurement and performance objectives are quantitative or qualitative objectives that do not require the additional rigor of statistical and other quantitative techniques. Quality and process performance objectives apply to High Maturity activities using statistical and other quantitative techniques. These objectives include the use of statistical and other quantitative techniques on the related data.
        `,
        DevExclusive: false,
        SvcExclusive: false,
        Optional: false
    },
    [MODEL_CONSTANTS.MC]: {
        Name: 'Monitor and Control (MC)',
        Intent: 'Provide an understanding of the project progress so appropriate corrective actions can be taken when performance deviates significantly from plans.',
        Value: 'Increases the probability of meeting objectives by taking early actions to adjust for significant performance deviations.',
        Id: 'MC',
        LevelIds: {
            1: MODEL_CONSTANTS.MC_LEVEL_1,
            2: MODEL_CONSTANTS.MC_LEVEL_2,
            3: MODEL_CONSTANTS.MC_LEVEL_3
        },
        AdditionalInfo: '',
        DevExclusive: false,
        SvcExclusive: false,
        Optional: false
    },
    [MODEL_CONSTANTS.OT]: {
        Name: 'Organizational Training (OT)',
        Intent: 'Develop the skills and knowledge of personnel so they perform their roles efficiently and effectively.',
        Value: 'Enhances individuals’ skills and knowledge to improve organizational work performance.',
        Id: 'OT',
        LevelIds: {
            1: MODEL_CONSTANTS.OT_LEVEL_1,
            2: MODEL_CONSTANTS.OT_LEVEL_2,
            3: MODEL_CONSTANTS.OT_LEVEL_3
        },
        AdditionalInfo: '',
        DevExclusive: false,
        SvcExclusive: false,
        Optional: false
    },
    [MODEL_CONSTANTS.PR]: {
        Name: 'Peer Reviews (PR)',
        Intent: 'Identify and address work product issues through reviews by the producer’s peers or Subject Matter Experts (SMEs).',
        Value: 'Reduce cost and rework by uncovering issues or defects early.',
        Id: 'PR',
        LevelIds: {
            1: MODEL_CONSTANTS.PR_LEVEL_1,
            2: MODEL_CONSTANTS.PR_LEVEL_2,
            3: MODEL_CONSTANTS.PR_LEVEL_3
        },
        AdditionalInfo: '',
        DevExclusive: false,
        SvcExclusive: false,
        Optional: false
    },
    [MODEL_CONSTANTS.PLAN]: {
        Name: 'Planning (PLAN)',
        Intent: "Develops plans to describe what is needed to accomplish the work within the standards and constraints of the organization.",        
        Value: 'Optimizes cost, functionality, and quality to increase the likelihood of meeting objectives.',
        Id: 'PLAN',
        LevelIds: {
            1: MODEL_CONSTANTS.PLAN_LEVEL_1,
            2: MODEL_CONSTANTS.PLAN_LEVEL_2,
            3: MODEL_CONSTANTS.PLAN_LEVEL_3,
            4: MODEL_CONSTANTS.PLAN_LEVEL_4
        },
        AdditionalInfo: `Planning includes developing budgets and schedules based on estimates; identifying the appropriate set of stakeholders and tasks; managing risks; determining the necessary resources; and developing and keeping the project plan updated to reflect how to perform the work. An important aspect of planning is capacity and availability management.

Capacity and availability management activities can be performed at different levels of the organization and applied to any type of work. Capacity and availability management activities typically involve:

• Developing a capacity and availability management approach and keeping it updated

• Providing and allocating resources

• Monitoring, analyzing, understanding, forecasting, adjusting, and reporting on current demand for:

    o Work activities

    o Services

    o Solutions and Deliverables

    o Resources

    o Capacity and availability

    o Service or service system performance

    o Availability

• Determining corrective actions to ensure appropriate capacity and availability while balancing costs against resources needed and supply against demand
        `,
        DevExclusive: false,
        SvcExclusive: false,
        Optional: false
    },
    [MODEL_CONSTANTS.PAD]: {
        Name: 'Process Asset Development (PAD)',
        Intent: 'Develops the process assets necessary to perform the work and keeps them updated.',
        Value: 'Provides a capability to understand and repeat successful performance.',
        Id: 'PAD',
        LevelIds: {
            1: MODEL_CONSTANTS.PAD_LEVEL_1,
            2: MODEL_CONSTANTS.PAD_LEVEL_2,
            3: MODEL_CONSTANTS.PAD_LEVEL_3
        },
        AdditionalInfo: '',
        DevExclusive: false,
        SvcExclusive: false,
        Optional: false
    },
    [MODEL_CONSTANTS.PCM]: {
        Name: 'Process Management (PCM)',
        Intent: "Manages and implements the continuous performance improvement of processes and infrastructure to meet business objectives " +
                "by identifying and implementing the most beneficial process improvements and making performance results visible, accessible, and sustainable.",
        Value: 'Ensures that processes, infrastructure, and their improvement contribute to successfully meeting business objectives.',
        Id: 'PCM',
        LevelIds: {
            1: MODEL_CONSTANTS.PCM_LEVEL_1,
            2: MODEL_CONSTANTS.PCM_LEVEL_2,
            3: MODEL_CONSTANTS.PCM_LEVEL_3,
            4: MODEL_CONSTANTS.PCM_LEVEL_4
        },
        AdditionalInfo: '',
        DevExclusive: false,
        SvcExclusive: false,
        Optional: false
    },
    [MODEL_CONSTANTS.PQA]: {
        Name: 'Process Quality Assurance (PQA)',
        Intent: 'Verifies and enables improvement of the quality of the processes performed and resulting work products.',
        Value: 'Increases the consistent use and improvement of the processes to maximize business benefit and customer satisfaction.',
        Id: 'PQA',
        LevelIds: {
            1: MODEL_CONSTANTS.PQA_LEVEL_1,
            2: MODEL_CONSTANTS.PQA_LEVEL_2,
            3: MODEL_CONSTANTS.PQA_LEVEL_3
        },
        AdditionalInfo: '',
        DevExclusive: false,
        SvcExclusive: false,
        Optional: false
    },
    [MODEL_CONSTANTS.PI]: {
        Name: 'Product Integration (PI)',
        Intent: 'Integrates and delivers the solution that addresses functionality, performance, and quality requirements.',
        Value: 'Increases customers’ satisfaction by giving them a solution that meets or exceeds their functionality and quality requirements.',
        Id: 'PI',
        LevelIds: {
            1: MODEL_CONSTANTS.PI_LEVEL_1,
            2: MODEL_CONSTANTS.PI_LEVEL_2,
            3: MODEL_CONSTANTS.PI_LEVEL_3
        },
        AdditionalInfo: '',
        DevExclusive: true,
        SvcExclusive: false,
        Optional: false
    },
    [MODEL_CONSTANTS.RDM]: {
        Name: 'Requirements Development and Management (RDM)',
        Intent: 'Elicit requirements, ensure common understanding by stakeholders, and align requirements, plans, and work products.',
        Value: 'Ensures that customers’ needs and expectations are satisfied.',
        Id: 'RDM',
        LevelIds: {
            1: MODEL_CONSTANTS.RDM_LEVEL_1,
            2: MODEL_CONSTANTS.RDM_LEVEL_2,
            3: MODEL_CONSTANTS.RDM_LEVEL_3
        },
        AdditionalInfo: '',
        DevExclusive: false,
        SvcExclusive: false,
        Optional: false
    },
    [MODEL_CONSTANTS.RSK]: {
        Name: 'Risk and Opportunity Management (RSK)',
        Intent: 'Identify, record, analyze, and manage potential risks or opportunities.',
        Value: 'Mitigate adverse impacts or capitalize on positive impacts to increase the likelihood of meeting objectives.',
        Id: 'RSK',
        LevelIds: {
            1: MODEL_CONSTANTS.RSK_LEVEL_1,
            2: MODEL_CONSTANTS.RSK_LEVEL_2,
            3: MODEL_CONSTANTS.RSK_LEVEL_3
        },
        AdditionalInfo: `The term risk refers to uncertainties that may have a negative impact on achieving objectives.

        The term opportunity refers to uncertainties that may have a positive impact on achieving objectives.`,
        DevExclusive: false,
        SvcExclusive: false,
        Optional: false
    },
    [MODEL_CONSTANTS.SDM]:{
        Name: 'Service Delivery Management (SDM)',
        Intent: 'Deliver services and manage the service delivery system.',
        Value: 'Increase customer satisfaction by delivering services that meet or exceed customer expectations.',
        Id: 'SDM',
        LevelIds: {
            1: MODEL_CONSTANTS.SDM_LEVEL_1,
            2: MODEL_CONSTANTS.SDM_LEVEL_2,
            3: MODEL_CONSTANTS.SDM_LEVEL_3
        },
        AdditionalInfo: `This includes:

        • Delivering services in accordance with service delivery approaches and agreements
        
        • Managing changes to the service delivery system
        
        • Receiving and processing service requests
        
        • Maintaining service delivery performance when changes occur`,
        DevExclusive: false,
        SvcExclusive: true,
        Optional: false
    },
    [MODEL_CONSTANTS.STSM]:{
        Name: 'Strategic Service Management (STSM)',
        Intent: 'Develop and deploy standard services that are compatible with strategic business needs and plans.',
        Value: 'Increases likelihood of meeting business objectives by aligning standard services with customer needs.',
        Id: 'STSM',
        LevelIds: {
            1: MODEL_CONSTANTS.STSM_LEVEL_1,
            2: MODEL_CONSTANTS.STSM_LEVEL_2,
            3: MODEL_CONSTANTS.STSM_LEVEL_3
        },
        AdditionalInfo: '',
        DevExclusive: false,
        SvcExclusive: true,
        Optional: false
    },
    [MODEL_CONSTANTS.SAM]: {
        Name: 'Supplier Agreement Management (SAM)',
        Intent: 'Establish an agreement with selected suppliers, ensure that the supplier and the acquirer perform according to the terms over the course of the agreement, and evaluate the supplier’s deliverables.',
        Value: 'Provides an explicit understanding between the acquirer and supplier to maximize the success of agreed-on efforts to deliver a supplier deliverable.',
        Id: 'SAM',
        LevelIds: {
            1: MODEL_CONSTANTS.SAM_LEVEL_1,
            2: MODEL_CONSTANTS.SAM_LEVEL_2,
            3: MODEL_CONSTANTS.SAM_LEVEL_3,
            4: MODEL_CONSTANTS.SAM_LEVEL_4
        },
        AdditionalInfo: 'The term “supplier deliverable” is an item to be provided to an acquirer or other recipient as specified in an agreement. The item can be a document, hardware or software item, a service, or any type of solution or work product.',
        DevExclusive: false,
        SvcExclusive: false,
        Optional: true
    },
    [MODEL_CONSTANTS.SSS]: {
        Name: 'Supplier Source Selection (SSS)',
        Intent: 'Develop and keep updated a package of materials used to seek proposals from potential suppliers and select one or more suppliers to deliver the solution.',
        Value: 'Improves the ability to select the most qualified suppliers to deliver solutions.',
        Id: 'SSS',
        LevelIds: {
            1: MODEL_CONSTANTS.SSS_LEVEL_1,
            2: MODEL_CONSTANTS.SSS_LEVEL_2,
            3: MODEL_CONSTANTS.SSS_LEVEL_3
        },
        AdditionalInfo: '',
        DevExclusive: false,
        SvcExclusive: false,
        Optional: false
    },
    [MODEL_CONSTANTS.TS]: {
        Name: 'Technical Solution (TS)',
        Intent: 'Design and build solutions that meet customer requirements.',
        Value: 'Provides a cost-effective design and solution that meets customer requirements and reduces rework.',
        Id: 'TS',
        LevelIds: {
            1: MODEL_CONSTANTS.TS_LEVEL_1,
            2: MODEL_CONSTANTS.TS_LEVEL_2,
            3: MODEL_CONSTANTS.TS_LEVEL_3
        },
        AdditionalInfo: '',
        DevExclusive: true,
        SvcExclusive: false,
        Optional: false
    },
    [MODEL_CONSTANTS.VV]: {
        Name: 'Verification and Validation (VV)',
        Intent: "Verification and validation includes activities that: Confirm selected solutions and components meet their requirements, " +
                "Demonstrate selected solutions and components fulfil their intended use in their target environment",
        Value: 'Verification and validation of selected solutions and components throughout the project increases the likelihood that the solution will satisfy the customer.',
        Id: 'VV',
        LevelIds: {
            1: MODEL_CONSTANTS.VV_LEVEL_1,
            2: MODEL_CONSTANTS.VV_LEVEL_2,
            3: MODEL_CONSTANTS.VV_LEVEL_3
        },
        AdditionalInfo: '',
        DevExclusive: false,
        SvcExclusive: false,
        Optional: false
    }
};

export { PRACTICE_AREAS };