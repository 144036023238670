import React, { useState } from 'react';
import { FormGroup, FormLabel, FormControl, FormCheck, Button, ListGroup, Modal, Row, Col } from 'react-bootstrap';
import uuid from 'uuid';
import DeleteableListItem from './DeleteableListItem';
import './ApprisalStateMachineBodies.css';
import '../modal.css';
import styled from 'styled-components';
import ConfirmDeleteModal from '../presenters/ConfirmDeleteModal';

const Styles = styled.div`

.custom-btn {
    background:rgb(0, 168, 168);
    color: white;
    border: none;    
    padding: 0;   
  } 
`;

const APPRAISAL_CONSTANTS = {
    NAME_ERROR_TOOLTIP: "Appraisal Name required",
    CLASHING_APPRAISAL_TOOLTIP: "Appraisal already exists",
    ORG_ERROR_TOOLTIP: "Organization must be selected",
    LEVEL_ERROR_TOOLTIP: "Maturity Level must be selected",
    PROJECT_ERROR_TOOLTIP: "At least one Project required",
    PROJECT_NAME_CLASH_TOOLTIP: "Project already exists",
    PROJECT_NAME_ERROR_TOOLTIP: "Invalid project name",
    ADD_PROJECT_TOOLTIP: "Add Project to Appraisal"
};

const DELETE_PROJECT_QUESTION = "Delete the selected project?  This action will result in loss of data.";
const LOWER_ML_QUESTION = "Are you sure you want to lower the maturity level? This action will result in loss of data.";
const REMOVE_SELECTED_PAS_QUESTION = "Are you sure you want to remove the selected practice area(s)?  This action will result in loss of data.";
const REMOVE_ALL_PAS_QUESTION = "Are you sure you want to remove all practice areas?  This action will result in loss of data.";

function AppraisalStateOne(props) {

    const [editProjectError, setEditProjectError] = useState(false);
    const [newProjectName, setNewProjectName] = useState("");
    const [showEditProjectModal, setShowEditProjectModal] = useState(false);
    const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);
    const [showLowerMLModal, setShowLowerMLModal] = useState(false);
    const [projectSelectedForEdit, setProjectSelectedForEdit] = useState(false);
    const [projectSelectedForDeletion, setProjectSelectedForDeletion] = useState(false);
    const [currentMLForChange, setCurrentMLForChange] = useState(false);
    const [selectedMLForChange, setSelectedMLForChange] = useState(false);
    const autoFocus = React.createRef();

    const handleFormCheckboxesChanged = (e) => {
        let { name, checked } = e.target;
        if (name === "devSelected" || name === "svcSelected") {
            props.setFormState(prevState => ({
                ...prevState, devSelected: (checked ^ prevState.devSelected),
                svcSelected: (checked ^ prevState.svcSelected)
            }));
            switch (name) {
                case "devSelected":
                    for (let i = 0; i < props.projects.value.length; i++) {
                        let project = props.projects.value[i];
                        const filteredPracticeAreas = project.practiceAreas.filter(pa => !pa.SvcExclusive);
                        project.practiceAreas = filteredPracticeAreas;
                        props.projects.replace(project.id, project);
                    }
                    break;
                case "svcSelected":
                    for (let i = 0; i < props.projects.value.length; i++) {
                        let project = props.projects.value[i];
                        const filteredPracticeAreas = project.practiceAreas.filter(pa => !pa.DevExclusive);
                        project.practiceAreas = filteredPracticeAreas;
                        props.projects.replace(project.id, project);
                    }
                    break;
                default:
                    break;
            }
        } 
    }

    const handleNameChanged = (value) => {
        let inError = props.formState.nameError;
        let tooltip = props.formState.nameTooltip;
        let clashes = props.checkAppraisalClashes(value, null);
        if (value === "") {
            inError = true;
            tooltip = APPRAISAL_CONSTANTS.NAME_ERROR_TOOLTIP;
        }
        if (clashes) {
            inError = true;
            tooltip = APPRAISAL_CONSTANTS.CLASHING_APPRAISAL_TOOLTIP;
        }
        else {
            inError = false;
            tooltip = ''
        }
        props.setFormState(prevState => ({ ...prevState, name: value, nameError: inError, nameTooltip: tooltip, organizationError: clashes, organizationTooltip: clashes ? APPRAISAL_CONSTANTS.CLASHING_APPRAISAL_TOOLTIP : "" }));
    }

    const handleLevelChanged = (value) => {
        let currentLevel = props.formState.level[props.formState.level.length - 1];
        let selectedLevel = value[value.length - 1];
        if (currentLevel > selectedLevel) {
            setSelectedMLForChange(value);
            setCurrentMLForChange(props.formState.level);
            setShowLowerMLModal(true);
            props.toggleModalOpen();
        }
        else {
            props.setFormState(prevState => ({ ...prevState, level: value, levelError: false, levelTooltip: '' }));
        }
    }

    const handleLowerML = () => {
        let currentLevel = currentMLForChange[currentMLForChange.length - 1];
        let selectedLevel = selectedMLForChange[selectedMLForChange.length - 1];

        if (selectedLevel < currentLevel) {
            props.handleMaturityLevelDowngrade(currentLevel, selectedLevel);
        }
        props.setFormState(prevState => ({ ...prevState, level: selectedMLForChange, levelError: false, levelTooltip: '' }));
        setShowLowerMLModal(false);
        props.toggleModalOpen();
    }

    const handleOrgChanged = (value) => {
        const clashes = props.checkAppraisalClashes(null, value);
        const orgTooltip = clashes ? APPRAISAL_CONSTANTS.CLASHING_APPRAISAL_TOOLTIP : "";
        const nameError = props.formState.name === "" || clashes;
        const nameTooltip = clashes ? APPRAISAL_CONSTANTS.CLASHING_APPRAISAL_TOOLTIP : "";
        props.setFormState(prevState => ({ ...prevState, organization: value, organizationError: clashes, organizationTooltip: orgTooltip, nameError: nameError, nameTooltip: nameTooltip }));
    }

    const handleProjectNameChanged = (value) => {
        let inError = props.formState.projectNameInError;
        let tooltip = "";
        const clashes = props.projects.value.filter(p => p.name === value);
        if (clashes.length > 0) {
            inError = true;
            tooltip = APPRAISAL_CONSTANTS.PROJECT_NAME_CLASH_TOOLTIP
        }
        if (value.length > 0 && value.trim().length === 0) {
            inError = true;
            tooltip = APPRAISAL_CONSTANTS.PROJECT_NAME_ERROR_TOOLTIP
        }
        if(containsOrgRollup(value.toLowerCase().trim())){
            inError = true;
            tooltip = APPRAISAL_CONSTANTS.PROJECT_NAME_ERROR_TOOLTIP
        }
        props.setFormState(prevState => ({ ...prevState, projectName: value, projectNameError: inError, projectNameTooltip: tooltip }));
    }

    const containsOrgRollup = (value) => {
        let pattern = /(org[A-Za-z0-9\-\s]*roll[\-\s]*up)/g;
        return value.match(pattern) ? true : false;
    }

    const addEnabled = () => {
        const clashes = props.projects.value.filter(p => p.name === props.formState.projectName);
        return props.formState.projectName.trim().length > 0 && clashes.length === 0 
        && !containsOrgRollup(props.formState.projectName.toLowerCase().trim());
    }

    const handleProjectAdded = () => {
        const newProject = {
            id: uuid.v1(),
            name: props.formState.projectName,
            practiceAreas: []
        };
        props.projects.add(newProject);
        props.setFormState(prevState => ({
            ...prevState, projectName: '', projectError: false,
            projectTooltip: '', projectNameError: false, projectNameTooltip: ''
        }));
    }

    const closeLowerMLModal = () => {
        setShowLowerMLModal(false);
        props.toggleModalOpen();
    }

    const handleShowEditProjectModal = (project) => {
        if (hasAnyModalOpen()) {
            return;
        }
        setNewProjectName("");
        setProjectSelectedForEdit(project);
        setShowEditProjectModal(true);
        props.toggleModalOpen();
    }

    const handleProjectNameFieldChanged = (value) => {

        setNewProjectName(value);

        let hasNameCollision = false;
        for (let i = 0; i < props.projects.value.length; i++) {
            if (value.toLowerCase() === props.projects.value[i].name.toLowerCase()) {
                hasNameCollision = true;
            }
        }

        if (value.toString() === "" || hasNameCollision) {
            setEditProjectError(true);
        }
        else if (containsOrgRollup(value.toLowerCase())){
            setEditProjectError(true);
        }
        else {
            setEditProjectError(false);
        }
    }

    const confirmProjectEdit = () => {
        if (newProjectName !== "" && !editProjectError) {

            //this case has a valid name change
            if (!editProjectError) {
                for (let i = 0; i < props.projects.value.length; i++) {
                    if (props.projects.value[i].id === projectSelectedForEdit.id) {
                        props.projects.value[i].name = newProjectName;

                        props.setFormState(prevState => ({
                            ...prevState, projectName: '', projectError: false,
                            projectTooltip: '', projectNameError: false, projectNameTooltip: ''
                        }));
                    }
                }
                tryCloseEditProjectModal();
            }

        }
        else {
            setEditProjectError(true);
        }
    }

    const tryCloseEditProjectModal = () => {
        setShowEditProjectModal(false);
        props.toggleModalOpen();
    }

    const handleShowDeleteProjectModal = (projectId) => {
        if (hasAnyModalOpen()) {
            return;
        }
        setProjectSelectedForDeletion(projectId);
        setShowDeleteProjectModal(true);
        props.toggleModalOpen();
    }

    const closeDeleteProjectModal = () => {
        setShowDeleteProjectModal(false);
        props.toggleModalOpen();
    }

    const handleProjectDeleted = () => {
        setShowDeleteProjectModal(false);
        props.projects.removeById(projectSelectedForDeletion);
        //The call above does not actually mutate the value of the projects list until this function falls out of scope,
        // hence the check for errors at one instead of zero.
        if (props.projects.value.length === 1) {
            props.setFormState(prevState => ({
                ...prevState, projectError: true,
                projectTooltip: APPRAISAL_CONSTANTS.PROJECT_ERROR_TOOLTIP,
                projectNameError: false, projectNameTooltip: ''
            }));
        }
        props.toggleModalOpen();
    }

    const handleProjectKeyPress = (e) => {
        var code = e.keyCode || e.which;
        if (code === 13) {
            if (addEnabled()) {
                handleProjectAdded();
            }
        }
    }

    const hasAnyModalOpen = () => {
        return showDeleteProjectModal || showLowerMLModal || props.modalOpen;
    }

    const projectItems = props.projects.value.map(p => {
        return (
            <DeleteableListItem key={p.id} id={p.id} displayText={p.name} title={p.name}
                onDeleteClicked={handleShowDeleteProjectModal}
                onEditClicked={handleShowEditProjectModal} />
        )
    });

    const availableOrgs = props.orgs.filter(o => o.isActive === true).map(o => {
        return (
            <option key={o.id} value={o.id}>{o.name}</option>
        );
    });

    const placeholderOrgOption = (
        <option disabled={true} value="Organization">Organization</option>
    );

    const placeholderLevelOption = (
        <option disabled key="1" value="Maturity Level">Maturity Level</option>
    );

    const selectedOrg = props.formState.organization.length > 0 ? props.formState.organization : placeholderOrgOption.props.value;
    const selectedLevel = props.formState.level.length > 0 ? props.formState.level : placeholderLevelOption.props.value;
    const errorStyle = {
        border: "1px solid red",
        color: 'dimgray'
    };
    const defaultStyle = {
        color: 'dimgray'
    };

    const editProjectTitle = editProjectError ? "Invalid project name" : "";
    const editStyle = editProjectError ? {
        borderBottomColor: 'red',
        borderRightColor: 'white',
        borderLeftColor: 'white',
        borderTopColor: 'white'
    }
        : {
            borderBottomColor: 'dimgray',
            borderRightColor: 'white',
            borderLeftColor: 'white',
            borderTopColor: 'white'
        };

    return (
        <Styles>
            <React.Fragment>
                <FormGroup id="name">
                    <FormControl type="text"
                        disabled={hasAnyModalOpen}
                        ref={props.autofocusRef}
                        name="name"
                        placeholder="Appraisal Name"
                        value={props.formState.name}
                        onChange={(e) => handleNameChanged(e.target.value)}
                        style={props.formState.nameError ? errorStyle : defaultStyle}
                        title={props.formState.nameTooltip}
                    />
                </FormGroup>
                <FormGroup id="organization">
                    <FormControl as="select" name="organization"
                        disabled={hasAnyModalOpen}
                        style={props.formState.organizationError ? errorStyle : defaultStyle}
                        title={props.formState.organizationTooltip}
                        onChange={(e) => handleOrgChanged(e.target.value)}
                        value={selectedOrg} >
                        {placeholderOrgOption}
                        {availableOrgs}
                    </FormControl>
                </FormGroup>
                <FormGroup id="type">
                    <FormCheck type='radio' inline name="devSelected"
                        disabled={props.type === "Edit" || hasAnyModalOpen()}
                        onChange={(e) => handleFormCheckboxesChanged(e)}
                        checked={props.formState.devSelected}
                        className="radioButtons" style={{ color: 'dimgray' }} label='Development(DEV)'></FormCheck>
                    {' '}
                    <FormCheck type='radio' inline name="svcSelected"
                        disabled={props.type === "Edit" || hasAnyModalOpen()}
                        onChange={(e) => handleFormCheckboxesChanged(e)}
                        checked={props.formState.svcSelected}
                        className="radioButtons" style={{ color: 'dimgray' }} label='Services(SVC)'></FormCheck>
                </FormGroup>
                <FormGroup id="level">
                    <FormControl as="select" placeholder="Maturity Level"
                        name="level"
                        disabled={hasAnyModalOpen()}
                        style={props.formState.levelError ? errorStyle : defaultStyle}
                        title={props.levelTooltip}
                        onChange={(e) => handleLevelChanged(e.target.value)}
                        value={selectedLevel}>
                        {placeholderLevelOption}
                        <option key="2" value="Level 2">Level 2</option>
                        <option key="3" value="Level 3">Level 3</option>
                        <option key="4" value="Level 4">Level 4</option>
                        <option key="5" value="Level 5">Level 5</option>
                    </FormControl>
                </FormGroup>
                <FormGroup id="projects">
                    <FormControl type="text" onChange={(e) => handleProjectNameChanged(e.target.value)}
                        style={props.formState.projectNameError ? errorStyle : defaultStyle}
                        title={props.formState.projectNameTooltip}
                        value={props.formState.projectName}
                        placeholder="Project Name"
                        name="projectName"
                        onKeyPress={(e) => handleProjectKeyPress(e)}
                        className="addTextBox" />
                    <Button disabled={!addEnabled()}
                        onClick={(e) => handleProjectAdded()}
                        className='custom-btn addButton'
                        title={APPRAISAL_CONSTANTS.ADD_PROJECT_TOOLTIP}>Add</Button>
                    <ListGroup className="projectsListGroup"
                        title={props.formState.projectTooltip}
                        style={props.formState.projectError ? errorStyle : defaultStyle}>
                        {projectItems}
                    </ListGroup>
                </FormGroup>
            </React.Fragment>
            <Modal show={showEditProjectModal} animation bsSize="large" onHide={tryCloseEditProjectModal} onEntered={() => autoFocus.current.focus()}>
                <Modal.Header>
                    <Modal.Title style={{ fontSize: '18px', color: 'dimgray' }}>
                        <img
                            src={require('../assets/icon.png')}
                            width="30"
                            height="30"
                            className="d-inline-block align-middle"
                            alt="CAP logo" />Change Project Name</Modal.Title>
                </Modal.Header>
                <Row className="d-flex justify-content-center">
                    <Col className='col-md-10'>
                        <Modal.Body>
                            <text style={{ fontSize: '18px', color: 'dimgray' }}>Current Name: {projectSelectedForEdit.name}</text>
                            <br></br><br></br>
                            <FormGroup controlId="locationInput">
                                <div>
                                    <FormControl
                                        ref={autoFocus}
                                        type="text"
                                        name="location"
                                        style={editStyle}
                                        title={editProjectTitle}
                                        value={newProjectName}
                                        onChange={(e) => handleProjectNameFieldChanged(e.target.value)}
                                        placeholder='New Project Name' />
                                </div>
                            </FormGroup>
                        </Modal.Body>
                    </Col>
                </Row>
                <Modal.Footer style={{ backgroundColor: 'rgb(0,168,168)' }}>
                    <Button
                        variant="outline-light"
                        size='sm'
                        onClick={confirmProjectEdit}
                    >Confirm</Button>{' '}
                    <Button
                        variant="outline-light"
                        size='sm'
                        onClick={tryCloseEditProjectModal}
                    >Cancel</Button>{' '}
                </Modal.Footer>
            </Modal>
            <ConfirmDeleteModal
                showDeleteModal={showDeleteProjectModal}
                close={closeDeleteProjectModal}
                deleteAction={handleProjectDeleted}
                deleteQuestion={DELETE_PROJECT_QUESTION}
            />
            <ConfirmDeleteModal
                showDeleteModal={showLowerMLModal}
                close={closeLowerMLModal}
                deleteAction={handleLowerML}
                deleteQuestion={LOWER_ML_QUESTION}
            />
        </Styles>
    );
}

function AppraisalStateTwo(props) {

    const [showRemoveSelectedPAsModal, setShowRemoveSelectedPAsModal] = useState(false);
    const [showRemoveAllPAsModal, setShowRemoveAllPAsModal] = useState(false);

    const handleProjectSelectionChanged = (projectId) => {
        const newProject = props.projects.getById(projectId);
        props.setFormState(prevState => ({ ...prevState, selectedProject: newProject }));
        const filtered = props.getInitialPracticeAreas(projectId).filter(pa => {
            return newProject.practiceAreas.filter(x => x.Optional === false).find(ppa => ppa.Id === pa.Id) === undefined
        });
        props.practiceAreas.setValue(filtered);
    }

    const handleAvailablePracticeAreaSelectionChanged = (e) => {
        const options = e.target.options;
        let availableSelected = props.practiceAreas.value.slice();
        for (let i = 0; i < options.length; i++) {
            availableSelected[i].SelectedForAdd = options[i].selected;
        }
        props.practiceAreas.setValue(availableSelected);
    }

    const handleAddSelectedPAsToProject = () => {
        const selectedPAs = props.practiceAreas.value.filter(apa => apa.SelectedForAdd === true)
            .map(pa => ({ ...pa, SelectedForAdd: false }));
        const remainingPAs = props.practiceAreas.value.filter(apa => apa.SelectedForAdd === false).sort((a, b) => {
            if (a.Id > b.Id) {
                return 1;
            } else if (a.Id === b.Id) {
                return 0;
            } else {
                return -1;
            }
        });
        let newProject = Object.assign({}, props.formState.selectedProject);
        newProject.practiceAreas = newProject.practiceAreas.concat(selectedPAs).sort((a, b) => {
            if (a.Id > b.Id) {
                return 1;
            } else if (a.Id === b.Id) {
                return 0;
            } else {
                return -1;
            }
        });
        props.setFormState(prevState => ({ ...prevState, selectedProject: newProject }));
        props.projects.replace(newProject.id, newProject);
        props.practiceAreas.setValue(remainingPAs);
    }

    const addSelectedPAsToProjectDisabled = () => {
        return props.practiceAreas.value.find(apa => apa.SelectedForAdd === true);
    }

    const addAllPAsToPRojectDisabled = () => {
        return props.practiceAreas.value.length > 0;
    }

    const handleAddAllPAsToProject = () => {
        const practices = props.practiceAreas.value.filter(x => x.Optional === false).map(pa => ({ ...pa, SelectedForAdd: false }));
        let newProject = Object.assign({}, props.formState.selectedProject);
        newProject.practiceAreas = newProject.practiceAreas.concat(practices).sort((a, b) => {
            if (a.Id > b.Id) {
                return 1;
            } else if (a.Id === b.Id) {
                return 0;
            } else {
                return -1;
            }
        });;

        props.setFormState(prevState => ({ ...prevState, selectedProject: newProject }));
        props.projects.replace(newProject.id, newProject);
        props.practiceAreas.clear();
    }

    const removedSelectedPAsFromProjectDisabled = () => {
        return props.formState.selectedProject.practiceAreas.find(pa => pa.SelectedForRemove === true);
    }

    const removeAllPAsFromProjectDisabled = () => {
        return props.formState.selectedProject.practiceAreas.filter(pa => !pa.Optional).length > 0;
    }

    const handleProjectPracticeAreaSelectionChanged = (e) => {
        let newProject = Object.assign({}, props.formState.selectedProject);
        const options = e.target.options;
        for (let i = 0; i < options.length; i++) {
            newProject.practiceAreas.find(x => x.Id === options[i].value).SelectedForRemove = options[i].selected;
        }

        props.setFormState(prevState => ({ ...prevState, selectedProject: newProject }));
    }

    const closeRemovePAModal = () => {
        setShowRemoveSelectedPAsModal(false);
        setShowRemoveAllPAsModal(false);
        props.toggleModalOpen();
    }

    const removeSelectedPAsClicked = () => {
        setShowRemoveSelectedPAsModal(true);
        props.toggleModalOpen();
    }

    const removeAllPAsClicked = () => {
        setShowRemoveAllPAsModal(true);
        props.toggleModalOpen();
    }

    const handleRemoveSelectedPAsFromProject = () => {
        let newProject = Object.assign({}, props.formState.selectedProject);
        const removing = newProject.practiceAreas.filter(pa => pa.SelectedForRemove === true)
            .map(pa => ({ ...pa, SelectedForRemove: false }));
        const remaining = newProject.practiceAreas.filter(pa => pa.SelectedForRemove === false).sort((a, b) => {
            if (a.Id > b.Id) {
                return 1;
            } else if (a.Id === b.Id) {
                return 0;
            } else {
                return -1;
            }
        });
        newProject.practiceAreas = remaining;
        props.practiceAreas.setValue(prevState => {
            return prevState.concat(removing).sort((a, b) => {
                if (a.Id > b.Id) {
                    return 1;
                } else if (a.Id === b.Id) {
                    return 0;
                } else {
                    return -1;
                }
            });
        });
        props.projects.replace(newProject.id, newProject);
        props.setFormState(prevState => ({ ...prevState, selectedProject: newProject }));
        closeRemovePAModal();
    }

    const handleRemoveAllRequiredPAsFromProject = () => {
        let newProject = Object.assign({}, props.formState.selectedProject);
        let removing = newProject.practiceAreas.filter(x => x.Optional === false);
        newProject.practiceAreas = newProject.practiceAreas.filter( function(x){
            return !removing.includes(x);
        });
        removing = removing.map(pa => ({ ...pa, SelectedForRemove: false }));
    
        props.practiceAreas.setValue(prevState => {
            return prevState.concat(removing).sort((a, b) => {
                if (a.Id > b.Id) {
                    return 1;
                } else if (a.Id === b.Id) {
                    return 0;
                } else {
                    return -1;
                }
            });
        });

        props.projects.replace(newProject.id, newProject);
        props.setFormState(prevState => ({ ...prevState, selectedProject: newProject }));
        closeRemovePAModal();
    }

    const projectOptions = props.projects.value.map(p => {
        return (
            <option key={p.id} value={p.id}>{p.name}</option>
        );
    });

    const mappedStyle = {
        color: "green"
    }

    const defaultStyle = {

    }

    const availablePracticeAreas = props.practiceAreas.value.map(pa => {
        return (
            <option key={pa.Id} value={pa.Id} style={pa.MappedByAnyProject ? mappedStyle : defaultStyle}>{pa.Name}</option>
        )
    });

    const projectPracticeAreas = props.formState.selectedProject.practiceAreas.filter(x => x.Optional === false).map(pa => {
        return (
            <option key={pa.Id} value={pa.Id}>{pa.Name}</option>
        )
    }).sort((a, b) => {
        if (a.key > b.key) {
            return 1;
        } else if (a.key === b.key) {
            return 0;
        } else {
            return -1;
        }
    });;

    const singlebutton = '<';
    const doubleButton = '<<';

    const singleBackButton = '>';
    const doubleBackButton = '>>';

    const hasAnyModalOpen = () => {
        return showRemoveAllPAsModal || showRemoveSelectedPAsModal || props.modalOpen;
    }

    return (
        <Styles>
            <React.Fragment>
                <FormGroup id="selectProjects">
                    <FormLabel style={{ color: 'dimgray' }}>Projects</FormLabel>
                    <FormControl as="select" placeholder="Projects" name="selectedProject" disabled={hasAnyModalOpen()}
                        onChange={(e) => handleProjectSelectionChanged(e.target.value)}>
                        {projectOptions}
                    </FormControl>
                </FormGroup>
                <FormLabel className="controlLabelOne">Project Assigned Required Practice Areas</FormLabel>
                <FormLabel className="controlLabelTwo">Required Practice Areas</FormLabel>
                <div id="listControls" className="listControls">
                    <select className="projectPAListGroup" multiple size="25"
                        disabled={hasAnyModalOpen()}
                        onChange={(e) => handleProjectPracticeAreaSelectionChanged(e)}>
                        {projectPracticeAreas}
                    </select>
                    <Button className="custom-btn forwardButtonOne"
                        disabled={!addSelectedPAsToProjectDisabled() || hasAnyModalOpen()}
                        onClick={(e) => handleAddSelectedPAsToProject()}>{singlebutton}</Button>
                    <Button className="custom-btn forwardButtonTwo"
                        onClick={(e) => handleAddAllPAsToProject()}
                        disabled={!addAllPAsToPRojectDisabled() || hasAnyModalOpen()}>{doubleButton}</Button>
                    <Button className="custom-btn backButtonTwo"
                        onClick={(e) => removeAllPAsClicked()}
                        disabled={!removeAllPAsFromProjectDisabled() || hasAnyModalOpen()}>{doubleBackButton}</Button>
                    <Button className="custom-btn backButtonOne"
                        disabled={!removedSelectedPAsFromProjectDisabled() || hasAnyModalOpen()}
                        onClick={(e) => removeSelectedPAsClicked()}>{singleBackButton}</Button>
                    <select multiple size="25" className="availablePAs" disabled={hasAnyModalOpen()} onChange={(e) => handleAvailablePracticeAreaSelectionChanged(e)}>
                        {availablePracticeAreas}
                    </select>
                </div>
            </React.Fragment>
            <ConfirmDeleteModal
                showDeleteModal={showRemoveSelectedPAsModal}
                close={closeRemovePAModal}
                deleteAction={handleRemoveSelectedPAsFromProject}
                deleteQuestion={REMOVE_SELECTED_PAS_QUESTION}
            />
            <ConfirmDeleteModal
                showDeleteModal={showRemoveAllPAsModal}
                close={closeRemovePAModal}
                deleteAction={handleRemoveAllRequiredPAsFromProject}
                deleteQuestion={REMOVE_ALL_PAS_QUESTION}
            />
        </Styles>
    );
}

function AppraisalStateThree(props) {

    const [showRemoveSelectedPAsModal, setShowRemoveSelectedPAsModal] = useState(false);
    const [showRemoveAllPAsModal, setShowRemoveAllPAsModal] = useState(false);

    const handleProjectSelectionChanged = (projectId) => {
        const newProject = props.projects.getById(projectId);
        props.setFormState(prevState => ({ ...prevState, selectedProject: newProject }));
        const filtered = props.getInitialOptionalPracticeAreas(projectId).filter(pa => {
            return newProject.practiceAreas.find(ppa => ppa.Id === pa.Id) === undefined
        });
        props.practiceAreas.setValue(filtered);
    }

    const handleAvailablePracticeAreaSelectionChanged = (e) => {
        const options = e.target.options;
        let availableSelected = props.practiceAreas.value.slice();
        for (let i = 0; i < options.length; i++) {
            availableSelected[i].SelectedForAdd = options[i].selected;
        }
        props.practiceAreas.setValue(availableSelected);
    }

    const handleAddSelectedPAsToProject = () => {
        const selectedPAs = props.practiceAreas.value.filter(apa => apa.SelectedForAdd === true)
            .map(pa => ({ ...pa, SelectedForAdd: false }));
        const remainingPAs = props.practiceAreas.value.filter(apa => apa.SelectedForAdd === false).sort((a, b) => {
            if (a.Id > b.Id) {
                return 1;
            } else if (a.Id === b.Id) {
                return 0;
            } else {
                return -1;
            }
        });
        let newProject = Object.assign({}, props.formState.selectedProject);
        newProject.practiceAreas = newProject.practiceAreas.concat(selectedPAs).sort((a, b) => {
            if (a.Id > b.Id) {
                return 1;
            } else if (a.Id === b.Id) {
                return 0;
            } else {
                return -1;
            }
        });
        props.setFormState(prevState => ({ ...prevState, selectedProject: newProject }));
        props.projects.replace(newProject.id, newProject);
        props.practiceAreas.setValue(remainingPAs);
    }

    const addSelectedPAsToProjectDisabled = () => {
        return props.practiceAreas.value.find(apa => apa.SelectedForAdd === true);
    }

    const addAllPAsToPRojectDisabled = () => {
        return props.practiceAreas.value.length > 0;
    }

    const handleAddAllPAsToProject = () => {
        const practices = props.practiceAreas.value.filter(x => x.Optional === true).map(pa => ({ ...pa, SelectedForAdd: false}));
        let newProject = Object.assign({}, props.formState.selectedProject);
        newProject.practiceAreas = newProject.practiceAreas.concat(practices).sort((a, b) => {
            if (a.Id > b.Id) {
                return 1;
            } else if (a.Id === b.Id) {
                return 0;
            } else {
                return -1;
            }
        });;

        props.setFormState(prevState => ({ ...prevState, selectedProject: newProject }));
        props.projects.replace(newProject.id, newProject);
        props.practiceAreas.clear();
    }

    const removedSelectedPAsFromProjectDisabled = () => {
        return props.formState.selectedProject.practiceAreas.find(pa => pa.SelectedForRemove === true);
    }

    const removeAllPAsFromProjectDisabled = () => {
        return props.formState.selectedProject.practiceAreas.filter(pa => pa.Optional).length > 0;
    }

    const handleProjectPracticeAreaSelectionChanged = (e) => {
        let newProject = Object.assign({}, props.formState.selectedProject);
        const options = e.target.options;
        for (let i = 0; i < options.length; i++) {
            newProject.practiceAreas.find(x => x.Id === options[i].value).SelectedForRemove = options[i].selected;
        }

        props.setFormState(prevState => ({ ...prevState, selectedProject: newProject }));
    }

    const closeRemovePAModal = () => {
        setShowRemoveSelectedPAsModal(false);
        setShowRemoveAllPAsModal(false);
        props.toggleModalOpen();
    }

    const removeSelectedPAsClicked = () => {
        setShowRemoveSelectedPAsModal(true);
        props.toggleModalOpen();
    }

    const removeAllPAsClicked = () => {
        setShowRemoveAllPAsModal(true);
        props.toggleModalOpen();
    }

    const handleRemoveSelectedPAsFromProject = () => {
        let newProject = Object.assign({}, props.formState.selectedProject);
        const removing = newProject.practiceAreas.filter(pa => pa.SelectedForRemove === true)
            .map(pa => ({ ...pa, SelectedForRemove: false }));
        const remaining = newProject.practiceAreas.filter(pa => pa.SelectedForRemove === false).sort((a, b) => {
            if (a.Id > b.Id) {
                return 1;
            } else if (a.Id === b.Id) {
                return 0;
            } else {
                return -1;
            }
        });
        newProject.practiceAreas = remaining;
        props.practiceAreas.setValue(prevState => {
            return prevState.concat(removing).sort((a, b) => {
                if (a.Id > b.Id) {
                    return 1;
                } else if (a.Id === b.Id) {
                    return 0;
                } else {
                    return -1;
                }
            });
        });
        props.projects.replace(newProject.id, newProject);
        props.setFormState(prevState => ({ ...prevState, selectedProject: newProject }));
        closeRemovePAModal();
    }

    const handleRemoveAllOptionalPAsFromProject = () => {
        let newProject = Object.assign({}, props.formState.selectedProject);
        let removing = newProject.practiceAreas.filter(x => x.Optional === true);
        newProject.practiceAreas = newProject.practiceAreas.filter( function(x){
            return !removing.includes(x);
        });
        removing = removing.map(pa => ({ ...pa, SelectedForRemove: false }));
    
        props.practiceAreas.setValue(prevState => {
            return prevState.concat(removing).sort((a, b) => {
                if (a.Id > b.Id) {
                    return 1;
                } else if (a.Id === b.Id) {
                    return 0;
                } else {
                    return -1;
                }
            });
        });

        props.projects.replace(newProject.id, newProject);
        props.setFormState(prevState => ({ ...prevState, selectedProject: newProject }));
        closeRemovePAModal();
    }

    const projectOptions = props.projects.value.map(p => {
        return (
            <option key={p.id} value={p.id}>{p.name}</option>
        );
    });

    const mappedStyle = {
        color: "green"
    }

    const defaultStyle = {

    }

    const availablePracticeAreas = props.practiceAreas.value.map(pa => {
        return (
            <option key={pa.Id} value={pa.Id} style={pa.MappedByAnyProject ? mappedStyle : defaultStyle}>{pa.Name}</option>
        )
    });

    const projectPracticeAreas = props.formState.selectedProject.practiceAreas.filter(x => x.Optional === true).map(pa => {
        return (
            <option key={pa.Id} value={pa.Id}>{pa.Name}</option>
        )
    }).sort((a, b) => {
        if (a.key > b.key) {
            return 1;
        } else if (a.key === b.key) {
            return 0;
        } else {
            return -1;
        }
    });;

    const singlebutton = '<';
    const doubleButton = '<<';

    const singleBackButton = '>';
    const doubleBackButton = '>>';

    const hasAnyModalOpen = () => {
        return showRemoveAllPAsModal || showRemoveSelectedPAsModal || props.modalOpen;
    }

    return (
        <Styles>
            <React.Fragment>
                <FormGroup id="selectProjects">
                    <FormLabel style={{ color: 'dimgray' }}>Projects</FormLabel>
                    <FormControl as="select" placeholder="Projects" name="selectedProject" disabled={hasAnyModalOpen()}
                        onChange={(e) => handleProjectSelectionChanged(e.target.value)}>
                        {projectOptions}
                    </FormControl>
                </FormGroup>
                <FormLabel className="controlLabelOne">Project Assigned Optional Practice Areas</FormLabel>
                <FormLabel className="controlLabelTwo">Optional Practice Areas</FormLabel>
                <div id="listControls" className="listControls">
                    <select className="projectPAListGroup" multiple size="25"
                        disabled={hasAnyModalOpen()}
                        onChange={(e) => handleProjectPracticeAreaSelectionChanged(e)}>
                        {projectPracticeAreas}
                    </select>
                    <Button className="custom-btn forwardButtonOne"
                        disabled={!addSelectedPAsToProjectDisabled() || hasAnyModalOpen()}
                        onClick={(e) => handleAddSelectedPAsToProject()}>{singlebutton}</Button>
                    <Button className="custom-btn forwardButtonTwo"
                        onClick={(e) => handleAddAllPAsToProject()}
                        disabled={!addAllPAsToPRojectDisabled() || hasAnyModalOpen()}>{doubleButton}</Button>
                    <Button className="custom-btn backButtonTwo"
                        onClick={(e) => removeAllPAsClicked()}
                        disabled={!removeAllPAsFromProjectDisabled() || hasAnyModalOpen()}>{doubleBackButton}</Button>
                    <Button className="custom-btn backButtonOne"
                        disabled={!removedSelectedPAsFromProjectDisabled() || hasAnyModalOpen()}
                        onClick={(e) => removeSelectedPAsClicked()}>{singleBackButton}</Button>
                    <select multiple size="25" className="availablePAs" disabled={hasAnyModalOpen()} onChange={(e) => handleAvailablePracticeAreaSelectionChanged(e)}>
                        {availablePracticeAreas}
                    </select>
                </div>
            </React.Fragment>
            <ConfirmDeleteModal
                showDeleteModal={showRemoveSelectedPAsModal}
                close={closeRemovePAModal}
                deleteAction={handleRemoveSelectedPAsFromProject}
                deleteQuestion={REMOVE_SELECTED_PAS_QUESTION}
            />
            <ConfirmDeleteModal
                showDeleteModal={showRemoveAllPAsModal}
                close={closeRemovePAModal}
                deleteAction={handleRemoveAllOptionalPAsFromProject}
                deleteQuestion={REMOVE_ALL_PAS_QUESTION}
            />
        </Styles>
    );
}

export { AppraisalStateOne, AppraisalStateTwo, AppraisalStateThree, APPRAISAL_CONSTANTS }