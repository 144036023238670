import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Spinner, FormControl, Row, Col } from 'react-bootstrap';
import { AppraisalRepository } from '../utils/repositories';
import { API } from 'aws-amplify';
import { Constants, getDefaultAPIHeaders } from '../utils/helpers';
import '../modal.css';

async function fetchData(setAppraisalName, setCurrentAppraisalId, setProjects, setSelectedProject) {
    const currentAppraisalId = localStorage.getItem("selectedAppraisal");
    setCurrentAppraisalId(currentAppraisalId);
    if (currentAppraisalId) {
        const apiRepo = new AppraisalRepository(null, null, { selected: false, availableForSelection: false });
        const appraisalData = await apiRepo.getOne(currentAppraisalId, false);
        if (!appraisalData.error) {
            setProjects(appraisalData.returnValue.projects.filter(x => x.name !== "Organizational Roll-up"));
            setSelectedProject(appraisalData.returnValue.projects[0]);
            setAppraisalName(appraisalData.returnValue.name);
        } else {
            console.log("error getting appraisal data: ", appraisalData.returnValue);
        }
    }
}

function ClearProjectInfoModal(props) {

    const [actionLoading, setActionLoading] = useState(false);
    const [showDeletingModal, setShowDeletingModal] = useState(false);
    const [isItemDeleting, setIsItemDeleting] = useState(false);
    const [itemBeingDeleted, setItemBeingDeleted] = useState("");
    const [itemDeleteTitle, setItemDeleteTitle] = useState("");
    const [itemDeleteStatus, setItemDeleteStatus] = useState("");
    const [itemDeleteButtonText, setItemDeleteButtonText] = useState("");
    const [currentAppraisalId, setCurrentAppraisalId] = useState('');
    const [appraisalName, setAppraisalName] = useState('');
    const [projects, setProjects] = useState({});
    const [selectedProject, setSelectedProject] = useState('');
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [showFinishedModal, setShowFinishedModal] = useState(false);
    const [warningText, setWarningText] = useState('');
    const [clearNotes, setClearNotes] = useState(false);
    const [clearStrengths, setClearStrengths] = useState(false);
    const [clearWeaknesses, setClearWeaknesses] = useState(false);
    const [clearIOs, setClearIOs] = useState(false);
    const [clearCharacterizations, setClearCharacterizations] = useState(false);
    const [clearHighlights, setClearHighlights] = useState(false);

    //reset when modal is shown
    useEffect(() => {
        const getData = () => fetchData(setAppraisalName, setCurrentAppraisalId, setProjects, setSelectedProject);
        getData();
        if (props.showModal === true) {
            setShowFinishedModal(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showModal]);

    const handleClick = async () => {
        setWarningText("Are you sure you want to clear the selected info for " + selectedProject.name + "?");
        setShowWarningModal(true);
    }

    const onYesToClear = async () => {
        setShowWarningModal(false);
        setActionLoading(true);

        setShowDeletingModal(true);
        setIsItemDeleting(true);
        setItemDeleteTitle("Clearing Project Data");
        setItemDeleteButtonText("Clearing Project Data, please wait...");

        await clearItemData();
        await clearStateData();

        setShowDeletingModal(false);
        setActionLoading(false);
        setShowFinishedModal(true);
    }

    const onNoToClear = () => {
        setShowWarningModal(false);
    }

    async function clearItemData() {
        if (clearNotes || clearStrengths || clearWeaknesses || clearIOs) {
            setItemBeingDeleted("Clearing Findings Data...");

            const apiPath = `${Constants.APPRAISAL_ITEM_PATH}/${currentAppraisalId}`
            const data = await API.get(Constants.API_PATH, apiPath, await getDefaultAPIHeaders());

            for (let i = 0; i < data.length; i++) {
                let index = i+1;
                setItemDeleteStatus("Processing finding " + index + " of " + data.length);

                if (data[i].project_id === selectedProject.id) {
                    let clear = false;
                    if (data[i].item_type === "notes") {
                        if (clearNotes) {
                            clear = true;
                        }
                    }
                    else if (data[i].item_type === "strengths") {
                        if (clearStrengths) {
                            clear = true;
                        }
                    }
                    else if (data[i].item_type === "weaknesses") {
                        if (clearWeaknesses) {
                            clear = true;
                        }
                    }
                    else if (data[i].item_type === "improvement_opportunities") {
                        if (clearIOs) {
                            clear = true;
                        }
                    }
                    if (clear) {
                        await clearItemNodeData(currentAppraisalId, selectedProject.id, data[i].node_id, data[i].item_type, data[i].revision_number + 1);
                    }
                }
            }
        }
    }

    async function clearStateData() {
        if (clearCharacterizations || clearHighlights) {
            setItemBeingDeleted("Clearing State Data...");
            const apiPath = `${Constants.APPRAISAL_STATE_PATH}/${currentAppraisalId}`
            const data = await API.get(Constants.API_PATH, apiPath, await getDefaultAPIHeaders());

            for (let i = 0; i < data.length; i++) {
                let index = i+1;
                setItemDeleteStatus("Processing state " + index + " of " + data.length);
                
                if (data[i].project_id === selectedProject.id) {
                    await clearStateNodeData(currentAppraisalId, selectedProject.id, data[i].node_id, data[i].affirmed, data[i].highlight, data[i].node_state, data[i].revision_number + 1);
                }
            }
        }
    }

    async function clearItemNodeData(appraisalId, projectId, nodeId, itemType, revNum) {
        const body = {
            appraisal_id: appraisalId,
            project_id: projectId,
            node_id: nodeId,
            item_type: itemType,
            item_data: null,
            revision_number: revNum
        }
        await API.put(Constants.API_PATH, Constants.APPRAISAL_ITEM_PATH, await getDefaultAPIHeaders(body));
    }

    async function clearStateNodeData(appraisalId, projectId, nodeId, currentAffirmed, currentHighlight, currentState, revNum) {

        let newCharacterization = currentState;
        if (clearCharacterizations) {
            newCharacterization = "Not Examined";
        }

        let newHighlight = currentHighlight;
        if (clearHighlights) {
            newHighlight = "";
        }

        const body = {
            appraisal_id: appraisalId,
            project_id: projectId,
            node_id: nodeId,
            affirmed: currentAffirmed,
            highlight: newHighlight,
            node_state: newCharacterization,
            revision_number: revNum
        };

        try {
            await API.put(Constants.API_PATH, Constants.APPRAISAL_STATE_PATH, await getDefaultAPIHeaders(body))
                .catch(err => {
                    console.log("Error clearing state data: " + err);
                });
        }
        catch (e) {
            console.log(e);
        }
    }

    const onFinishedAcknowledge = () => {
        setShowFinishedModal(false);
        props.setShowModal(false);
        window.location.reload();
    }

    const getDeletingButtonText = () => {
        return !isItemDeleting ? "Close" : itemDeleteButtonText;
    }

    const tryCloseDeletingModal = () => {
        if (!isItemDeleting) {
            setShowDeletingModal(false);
        }
    }

    const tryHideModal = (e) => {
        if (!actionLoading) {
            props.setShowModal(false)
        }
    }

    const handleProjectChanged = (e) => {
        let selected = projects.filter(x => x.id === e.target.value)[0];
        setSelectedProject(selected);

    }

    const getOptions = () => {
        let options = [];
        for (let i = 0; i < projects.length; i++) {
            options.push(
                <option value={projects[i].id}>{projects[i].name}</option>
            )
        }
        return options;
    }

    const notesCheckBoxChanged = () => {
        setClearNotes(!clearNotes);
    }

    const strengthsCheckBoxChanged = () => {
        setClearStrengths(!clearStrengths);
    }

    const weaknessesCheckBoxChanged = () => {
        setClearWeaknesses(!clearWeaknesses);
    }

    const iosCheckBoxChanged = () => {
        setClearIOs(!clearIOs);
    }

    const characterizationsCheckBoxChanged = () => {
        setClearCharacterizations(!clearCharacterizations);
    }

    const highlightsCheckBoxChanged = () => {
        setClearHighlights(!clearHighlights);
    }

    return (
        <Modal show={props.showModal}
            onHide={(e) => tryHideModal(e)}
            animation
            disabled={showFinishedModal}
        >
            <Modal.Header closeButton disabled={showFinishedModal || actionLoading}>
                <Modal.Title style={{ fontSize: '18px', color: 'dimgray' }}>
                    <img
                        src={require('../assets/icon.png')}
                        width="30"
                        height="30"
                        className="d-inline-block align-middle"
                        alt="CAP logo"
                    />{' '}Clear Project Info</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Form.Group as={Row} className="d-flex justify-content-center">
                    <Col sm={3} style={{ marginLeft: "-100px" }}></Col>
                    <Form.Label
                        column sm='3'
                        style={{
                            color: 'white',
                            backgroundColor: 'rgb(0,168,168)',
                            fontSize: '16px',
                            textAlign: 'center'
                        }}>
                        Project
                    </Form.Label>
                    <Col sm={6} style={{ textAlign: 'left' }}>
                        <FormControl
                            as="select"
                            style={{ borderRightColor: 'white', borderTopColor: 'white', color:'dimgray' }}
                            onChange={(e) => handleProjectChanged(e)}
                        >
                            {getOptions()}
                        </FormControl>
                    </Col>
                </Form.Group>
                <Row className="d-flex justify-content-center">
                    <Col sm={3} style={{ marginLeft: "-100px" }}></Col>
                    <Form.Label
                        column sm='3'
                        style={{
                            color: 'white',
                            backgroundColor: 'rgb(0,168,168)',
                            fontSize: '16px',
                            textAlign: 'center'
                        }}>
                        Info to Clear
                    </Form.Label>
                    <Col sm={6} style={{ textAlign: 'left' }}>
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Notes"
                                style={{color: 'dimgray'}}
                                checked={clearNotes}
                                onChange={notesCheckBoxChanged}
                                disabled={showWarningModal || showFinishedModal || actionLoading}
                            />
                            <Form.Check type="checkbox" label="Strengths"
                                style={{color: 'dimgray'}}
                                checked={clearStrengths}
                                onChange={strengthsCheckBoxChanged}
                                disabled={showWarningModal || showFinishedModal || actionLoading}
                            />
                            <Form.Check type="checkbox" label="Weaknesses"
                                style={{color: 'dimgray'}}
                                checked={clearWeaknesses}
                                onChange={weaknessesCheckBoxChanged}
                                disabled={showWarningModal || showFinishedModal || actionLoading}
                            />
                            <Form.Check type="checkbox" label="Improvement Opportunities"
                                style={{color: 'dimgray'}}
                                checked={clearIOs}
                                onChange={iosCheckBoxChanged}
                                disabled={showWarningModal || showFinishedModal || actionLoading}
                            />
                            <Form.Check type="checkbox" label="Characterizations"
                                style={{color: 'dimgray'}}
                                checked={clearCharacterizations}
                                onChange={characterizationsCheckBoxChanged}
                                disabled={showWarningModal || showFinishedModal || actionLoading}
                            />
                            <Form.Check type="checkbox" label="Highlights"
                                style={{color: 'dimgray'}}
                                checked={clearHighlights}
                                onChange={highlightsCheckBoxChanged}
                                disabled={showWarningModal || showFinishedModal || actionLoading}
                            />
                        </Form.Group>

                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer style={{ backgroundColor: 'rgb(0,168,168)' }}>
                <Button
                    variant="outline-light"
                    size='sm'
                    disabled={showFinishedModal || actionLoading}
                    onClick={(event) => handleClick()}
                >
                    {actionLoading &&
                        <Spinner
                            as="span"
                            animation="border"
                            size='sm'
                            role="status"
                            aria-hidden="true"
                        />}
                    {!actionLoading ? "Clear Info" : 'Clearing Info...'}
                </Button>{' '}
            </Modal.Footer>

            <Modal bsSize="small"
                show={showWarningModal}
                onHide={(e) => setShowWarningModal(false)}
                animation
                style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
                size='sm'>
                <Modal.Header>
                    <Col>
                        <Modal.Title className='w-100 text-center' style={{ fontSize: '16px', color: 'dimgray' }}>{warningText}</Modal.Title>
                        <div><br /></div>
                        <Modal.Title className='w-100 text-center' style={{ fontSize: '16px', color: 'red' }}>This action cannot be undone.</Modal.Title>
                    </Col>
                </Modal.Header>
                <Modal.Footer style={{ backgroundColor: 'rgb(0,168,168)' }}>
                    <Button
                        variant="outline-light"
                        size='sm'
                        onClick={(event) => onYesToClear()}
                    >Yes</Button>
                    <Button
                        variant="outline-light"
                        size='sm'
                        onClick={(event) => onNoToClear()}
                    >No</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeletingModal} onHide={(e) => tryCloseDeletingModal(e)} >
                <Modal.Header >
                    <Modal.Title style={{ fontSize: '18px', color: 'dimgray' }}>
                        <img
                            src={require('../assets/icon.png')}
                            width="30"
                            height="30"
                            className="d-inline-block align-middle"
                            alt="CAP logo"
                        />{itemDeleteTitle}</Modal.Title>
                </Modal.Header>
                <Row className="d-flex justify-content-center">
                    <Col className='col-md-10'>
                        <Modal.Body>
                            <text style={{ fontSize: '14px', color: 'dimgray' }}>{itemBeingDeleted}</text>
                            <br></br>
                            <text style={{ fontSize: '12px', color: 'dimgray' }}>{itemDeleteStatus}</text>
                        </Modal.Body>
                    </Col>
                </Row>
                <Modal.Footer style={{ backgroundColor: 'rgb(0,168,168)' }}>
                    <Button
                        variant="outline-light"
                        disabled={isItemDeleting}
                        size='sm'
                        onClick={(e) => tryCloseDeletingModal(e)}>
                        {isItemDeleting &&
                            <Spinner
                                as="span"
                                animation="border"
                                size='sm'
                                role="status"
                                aria-hidden="true"
                            />}
                        {getDeletingButtonText()}
                    </Button>{' '}
                </Modal.Footer>
            </Modal>

            <Modal bsSize="small"
                show={showFinishedModal}
                onHide={(e) => setShowFinishedModal(false)}
                animation
                style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
                size='sm'>
                <Modal.Header>
                    <Modal.Title className='w-100 text-center' style={{ fontSize: '16px', color: 'dimgray' }}>Project info cleared successfully.</Modal.Title>
                </Modal.Header>
                <Modal.Footer style={{ backgroundColor: 'rgb(0,168,168)' }}>
                    <Button
                        variant="outline-light"
                        size='sm'
                        onClick={(event) => onFinishedAcknowledge()}
                    >Ok</Button>
                </Modal.Footer>
            </Modal>
        </Modal>
    );
}

export default ClearProjectInfoModal;