import React, { useState } from 'react';
import Select, { components } from 'react-select'
import "./TreeView.css";
import { PA_OPTIONS, PA_GROUP_OPTIONS, PRACTICES_OPTIONS, ICON_MAP } from '../../utils/IconMap';

const customStyles={
    indicatorSeparator: base=> (
        {
            ...base,
            display: 'none'
        }
    ),
    indicatorsContainer: base=> (
        {
            ...base,
            display: 'none'
        }
    ),
    menu: base=> (
        {
            ...base,
            width: 'auto'
        }
    ),
    menuList: base=> (
        {
            ...base,
            display: "flex",
            flexWrap: "wrap",    
            width: "120px",        
            backgroundColor: "white"
        }
    )   
   
}

function TreeNode(props){
    const [nodeRating, setNodeRating] = useState({value: props.node.state.rating, label: ICON_MAP[props.node.state.rating]});
    const handleLinkClicked = (e) => {
        e.preventDefault();
        props.handleNodeClicked({
            nodeId: props.node.state.nodeId,
            isPractice: props.node.state.isPractice,
            isGroup: props.node.state.isGroup,
            name: props.node.name
        });
    }

    const handleNodeRatingChanged = (rating) => {
        setNodeRating({value: rating.value, label: ICON_MAP[rating.value]});
        props.nodeRatingChanged(props.node, rating.value);
    }
    
    const Option = (props) => {
        return(
            <div title={props.value}>
                <components.Option {...props}/>
            </div>
        )
    }

    const GetOptions = () => {
        if(props.node.state.isPractice){
            return PRACTICES_OPTIONS;
        }
        else if (props.node.state.isGroup){
            return PA_GROUP_OPTIONS;
        }
        else{
            return PA_OPTIONS;
        }
    }

    let highlightStyle = {backgroundColor: props.node.state.highlight}

    const getHighlightStyle = () => {
        if(props.node.state.highlight){
            return highlightStyle;
        }
        else{
            return null;
        }
    }

    return(
        <div className="node" onDoubleClick={(e)=>e.stopPropagation()}>
            <Select 
                isDisabled={props.disabled}
                className="select"  
                isSearchable={false} 
                options={GetOptions()}
                components={{ Option }}
                styles={customStyles} 
                value={nodeRating} 
                onChange={(e)=>handleNodeRatingChanged(e)} 
                menuPlacement={"auto"}></Select>               
            <a className="nodeLabel" href="test" style={getHighlightStyle()} onClick={(e)=>handleLinkClicked(e)}>{props.node.name}</a>
        </div>
    )
}

export default TreeNode;