import React from 'react';
import Amplify from 'aws-amplify';
import config from './utils/config.js';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.js';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router} from 'react-router-dom';
import {Constants} from './utils/helpers';
const OPEN = "public";

Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID
    },
    API: {
      endpoints: [
        {
          name: OPEN,
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        },
        {
          name: Constants.API_PATH,
          endpoint: config.apiGateway.URL + `/${Constants.API_PATH}`,
          region: config.apiGateway.REGION
        }
      ]
    }
  });

ReactDOM.render(
<Router>
    <App/>
</Router>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
