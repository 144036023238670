import React, { useState } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import brand from '../assets/icon.png';
import FindReplaceEvidenceModal from '../containers/FindReplaceEvidenceModal';
import GenerateRollupModal from '../containers/GenerateRollupModal';
import styled from 'styled-components';
import CopyAppraisalDataModal from '../containers/CopyAppraisalDataModal.js';
import ClearProjectInfoModal from '../containers/ClearProjectInfoModal.js';
import FindingsReportModal from '../containers/FindingsReportModal.js';
import EvidenceSearchModal from '../containers/EvidenceSearchModal.js';
import EvidenceMappingsReportModal from '../containers/EvidenceMappingsReportModal.js';
import InterviewQuestionsReportModal from '../containers/InterviewQuestionsReportModal.js';
import InterviewQuestionsCountReportModal from '../containers/InterviewQuestionsCountReportModal.js';
import AffirmationCoverageReportModal from '../containers/AffirmationCoverageReportModal.js';
import PracticeComplianceReportModal from '../containers/PracticeComplianceReportModal.js';
import CompliancePercentReportModal from '../containers/CompliancePercentReportModal.js';
import RatingsReportModal from '../containers/RatingsReportModal.js';
import SummaryView from '../containers/SummaryView.js';
import FindingsPresentationModal from '../containers/FindingsPresentationModal.js';

const Styles = styled.div`
.navbar {
    background:rgb(0, 168, 168);   
    margin-bottom: 1.5rem;  
  } 
`;

export const DefaultNavigationBar = (props) => {
    const handleFindReplaceClicked = (e) => {
        e.preventDefault();
        setShowFindReplaceModal(true);
    }

    const handleEvidenceSearchClicked = (e) => {
        e.preventDefault();
        setShowEvidenceSearchModal(true);
    }

    const handleGenerateOrganizationalRollupClicked = (e) => {
        e.preventDefault();
        setShowGenerateRollupModal(true);
    }

    const handleCopyAppraisalDataClicked = (e) => {
        e.preventDefault();
        setShowCopyAppraisalDataModal(true);
    }

    const handleClearProjectInformationClicked = (e) => {
        e.preventDefault();
        setshowClearProjectInfoModal(true);
    }

    const openFindingsReport = (e) => {
        e.preventDefault();
        setShowFindingsModal(true);
    }

    const openEvidenceMappingsReport = (e) => {
        e.preventDefault();
        setShowEvidenceMappingsModal(true);
    }

    const openInterviewQuestionsReport = (e) => {
        e.preventDefault();
        setShowInterviewQuestionsModal(true);
    }

    const openInterviewQuestionsCountReport = (e) => {
        e.preventDefault();
        setShowInterviewQuestionsCountModal(true);
    }

    const openAffirmationCoverageReport = (e) => {
        e.preventDefault();
        setShowAffirmationCoverageModal(true);
    }

    const openPracticeComplianceReport = (e) => {
        e.preventDefault();
        setShowPracticeComplianceModal(true);
    }

    const openCompliancePercentReport = (e) => {
        e.preventDefault();
        setShowCompliancePercentModal(true);
    }

    const openRatingsReport = (e) => {
        e.preventDefault();
        setShowRatingsModal(true);
    }

    const openSummaryView = (e) => {
        e.preventDefault();
        setShowSummaryView(true);
    }

    const openFindingsPresentation = (e) => {
        e.preventDefault();
        setShowFindingsPresentationModal(true);
    }

    const [showFindReplaceModal, setShowFindReplaceModal] = useState(false);
    const [showEvidenceSearchModal, setShowEvidenceSearchModal] = useState(false);
    const [showGenerateRollupModal, setShowGenerateRollupModal] = useState(false);
    const [showCopyAppraisalDataModal, setShowCopyAppraisalDataModal] = useState(false);
    const [showClearProjectInfoModal, setshowClearProjectInfoModal] = useState(false);
    const [showFindingsModal, setShowFindingsModal] = useState(false);
    const [showEvidenceMappingsModal, setShowEvidenceMappingsModal] = useState(false);
    const [showInterviewQuestionsModal, setShowInterviewQuestionsModal] = useState(false);
    const [showInterviewQuestionsCountModal, setShowInterviewQuestionsCountModal] = useState(false);
    const [showAffirmationCoverageModal, setShowAffirmationCoverageModal] = useState(false);
    const [showPracticeComplianceModal, setShowPracticeComplianceModal] = useState(false);
    const [showCompliancePercentModal, setShowCompliancePercentModal] = useState(false);
    const [showRatingsModal, setShowRatingsModal] = useState(false);
    const [showSummaryView, setShowSummaryView] = useState(false);
    const [showFindingsPresentationModal, setShowFindingsPresentationModal] = useState(false);

    return (
        <Styles>
            <Navbar className='navbar navbar-expand' variant='dark'>
                <Navbar.Brand href='/about'>
                    <img
                        src={brand}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                        alt="CAP logo and text"
                    />{' '}
                CAP
            </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Item><Nav.Link href="/">Home</Nav.Link></Nav.Item>
                        <NavDropdown title="Tools" id="toolsDropdown">
                            <NavDropdown.Item style={{color:"dimgray"}} onClick={e => openSummaryView(e)}>View Appraisal Summary</NavDropdown.Item>
                            <NavDropdown.Item style={{color:"dimgray"}} onClick={e => handleCopyAppraisalDataClicked(e)}>Copy Appraisal Data</NavDropdown.Item>
                            <NavDropdown.Item style={{color:"dimgray"}} onClick={e => handleEvidenceSearchClicked(e)}>Evidence Search</NavDropdown.Item>
                            <NavDropdown.Item style={{color:"dimgray"}} onClick={e => handleFindReplaceClicked(e)}>Find and Replace Evidence Paths</NavDropdown.Item>
                            <NavDropdown.Item style={{color:"dimgray"}} onClick={e => handleGenerateOrganizationalRollupClicked(e)}>Generate Organizational Roll-up</NavDropdown.Item>
                            <NavDropdown.Item style={{color:"dimgray"}} onClick={e => handleClearProjectInformationClicked(e)}>Clear Project Information</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Reports">
                            <NavDropdown.Item style={{color:"dimgray"}} onClick={e => openFindingsReport(e)}>Findings</NavDropdown.Item>
                            <NavDropdown.Item style={{color:"dimgray"}} onClick={e => openFindingsPresentation(e)}>Findings Presentation</NavDropdown.Item>
                            <NavDropdown.Item style={{color:"dimgray"}} onClick={e => openEvidenceMappingsReport(e)}>Evidence Mappings</NavDropdown.Item>
                            <NavDropdown.Item style={{color:"dimgray"}} onClick={e => openInterviewQuestionsReport(e)}>Interview Questions</NavDropdown.Item>
                            <NavDropdown.Item style={{color:"dimgray"}} onClick={e => openInterviewQuestionsCountReport(e)}>Interview Question Count</NavDropdown.Item>
                            <NavDropdown.Item style={{color:"dimgray"}} onClick={e => openAffirmationCoverageReport(e)}>Affirmation Coverage</NavDropdown.Item>
                            <NavDropdown.Item style={{color:"dimgray"}} onClick={e => openPracticeComplianceReport(e)}>Practice Compliance</NavDropdown.Item>
                            <NavDropdown.Item style={{color:"dimgray"}} onClick={e => openCompliancePercentReport(e)}>Compliance Percent</NavDropdown.Item>
                            <NavDropdown.Item style={{color:"dimgray"}} onClick={e => openRatingsReport(e)}>Ratings</NavDropdown.Item>
                        </NavDropdown>
                        {props.isAdmin &&
                            <Nav.Item><Nav.Link href="/adminview">Admin</Nav.Link></Nav.Item>
                        }
                        <Nav.Item><Nav.Link href="/profile">Profile</Nav.Link></Nav.Item>
                        <Nav.Item onClick={props.onLogoutClicked}><Nav.Link href="/login">Logout </Nav.Link></Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <EvidenceSearchModal showModal={showEvidenceSearchModal} setShowModal={setShowEvidenceSearchModal} />
            <FindReplaceEvidenceModal showModal={showFindReplaceModal} setShowModal={setShowFindReplaceModal} />
            <GenerateRollupModal showModal={showGenerateRollupModal} setShowModal={setShowGenerateRollupModal} />
            <CopyAppraisalDataModal showModal={showCopyAppraisalDataModal} setShowModal={setShowCopyAppraisalDataModal} />
            <ClearProjectInfoModal showModal={showClearProjectInfoModal} setShowModal={setshowClearProjectInfoModal} />
            <FindingsReportModal showModal={showFindingsModal} setShowModal={setShowFindingsModal} />
            <EvidenceMappingsReportModal showModal={showEvidenceMappingsModal} setShowModal={setShowEvidenceMappingsModal} />
            <InterviewQuestionsReportModal showModal={showInterviewQuestionsModal} setShowModal={setShowInterviewQuestionsModal} />
            <InterviewQuestionsCountReportModal showModal={showInterviewQuestionsCountModal} setShowModal={setShowInterviewQuestionsCountModal} />
            <AffirmationCoverageReportModal showModal={showAffirmationCoverageModal} setShowModal={setShowAffirmationCoverageModal} />
            <PracticeComplianceReportModal showModal={showPracticeComplianceModal} setShowModal={setShowPracticeComplianceModal} />
            <CompliancePercentReportModal showModal={showCompliancePercentModal} setShowModal={setShowCompliancePercentModal} />
            <RatingsReportModal showModal={showRatingsModal} setShowModal={setShowRatingsModal} />
            <SummaryView showModal={showSummaryView} setShowModal={setShowSummaryView} />
            <FindingsPresentationModal showModal={showFindingsPresentationModal} setShowModal={setShowFindingsPresentationModal} />
        </Styles>
    )
};