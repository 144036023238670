import React from 'react';
import { Row, Col } from 'react-bootstrap'
import { getCurrentDateTime } from '../utils/helpers';

function generateSessionQuestionReport(reportData, addBlankLines, addEvidence) {
    let reportRows = [];
    let questionNumber = 1;
    for (let i = 0; i < reportData.length; i++) {
        reportRows.push(<h5 style={{ background: 'rgb(0,168,168)', paddingLeft: '5px' }}><b>{reportData[i].session} - {reportData[i].hours} hour(s) - {reportData[i].questionCount} Question(s)</b></h5>);
        let paData = reportData[i].practiceAreaData;

        for (let j = 0; j < paData.length; j++) {
            reportRows.push(<div style={{ paddingLeft: '35px' }}><b>{paData[j].practiceAreaName}</b></div>);
            let practiceData = paData[j].practiceData;
            for (let k = 0; k < practiceData.length; k++) {
                reportRows.push(<div style={{ paddingLeft: '70px' }}><b>{practiceData[k].practiceName}: {practiceData[k].statement}</b></div>);
                let questionData = practiceData[k].questionData;
                let formattedQuestionData = [];
                for (let l = 0; l < questionData.length; l++) {
                    let existingQuestion = formattedQuestionData.filter(x => x.question.toLowerCase().trim() === questionData[l].question.toLowerCase().trim())[0];
                    if (existingQuestion) {
                        existingQuestion.projects.push(questionData[l].projectName)
                    }
                    else {
                        let question = questionData[l].question;
                        let projects = [];
                        projects.push(questionData[l].projectName);
                        formattedQuestionData.push({
                            question,
                            projects
                        })
                    }
                }
                for (let l = 0; l < formattedQuestionData.length; l++) {
                    reportRows.push(<div style={{ paddingLeft: '105px' }}><b>Q {questionNumber}:</b> {formattedQuestionData[l].question}</div>);
                    reportRows.push(<div style={{ paddingLeft: '105px' }}><b>Project:</b>  {formattedQuestionData[l].projects.sort().join(', ')}</div>);
                    reportRows.push(<div><br></br></div>);
                    if (addBlankLines) {
                        reportRows.push(<div><br></br><br></br><br></br><br></br><br></br><br></br></div>);
                    }
                    questionNumber++;
                }
                if (addEvidence) {
                    let evidenceData = practiceData[k].evidenceData;
                    reportRows.push(<div><br></br></div>);
                    reportRows.push(<h5 style={{ color: 'rgb(0,168,168)', paddingLeft: '105px' }}><b>Evidence</b></h5>);
                    if (evidenceData.length > 0) {
                        reportRows.push(
                            <Row style={{ paddingLeft: '105px' }}>
                                <Col><b>Project</b></Col>
                                <Col><b>Work Product Name</b></Col>
                                <Col><b>Comments</b></Col>
                            </Row>)
                        for (let l = 0; l < evidenceData.length; l++) {
                            reportRows.push(
                                <Row style={{ paddingLeft: '105px' }}>
                                    <Col>{evidenceData[l].projectName}</Col>
                                    <Col>{evidenceData[l].fileName}</Col>
                                    <Col>{evidenceData[l].notes}</Col>
                                </Row>)
                        }
                    }
                    else {
                        reportRows.push(<div style={{ paddingLeft: '105px' }}><b>No evidence found.</b></div>);
                    }
                }
                //space between practices
                reportRows.push(<div><br></br><br></br></div>);
            }
            //space between PAs
            reportRows.push(<div><br></br><br></br></div>);
        }
    }
    return reportRows;
}

function generatePracticeAreaQuestionReport(reportData, addBlankLines, addEvidence) {
    let reportRows = [];
    let questionNumber = 1;

    for (let i = 0; i < reportData.length; i++) {
        reportRows.push(<h5 style={{ background: 'rgb(0,168,168)', paddingLeft: '5px' }}><b>{reportData[i].practiceAreaName}</b></h5>);
        let practiceData = reportData[i].practiceData;
        for (let j = 0; j < practiceData.length; j++) {
            reportRows.push(<div style={{ paddingLeft: '35px' }}><b>{practiceData[j].practiceName}: {practiceData[j].statement}</b></div>);
            let questionData = practiceData[j].questionData;
            let formattedQuestionData = [];
            for (let k = 0; k < questionData.length; k++) {
                let existingQuestion = formattedQuestionData.filter(x => x.question.toLowerCase().trim() === questionData[k].question.toLowerCase().trim()
                    && x.sessionNames === questionData[k].sessionNames)[0];
                if (existingQuestion) {
                    existingQuestion.projects.push(questionData[k].projectName)
                }
                else {
                    let question = questionData[k].question;
                    let sessionNames = questionData[k].sessionNames;
                    let projects = [];
                    projects.push(questionData[k].projectName);
                    formattedQuestionData.push({
                        question,
                        sessionNames,
                        projects
                    })
                }
            }
            for (let k = 0; k < formattedQuestionData.length; k++) {
                reportRows.push(<div style={{ paddingLeft: '105px' }}><b>Q {questionNumber}:</b>  {formattedQuestionData[k].question}</div>);
                reportRows.push(<div style={{ paddingLeft: '105px' }}><b>Session(s):</b>  {formattedQuestionData[k].sessionNames}</div>);
                reportRows.push(<div style={{ paddingLeft: '105px' }}><b>Project:</b>  {formattedQuestionData[k].projects.sort().join(', ')}</div>);
                reportRows.push(<div><br></br></div>);
                if (addBlankLines) {
                    reportRows.push(<div><br></br><br></br><br></br><br></br><br></br><br></br></div>);
                }
                questionNumber++;
            }
            if (addEvidence) {
                let evidenceData = practiceData[j].evidenceData;
                reportRows.push(<div><br></br></div>);
                reportRows.push(<h5 style={{ color: 'rgb(0,168,168)', paddingLeft: '105px' }}><b>Evidence</b></h5>);
                if (evidenceData.length > 0) {
                    reportRows.push(
                        <Row style={{ paddingLeft: '105px' }}>
                            <Col><b>Project</b></Col>
                            <Col><b>Work Product Name</b></Col>
                            <Col><b>Comments</b></Col>
                        </Row>)
                    for (let l = 0; l < evidenceData.length; l++) {
                        reportRows.push(
                            <Row style={{ paddingLeft: '105px' }}>
                                <Col>{evidenceData[l].projectName}</Col>
                                <Col>{evidenceData[l].fileName}</Col>
                                <Col>{evidenceData[l].notes}</Col>
                            </Row>)
                    }
                }
                else {
                    reportRows.push(<div style={{ paddingLeft: '105px' }}><b>No evidence found.</b></div>);
                }
                //space between practices
                reportRows.push(<div><br></br><br></br></div>);
            }
            //space between PAs
            reportRows.push(<div><br></br><br></br></div>);
        }
    }
    return reportRows;
}

const QuestionsReportView = ({ filterType, addBlankLines, addEvidence, reportData }) => {
    let reportRows = [];

    if (filterType === "session") {
        reportRows = generateSessionQuestionReport(reportData, addBlankLines, addEvidence);
    }
    else {
        reportRows = generatePracticeAreaQuestionReport(reportData, addBlankLines, addEvidence);
    }

    let dateTime = getCurrentDateTime();
    return (
        <React.Fragment>
            <Row style={{ height: '20px', marginBottom: '8px' }}>
                <Col>
                    <p style={{ textAlign: "right" }}><small><b>Date/Time Generated:</b></small></p>
                </Col>
                <Col>
                    <p style={{ textAlign: "left" }}><small>{dateTime}</small></p>
                </Col>
            </Row>
            <hr class='my-2' style={{ height: '1px', borderWidth: '0', color: 'dimgray', backgroundColor: 'dimgray' }} />
            <div style={{ display: "grid", gridTemplateColumns: "15% 70% 15%", gridTemplateRows: "100%" }}>
                <div style={{ gridColumnStart: "2", gridRowStart: "1", border: 'none' }} >
                    {reportRows}
                </div>
            </div>
        </React.Fragment>
    );
}
export default QuestionsReportView; 