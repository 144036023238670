import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Home from '../containers/Home.js';
import Login from '../containers/Login.js';
import Users from '../containers/Users';
import Organizations from '../containers/Organizations';
import AdminView from '../containers/AdminView';
import Appraisals from '../containers/Appraisals';
import NotFound from './NotFound.js';
import { About } from '../About';
import AdminRoute from './AdminRoute';
import UserProfile from '../UserProfile';
import { EditableProfile } from '../EditableProfile';
import AuthenticatedRoute from './AuthenticatedRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute.js';
import UnconfirmedUser from './UnconfirmedUser';
import { LoginNavigationBar } from '../components/LoginNavigationBar';
import { DefaultNavigationBar } from '../components/DefaultNavigationBar';
import { Layout } from '../components/Layout';
import UpdatePasswordForm from '../containers/UpdatePasswordForm';
import ChangePasswordForm from '../containers/ChangePasswordForm';
import { SecondaryNavigationBar } from '../components/SecondaryNavigationBar.js';

const Routes = ({ childProps }) => {
  
  const currentPath = window.location.pathname;
  const getNavigationBar = () => {
    if(currentPath.includes("/login") || 
      currentPath ==='change'|| 
      currentPath ==='change-password'){
        return <LoginNavigationBar />
    }
    else if ((window.location.toString().endsWith("redirect=/")) ||
      currentPath === '/' || 
      currentPath === '/organizations'  || 
      currentPath === '/users'  || 
      currentPath === '/appraisals'){
        if(childProps.session){
          return <DefaultNavigationBar isAdmin={childProps.isAdmin} onLogoutClicked={childProps.onLogoutClicked} />
        }
        else{
          return <LoginNavigationBar />
        }
    }
    else{
      return <SecondaryNavigationBar onLogoutClicked={childProps.onLogoutClicked} />
    }
  };

  return (
    <Router>
          {getNavigationBar()}
          <Layout>
            <Switch>
              <AuthenticatedRoute path="/" exact component={Home} props={childProps} />
              <Route path="/about" exact component={About}/>
              <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />              
              <AuthenticatedRoute path="/profile" component={UserProfile} props={childProps} />
              <AuthenticatedRoute path="/update" component={EditableProfile} props={childProps} />
              <AuthenticatedRoute path="/change" component={UpdatePasswordForm} props={childProps} />
              <AuthenticatedRoute path="/change-password" component={ChangePasswordForm} props={childProps} />
              <AdminRoute path='/organizations' exact component={Organizations} props={childProps} />
              <AdminRoute path='/users' exact component={Users} props={childProps} />
              <AdminRoute path='/appraisals' exact component={Appraisals} props={childProps} />
              <AdminRoute path='/adminview' exact component={AdminView} props={childProps} />
              <Route path='/unconfirmed' exact component={UnconfirmedUser} />
              <Route component={NotFound} />
            </Switch>
          </Layout>
    </Router>
  );
}
export { Routes };
