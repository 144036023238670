import { useState, useCallback } from 'react';

//Simple custom hook that manages an array
// of objects and exposes easy-to-use
// wrappers for common use casess
// Note - This hook makes the assumption
// that the objects it is managing contain a unique key property
// called id.
function useArray(initial){
    const [value, setValue] = useState(initial);
    return{
        value,
        setValue,
        //Adds an item to the array.
        add: useCallback(a => setValue(v => [...v, a]), []),
        //Clears the array.
        clear: useCallback(()=> setValue(()=>[]), []),
        //Removes an object from the array by id.
        removeById: useCallback(
            id => setValue((arr)=>arr.filter((v) => v && v.id !== id)),
            []
        ),
        //Removes an object from the array by index.
        removeIndex: useCallback(
            index=>
                setValue((v) => {
                    v.splice(index, 1);
                    return v;
                }),
            []
        ),
        // replaces an object in the array with the given id with the given object i.
        replace: useCallback(
            (id, i) => setValue((arr)=> {
                const replaceIndex = arr.findIndex((el)=>el.id === id);
                if(replaceIndex !== -1){
                    let newArrary = arr.slice();
                    newArrary[replaceIndex] = i;
                    return newArrary;
                }
                return arr;
            }), []),
        //Don't need to use a callback here
        // because getById doesn't mutate the array.
        getById: function(id){
            return value.find((el)=>el.id === id)
        }
    };
}

export default useArray;