import React from "react";
import { Button, Spinner } from "react-bootstrap";
import "./LoaderButton.css";

export default ({
  isLoading,
  text,
  loadingText,
  className = "",
  disabled = false,
  ...props
}) =>
  <Button
    className={`LoaderButton ${className}`}
    variant="outline-light"
    size='sm'
    disabled={disabled || isLoading}
    {...props}
  >
    {isLoading &&
                <Spinner
                    as="span"
                    animation="border"
                    size='sm'
                    role="status"
                    aria-hidden="true"
                />}
    {/* {isLoading && <Glyphicon glyph="refresh" className="spinning" />} */}
    {!isLoading ? text : loadingText}
  </Button>    