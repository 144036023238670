import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faChevronDown, 
    faChevronUp, faCircle, faEllipsisH, faTimes, faCheck, faAdjust, faThumbsUp, faThumbsDown, faTrash, 
    faChevronCircleUp, faChevronCircleDown, faQuestion, faPlusCircle, faEdit, faPaintBrush } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

const HIGHLIGHT_YELLOW = "yellow";
const HIGHLIGHT_YELLOW_ICON = (<FontAwesomeIcon icon={faCircle} color="yellow" size="1x"/>);
const HIGHLIGHT_CHARTREUSE = "lightgreen";
const HIGHLIGHT_CHARTREUSE_ICON = (<FontAwesomeIcon icon={faCircle} color="lightgreen" size="1x"/>);
const HIGHLIGHT_TURQUOISE = "lightblue";
const HIGHLIGHT_TURQUOISE_ICON = (<FontAwesomeIcon icon={faCircle} color="lightblue" size="1x"/>);
const HIGHLIGHT_HOT_PINK = "lightpink";
const HIGHLIGHT_HOT_PINK_ICON = (<FontAwesomeIcon icon={faCircle} color="lightpink" size="1x"/>);
const HIGHLIGHT_ROYAL_BLUE = "orange";
const HIGHLIGHT_ROYAL_BLUE_ICON = (<FontAwesomeIcon icon={faCircle} color="orange" size="1x"/>);
const HIGHLIGHT_DEFAULT_BUTTON = "transparent"
const HIGHLIGHT_DEFAULT_BUTTON_ICON = (<FontAwesomeIcon icon={faPaintBrush} color="rgb(0,168,168)" size="1x"/>);

const NOT_EXAMINED = "Not Examined";
const NOT_EXAMINED_ICON = (<FontAwesomeIcon icon={faCircle} color="white" size="1x"/>);
const NOT_EXAMINED_ICON_EVAL = (<FontAwesomeIcon icon={faCircle} color="white" size="3x"/>);
const NOT_YET_IMPLEMENTED = "Not Yet Implemented";
const NOT_YET_IMPLEMENTED_ICON = (<FontAwesomeIcon icon={faEllipsisH} color="black" size="1x"/>);
const NOT_YET_IMPLEMENTED_ICON_EVAL = (<FontAwesomeIcon icon={faEllipsisH} color="black" size="3x"/>);
const MAJOR_ISSUES = "Major Issues";
const MAJOR_ISSUES_ICON = (<FontAwesomeIcon icon={faCircle} color="darkred" size="1x"/>);
const MAJOR_ISSUES_ICON_EVAL = (<FontAwesomeIcon icon={faCircle} color="darkred" size="3x"/>);
const MINOR_ISSUES = "Minor Issues";
const MINOR_ISSUES_ICON = (<FontAwesomeIcon icon={faCircle} color="gold" size="1x"/>);
const MINOR_ISSUES_ICON_EVAL = (<FontAwesomeIcon icon={faCircle} color="gold" size="3x"/>);
const NO_ISSUES = "No Issues";
const NO_ISSUES_ICON = (<FontAwesomeIcon icon={faCircle} color="darkgreen" size="1x"/>);
const NO_ISSUES_ICON_EVAL = (<FontAwesomeIcon icon={faCircle} color="darkgreen" size="3x"/>);
const DOES_NOT_MEET = "Does Not Meet";
const DOES_NOT_MEET_ICON = (<FontAwesomeIcon icon={faCircle} color="red" size="1x"/>);
const DOES_NOT_MEET_ICON_EVAL = (<FontAwesomeIcon icon={faCircle} color="red" size="3x"/>);
const PARTIALLY_MEETS = "Partially Meets";
const PARTIALLY_MEETS_ICON = (<FontAwesomeIcon icon={faAdjust} color="red" size="1x"/>);
const PARTIALLY_MEETS_ICON_EVAL = (<FontAwesomeIcon icon={faAdjust} color="red" size="3x"/>);
const LARGELY_MEETS = "Largely Meets";
const LARGELY_MEETS_ICON = (<FontAwesomeIcon icon={faAdjust} color="orange" size="1x"/>);
const LARGELY_MEETS_ICON_EVAL = (<FontAwesomeIcon icon={faAdjust} color="orange" size="3x"/>);
const FULLY_MEETS = "Fully Meets";
const FULLY_MEETS_ICON = (<FontAwesomeIcon icon={faCircle} color="mediumseagreen" size="1x"/>);
const FULLY_MEETS_ICON_EVAL = (<FontAwesomeIcon icon={faCircle} color="mediumseagreen" size="3x"/>);
const HIGH_RISK = "High Risk";
const HIGH_RISK_ICON = (<FontAwesomeIcon icon={faChevronDown} color="red" size="1x"/>);
const HIGH_RISK_ICON_RATINGS = (<FontAwesomeIcon icon={faChevronDown} color="red" size="3x"/>);
const LOW_RISK = "Low Risk";
const LOW_RISK_ICON = (<FontAwesomeIcon icon={faChevronUp} color="darkgreen" size="1x"/>);
const LOW_RISK_ICON_RATINGS = (<FontAwesomeIcon icon={faChevronUp} color="darkgreen" size="3x"/>);
const UNSATISFIED = "Unsatisfied";
const UNSATISFIED_ICON = (<FontAwesomeIcon icon={faPlay} color="red" size="1x" rotation={90}/>);
const UNSATISFIED_ICON_RATINGS = (<FontAwesomeIcon icon={faPlay} color="red" size="3x" rotation={90}/>);
const SATISFIED = "Satisfied";
const SATISFIED_ICON = (<FontAwesomeIcon icon={faPlay} color="lime" size="1x" rotation={270}/>);
const SATISFIED_ICON_RATINGS = (<FontAwesomeIcon icon={faPlay} color="lime" size="3x" rotation={270}/>);
const COPY = "Copy";
const COPY_ICON = (<img src={process.env.PUBLIC_URL + '/copier.png'} alt="Copy" style={{height: "25px", width: "20px"}}/>);
const PASTE = "Paste";
const PASTE_ICON = (<img src={process.env.PUBLIC_URL + '/clipboard.png'} alt="Paste" style={{height: "25px", width: "20px"}}/>);
const VALID = "Valid";
const VALID_ICON = (<FontAwesomeIcon icon={faThumbsUp} color="lime" size="1x"/>);
const INVALID = "Invalid";
const NOT_VALID_ICON = (<FontAwesomeIcon icon={faThumbsDown} color="red" size="1x"/>);
const NOT_REVIEWED = "Not Reviewed";
const NOT_REVIEWED_ICON = (<FontAwesomeIcon icon={faQuestion} size="1x" color="black"/>);
const DELETE = "Delete";
const DELETE_ICON = (<FontAwesomeIcon icon={faTrash} color="white" size="1x"/>);
const ADD = "Add";
const ADD_ICON = (<FontAwesomeIcon icon={faPlusCircle} color="white" size="1x"/>);
const EDIT = "Edit";
const EDIT_ICON = (<FontAwesomeIcon icon={faEdit} color="white" size="1x"/>);
const SORT_UP = "Move selected items to the top";
const SORT_UP_ICON = (<FontAwesomeIcon icon={faChevronCircleUp} size="1x" title={SORT_UP}/>);
const SORT_DOWN = "Move selected items to the bottom";
const SORT_DOWN_ICON = (<FontAwesomeIcon icon={faChevronCircleDown} size="1x" title={SORT_DOWN}/>);

const CHECK_ICON = (<FontAwesomeIcon icon={faCheck} color="green" size="1x"/>);
const X_ICON = (<FontAwesomeIcon icon={faTimes} color="red" size="1x"/>);

const customIconContainerStyle = {
    fontSize:".5rem"
}

const customIconStyle = {
    fontSize:'16px',
    marginRight:'1px',
    color:"rgb(0, 168, 168)"
}

const customIconContainerStyle_Ratings = {
    fontSize:"1.5rem"
}

const customIconStyle_Ratings = {
    fontSize:'36px',
    marginRight:'1px',
    color:"rgb(0, 168, 168)"
}

const TWO = "Two";
const TWO_ICON = (
    <span class="fa-stack" style={customIconContainerStyle}>
        <strong class="fa-stack-1x" style={customIconStyle}>2</strong>
    </span>
);
const TWO_ICON_RATINGS = (
    <span class="fa-stack" style={customIconContainerStyle_Ratings}>
        <strong class="fa-stack-1x" style={customIconStyle_Ratings}>2</strong>
    </span>
);

const THREE = "Three";
const THREE_ICON = (
    <span class="fa-stack" style={customIconContainerStyle}>
        <strong class="fa-stack-1x" style={customIconStyle}>3</strong>
    </span>
);
const THREE_ICON_RATINGS = (
    <span class="fa-stack" style={customIconContainerStyle_Ratings}>
        <strong class="fa-stack-1x" style={customIconStyle_Ratings}>3</strong>
    </span>
);

const FOUR = "Four";
const FOUR_ICON = (
    <span class="fa-stack" style={customIconContainerStyle}>
        <strong class="fa-stack-1x" style={customIconStyle}>4</strong>
    </span>
);
const FOUR_ICON_RATINGS = (
    <span class="fa-stack" style={customIconContainerStyle_Ratings}>
        <strong class="fa-stack-1x" style={customIconStyle_Ratings}>4</strong>
    </span>
);

const FIVE = "Five";
const FIVE_ICON = (
    <span class="fa-stack" style={customIconContainerStyle}>
        <strong class="fa-stack-1x" style={customIconStyle}>5</strong>
    </span>
);
const FIVE_ICON_RATINGS = (
    <span class="fa-stack" style={customIconContainerStyle_Ratings}>
        <strong class="fa-stack-1x" style={customIconStyle_Ratings}>5</strong>
    </span>
);

const OUT_OF_SCOPE = "Out of scope";
const OUT_OF_SCOPE_ICON = (
    <span class="fa-stack" style={customIconContainerStyle}>
        <strong class="fa-stack-1x" style={{fontSize:'12px', marginRight:'1px', color:"orange"}}>OS</strong>
    </span>
);
const OUT_OF_SCOPE_ICON_RATINGS = (
    <span class="fa-stack" style={customIconContainerStyle_Ratings}>
        <strong class="fa-stack-1x" style={{fontSize:'32px', marginRight:'3px', color:"orange"}}>OS</strong>
    </span>
);

const NOT_RATED = "Not Rated";
const NOT_RATED_ICON = (
    <span class="fa-stack" style={customIconContainerStyle}>
        <strong class="fa-stack-1x" style={{fontSize:'12px', marginRight:'1px', color:"red"}}>NR</strong>
    </span>
);
const NOT_RATED_ICON_RATINGS = (
    <span class="fa-stack" style={customIconContainerStyle_Ratings}>
        <strong class="fa-stack-1x" style={{fontSize:'32px', marginRight:'3px', color:"red"}}>NR</strong>
    </span>
);

const HIGHLIGHT_OPTIONS = [
    {value: HIGHLIGHT_DEFAULT_BUTTON, label: HIGHLIGHT_DEFAULT_BUTTON_ICON},
    {value: HIGHLIGHT_YELLOW, label: HIGHLIGHT_YELLOW_ICON},
    {value: HIGHLIGHT_CHARTREUSE, label:HIGHLIGHT_CHARTREUSE_ICON},
    {value: HIGHLIGHT_TURQUOISE, label: HIGHLIGHT_TURQUOISE_ICON},
    {value: HIGHLIGHT_HOT_PINK, label: HIGHLIGHT_HOT_PINK_ICON},
    {value: HIGHLIGHT_ROYAL_BLUE, label: HIGHLIGHT_ROYAL_BLUE_ICON}
];

const PA_OPTIONS = [
    {value: NOT_EXAMINED, label: NOT_EXAMINED_ICON},
    {value: TWO, label: TWO_ICON},
    {value: THREE, label: THREE_ICON},
    {value: FOUR, label: FOUR_ICON},
    {value: FIVE, label: FIVE_ICON},
    {value: OUT_OF_SCOPE, label: OUT_OF_SCOPE_ICON},
    {value: NOT_RATED, label: NOT_RATED_ICON}
];

const PA_GROUP_OPTIONS = [
    {value: NOT_EXAMINED, label: NOT_EXAMINED_ICON},
    {value: LOW_RISK, label: LOW_RISK_ICON},
    {value: HIGH_RISK, label: HIGH_RISK_ICON},
    {value: SATISFIED, label: SATISFIED_ICON},
    {value: UNSATISFIED, label: UNSATISFIED_ICON}
];

const RATINGS_LEGEND_MAP = [
    {value: NOT_EXAMINED, label: NOT_EXAMINED_ICON},
    {value: OUT_OF_SCOPE, label: OUT_OF_SCOPE_ICON},
    {value: NOT_RATED, label: NOT_RATED_ICON},
    {value: LOW_RISK, label: LOW_RISK_ICON},
    {value: HIGH_RISK, label: HIGH_RISK_ICON},
    {value: SATISFIED, label: SATISFIED_ICON},
    {value: UNSATISFIED, label: UNSATISFIED_ICON}
];

const PRACTICES_OPTIONS = [
    {value: NOT_EXAMINED, label: NOT_EXAMINED_ICON},
    {value: DOES_NOT_MEET, label: DOES_NOT_MEET_ICON},
    {value: PARTIALLY_MEETS, label: PARTIALLY_MEETS_ICON},
    {value: LARGELY_MEETS, label: LARGELY_MEETS_ICON},
    {value: FULLY_MEETS, label: FULLY_MEETS_ICON},
    {value: NOT_YET_IMPLEMENTED, label: NOT_YET_IMPLEMENTED_ICON},
    {value: MAJOR_ISSUES, label: MAJOR_ISSUES_ICON},
    {value: MINOR_ISSUES, label: MINOR_ISSUES_ICON},
    {value: NO_ISSUES, label: NO_ISSUES_ICON}
];

const MAPPING_OPTIONS = [
    {value: NOT_REVIEWED, label: NOT_REVIEWED_ICON},
    {value: VALID, label: VALID_ICON},
    {value: INVALID, label: NOT_VALID_ICON}
];

const ICON_MAP = {
    [NOT_EXAMINED]: NOT_EXAMINED_ICON,
    [NOT_YET_IMPLEMENTED]: NOT_YET_IMPLEMENTED_ICON,
    [MAJOR_ISSUES]: MAJOR_ISSUES_ICON,
    [MINOR_ISSUES]: MINOR_ISSUES_ICON,
    [NO_ISSUES]: NO_ISSUES_ICON,
    [DOES_NOT_MEET]: DOES_NOT_MEET_ICON,
    [PARTIALLY_MEETS]: PARTIALLY_MEETS_ICON,
    [LARGELY_MEETS]: LARGELY_MEETS_ICON,
    [FULLY_MEETS]: FULLY_MEETS_ICON,
    [LOW_RISK]: LOW_RISK_ICON,
    [HIGH_RISK]: HIGH_RISK_ICON,
    [UNSATISFIED]: UNSATISFIED_ICON,
    [SATISFIED]: SATISFIED_ICON,
    [NOT_REVIEWED]: NOT_REVIEWED_ICON,
    [INVALID]: NOT_VALID_ICON,
    [VALID]: VALID_ICON,
    [TWO]: TWO_ICON,
    [THREE]: THREE_ICON,
    [FOUR]: FOUR_ICON,
    [FIVE]: FIVE_ICON,
    [OUT_OF_SCOPE]: OUT_OF_SCOPE_ICON,
    [NOT_RATED]: NOT_RATED_ICON,
}

const PRACTICE_EVAL_ICON_MAP = {
    [NOT_EXAMINED]: NOT_EXAMINED_ICON_EVAL,
    [MAJOR_ISSUES]: MAJOR_ISSUES_ICON_EVAL,
    [MINOR_ISSUES]: MINOR_ISSUES_ICON_EVAL,
    [NO_ISSUES]: NO_ISSUES_ICON_EVAL,
    [DOES_NOT_MEET]: DOES_NOT_MEET_ICON_EVAL,
    [PARTIALLY_MEETS]: PARTIALLY_MEETS_ICON_EVAL,
    [LARGELY_MEETS]: LARGELY_MEETS_ICON_EVAL,
    [FULLY_MEETS]: FULLY_MEETS_ICON_EVAL,
    [NOT_YET_IMPLEMENTED]: NOT_YET_IMPLEMENTED_ICON_EVAL
}

const RATINGS_ICON_MAP = {
    [NOT_EXAMINED]: NOT_EXAMINED_ICON_EVAL,
    [LOW_RISK]: LOW_RISK_ICON_RATINGS,
    [HIGH_RISK]: HIGH_RISK_ICON_RATINGS,
    [UNSATISFIED]: UNSATISFIED_ICON_RATINGS,
    [SATISFIED]: SATISFIED_ICON_RATINGS,
    [TWO]: TWO_ICON_RATINGS,
    [THREE]: THREE_ICON_RATINGS,
    [FOUR]: FOUR_ICON_RATINGS,
    [FIVE]: FIVE_ICON_RATINGS,
    [OUT_OF_SCOPE]: OUT_OF_SCOPE_ICON_RATINGS,
    [NOT_RATED]: NOT_RATED_ICON_RATINGS
}

const HIGHLIGHT_COLOR_MAP = {
    [HIGHLIGHT_YELLOW]: HIGHLIGHT_YELLOW_ICON,
    [HIGHLIGHT_CHARTREUSE]: HIGHLIGHT_CHARTREUSE_ICON,
    [HIGHLIGHT_TURQUOISE]: HIGHLIGHT_TURQUOISE_ICON,
    [HIGHLIGHT_HOT_PINK]: HIGHLIGHT_HOT_PINK_ICON,
    [HIGHLIGHT_ROYAL_BLUE]: HIGHLIGHT_ROYAL_BLUE_ICON,
    [HIGHLIGHT_DEFAULT_BUTTON]: HIGHLIGHT_DEFAULT_BUTTON_ICON
};

const COMMC_OPERATION_ICONS = {
    [COPY]: COPY_ICON,
    [PASTE]: PASTE_ICON,
    [SORT_UP]: SORT_UP_ICON,
    [SORT_DOWN]: SORT_DOWN_ICON,
    [DELETE]: DELETE_ICON,
    [ADD]: ADD_ICON,
    [EDIT]: EDIT_ICON
};

//Need to be able to re-size these icons in some places, so create a dictionary of
// the raw icon objects with some additional information so that other components can
// wrap them in a FontAwesomeIcon themselves.
const RAW_ICONS = {
    [VALID]: {
        icon: faThumbsUp,
        color: "lime",
        title: "Valid"
    },
    [INVALID]: {
        icon: faThumbsDown,
        color: "red",
        title: "Invalid"
    },
    [NOT_REVIEWED]: {
        icon: faQuestion,
        color: "black",
        title: "Not Reviewed"
    }
};

export {PA_OPTIONS, PA_GROUP_OPTIONS, RATINGS_LEGEND_MAP, PRACTICES_OPTIONS, MAPPING_OPTIONS, ICON_MAP, PRACTICE_EVAL_ICON_MAP, RATINGS_ICON_MAP, COMMC_OPERATION_ICONS, RAW_ICONS, CHECK_ICON, X_ICON, HIGHLIGHT_OPTIONS, HIGHLIGHT_COLOR_MAP}