import React from 'react';
import {Row, Col} from 'react-bootstrap'
import { getCurrentDateTime } from '../utils/helpers';

function getProjectSubTitle(projects){
    let projectSubTitle = "";
    for(let i = 0; i < projects.length; i++){
        if(i !== projects.length - 1){
            const nextProject = `${projects[i].name}, `;
            projectSubTitle = projectSubTitle.concat(nextProject);
        } else {
            projectSubTitle = projectSubTitle.concat(projects[i].name);
        }
    }
    return projectSubTitle;
}

function formatEvidenceData(evidenceData, searchDetails){
    let reportRows = [];
   
    reportRows.push(<h5 style={{paddingLeft:'5px'}}><b>{searchDetails}</b></h5>)
    
    for(let i = 0; i < evidenceData.length; i++){
    
        let practiceAreaData = evidenceData[i].practiceAreaData;
        let projectName = "";
        if(practiceAreaData.length > 0){

            projectName = evidenceData[i].projectName;
            reportRows.push(<h5 style={{background: 'rgb(0,168,168)', paddingLeft:'5px'}}><b>{projectName}</b></h5>)
        }

        for(let j = 0; j < practiceAreaData.length; j++){
            let practiceAreaName = practiceAreaData[j].practiceAreaName;
            reportRows.push(<div style={{paddingLeft : '35px'}}><b>{practiceAreaName}</b></div>)

            let practiceData = practiceAreaData[j].practiceData;
            for(let k = 0; k < practiceData.length; k++){
                let practiceName = practiceData[k].practiceName;
                reportRows.push(<div style={{paddingLeft : '70px'}}><b>{practiceName}</b></div>)
            }
            reportRows.push(<div><br></br></div>);
        }
    }
    
    return reportRows;
}

const EvidenceSearchView = ({reportType, projects, evidenceData, searchDetails}) => {
    const projectSubtitle = getProjectSubTitle(projects);
    let reportRows=[];
    let emptyProjects = [];
    let noDataText = " - No results found";
    let hasProjectWithNoData = false;
 
    evidenceData.forEach(d => {
        if(d.practiceAreaData.length === 0){
            emptyProjects.push(d.projectName);
            hasProjectWithNoData = true;
        }
    });

    const InsertNoDataRow = () =>{
        let projectList = emptyProjects.join(',');
        let noResults = projectList.concat(noDataText);

        if(hasProjectWithNoData){
            return(<div>
                {
                    noResults
                }
            </div>)
        }
    }

    if(evidenceData.length !== 0){        
        reportRows = formatEvidenceData(evidenceData, searchDetails); 
    }
    
    let dateTime = getCurrentDateTime();

    return (
        <React.Fragment>
            <Row style={{height:'auto', marginBottom:'0px'}}>
                <Col>
                    <p style={{textAlign:'right', fontSize:'18px'}}><b>Project(s):</b></p>
                </Col>
                <Col>
                    <p style={{textAlign:"left", fontSize:'18px'}}>{projectSubtitle}</p>
                </Col>
            </Row>
            <Row style={{height:'20px', marginBottom:'8px'}}>
            <Col>
                    <p style={{textAlign: "right"}}><small><b>Date/Time Generated:</b></small></p>
                </Col>
                <Col>
                    <p style={{textAlign: "left"}}><small>{dateTime}</small></p>
                </Col>
            </Row>
            <hr class='my-2' style={{ height: '1px', borderWidth: '0', color: 'dimgray', backgroundColor: 'dimgray' }} />
            <div style={{ display: "grid", gridTemplateColumns: "15% 70% 15%", gridTemplateRows: "100%" }}>
                <div style={{ gridColumnStart: "2", gridRowStart: "1", border: 'none' }} >
                    {reportRows}                    
                    <h5 style={{ background: 'rgb(0,168,168)' }}><b>{InsertNoDataRow()}</b></h5>
                </div>
            </div>            
        </React.Fragment>
    );
}
export default EvidenceSearchView; 