import { Auth } from 'aws-amplify';
import { VIEWS, PRACTICE_AREAS } from '../model/modelcontent';

//Returns an object that contains the correct authorization 
// and content-type headers for a typical API request along with
// an optional body
async function getDefaultAPIHeaders(body = null){
    const currentSession = await Auth.currentSession();
    return {
        headers:{
            'Content-Type': 'application/json',
            Authorization: currentSession.idToken.jwtToken
        },
        body: body
    };
}

//Formats the node info data into an API path that can be used to make back end calls
function getNodeAPIPath(nodeInfo, apiType){
    return nodeInfo ? `${apiType}/${nodeInfo.appraisalId}/${nodeInfo.projectId}/${nodeInfo.nodeId}` : ""
}

function createMergeConflictData(serverText, clientText, revNumber){
    return {
        client:{
            text: clientText,
            revNumber: revNumber
        },
        server: {
            text: serverText,
            revNumber: revNumber
        }
    };
}

const Constants = {
    USER_PATH: '/users',
    APPRAISAL_PATH: '/appraisals',
    INTERVIEW_PATH: '/interviews',
    INTERVIEW_QUESTIONS_PATH: '/interviewquestions',
    APPRAISAL_STATE_PATH: '/appraisalstate',
    APPRAISAL_ITEM_PATH: '/appraisalitem',
    APPRAISAL_EVIDENCE_PATH: '/appraisalevidence',
    API_PATH: 'api'
}

function sortPracticeAreas(practiceAreas) {
    return practiceAreas.sort((a, b)=>{
        if(a.Id > b.Id || a.id > b.id){
            return 1;
        } else if (a.Id === b.Id || a.id === b.id){
            return 0;
        } else{
            return -1;
        }
    });
}

const modelContentUtils = {
    getPracticeAreasFromView: (view, sort = true, returnNamesOnly = false) =>{
        const practiceAreaIds = VIEWS[view];
        const practiceAreas = Object.keys(practiceAreaIds).map(k=>{
            const pa = PRACTICE_AREAS[k];
            if(!returnNamesOnly){
                return pa;
            } else {
                return {
                    id: pa.Id,
                    name: pa.Name 
                }
            }
        });
        if(sort){
            return sortPracticeAreas(practiceAreas);
        } else {
            return practiceAreas;
        }
    },
    getPracticeAreasFromAppraisalProjects: (appraisalProjects, sort = true, returnNamesOnly = false) =>{
        let appraisalPracticeAreaIds = [];
        for(let i = 0; i < appraisalProjects.length; i++){
            let currentProject = appraisalProjects[i];
            for(let j = 0; j < currentProject.practiceAreas.length; j++){
                appraisalPracticeAreaIds.push(currentProject.practiceAreas[j]);
            }
            appraisalPracticeAreaIds = Array.from(new Set(appraisalPracticeAreaIds));
        }
        const practiceAreas = appraisalPracticeAreaIds.map(k=>{
            const pa = PRACTICE_AREAS[k];
            if(!returnNamesOnly){
                return pa;
            } else {
                return {
                    id: pa.Id,
                    name: pa.Name 
                }
            }
        });
        if(sort){
            return sortPracticeAreas(practiceAreas);
        } else {
            return practiceAreas;
        }
    },
    getMaturityLevelFromView: (view) => {
        const levelRegex = /\d/;
        const matches = view.match(levelRegex);
        return matches[0];
    }
};

const emailUtils = {
    validateEmailCase: (event) => {
        const code = event.charCode ? String.fromCharCode(event.charCode)
        : event.clipboardData.getData('text');
       const matches = code.match(new RegExp("[A-Z]"));
       if(matches){
         event.preventDefault();
       }
    }
}

function getCurrentDateTime() {
    var d = new Date();
    var hr = d.getHours();
    var min = d.getMinutes();
    if (min < 10) {
        min = "0" + min;
    }
    var ampm = "AM";
    if (hr === 0){
        hr = 12;
    }
    else if (hr === 12){
        ampm = "PM";
    }
    else if (hr > 12) {
        hr -= 12;
        ampm = "PM";
    }

    var date = d.getDate();
    var month = d.getMonth();
    var year = d.getFullYear();
    return month + 1 + "-" + date + "-" + year + " " + hr + ":" + min + " " + ampm;
}

export { Constants, getDefaultAPIHeaders, modelContentUtils, emailUtils, getNodeAPIPath, createMergeConflictData, getCurrentDateTime };