import React from 'react';
import { PRACTICES_OPTIONS, RATINGS_LEGEND_MAP } from '../utils/IconMap';

const CharacterizationsLegend = (
    <div>
        {getCharacterizationLegendItems()}
    </div>
);

const RatingsLegend = (
    <div>
        {getRatingsLegendItems()}
    </div>
);

function getCharacterizationLegendItems() {
    return generateLegend("Characterizations Legend", PRACTICES_OPTIONS);
}

function getRatingsLegendItems() {
    return generateLegend("Ratings Legend", RATINGS_LEGEND_MAP);
}

//takes a title and a collection with label and value properties to generate a legend
function generateLegend(title, collection){
    let rows = [];
    rows.push(
        <div class="list-group-item" style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none', borderBottomWidth: '2px', marginLeft: '20px' }} >
            <b>{title}</b>
        </div>
    );

    let columns = [];
    let currentRows = [];
    for (let i = 0; i < collection.length; i++) {
        currentRows.push(
            <div class="row">
                <div class="col-auto" style={{ padding: '4px 0' }}>{collection[i].label}</div>
                <div class="col-auto">{collection[i].value}</div>
            </div>
        );

        //every 3 options, create a new column
        if ((i + 1) % 3 === 0) {
            columns.push(
                <div class="col">
                    <div class="list-group-item" style={{ border: 'none', marginLeft: '20px', width: '200px' }}>
                        {currentRows}
                    </div>
                </div>
            );
            currentRows = [];
        }
    }

    //if there are any items left over, add them to a final column
    if(currentRows.length > 0){
        columns.push(
            <div class="col">
                <div class="list-group-item" style={{ border: 'none', marginLeft: '20px', width: '200px' }}>
                    {currentRows}
                </div>
            </div>
        );
        currentRows = [];
    }

    rows.push(
        <div class="row">
            {columns}
        </div>
    );

    return rows;
}

export { CharacterizationsLegend, RatingsLegend }