import React from 'react';
import ToggleButton from 'react-toggle-button';
import './ToggleButtonLoadingWrapper.css';

const ToggleButtonLoadingWrapper = ({ value, onToggle }) => {
    return(
        <div className="toggle-button-items">
            <label className="heading" style={{color:'dimgray', fontSize:'16px', verticalAlign:'bottom', marginBottom:'0rem', fontWeight:'bold'}}>Affirmed:</label>
            <ToggleButton value={value} onToggle={e=>onToggle()} inactiveLabel="No" activeLabel="Yes"/>
        </div>
    );
}

export default ToggleButtonLoadingWrapper;