import React from 'react';
import './UnconfirmedUser.css';

function UnconfirmedUser(props){
    return(
        <div className="unconfirmed">
            <h1>Your account has not been activated</h1>
            <h3>Please contact your Cepeda Systems POC for more details.</h3>
        </div>
    )
}

export default UnconfirmedUser;