import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, FormControl, Row, Col } from 'react-bootstrap';
import CopyAppraisalDataPracticeModal from '../containers/CopyAppraisalDataPracticeModal';
import CopyAppraisalDataPracticeAreaModal from '../containers/CopyAppraisalDataPracticeAreaModal';
import CopyAppraisalDataProjectModal from '../containers/CopyAppraisalDataProjectModal';
import '../modal.css';

function CopyAppraisalDataModal(props) {

    //parent modal states
    const [actionLoading, setActionLoading] = useState(false);

    //copy options
    const [selectedCopyLevel, setSelectedCopyLevel] = useState('practice');
    const [copyEvidence, setCopyEvidence] = useState(false);
    const [copyInterviews, setCopyInterviews] = useState(false);
    const [copyNotes, setCopyNotes] = useState(false);
    const [copyStrengths, setCopyStrengths] = useState(false);
    const [copyWeaknesses, setCopyWeaknesses] = useState(false);
    const [copyIOs, setCopyIOs] = useState(false);
    const [copyOptions, setCopyOptions] = useState("");

    //submodal states
    const [showPracticeModal, setShowPracticeModal] = useState(false);
    const [showPracticeAreaModal, setShowPracticeAreaModal] = useState(false);
    const [showProjectModal, setShowProjectModal] = useState(false);

    //reset when modal is shown
    useEffect(() => {
        if (props.showModal === true) {
            ResetModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showModal]);

    const ResetModal = () => {
        setSelectedCopyLevel('practice');
        setShowPracticeModal(false);
        setCopyEvidence(false);
        setCopyInterviews(false);
        setCopyNotes(false);
        setCopyStrengths(false);
        setCopyWeaknesses(false);
        setCopyIOs(false);
    }

    const handleClick = async () => {
        let options = {
            Evidence: copyEvidence,
            Interviews: copyInterviews,
            Notes: copyNotes,
            Strengths: copyStrengths,
            Weaknesses: copyWeaknesses,
            IOs: copyIOs
        };
        setCopyOptions(options);

        if(selectedCopyLevel === "practice"){
            setShowPracticeModal(true);
        }
        else if(selectedCopyLevel === "practiceArea"){
            setShowPracticeAreaModal(true);
        }
        else if(selectedCopyLevel === "project"){
            setShowProjectModal(true);
        }
    }

    const tryHideModal = (e) => {
        if (!actionLoading) {
            props.setShowModal(false)
        }
    }

    const handleCopyLevelChanged = (e) => {
        setSelectedCopyLevel(e);
    }

    const evidenceCheckBoxChanged = () => {
        setCopyEvidence(!copyEvidence);
    }

    const interviewsCheckBoxChanged = () => {
        setCopyInterviews(!copyInterviews);
    }

    const notesCheckBoxChanged = () => {
        setCopyNotes(!copyNotes);
    }

    const strengthsCheckBoxChanged = () => {
        setCopyStrengths(!copyStrengths);
    }

    const weaknessesCheckBoxChanged = () => {
        setCopyWeaknesses(!copyWeaknesses);
    }

    const iosCheckBoxChanged = () => {
        setCopyIOs(!copyIOs);
    }

    const canContinue = () => {
        return (copyEvidence
            || copyInterviews
            || copyNotes
            || copyStrengths
            || copyWeaknesses
            || copyIOs)
    }

    return (
        <Modal show={props.showModal} onHide={(e) => tryHideModal(e)} animation>
            <Modal.Header closeButton disabled={actionLoading}>
                <Modal.Title style={{ fontSize: '18px', color: 'dimgray' }}>
                    <img
                        src={require('../assets/icon.png')}
                        width="30"
                        height="30"
                        className="d-inline-block align-middle"
                        alt="CAP logo"
                    />{' '}Copy Appraisal Data</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Form.Group as={Row} className="d-flex justify-content-center">
                    <Col sm={3} style={{ marginLeft: "-100px" }}></Col>
                    <Form.Label
                        column sm='3'
                        style={{
                            color: 'white',
                            backgroundColor: 'rgb(0,168,168)',
                            fontSize: '16px',
                            textAlign: 'center'
                        }}>
                        Copy Level
                    </Form.Label>
                    <Col sm={6} style={{ textAlign: 'left' }}>
                        <FormControl
                            as="select"
                            style={{ borderRightColor: 'white', borderTopColor: 'white', color:'dimgray' }}
                            onChange={(e) => handleCopyLevelChanged(e.target.value)}
                        >
                            {[
                                <option value="practice">Practice</option>,
                                <option value="practiceArea">Practice Area</option>,
                                <option value="project">Project</option>
                            ]}
                        </FormControl>
                    </Col>
                </Form.Group>
                <Row className="d-flex justify-content-center">
                    <Col sm={3} style={{ marginLeft: "-100px" }}></Col>
                    <Form.Label
                        column sm='3'
                        style={{
                            color: 'white',
                            backgroundColor: 'rgb(0,168,168)',
                            fontSize: '16px',
                            textAlign: 'center'
                        }}>
                        Info to Copy
                    </Form.Label>
                    <Col sm={6} style={{ textAlign: 'left' }}>
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Evidence"
                                style={{color: 'dimgray'}}
                                checked={copyEvidence}
                                onChange={evidenceCheckBoxChanged}
                                disabled={actionLoading}
                            />
                            <Form.Check type="checkbox" label="Interviews"
                                style={{color: 'dimgray'}}
                                checked={copyInterviews}
                                onChange={interviewsCheckBoxChanged}
                                disabled={actionLoading}
                            />
                            <Form.Check type="checkbox" label="Notes"
                                style={{color: 'dimgray'}}
                                checked={copyNotes}
                                onChange={notesCheckBoxChanged}
                                disabled={actionLoading}
                            />
                            <Form.Check type="checkbox" label="Strengths"
                                style={{color: 'dimgray'}}
                                checked={copyStrengths}
                                onChange={strengthsCheckBoxChanged}
                                disabled={actionLoading}
                            />
                            <Form.Check type="checkbox" label="Weaknesses"
                                style={{color: 'dimgray'}}
                                checked={copyWeaknesses}
                                onChange={weaknessesCheckBoxChanged}
                                disabled={actionLoading}
                            />
                            <Form.Check type="checkbox" label="Improvement Opportunities"
                                style={{color: 'dimgray'}}
                                checked={copyIOs}
                                onChange={iosCheckBoxChanged}
                                disabled={actionLoading}
                            />
                        </Form.Group>

                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer style={{ backgroundColor: 'rgb(0,168,168)' }}>
                <Button
                    variant="outline-light"
                    size='sm'
                    disabled={actionLoading || !canContinue()}
                    onClick={(event) => handleClick()}
                >Continue
                </Button>{' '}
            </Modal.Footer>
            <CopyAppraisalDataPracticeModal showModal={showPracticeModal} setShowModal={setShowPracticeModal} options={copyOptions}/>
            <CopyAppraisalDataPracticeAreaModal showModal={showPracticeAreaModal} setShowModal={setShowPracticeAreaModal} options={copyOptions}/>
            <CopyAppraisalDataProjectModal showModal={showProjectModal} setShowModal={setShowProjectModal} options={copyOptions}/>
        </Modal>
    );
}

export default CopyAppraisalDataModal;