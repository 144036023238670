import React, { useState, useRef } from 'react';
import { API } from 'aws-amplify';
import { Modal, FormGroup, FormControl, Button, FormCheck, Row, Col, Spinner } from 'react-bootstrap';
import './EvidenceModal.css';
import '../modal.css';
import { Constants, getDefaultAPIHeaders } from '../utils/helpers';
import NetworkErrorModal from '../presenters/NetworkErrorModal';

const EvidenceModal = ({ modalState, setModalState, evidence, nodeInfo, formatEvidenceData }) => {
    const [showNetworkErrorModal, setShowNetworkErrorModal] = useState(false);
    const [actionMade, setActionMade] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);
    const [locationError, setLocationError] = useState(false);
    const [fileNameError, setFileNameError] = useState(false);
    const browseRef = useRef({});
    const autoFocus = React.createRef();

    const handleClose = () => {
        setModalState((prevState) => (
            {
                ...prevState,
                showModal: false
            }
        ));
        setLocationError(false);
        setFileNameError(false);
    }

    const handleModalStateChanged = (name, value) => {
        if (name === "browseLocation") {
            //TODO - add validation before setting the state.
            setModalState((prevState) => (
                {
                    ...prevState,
                    location: browseRef.current.files[0].path
                }
            ));
            setLocationError(false);
        } else {
            setModalState((prevState) => (
                {
                    ...prevState,
                    [name]: value
                }
            ));
            if (name === "location") {

                //we try to autopopulate the fileName (work product name field) based on input from the location box
                if (value === "") {
                    setLocationError(true);
                    setModalState((prevState) => (
                        {
                            ...prevState,
                            fileName: ""
                        }
                    ));
                }
                else {
                    setLocationError(false);
                    if (value.includes('/') || value.includes('\\')) {
                        let workProductName = value.replace(/^.*[\\/]/, '').replace(/"/g, '');

                        setModalState((prevState) => (
                            {
                                ...prevState,
                                fileName: workProductName
                            }
                        ));
                    }
                }
            }
            if (name === "fileName") {
                setFileNameError(value === "");
            }
        }
    }

    const handleClick = async () => {
        if (modalState.location.trim() !== "" && modalState.fileName.trim() !== "") {

            if(actionMade) return;

            setActionMade(true);
            setActionLoading(true);
            setLocationError(false);
            setFileNameError(false);

            if (modalState.titleFragment === "Add") {
                const body = {
                    node_id: nodeInfo.nodeId,
                    appraisal_id: nodeInfo.appraisalId,
                    project_id: nodeInfo.projectId,
                    evidence_fileName: modalState.fileName,
                    evidence_path: modalState.location.replace(/"/g, ''),
                    evidence_notes: modalState.comments || "-",
                    evidence_state: "VRE",
                    isHyperlink: modalState.isHyperlink
                };
                try {
                    const data = await API.post(Constants.API_PATH, Constants.APPRAISAL_EVIDENCE_PATH, await getDefaultAPIHeaders(body))
                        .catch(err => {
                            if (err.message === "Network Error") {
                                setShowNetworkErrorModal(true);
                            }
                            return;
                        });
                    formatEvidenceData(data, evidence);
                }
                catch (e) {
                    console.log('evidence post failure' + e);
                }
            } else {
                const body = {
                    node_id: nodeInfo.nodeId,
                    appraisal_id: nodeInfo.appraisalId,
                    project_id: nodeInfo.projectId,
                    evidence_fileName: modalState.fileName,
                    evidence_path: modalState.location.replace(/"/g, ''),
                    evidence_notes: modalState.comments || '-',
                    evidence_state: modalState.state,
                    revision_number: modalState.revNumber,
                    appraisal_evidence_id: modalState.id,
                    isHyperlink: modalState.isHyperlink
                };
                try {
                    const data = await API.put(Constants.API_PATH, Constants.APPRAISAL_EVIDENCE_PATH, await getDefaultAPIHeaders(body))
                        .catch(err => {
                            if (err.message === "Network Error") {
                                setShowNetworkErrorModal(true);
                            }
                        });
                    formatEvidenceData(data, evidence);
                }
                catch (e) {
                    //if the error code is 412, it was unable to put, retry put with returned revision number
                    if (e.response.status === 412) {
                        console.log('edit evidence revision mismatch, retrying.')
                        const body = {
                            node_id: nodeInfo.nodeId,
                            appraisal_id: nodeInfo.appraisalId,
                            project_id: nodeInfo.projectId,
                            evidence_fileName: modalState.fileName,
                            evidence_path: modalState.location.replace(/"/g, ''),
                            evidence_notes: modalState.comments || '-',
                            evidence_state: modalState.state,
                            revision_number: e.response.data.revision_number,
                            appraisal_evidence_id: modalState.id,
                            isHyperlink: modalState.isHyperlink
                        };
                        try {
                            const data = await API.put(Constants.API_PATH, Constants.APPRAISAL_EVIDENCE_PATH, await getDefaultAPIHeaders(body))
                                .catch(err => {
                                    if (err.message === "Network Error") {
                                        setShowNetworkErrorModal(true);
                                    }
                                });
                            formatEvidenceData(data, evidence);
                            console.log('edit evidence retry successful.')
                        }
                        catch (e) {
                            console.log('evidence post failure' + e);
                        }
                    }
                    else {
                        console.log('evidence post failure' + e);
                    }
                }
            }
            setModalState((prevState) => (
                {
                    ...prevState,
                    showModal: false
                }
            ));
            setActionLoading(false);
        } else {
            if (modalState.location.trim() === "") {
                setLocationError(true);
            }
            if (modalState.fileName.trim() === "") {
                setFileNameError(true);
            }
        }
    }

    const isHyperlinkChanged = () => {
        setModalState((prevState) => (
            {
                ...prevState,
                isHyperlink: !modalState.isHyperlink
            }
        ));
    }

    const onModalOpened = () => {
        autoFocus.current.focus();
        setActionMade(false);
    }

    const fileNameStyle = fileNameError ? {
        borderBottomColor: 'red',
        borderRightColor: 'white',
        borderLeftColor: 'white',
        borderTopColor: 'white'
    }
        : {
            borderBottomColor: 'dimgray',
            borderRightColor: 'white',
            borderLeftColor: 'white',
            borderTopColor: 'white'
        };
    const fileNameTitle = fileNameError ? "The Work Product Name field must not be empty" : "";
    const locationStyle = locationError ? {
        borderBottomColor: 'red',
        borderRightColor: 'white',
        borderLeftColor: 'white',
        borderTopColor: 'white'
    }
        : {
            borderBottomColor: 'dimgray',
            borderRightColor: 'white',
            borderLeftColor: 'white',
            borderTopColor: 'white'
        };
    const locationTitle = locationError ? "The Location field must not be empty" : "";
    const textAreaStyle = {
        borderBottomColor: 'dimgray',
        borderRightColor: 'white',
        borderLeftColor: 'white',
        borderTopColor: 'white',
        color: 'dimgray'
    };

    return (
        <div>
            <Modal show={modalState.showModal} backdrop="static" onHide={handleClose} bsSize="large" onEntered={onModalOpened}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '18px', color: 'dimgray' }}>
                        <img
                            src={require('../assets/icon.png')}
                            width="30"
                            height="30"
                            className="d-inline-block align-middle"
                            alt="CAP logo"
                        />{modalState.titleFragment} Evidence</Modal.Title>
                </Modal.Header>
                <Row className="d-flex justify-content-center">
                    <Col className='col-md-10'>
                        <Modal.Body>
                            <FormGroup controlId="locationInput">
                                <div>
                                    <FormControl
                                        ref={autoFocus}
                                        type="text"
                                        name="location"
                                        style={locationStyle}
                                        title={locationTitle}
                                        onChange={(e) => handleModalStateChanged(e.target.name, e.target.value)}
                                        value={modalState.location}
                                        placeholder='File Location' />
                                </div>
                            </FormGroup>
                            <FormGroup controlId="formBasicCheckbox">
                                <FormCheck type="checkbox" label="Hyperlink File Location"
                                    style={{ color: "dimgray" }}
                                    checked={modalState.isHyperlink}
                                    onChange={isHyperlinkChanged}
                                />
                            </FormGroup>
                            <FormGroup controlId="filenameInput">
                                <div>
                                    <FormControl
                                        type="text"
                                        name="fileName"
                                        style={fileNameStyle}
                                        title={fileNameTitle}
                                        onChange={(e) => handleModalStateChanged(e.target.name, e.target.value)}
                                        value={modalState.fileName}
                                        placeholder='Work Product Name' />
                                </div>
                            </FormGroup>
                            <FormGroup controlId="commentsInput">
                                <FormControl
                                    style={textAreaStyle}
                                    as='textarea' rows='4'
                                    name="comments"
                                    onChange={(e) => handleModalStateChanged(e.target.name, e.target.value)}
                                    value={modalState.comments}
                                    placeholder='Comments' />
                            </FormGroup>
                        </Modal.Body>
                    </Col>
                </Row>
                <Modal.Footer style={{ backgroundColor: 'rgb(0,168,168)' }}>
                    <Button
                        variant="outline-light"
                        size='sm'
                        //disabled={findTextError || replaceTextError || showReplacedModal}
                        onClick={(event) => handleClick()}
                    >
                        {actionLoading &&
                            <Spinner
                                as="span"
                                animation="border"
                                size='sm'
                                role="status"
                                aria-hidden="true"
                            />}
                        {!actionLoading ? "Save" : ''}
                    </Button>{' '}
                </Modal.Footer>

            </Modal>
            <NetworkErrorModal showNetworkErrorModal={showNetworkErrorModal} />
        </div>
    )
}

export default EvidenceModal;