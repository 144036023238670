import React from 'react';
import { Button } from 'react-bootstrap';
import './DeleteableListItem.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'

function DeleteableListItem(props) {
    const listItemKey = props.id + "listItem";
    const labelKey = props.id + "label";
    const buttonKey = props.id + "button";
    const editButtonKey = props.id + "editButton";

    return (
        <li className="projectItems" key={listItemKey} title={props.title}>
            <label className="projLabelName" style={{ color: 'dimgray' }} key={labelKey}>{props.displayText}</label>
            <Button
                variant="light"
                className="editProjButton"
                type="submit"
                size="sm"
                key={editButtonKey}
                style={{ backgroundColor: 'white', border: 'none' }}
                title= {`Edit ${props.title}`}
                onClick={(e) => props.onEditClicked({id:props.id, name:props.title})}>
                <FontAwesomeIcon icon={faEdit} size="lg" color="royalblue" />
            </Button>
            <Button
                variant="light"
                className="delProjButton"
                type="submit"
                size="sm"
                key={buttonKey}
                style={{ backgroundColor: 'white', border: 'none' }}
                title= {`Delete ${props.title}`}
                onClick={(e) => props.onDeleteClicked(props.id)}>
                <FontAwesomeIcon icon={faTrash} size="lg" color="red" />
            </Button>
        </li>
    )
}

export default DeleteableListItem;