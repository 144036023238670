import { MODEL_CONSTANTS } from './modelconstants';

const PRACTICES = {
    [MODEL_CONSTANTS.CAR_11]: {
        Statement:'Identify and address causes of selected outcomes.',
        Level: 1,
        Abbreviation:"CAR 1.1",
        Id: MODEL_CONSTANTS.CAR_11,
        PracticeAreaID: MODEL_CONSTANTS.CAR   
    },
    [MODEL_CONSTANTS.CAR_21]: {
        Statement: 'Select outcomes for analysis.',
        Level: 2,
        Abbreviation: 'CAR 2.1',
        Id: MODEL_CONSTANTS.CAR_21,
        PracticeAreaID: MODEL_CONSTANTS.CAR 
    },
    [MODEL_CONSTANTS.CAR_22]: {
        Statement: 'Analyze and address causes of outcomes.',
        Level: 2,
        Abbreviation: 'CAR 2.2',
        Id: MODEL_CONSTANTS.CAR_22,
        PracticeAreaID: MODEL_CONSTANTS.CAR 
    },
    [MODEL_CONSTANTS.CAR_31]: {
        Statement: 'Determine causes of selected outcomes by following an organizational process.',
        Level: 3,
        Abbreviation: 'CAR 3.1',
        Id: MODEL_CONSTANTS.CAR_31,
        PracticeAreaID: MODEL_CONSTANTS.CAR 
    },
    [MODEL_CONSTANTS.CAR_32]: {
        Statement: 'Propose actions to address identified causes.',
        Level: 3,
        Abbreviation: 'CAR 3.2',
        Id: MODEL_CONSTANTS.CAR_32,
        PracticeAreaID: MODEL_CONSTANTS.CAR 
    },
    [MODEL_CONSTANTS.CAR_33]: {
        Statement: 'Implement selected action proposals.',
        Level: 3,
        Abbreviation: 'CAR 3.3',
        Id: MODEL_CONSTANTS.CAR_33,
        PracticeAreaID: MODEL_CONSTANTS.CAR 
    },
    [MODEL_CONSTANTS.CAR_34]: {
        Statement: 'Record causal analysis and resolution data.',
        Level: 3,
        Abbreviation: 'CAR 3.4',
        Id: MODEL_CONSTANTS.CAR_34,
        PracticeAreaID: MODEL_CONSTANTS.CAR 
    },
    [MODEL_CONSTANTS.CAR_35]: {
        Statement: 'Submit improvement proposals for changes proven to be effective.',
        Level: 3,
        Abbreviation: 'CAR 3.5',
        Id: MODEL_CONSTANTS.CAR_35,
        PracticeAreaID: MODEL_CONSTANTS.CAR 
    },
    [MODEL_CONSTANTS.CAR_41]: {
        Statement: 'Perform root cause analysis of selected outcomes using statistical and other quantitative techniques.',
        Level: 4,
        Abbreviation: 'CAR 4.1',
        Id: MODEL_CONSTANTS.CAR_41,
        PracticeAreaID: MODEL_CONSTANTS.CAR 
    },
    [MODEL_CONSTANTS.CAR_42]: {
        Statement: 'Evaluate the effect of implemented actions on process performance using statistical and other quantitative techniques.',
        Level: 4,
        Abbreviation: 'CAR 4.2',
        Id: MODEL_CONSTANTS.CAR_42,
        PracticeAreaID: MODEL_CONSTANTS.CAR 
    },
    [MODEL_CONSTANTS.CAR_51]: {
        Statement: 'Use statistical and other quantitative techniques to evaluate other solutions and processes to determine if the resolution should be applied on a broader scale.',
        Level: 5,
        Abbreviation: 'CAR 5.1',
        Id: MODEL_CONSTANTS.CAR_51,
        PracticeAreaID: MODEL_CONSTANTS.CAR 
    },
    [MODEL_CONSTANTS.CM_11]: {
        Statement: 'Perform version control.',
        Level: 1,
        Abbreviation: 'CM 1.1',
        Id: MODEL_CONSTANTS.CM_11,
        PracticeAreaID: MODEL_CONSTANTS.CM
    },
    [MODEL_CONSTANTS.CM_21]: {
        Statement: 'Identify items to be placed under configuration management.',
        Level: 2,
        Abbreviation: 'CM 2.1',
        Id: MODEL_CONSTANTS.CM_21,
        PracticeAreaID: MODEL_CONSTANTS.CM
    },
    [MODEL_CONSTANTS.CM_22]: {
        Statement: 'Develop, keep updated, and use a configuration and change management system.',
        Level: 2,
        Abbreviation: 'CM 2.2',
        Id: MODEL_CONSTANTS.CM_22,
        PracticeAreaID: MODEL_CONSTANTS.CM  
    },
    [MODEL_CONSTANTS.CM_23]: {
        Statement: 'Develop or release baselines for internal use or for delivery to the customer.',
        Level: 2,
        Abbreviation: 'CM 2.3',
        Id: MODEL_CONSTANTS.CM_23,
        PracticeAreaID: MODEL_CONSTANTS.CM
    },
    [MODEL_CONSTANTS.CM_24]: {
        Statement: 'Manage changes to the items under configuration management.',
        Level: 2,
        Abbreviation: 'CM 2.4',
        Id: MODEL_CONSTANTS.CM_24,
        PracticeAreaID: MODEL_CONSTANTS.CM
    },
    [MODEL_CONSTANTS.CM_25]: {
        Statement: 'Develop, keep updated, and use records describing items under configuration management.',
        Level: 2,
        Abbreviation: 'CM 2.5',
        Id: MODEL_CONSTANTS.CM_25,
        PracticeAreaID: MODEL_CONSTANTS.CM
    },
    [MODEL_CONSTANTS.CM_26]: {
        Statement: 'Perform configuration audits to maintain the integrity of configuration baselines, changes, and content of the configuration management system.',
        Level: 2,
        Abbreviation: 'CM 2.6',
        Id: MODEL_CONSTANTS.CM_26,
        PracticeAreaID: MODEL_CONSTANTS.CM
    },
    [MODEL_CONSTANTS.CONT_11]: {
        Statement: 'Develop contingency approaches for managing significant disruptions to operations.',
        Level: 1,
        Abbreviation: 'CONT 1.1',
        Id: MODEL_CONSTANTS.CONT_11,
        PracticeAreaID: MODEL_CONSTANTS.CONT
    },
    [MODEL_CONSTANTS.CONT_21]: {
        Statement: 'Identify and prioritize functions essential for continuity.',
        Level: 2,
        Abbreviation: 'CONT 2.1',
        Id: MODEL_CONSTANTS.CONT_21,
        PracticeAreaID: MODEL_CONSTANTS.CONT
    },
    [MODEL_CONSTANTS.CONT_22]: {
        Statement: 'Identify and prioritize resources essential for continuity.',
        Level: 2,
        Abbreviation: 'CONT 2.2',
        Id: MODEL_CONSTANTS.CONT_22,
        PracticeAreaID: MODEL_CONSTANTS.CONT
    },
    [MODEL_CONSTANTS.CONT_23]: {
        Statement: 'Develop, keep updated, and follow continuity plans to resume performing essential functions.',
        Level: 2,
        Abbreviation: 'CONT 2.3',
        Id: MODEL_CONSTANTS.CONT_23,
        PracticeAreaID: MODEL_CONSTANTS.CONT
    },
    [MODEL_CONSTANTS.CONT_31]: {
        Statement: 'Develop and keep updated materials for continuity training.',
        Level: 3,
        Abbreviation: 'CONT 3.1',
        Id: MODEL_CONSTANTS.CONT_31,
        PracticeAreaID: MODEL_CONSTANTS.CONT
    },
    [MODEL_CONSTANTS.CONT_32]: {
        Statement: 'Provide and evaluate continuity training according to the plan.',
        Level: 3,
        Abbreviation: 'CONT 3.2',
        Id: MODEL_CONSTANTS.CONT_32,
        PracticeAreaID: MODEL_CONSTANTS.CONT
    },
    [MODEL_CONSTANTS.CONT_33]: {
        Statement: 'Prepare, conduct, and analyze results from verification and validation of the continuity plan.',
        Level: 3,
        Abbreviation: 'CONT 3.3',
        Id: MODEL_CONSTANTS.CONT_33,
        PracticeAreaID: MODEL_CONSTANTS.CONT
    },
    [MODEL_CONSTANTS.DAR_11]: {
        Statement: 'Define and record the alternatives.',
        Level: 1,
        Abbreviation: 'DAR 1.1',
        Id: MODEL_CONSTANTS.DAR_11,
        PracticeAreaID: MODEL_CONSTANTS.DAR
    },
    [MODEL_CONSTANTS.DAR_12]: {
        Statement: 'Make and record the decision.',
        Level: 1,
        Abbreviation: 'DAR 1.2',
        Id: MODEL_CONSTANTS.DAR_12,
        PracticeAreaID: MODEL_CONSTANTS.DAR
    },
    [MODEL_CONSTANTS.DAR_21]: {
        Statement: 'Develop, keep updated, and use rules to determine when to follow a recorded process for criteria-based decisions.',
        Level: 2,
        Abbreviation: 'DAR 2.1',
        Id: MODEL_CONSTANTS.DAR_21,
        PracticeAreaID: MODEL_CONSTANTS.DAR
    },
    [MODEL_CONSTANTS.DAR_22]: {
        Statement: 'Develop criteria for evaluating alternatives.',
        Level: 2,
        Abbreviation: 'DAR 2.2',
        Id: MODEL_CONSTANTS.DAR_22,
        PracticeAreaID: MODEL_CONSTANTS.DAR
    },
    [MODEL_CONSTANTS.DAR_23]: {
        Statement: 'Identify alternative solutions.',
        Level: 2,
        Abbreviation: 'DAR 2.3',
        Id: MODEL_CONSTANTS.DAR_23,
        PracticeAreaID: MODEL_CONSTANTS.DAR
    },
    [MODEL_CONSTANTS.DAR_24]: {
        Statement: 'Select evaluation methods.',
        Level: 2,
        Abbreviation: 'DAR 2.4',
        Id: MODEL_CONSTANTS.DAR_24,
        PracticeAreaID: MODEL_CONSTANTS.DAR
    },
    [MODEL_CONSTANTS.DAR_25]: {
        Statement: 'Evaluate and select solutions using criteria and methods.',
        Level: 2,
        Abbreviation: 'DAR 2.5',
        Id: MODEL_CONSTANTS.DAR_25,
        PracticeAreaID: MODEL_CONSTANTS.DAR
    },
    [MODEL_CONSTANTS.DAR_31]: {
        Statement: 'Develop, keep updated, and use a description of role-based decision authority.',
        Level: 3,
        Abbreviation: 'DAR 3.1',
        Id: MODEL_CONSTANTS.DAR_31,
        PracticeAreaID: MODEL_CONSTANTS.DAR
    },
    [MODEL_CONSTANTS.EST_11]: {
        Statement: 'Develop high-level estimates to perform the work.',
        Level: 1,
        Abbreviation: 'EST 1.1',
        Id: MODEL_CONSTANTS.EST_11,
        PracticeAreaID: MODEL_CONSTANTS.EST
    },
    [MODEL_CONSTANTS.EST_21]: {
        Statement: 'Develop, keep updated, and use the scope of what is being estimated.',
        Level: 2,
        Abbreviation: 'EST 2.1',
        Id: MODEL_CONSTANTS.EST_21,
        PracticeAreaID: MODEL_CONSTANTS.EST
    },
    [MODEL_CONSTANTS.EST_22]: {
        Statement: 'Develop and keep updated estimates for the size of the solution.',
        Level: 2,
        Abbreviation: 'EST 2.2',
        Id: MODEL_CONSTANTS.EST_22,
        PracticeAreaID: MODEL_CONSTANTS.EST
    },
    [MODEL_CONSTANTS.EST_23]: {
        Statement: 'Based on size estimates, develop and record effort, duration, and cost estimates and their rationale for the solution.',
        Level: 2,
        Abbreviation: 'EST 2.3',
        Id: MODEL_CONSTANTS.EST_23,
        PracticeAreaID: MODEL_CONSTANTS.EST
    },
    [MODEL_CONSTANTS.EST_31]: {
        Statement: 'Develop and keep updated a recorded estimation method.',
        Level: 3,
        Abbreviation: 'EST 3.1',
        Id: MODEL_CONSTANTS.EST_31,
        PracticeAreaID: MODEL_CONSTANTS.EST
    },
    [MODEL_CONSTANTS.EST_32]: {
        Statement: 'Use the organizational measurement repository and process assets for estimating work.',
        Level: 3,
        Abbreviation: 'EST 3.2',
        Id: MODEL_CONSTANTS.EST_32,
        PracticeAreaID: MODEL_CONSTANTS.EST
    },
    [MODEL_CONSTANTS.EVSD_11]: {
        Statement: 'Identify and record virtual solution delivery needs and constraints.',
        Level: 1,
        Abbreviation: 'EVSD 1.1',
        Id: MODEL_CONSTANTS.EVSD_11,
        PracticeAreaID: MODEL_CONSTANTS.EVSD
    },
    [MODEL_CONSTANTS.EVSD_12]: {
        Statement: 'Deliver virtual solutions.',
        Level: 1,
        Abbreviation: 'EVSD 1.2',
        Id: MODEL_CONSTANTS.EVSD_12,
        PracticeAreaID: MODEL_CONSTANTS.EVSD
    },
    [MODEL_CONSTANTS.EVSD_21]: {
        Statement: 'Develop, communicate, and follow an approach to address virtual solution delivery needs and constraints; and keep it updated.',
        Level: 2,
        Abbreviation: 'EVSD 2.1',
        Id: MODEL_CONSTANTS.EVSD_21,
        PracticeAreaID: MODEL_CONSTANTS.EVSD
    },
    [MODEL_CONSTANTS.EVSD_22]: {
        Statement: 'Evaluate the effectiveness and fidelity of the virtual solution delivery approach and take action to improve performance.',
        Level: 2,
        Abbreviation: 'EVSD 2.2',
        Id: MODEL_CONSTANTS.EVSD_22,
        PracticeAreaID: MODEL_CONSTANTS.EVSD
    },
    [MODEL_CONSTANTS.EVSD_31]: {
        Statement: 'Establish and deploy an organizational strategy and approach, and functional capability for delivering virtual solutions.',
        Level: 3,
        Abbreviation: 'EVSD 3.1',
        Id: MODEL_CONSTANTS.EVSD_31,
        PracticeAreaID: MODEL_CONSTANTS.EVSD
    },
    [MODEL_CONSTANTS.EVSD_32]: {
        Statement: 'Periodically perform virtual solution delivery reviews and evaluations throughout the organization.',
        Level: 3,
        Abbreviation: 'EVSD 3.2',
        Id: MODEL_CONSTANTS.EVSD_32,
        PracticeAreaID: MODEL_CONSTANTS.EVSD
    },
    [MODEL_CONSTANTS.EVSD_33]: {
        Statement: 'Take actions based on virtual delivery reviews and evaluations to verify that they are effectively addressing the needs and constraints of customers and the organization.',
        Level: 3,
        Abbreviation: 'EVSD 3.3',
        Id: MODEL_CONSTANTS.EVSD_33,
        PracticeAreaID: MODEL_CONSTANTS.EVSD
    },
    [MODEL_CONSTANTS.GOV_11]: {
        Statement: 'Senior management identifies what is important for doing the work and defines the approach needed to accomplish the objectives of the organization.',
        Level: 1,
        Abbreviation: 'GOV 1.1',
        Id: MODEL_CONSTANTS.GOV_11,
        PracticeAreaID: MODEL_CONSTANTS.GOV
    },
    [MODEL_CONSTANTS.GOV_21]: {
        Statement: 'Senior management defines, keeps updated, and communicates organizational directives for process implementation and performance improvement based on organization needs, and objectives.',
        Level: 2,
        Abbreviation: 'GOV 2.1',
        Id: MODEL_CONSTANTS.GOV_21,
        PracticeAreaID: MODEL_CONSTANTS.GOV
    },
    [MODEL_CONSTANTS.GOV_22]: {
        Statement: 'Senior management provides funding, resources, and training for developing, supporting, performing, improving, and evaluating adherence to processes.',
        Level: 2,
        Abbreviation: 'GOV 2.2',
        Id: MODEL_CONSTANTS.GOV_22,
        PracticeAreaID: MODEL_CONSTANTS.GOV
    },
    [MODEL_CONSTANTS.GOV_23]: {
        Statement: 'Senior management identifies their information needs and uses the collected information to provide governance and oversight of effective process implementation and performance improvement.',
        Level: 2,
        Abbreviation: 'GOV 2.3',
        Id: MODEL_CONSTANTS.GOV_23,
        PracticeAreaID: MODEL_CONSTANTS.GOV
    },
    [MODEL_CONSTANTS.GOV_24]: {
        Statement: 'Senior management assigns authority and holds people accountable for adhering to organization directives and achieving process implementation and performance improvement objectives.',
        Level: 2,
        Abbreviation: 'GOV 2.4',
        Id: MODEL_CONSTANTS.GOV_24,
        PracticeAreaID: MODEL_CONSTANTS.GOV
    },
    [MODEL_CONSTANTS.GOV_31]: {
        Statement: 'Senior management ensures that measures supporting objectives throughout the organization are collected, analyzed, and used.',
        Level: 3,
        Abbreviation: 'GOV 3.1',
        Id: MODEL_CONSTANTS.GOV_31,
        PracticeAreaID: MODEL_CONSTANTS.GOV
    },
    [MODEL_CONSTANTS.GOV_32]: {
        Statement: 'Senior management ensures that competencies and processes are aligned with the objectives of the organization.',
        Level: 3,
        Abbreviation: 'GOV 3.2',
        Id: MODEL_CONSTANTS.GOV_32,
        PracticeAreaID: MODEL_CONSTANTS.GOV
    },
    [MODEL_CONSTANTS.GOV_41]: {
        Statement: 'Senior management ensures that selected decisions are driven by statistical and quantitative analysis related to performance and achievement of quality and process performance objectives.',
        Level: 4,
        Abbreviation: 'GOV 4.1',
        Id: MODEL_CONSTANTS.GOV_41,
        PracticeAreaID: MODEL_CONSTANTS.GOV
    },
    [MODEL_CONSTANTS.II_11]: {
        Statement: 'Perform processes that address the intent of the Level 1 practices.',
        Level: 1,
        Abbreviation: 'II 1.1',
        Id: MODEL_CONSTANTS.II_11,
        PracticeAreaID: MODEL_CONSTANTS.II 
    },
    [MODEL_CONSTANTS.II_21]: {
        Statement: 'Provide sufficient resources, funding, and training for developing and performing processes.',
        Level: 2,
        Abbreviation: 'II 2.1',
        Id: MODEL_CONSTANTS.II_21,
        PracticeAreaID: MODEL_CONSTANTS.II 
    },
    [MODEL_CONSTANTS.II_22]: {
        Statement: 'Develop and keep processes updated, and verify they are being followed.',
        Level: 2,
        Abbreviation: 'II 2.2',
        Id: MODEL_CONSTANTS.II_22,
        PracticeAreaID: MODEL_CONSTANTS.II
    },
    [MODEL_CONSTANTS.II_31]: {
        Statement: 'Use organizational processes and process assets to plan, manage, and perform the work.',
        Level: 3,
        Abbreviation: 'II 3.1',
        Id: MODEL_CONSTANTS.II_31,
        PracticeAreaID: MODEL_CONSTANTS.II
    },
    [MODEL_CONSTANTS.II_32]: {
        Statement: 'Evaluate the adherence to and effectiveness of the organizational processes.',
        Level: 3,
        Abbreviation: 'II 3.2',
        Id: MODEL_CONSTANTS.II_32,
        PracticeAreaID: MODEL_CONSTANTS.II
    },
    [MODEL_CONSTANTS.II_33]: {
        Statement: 'Contribute process-related information or process assets to the organization.',
        Level: 3,
        Abbreviation: 'II 3.3',
        Id: MODEL_CONSTANTS.II_33,
        PracticeAreaID: MODEL_CONSTANTS.II
    },
    [MODEL_CONSTANTS.IRP_11]: {
        Statement: 'Record and resolve incidents.',
        Level: 1,
        Abbreviation: 'IRP 1.1',
        Id: MODEL_CONSTANTS.IRP_11,
        PracticeAreaID: MODEL_CONSTANTS.IRP
    },
    [MODEL_CONSTANTS.IRP_21]: {
        Statement: 'Develop, keep updated, and follow an approach for incident resolution and prevention.',
        Level: 2,
        Abbreviation: 'IRP 2.1',
        Id: MODEL_CONSTANTS.IRP_21,
        PracticeAreaID: MODEL_CONSTANTS.IRP
    },
    [MODEL_CONSTANTS.IRP_22]: {
        Statement: 'Monitor and resolve each incident to closure.',
        Level: 2,
        Abbreviation: 'IRP 2.2',
        Id: MODEL_CONSTANTS.IRP_22,
        PracticeAreaID: MODEL_CONSTANTS.IRP
    },
    [MODEL_CONSTANTS.IRP_23]: {
        Statement: 'Communicate incident status.',
        Level: 2,
        Abbreviation: 'IRP 2.3',
        Id: MODEL_CONSTANTS.IRP_23,
        PracticeAreaID: MODEL_CONSTANTS.IRP
    },
    [MODEL_CONSTANTS.IRP_31]: {
        Statement: 'Develop, keep updated, and use an incident management system for processing and tracking incidents and their resolution.',
        Level: 3,
        Abbreviation: 'IRP 3.1',
        Id: MODEL_CONSTANTS.IRP_31,
        PracticeAreaID: MODEL_CONSTANTS.IRP
    },
    [MODEL_CONSTANTS.IRP_32]: {
        Statement: 'Analyze selected incident and resolution data for prevention of future incidents.',
        Level: 3,
        Abbreviation: 'IRP 3.2',
        Id: MODEL_CONSTANTS.IRP_32,
        PracticeAreaID: MODEL_CONSTANTS.IRP
    },
    [MODEL_CONSTANTS.MPM_11]: {
        Statement: 'Collect measures and record performance.',
        Level: 1,
        Abbreviation: 'MPM 1.1',
        Id: MODEL_CONSTANTS.MPM_11,
        PracticeAreaID: MODEL_CONSTANTS.MPM
    },
    [MODEL_CONSTANTS.MPM_12]: {
        Statement: 'Identify and address performance issues.',
        Level: 1,
        Abbreviation: 'MPM 1.2',
        Id: MODEL_CONSTANTS.MPM_12,
        PracticeAreaID: MODEL_CONSTANTS.MPM
    },
    [MODEL_CONSTANTS.MPM_21]: {
        Statement: 'Derive and record measurement and performance objectives from selected business needs and objectives and keep them updated.',
        Level: 2,
        Abbreviation: 'MPM 2.1',
        Id: MODEL_CONSTANTS.MPM_21,
        PracticeAreaID: MODEL_CONSTANTS.MPM
    },
    [MODEL_CONSTANTS.MPM_22]: {
        Statement: 'Develop, keep updated, and use operational definitions for measures.',
        Level: 2,
        Abbreviation: 'MPM 2.2',
        Id: MODEL_CONSTANTS.MPM_22,
        PracticeAreaID: MODEL_CONSTANTS.MPM
    },
    [MODEL_CONSTANTS.MPM_23]: {
        Statement: 'Obtain specified measurement data according to the operational definitions.',
        Level: 2,
        Abbreviation: 'MPM 2.3',
        Id: MODEL_CONSTANTS.MPM_23,
        PracticeAreaID: MODEL_CONSTANTS.MPM
    },
    [MODEL_CONSTANTS.MPM_24]: {
        Statement: 'Analyze performance and measurement data according to the operational definitions.',
        Level: 2,
        Abbreviation: 'MPM 2.4',
        Id: MODEL_CONSTANTS.MPM_24,
        PracticeAreaID: MODEL_CONSTANTS.MPM
    },
    [MODEL_CONSTANTS.MPM_25]: {
        Statement: 'Store measurement data, measurement specifications, and analysis results according to the operational definitions.',
        Level: 2,
        Abbreviation: 'MPM 2.5',
        Id: MODEL_CONSTANTS.MPM_25,
        PracticeAreaID: MODEL_CONSTANTS.MPM
    },
    [MODEL_CONSTANTS.MPM_26]: {
        Statement: 'Take actions to address identified issues with meeting measurement and performance objectives.',
        Level: 2,
        Abbreviation: 'MPM 2.6',
        Id: MODEL_CONSTANTS.MPM_26,
        PracticeAreaID: MODEL_CONSTANTS.MPM
    },
    [MODEL_CONSTANTS.MPM_31]: {
        Statement: 'Develop, keep updated, and use the organization\'s measurement and performance objectives traceable to business objectives.',
        Level: 3,
        Abbreviation: 'MPM 3.1',
        Id: MODEL_CONSTANTS.MPM_31,
        PracticeAreaID: MODEL_CONSTANTS.MPM
    },
    [MODEL_CONSTANTS.MPM_32]: {
        Statement: 'Follow organizational processes and standards to develop and use operational definitions for measures and keep them updated.',
        Level: 3,
        Abbreviation: 'MPM 3.2',
        Id: MODEL_CONSTANTS.MPM_32,
        PracticeAreaID: MODEL_CONSTANTS.MPM
    },
    [MODEL_CONSTANTS.MPM_33]: {
        Statement: 'Develop, keep updated, and follow a data quality process.',
        Level: 3,
        Abbreviation: 'MPM 3.3',
        Id: MODEL_CONSTANTS.MPM_33,
        PracticeAreaID: MODEL_CONSTANTS.MPM
    },
    [MODEL_CONSTANTS.MPM_34]: {
        Statement: 'Develop, keep updated, and use the organization’s measurement repository.',
        Level: 3,
        Abbreviation: 'MPM 3.4',
        Id: MODEL_CONSTANTS.MPM_34,
        PracticeAreaID: MODEL_CONSTANTS.MPM
    },
    [MODEL_CONSTANTS.MPM_35]: {
        Statement: 'Analyze organizational performance using measurement and performance data to determine and address performance improvement needs.',
        Level: 3,
        Abbreviation: 'MPM 3.5',
        Id: MODEL_CONSTANTS.MPM_35,
        PracticeAreaID: MODEL_CONSTANTS.MPM
    },
    [MODEL_CONSTANTS.MPM_36]: {
        Statement: 'Periodically communicate performance results to the organization.',
        Level: 3,
        Abbreviation: 'MPM 3.6',
        Id: MODEL_CONSTANTS.MPM_36,
        PracticeAreaID: MODEL_CONSTANTS.MPM
    },
    [MODEL_CONSTANTS.MPM_41]: {
        Statement: 'Use statistical and other quantitative techniques to develop, keep updated, and communicate quality and process performance objectives that are traceable to business objectives.',
        Level: 4,
        Abbreviation: 'MPM 4.1',
        Id: MODEL_CONSTANTS.MPM_41,
        PracticeAreaID: MODEL_CONSTANTS.MPM
    },
    [MODEL_CONSTANTS.MPM_42]: {
        Statement: 'Select measures and analytic techniques to quantitatively manage performance to achieve quality and process performance objectives.',
        Level: 4,
        Abbreviation: 'MPM 4.2',
        Id: MODEL_CONSTANTS.MPM_42,
        PracticeAreaID: MODEL_CONSTANTS.MPM
    },
    [MODEL_CONSTANTS.MPM_43]: {
        Statement: 'Use statistical and other quantitative techniques to develop and analyze process performance baselines and keep them updated.',
        Level: 4,
        Abbreviation: 'MPM 4.3',
        Id: MODEL_CONSTANTS.MPM_43,
        PracticeAreaID: MODEL_CONSTANTS.MPM
    },
    [MODEL_CONSTANTS.MPM_44]: {
        Statement: 'Use statistical and other quantitative techniques to develop and analyze process performance models and keep them updated.',
        Level: 4,
        Abbreviation: 'MPM 4.4',
        Id: MODEL_CONSTANTS.MPM_44,
        PracticeAreaID: MODEL_CONSTANTS.MPM
    },
    [MODEL_CONSTANTS.MPM_45]: {
        Statement: 'Use statistical and other quantitative techniques to determine or predict achievement of quality and process performance objectives.',
        Level: 4,
        Abbreviation: 'MPM 4.5',
        Id: MODEL_CONSTANTS.MPM_45,
        PracticeAreaID: MODEL_CONSTANTS.MPM
    },
    [MODEL_CONSTANTS.MPM_51]: {
        Statement: 'Use statistical and other quantitative techniques to ensure that business objectives are aligned with business strategy and performance.',
        Level: 5,
        Abbreviation: 'MPM 5.1',
        Id: MODEL_CONSTANTS.MPM_51,
        PracticeAreaID: MODEL_CONSTANTS.MPM
    },
    [MODEL_CONSTANTS.MPM_52]: {
        Statement: 'Analyze performance data using statistical and other quantitative techniques to determine the organization’s ability to satisfy selected business objectives and identify potential areas for performance improvement.',
        Level: 5,
        Abbreviation: 'MPM 5.2',
        Id: MODEL_CONSTANTS.MPM_52,
        PracticeAreaID: MODEL_CONSTANTS.MPM
    },
    [MODEL_CONSTANTS.MPM_53]: {
        Statement: 'Select and implement improvement proposals, based on the statistical and quantitative analysis of the expected effect of proposed improvements on meeting business, quality, and process performance objectives.',
        Level: 5,
        Abbreviation: 'MPM 5.3',
        Id: MODEL_CONSTANTS.MPM_53,
        PracticeAreaID: MODEL_CONSTANTS.MPM
    },
    [MODEL_CONSTANTS.MC_11]: {
        Statement: 'Record task completions.',
        Level: 1,
        Abbreviation: 'MC 1.1',
        Id: MODEL_CONSTANTS.MC_11,
        PracticeAreaID: MODEL_CONSTANTS.MC
    },
    [MODEL_CONSTANTS.MC_12]: {
        Statement: 'Identify and resolve issues.',
        Level: 1,
        Abbreviation: 'MC 1.2',
        Id: MODEL_CONSTANTS.MC_12,
        PracticeAreaID: MODEL_CONSTANTS.MC
    },
    [MODEL_CONSTANTS.MC_21]: {
        Statement: 'Track actual results against estimates for size, effort, schedule, resources, knowledge and skills, and budget.',
        Level: 2,
        Abbreviation: 'MC 2.1',
        Id: MODEL_CONSTANTS.MC_21,
        PracticeAreaID: MODEL_CONSTANTS.MC
    }, 
    [MODEL_CONSTANTS.MC_22]: {
        Statement: 'Track the involvement of identified stakeholders and commitments.',
        Level: 2,
        Abbreviation: 'MC 2.2',
        Id: MODEL_CONSTANTS.MC_22,
        PracticeAreaID: MODEL_CONSTANTS.MC
    },
    [MODEL_CONSTANTS.MC_23]: {
        Statement: 'Monitor the transition to operations and support.',
        Level: 2,
        Abbreviation: 'MC 2.3',
        Id: MODEL_CONSTANTS.MC_23,
        PracticeAreaID: MODEL_CONSTANTS.MC
    },
    [MODEL_CONSTANTS.MC_24]: {
        Statement: 'Take corrective actions when actual results differ significantly from planned results and manage to closure.',
        Level: 2,
        Abbreviation: 'MC 2.4',
        Id: MODEL_CONSTANTS.MC_24,
        PracticeAreaID: MODEL_CONSTANTS.MC
    },
    [MODEL_CONSTANTS.MC_31]: {
        Statement: 'Manage the project using the project plan and the project process.',
        Level: 3,
        Abbreviation: 'MC 3.1',
        Id: MODEL_CONSTANTS.MC_31,
        PracticeAreaID: MODEL_CONSTANTS.MC
    },
    [MODEL_CONSTANTS.MC_32]: {
        Statement: 'Manage critical dependencies and activities.',
        Level: 3,
        Abbreviation: 'MC 3.2',
        Id: MODEL_CONSTANTS.MC_32,
        PracticeAreaID: MODEL_CONSTANTS.MC
    },
    [MODEL_CONSTANTS.MC_33]: {
        Statement: 'Monitor the work environment to identify issues.',
        Level: 3,
        Abbreviation: 'MC 3.3',
        Id: MODEL_CONSTANTS.MC_33,
        PracticeAreaID: MODEL_CONSTANTS.MC
    },
    [MODEL_CONSTANTS.MC_34]: {
        Statement: 'Manage and resolve issues with affected stakeholders.',
        Level: 3,
        Abbreviation: 'MC 3.4',
        Id: MODEL_CONSTANTS.MC_34,
        PracticeAreaID: MODEL_CONSTANTS.MC
    },
    [MODEL_CONSTANTS.OT_11]: {
        Statement: 'Train people.',
        Level: 1,
        Abbreviation: 'OT 1.1',
        Id: MODEL_CONSTANTS.OT_11,
        PracticeAreaID: MODEL_CONSTANTS.OT
    },
    [MODEL_CONSTANTS.OT_21]: {
        Statement: 'Identify training needs.',
        Level: 2,
        Abbreviation: 'OT 2.1',
        Id: MODEL_CONSTANTS.OT_21,
        PracticeAreaID: MODEL_CONSTANTS.OT
    },
    [MODEL_CONSTANTS.OT_22]: {
        Statement: 'Train personnel and keep records.',
        Level: 2,
        Abbreviation: 'OT 2.2',
        Id: MODEL_CONSTANTS.OT_22,
        PracticeAreaID: MODEL_CONSTANTS.OT
    },
    [MODEL_CONSTANTS.OT_31]: {
        Statement: 'Develop and keep updated the organization’s strategic and short-term training needs.',
        Level: 3,
        Abbreviation: 'OT 3.1',
        Id: MODEL_CONSTANTS.OT_31,
        PracticeAreaID: MODEL_CONSTANTS.OT
    },
    [MODEL_CONSTANTS.OT_32]: {
        Statement: 'Coordinate training needs and delivery between the projects and the organization.',
        Level: 3,
        Abbreviation: 'OT 3.2',
        Id: MODEL_CONSTANTS.OT_32,
        PracticeAreaID: MODEL_CONSTANTS.OT
    },
    [MODEL_CONSTANTS.OT_33]: {
        Statement: 'Develop, keep updated, and follow organizational strategic and short-term training plans.',
        Level: 3,
        Abbreviation: 'OT 3.3',
        Id: MODEL_CONSTANTS.OT_33,
        PracticeAreaID: MODEL_CONSTANTS.OT
    },
    [MODEL_CONSTANTS.OT_34]: {
        Statement: 'Develop, keep updated, and use a training capability to address organizational training needs.',
        Level: 3,
        Abbreviation: 'OT 3.4',
        Id: MODEL_CONSTANTS.OT_34,
        PracticeAreaID: MODEL_CONSTANTS.OT
    },
    [MODEL_CONSTANTS.OT_35]: {
        Statement: 'Assess and report the effectiveness of the organization’s training program.',
        Level: 3,
        Abbreviation: 'OT 3.5',
        Id: MODEL_CONSTANTS.OT_35,
        PracticeAreaID: MODEL_CONSTANTS.OT
    },
    [MODEL_CONSTANTS.OT_36]: {
        Statement: 'Record, keep updated, and use the set of organizational training records.',
        Level: 3,
        Abbreviation: 'OT 3.6',
        Id: MODEL_CONSTANTS.OT_36,
        PracticeAreaID: MODEL_CONSTANTS.OT
    },
    [MODEL_CONSTANTS.PR_11]: {
        Statement: 'Perform reviews of work products and record issues.',
        Level: 1,
        Abbreviation: 'PR 1.1',
        Id: MODEL_CONSTANTS.PR_11,
        PracticeAreaID: MODEL_CONSTANTS.PR
    },
    [MODEL_CONSTANTS.PR_21]: {
        Statement: 'Develop and keep updated procedures and supporting materials used to prepare for and perform peer reviews.',
        Level: 2,
        Abbreviation: 'PR 2.1',
        Id: MODEL_CONSTANTS.PR_21,
        PracticeAreaID: MODEL_CONSTANTS.PR
    },
    [MODEL_CONSTANTS.PR_22]: {
        Statement: 'Select work products to be peer reviewed.',
        Level: 2,
        Abbreviation: 'PR 2.2',
        Id: MODEL_CONSTANTS.PR_22,
        PracticeAreaID: MODEL_CONSTANTS.PR
    },
    [MODEL_CONSTANTS.PR_23]: {
        Statement: 'Prepare and perform peer reviews on selected work products using established procedures.',
        Level: 2,
        Abbreviation: 'PR 2.3',
        Id: MODEL_CONSTANTS.PR_23,
        PracticeAreaID: MODEL_CONSTANTS.PR
    },
    [MODEL_CONSTANTS.PR_24]: {
        Statement: 'Resolve issues identified in peer reviews.',
        Level: 2,
        Abbreviation: 'PR 2.4',
        Id: MODEL_CONSTANTS.PR_24,
        PracticeAreaID: MODEL_CONSTANTS.PR
    },
    [MODEL_CONSTANTS.PR_31]: {
        Statement: 'Analyze results and data from peer reviews.',
        Level: 3,
        Abbreviation: 'PR 3.1',
        Id: MODEL_CONSTANTS.PR_31,
        PracticeAreaID: MODEL_CONSTANTS.PR
    },
    [MODEL_CONSTANTS.PLAN_11]: {
        Statement: 'Develop a list of tasks.',
        Level: 1,
        Abbreviation: 'PLAN 1.1',
        Id: MODEL_CONSTANTS.PLAN_11,
        PracticeAreaID: MODEL_CONSTANTS.PLAN
    },
    [MODEL_CONSTANTS.PLAN_12]: {
        Statement: 'Assign people to tasks.',
        Level: 1,
        Abbreviation: 'PLAN 1.2',
        Id: MODEL_CONSTANTS.PLAN_12,
        PracticeAreaID: MODEL_CONSTANTS.PLAN
    },
    [MODEL_CONSTANTS.PLAN_21]: {
        Statement: 'Develop and keep updated the approach for accomplishing the work.',
        Level: 2,
        Abbreviation: 'PLAN 2.1',
        Id: MODEL_CONSTANTS.PLAN_21,
        PracticeAreaID: MODEL_CONSTANTS.PLAN
    },
    [MODEL_CONSTANTS.PLAN_22]: {
        Statement: 'Plan for the knowledge and skills needed to perform the work.',
        Level: 2,
        Abbreviation: 'PLAN 2.2',
        Id: MODEL_CONSTANTS.PLAN_22,
        PracticeAreaID: MODEL_CONSTANTS.PLAN
    },
    [MODEL_CONSTANTS.PLAN_23]: {
        Statement: 'Based on recorded estimates, develop and keep the budget and schedule updated.',
        Level: 2,
        Abbreviation: 'PLAN 2.3',
        Id: MODEL_CONSTANTS.PLAN_23,
        PracticeAreaID: MODEL_CONSTANTS.PLAN
    },
    [MODEL_CONSTANTS.PLAN_24]: {
        Statement: 'Plan the involvement of identified stakeholders.',
        Level: 2,
        Abbreviation: 'PLAN 2.4',
        Id: MODEL_CONSTANTS.PLAN_24,
        PracticeAreaID: MODEL_CONSTANTS.PLAN
    },
    [MODEL_CONSTANTS.PLAN_25]: {
        Statement: 'Plan transition to operations and support.',
        Level: 2,
        Abbreviation: 'PLAN 2.5',
        Id: MODEL_CONSTANTS.PLAN_25,
        PracticeAreaID: MODEL_CONSTANTS.PLAN
    },
    [MODEL_CONSTANTS.PLAN_26]: {
        Statement: 'Ensure plans are feasible by reconciling estimates against capacity and availability of resources.',
        Level: 2,
        Abbreviation: 'PLAN 2.6',
        Id: MODEL_CONSTANTS.PLAN_26,
        PracticeAreaID: MODEL_CONSTANTS.PLAN
    },
    [MODEL_CONSTANTS.PLAN_27]: {
        Statement: 'Develop the project plan, ensure consistency among its elements, and keep it updated.',
        Level: 2,
        Abbreviation: 'PLAN 2.7',
        Id: MODEL_CONSTANTS.PLAN_27,
        PracticeAreaID: MODEL_CONSTANTS.PLAN
    },
    [MODEL_CONSTANTS.PLAN_28]: {
        Statement: 'Review plans and obtain commitments from affected stakeholders.',
        Level: 2,
        Abbreviation: 'PLAN 2.8',
        Id: MODEL_CONSTANTS.PLAN_28,
        PracticeAreaID: MODEL_CONSTANTS.PLAN
    },
    [MODEL_CONSTANTS.PLAN_31]: {
        Statement: 'Use the organization’s set of standard processes and tailoring guidelines to develop, keep updated, and follow the project process.',
        Level: 3,
        Abbreviation: 'PLAN 3.1',
        Id: MODEL_CONSTANTS.PLAN_31,
        PracticeAreaID: MODEL_CONSTANTS.PLAN
    },
    [MODEL_CONSTANTS.PLAN_32]: {
        Statement: 'Develop a plan and keep it updated using the project process, the organization’s process assets, and the measurement repository.',
        Level: 3,
        Abbreviation: 'PLAN 3.2',
        Id: MODEL_CONSTANTS.PLAN_32,
        PracticeAreaID: MODEL_CONSTANTS.PLAN
    },
    [MODEL_CONSTANTS.PLAN_33]: {
        Statement: 'Identify and negotiate critical dependencies.',
        Level: 3,
        Abbreviation: 'PLAN 3.3',
        Id: MODEL_CONSTANTS.PLAN_33,
        PracticeAreaID: MODEL_CONSTANTS.PLAN
    },
    [MODEL_CONSTANTS.PLAN_34]: {
        Statement: 'Plan for the project environment and keep it updated based on the organization’s standards.',
        Level: 3,
        Abbreviation: 'PLAN 3.4',
        Id: MODEL_CONSTANTS.PLAN_34,
        PracticeAreaID: MODEL_CONSTANTS.PLAN
    },
    [MODEL_CONSTANTS.PLAN_41]: {
        Statement: 'Use statistical and other quantitative techniques to develop and keep the project processes updated to enable achievement of the quality and process performance objectives.',
        Level: 4,
        Abbreviation: 'PLAN 4.1',
        Id: MODEL_CONSTANTS.PLAN_41,
        PracticeAreaID: MODEL_CONSTANTS.PLAN
    },
    [MODEL_CONSTANTS.PAD_11]: {
        Statement: 'Develop process assets to perform the work.',
        Level: 1,
        Abbreviation: 'PAD 1.1',
        Id: MODEL_CONSTANTS.PAD_11,
        PracticeAreaID: MODEL_CONSTANTS.PAD     
    },
    [MODEL_CONSTANTS.PAD_21]: {
        Statement: 'Determine what process assets will be needed to perform the work.',
        Level: 2,
        Abbreviation: 'PAD 2.1',
        Id: MODEL_CONSTANTS.PAD_21,
        PracticeAreaID: MODEL_CONSTANTS.PAD  
    },
    [MODEL_CONSTANTS.PAD_22]: {
        Statement: 'Develop, buy, or reuse process assets.',
        Level: 2,
        Abbreviation: 'PAD 2.2',
        Id: MODEL_CONSTANTS.PAD_22,
        PracticeAreaID: MODEL_CONSTANTS.PAD  
    },
    [MODEL_CONSTANTS.PAD_23]: {
        Statement: 'Make processes and assets available.',
        Level: 2,
        Abbreviation: 'PAD 2.3',
        Id: MODEL_CONSTANTS.PAD_23,
        PracticeAreaID: MODEL_CONSTANTS.PAD  
    },
    [MODEL_CONSTANTS.PAD_31]: {
        Statement: 'Develop, keep updated, and follow a strategy for building and updating process assets.',
        Level: 3,
        Abbreviation: 'PAD 3.1',
        Id: MODEL_CONSTANTS.PAD_31,
        PracticeAreaID: MODEL_CONSTANTS.PAD  
    },
    [MODEL_CONSTANTS.PAD_32]: {
        Statement: 'Develop, record, and keep updated a process architecture that describes the structure of the organization’s processes and process assets.',
        Level: 3,
        Abbreviation: 'PAD 3.2',
        Id: MODEL_CONSTANTS.PAD_32,
        PracticeAreaID: MODEL_CONSTANTS.PAD  
    },
    [MODEL_CONSTANTS.PAD_33]: {
        Statement: 'Develop, keep updated, and make the organization’s processes and assets available for use in a process asset library.',
        Level: 3,
        Abbreviation: 'PAD 3.3',
        Id: MODEL_CONSTANTS.PAD_33,
        PracticeAreaID: MODEL_CONSTANTS.PAD  
    },
    [MODEL_CONSTANTS.PAD_34]: {
        Statement: 'Develop, keep updated, and make tailoring criteria and guidelines for the set of standard processes available for use.',
        Level: 3,
        Abbreviation: 'PAD 3.4',
        Id: MODEL_CONSTANTS.PAD_34,
        PracticeAreaID: MODEL_CONSTANTS.PAD  
    },
    [MODEL_CONSTANTS.PAD_35]: {
        Statement: 'Develop, keep updated, and make work environment standards available for use.',
        Level: 3,
        Abbreviation: 'PAD 3.5',
        Id: MODEL_CONSTANTS.PAD_35,
        PracticeAreaID: MODEL_CONSTANTS.PAD  
    },
    [MODEL_CONSTANTS.PAD_36]: {
        Statement: 'Develop, keep updated, and make organizational measurement and analysis standards available for use.',
        Level: 3,
        Abbreviation: 'PAD 3.6',
        Id: MODEL_CONSTANTS.PAD_36,
        PracticeAreaID: MODEL_CONSTANTS.PAD  
    },
    [MODEL_CONSTANTS.PCM_11]: {
        Statement: 'Develop a support structure to provide process guidance, identify and fix process problems, and continuously improve processes.',
        Level: 1,
        Abbreviation: 'PCM 1.1',
        Id: MODEL_CONSTANTS.PCM_11,
        PracticeAreaID: MODEL_CONSTANTS.PCM     
    },
    [MODEL_CONSTANTS.PCM_12]: {
        Statement: 'Appraise the current process implementation and identify strengths and weaknesses.',
        Level: 1,
        Abbreviation: 'PCM 1.2',
        Id: MODEL_CONSTANTS.PCM_12,
        PracticeAreaID: MODEL_CONSTANTS.PCM
    },
    [MODEL_CONSTANTS.PCM_13]: {
        Statement: 'Address improvement opportunities or process issues.',
        Level: 1,
        Abbreviation: 'PCM 1.3',
        Id: MODEL_CONSTANTS.PCM_13,
        PracticeAreaID: MODEL_CONSTANTS.PCM
    },
    [MODEL_CONSTANTS.PCM_21]: {
        Statement: 'Identify improvements to the processes and process assets.',
        Level: 2,
        Abbreviation: 'PCM 2.1',
        Id: MODEL_CONSTANTS.PCM_21,
        PracticeAreaID: MODEL_CONSTANTS.PCM
    },
    [MODEL_CONSTANTS.PCM_22]: {
        Statement: 'Develop, keep updated, and follow plans for implementing selected process improvements',
        Level: 2,
        Abbreviation: 'PCM 2.2',
        Id: MODEL_CONSTANTS.PCM_22,
        PracticeAreaID: MODEL_CONSTANTS.PCM
    },
    [MODEL_CONSTANTS.PCM_31]: {
        Statement: 'Develop, keep updated, and use process improvement objectives traceable to the business objectives.',
        Level: 3,
        Abbreviation: 'PCM 3.1',
        Id: MODEL_CONSTANTS.PCM_31,
        PracticeAreaID: MODEL_CONSTANTS.PCM
    },
    [MODEL_CONSTANTS.PCM_32]: {
        Statement: 'Identify processes that are the largest contributors to meeting business objectives.',
        Level: 3,
        Abbreviation: 'PCM 3.2',
        Id: MODEL_CONSTANTS.PCM_32,
        PracticeAreaID: MODEL_CONSTANTS.PCM
    },
    [MODEL_CONSTANTS.PCM_33]: {
        Statement: 'Explore and evaluate potential new processes, techniques, methods, and tools to identify improvement opportunities.',
        Level: 3,
        Abbreviation: 'PCM 3.3',
        Id: MODEL_CONSTANTS.PCM_33,
        PracticeAreaID: MODEL_CONSTANTS.PCM
    },
    [MODEL_CONSTANTS.PCM_34]: {
        Statement: 'Provide support for implementing, deploying, and sustaining process improvements.',
        Level: 3,
        Abbreviation: 'PCM 3.4',
        Id: MODEL_CONSTANTS.PCM_34,
        PracticeAreaID: MODEL_CONSTANTS.PCM
    },
    [MODEL_CONSTANTS.PCM_35]: {
        Statement: 'Deploy organizational standard processes and process assets.',
        Level: 3,
        Abbreviation: 'PCM 3.5',
        Id: MODEL_CONSTANTS.PCM_35,
        PracticeAreaID: MODEL_CONSTANTS.PCM
    },
    [MODEL_CONSTANTS.PCM_36]: {
        Statement: 'Evaluate and report the effectiveness of deployed improvements in achieving process improvement objectives.',
        Level: 3,
        Abbreviation: 'PCM 3.6',
        Id: MODEL_CONSTANTS.PCM_36,
        PracticeAreaID: MODEL_CONSTANTS.PCM
    },
    [MODEL_CONSTANTS.PCM_41]: {
        Statement: 'Use statistical and other quantitative techniques to validate selected performance improvements against proposed improvement expectations, business objectives, or quality and process performance objectives.',
        Level: 4,
        Abbreviation: 'PCM 4.1',
        Id: MODEL_CONSTANTS.PCM_41,
        PracticeAreaID: MODEL_CONSTANTS.PCM
    },
    [MODEL_CONSTANTS.PQA_11]: {
        Statement: 'Identify and address process and work product issues.',
        Level: 1,
        Abbreviation: 'PQA 1.1',
        Id: MODEL_CONSTANTS.PQA_11,
        PracticeAreaID: MODEL_CONSTANTS.PQA
    },
    [MODEL_CONSTANTS.PQA_21]: {
        Statement: 'Develop, keep updated, and follow a quality assurance approach and plan based on historical quality data.',
        Level: 2,
        Abbreviation: 'PQA 2.1',
        Id: MODEL_CONSTANTS.PQA_21,
        PracticeAreaID: MODEL_CONSTANTS.PQA
    },
    [MODEL_CONSTANTS.PQA_22]: {
        Statement: 'Objectively evaluate selected performed processes and work products against the recorded process and applicable standards.',
        Level: 2,
        Abbreviation: 'PQA 2.2',
        Id: MODEL_CONSTANTS.PQA_22,
        PracticeAreaID:MODEL_CONSTANTS.PQA
    },
    [MODEL_CONSTANTS.PQA_23]: {
        Statement: 'Communicate quality and non-compliance issues and ensure their resolution.',
        Level: 2,
        Abbreviation: 'PQA 2.3',
        Id: MODEL_CONSTANTS.PQA_23,
        PracticeAreaID: MODEL_CONSTANTS.PQA
    },
    [MODEL_CONSTANTS.PQA_24]: {
        Statement: 'Record and use results of quality assurance activities.',
        Level: 2,
        Abbreviation: 'PQA 2.4',
        Id: MODEL_CONSTANTS.PQA_24,
        PracticeAreaID: MODEL_CONSTANTS.PQA
    },
    [MODEL_CONSTANTS.PQA_31]: {
        Statement: 'Identify and record opportunities for improvement during quality assurance activities.',
        Level: 3,
        Abbreviation: 'PQA 3.1',
        Id: MODEL_CONSTANTS.PQA_31,
        PracticeAreaID: MODEL_CONSTANTS.PQA
    },
    [MODEL_CONSTANTS.PI_11]: {
        Statement: 'Assemble solutions and deliver to the customer.',
        Level: 1,
        Abbreviation: 'PI 1.1',
        Id: MODEL_CONSTANTS.PI_11,
        PracticeAreaID: MODEL_CONSTANTS.PI
    },
    [MODEL_CONSTANTS.PI_21]: {
        Statement: 'Develop, keep updated, and follow an integration strategy.',
        Level: 2,
        Abbreviation: 'PI 2.1',
        Id: MODEL_CONSTANTS.PI_21,
        PracticeAreaID: MODEL_CONSTANTS.PI
    },
    [MODEL_CONSTANTS.PI_22]: {
        Statement: 'Develop, keep updated, and use the integration environment.',
        Level: 2,
        Abbreviation: 'PI 2.2',
        Id: MODEL_CONSTANTS.PI_22,
        PracticeAreaID: MODEL_CONSTANTS.PI
    },
    [MODEL_CONSTANTS.PI_23]: {
        Statement: 'Develop, keep updated, and follow procedures and criteria for integrating solutions and components.',
        Level: 2,
        Abbreviation: 'PI 2.3',
        Id: MODEL_CONSTANTS.PI_23,
        PracticeAreaID: MODEL_CONSTANTS.PI
    },
    [MODEL_CONSTANTS.PI_24]: {
        Statement: 'Confirm, prior to integration, that each component has been properly identified and operates according to its requirements and design.',
        Level: 2,
        Abbreviation: 'PI 2.4',
        Id: MODEL_CONSTANTS.PI_24,
        PracticeAreaID: MODEL_CONSTANTS.PI
    },
    [MODEL_CONSTANTS.PI_25]: {
        Statement: 'Evaluate integrated components to ensure conformance to the solution’s requirements and design.',
        Level: 2,
        Abbreviation: 'PI 2.5',
        Id: MODEL_CONSTANTS.PI_25,
        PracticeAreaID: MODEL_CONSTANTS.PI
    },
    [MODEL_CONSTANTS.PI_26]: {
        Statement: 'Integrate solutions and components according to the integration strategy.',
        Level: 2,
        Abbreviation: 'PI 2.6',
        Id: MODEL_CONSTANTS.PI_26,
        PracticeAreaID: MODEL_CONSTANTS.PI
    },
    [MODEL_CONSTANTS.PI_31]: {
        Statement: 'Review and keep updated interface or connection descriptions for coverage, completeness, and consistency throughout the solution’s life.',
        Level: 3,
        Abbreviation: 'PI 3.1',
        Id: MODEL_CONSTANTS.PI_31,
        PracticeAreaID: MODEL_CONSTANTS.PI
    },
    [MODEL_CONSTANTS.PI_32]: {
        Statement: 'Confirm, prior to integration, that component interfaces or connections comply with interface or connection descriptions.',
        Level: 3,
        Abbreviation: 'PI 3.2',
        Id: MODEL_CONSTANTS.PI_32,
        PracticeAreaID: MODEL_CONSTANTS.PI
    },
    [MODEL_CONSTANTS.PI_33]: {
        Statement: 'Evaluate integrated components for interface or connection compatibility.',
        Level: 3,
        Abbreviation: 'PI 3.3',
        Id: MODEL_CONSTANTS.PI_33,
        PracticeAreaID: MODEL_CONSTANTS.PI
    },
    [MODEL_CONSTANTS.RDM_11]: {
        Statement: 'Record requirements.',
        Level: 1,
        Abbreviation: 'RDM 1.1',
        Id: MODEL_CONSTANTS.RDM_11,
        PracticeAreaID: MODEL_CONSTANTS.RDM
    },
    [MODEL_CONSTANTS.RDM_21]: {
        Statement: 'Elicit stakeholder needs, expectations, constraints, and interfaces or connections and confirm understanding of the requirements.',
        Level: 2,
        Abbreviation: 'RDM 2.1',
        Id: MODEL_CONSTANTS.RDM_21,
        PracticeAreaID: MODEL_CONSTANTS.RDM
    },
    [MODEL_CONSTANTS.RDM_22]: {
        Statement: 'Transform stakeholder needs, expectations, constraints, and interfaces or connections into prioritized customer requirements.',
        Level: 2,
        Abbreviation: 'RDM 2.2',
        Id: MODEL_CONSTANTS.RDM_22,
        PracticeAreaID: MODEL_CONSTANTS.RDM
    },
    [MODEL_CONSTANTS.RDM_23]: {
        Statement: 'Obtain commitment from project participants that they can implement the requirements.',
        Level: 2,
        Abbreviation: 'RDM 2.3',
        Id: MODEL_CONSTANTS.RDM_23,
        PracticeAreaID: MODEL_CONSTANTS.RDM
    },
    [MODEL_CONSTANTS.RDM_24]: {
        Statement: 'Develop, record, and maintain bidirectional traceability among requirements and activities or work products.',
        Level: 2,
        Abbreviation: 'RDM 2.4',
        Id: MODEL_CONSTANTS.RDM_24,
        PracticeAreaID: MODEL_CONSTANTS.RDM
    },
    [MODEL_CONSTANTS.RDM_25]: {
        Statement: 'Ensure that plans and activities or work products remain consistent with requirements.',
        Level: 2,
        Abbreviation: 'RDM 2.5',
        Id: MODEL_CONSTANTS.RDM_25,
        PracticeAreaID: MODEL_CONSTANTS.RDM
    },
    [MODEL_CONSTANTS.RDM_31]: {
        Statement: 'Develop and keep requirements updated for the solution and its components.',
        Level: 3,
        Abbreviation: 'RDM 3.1',
        Id: MODEL_CONSTANTS.RDM_31,
        PracticeAreaID: MODEL_CONSTANTS.RDM
    },
    [MODEL_CONSTANTS.RDM_32]: {
        Statement: 'Develop operational concepts and scenarios.',
        Level: 3,
        Abbreviation: 'RDM 3.2',
        Id: MODEL_CONSTANTS.RDM_32,
        PracticeAreaID: MODEL_CONSTANTS.RDM
    },
    [MODEL_CONSTANTS.RDM_33]: {
        Statement: 'Allocate the requirements to be implemented.',
        Level: 3,
        Abbreviation: 'RDM 3.3',
        Id: MODEL_CONSTANTS.RDM_33,
        PracticeAreaID: MODEL_CONSTANTS.RDM
    },
    [MODEL_CONSTANTS.RDM_34]: {
        Statement: 'Identify, develop, and keep updated interface or connection requirements.',
        Level: 3,
        Abbreviation: 'RDM 3.4',
        Id: MODEL_CONSTANTS.RDM_34,
        PracticeAreaID: MODEL_CONSTANTS.RDM
    },
    [MODEL_CONSTANTS.RDM_35]: {
        Statement: 'Ensure that requirements are necessary and sufficient.',
        Level: 3,
        Abbreviation: 'RDM 3.5',
        Id: MODEL_CONSTANTS.RDM_35,
        PracticeAreaID: MODEL_CONSTANTS.RDM
    },
    [MODEL_CONSTANTS.RDM_36]: {
        Statement: 'Balance stakeholder needs and constraints.',
        Level: 3,
        Abbreviation: 'RDM 3.6',
        Id: MODEL_CONSTANTS.RDM_36,
        PracticeAreaID: MODEL_CONSTANTS.RDM
    },
    [MODEL_CONSTANTS.RDM_37]: {
        Statement: 'Validate requirements to ensure the resulting solution will perform as intended in the target environment.',
        Level: 3,
        Abbreviation: 'RDM 3.7',
        Id: MODEL_CONSTANTS.RDM_37,
        PracticeAreaID: MODEL_CONSTANTS.RDM
    },
    [MODEL_CONSTANTS.RSK_11]:{
        Statement: 'Identify and record risks or opportunities and keep them updated.',
        Level: 1,
        Abbreviation: 'RSK 1.1',
        Id: MODEL_CONSTANTS.RSK_11,
        PracticeAreaID: MODEL_CONSTANTS.RSK
    },
    [MODEL_CONSTANTS.RSK_21]: {
        Statement: 'Analyze identified risks or opportunities.',
        Level: 2,
        Abbreviation: 'RSK 2.1',
        Id: MODEL_CONSTANTS.RSK_21,
        PracticeAreaID: MODEL_CONSTANTS.RSK
    },
    [MODEL_CONSTANTS.RSK_22]:{
        Statement: 'Monitor identified risks or opportunities and communicate status to affected stakeholders.',
        Level: 2,
        Abbreviation: 'RSK 2.2',
        Id: MODEL_CONSTANTS.RSK_22,
        PracticeAreaID: MODEL_CONSTANTS.RSK
    },
    [MODEL_CONSTANTS.RSK_31]:{
        Statement: 'Identify and use risk or opportunity categories.',
        Level: 3,
        Abbreviation: 'RSK 3.1',
        Id: MODEL_CONSTANTS.RSK_31,
        PracticeAreaID: MODEL_CONSTANTS.RSK
    },
    [MODEL_CONSTANTS.RSK_32]:{
        Statement: 'Define and use parameters for risk or opportunity analysis and handling.',
        Level: 3,
        Abbreviation: 'RSK 3.2',
        Id: MODEL_CONSTANTS.RSK_32,
        PracticeAreaID: MODEL_CONSTANTS.RSK
    },
    [MODEL_CONSTANTS.RSK_33]:{
        Statement: 'Develop and keep updated a risk or opportunity management strategy.',
        Level: 3,
        Abbreviation: 'RSK 3.3',
        Id: MODEL_CONSTANTS.RSK_33,
        PracticeAreaID: MODEL_CONSTANTS.RSK
    },
    [MODEL_CONSTANTS.RSK_34]:{
        Statement: 'Develop and keep updated risk or opportunity management plans.',
        Level: 3,
        Abbreviation: 'RSK 3.4',
        Id: MODEL_CONSTANTS.RSK_34,
        PracticeAreaID: MODEL_CONSTANTS.RSK
    },
    [MODEL_CONSTANTS.RSK_35]:{
        Statement: 'Manage risks or opportunities by implementing planned risk or opportunity management activities.',
        Level: 3,
        Abbreviation: 'RSK 3.5',
        Id: MODEL_CONSTANTS.RSK_35,
        PracticeAreaID: MODEL_CONSTANTS.RSK
    },
    [MODEL_CONSTANTS.SDM_11]: {
        Statement: 'Use the service system to deliver services.',
        Level: 1,
        Abbreviation: 'SDM 1.1',
        Id: MODEL_CONSTANTS.SDM_11,
        PracticeAreaID: MODEL_CONSTANTS.SDM
    },
    [MODEL_CONSTANTS.SDM_21]: {
        Statement: 'Develop, record, keep updated, and follow service agreements.',
        Level: 2,
        Abbreviation: 'SDM 2.1',
        Id: MODEL_CONSTANTS.SDM_21,
        PracticeAreaID: MODEL_CONSTANTS.SDM
    },
    [MODEL_CONSTANTS.SDM_22]: {
        Statement: 'Receive and process service requests in accordance with service agreements.',
        Level: 2,
        Abbreviation: 'SDM 2.2',
        Id: MODEL_CONSTANTS.SDM_22,
        PracticeAreaID: MODEL_CONSTANTS.SDM
    },
    [MODEL_CONSTANTS.SDM_23]:{
        Statement: 'Deliver services in accordance with service agreements.',
        Level: 2,
        Abbreviation: 'SDM 2.3',
        Id: MODEL_CONSTANTS.SDM_23,
        PracticeAreaID: MODEL_CONSTANTS.SDM
    },
    [MODEL_CONSTANTS.SDM_24]:{
        Statement: 'Analyze existing service agreements and service data to prepare for updated or new agreements.',
        Level: 2,
        Abbreviation: 'SDM 2.4',
        Id: MODEL_CONSTANTS.SDM_24,
        PracticeAreaID: MODEL_CONSTANTS.SDM
    },
    [MODEL_CONSTANTS.SDM_25]:{
        Statement: 'Develop, record, keep updated, and follow the approach for operating and changing the service system.',
        Level: 2,
        Abbreviation: 'SDM 2.5',
        Id: MODEL_CONSTANTS.SDM_25,
        PracticeAreaID: MODEL_CONSTANTS.SDM
    },
    [MODEL_CONSTANTS.SDM_26]:{
        Statement: 'Confirm the readiness of the service system to support the delivery of services.',
        Level: 2,
        Abbreviation: 'SDM 2.6',
        Id: MODEL_CONSTANTS.SDM_26,
        PracticeAreaID: MODEL_CONSTANTS.SDM
    },
    [MODEL_CONSTANTS.SDM_31]: {
        Statement: 'Develop, record, keep updated, and use organizational standard service systems and agreements.',
        Level: 3,
        Abbreviation: 'SDM 3.1',
        Id: MODEL_CONSTANTS.SDM_31,
        PracticeAreaID: MODEL_CONSTANTS.SDM
    },
    [MODEL_CONSTANTS.STSM_11]: {
        Statement: 'Develop a list of current services.',
        Level: 1,
        Abbreviation: 'STSM 1.1',
        Id: MODEL_CONSTANTS.STSM_11,
        PracticeAreaID: MODEL_CONSTANTS.STSM
    },
    [MODEL_CONSTANTS.STSM_21]: {
        Statement: 'Develop, keep updated, and use descriptions of current services.',
        Level: 2,
        Abbreviation: 'STSM 2.1',
        Id: MODEL_CONSTANTS.STSM_21,
        PracticeAreaID: MODEL_CONSTANTS.STSM
    },
    [MODEL_CONSTANTS.STSM_22]: {
        Statement: 'Collect, record, and analyze data about strategic needs and capabilities for service delivery.',
        Level: 2,
        Abbreviation: 'STSM 2.2',
        Id: MODEL_CONSTANTS.STSM_22,
        PracticeAreaID: MODEL_CONSTANTS.STSM
    },
    [MODEL_CONSTANTS.STSM_23]: {
        Statement: 'Develop, keep updated, and follow an approach for providing new or changed services derived from strategic needs and capabilities.',
        Level: 2,
        Abbreviation: 'STSM 2.3',
        Id: MODEL_CONSTANTS.STSM_23,
        PracticeAreaID: MODEL_CONSTANTS.STSM
    },
    [MODEL_CONSTANTS.STSM_31]: {
        Statement: 'Develop, keep updated, and use the set of organizational standard service descriptions and service levels.',
        Level: 3,
        Abbreviation: 'STSM 3.1',
        Id: MODEL_CONSTANTS.STSM_31,
        PracticeAreaID: MODEL_CONSTANTS.STSM
    },
    [MODEL_CONSTANTS.SAM_11]: {
        Statement: 'Identify, evaluate, and select suppliers.',
        Level: 1,
        Abbreviation: 'SAM 1.1',
        Id: MODEL_CONSTANTS.SAM_11,
        PracticeAreaID: MODEL_CONSTANTS.SAM
    },
    [MODEL_CONSTANTS.SAM_12]: {
        Statement: 'Develop and record the supplier agreement.',
        Level: 1,
        Abbreviation: 'SAM 1.2',
        Id: MODEL_CONSTANTS.SAM_12,
        PracticeAreaID: MODEL_CONSTANTS.SAM
    },
    [MODEL_CONSTANTS.SAM_13]: {
        Statement: 'Accept or reject the supplier deliverables.',
        Level: 1,
        Abbreviation: 'SAM 1.3',
        Id: MODEL_CONSTANTS.SAM_13,
        PracticeAreaID: MODEL_CONSTANTS.SAM
    },
    [MODEL_CONSTANTS.SAM_14]: {
        Statement: 'Process supplier invoices.',
        Level: 1,
        Abbreviation: 'SAM 1.4',
        Id: MODEL_CONSTANTS.SAM_14,
        PracticeAreaID: MODEL_CONSTANTS.SAM
    },
    [MODEL_CONSTANTS.SAM_21]: {
        Statement: 'Identify evaluation criteria, potential suppliers, and distribute supplier requests.',
        Level: 2,
        Abbreviation: 'SAM 2.1',
        Id: MODEL_CONSTANTS.SAM_21,
        PracticeAreaID: MODEL_CONSTANTS.SAM
    },
    [MODEL_CONSTANTS.SAM_22]: {
        Statement: 'Evaluate supplier responses according to recorded evaluation criteria and select suppliers.',
        Level: 2,
        Abbreviation: 'SAM 2.2',
        Id: MODEL_CONSTANTS.SAM_22,
        PracticeAreaID: MODEL_CONSTANTS.SAM
    },
    [MODEL_CONSTANTS.SAM_23]: {
        Statement: 'Manage supplier activities as specified in the supplier agreement and keep agreement updated.',
        Level: 2,
        Abbreviation: 'SAM 2.3',
        Id: MODEL_CONSTANTS.SAM_23,
        PracticeAreaID: MODEL_CONSTANTS.SAM
    },
    [MODEL_CONSTANTS.SAM_24]: {
        Statement: 'Verify that the supplier agreement is satisfied before accepting the acquired supplier deliverable.',
        Level: 2,
        Abbreviation: 'SAM 2.4',
        Id: MODEL_CONSTANTS.SAM_24,
        PracticeAreaID: MODEL_CONSTANTS.SAM
    }, 
    [MODEL_CONSTANTS.SAM_25]: {
        Statement: 'Manage invoices submitted by the supplier according to the supplier agreements.',
        Level: 2,
        Abbreviation: 'SAM 2.5',
        Id: MODEL_CONSTANTS.SAM_25,
        PracticeAreaID: MODEL_CONSTANTS.SAM
    }, 
    [MODEL_CONSTANTS.SAM_31]: {
        Statement: 'Conduct technical reviews of supplier performance activities and selected deliverables.',
        Level: 3,
        Abbreviation: 'SAM 3.1',
        Id: MODEL_CONSTANTS.SAM_31,
        PracticeAreaID: MODEL_CONSTANTS.SAM
    },
    [MODEL_CONSTANTS.SAM_32]: {
        Statement: 'Manage supplier performance and processes based on criteria in the supplier agreement.',
        Level: 3,
        Abbreviation: 'SAM 3.2',
        Id: MODEL_CONSTANTS.SAM_32,
        PracticeAreaID: MODEL_CONSTANTS.SAM
    },
    [MODEL_CONSTANTS.SAM_41]: {
        Statement: 'Select measures and apply analytical techniques to quantitatively manage supplier performance to achieve quality and process performance objectives.',
        Level: 4,
        Abbreviation: 'SAM 4.1',
        Id: MODEL_CONSTANTS.SAM_41,
        PracticeAreaID: MODEL_CONSTANTS.SAM
    },
    [MODEL_CONSTANTS.SSS_11]: {
        Statement: 'Determine the type of acquisition.',
        Level: 1,
        Abbreviation: 'SSS 1.1',
        Id: MODEL_CONSTANTS.SSS_11,
        PracticeAreaID: MODEL_CONSTANTS.SSS
    },
    [MODEL_CONSTANTS.SSS_12]: {
        Statement: 'Identify potential suppliers and distribute requests for proposals.',
        Level: 1,
        Abbreviation: 'SSS 1.2',
        Id: MODEL_CONSTANTS.SSS_12,
        PracticeAreaID: MODEL_CONSTANTS.SSS
    },
    [MODEL_CONSTANTS.SSS_13]: {
        Statement: 'Evaluate proposals and select suppliers.',
        Level: 1,
        Abbreviation: 'SSS 1.3',
        Id: MODEL_CONSTANTS.SSS_13,
        PracticeAreaID: MODEL_CONSTANTS.SSS
    },
    [MODEL_CONSTANTS.SSS_21]: {
        Statement: 'Develop a solicitation package and keep it updated',
        Level: 2,
        Abbreviation: 'SSS 2.1',
        Id: MODEL_CONSTANTS.SSS_21,
        PracticeAreaID: MODEL_CONSTANTS.SSS
    },
    [MODEL_CONSTANTS.SSS_22]: {
        Statement: 'Identify qualified potential suppliers and distribute the solicitation package for their response.',
        Level: 2,
        Abbreviation: 'SSS 2.2',
        Id: MODEL_CONSTANTS.SSS_22,
        PracticeAreaID: MODEL_CONSTANTS.SSS
    },
    [MODEL_CONSTANTS.SSS_23]: {
        Statement: 'Evaluate proposed solutions according to recorded evaluation criteria and select suppliers.',
        Level: 2,
        Abbreviation: 'SSS 2.3',
        Id: MODEL_CONSTANTS.SSS_23,
        PracticeAreaID: MODEL_CONSTANTS.SSS
    }, 
    [MODEL_CONSTANTS.SSS_31]: {
        Statement: 'Develop, keep updated, and follow negotiation approaches for soliciting, evaluating, and selecting suppliers.',
        Level: 3,
        Abbreviation: 'SSS 3.1',
        Id: MODEL_CONSTANTS.SSS_31,
        PracticeAreaID: MODEL_CONSTANTS.SSS
    },
    [MODEL_CONSTANTS.TS_11]: {
        Statement: 'Build a solution to meet requirements.',
        Level: 1,
        Abbreviation: 'TS 1.1',
        Id: MODEL_CONSTANTS.TS_11,
        PracticeAreaID: MODEL_CONSTANTS.TS
    },
    [MODEL_CONSTANTS.TS_21]: {
        Statement: 'Design and build a solution to meet requirements.',
        Level: 2,
        Abbreviation: 'TS 2.1',
        Id: MODEL_CONSTANTS.TS_21,
        PracticeAreaID: MODEL_CONSTANTS.TS
    },
    [MODEL_CONSTANTS.TS_22]: {
        Statement: 'Evaluate the design and address identified issues.',
        Level: 2,
        Abbreviation: 'TS 2.2',
        Id: MODEL_CONSTANTS.TS_22,
        PracticeAreaID: MODEL_CONSTANTS.TS
    },
    [MODEL_CONSTANTS.TS_23]: {
        Statement: 'Provide guidance on use of the solution.',
        Level: 2,
        Abbreviation: 'TS 2.3',
        Id: MODEL_CONSTANTS.TS_23,
        PracticeAreaID: MODEL_CONSTANTS.TS
    },
    [MODEL_CONSTANTS.TS_31]: {
        Statement: 'Develop criteria for design decisions.',
        Level: 3,
        Abbreviation: 'TS 3.1',
        Id: MODEL_CONSTANTS.TS_31,
        PracticeAreaID: MODEL_CONSTANTS.TS
    },
    [MODEL_CONSTANTS.TS_32]: {
        Statement: 'Develop alternative solutions for selected components.',
        Level: 3,
        Abbreviation: 'TS 3.2',
        Id: MODEL_CONSTANTS.TS_32,
        PracticeAreaID: MODEL_CONSTANTS.TS
    },
    [MODEL_CONSTANTS.TS_33]: {
        Statement: 'Perform a build, buy, or reuse analysis.',
        Level: 3,
        Abbreviation: 'TS 3.3',
        Id: MODEL_CONSTANTS.TS_33,
        PracticeAreaID: MODEL_CONSTANTS.TS
    },
    [MODEL_CONSTANTS.TS_34]: {
        Statement: 'Select solutions based on design criteria.',
        Level: 3,
        Abbreviation: 'TS 3.4',
        Id: MODEL_CONSTANTS.TS_34,
        PracticeAreaID: MODEL_CONSTANTS.TS
    },
    [MODEL_CONSTANTS.TS_35]: {
        Statement: 'Develop, keep updated, and use information needed to implement the design.',
        Level: 3,
        Abbreviation: 'TS 3.5',
        Id: MODEL_CONSTANTS.TS_35,
        PracticeAreaID: MODEL_CONSTANTS.TS
    },
    [MODEL_CONSTANTS.TS_36]: {
        Statement: 'Design solution interfaces or connections using established criteria.',
        Level: 3,
        Abbreviation: 'TS 3.6',
        Id: MODEL_CONSTANTS.TS_36,
        PracticeAreaID: MODEL_CONSTANTS.TS
    },
    [MODEL_CONSTANTS.VV_11]: {
        Statement: 'Perform verification to ensure the requirements are implemented and record and communicate results.',
        Level: 1,
        Abbreviation: 'VV 1.1',
        Id: MODEL_CONSTANTS.VV_11,
        PracticeAreaID: MODEL_CONSTANTS.VV
    },
    [MODEL_CONSTANTS.VV_12]: {
        Statement: 'Perform validation to ensure the solution will function as intended in its target environment and record and communicate results.',
        Level: 1,
        Abbreviation: 'VV 1.2',
        Id: MODEL_CONSTANTS.VV_12,
        PracticeAreaID: MODEL_CONSTANTS.VV
    },
    [MODEL_CONSTANTS.VV_21]: {
        Statement: 'Select components and methods for verification and validation.',
        Level: 2,
        Abbreviation: 'VV 2.1',
        Id: MODEL_CONSTANTS.VV_21,
        PracticeAreaID: MODEL_CONSTANTS.VV
    },
    [MODEL_CONSTANTS.VV_22]: {
        Statement: 'Develop, keep updated, and use the environment needed to support verification and validation.',
        Level: 2,
        Abbreviation: 'VV 2.2',
        Id: MODEL_CONSTANTS.VV_22,
        PracticeAreaID: MODEL_CONSTANTS.VV
    },
    [MODEL_CONSTANTS.VV_23]: {
        Statement: 'Develop, keep updated, and follow procedures for verification and validation.',
        Level: 2,
        Abbreviation: 'VV 2.3',
        Id: MODEL_CONSTANTS.VV_23,
        PracticeAreaID: MODEL_CONSTANTS.VV
    },
    [MODEL_CONSTANTS.VV_31]: {
        Statement: 'Develop, keep updated, and use criteria for verification and validation.',
        Level: 3,
        Abbreviation: 'VV 3.1',
        Id: MODEL_CONSTANTS.VV_31,
        PracticeAreaID: MODEL_CONSTANTS.VV
    },
    [MODEL_CONSTANTS.VV_32]: {
        Statement: 'Analyze and communicate verification and validation results.',
        Level: 3,
        Abbreviation: 'VV 3.2',
        Id: MODEL_CONSTANTS.VV_32,
        PracticeAreaID: MODEL_CONSTANTS.VV
    }
};

export { PRACTICES }