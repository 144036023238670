const region = "us-east-1";

const devCognito = {
  REGION: region,
  USER_POOL_ID: "us-east-1_IxHHgdgzh",
  APP_CLIENT_ID: "6d7fuc91i3l2kjm4tc7mclga94",
  IDENTITY_POOL_ID: "us-east-1:695352fd-3280-4a54-af4a-8278bee23bfb"
}

const joshdevCognito = {
  REGION: region,
  USER_POOL_ID: "us-east-1_FhL7CKWg5",
  APP_CLIENT_ID: "71m5or5jj36rki3ps6571q5l34",
  IDENTITY_POOL_ID: "us-east-1:b262d64d-dacf-4fa3-bb7d-ee7f0e56ca4c"
}

const prodCognito = {
  REGION: region,
  USER_POOL_ID: "us-east-1_ULZekzRmT",
  APP_CLIENT_ID: "5852k1j0thoqbnhijmv750j3uj",
  IDENTITY_POOL_ID: "us-east-1:2a3f0681-aa39-46cd-9ca4-61689aad1e4c"
}

const dev =  {
    apiGateway: {
      REGION: region,
      URL: "https://llkja281ql.execute-api.us-east-1.amazonaws.com/dev"
    },
    cognito: devCognito
}

const prod = {
  apiGateway:{
    REGION: region,
    URL: "https://697stk66c5.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: prodCognito
};

const samDev = {
  apiGateway:{
    apiGateway:{
      REGION: region,
      URL: "https://3fjn6h3500.execute-api.us-east-1.amazonaws.com/samdev"
    }
  },
  cognito: devCognito
};

const joshDev = {
  apiGateway:{
    REGION: region,
    URL:"https://ewcqsnsl95.execute-api.us-east-1.amazonaws.com/joshdev"
  },
  cognito: joshdevCognito
}

let config;
switch(process.env.REACT_APP_STAGE){
  case "dev":
    config = dev;
    break;
  case "prod":
    config = prod;
    break;
  case "samdev":
    config = samDev;
    break;
  case "joshdev":
    config = joshDev;
    break;
  default:
    config = dev;
    break;
}

export default config;