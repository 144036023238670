import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { Constants, getDefaultAPIHeaders, getNodeAPIPath } from '../utils/helpers';
import NodePageModelInfo from '../presenters/NodePageModelInfo';
import Findings from '../presenters/Findings';
import LoadingComponent from '../presenters/LoadingComponent';
import MergeConflictModal from './MergeConflictModal';
import useArray from '../hooks/useArray';
import TabControl from '../presenters/TabControl';
import './NodePage.css';

//Returns an object that contains the finding item primitives. Using this so I can
// create one in one line without having object declarations everywhere.
function createFindingItem(apiId, key, text, header, revNumber){
    return{
        apiId: apiId,
        key: key,
        text: text,
        header: header,
        revNumber: revNumber,
    }
}

//Fetches the initial node page state (findings, evidence, etc..)
async function fetchData(nodeInfo, nodePageFindings, setNodePageFindings, setNodeHighlight, setPracticeAffirmed, evidence, interviews, interviewSessions, setFindingsLoading, setEvidenceLoading, setInterviewsLoading, setAffirmedLoading,formatFindingData){
    if(nodeInfo){
        
        //save the newly loaded node id into session storage for state checking purposes
        sessionStorage.setItem('currentNode', nodeInfo.nodeId);

        try{
            const data = await API.get(Constants.API_PATH, getNodeAPIPath(nodeInfo, Constants.APPRAISAL_ITEM_PATH), await getDefaultAPIHeaders());
            formatFindingData(data, nodePageFindings, setNodePageFindings);
        }catch(e){
            console.log('Findings get failure' + e);
        }
        setFindingsLoading(false);
        if(nodeInfo.isPractice){
            try{
                const affirmedData = await API.get(Constants.API_PATH, getNodeAPIPath(nodeInfo, Constants.APPRAISAL_STATE_PATH), await getDefaultAPIHeaders());
                if(affirmedData.highlight){
                    setNodeHighlight(affirmedData.highlight);
                }
                else{
                    setNodeHighlight("transparent");
                }
                setPracticeAffirmed(affirmedData.affirmed || false);
            } catch(e) {
                setNodeHighlight("transparent");
                setPracticeAffirmed(false); // the affirmation has not been set
            }
            setAffirmedLoading(false);
            try{
                const evidenceData = await API.get(Constants.API_PATH, getNodeAPIPath(nodeInfo, Constants.APPRAISAL_EVIDENCE_PATH), await getDefaultAPIHeaders());
                formatEvidenceData(evidenceData, evidence);
            } catch(e){
                console.log('Evidence get failure:' + e);
            }
            setEvidenceLoading(false);
            try{
                const interviewSessionData = await API.get(Constants.API_PATH, `${Constants.INTERVIEW_PATH}/${nodeInfo.appraisalId}`, await getDefaultAPIHeaders());
                formatInterviewSessionData(interviewSessionData, interviewSessions);
            } catch(e){
                console.log('Interview Session get failure ' + e);
            }
            try{
                const interviewData = await API.get(Constants.API_PATH, getNodeAPIPath(nodeInfo, Constants.INTERVIEW_QUESTIONS_PATH), await getDefaultAPIHeaders());
                formatInterviewData(interviewData, interviews);
            }
            catch(e){
                console.log('Interviews get failure ' + e);
            }
            setInterviewsLoading(false);
        }
    }
}

function formatEvidenceData(data, evidence){
    evidence.clear();

    data.forEach(e=>{
        evidence.add({
            id: e.appraisal_evidence_id,
            fileName: e.evidence_fileName,
            location: e.evidence_path,
            comments: e.evidence_notes,
            state: e.evidence_state,
            revNumber: e.revision_number,
            isHyperlink: e.isHyperlink
        });
    });
}

function formatInterviewData(data, interviews){
    interviews.clear();

    data.forEach(i=>{
        interviews.add({
            id: i.interview_question_id,
            sessions: i.sessions,
            question: i.question,
            revNumber: i.revision_number,
            markedForDelete: false
        });
    })
}

function formatInterviewSessionData(data, interviewSessions){
    interviewSessions.clear();

    data.forEach(is=>{
        interviewSessions.add({
            id: is.interview_session_id,
            hours: is.hours,
            interviewee: is.interviewee,
            selected: false
        });
    });
}

const NodePage = ({nodeInfo, onNodeHighlight, onPracticeAffirmed, setTreeControlLoading}) => {
    const [nodePageFindings, setNodePageFindings] = useState({
        notes: createFindingItem("", "notes", "", "Notes", undefined),
        strengths: createFindingItem("", "strengths", "", "Strengths", undefined),
        weaknesses: createFindingItem("", "weaknesses", "", "Weaknesses", undefined),
        improvement_opportunities: createFindingItem("", "improvement_opportunities", "", "Improvement Opportunities", undefined)
    });
    const [nodeHighlight, setNodeHighlight] = useState("transparent");
    const [practiceAffirmed, setPracticeAffirmed] = useState(false);
    const nodeEvidence = useArray([]);
    const nodeInterviews = useArray([]);
    const appraisalInterviewSessions = useArray([]);
    const [findingsLoading, setFindingsLoading] = useState(true);
    const [evidenceLoading, setEvidenceLoading] = useState(true);
    const [interviewsLoading, setInterviewsLoading] = useState(true);
    const [affirmedLoading, setAffirmedLoading] = useState(true);
    const [mergeConflictModalState, setMergeConflictModalState] = useState({});

    //Gets 
    useEffect(() => {
        setAffirmedLoading(true);
        setFindingsLoading(true);
        setEvidenceLoading(true);
        setInterviewsLoading(true);
        setNodeHighlight("transparent");
        const getData = () => fetchData(nodeInfo, nodePageFindings, setNodePageFindings,setNodeHighlight, setPracticeAffirmed, nodeEvidence, nodeInterviews, appraisalInterviewSessions, setFindingsLoading, setEvidenceLoading, setInterviewsLoading, setAffirmedLoading,formatFindingData);
        getData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [nodeInfo]);


    
    //Formats data that came from the back end and sets the node page's state
    function formatFindingData(data, nodePageFindings, setNodePageFindings){

        let findings = {};
        data.forEach(d=>{
            switch(d.item_type){
                case "notes":
                    findings.notes = createFindingItem(d.appraisal_item_id, "notes", 
                        d.item_data, "Notes", d.revision_number);
                    break;
                case "strengths":
                    findings.strengths = createFindingItem(d.appraisal_item_id, "strengths",
                        d.item_data, "Strengths", d.revision_number);
                    break;
                case "weaknesses":
                    findings.weaknesses = createFindingItem(d.appraisal_item_id, "weaknesses",
                        d.item_data, "Weaknesses               ", d.revision_number);
                    break;
                case "improvement_opportunities":
                    findings.improvement_opportunities = createFindingItem(d.appraisal_item_id, "improvement_opportunities", 
                        d.item_data, "Improvement Opportunities", d.revision_number);
                    break;
                default: 
                    break;
            }
        });
        //If the API didn't give us back any information for the finding, then we want to set it to the default initialization state.
        setNodePageFindings({
            notes: findings.notes || createFindingItem("", "notes", "", "Notes", undefined),
            strengths: findings.strengths || createFindingItem("", "strengths", "", "Strengths", undefined),
            weaknesses: findings.weaknesses || createFindingItem("", "weaknesses", "", "Weaknesses", undefined),
            improvement_opportunities: findings.improvement_opportunities || createFindingItem("", "improvement_opportunities", "", "Improvement Opportunities", undefined),
            dirty: false
        });
    }

    const handleFindingUpdated = (data) => {
        if(!data[0]){
            return;
        }
        let responseNodeId = data[0].node_id;
        let loadedNodeId = sessionStorage.getItem('currentNode');
        
        //only repopulate the findings controls if we are still on the same node page
        if(responseNodeId === loadedNodeId){
            formatFindingData(data, nodePageFindings, setNodePageFindings);
        }
    }

    function refreshPage(){
        setAffirmedLoading(true);
        setFindingsLoading(true);
        setEvidenceLoading(true);
        setInterviewsLoading(true);
        fetchData(nodeInfo, nodePageFindings, setNodePageFindings,setNodeHighlight, setPracticeAffirmed, nodeEvidence, nodeInterviews, appraisalInterviewSessions, setFindingsLoading, setEvidenceLoading, setInterviewsLoading, setAffirmedLoading, formatFindingData);
    }

    const showMergeConflictModal = (mergeConflictData, mergeConflictHandler, modalTitleFragment, canMerge = false, mergeConflictRenderer = null) => {
        setMergeConflictModalState({
            showMergeConflictModal: true,
            mergeConflictData: mergeConflictData,
            mergeConflictHandler: mergeConflictHandler,
            mergeConflictRenderer: mergeConflictRenderer,
            modalTitleFragment: modalTitleFragment,
            canMerge: canMerge
        });
    }

    const hideMergeConflictModal = () => {
        setMergeConflictModalState(prevState=>(
            {
                ...prevState,
                showMergeConflictModal: false
            }
        ));
    }

    //TODO - change evidence loading when we have integrated to have it's own variable (instead of keying off the findings loading)
    return(
        nodeInfo ? 
            <div>
                <NodePageModelInfo nodeInfo={nodeInfo} nodeHighlight={nodeHighlight} setNodeHighlight={setNodeHighlight} practiceAffirmed={practiceAffirmed} setPracticeAffirmed={setPracticeAffirmed} affirmedLoading={affirmedLoading} setTreeControlLoading={setTreeControlLoading} onNodeHighlight={onNodeHighlight} onPracticeAffirmed={onPracticeAffirmed} refreshPage={refreshPage} />
                <TabControl evidence={nodeEvidence} 
                    interviews={nodeInterviews}
                    interviewSessions={appraisalInterviewSessions}
                    formatInterviewData={formatInterviewData} 
                    nodeInfo={nodeInfo} 
                    evidenceLoading={evidenceLoading}
                    interviewsLoading={interviewsLoading} 
                    formatEvidenceData={formatEvidenceData}/>
                <LoadingComponent isLoading={findingsLoading}>
                    <Findings findings={nodePageFindings} setFindings={setNodePageFindings} nodeInfo={nodeInfo}
                        handleFindingUpdated={handleFindingUpdated} showMergeConflictModal={showMergeConflictModal}/>
                </LoadingComponent>
                <MergeConflictModal conflict={mergeConflictModalState.mergeConflictData} 
                    handleMergeConflict={mergeConflictModalState.mergeConflictHandler} 
                    showModal={mergeConflictModalState.showMergeConflictModal}
                    modalTitleFragment={mergeConflictModalState.modalTitleFragment}
                    renderConflictContent={mergeConflictModalState.mergeConflictRenderer}
                    canMerge={mergeConflictModalState.canMerge} 
                    closeModal={hideMergeConflictModal}/>
            </div>
            : null
    )
}

export default NodePage;