import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingComponent = ({isLoading, children}) => {   
    return(
        isLoading ? 
        <div class="d-flex justify-content-center">
            <Spinner animation="border" variant="secondary"
                    style={{borderTop: '22px dotted #006666', 
                    borderRight: '22px dotted #008080', 
                    borderBottom: '22px dotted #009999', 
                    borderLeft: '22px dotted #00a8a8'}} 
                        />
        </div>
        : children
    )
}
export default LoadingComponent;