import React from 'react';
import {Image as Pic, Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import logo from '../assets/icon_cropped.png';

const Styles = styled.div`
.App-logo {
    height: 20vmin;
    pointer-events: none;  
    z-index: 0;  
    margin-top: 50px;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
@keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }  
  }
`;

export const Image = () => (
    <Styles>
        <Container>
            <Row className="justify-content-md-center">
                <Col></Col>
                <Col md="auto">
                    <Pic src={logo} fluid rounded className="App-logo" alt="logo" />  
                </Col>
                <Col></Col>
            </Row> 
            <br></br>
        </Container>                  
    </Styles>
)