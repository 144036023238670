import React, { useState, useEffect } from 'react';
import { Button, Spinner, Row, Col, Form, Modal, FormControl } from 'react-bootstrap';
import { modelContentUtils } from '../utils/helpers';
import { AppraisalRepository } from '../utils/repositories';
import useArray from '../hooks/useArray';
import LoadingComponent from '../presenters/LoadingComponent';
import ReportFragment from '../presenters/ReportFragment';
import MultiSelectListReportOptions from '../presenters/MultiSelectListReportOptions';
import EvidenceSearchView from '../presenters/EvidenceSearchView';
import { PRACTICE_AREA_RELATIONSHIPS } from '../model/practicearearelationships';
import { PRACTICES } from '../model/practices';
import { Constants, getDefaultAPIHeaders } from '../utils/helpers';
import { API } from 'aws-amplify';
import NewWindow from 'react-new-window';

async function fetchData(setDataLoading, setAppraisalName, projects, practiceAreas) {
    setDataLoading(true);
    const currentAppraisalId = localStorage.getItem("selectedAppraisal");
    if (currentAppraisalId) {
        const apiRepo = new AppraisalRepository(null, null, { selected: false, availableForSelection: false });
        const appraisalData = await apiRepo.getOne(currentAppraisalId, false);
        if (!appraisalData.error) {
            projects.setValue(appraisalData.returnValue.projects.filter(x => x.name !== "Organizational Roll-up"));
            setAppraisalName(appraisalData.returnValue.name);
            const formattedPracticeAreas = modelContentUtils.getPracticeAreasFromAppraisalProjects(appraisalData.returnValue.projects, true, true)
                .map(pa => {
                    return {
                        ...pa,
                        selected: false,
                        availableForSelection: false
                    }
                });
            practiceAreas.setValue(formattedPracticeAreas);
        } else {
            console.log("error getting appraisal data: ", appraisalData.returnValue);
        }
    }

    setDataLoading(false);
}

const EvidenceSearchModal = (props) => {
    const [dataLoading, setDataLoading] = useState(true);
    const [optionsVisible, setOptionsVisible] = useState(true);
    const [appraisalName, setAppraisalName] = useState('');
    const [reportLoading, setReportLoading] = useState(false);
    const [evidenceData, setEvidenceData] = useState({});
    const [searchDetails, setSearchDetails] = useState(false);
    const [hasProjectSelected, setHasProjectSelected] = useState("false");
    const [hasPracticeAreaSelected, setHasPracticeAreaSelected] = useState("false");
    const [searchType, setSearchType] = useState("fileLocation");
    const [searchPhrase, setSearchPhrase] = useState("");
    const projects = useArray([]);
    const practiceAreas = useArray([]);

    useEffect(() => {
        if (props.showModal) {
            const getData = () => fetchData(setDataLoading, setAppraisalName, projects, practiceAreas);
            getData();
            setOptionsVisible(true);
            setHasProjectSelected(false);
            setHasPracticeAreaSelected(false);
            setSearchPhrase("");
            setSearchDetails("");
            setSearchType("workProductName");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showModal]);

    const handleProjectSelectionChanged = (event) => {
        const allOptions = event.target.options;
        let selectedProjectIds = [];
        for (let i = 0; i < allOptions.length; i++) {
            const currentOption = allOptions[i];
            let project = projects.getById(currentOption.value);
            if (currentOption.selected) {
                selectedProjectIds.push(currentOption.value);
            }
            project.selected = currentOption.selected;
            projects.replace(currentOption.value, project);
        }

        const selectedProjectPracticeAreaIds = projects.value.filter(p => selectedProjectIds.includes(p.id))
            .map(p => p.practiceAreas).flat();
        const newPracticeAreas = practiceAreas.value.slice()
            .map(pa => {
                if (selectedProjectPracticeAreaIds.includes(pa.id)) {
                    return {
                        ...pa,
                        availableForSelection: true
                    }
                } else {
                    return {
                        ...pa,
                        availableForSelection: false,
                        selected: false
                    }
                }
            });
        practiceAreas.setValue(newPracticeAreas);

        setHasProjectSelected(projects.value.filter(p => p.selected === true).length > 0 ? true : false);

        let anyPASelected = false;
        newPracticeAreas.forEach(d => {
            if (d.selected) {
                anyPASelected = true;
            }
        });
        setHasPracticeAreaSelected(anyPASelected);
    }

    const handlePracticeAreaSelectionChanged = (event) => {
        const allOptions = event.target.options;
        let selectedPracticeAreaIds = [];
        for (let i = 0; i < allOptions.length; i++) {
            const currentOption = allOptions[i];
            if (currentOption.selected) {
                selectedPracticeAreaIds.push(currentOption.value);
            }
        }
        const newPracticeAreas = practiceAreas.value.slice()
            .map(pa => {
                if (selectedPracticeAreaIds.includes(pa.id)) {
                    return {
                        ...pa,
                        selected: true
                    }
                } else {
                    return {
                        ...pa,
                        selected: false
                    }
                }
            });
        practiceAreas.setValue(newPracticeAreas);

        setHasPracticeAreaSelected(selectedPracticeAreaIds.length > 0 ? true : false);
    }

    async function getEvidenceData(currentAppraisalId, selectedProjects, selectedPracticeAreas) {

        let findingsCount = 0;
        let allEvidenceData = [];
        const apiPath = `${Constants.APPRAISAL_EVIDENCE_PATH}/${currentAppraisalId}`
        const data = await API.get(Constants.API_PATH, apiPath, await getDefaultAPIHeaders());

        for (let i = 0; i < selectedProjects.length; i++) {
            const currentProject = selectedProjects[i];

            let practiceAreaData = [];
            for (let i = 0; i < selectedPracticeAreas.length; i++) {
                const practiceAreaName = [selectedPracticeAreas[i].name]

                let practiceData = [];
                //get the node ids for each node in the practice area
                const practiceNodes = PRACTICE_AREA_RELATIONSHIPS[selectedPracticeAreas[i].id];
                for (let i = 0; i < practiceNodes.length; i++) {

                    //get the friendly abbreviation for the practice node
                    const practice = PRACTICES[practiceNodes[i].Id].Abbreviation;

                    let match = false;

                    data.forEach(d => {
                        if (d.project_id === currentProject.id && d.node_id === practiceNodes[i].Id) {
                            
                            if(searchType === "fileLocation"){
                                if(d.evidence_path.toLowerCase().includes(searchPhrase.toLowerCase())){
                                    match = true;
                                }
                            }
                            else if(searchType === "workProductName"){
                                if(d.evidence_fileName.toLowerCase().includes(searchPhrase.toLowerCase())){
                                    match = true;
                                }
                            }
                            else if(searchType === "comments"){
                                if(d.evidence_notes.toLowerCase().includes(searchPhrase.toLowerCase())){
                                    match = true;
                                }
                            }
                        }
                    });

                    if (match) {
                        practiceData.push({
                            practiceName: practice
                        })
                        findingsCount++;
                    }
                }

                if (practiceData.length > 0) {
                    practiceAreaData.push({
                        practiceAreaName: practiceAreaName,
                        practiceData
                    });
                }
            }
            allEvidenceData.push({
                projectName: currentProject.name,
                practiceAreaData
            });
        }

        let details = getSearchDetails(findingsCount);
        setSearchDetails(details);

        return allEvidenceData;
    }

    function getSearchDetails(count){

        let formattedType = "";
        if(searchType === "workProductName"){
            formattedType = "Work Product Name";
        }
        else if(searchType === "fileLocation"){
            formattedType = "File Location";
        }
        else{
            formattedType = "Comments";
        }

        let details = "Found " + count + " " + formattedType + " results for \"" + searchPhrase + "\":";
        return details;
    }

    const handleGenerateReportClicked = async (event) => {
        generateReport();
    }

    const handleSubmitWithEnter = async () => {
        if(hasProjectSelected && hasPracticeAreaSelected && searchPhrase !== ""){
            generateReport();
        }
    }

    const generateReport = async () => {
        setReportLoading(true);

        let currentAppraisalId = localStorage.getItem("selectedAppraisal");
        let selectedProjects = projects.value.filter(p => p.selected === true);
        let selectedPracticeAreas = practiceAreas.value.filter(p => p.selected === true);
        let evidenceinfo = await getEvidenceData(currentAppraisalId, selectedProjects, selectedPracticeAreas);
        setEvidenceData(evidenceinfo);

        setReportLoading(false);
        setOptionsVisible(false);
        props.setShowModal(false);
    }


    const handleTypeChanged = (value) => {
        setSearchType(value);
    }

    const handleSearchPhraseChanged = (value) => {
        setSearchPhrase(value);
    }

    const handleEnterPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            e.preventDefault();
            handleSubmitWithEnter();
        }
    }


    const projectSelectionDisplayOptions = {
        title: "Projects",
        displayKey: "name",
        filterAvailable: false
    };

    const practiceAreaSelectionDisplayOptions = {
        displayKey: "name",
        filterAvailable: true,
        availableKey: "availableForSelection",
    };

    const viewReportHeader = ``;
    const scopeHeader = '';
    const appraisalHeader = 'Appraisal:';
    const reportName = 'Evidence Search';

    const spacingStyle = {
        marginBottom: "10px",
        marginLeft: "20%",
        fontSize: "16px",
        justifyContent: 'center',
        alignItems: 'center'
    };

    const viewReportStyle = {
        textAlign: "right",
        paddingTop: "0px",
        color: 'black',
        marginTop: '16px',
        fontSize: '22px'
    }

    return (
        <React.Fragment>
            <Modal show={props.showModal} onHide={(e) => props.setShowModal(false)} dialogClassName="findingsReportModalStyle">
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '18px', color: 'dimgray' }}>
                        <img
                            src={require('../assets/icon.png')}
                            width="30"
                            height="30"
                            className="d-inline-block align-middle"
                            alt="CAP logo"
                        />{' '}Evidence Search</Modal.Title>
                </Modal.Header>
                <LoadingComponent isLoading={dataLoading} iconSize={80}>
                    <ReportFragment visible={optionsVisible} >
                        <Row className="d-flex justify-content-center" style={{ marginTop: '-50px' }}>
                            <Col md={6}>
                                <Form>
                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <Form.Label
                                            column sm='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                padding: '.5rem'
                                            }}>
                                            Appraisal
                                        </Form.Label>
                                        <Col sm={6} style={{ textAlign: 'left' }}>
                                            <Form.Control plaintext readOnly value={appraisalName} style={{ color: 'dimgray', paddingLeft: '1rem' }} />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <Form.Label
                                            column sm='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                padding: '.5rem'
                                            }}>
                                            Projects
                                        </Form.Label>
                                        <Col sm={6} style={{ textAlign: 'left' }}>
                                            <MultiSelectListReportOptions listData={projects.value} style={spacingStyle}
                                                handleSelectionChanged={handleProjectSelectionChanged}
                                                displayOptions={projectSelectionDisplayOptions} />
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col md={6}>
                                <Form>
                                    <Form.Group as={Row} className="d-flex justify-content-center" >
                                        <Form.Label hidden={!hasProjectSelected}
                                            column sm='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                padding: '.5rem'
                                            }}>
                                            Practice Areas
                                        </Form.Label>
                                        <Col style={{ marginRight: '10px' }}>
                                            <MultiSelectListReportOptions listData={practiceAreas.value}
                                                handleSelectionChanged={handlePracticeAreaSelectionChanged}
                                                displayOptions={practiceAreaSelectionDisplayOptions} />
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form>
                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <Form.Label
                                            column sm='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                padding: '.5rem'
                                            }}>
                                            Search By
                                        </Form.Label>
                                        <Col sm={6} style={{ textAlign: 'left' }}>
                                            <FormControl
                                                as="select"
                                                style={{ borderRightColor: 'white', borderTopColor: 'white' }}
                                                onChange={(e) => handleTypeChanged(e.target.value)} >
                                                <option value='workProductName'>Work Product Name</option>
                                                <option value='fileLocation'>File Location</option>
                                                <option value='comments'>Comments</option>
                                            </FormControl>
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                            <Form>
                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <Form.Label
                                            column sm='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                padding: '.5rem'
                                            }}>
                                            Search Phrase
                                        </Form.Label>
                                        <Col sm={6} style={{ textAlign: 'left' }}>
                                            <FormControl
                                                as="input"
                                                style={{ borderRightColor: 'white', borderTopColor: 'white' }}
                                                onChange={(e) => handleSearchPhraseChanged(e.target.value)} 
                                                onKeyPress={(e)=>handleEnterPress(e)} >
                                            </FormControl>
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-center">
                            <Button
                                variant="outline-light"
                                disabled={!hasProjectSelected || !hasPracticeAreaSelected || searchPhrase === ""}
                                style={{
                                    background: 'rgb(0, 168, 168)',
                                    color: 'white',
                                    border: 'none',
                                    justifyContent: 'center',
                                    padding: '.5rem',
                                    marginTop: '1rem',
                                    marginBottom: '2rem'
                                }}
                                size='sm'
                                onClick={(e) => handleGenerateReportClicked(e)}>
                                {reportLoading &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size='sm'
                                        role="status"
                                        aria-hidden="true"
                                    />}
                                {!reportLoading ? "Search Evidence" : ' Searching...'}
                            </Button>
                        </Row>
                    </ReportFragment>
                </LoadingComponent>
            </Modal>

            {!optionsVisible &&
                <NewWindow>
                    <ReportFragment
                        reportName={reportName}
                        reportHeader={viewReportHeader}
                        reportHeaderStyle={viewReportStyle}
                        scopeHeader={scopeHeader}
                        appraisalHeader={appraisalHeader}
                        appraisalName={appraisalName}>
                        <EvidenceSearchView
                            reportType="Evidence Search"
                            projects={projects.value.filter(p => p.selected === true)}
                            evidenceData={evidenceData}
                            searchDetails={searchDetails}
                        />
                    </ReportFragment>
                </NewWindow>
            }
        </React.Fragment>
    );
}
export default EvidenceSearchModal;