import React from 'react';
import {Row, Col, Button} from 'react-bootstrap'
import { getCurrentDateTime } from '../utils/helpers';

function getProjectSubTitle(projects){
    let projectSubTitle = "";
    for(let i = 0; i < projects.length; i++){
        if(i !== projects.length - 1){
            const nextProject = `${projects[i].name}, `;
            projectSubTitle = projectSubTitle.concat(nextProject);
        } else {
            projectSubTitle = projectSubTitle.concat(projects[i].name);
        }
    }
    return projectSubTitle;
}

let csvData = [];
function formatEvidenceData(evidenceData, includeComments, includePath){
    let reportRows = [];
    if(includeComments && includePath){
        csvData.push(["Project", "Practice Area", "Practice", "Work Product Name", "Path", "Comments"]);
    }
    else if(includeComments){
        csvData.push(["Project", "Practice Area", "Practice", "Work Product Name", "Comments"]);
    }
    else if(includePath){
        csvData.push(["Project", "Practice Area", "Practice", "Work Product Name", "Path"]);
    }
    else{
        csvData.push(["Project", "Practice Area", "Practice", "Work Product Name"]);
    }
    
    for(let i = 0; i < evidenceData.length; i++){
    
        let practiceAreaData = evidenceData[i].practiceAreaData;
        let projectName = "";
        if(practiceAreaData.length > 0){

            projectName = evidenceData[i].projectName;
            reportRows.push(<h5 style={{background: 'rgb(0,168,168)', paddingLeft:'5px'}}><b>{projectName}</b></h5>)
        }

        for(let j = 0; j < practiceAreaData.length; j++){
            let practiceAreaName = practiceAreaData[j].practiceAreaName;
            reportRows.push(<div style={{paddingLeft : '35px'}}><b>{practiceAreaName}</b></div>)

            let practiceData = practiceAreaData[j].practiceData;
            for(let k = 0; k < practiceData.length; k++){
                let practiceName = practiceData[k].practiceName;
                let statement = practiceData[k].statement;
                reportRows.push(<div style={{paddingLeft : '70px'}}><b>{practiceName}: {statement}</b></div>)

                let practiceEvidence = practiceData[k].evidence;
                reportRows.push(
                    <div style={{paddingLeft : '105px'}}>
                        <Row >
                            <Col md={3} style={{color: 'rgb(0,168,168)'}}><b>Work Product Name</b></Col>
                            { includePath && 
                                <Col md={4} style={{color: 'rgb(0,168,168)'}}><b>Path</b></Col>
                            }
                            { includeComments && 
                                <Col md={5} style={{color: 'rgb(0,168,168)'}}><b>Comments</b></Col>
                            }
                        </Row>
                    </div>
                )
                for(let l = 0; l < practiceEvidence.length; l++){
                    reportRows.push(
                        <div style={{paddingLeft : '105px'}}>
                            <Row >
                                <Col  md={3} style={{wordWrap:'break-word'}}>{practiceEvidence[l].fileName}</Col>
                                { includePath && 
                                    <Col  md={4} style={{wordWrap:'break-word'}}>{practiceEvidence[l].path}</Col>
                                }
                                { includeComments && 
                                    <Col  md={5} style={{wordWrap:'break-word'}}>{practiceEvidence[l].notes}</Col>
                                }
                            </Row>
                            <br/>
                        </div>
                    )

                    //add data to CSV output
                    if(includeComments && includePath){
                        csvData.push([projectName, practiceAreaName, practiceName, practiceEvidence[l].fileName, practiceEvidence[l].path, practiceEvidence[l].notes]);
                    }
                    else if(includeComments){
                        csvData.push([projectName, practiceAreaName, practiceName,  practiceEvidence[l].fileName, practiceEvidence[l].notes]);
                    }
                    else if(includePath){
                        csvData.push([projectName, practiceAreaName, practiceName,  practiceEvidence[l].fileName, practiceEvidence[l].path]);
                    }
                    else{
                        csvData.push([projectName, practiceAreaName, practiceName,  practiceEvidence[l].fileName]);
                    }
                }
                reportRows.push(<div><br></br></div>);
            }
        }
    }
    
    return reportRows;
}

const EvidenceMappingsReportView = ({reportType, projects, evidenceData, includeComments, includePath}) => {
    const projectSubtitle = getProjectSubTitle(projects);
    let reportRows=[];
    let emptyProjects = [];
    let noDataText = " - No results found";
    let hasProjectWithNoData = false;
 
    evidenceData.forEach(d => {
        if(d.practiceAreaData.length === 0){
            emptyProjects.push(d.projectName);
            hasProjectWithNoData = true;
        }
    });

    const InsertNoDataRow = () =>{
        let projectList = emptyProjects.join(',');
        let noResults = projectList.concat(noDataText);

        if(hasProjectWithNoData){
            return(<div>
                {
                    noResults
                }
            </div>)
        }
    }

    if(evidenceData.length !== 0){        
        reportRows = formatEvidenceData(evidenceData, includeComments, includePath); 
    }
    
    let dateTime = getCurrentDateTime();

    return (
        <React.Fragment>
            <Row style={{height:'auto', marginBottom:'0px'}}>
                <Col>
                    <p style={{textAlign:'right', fontSize:'18px'}}><b>Created for Project(s):</b></p>
                </Col>
                <Col>
                    <p style={{textAlign:"left", fontSize:'18px'}}>{projectSubtitle}</p>
                </Col>
            </Row>
            <Row style={{height:'20px', marginBottom:'8px'}}>
            <Col>
                    <p style={{textAlign: "right"}}><small><b>Date/Time Generated:</b></small></p>
                </Col>
                <Col>
                    <p style={{textAlign: "left"}}><small>{dateTime}</small></p>
                </Col>
            </Row>
            <hr class='my-2' style={{ height: '1px', borderWidth: '0', color: 'dimgray', backgroundColor: 'dimgray' }} />
            <div style={{ display: "grid", gridTemplateColumns: "15% 70% 15%", gridTemplateRows: "100%" }}>
                <div style={{ gridColumnStart: "2", gridRowStart: "1", border: 'none' }} >
                    {reportRows}                    
                    <h5 style={{ background: 'rgb(0,168,168)' }}><b>{InsertNoDataRow()}</b></h5>
                </div>
            </div>            
        </React.Fragment>
    );
}
export default EvidenceMappingsReportView; 