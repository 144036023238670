import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select'
import { HIGHLIGHT_OPTIONS, HIGHLIGHT_COLOR_MAP } from '../utils/IconMap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const customSelectStyles = {
    control: (base, state) => ({
        ...base,
        borderLeft: '0 !important',
        borderRight: '0 !important',
        borderTop: '0 !important',
        borderBottom: '0 !important',
        boxShadow: '0 !important',
        outline: 'none !important'
    }),
    indicatorSeparator: base => ({
        ...base,
        display: 'none'
    }),
    indicatorsContainer: base => ({
        ...base,
        display: 'none'
    }),
    menu: base => ({
        ...base,
        width: "auto"
    }),
    menuList: base => ({
        ...base,
        display: "flex",
        flexWrap: "wrap",
        width: "120px",
        height: "120px",
        backgroundColor: "white"
    })
}

function HighlightColorPicker(props) {
    const [highlightColor, setHighlightColor] = useState({ value: "transparent", label: HIGHLIGHT_COLOR_MAP["transparent"] });

    useEffect(() => {
        setHighlightColor({ value: props.nodeHighlight, label: HIGHLIGHT_COLOR_MAP[props.nodeHighlight] });
    }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [props.nodeInfo, props.nodeHighlight]);

    const handleHighlightColorChanged = (highlight) => {
        setHighlightColor({ value: highlight.value, label: HIGHLIGHT_COLOR_MAP[highlight.value] });
        props.onHighlightColorPicked(highlight.value);
        props.setNodeHighlight(highlight.value);
    }

    const GetOptions = () => {
        return HIGHLIGHT_OPTIONS;
    }
    const Option = (props) => {
        return (
            <div title={props.value}>
                <components.Option {...props} />
            </div>
        )
    }

    return (
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Highlight Practice</Tooltip>}>
            <span className="d-inline-block">
            <Select
                className="select"
                isSearchable={false}
                options={GetOptions()}
                components={{ Option }}
                styles={customSelectStyles}
                value={highlightColor}
                onChange={(e) => handleHighlightColorChanged(e)}
                menuPlacement={"auto"}
                clearable={true}>
            </Select>
            </span>
            </OverlayTrigger >
    )
}
export default HighlightColorPicker;