import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AdminViewOrganizations from './Organizations';
import AdminViewUsers from './Users';
import AdminViewAppraisals from './Appraisals';
import styled from 'styled-components';

const Styles = styled.div`
.table{
    table-layout: auto;
}

.thead{
  background-color: rgb(0,168,168); 
  font-size: 16px;   
  color: white; 
  width: 100%;
}

.custom-btn {
    background:rgb(0, 168, 168);        
    color: dimgray;
    border: none;
    justify-content: center; 
    padding: .5rem;  
  } 
`;

//Organizations page
function AdminView(props) {
    
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [refreshTrigger, setRefreshTrigger] = useState(true);

    const updateSelectedOrg = (org) => {
        setSelectedOrg(org);
    }

    const onOrgEditted = () => {
        setRefreshTrigger(!refreshTrigger);
    }

    return (
        <Styles>  
            <Container fluid>
            <Row className="d-flex justify-content-center">        
                    <Col md={3}>
                        <AdminViewOrganizations updateSelectedOrg={updateSelectedOrg} onOrgEditted={onOrgEditted} />
                    </Col>
                    <Col md={4}>
                        <AdminViewAppraisals org={selectedOrg} />  
                    </Col>
                    <Col xs={1} style={{maxWidth:"5px", background: "rgb(0,168,168)", paddingLeft:"1px", paddingRight:"1px"}}>
                    </Col>    
                    <Col md={4}>                      
                        <AdminViewUsers refreshTrigger={refreshTrigger}/>
                    </Col>   
            </Row>
            </Container>          
        </Styles>
    )
}
export default AdminView;