import React, { useState, useEffect } from 'react';
import { Button, Spinner, Row, Col, Form, Modal } from 'react-bootstrap';
import { AppraisalRepository } from '../utils/repositories';
import LoadingComponent from '../presenters/LoadingComponent';
import ReportFragment from '../presenters/ReportFragment';
import AffirmationCoverageReportView from '../presenters/AffirmationCoverageReportView';
import { AppraisalStateRepository } from '../utils/repositories';
import { modelContentUtils, Constants, getDefaultAPIHeaders } from '../utils/helpers';
import { API } from 'aws-amplify';
import { PRACTICE_AREA_RELATIONSHIPS, PRACTICES } from '../model/modelcontent';
import NewWindow from 'react-new-window'

async function fetchData(setDataLoading, setAppraisalName) {
    setDataLoading(true);
    const currentAppraisalId = localStorage.getItem("selectedAppraisal");
    if (currentAppraisalId) {
        const apiRepo = new AppraisalRepository();
        const appraisalData = await apiRepo.getOne(currentAppraisalId, false);
        if (!appraisalData.error) {
            setAppraisalName(appraisalData.returnValue.name);
        } else {
            console.log("error getting appraisal data: ", appraisalData.returnValue);
        }
    }

    setDataLoading(false);
}

const AffirmationCoverageReportModal = (props) => {
    const [dataLoading, setDataLoading] = useState(true);
    const [optionsVisible, setOptionsVisible] = useState(true);
    const [appraisalName, setAppraisalName] = useState('');
    const [reportLoading, setReportLoading] = useState(false);
    const [projects, setProjects] = useState({});
    const [practiceAreas, setPracticeAreas] = useState({});
    const [maturityLevel, setMaturityLevel] = useState(0);
    const [affirmationData, setAffirmationData] = useState({});
    const [includeLevel1, setIncludeLevel1] = useState(false);

    useEffect(() => {
        if (props.showModal) {
            const getData = () => fetchData(setDataLoading, setAppraisalName);
            getData();
            setAffirmationData({});
            setProjects({});
            setOptionsVisible(true);
            setIncludeLevel1(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showModal]);

    async function getAffirmationData(currentAppraisalId) {
        const interviewsData = await API.get(Constants.API_PATH, `${Constants.INTERVIEW_QUESTIONS_PATH}/${currentAppraisalId}`, await getDefaultAPIHeaders());
        const appraisalRepo = new AppraisalRepository();
        const stateRepo = new AppraisalStateRepository();
        const data = await appraisalRepo.getOne(currentAppraisalId, false);
        let maturityLevel = 0;
        let projects = [];
        let practiceAreas = [];
        if (!data.error) {
            maturityLevel = modelContentUtils.getMaturityLevelFromView(data.returnValue.view);
            setMaturityLevel(maturityLevel);
            projects = data.returnValue.projects;
            setProjects(projects);
            practiceAreas = modelContentUtils.getPracticeAreasFromAppraisalProjects(data.returnValue.projects, true, true)
            setPracticeAreas(practiceAreas);
        }

        //build an array to send over to the view containing:
        // projectName, practiceAreaName, practiceName, hasQuestion, isAffirmed
        let affirmationData = [];

        for (let i = 0; i < projects.length; i++) {

            //do not include a rollup project
            if (projects[i].name !== "Organizational Roll-up") {

                //get all state entries for the current project
                let stateEntries = "";
                const response = await stateRepo.getAll(currentAppraisalId, projects[i].id);
                if (!response.error) {
                    stateEntries = response.returnValue;
                }

                for (let j = 0; j < projects[i].practiceAreas.length; j++) {

                    //get all practices available to the current practice area and maturity level
                    let currentPracticeArea = projects[i].practiceAreas[j];
                    let practices = [];
                    if(includeLevel1){
                        practices = PRACTICE_AREA_RELATIONSHIPS[currentPracticeArea].filter(x => x.Level <= maturityLevel);
                    }
                    else{
                        practices = PRACTICE_AREA_RELATIONSHIPS[currentPracticeArea].filter(x => x.Level > 1 && x.Level <= maturityLevel);
                    }

                    for (let k = 0; k < practices.length; k++) {
                        let practiceAffirmed = false;
                        let hasQuestion = false;

                        //see if there is a state entry for the practice
                        let correspondingStateEntry = stateEntries.filter(x => x.nodeId === practices[k].Id)
                        if (correspondingStateEntry.length > 0) {
                            if (correspondingStateEntry[0].affirmed) {
                                practiceAffirmed = true;
                            }
                        }
                        //see if there is a interview question entry for the practice
                        let correspondingInterviewEntry = interviewsData.filter(x => x.node_id === practices[k].Id && x.project_id === projects[i].id)
                        if (correspondingInterviewEntry.length > 0) {
                            hasQuestion = true;
                        }

                        //store the information
                        let affirmationEntry = {
                            project: projects[i].name,
                            practiceArea: currentPracticeArea,
                            practice: PRACTICES[practices[k].Id].Abbreviation,
                            hasQuestion: hasQuestion,
                            isAffirmed: practiceAffirmed
                        }
                        affirmationData.push(affirmationEntry)
                    }
                }
            }
        }
        return affirmationData;
    }

    const levelOneCheckBoxChanged = () => {
        setIncludeLevel1(!includeLevel1);
    }

    const handleGenerateReportClicked = async (event) => {
        setReportLoading(true);

        let currentAppraisalId = localStorage.getItem("selectedAppraisal");
        let affirmationInfo = [];

        affirmationInfo = await getAffirmationData(currentAppraisalId);

        setAffirmationData(affirmationInfo);

        setReportLoading(false);
        setOptionsVisible(false);
        props.setShowModal(false);
    }

    const viewReportHeader = ``;
    const scopeHeader = '';
    const appraisalHeader = 'Appraisal:';
    const reportName = 'Affirmation Coverage Report';

    const viewReportStyle = {
        textAlign: "right",
        paddingTop: "0px",
        color: 'black',
        marginTop: '16px',
        fontSize: '22px'
    }

    return (
        <React.Fragment>
            <Modal show={props.showModal} onHide={(e) => props.setShowModal(false)} dialogClassName="reportModalStyle">
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '18px', color: 'dimgray' }}>
                        <img
                            src={require('../assets/icon.png')}
                            width="30"
                            height="30"
                            className="d-inline-block align-middle"
                            alt="CAP logo"
                        />{' '}Affirmation Coverage Report</Modal.Title>
                </Modal.Header>
                <LoadingComponent isLoading={dataLoading} iconSize={80}>
                    <ReportFragment visible={optionsVisible} >
                        <Row className="d-flex justify-content-center" style={{ marginTop: '-50px' }}>
                            <Col md={10}>
                                <Form>
                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <Form.Label
                                            column sm='3'
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'rgb(0,168,168)',
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                padding: '.5rem'
                                            }}>
                                            Appraisal
                                        </Form.Label>
                                        <Col sm={6} style={{ textAlign: 'left' }}>
                                            <Form.Control plaintext readOnly value={appraisalName} style={{ color: 'dimgray', paddingLeft: '1rem' }} />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="d-flex justify-content-center">
                                        <Col sm={3}></Col>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col md={5}>
                            </Col>
                            <Col md={1}></Col>


                        </Row>
                        <Row className="d-flex justify-content-center">
                            <Form.Group controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Include Level 1 Practices"
                                    style={{ color: "dimgray" }}
                                    checked={includeLevel1}
                                    onChange={levelOneCheckBoxChanged}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="d-flex justify-content-center">
                            <Button
                                variant="outline-light"
                                style={{
                                    background: 'rgb(0, 168, 168)',
                                    color: 'white',
                                    border: 'none',
                                    justifyContent: 'center',
                                    padding: '.5rem',
                                    marginTop: '1rem',
                                    marginBottom: '1rem'
                                }}
                                size='sm'
                                onClick={(e) => handleGenerateReportClicked(e)}>
                                {reportLoading &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size='sm'
                                        role="status"
                                        aria-hidden="true"
                                    />}
                                {!reportLoading ? "Generate Affirmation Coverage Report" : ' Generating Report...'}
                            </Button>{' '}
                        </Row>
                    </ReportFragment>

                </LoadingComponent>
            </Modal>

            {!optionsVisible &&
                <NewWindow>
                    <ReportFragment
                        reportName={reportName}
                        reportHeader={viewReportHeader}
                        reportHeaderStyle={viewReportStyle}
                        scopeHeader={scopeHeader}
                        appraisalHeader={appraisalHeader}
                        appraisalName={appraisalName}>
                        <AffirmationCoverageReportView
                            reportType="Affirmation Coverage"
                            projects={projects}
                            practiceAreas={practiceAreas}
                            maturityLevel={maturityLevel}
                            affirmationData={affirmationData}
                            includeLevel1={includeLevel1}
                        />
                    </ReportFragment>
                </NewWindow>
            }
        </React.Fragment>
    );
}
export default AffirmationCoverageReportModal;